import ISSUE_TYPES from "./types";

export const ISSUE_FILTERS_TYPES = {
  ID: ISSUE_TYPES.REPORTED_BUG_ID,
  STATUS: ISSUE_TYPES.STATUS,
  SEARCH: ISSUE_TYPES.MESSAGE,
  PRIORITY: ISSUE_TYPES.PRIORITY,
  CATEGORY: ISSUE_TYPES.CATEGORY,
  REPORTED_BY: ISSUE_TYPES.REPORTED_BY,
  ASSIGNED_TO: ISSUE_TYPES.ASSIGNED_TO,
  JIRA_URL: ISSUE_TYPES.JIRA_URL,
  HIDE_RESOLVED: "hide_resolved",
  DAYS_BACK_FROM: "days_back_from",
};

export const ISSUE_FILTER_VALUE_ALL = 0;

const ISSUE_FILTER_STATUS_OPTION_ALL = {
  name: "All",
  value: ISSUE_FILTER_VALUE_ALL,
};

export const getDefaultsOfFilters = () => {
  return {
    [ISSUE_FILTERS_TYPES.PRIORITY]: ISSUE_FILTER_VALUE_ALL,
    [ISSUE_FILTERS_TYPES.CATEGORY]: ISSUE_FILTER_VALUE_ALL,
    [ISSUE_FILTERS_TYPES.STATUS]: [ISSUE_FILTER_STATUS_OPTION_ALL.value],
    [ISSUE_FILTERS_TYPES.REPORTED_BY]: "",
    [ISSUE_FILTERS_TYPES.ASSIGNED_TO]: "",
    [ISSUE_FILTERS_TYPES.JIRA_URL]: "",
    [ISSUE_FILTERS_TYPES.ID]: "",
    [ISSUE_FILTERS_TYPES.SEARCH]: "",
    [ISSUE_FILTERS_TYPES.HIDE_RESOLVED]: false,
    [ISSUE_FILTERS_TYPES.DAYS_BACK_FROM]: {
      daysBack: 14,
      fromDate: null,
    },
  };
};

export const ISSUE_FILTERS = [
  {
    name: ISSUE_FILTERS_TYPES.ID,
    title: "ID",
    type: "string",
  },
  {
    name: ISSUE_FILTERS_TYPES.PRIORITY,
    title: "Priority",
    type: "select",
    multi: false,
    values: [
      {
        name: "All",
        value: ISSUE_FILTER_VALUE_ALL,
      },
    ],
  },
  {
    name: ISSUE_FILTERS_TYPES.REPORTED_BY,
    title: "Reported By",
    type: "string",
  },
  {
    name: ISSUE_FILTERS_TYPES.CATEGORY,
    title: "Category",
    type: "select",
    multi: false,
    values: [
      {
        name: "All",
        value: ISSUE_FILTER_VALUE_ALL,
      },
    ],
  },
  {
    name: ISSUE_FILTERS_TYPES.ASSIGNED_TO,
    title: "Assigned To",
    type: "string",
  },
  {
    name: ISSUE_FILTERS_TYPES.SEARCH,
    title: "Search",
    type: "string",
  },
  {
    name: ISSUE_FILTERS_TYPES.JIRA_URL,
    title: "Jira URL",
    type: "string",
  },
  {
    name: ISSUE_FILTERS_TYPES.DAYS_BACK_FROM,
    title: "Updated On",
    type: "custom",
    fieldTypes: {
      daysBack: "number",
      fromDate: "date",
    },
  },
  {
    name: ISSUE_FILTERS_TYPES.STATUS,
    title: "Status",
    type: "select",
    values: [
      ISSUE_FILTER_STATUS_OPTION_ALL,
    ],
  },
  {
    name: ISSUE_FILTERS_TYPES.HIDE_RESOLVED,
    title: "Hide Resolved",
    type: "bool",
  },
];

export default ISSUE_FILTERS;
