import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

export const ActionCell = ({ value, row, className, onClickBtn }) => {
  return (
    <StyledCell
      className={className}
      paddingLeft={row.absDepth > 0 ? row.absDepth * 16 + 8 : undefined}
    >
      {value}
      <button className={row.finalActionStatus === true ? 'btn btn-default btn-subscriber disabled' : 'btn btn-default btn-subscriber'}
        disabled={row.finalActionStatus === true}
        title={row.finalActionStatus === true ? 'Watch (Completed/failed actions cannot be watched)' : 'Watch'}
        onClick={() => onClickBtn(row)}>
        <i className="fa-light fa-bell-on"/>
      </button>
    </StyledCell>
  );
};

const StyledCell = styled.td`
  white-space:nowrap;
  ${props => props.paddingLeft && css`
    padding-left: ${props.paddingLeft}px !important;
  `}
  
  .btn-subscriber{
    margin-left:5px;
  }
`;

ActionCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object,
  className: PropTypes.string
};

