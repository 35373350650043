import React, { Component } from "react";
import { connect } from "react-redux";
import html2canvas from "html2canvas";
import styled from "styled-components";
import {
  toggleReportBugModal,
  setReportBugModalLoading,
  setBugScreenshotUrl,
} from "../layouts/actions";
import { Button } from "react-bootstrap";

class ReportBugButton extends Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (selector = "body") => {
    const { dispatch } = this.props;
    dispatch(toggleReportBugModal(true));
    dispatch(setReportBugModalLoading(true));
    const node = document.querySelector(selector);

    html2canvas(node).then((canvas) => {
      const url = canvas.toDataURL();
      dispatch(setBugScreenshotUrl(url));
      dispatch(setReportBugModalLoading(false));
    });
  };

  render() {
    const { isForModal = false, className, isIcon } = this.props;

    if (isForModal) {
      return (
        <ContainerForModalStyled className={className ? ` ${className}` : ''}>
          <Button
            title="Report an Issue"
            bsSize="small"
            onClick={() => this.handleClick()}
          >
            <i className="fa-light fa-comments-question-check fa-xl"></i>
          </Button>
        </ContainerForModalStyled>
      );
    }

    return (
      <ReportBugButtonStyled onClick={() => this.handleClick()}>
        {isIcon ? <i className="fa-regular fa-comments fa-xl" title="Report an Issue"></i> : 'Report an Issue'}
      </ReportBugButtonStyled>
    );
  }
}

const ContainerForModalStyled = styled.div`
  float: right;
  display: inline;
  padding: 0 20px;
  &.loader-report-bug-btn{
    position: fixed;
    right: 80px;
    bottom: 50px;
    z-index: 100001;
  }
  &.error-report-bug-btn{
    padding: 0;
    position: absolute;
    right: 75px;
    top: 22px;
    z-index: 100001;
    i {
      display: block !important;
    }
    button {
      height: 28px;
    }
  }
  button {
    outline: none !important;
  }
`;

const ReportBugButtonStyled = styled.button`
  padding: 15px;
  line-height: 20px;
  background-color: transparent !important;
  border: 0;
  outline: none !important;
  color: #9d9d9d;
  :hover,
  :focus {
    color: #fff;
  }
`;

export default connect()(ReportBugButton);
