import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, FormGroup, FormControl, ControlLabel} from 'react-bootstrap';
import styled from 'styled-components';
import {getValueForOS} from '../../utils';
import {isValidFileName} from '../../../../utils';
import TextEllipsisAdvanced from '../../../../components/TextEllipsis/TextEllipsisAdvanced';
import RndModal from '../../../../components/modals/RndModal';

class NewFolderModal extends Component {
  state = {
    inputValue: ''
  };

  isInvalidName = () => {
    const {inputValue} = this.state;
    return !isValidFileName(inputValue);
  };

  handleChange = event => {
    this.setState({inputValue: event.target.value});
  };

  handleMake = () => {
    const {onMake} = this.props;
    const {inputValue} = this.state;
    onMake(inputValue);
  };

  handleClose = () => {
    const {onClose} = this.props;
    onClose();
  };

  componentWillUnmount() {
    this.handleClose();
  }

  render() {
    const {item} = this.props;
    const {inputValue} = this.state;
    return (
      <RndModal
        show={true}
        onHide={this.handleClose}
        backdrop="static"
        enforceFocus={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>Make directory</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            {!!item &&
              <FormGroupStyled>
                <ControlLabel>Parent Directory</ControlLabel>
                <TextEllipsisAdvanced data={getValueForOS(item, 'key')}/>
              </FormGroupStyled>
            }
            <FormGroupStyled>
              <ControlLabel>Directory Name</ControlLabel>
              <FormControl
                value={inputValue}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                inputRef={ref => {
                  this._input = ref;
                }}
              />
            </FormGroupStyled>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.handleMake} disabled={this.isInvalidName()}>Make</Button>
          <Button bsStyle="default" onClick={this.handleClose}>Cancel</Button>
        </Modal.Footer>
      </RndModal>
    );
  }
}

const FormGroupStyled = styled(FormGroup)`
  display: flex;
  align-items: center;
  .control-label {
    margin: 0;
    padding: 0;
    flex: 0 0 140px;
    + div, + .form-control {
      flex-grow: 1;
      max-width: calc(100% - 140px);
    }
  }
  .form-control {
    font-family: monospace;
  }
`;

NewFolderModal.propTypes = {
  onMake: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  item: PropTypes.object
};

export default NewFolderModal;
