import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import {FormGroup, FormControl, ButtonToolbar, Button} from "react-bootstrap";

class CommentForm extends Component {
  state = {
    isFocused: false,
    commentPlainText: null,
    isSaving: false,
  };

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  getClassNamePrefix = () => this.props.classNamePrefix || 'sidebar-general-comment';

  getDefaultHeight = () => {
    const {height} = this.props;
    return height;
  };

  setHeight = (height) => {
    try {
      const classNamePrefix = this.getClassNamePrefix();
      const maxHeight = window.innerHeight / 2;
      const resHeight = Math.min(height, maxHeight);
      const formElm = document.getElementsByClassName(classNamePrefix + '-form')[0];
      formElm.style.height = `${resHeight}px`;
      const listElm = document.getElementsByClassName(classNamePrefix + 's-list-cont')[0];
      if (listElm) {
        const newHeight = `calc(100% - ${resHeight}px)`;
        listElm.style.height = newHeight;
        listElm.style.maxHeight = newHeight;
      } else if (this.props.onChangeCommentFormHeight) {
        this.props.onChangeCommentFormHeight(resHeight);
      }
    } catch {}
  };

  getBaseHeight = () => {
    const baseHeight = this.getDefaultHeight();
    return baseHeight;
  }

  changeHeight = (commentPlainText) => {
    const baseHeight = this.getBaseHeight();
    try {
      if(baseHeight < this._input.scrollHeight && commentPlainText) {
        this.setHeight(this._input.scrollHeight);
      } else {
        this.setHeight(baseHeight);
      }
    } catch {}
  };

  handleChange = ({target: {value}}) => {
    this.setState({commentPlainText: value});
    this.changeHeight(value);
  };

  handleFocus = () => {
    this.setState({isFocused: true});
  };

  handleReset = () => {
    if (this.isUnmounted) {
      return;
    }
    this.setState({
      isFocused: false,
      commentPlainText: null,
      isSaving: false,
    });
    this.setHeight(this.getDefaultHeight());
  };

  handleBlur = (event, clickedSuggestion) => {
    if (this.isUnmounted) {
      return;
    }
    const {onBlur, sendByEnter} = this.props;
    if (!clickedSuggestion && (onBlur || this.state.isSaving || sendByEnter || !this.state.commentPlainText)) {
      if (onBlur || this.state.isSaving) {
        this.handleReset();
        onBlur && onBlur();
      } else if (this.state.isFocused) {
        this.setState({isFocused: false});
        this.changeHeight(this.state.commentPlainText);
      }
    }
  };

  blur = () => {
    try {
      this._input.blur();
    } catch {}
  };

  handleCancel = () => {
    this.handleReset();
    setTimeout(this.blur, 100);
  };

  handleSave = () => {
    const {onSave} = this.props;
    const {commentPlainText} = this.state;
    if (commentPlainText) {
      this.setState({isSaving: true});
      onSave(commentPlainText).then(() => {
        if (!this.isUnmounted) {
          this.blur();
          this.handleBlur();
        }
      });
    } else {
      this.blur();
    }
  };

  handleKeyDown = ({keyCode, altKey, ctrlKey, shiftKey}) => {
    const {sendByEnter} = this.props;
    if (keyCode === 13 && !altKey && !ctrlKey && !shiftKey && sendByEnter) {
      this.handleSave();
    } else if (keyCode === 27) {
      this.handleCancel();
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.requestId !== prevProps.requestId) {
      this.handleCancel();
    }
  }

  render() {
    const {isLoading, placeholder, heightDiff, className, sendByEnter} = this.props;
    const {commentPlainText, isFocused, isSaving} = this.state;
    const minHeight = this.getBaseHeight();
    const classNamePrefix = this.getClassNamePrefix();
    const formClassName = classNamePrefix + '-form';
    const showToolbar = !sendByEnter && isFocused;
    return (
      <StyledForm
        className={classNames({
          [className || 'custom--comment-form']: true,
          [formClassName]: true,
          'is-focused': isFocused,
          'has-toolbar': showToolbar,
        })}
        formMinHeight={minHeight}
        inputMinHeight={minHeight - (heightDiff || 0)}
      >
        <i className="fa-solid fa-message icon-comment"/>
        <form action="#" onSubmit={event => event.preventDefault()}>
          <FormGroup className="comments-textarea">
            <FormControl
              componentClass="textarea"
              placeholder={`${placeholder || 'Add a comment...'}${isFocused && sendByEnter ? " (use '⏎' to send)" : ''}`}
              value={commentPlainText || ''}
              disabled={isLoading || isSaving}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onKeyDown={this.handleKeyDown}
              inputRef={ref => {
                this._input = ref;
              }}
            />
          </FormGroup>
        </form>
        {showToolbar &&
          <StyledButtonToolbar>
            <Button
              bsStyle="primary"
              bsSize="xsmall"
              onClick={this.handleSave}
            >
              Save
            </Button>
            <Button
              bsSize="xsmall"
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
          </StyledButtonToolbar>
        }
      </StyledForm>
    );
  }
}

const StyledButtonToolbar = styled(ButtonToolbar)`
  position: absolute;
  margin-top: 5px;
`;

const StyledForm = styled.div`
  position: relative;
  background: #fff;
  height: ${props => props.formMinHeight + 'px'};
  min-height: ${props => props.formMinHeight + 'px'};
  max-height: 50vh;
  box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.08);

  i.icon-comment {
    position: absolute;
    top: 17px;
    left: 23px;
    font-size: 19px;
    color: #adadad;
    opacity: 0.57;
  }

  &.is-focused {
    box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.15);
    i.icon-comment {
      opacity: 1;
    }
  }

  form, .comments-textarea, .comments-textarea__control {
    margin: 0;
    height: 100%;
  }

  .comments-textarea {
    position: relative !important;
    overflow-y: visible !important;
  }

  .form-group {
    margin: 0;
  }

  textarea.form-control {
    margin: 0 !important;
    padding: 18px 12px 18px 53px !important;
    width: 100%;
    height: 100%;
    min-height: ${props => props.inputMinHeight + 'px'} !important;
    max-height: 50vh !important;
    border: none !important;
    border-radius: 0;
    box-sizing: border-box;
    box-shadow: none !important;
    font-family: 'Roboto', sans-serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 14px !important;
    outline: none !important;
    resize: none;
    overflow: auto !important;
    white-space: pre-wrap !important;
    overflow-wrap: break-word !important;

    @media (max-width: 767px) {
      font-size: 13px !important;
      line-height: 15px !important;
    }

    &::placeholder {
      font-family: 'Roboto', sans-serif !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 12px !important;
      line-height: 14px !important;

      @media (max-width: 767px) {
        font-size: 13px !important;
        line-height: 15px !important;
      }
    }
  }
`;

CommentForm.defaultProps = {
  isLoading: false,
  sendByEnter: true,
};

CommentForm.propTypes = {
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  requestId: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  heightDiff: PropTypes.number,
  placeholder: PropTypes.string,
  sendByEnter: PropTypes.bool.isRequired,
};

export default CommentForm;
