import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {FormGroup, FormControl, ControlLabel, Row, Col, OverlayTrigger, Popover} from 'react-bootstrap';
import {FilterSelectStyled} from './FiltersStyled';
import Select from 'react-select';
import DateField from './DateField';
import {convertSizeToBytes, SIZE_UNITS_FILTER} from '../../utils';

class Filter extends Component {
  getInputField(type) {
    const {filter, value, onChange} = this.props;
    const inputProps = type === 'number' ? {type, min: 1} : {};
    return (
      <FormControl
        {...filter.props}
        {...inputProps}
        id={filter.name}
        value={value || ''}
        onChange={event => onChange(event.target.id, event.target.value)}
        title={filter.tooltip}
        className={filter.className}
        placeholder={filter.placeholder}
      />
    );
  }

  getSelectOptions() {
    const {filter} = this.props;
    return (filter && filter.values && filter.values.map(item => {
      return {
        label: item[filter.labelKey || 'name'],
        value: item[filter.valueKey || 'value']
      };
    })) || [];
  }

  getSelectField() {
    const {filter, value, onChange} = this.props;
    const multi = typeof filter.multi !== 'boolean' || filter.multi;
    return (
      <FilterSelectStyled className={filter.button ? 'filter-select-with-button' : ''}>
        <Select
          multi={multi}
          defaultValue={multi ? (filter.defaultValue || []) : filter.defaultValue}
          value={value}
          options={this.getSelectOptions()}
          onChange={v => onChange(filter.name, multi ? v : (v || {}).value)}
          />
        {filter.button || null}
      </FilterSelectStyled>
    );
  }

  onRadioChange = (event, options) => {
    const {name, dataset} = event.target;
    const {value} = options.filter(item => item.name === dataset.name)[0];
    this.props.onChange(name, value);
  };

  getRadioField() {
    const {filter, value} = this.props;
    const options = filter.values || [];
    if (options.length > 2) {
      return this.getSelectField();
    }
    return (
      <FilterRadioStyled>
        {options.map((item, index) => {
          const id = `radio-${item.name.toLowerCase()}`;
          return (
            <Fragment key={index}>
              {index > 0 ? <ControlLabel className="label-or">or</ControlLabel> : null}
              <input
                id={id}
                name={filter.name}
                data-name={item.name}
                type="radio"
                value={item.value}
                checked={item.value === value}
                onChange={event => this.onRadioChange(event, options)}
              />
              <ControlLabel htmlFor={id}>
              {item.name}
              </ControlLabel>
            </Fragment>
          );
        })}
      </FilterRadioStyled>
    );
  }

  onDateChange = (id, date) => {
    const {filter, value, onChange} = this.props;
    onChange(filter.name, {
      ...value,
      [id]: date
    });
  };

  prepareDate = value => {
    const date = new Date(value);
    const res = date.getTime() ? date : null;
    return res || null;
  };

  getDateField() {
    const {value} = this.props;
    const startDate = this.prepareDate(value && value.start);
    const endDate = this.prepareDate(value && value.end);
    return (
      <Row className="date-group">
        <Col md={6} xs={6}>
          <DateField
            id="start"
            placeholderText="From"
            selected={startDate}
            selectsStart
            maxDate={endDate}
            startDate={startDate}
            endDate={endDate}
            onChange={date => {
              if (date && endDate && date.getTime() > endDate.getTime()) {
                date = endDate;
              }
              this.onDateChange('start', date);
            }}
            />
        </Col>
        <Col md={6} xs={6}>
          <DateField
            id="end"
            placeholderText="To"
            selected={endDate}
            selectsEnd
            minDate={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={date => {
              if (date && startDate && date.getTime() < startDate.getTime()) {
                date = startDate;
              }
              this.onDateChange('end', date);
            }}
            />
        </Col>
      </Row>
    );
  }

  getDateSingleField() {
    const {filter, value, onChange} = this.props;
    return (
      <DateField
        id={filter.name}
        placeholderText=""
        showTime={false}
        dateFormat="yyyy/MM/dd"
        selected={this.prepareDate(value)}
        maxDate={Date.now()}
        onChange={date => {
          if (date && date.getTime() > Date.now()) {
            date = new Date();
          }
          onChange(filter.name, date);
        }}
      />
    );
  }

  getCheckboxField() {
    const {filter, value, onChange} = this.props;
    return (
      <FormControl
        id={filter.name}
        type="checkbox"
        checked={value}
        onChange={event => onChange(event.target.id, event.target.checked)}
        disabled={filter.disabled === true}
        />
    );
  }

  handleSizeChange = (id, sizeValue, unit, isBlur) => {
    const {filter, value, onChange} = this.props;
    let size = parseInt(sizeValue, 10);
    let sizeUnit = unit;
    if (isNaN(size) || size < 0) {
      size = undefined;
      sizeUnit = undefined;
    } else if (isBlur) {
      if (id === 'from') {
        const sizeTo = parseInt(value && value.to, 10);
        if (!isNaN(sizeTo)) {
          const bytesTo = convertSizeToBytes(sizeTo, value.to_unit, 'to');
          const bytes = convertSizeToBytes(size, sizeUnit, 'from');
          if (bytes > bytesTo) {
            size = sizeTo;
            sizeUnit = value.to_unit;
          }
        }
      } else {
        const sizeFrom = parseInt(value && value.from, 10);
        if (!isNaN(sizeFrom)) {
          const bytesFrom = convertSizeToBytes(sizeFrom, value.from_unit, 'from');
          const bytes = convertSizeToBytes(size, sizeUnit, 'to');
          if (bytes < bytesFrom) {
            size = sizeFrom;
            sizeUnit = value.from_unit;
          }
        }
      }
    }
    onChange(filter.name, {
      ...value,
      [id]: size,
      [`${id}_unit`]: sizeUnit
    });
  };

  handleSizeValueChange = (event, unit, isBlur) => {
    this.handleSizeChange(event.target.id, event.target.value, unit, isBlur);
  };

  handleSizeUnitChange = (id, unit, size) => {
    this.handleSizeChange(id, size, unit, true);
  };

  getSizeField() {
    const units = SIZE_UNITS_FILTER.map(value => ({label: value, value}));
    const {value} = this.props;
    const sizeFrom = value && value.from;
    const sizeTo = value && value.to;
    const defaultUnit = units[0].value;
    const sizeFromUnit = (value && value.from_unit) || defaultUnit;
    const sizeToUnit = (value && value.to_unit) || defaultUnit;
    return (
      <Row className="size-group with-units">
        <Col md={4} xs={4}>
          <FormControl
            id="from"
            type="number"
            placeholder="From"
            value={sizeFrom >= 0 ? sizeFrom : ''}
            min={0}
            onChange={event => this.handleSizeValueChange(event, sizeFromUnit)}
            onBlur={event => this.handleSizeValueChange(event, sizeFromUnit, true)}
            />
        </Col>
        <Col md={2} xs={2}>
          <FilterSelectStyled>
            <Select
              multi={false}
              searchable={false}
              defaultValue={defaultUnit}
              value={sizeFromUnit}
              options={units}
              onChange={v => this.handleSizeUnitChange('from', v.value, sizeFrom >= 0 ? sizeFrom : '')}
              placeholder=""
            />
          </FilterSelectStyled>
        </Col>
        <Col md={4} xs={4}>
          <FormControl
            id="to"
            type="number"
            placeholder="To"
            value={sizeTo >= 0 ? sizeTo : ''}
            min={0}
            onChange={event => this.handleSizeValueChange(event, sizeToUnit)}
            onBlur={event => this.handleSizeValueChange(event, sizeToUnit, true)}
            />
        </Col>
        <Col md={2} xs={2}>
          <FilterSelectStyled>
            <Select
              multi={false}
              searchable={false}
              defaultValue={defaultUnit}
              value={sizeToUnit}
              options={units}
              onChange={v => this.handleSizeUnitChange('to', v.value, sizeTo >= 0 ? sizeTo : '')}
              placeholder=""
            />
          </FilterSelectStyled>
        </Col>
      </Row>
    );
  }
  getCustomField = () => {
    const {filter} = this.props;
    return filter.field;
  };

  render() {
    const {filter} = this.props;
    const ValueField = {
      custom: this.getCustomField(),
      size: this.getSizeField(),
      string: this.getInputField(),
      number: this.getInputField('number'),
      select: this.getSelectField(),
      radio: this.getRadioField(),
      date: this.getDateField(),
      'date-single': this.getDateSingleField(),
      bool: this.getCheckboxField()
    }[filter.type];

    return filter.isHidden ? null : (
      <FilterStyled>
       <Row>
         <Col md={4} xs={12}>
           {
             filter.overlay ? (
               <label
                 disabled={true}
                 className="form-control filter-field-label"
                 >
                 <OverlayTrigger
                   placement="right"
                   overlay={<Popover id={`popover-${filter.name}`} className="filter-popover">{filter.overlay}</Popover>}
                   >
                   <span>{filter.title}<i className="fas fa-question-circle"/></span>
                 </OverlayTrigger>
               </label>
             ) : (
               <FormControl
                 value={filter.title}
                 disabled={true}
                 className="filter-field-label"
                 />
             )
           }
         </Col>
         <Col md={8} xs={12}>{ValueField}</Col>
       </Row>
      </FilterStyled>
    );
  }
}

const FilterRadioStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  label.control-label {
    margin: 4px 0 0 2px;

    &.label-or {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
`;

export const FilterStyled = styled(FormGroup)`
  margin-bottom: 6px;
  .control-label {
    font-size: 10px;
    margin-right: 10px;
  }
  .form-control {
    border: 1px solid #E9E9E9;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    font-size: 10px;
    font-weight: bold;
    height: 27px;
    &[type="checkbox"] {
      height: auto;
      width: auto;
      cursor: pointer;
    }
    &.filter-field-label {
      cursor: default !important;
      font-weight: bold;
      padding-left: 9px;
      &.presets-field-label {
        background-color: #dedede;
        border-color: #d6d6d6;
        color: #333;
      }
    }
    i {
      cursor: pointer;
      margin-left: 7px;
    }
    &[type=number]:hover::-webkit-inner-spin-button {
      height: 16px;
    }
  }
  input.form-control {
    padding: 9px;
    font-weight: normal;
  }
  .date-group, .size-group {
    display: flex;
    justify-content: space-between;
    [class*="col-"] {
      padding: 0 15px;
    }
    &.with-units {
      [class*="col-"] {
        padding: 0 0 0 5px;
      }
      .col-md-4, .col-xs-4 {
        padding-left: 15px;
      }
      .col-md-2, .col-xs-2 {
        padding-right: 15px;
      }
    }
  }
`;

Filter.propTypes = {
  filter: PropTypes.object.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

export default Filter;