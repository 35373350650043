import React, {useEffect} from 'react';
import styled from 'styled-components';
import {FormGroup, FormControl} from 'react-bootstrap';

const FileNameEditCell = ({className, file, deliveryFileName, isValidName, onChange, disabled}) => {
  const {categoryID, woMemfis, contentID, isManuallyChanged} = file;

  const id = `f${categoryID}-${woMemfis}-${contentID}`;

  useEffect(() => {
    try {
      const input = document.getElementById(id);
      if (input.clientHeight < input.scrollHeight) {
        input.style.height = `${input.scrollHeight}px`;
      }
    } catch(ignored) {/**/}
  }, [id]);

  const handleKeyDown = ({keyCode}) => {
    try {
      keyCode === 13 && document.getElementById(id).blur();
    } catch(ignored) {/**/}
  };

  return (
    <td className={className}>
      <StyledFormGroup
        validationState={isManuallyChanged && !isValidName ? 'error' : null}
      >
        <FormControl
          id={id}
          componentClass="textarea"
          value={deliveryFileName || ''}
          disabled={disabled}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          rows="1"
        />
      </StyledFormGroup>
    </td>
  );
};

const StyledFormGroup = styled(FormGroup)`
  margin: 0;
  &.has-error {
    .form-control {
      &:not(:focus) {
        color: #a94442;
      }
    }
  }
  .form-control {
    margin: 0;
    width: 100% !important;
    min-height: 34px;
    resize: vertical;
    &:not(:focus) {
      padding: 0;
      height: 20px;
      min-height: 20px;
      border: none;
      box-shadow: none;
      cursor: pointer;
    }
    &[disabled] {
      background-color: #fff;
      cursor: text
    }
  }
`;

export default FileNameEditCell;