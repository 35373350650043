import styled from 'styled-components';

export const StyledTable = styled.div`
  display: flex;
  justify-content: space-between;

  .table-container {
    width: 75%;
    transition: width 0.3s ease-in-out 0s;
  }

  &.info-panel-collapsed  {
    .table-container {
      width: 100%;
    }
  }

  .table-block {
    max-width: 100%;
    overflow-x: auto;
    max-height: calc(100vh - 71px - 150px);
    overflow-y: auto;

    @media (max-width: 767px) {
      max-height: calc(100vh - 71px - 210px);
    }

    .table {
      border-collapse: separate;
      color: #282828;
      margin-bottom: 0;
      table-layout: fixed;
      width: 100%;
      display: table;
      --checkbox-cell-width: 55px;

      caption {
        padding-top: 8px;
        padding-bottom: 8px;
        color: #777;
        text-align: center;
        caption-side: bottom;
      }

      tr {
        td, th {
          &.checkbox-cell {
            // width: calc(36% - var(--checkbox-cell-width));
            width: var(--checkbox-cell-width);
            padding: 0;
            vertical-align:middle;
            
            input {
              display: block;
              margin: auto;
              position:relative;
              left:-5px;
            }
          }
          &.sender {
            width: 10%;
            padding: 8px 0;
          }
          &.name {
            width: 54%;
            padding: 8px 8px 8px 0;
          }
          &.updated {
            width: 20%;
          }
        }
      }

      &:not(.view_advanced_predictive) {
        tr {
          td, th {
            &.predictive-status {
              display: none;
            }
            &.updated, &.modified {
              width: 15%;
            }
          }
        }
      }

      &.no-roots {
        tr {
          th, td {
            &.updated {
              display: none;
            }
          }
        }

        &.view_advanced_predictive {
          tr {
            th, td {
              &.name {
                width: 28%;
              }
              &.full-path {
                width: 42%;
              }
              &.checkbox-cell + .name + .full-path {
                width: calc(42% - var(--checkbox-cell-width));
              }
            }
          }
        }

        &:not(.view_advanced_predictive) {
          tr {
            th, td {
              &.name {
                width: 30%;
              }
              &.full-path {
                width: 45%;
              }
              &.checkbox-cell + .name + .full-path {
                width: calc(45% - var(--checkbox-cell-width));
              }
            }
          }
        }
      }

      &:not(.with-full-path) {
        tr {
          td, th {
            &.full-path {
              display: none;
            }
            &.name {
              width: 60%;
            }
            &.checkbox-cell + .name {
              width: calc(60% - var(--checkbox-cell-width));
            }
          }
        }

        &.no-roots {

          &.view_advanced_predictive {
            tr {
              th, td {
                &.name {
                  width: 70%;
                }
                &.checkbox-cell + .name {
                  width: calc(70% - var(--checkbox-cell-width));
                }
              }
            }
          }

          &:not(.view_advanced_predictive) {
            tr {
              th, td {
                &.name {
                  width: 75%;
                }
                &.checkbox-cell + .name {
                  width: calc(75% - var(--checkbox-cell-width));
                }
              }
            }
          }
        }
      }

      > thead {

        > tr {

          > th {
            position: sticky;
            top: 0px;
            background-color: rgb(255, 255, 255);
            overflow: hidden;
            z-index: 2;

            &:not(.checkbox-cell) {
              /*white-space: nowrap;*/
            }

             &:hover {
              &.name, &.full-path, &.size, &.updated, &.modified, &.predictive-status {
                cursor: pointer;
              }
            }
          }
        }
      }

      > tbody {

        > tr {
          &:hover {
            background-color: #f0f2ff;
          }

          &:first-child {
            > td {
              border-top: 0;
            }
          }

          > td {

            &.predictive-status {
              word-break: break-all;
            }
            &.data-table-empty {
              text-align: center;
              width: 100% !important;
              color: #777;
            }
          }

          &.tr-ellipsis {
            pointer-events: none;
            > td {
              &.td-ellipsis {
                font-weight: 700;
              }
              &:not(.td-ellipsis) {
                color: transparent;
                * {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
`;
