import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Button, DropdownButton, MenuItem, ButtonGroup, ButtonToolbar} from 'react-bootstrap';
import _ from 'lodash';
import {
  deleteAssets, deleteWithApproval, showLinkToMemfisWoModal, closeInstantScanResult, getActions,
  getAndShowIngestOperationTypes, showMoveModal, showRenameModal, showNewFolderModal, makeDirectory,
  uploadFile, generateAndUploadZip, getAplusSettings, saveAplusSettings, closeAplusSettingsModal,
  showEncryptModal, getCopyData, showSetContentPermissionsModal, showUnlinkModal,
  toggleShowRenameAmazonFile, showModalAddFileToWO, showMediaInfoModal, showUploadToAsperaModal,
  showCreateSymlinkModal, changeOpenedFoldersInModal, setFoldersInModal, getAvailableActionsBySources,
  instantScanSubFolder
} from '../actions';
import {showError} from '../../../layouts/actions';
import MoveModal from './modal/MoveModal';
import RenameModal from './modal/RenameModal';
import RenameAmazonFileModal from './modal/RenameAmazonFileModal';
import GenerateChecksumModal from './modal/GenerateChecksum';
import CaptionsModal from './modal/CaptionsModal';
import PseModal from './modal/PseModal';
import InstantScanModal from './modal/InstantScanModal';
import {
  DEFAULT_MAX_CONTENT_COUNT_TO_MIGRATE,
  DISPLAY_CATEGORY_SEARCH_RESULTS,
  FS_ACTION_TYPE__DECRYPT_CONTENT,
  FS_ACTION_TYPE__DELETE_WITH_APPROVAL,
  FS_ACTION_TYPE__ENCRYPT_CONTENT,
  FS_ACTION_TYPE__GET_APLUS_SETTINGS,
  FS_ACTION_TYPE__MIGRATE_PORTAL,
  FS_ACTION_TYPE__MK_DIRECTORY,
  FS_ACTION_TYPE__MOVE,
  FS_ACTION_TYPE__MULTIPLE_MOVE,
  FS_ACTION_TYPE__PROXY_COPY,
  FS_ACTION_TYPE__SET_CONTENT_GROUP_PORTAL,
  FS_ACTION_TYPE__SET_CONTENT_PERMISSIONS_PORTAL,
  FS_ACTION_TYPE__SIMPLE_COPY,
  FS_ACTION_TYPE__COPY,
  FS_ACTION_TYPE__ZIP_CONTENT,
  FS_ACTION_TYPE__ADD_TO_MEMFIS_WO,
  FS_ACTION_TYPE__LINK_TO_MEMFIS_WO,
  FS_ACTION_TYPE__CREATE_SYMLINK_PORTAL,
  FS_ACTION_TYPE__UPLOAD_TO_ASPERA,
  FS_ACTION_TYPE__UPLOAD_CONTENT,
} from '../constants';
import {
  ACTION_FILTER_FS_ROOT_ID,
  ACTION_FILTER_CONTENT_ID,
  ACTION__SOURCE_OF_REQUEST__ACTIONSBAR,
} from './fs-actions/constants';
import {confirmAlert} from 'react-confirm-alert';
import {
  isForbiddenActionByPermissions,
  isPermittedByRootsPermissions,
  isForbiddenAction,
  isHiddenActionBtn,
} from '../../../utils';
import {
  isDeletedStatus,
  parseName,
  isTooLongName,
  isRootItem,
  isReadOnlyFsRoot,
  isRegularDrive, isNearlineDrive,
} from '../utils';
import {LinkToMemfisWoAndIngest} from './LinkToMemfisWoAndIngest';
import NewFolderModal from './modal/NewFolderModal';
import Dropzone from 'react-dropzone';
import AplusSettingsModal from './modal/AplusSettingsModal';
import EncryptModal from './modal/EncryptModal';
import CopyModal from './modal/CopyModal';
import MediaInfoModal from './modal/MediaInfoModal';
import ContentPermissionsModal from './modal/ContentPermissionsModal';
import LKFSModal from "./modal/LKFSModal";
import AddFileToWoModal from './modal/AddFileToWoModal';
import AttachToWoModal from './modal/AttachToWoModal';
import UploadToAsperaModal from './modal/UploadToAsperaModal';
import CopyToQodModal from './modal/CopyToQodModal';
import CreateSymlinkModal from './modal/CreateSymlinkModal';
import UnlinkModal from './modal/UnlinkModal';
import ChecksumReportModal from './modal/ChecksumReportModal';
import {
  isInitConfirmDeletion,
  renderActionButton,
  LKFSButton,
  GenerateChecksumButtons,
  DownloadButton,
  PSEButton,
  MediaInfoButton,
  CopyToQodButton,
  AttachToWoButton,
  CaptionsButton,
  MetafierButton,
  EdgePassButton,
  ScreenToQodButton,
  HdrReportButton
} from './buttons/FsActions';
import ActionsHistoryToggle from './fs-actions/ActionsHistoryToggle';
import AlertModal from "./modal/AlertModal";
import MetafierModal from "./modal/MetafierModal";
import FrameRateModal from "./modal/FrameRateModal";
import WoStepsModal from "./modal/WoStepsModal";
import IssueScreenshotToQodModal from "./modal/IssueScreenshotToQodModal";
import HdrReportModal from "./modal/HdrReportModal";

class Actionbar extends Component {
  state = {
    modalForDisplay: null,
  };

  openHdrReportClick = () => {
    this.setState({
      modalForDisplay: 'HDR_REPORT',
    });
  };

  closeHdrReportModalClick = () => {
    this.setState({
      modalForDisplay: null,
    });
  };

  handleClickActionCopy = (allowedActions) => {
    const {dispatch, storageManager: {checkedItems}} = this.props;
    dispatch(getCopyData(checkedItems[0], null, false, allowedActions));
  };

  handleCloseRenameModal = () => {
    const {dispatch} = this.props;
    dispatch(showRenameModal(false));
  };

  handleClickActionMove = (checkedItems, allowedActions) => {
    const {dispatch} = this.props;
    const showModal = (actions) => dispatch(showMoveModal(true, actions));
    if (checkedItems.length === 1 && allowedActions.includes(FS_ACTION_TYPE__MIGRATE_PORTAL)) {
      dispatch(getAvailableActionsBySources(checkedItems, FS_ACTION_TYPE__MIGRATE_PORTAL, (availableActionsBySources) => {
        if (Object.keys(availableActionsBySources || {}).includes(FS_ACTION_TYPE__MIGRATE_PORTAL)) {
          showModal(allowedActions);
        } else if (allowedActions.length > 1) {
          showModal(allowedActions.filter(a => a !== FS_ACTION_TYPE__MIGRATE_PORTAL));
        } else {
          dispatch(showError('The Migrate action is not allowed for the selected content item.'));
        }
      }));
    } else {
      showModal(allowedActions);
    }
  };

  handleClickActionRename = () => {
    const {dispatch} = this.props;
    dispatch(showRenameModal(true));
  };

  handleShowNewFolderModal = () => {
    const {dispatch} = this.props;
    dispatch(showNewFolderModal(true));
  };

  handleCloseNewFolderModal = () => {
    const {dispatch} = this.props;
    dispatch(showNewFolderModal(false));
  };

  handleCloseMediaInfoModal = () => {
    const {dispatch} = this.props;
    dispatch(showMediaInfoModal(false));
  };

  handleCloseCreateSymlinkModal = () => {
    const {dispatch} = this.props;
    dispatch(showCreateSymlinkModal(false));
    dispatch(changeOpenedFoldersInModal({}));
    dispatch(setFoldersInModal([]));
  }

  handleCloseUploadToAsperaModal = () => {
    const {dispatch} = this.props;
    dispatch(showUploadToAsperaModal(false));
  };

  handleShowContentPermissionsModal = () => {
    const {dispatch} = this.props;
    dispatch(showSetContentPermissionsModal(true));
  };

  isSearchResultsDisplay = () => {
    const {storageManager} = this.props;
    const {displayCategory} = storageManager;
    return displayCategory === DISPLAY_CATEGORY_SEARCH_RESULTS;
  };

  isInitConfirmDeletionDisplay = () => {
    const {storageManager} = this.props;
    return isInitConfirmDeletion(storageManager);
  };

  showDeleteConfirmAlert = (onDelete, file = '') => {
    confirmAlert({
      title: "Deletion",
      message: `Are you sure you want to delete ${file}`,
      buttons: [
        {
          label: "Delete",
          onClick: onDelete,
        },
        {
          label: "Cancel",
          onClick: () => null,
        },
      ],
    });
  }

  handleClickDeleteAssets = () => {
    const { dispatch, history, storageManager } = this.props;
    const { checkedItems } = storageManager;

    if (this.isInitConfirmDeletionDisplay()) {
      this.showDeleteConfirmAlert(() => {
        dispatch(deleteWithApproval(history, true));
      }, checkedItems[0].name);
    } else {
      dispatch(deleteAssets(history, this.showDeleteConfirmAlert));
    }
  };

  handleClickIngest = () => {
    const {dispatch, storageManager} = this.props;
    const { checkedItems } = storageManager;
    dispatch(getAndShowIngestOperationTypes(checkedItems[0].contentID, checkedItems[0].fsRootID));
  };

  handleClickUnlinkMemfisWO = () => {
    const {dispatch} = this.props;
    dispatch(showUnlinkModal(true));
  };

  handleClickLinkToMemfisWO = () => {
    const {dispatch, storageManager} = this.props;
    const {checkedItems} = storageManager;
    dispatch(showLinkToMemfisWoModal(checkedItems[0]));
  };

  handleClickAddToWO = () => {
    const {dispatch} = this.props;
    dispatch(showModalAddFileToWO());
  };

  handleShowRenameAmazonFile = isShow => {
    const {dispatch} = this.props;
    dispatch(toggleShowRenameAmazonFile(isShow));
  };

  handleGetAplusSettings = () => {
    const {dispatch, storageManager} = this.props;
    const {currentFolder, checkedItems} = storageManager;
    dispatch(getAplusSettings((currentFolder || checkedItems[0]).fsRootID));
  };

  handleSaveAplusSettings = (fsRootID, settings) => {
    const {dispatch} = this.props;
    dispatch(saveAplusSettings(fsRootID, settings));
  };

  handleCloseAplusSettingsModal = () => {
    const {dispatch} = this.props;
    dispatch(closeAplusSettingsModal());
  };

  isReadFilePermittedTypes = file => {
    const {actionTypes} = this.props.storageManager;

    if (actionTypes) {
      const {[FS_ACTION_TYPE__UPLOAD_CONTENT]: {allowReadingProperties}} = actionTypes;

      const fileExtensions = Object.keys(allowReadingProperties).map(ext => ext.replace(/^[.]+/, ''));
      const regExpAllowReadingFile = fileExtensions.length > 0 ?
        new RegExp('^.+[.](' + fileExtensions.join('|') + ')$', 'i') :
        null;

      if (regExpAllowReadingFile) {
        const ext = regExpAllowReadingFile.exec(file.name);
        if (ext && ext.length > 1) {
          return !!ext[1];
        }
      }
    }
    return false;
  };

  handleCloseInstantScan = () => {
    const {dispatch} = this.props;
    dispatch(closeInstantScanResult());
  };

  handleClickInstantScanSubFolder = (subFolder) => {
    const {dispatch, history} = this.props;
    const {FileName} = subFolder;
    dispatch(instantScanSubFolder(history, FileName));
  };

  handleClickActionsHistory = () => {
    const {dispatch, history, storageManager} = this.props;
    const {checkedItems} = storageManager;
    const filters = {};
    if (checkedItems.length) {
      const fsRootIDs = _.uniq(checkedItems.map(item => item.fsRootID));
      const contentIDs = _.uniq(checkedItems.map(item => item.contentID));
      filters[ACTION_FILTER_FS_ROOT_ID] = fsRootIDs;
      filters[ACTION_FILTER_CONTENT_ID] = contentIDs.join(',');
    }
    dispatch(getActions(history, filters, ACTION__SOURCE_OF_REQUEST__ACTIONSBAR));
  };

  handleMakeDirectory = name => {
    const {dispatch, history} = this.props;
    dispatch(makeDirectory(history, name));
  };

  filterFilesToUpload = (files, isUploadFolder) => {
    const {actionTypes} = this.props.storageManager;

    if (actionTypes) {
      const {[FS_ACTION_TYPE__UPLOAD_CONTENT]: {allowReadingProperties}} = actionTypes;

      const fileExtensions = Object.keys(allowReadingProperties).map(ext => ext.replace(/^[.]+/, ''));
      const regExpAllowReadingFile = fileExtensions.length > 0 ?
        new RegExp('^.+[.](' + fileExtensions.join('|') + ')$', 'i') :
        null;

      try {
        return files.filter(file => {
          const {name, size} = file;
          const ext = regExpAllowReadingFile.exec(name);
          if (ext && ext.length > 1 && ext[1]) {
            if (isUploadFolder && ext[1].toLowerCase() === 'zip') {
              return false;
            }
            const fileSettings = allowReadingProperties[`.${ext[1]}`];
            const fileSizeSetting = Array.isArray(fileSettings) ?
              fileSettings.find(s => s.propertyName === 'max_size_to_read' && !!s.PropertyValue) : null;
            if (fileSizeSetting && size <= Number(fileSizeSetting.PropertyValue)) {
              return true;
            }
          }
          return false;
        });
      } catch (e) {
        console.log(e);
        return null;
      }
    }
  };

  isAlreadyExistsContent = (name, isDirectory) => {
    const {displayedItems} = this.props.storageManager;
    const nameInLC = name.toLowerCase();
    return displayedItems.some(i => {
      const itemNameInLC = parseName(i.key.toLowerCase());
      return (
        ((i.isDirectory || false) === isDirectory && itemNameInLC === nameInLC) ||
        (!isDirectory && nameInLC.endsWith('.zip') && !!i.isDirectory && itemNameInLC === nameInLC.replace(/[.]zip$/, ''))
      );
    });
  };

  showConfirmUploadWhenNeedToRename = (name, isDirectory, onRename, alreadyExists, tooLongName, msg) => {
    const {dispatch} = this.props;
    const messages = [];
    if (alreadyExists) {
      const contentTypeName = isDirectory ? 'folder' : name.toLowerCase().endsWith('.zip') ? 'content' : 'file';
      messages.push(`A ${contentTypeName} with the same name already exists in the current directory.`);
    }
    if (tooLongName) {
      messages.push(`${alreadyExists ? 'And the' : 'The'} ${isDirectory ? 'folder' : 'file'} name is too long.`);
    }
    if (msg) {
      messages.push(msg);
    }
    confirmAlert({
      message: messages.join('  '),
      buttons: [{
        label: 'Cancel uploading'
      }, {
        label: 'Rename and continue',
        onClick: () => dispatch(showRenameModal(true, {
          item: {name, isDirectory},
          onRename,
          isAlreadyExistsContent: this.isAlreadyExistsContent
        }))
      }]
    });
  };

  handleUploadFile = files => {
    const {dispatch, history} = this.props;
    const filesToUpload = this.filterFilesToUpload(files, false);
    if (!filesToUpload) {
      dispatch(showError('Could not validate file type permissions.  Please contact support.'));
    } if (!filesToUpload.length) {
      dispatch(showError('This file cannot be uploaded due to security settings.'));
    } else {
      const uploadSingleFile = (name) => dispatch(uploadFile(history, name, filesToUpload[0]));
      const fileName = filesToUpload[0].name;
      const alreadyExists = this.isAlreadyExistsContent(fileName, false);
      const tooLongName = isTooLongName(fileName, false);
      if (alreadyExists || tooLongName) {
        this.showConfirmUploadWhenNeedToRename(fileName, false, uploadSingleFile, alreadyExists, tooLongName);
      } else {
        uploadSingleFile(fileName);
      }
    }
  };

  handleUploadFolder = files => {
    const {dispatch, history} = this.props;
    const filesToUpload = this.filterFilesToUpload(files, true);
    if (!filesToUpload) {
      dispatch(showError('Could not validate file type permissions.  Please contact support.'));
    } if (!filesToUpload.length) {
      dispatch(showError('None of the files in this folder can be uploaded due to security settings.'));
    } else {
      const uploadFolder = (name) => dispatch(generateAndUploadZip(history, name, filesToUpload));
      const folderName = filesToUpload[0].path.replace(/^(\/|\\)+/, '').split(/(\/|\\)/)[0];
      const alreadyExists = this.isAlreadyExistsContent(folderName, true);
      const forbiddenFilesCount = files.length - filesToUpload.length;
      const hasForbiddenFiles = !!forbiddenFilesCount;
      const tooLongName = isTooLongName(folderName, true);
      const needToRename = alreadyExists || tooLongName;
      if (needToRename || hasForbiddenFiles) {
        const msgForForbiddenFiles = !hasForbiddenFiles ? null :
          `${(needToRename) ? 'And the' : 'The'} folder contains file${forbiddenFilesCount > 1 ? 's' : ''} (${forbiddenFilesCount} of ${files.length}) that will not be uploaded.`;
        if (needToRename) {
          this.showConfirmUploadWhenNeedToRename(folderName, true, uploadFolder, alreadyExists, tooLongName, msgForForbiddenFiles);
        } else {
          confirmAlert({
            message: msgForForbiddenFiles,
            buttons: [{
              label: 'Cancel'
            }, {
              label: 'Continue uploading',
              onClick: () => uploadFolder(folderName)
            }]
          });
        }
      } else {
        uploadFolder(folderName);
      }
    }
  };

  handleClickActionUploadToAspera = () => {
    const {dispatch} = this.props;
    dispatch(showUploadToAsperaModal(true));
  }

  isForbiddenActionLinkToMemfisWO = fsRootID => {
    return isForbiddenAction(FS_ACTION_TYPE__LINK_TO_MEMFIS_WO, fsRootID);
  };

  isForbiddenUnlinkOrIngest = ({fsRootID, isDirectory}) => {
    const {storageManager} = this.props;
    const {lockAndServicesStatuses} = storageManager;
    const {FsRootLockStatuses} = lockAndServicesStatuses;
    if (!isDirectory && isReadOnlyFsRoot({fsRootID}, FsRootLockStatuses)) {
      return isForbiddenActionByPermissions(FS_ACTION_TYPE__LINK_TO_MEMFIS_WO, fsRootID, storageManager);
    }
    return this.isForbiddenActionLinkToMemfisWO(fsRootID);
  };

  isAllowSetContentPermissions = ({fsRootID}) => {
    return !isForbiddenAction(FS_ACTION_TYPE__SET_CONTENT_PERMISSIONS_PORTAL, fsRootID);
  };

  isAllowSetContentGroup = ({fsRootID}) => {
    return !isForbiddenAction(FS_ACTION_TYPE__SET_CONTENT_GROUP_PORTAL, fsRootID);
  };

  renderActiveModal() {
    const {storageManager, dispatch} = this.props;
    const {
      fsRoots,
      availableManualMemfisWOs,
      aplusSettings,
      addFileToWoModalState,
      checkedItems,
      currentFolder,
      currentView,
      copyModalData,
      copyToQodModalState,
      foldersInModal,
      ingestOperationTypes,
      instantScanActionResult,
      instantScanObject,
      instantScanning,
      isShowContentPermissionsModal,
      isShowCopyModal,
      isShowCopyToQodModal,
      isShowCreateSymlinkModal,
      isShowEncryptModal,
      isShowMediaInfoModal,
      isShowMoveModal,
      isShowNewFolderModal,
      isShowRenameModal,
      isShowShowRenameAmazonFileModal,
      isShowUnlinkModal,
      isShowUploadToAsperaModal,
      linkInBurbankModalItem,
      LKFSModalState,
      loaders,
      checksumOnlyType,
      md5ChecksumOpening,
      sha1ChecksumOpening,
      md5ChecksumIsGenerating,
      sha1ChecksumIsGenerating,
      checksumIsShowModal,
      captionsIsShowModal,
      openedFoldersInModal,
      renameModalData,
      tempDirectories,
      isShowChecksumReportModal,
      isShowAttachToWoModal,
      isShowIssueScreenshotModal,
      pseIsShowModal,
      metafierIsShowModal,
      //edgePassIsShowModal,
      //screenToQodIsShowModal,
      frameRateIsShowModal,
      alertIsShowModal,
      alertModalType,
      alertModalMessage,
      woSteps,
      woStepsIsShowModal
    } = storageManager;
    if (isShowAttachToWoModal) {
      return <AttachToWoModal/>
    }
    if (isShowChecksumReportModal) {
      return (
        <ChecksumReportModal
          dispatch={dispatch}
          storageManager={storageManager}
          items={checkedItems}
        />
      );
    }
    if (isShowNewFolderModal) {
      return (
        <NewFolderModal
          onMake={this.handleMakeDirectory}
          onClose={this.handleCloseNewFolderModal}
          item={currentFolder}
        />
      );
    }
    if (woStepsIsShowModal) {
      return (
        <WoStepsModal
          dispatch={dispatch}
          storageManager={storageManager}
          woSteps={woSteps}
        />
      );
    }
    if (frameRateIsShowModal) {
      return (
        <FrameRateModal
          frameRate={LKFSModalState.frameRate}
          dbFrameRate={LKFSModalState.dbFrameRate}
          newFrameRate={LKFSModalState.newFrameRate}
          errorType={LKFSModalState.errorType}
          fileFrameRates={LKFSModalState.fileFrameRates}
          isErrorNumberFrameRate={LKFSModalState.isErrorNumberFrameRate}
          dispatch={dispatch}
          storageManager={storageManager}
        />
      );
    }
    if (linkInBurbankModalItem || availableManualMemfisWOs || ingestOperationTypes) {
      return (
        <LinkToMemfisWoAndIngest
          dispatch={dispatch}
          storageManager={storageManager}
          requestedPage="storage"
        />
      );
    }
    if (isShowShowRenameAmazonFileModal) {
      return (
        <RenameAmazonFileModal
          dispatch={dispatch}
          onClose={() => this.handleShowRenameAmazonFile(false)}
          file={checkedItems[0]}
        />
      );
    }
    if (checksumIsShowModal) {
      return (
        <GenerateChecksumModal
          dispatch={dispatch}
          file={checkedItems[0]}
          isGeneratingMD5={md5ChecksumIsGenerating}
          isGeneratingSHA1={sha1ChecksumIsGenerating}
          md5ChecksumOpening={md5ChecksumOpening}
          sha1ChecksumOpening={sha1ChecksumOpening}
          checksumOnlyType={checksumOnlyType}
        />
      );
    }
    if (instantScanActionResult) {
      return (
        <InstantScanModal
          onClose={this.handleCloseInstantScan}
          onInstantScan={this.handleClickInstantScanSubFolder}
          item={instantScanObject}
          data={instantScanActionResult}
          loading={instantScanning}
        />
      );
    }
    if (isShowMediaInfoModal) {
      const {mediaInfo, loaders} = storageManager;
      return (
        <MediaInfoModal
          dispatch={dispatch}
          onClose={this.handleCloseMediaInfoModal}
          loading={!!loaders.loadMediaInfoAction}
          sending={!!loaders.sendingMediaInfoToQOD}
          item={checkedItems[0]}
          mediaInfo={mediaInfo}
        />
      );
    }
    if (isShowUnlinkModal) {
      return (
        <UnlinkModal
          dispatch={dispatch}
          availableFsRoots={storageManager.availableFsRoots}
        />
      );
    }
    if (isShowCreateSymlinkModal) {
      const {loaders} = storageManager;
      return (
        <CreateSymlinkModal
          dispatch={dispatch}
          onClose={this.handleCloseCreateSymlinkModal}
          loading={!!loaders.loadCreateSymlinkAction}
          storageManager={storageManager}
          item={checkedItems[0]}
        />
      );
    }
    if (isShowUploadToAsperaModal) {
      const {checkedItems, faspexAccounts} = storageManager;
      return (
        <UploadToAsperaModal
          dispatch={dispatch}
          items={checkedItems}
          faspexAccounts={faspexAccounts}
          onClose={this.handleCloseUploadToAsperaModal}
        />
      );
    }
    if (isShowCopyModal) {
      return (
        <CopyModal
          dispatch={dispatch}
          copyData={copyModalData}
          item={checkedItems[0]}
          loaders={loaders}
          fsRoots={fsRoots}
          folders={foldersInModal}
          openedFolders={openedFoldersInModal}
          currentView={currentView}
          tempDirectories={tempDirectories}
          currentFolder={currentFolder}
          storageManager={storageManager}
          allowedActions={storageManager.allowedCopyActions}
        />
      );
    }
    if (isShowCopyToQodModal) {
      return (
        <CopyToQodModal
          dispatch={dispatch}
          item={checkedItems[0]}
          copyModalData={copyModalData}
          copyToQodModalState={copyToQodModalState}
          addFileToWoModalState={addFileToWoModalState}
          loaders={loaders}
        />
      );
    }
    if (isShowContentPermissionsModal) {
      const permissionsAllowedTypes = [];
      if (this.isAllowSetContentPermissions(checkedItems[0])) {
        permissionsAllowedTypes.push('permissions');
      }
      if (this.isAllowSetContentGroup(checkedItems[0])) {
        permissionsAllowedTypes.push('group');
      }
      return (
        <ContentPermissionsModal
          dispatch={dispatch}
          permissionTypes={permissionsAllowedTypes}
          item={checkedItems[0]}
        />
      );
    }
    if (isShowMoveModal) {
      return (
        <MoveModal
          dispatch={dispatch}
          allowedActions={storageManager.allowedMoveActions}
          items={checkedItems}
          loaders={loaders}
          fsRoots={fsRoots}
          folders={foldersInModal}
          openedFolders={openedFoldersInModal}
          currentView={currentView}
          tempDirectories={tempDirectories}
          storageManager={storageManager}
        />
      );
    }
    if (isShowRenameModal) {
      const modalProps = {...renameModalData};
      if (!renameModalData) {
        modalProps.item = checkedItems[0];
      }
      return (
        <RenameModal
          dispatch={dispatch}
          onClose={this.handleCloseRenameModal}
          {...modalProps}
        />
      );
    }
    if (aplusSettings) {
      return (
        <AplusSettingsModal
          onSave={this.handleSaveAplusSettings}
          onClose={this.handleCloseAplusSettingsModal}
          data={aplusSettings}
        />
      );
    }
    if (isShowEncryptModal) {
      return (
        <EncryptModal
          dispatch={dispatch}
          item={checkedItems[0]}
          loaders={loaders}
          folders={foldersInModal}
          openedFolders={openedFoldersInModal}
          currentView={currentView}
          tempDirectories={tempDirectories}
          storageManager={storageManager}
        />
      );
    }
    if (LKFSModalState.show) {
      return <LKFSModal/>
    }
    if (addFileToWoModalState.show) {
      return <AddFileToWoModal />
    }
    if (captionsIsShowModal) {
      return (
        <CaptionsModal
          dispatch={dispatch}
          file={checkedItems[0]}
        />
      );
    }
    if (pseIsShowModal && checkedItems && checkedItems[0] && !!checkedItems[0].memfisWoID) {
      return (
        <PseModal
          dispatch={dispatch}
          storageManager={storageManager}
          file={checkedItems[0]}
        />
      );
    }
    if (metafierIsShowModal && checkedItems && checkedItems[0]) {
      return (
        <MetafierModal
          dispatch={dispatch}
          storageManager={storageManager}
          file={checkedItems[0]}
        />
      );
    }
    //if (edgePassIsShowModal && checkedItems && checkedItems[0]) {
    //  return (
    //    <EdgePassModal
    //      dispatch={dispatch}
    //      storageManager={storageManager}
    //      file={checkedItems[0]}
    //    />
    //  );
    //}
    //if (screenToQodIsShowModal && checkedItems && checkedItems[0]) {
    //  return (
    //    <ScreenToQodModal
    //      dispatch={dispatch}
    //      storageManager={storageManager}
    //      file={checkedItems[0]}
    //    />
    //  );
    //}
    if (alertIsShowModal) {
      return (
        <AlertModal
          dispatch={dispatch}
          storageManager={storageManager}
          type={alertModalType}
          message={alertModalMessage}
        />
      );
    }
    if (isShowIssueScreenshotModal) {
      return (
        <IssueScreenshotToQodModal
          dispatch={dispatch}
        />
      )
    }
    if (this.state.modalForDisplay === 'HDR_REPORT') {
      return (
          <HdrReportModal
              onClose={this.closeHdrReportModalClick}
          />
      );
    }
    return null;
  }

  renderUploadBtnMenuItem(contentType) {
    const {actionTypes} = this.props.storageManager;

    if (actionTypes) {
      const {[FS_ACTION_TYPE__UPLOAD_CONTENT]: {allowReadingProperties}} = actionTypes;

      const fileTypes = Object.keys(allowReadingProperties).sort().join(', ');
      const isFolder = contentType === 'Folder';
      const inputProps = {multiple: false, ...(isFolder ? {
        webkitdirectory: '',
        mozdirectory: '',
        msdirectory: '',
        odirectory: '',
        directory: ''
      } : {accept: fileTypes})};
      const hint = isFolder ? 'Please upload a non-empty folder containing no ".zip" files' : `Upload file (${fileTypes})`;
      return (
        <MenuItem eventKey={contentType}>
          <Dropzone onDrop={isFolder ? this.handleUploadFolder : this.handleUploadFile}>
            {({getRootProps, getInputProps}) => (
              <div {...getRootProps({className: 'dropzone', title: hint})}>
                <input {...getInputProps(inputProps)}/>
                <i className={`far fa-${contentType.toLowerCase()}-upload`}/>{`Upload ${contentType}`}
              </div>
            )}
          </Dropzone>
        </MenuItem>
      );
    }
  }

  renderActionBtn(title, onClick, iconClassName, allowAction, forbiddenActionReason, text, items, actionTypeForSpecSymbols) {
    const {dispatch} = this.props;
    return renderActionButton({
      title, onClick, iconClassName, allowAction, forbiddenActionReason, text, items, actionTypeForSpecSymbols, dispatch
    });
  }

  renderBtnMoveMigrate(checkedItems, checkedRoots) {
    const {dispatch} = this.props;

    const actions = {};

    let forbiddenReason;

    const isMultipleMove = checkedItems.length > 1;
    const moveActionType = isMultipleMove ? FS_ACTION_TYPE__MULTIPLE_MOVE : FS_ACTION_TYPE__MOVE;
    if (!isHiddenActionBtn(FS_ACTION_TYPE__MOVE) || !isHiddenActionBtn(FS_ACTION_TYPE__MULTIPLE_MOVE)) {
      // any move
      let allowAction = !isHiddenActionBtn(moveActionType) && checkedItems.length > 0 && !isForbiddenAction(moveActionType, checkedItems[0].fsRootID);
      if (allowAction) {
        if (checkedRoots.length > 1) {
          forbiddenReason = 'Please select a single file system';
        } else if (isMultipleMove && this.isSearchResultsDisplay()) {
          const childrenItems = checkedItems.filter(a => checkedItems.some(b => a.key !== b.key && a.key.startsWith(b.key)));
          if (childrenItems.length) {
            const s = childrenItems.length > 1 ? 's' : '';
            const an = childrenItems.length > 1 ? '' : 'an ';
            forbiddenReason = `Please select ${an}item${s} with no parent folder${s}`;
          }
        }
        allowAction = !forbiddenReason;
      }
      actions[moveActionType] = {allowAction};
    }

    if (!isHiddenActionBtn(FS_ACTION_TYPE__MIGRATE_PORTAL)) {
      // migrate
      const {settings, availableActionsBySources} = this.props.storageManager;
      let maxContentCountToMigrate = DEFAULT_MAX_CONTENT_COUNT_TO_MIGRATE;
      if (
        settings
        && settings.actionSettings
        && settings.actionSettings.values
        && settings.actionSettings.values.maxContentCountToMigrate
      ) {
        maxContentCountToMigrate = settings.actionSettings.values.maxContentCountToMigrate.value
          || DEFAULT_MAX_CONTENT_COUNT_TO_MIGRATE;
      }
      let allowAction = checkedItems.length > 0
        && checkedItems.length <= maxContentCountToMigrate
        && !checkedItems.some((item) => !item.isDirectory)
        && !isForbiddenAction(FS_ACTION_TYPE__MIGRATE_PORTAL, checkedItems[0].fsRootID);
      if (allowAction) {
        const allowedBySource = Object.keys(availableActionsBySources).includes(FS_ACTION_TYPE__MIGRATE_PORTAL);
        if (checkedItems.length > 1) {
          allowAction = allowedBySource;
        } else if (availableActionsBySources._forItem &&
                    availableActionsBySources._forItem.fsRootID === checkedItems[0].fsRootID &&
                    availableActionsBySources._forItem.contentID === checkedItems[0].contentID) {
          allowAction = allowedBySource;
        }
      }

      if (Object.keys(actions).length === 0) {
        // migrate only
        return renderActionButton({
          dispatch,
          title: 'Migrate',
          onClick: () => this.handleClickActionMove(checkedItems, [FS_ACTION_TYPE__MIGRATE_PORTAL]),
          iconClassName: 'far fa-people-carry',
          allowAction,
          items: checkedItems,
          actionTypeForSpecSymbols: FS_ACTION_TYPE__MIGRATE_PORTAL
        });
      }

      actions[FS_ACTION_TYPE__MIGRATE_PORTAL] = {allowAction};
    }

    if (Object.keys(actions).length === 0) {
      return null;
    }

    const allowedActionTypes = Object.keys(actions).filter(t => actions[t].allowAction);
    return renderActionButton({
      dispatch,
      title: 'Move',
      onClick: () => this.handleClickActionMove(checkedItems, allowedActionTypes),
      iconClassName: 'fa-regular fa-fw fa-file-export',
      allowAction: Object.values(actions).some(({allowAction}) => allowAction),
      forbiddenActionReason: forbiddenReason,
      items: checkedItems,
      actionTypeForSpecSymbols: allowedActionTypes.includes(moveActionType) ? moveActionType : allowedActionTypes[0]
    });
  }

  renderBtnCopy(checkedItems) {
    if (isHiddenActionBtn(FS_ACTION_TYPE__SIMPLE_COPY) && isHiddenActionBtn(FS_ACTION_TYPE__PROXY_COPY)) {
      return null;
    }
    const {dispatch} = this.props;
    const allowAction = checkedItems.length === 1 && (
      !isForbiddenAction(FS_ACTION_TYPE__SIMPLE_COPY, checkedItems[0].fsRootID) ||
      !isForbiddenAction(FS_ACTION_TYPE__PROXY_COPY, checkedItems[0].fsRootID)
    );
    return renderActionButton({
      dispatch,
      title: "Copy",
      onClick: () => this.handleClickActionCopy([FS_ACTION_TYPE__SIMPLE_COPY, FS_ACTION_TYPE__COPY]),
      iconClassName: "fa-regular fa-fw fa-copy",
      allowAction,
      items: checkedItems,
      actionTypeForSpecSymbols: FS_ACTION_TYPE__SIMPLE_COPY
    });
  }

  renderBtnRename(checkedItems) {
    if (isHiddenActionBtn(FS_ACTION_TYPE__MOVE)) {
      return null;
    }
    const allowAction = checkedItems.length === 1 && !isForbiddenAction(FS_ACTION_TYPE__MOVE, checkedItems[0].fsRootID);
    return this.renderActionBtn("Rename", this.handleClickActionRename, "fa-regular fa-fw fa-i-cursor", allowAction);
  }

  renderBtnDelete(checkedItems, checkedRoots) {
    if (isHiddenActionBtn(FS_ACTION_TYPE__DELETE_WITH_APPROVAL)) {
      return null;
    }
    let allowAction = checkedItems.length > 0 && !isForbiddenAction(FS_ACTION_TYPE__DELETE_WITH_APPROVAL, checkedItems[0].fsRootID);
    let forbiddenReason;
    if (allowAction) {
      if (checkedRoots.length > 1) {
        forbiddenReason = 'Please select a single file system';
      }
      allowAction = !forbiddenReason;
    }
    return this.renderActionBtn("Delete", this.handleClickDeleteAssets, "fa-regular fa-fw fa-trash-xmark", allowAction, forbiddenReason);
  }

  renderEncryptBtn(checkedItems, isDisabled) {
    const isHiddenDecrypt = isHiddenActionBtn(FS_ACTION_TYPE__DECRYPT_CONTENT);
    const isHiddenEncrypt = isHiddenActionBtn(FS_ACTION_TYPE__ENCRYPT_CONTENT);
    if (isHiddenDecrypt && isHiddenEncrypt) {
      return null;
    }
    const {dispatch} = this.props;
    const actionType = checkedItems.length === 1 && 'isEncrypted' in checkedItems[0] ?
      ((checkedItems[0].isEncrypted || (checkedItems[0].encData != null && checkedItems[0].encData.encryptionFlags === 1) ? FS_ACTION_TYPE__DECRYPT_CONTENT : FS_ACTION_TYPE__ENCRYPT_CONTENT)) : null;
    const allowAction = !isDisabled && !!actionType && !isForbiddenAction(actionType, checkedItems[0].fsRootID);
    const onClick = () => dispatch(showEncryptModal());
    if (actionType === FS_ACTION_TYPE__DECRYPT_CONTENT) {
      return !isHiddenDecrypt && this.renderActionBtn("Decrypt", onClick, "fa-regular fa-fw fa-lock-keyhole-open", allowAction);
    }
    return !isHiddenEncrypt && this.renderActionBtn("Encrypt", onClick, "fa-regular fa-fw fa-lock-keyhole", allowAction);
  }

  renderBtnSetContentPermissions(checkedItems) {
    if (isHiddenActionBtn(FS_ACTION_TYPE__SET_CONTENT_PERMISSIONS_PORTAL) && isHiddenActionBtn(FS_ACTION_TYPE__SET_CONTENT_GROUP_PORTAL)) {
      return null;
    }
    const allowSetPermissions = checkedItems.length === 1 && this.isAllowSetContentPermissions(checkedItems[0]);
    const allowSetGroup = checkedItems.length === 1 && this.isAllowSetContentGroup(checkedItems[0]);
    const allowAction = allowSetPermissions || allowSetGroup;
    return this.renderActionBtn(
      allowAction ? `Set ${allowSetPermissions ? `permissions${allowSetGroup ? ' and ' : ''}` : ''}${allowSetGroup ? 'group' : ''}` : 'Permissions and group',
      this.handleShowContentPermissionsModal,
      "fa-regular fa-fw fa-user-lock",
      allowAction
    );
  }

  isSelectedSingleFile = checkedItems => checkedItems.length === 1 && !checkedItems[0].isDirectory && (checkedItems[0].contentID || 0) > 0;
  isSelectedSingleFileOrFolder = checkedItems => checkedItems.length === 1 && (checkedItems[0].contentID || 0) > 0;

  renderLinkToMemfisWoButtons = (checkedItems, isInitConfirmDeletion, isCheckedRoots, isCheckedDeletedItems) => {
    const isHiddenLinkBtn = isHiddenActionBtn(FS_ACTION_TYPE__LINK_TO_MEMFIS_WO);
    const isHiddenAddBtn = isHiddenActionBtn(FS_ACTION_TYPE__ADD_TO_MEMFIS_WO);
    if (isHiddenLinkBtn && isHiddenAddBtn) {
      return null;
    }

    const {storageManager: {availableManualMemfisWOsIsLoading, availableActionsBySources, enableLinkToWO, enableAddToWO, fsRoots}} = this.props;

    const itemsToLinkOrAdd = (isInitConfirmDeletion || isCheckedRoots || isCheckedDeletedItems) ? [] : checkedItems;
    const isSelectedSingleItem = this.isSelectedSingleFileOrFolder(itemsToLinkOrAdd);
    const isLinkedItem = isSelectedSingleItem && !!checkedItems[0].memfisWoID;
    const isAllowUnlinkOrIngest = isLinkedItem && !this.isForbiddenUnlinkOrIngest(checkedItems[0]);

    const linkBtn = !isHiddenLinkBtn && this.renderActionBtn("Link to Work Order", this.handleClickLinkToMemfisWO, "fa-regular fa-fw fa-link",
      enableLinkToWO && isSelectedSingleItem && !isLinkedItem && !availableManualMemfisWOsIsLoading && !this.isForbiddenActionLinkToMemfisWO(itemsToLinkOrAdd[0].fsRootID),
      null, null, checkedItems, FS_ACTION_TYPE__LINK_TO_MEMFIS_WO
    );

    const unlinkBtn = !isHiddenLinkBtn && this.renderActionBtn("Unlink Work Order", this.handleClickUnlinkMemfisWO, "fa-regular fa-fw fa-unlink",
      isAllowUnlinkOrIngest,
      null, null, checkedItems, FS_ACTION_TYPE__LINK_TO_MEMFIS_WO
    );

    let fileIsNearline = checkedItems.length === 1 && !checkedItems[0].isDirectory && isNearlineDrive(checkedItems[0], fsRoots);
    const addBtn = !isHiddenAddBtn && !fileIsNearline && this.renderActionBtn("Add to Work Order", this.handleClickAddToWO, "fa-regular fa-fw fa-layer-plus",
      enableAddToWO && checkedItems.length > 1 ? Object.keys(availableActionsBySources).includes(FS_ACTION_TYPE__ADD_TO_MEMFIS_WO) :
        enableAddToWO && isSelectedSingleItem && isPermittedByRootsPermissions(FS_ACTION_TYPE__ADD_TO_MEMFIS_WO, itemsToLinkOrAdd && itemsToLinkOrAdd.length > 0 ? itemsToLinkOrAdd[0].fsRootID : 0),
      null, null, checkedItems, FS_ACTION_TYPE__ADD_TO_MEMFIS_WO
    );

    const ingestBtn = !isHiddenLinkBtn && this.renderActionBtn("Ingest", this.handleClickIngest, "fa-regular fa-fw fa-gears",
      isAllowUnlinkOrIngest && !!checkedItems[0].needToIngest,
      null, null, checkedItems, FS_ACTION_TYPE__LINK_TO_MEMFIS_WO
    );

    return (
      <>
        {linkBtn}
        {addBtn}
        {ingestBtn}
        {unlinkBtn}
      </>
    );
  };

  renderBtnEnabledForSingleFile = (checkedItems, btnProps) => {
    const {type, title, iconClassName, onClick} = btnProps;
    if (isHiddenActionBtn(type)) {
      return null;
    }
    const allowAction = this.isSelectedSingleFile(checkedItems) && !isForbiddenAction(type, checkedItems[0].fsRootID);
    return this.renderActionBtn(title, onClick, iconClassName, allowAction);
  };

  renderBtnUploadToAspera = (checkedItems) => {
    if (isHiddenActionBtn(FS_ACTION_TYPE__UPLOAD_TO_ASPERA)) {
      return null;
    }
    const isCheckedRoots = checkedItems.some(isRootItem);
    const allowAction = checkedItems.length > 0 && !isCheckedRoots && !isForbiddenAction(FS_ACTION_TYPE__UPLOAD_TO_ASPERA, checkedItems[0].fsRootID);
    return this.renderActionBtn("Upload to Aspera", this.handleClickActionUploadToAspera, "fa-regular fa-fw fa-cloud-plus", allowAction);
  }

  renderCreateSymlinkButton(checkedItems, isInitConfirmDeletion) {
    const {dispatch} = this.props;
    return !isHiddenActionBtn(FS_ACTION_TYPE__CREATE_SYMLINK_PORTAL) && (
      <Button
        bsStyle="default"
        title="Create Symlink"
        onClick={() => dispatch(showCreateSymlinkModal(true))}
        disabled={
          isInitConfirmDeletion ||
          !(checkedItems.length === 1/* && checkedItems[0].isDirectory*/ && !isRootItem(checkedItems[0]))
        }
      >
        <i className="fa-regular fa-fw fa-link-simple"/>
      </Button>
    );
  }

  render() {
    const {dispatch, storageManager, lockStatusesHeight} = this.props;
    const {
      currentFolder, checkedItems, zipFileSetting, actionsRequestorsMap, fsRoots
    } = storageManager;
    const meRequestor = Object.entries(actionsRequestorsMap || {}).find(([k, v]) => v === 'me');
    const checkedRoots = _.uniq(checkedItems.map(item => item.fsRootID));
    const isCheckedDeletedItems = checkedItems.some(isDeletedStatus);
    const isCheckedRoots = checkedItems.some(isRootItem);
    const isInitConfirmDeletion = this.isInitConfirmDeletionDisplay();
    const itemsForDeleteAction = (isCheckedRoots || isCheckedDeletedItems) ? [] : checkedItems;
    const itemsForMostActions = isInitConfirmDeletion ? [] : itemsForDeleteAction;
    return (
      <StyledActionbar top={lockStatusesHeight}>
        {this.renderActiveModal()}
        <div className="container">
          <div>
            <ButtonToolbar className="col-actions">
              <ButtonGroup>
                {!isHiddenActionBtn(FS_ACTION_TYPE__MK_DIRECTORY) && <Button
                  title="New Directory"
                  disabled={
                    isInitConfirmDeletion ||
                    !currentFolder ||
                    !!checkedItems.length ||
                    isForbiddenAction(FS_ACTION_TYPE__MK_DIRECTORY, currentFolder.fsRootID) ||
                    isCheckedRoots
                  }
                  onClick={this.handleShowNewFolderModal}
                  >
                  <i className="fa-light fa-folder-plus"/>
                </Button>}
                {this.renderBtnCopy(itemsForMostActions)}
                {this.renderBtnMoveMigrate(itemsForMostActions, checkedRoots)}
                {this.renderBtnRename(itemsForMostActions)}
              </ButtonGroup>

              <ButtonGroup>
                {LKFSButton({dispatch, storageManager, items: checkedItems})}
                {PSEButton({dispatch, storageManager, items: checkedItems})}
                {GenerateChecksumButtons({dispatch, storageManager, items: checkedItems})}
                {this.renderBtnEnabledForSingleFile(itemsForMostActions, {type: FS_ACTION_TYPE__MOVE, title: 'Rename for Amazon', iconClassName: 'fab fa-fw fa-amazon', onClick: () => this.handleShowRenameAmazonFile(true)})}
                {CaptionsButton({dispatch, storageManager, items: checkedItems})}
                {EdgePassButton({dispatch, storageManager, items: checkedItems})}
                {ScreenToQodButton({dispatch, storageManager, items: checkedItems})}
                <HdrReportButton
                    storageManager={storageManager}
                    items={checkedItems}
                    onClick={this.openHdrReportClick}
                />
              </ButtonGroup>

              <ButtonGroup>
                {this.renderLinkToMemfisWoButtons(checkedItems, isInitConfirmDeletion, isCheckedRoots, isCheckedDeletedItems)}
                {this.renderCreateSymlinkButton(checkedItems, isInitConfirmDeletion)}
              </ButtonGroup>

              <ButtonGroup>
                {DownloadButton({dispatch, storageManager, items: checkedItems})}
                {!isHiddenActionBtn(FS_ACTION_TYPE__ZIP_CONTENT) && <DropdownButton
                  id="dropdown-upload-button"
                  title={<i className="fa-regular fa-fw fa-upload" title="Upload"/>}
                  disabled={
                    isInitConfirmDeletion ||
                    !zipFileSetting ||
                    !currentFolder ||
                    isForbiddenAction(FS_ACTION_TYPE__ZIP_CONTENT, currentFolder.fsRootID) ||
                    checkedItems.length > 0 ||
                    !isRegularDrive(currentFolder, fsRoots)
                  }
                >
                  {this.renderUploadBtnMenuItem('File')}
                  {this.renderUploadBtnMenuItem('Folder')}
                </DropdownButton>}
                {this.renderEncryptBtn(checkedItems, isInitConfirmDeletion || isCheckedDeletedItems || isCheckedRoots)}
                {this.renderBtnSetContentPermissions(itemsForMostActions)}
                {MediaInfoButton({dispatch, storageManager, items: checkedItems})}
                {CopyToQodButton({dispatch, storageManager, items: checkedItems})}
                {AttachToWoButton({dispatch, storageManager, items: checkedItems})}
                {MetafierButton({dispatch, storageManager, items: checkedItems})}
              </ButtonGroup>

              <ButtonGroup>
                {this.renderBtnDelete(itemsForDeleteAction, checkedRoots)}
                {this.renderBtnUploadToAspera(checkedItems)}
                {!isHiddenActionBtn(FS_ACTION_TYPE__GET_APLUS_SETTINGS) && <Button
                  title="Auto-shutdown Settings"
                  disabled={
                    isInitConfirmDeletion ||
                    !!checkedItems.length ||
                    isForbiddenAction(FS_ACTION_TYPE__GET_APLUS_SETTINGS, currentFolder ? currentFolder.fsRootID :
                      (checkedItems.length === 1 && isCheckedRoots) ? checkedItems[0].fsRootID : null)
                  }
                  onClick={this.handleGetAplusSettings}
                >
                  <i className="fa-regular fa-fw fa-power-off"/>
                </Button>}
              </ButtonGroup>
            </ButtonToolbar>

            <div className="col-actions-history">
              {!!meRequestor &&
                <div className="own-actions-btn">
                  <a
                    href={`/storage?own_actions=${meRequestor[0]}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    My Actions
                  </a>
                </div>
              }
              <label className="action-history-label">
                Actions History
              </label>
              <ActionsHistoryToggle onChange={this.handleClickActionsHistory} />
            </div>
          </div>
        </div>
      </StyledActionbar>
    );
  }
}

const StyledActionbar = styled.div`
  position: fixed;
  top: ${props => `${props.top + 121}px`};
  right: 0;
  left: 0;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  z-index: 1020;

  @media (max-width: 767px) {
    top: ${props => `${props.top + 181}px`};
  }

  .container > div {
    display: flex;
    justify-content: space-between;
  }

  .col-actions {
    &.btn-toolbar {
      margin-left: -10px;
      > .btn, > .btn-group, > .input-group {
        margin-left: 10px;
      }
    }

    #dropdown-upload-button {

      i {
        padding-right: 5px;
      }

      & + .dropdown-menu {
        min-width: 150px;

        > li > a {
          padding: 0;

          .dropzone {
            padding: 3px 20px;
          }

          i {
            margin-right: 5px;
            min-width: 14px;
            &.fa-file-upload {
              padding-left: 1px;
            }
          }
        }
      }
    }
  }

  .col-actions-history {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    .action-history-label {
      font-size: 12px;
      margin: 0;
      padding: 0 10px;
    }
    .own-actions-btn {
      margin-right: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      a {
        padding: 2px;
        border-radius: 3px;
        font-size: 12px;
        font-weight: bold;
        white-space: nowrap;
        text-decoration: none !important;
        &:hover {
          background-color: #f5f5f5;
          box-shadow: -5px -3px 0 #f5f5f5, 5px -3px 0 0 #f5f5f5, -5px 3px 0 #f5f5f5, 5px 3px 0 0 #f5f5f5;
        }
      }
    }
  }
`;

Actionbar.propsTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  storageManager: PropTypes.object.isRequired,
};

export default Actionbar;
