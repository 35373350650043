import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {Button} from 'react-bootstrap';

export const HierarchyCell = ({value, row, className, onClickUp, OnClickDown}) => {
  return (
    <StyledCell
      className={className}
      paddingLeft={row.absDepth > 0 ? row.absDepth * 16 + 8 : undefined}
      >
      <div>
        {
          row.parentActionID && row.depth <= 0?
            <Button
              bsStyle={row.isExpandedToUp ? 'default' : 'primary'}
              onClick={() => onClickUp(row)} title={`${row.isExpandedToUp ? 'Hide' : 'Show'} parent action`}
              >
              <i className={`fa fa-angle-double-${!row.isExpandedToUp ? 'up' : 'left'}`}/>
            </Button> : null
        }
        {
          row.hasChildren && row.depth >= 0 ?
            <Button
              bsStyle={row.isExpandedToDown ? 'default' : 'primary'}
              onClick={() => OnClickDown(row)} title={`${row.isExpandedToDown ? 'Hide' : 'Show'} child action(s)`}
              >
              <i className={`fa fa-angle-double-${row.isExpandedToDown ? 'down' : 'right'}`}/>
            </Button> : null
        }
      </div>
    </StyledCell>
  );
};

const StyledCell = styled.td`
  ${props => props.paddingLeft && css`
    padding-left: ${props.paddingLeft}px !important;
  `}
  ${props => props.paddingLeft && css`
    width: ${props.paddingLeft + 24 + 8}px !important;
  `}

  div {
    display: flex;
    flex-direction: column;
  }

  button {
    padding: 1px 0;
    width: 24px;
    & + button {
      margin-top: 5px;
    }
  }
`;

HierarchyCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object,
  className: PropTypes.string,
  onClickUp: PropTypes.func,
  OnClickDown: PropTypes.func
};
