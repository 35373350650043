import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

export const PaddingCell = ({value, row, className}) => {
  return (
    <StyledCell
      className={className}
      paddingLeft={row.absDepth > 0 ? row.absDepth * 16 + 8 : undefined}
      >
      {value}
    </StyledCell>
  );
};

const StyledCell = styled.td`
  ${props => props.paddingLeft && css`
    padding-left: ${props.paddingLeft}px !important;
  `}
`;

PaddingCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object,
  className: PropTypes.string
};
