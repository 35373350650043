import moment from 'moment';
import 'moment-timezone';
import store from './store';

export const SIZE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
export const SIZE_UNITS_FILTER = ['KB', 'MB', 'GB'];

export const regexDelimeter = /([\\/])/g;
export const PARENT_DIR_REGEX_FOR_REPLACE = /[\\/]([^\\/]*)$/;

export const splitPathOnParentAndName = path => {
  const splitted = path.split(regexDelimeter);
  const splitIndex = path.endsWith('/') ? 3 : 1;
  const parent = splitted.slice(0, splitted.length - splitIndex).join('');
  const name = splitted.slice(splitted.length - splitIndex).join('');
  return {parent, name};
};

const getFloatPrecision = (floatValue, precision) => {
  floatValue = parseFloat(floatValue);
  if (isNaN(floatValue)) {
    return parseFloat('0').toFixed(precision);
  } else {
    const power = Math.pow(10, precision);
    floatValue = (Math.round(floatValue * power) / power).toFixed(precision);
    return floatValue.toString();
  }
};

export const prepareFileSize = size => {
  if (typeof size === 'undefined') {
     return '-';
  }
  if (size > 1024) {
    const kbSize = size / 1024;
    if (kbSize > 1024) {
      let mbSize = kbSize / 1024;
      if (mbSize > 1024) {
        let gbSize = mbSize / 1024;
        if (gbSize > 1024) {
          let tbSize = gbSize / 1024;
          return `${getFloatPrecision(tbSize, 2)} TB`;
        }
        return `${getFloatPrecision(gbSize, 2)} GB`;
      }
      return `${getFloatPrecision(mbSize, 2)} MB`;
    }
    return `${Math.round(kbSize)} kB`;
  }
  return `${size} B`;
};

export const formatBytes = (bytes, defaultValue) => {
  if (typeof bytes === 'undefined') {
     return defaultValue || '';
  }

  let index;
  for (index = 0; index < SIZE_UNITS.length; index++) {
    if (bytes < Math.pow(2, 10 * (index + 1))) {
      break;
    }
  }
  return `${(bytes / Math.pow(2, 10 * index)).toFixed(2)} ${SIZE_UNITS[index]}`.replace(/[.]00 /,' ');
};

export const convertSizeToBytes = (size, unit, minOrMax) => {
  if (typeof size !== 'undefined' && unit) {
    const number = Number(size);
    if (!isNaN(number)) {
      let unitIndex = SIZE_UNITS.indexOf(unit);
      if (unitIndex < 0) {
        unitIndex = 0;
      }
      const value = Math.pow(2, 10 * unitIndex) * number;
      return minOrMax === 'min' ? Math.floor(value) : Math.ceil(value);
    }
  }
};

export const parseSize = (size, minOrMax) => {
  const temp = /^([0-9]+)(KB|MB|GB)?$/.exec(size.replace(/[,.]/g, '').replace(/ /g, '').toUpperCase());
  if (temp && temp.length > 2) {
    const number = Number(temp[1]);
    if (!isNaN(number)) {
      let unitIndex = SIZE_UNITS_FILTER.indexOf(temp[2]);
      if (unitIndex < 0) {
        unitIndex = 0;
      }
      const value = Math.pow(2, 10 * unitIndex) * number;
      const isMinValue = ['min', 'from'].includes(minOrMax);
      const result = isMinValue ? Math.floor(value) : Math.ceil(value);
      return {valueInBytes: result, value: number, unit: SIZE_UNITS_FILTER[unitIndex], unitIndex, isMinValue};
    }
  }
  return null;
};

export const getFileAbsPath = file => {
  if (!file) {
    return '';
  }

  if (!file.isDirectory) {
    return file.absolutePath;
  }

  let absPath = (file.relativeKey || file.key || '').replace(/[\\/]+$/, '').replace(/[\\/]/g, '\\');
  if (file.isRoot) {
    absPath = absPath.replace(/[:]$/, ':\\');
  }
  return absPath;
};

export const compareRows = (firstRow, secondRow, field, direction, altFields = {}, altFieldsGet = {}) => {
  if (!firstRow || !secondRow) {
    return checkRows(firstRow, secondRow, direction);
  }

  let a = getAltFieldValue(firstRow, altFields, field, altFieldsGet);
  let b = getAltFieldValue(secondRow, altFields, field, altFieldsGet);

  if (direction === 'desc') {
    [a, b] = [b, a];
  }

  if (typeof a === 'number') {
    return sortByNumber(a, b)
  }

  if (typeof a === 'boolean') {
    return sortByBoolean(a, b);
  }

  return sortByString(a, b);
};

export const checkRows = (firstRow, secondRow, direction) => {
  const isDesc = direction === 'desc';

  if (!firstRow && !secondRow) {
    return 0;
  } else if (!firstRow) {
    return isDesc ? -1 : 1;
  } else if (!secondRow) {
    return isDesc ? 1 : -1;
  }
};

export const getAltFieldValue = (target, altFields, field, altFieldsGet) => {
  if (altFieldsGet && typeof altFieldsGet[field] === 'function') {
    return altFieldsGet[field](target[field], target);
  }
  return target[altFields[field] || field];
};

export const sortByNumber = (a, b) => a - b;

export const sortByString = (a, b) => (""+(a || "")).localeCompare(""+(b || ""));

export const sortByBoolean = (a, b) => {
  if (a === b) {
    return 0;
  } else if (a) {
    return -1;
  }
  return 1;
};

export const getSortedRowsBy = (rows, field, direction, altFields, altFieldsGet) => {
  if (!field) {
    return rows;
  }

  const newRows = rows.slice();
  newRows.sort((a, b) => compareRows(a, b, field, direction, altFields, altFieldsGet));

  if (field === 'name') {
    const field = 'isDirectory';
    const direction = 'asc';
    newRows.sort((a, b) => compareRows(a, b, field, direction));
  }

  return newRows;
};

export const formatDate = (date, fromFormat, toFormat, defaultValue) => {
  if (date && date !== '') {
    return moment(date, [(fromFormat || ''), moment.ISO_8601], false).format(toFormat || 'YYYY-MM-DD');
  }
  return date || defaultValue || '';
};

export const formatXDate = (date, defaultValue) => {
  if (date && date !== '') {
    return moment(date, ['', moment.ISO_8601], false).format('x');
  }
  return defaultValue || null;
};

export const formatDateFromUtcSeconds = (dateInSeconds, toFormat, defaultValue) => {
  if (dateInSeconds) {
    const timezone = moment.tz.guess();
    if (timezone) {
      return moment(dateInSeconds * 1000, 'x').tz(timezone).format(toFormat || 'YYYY-MM-DD HH:mm:ss');
    }
    return moment(dateInSeconds * 1000, 'x').format(toFormat || 'YYYY-MM-DD HH:mm:ss');
  }
  return defaultValue || '';
};

export const getCurrentDomainObj = () => {
  if (process.env.NODE_ENV === 'production') {
    const split = window.location.host.split('.');
    return {domain: `${split[split.length - 2]}.${split[split.length - 1]}`};
  }
  return {};
};

export const getCurrentPageToSave = () => {
  const {pathname, search, hash} = window.location;
  const preparedSearch = pathname.indexOf('download') !== -1 ? `${search}${search.startsWith('?') ? '&' : '?'}rsf=1` : search;
  return `${pathname}${preparedSearch}${hash}`;
};

export const buildRedirectUrlToLoginPage = () => {
  const curPage = getCurrentPageToSave();
  if (window.location.pathname === '/login') {
    return curPage;
  }
  const redirectParam = curPage.length > 1 ? `?r=${encodeURIComponent(curPage)}` : '';
  return `/login${redirectParam}`;
};

export const redirectToLoginPage = () => {
  if (window.location.pathname !== '/login') {
    window.location.href = buildRedirectUrlToLoginPage();
  }
};

export const getErrorMessage = error => {
  if (!error) {
    return null;
  }

  if (typeof error === 'string') {
    return error;
  }

  if (error.responseJSON) {
    const errorText = Array.isArray(error.responseJSON.errors) && error.responseJSON.errors.length ?
      ` (${error.responseJSON.errors[0].message})` : undefined;
    return `${error.responseJSON.message}${errorText !== undefined ? errorText : ''}`;
  }

  if (error.statusText && error.statusText !== 'error') {
    return error.statusText;
  }

  return 'Something went wrong';
};

export const isForbiddenActionByUserPermissions = (actionsUserPermissions, actionType) => {
  return !actionsUserPermissions.includes(actionType);
};

export const isForbiddenByPermissionsWithRoots = (permissions, actionType, fsRootID) => {
  if (!fsRootID) {
    return true;
  }
  const roots = permissions[actionType];
  if (!roots || !Array.isArray(roots.availableFsRootIds)) {
    return true;
  }
  if (roots.availableFsRootIds.length) {
    return !roots.availableFsRootIds.includes(fsRootID);
  }
  return false;
};

export const isForbiddenActionByPermissions = (actionType, fsRootID, allPermissions) => {
  const {actionsUserPermissions, actionsUserPermissionsWithRoots} = allPermissions;
  return isForbiddenActionByUserPermissions(actionsUserPermissions, actionType) &&
    isForbiddenByPermissionsWithRoots(actionsUserPermissionsWithRoots, actionType, fsRootID);
};

export const isValidFileName = name => {
  return /^(?=[\S])[^\\/:*?"<>| ]+$/.test(name);
};

export const isAsciiString = text => {
  return /^[\x00-\x7F]+$/g.test(text);
}

export const isNetflixClient = clientName => {
  return (clientName || '').toUpperCase() === 'NETFLIX';
};

export const isPermittedByRootsPermissions = (action, fsRootID) => {
  const {actionsUserPermissionsWithRoots} = store.getState().storageManager;
  const thisActionPermissions = actionsUserPermissionsWithRoots[action];
  if (thisActionPermissions && Array.isArray(thisActionPermissions.availableFsRootIds)) {
    const {availableFsRootIds} = thisActionPermissions;
    if (availableFsRootIds.length) {
      return availableFsRootIds.includes(fsRootID);
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const isForbiddenByContentTypesFlag = (action) => {
  const {actionsUserPermissionsWithRoots, checkedItems} = store.getState().storageManager;
  const hasCheckedSomeSymlinks = checkedItems.some((item) => item.isSymlink);
  const actionPermissions = actionsUserPermissionsWithRoots[action];
  if (hasCheckedSomeSymlinks && actionPermissions) {
    switch (actionPermissions.contentTypesFlag) {
      case 0:
        return true;
      case 1:
      default:
        return false;
    }
  } else {
    return false;
  }
};

export const isNoConnectedDrive = (DriveLabelAlias, AvailableDrives) => {
  return !DriveLabelAlias || !Array.isArray(AvailableDrives) || !AvailableDrives.find(d => d.DriveLabelAlias === DriveLabelAlias);
};

export const isReadOnlyFsRootWithoutConnectedDrive = (item, fsRootLockStatuses) => {
  if (/*isRootItem(item) && */fsRootLockStatuses) {
    const lockStatusOfRoot = fsRootLockStatuses.find(r => r.FSRootID === item.fsRootID);
    if (lockStatusOfRoot) {
      const {ReadOnly, DriveLabelAlias, AvailableDrives} = lockStatusOfRoot;
      return !!ReadOnly && isNoConnectedDrive(DriveLabelAlias, AvailableDrives);
    }
  }
  return false;
};

export const isForbiddenAction = (actionType, fsRootID) => {
  const storageManager = store.getState().storageManager;
  const {lockAndServicesStatuses} = storageManager;
  const {FsRootLockStatuses} = lockAndServicesStatuses;
  const isForbidden = isForbiddenByContentTypesFlag(actionType)
    || isForbiddenActionByPermissions(actionType, fsRootID, storageManager)
    || isReadOnlyFsRootWithoutConnectedDrive({fsRootID}, FsRootLockStatuses);
  return isForbidden;
};

export const isHiddenActionBtn = actionType => {
  const {actionsUserPermissionsWithRoots} = store.getState().storageManager;
  return !actionsUserPermissionsWithRoots[actionType];
};

export const isFsRootItem = item => {
  const {isRoot, fsRootID, contentID} = item;
  if (isRoot) {
    return true;
  }
  const {fsRoots} = store.getState().storageManager;
  return (fsRoots || []).some(r => r.fsRootID === fsRootID && r.contentID === contentID);
};

const ACTION_TYPE_FLAG__SCHEDULABLE = 1<<7;
export const isSchedulableActionType = actionType => {
  const {actionsUserPermissionsWithRoots} = store.getState().storageManager;
  const {actionFlags} = actionsUserPermissionsWithRoots[actionType] || {};
  return ((actionFlags || 0) & ACTION_TYPE_FLAG__SCHEDULABLE) === ACTION_TYPE_FLAG__SCHEDULABLE;
};

const ACTION_TYPE_FLAG__PAUSABLE = 1<<8;
export const isPausableActionType = actionType => {
  const {actionsUserPermissionsWithRoots} = store.getState().storageManager;
  const {actionFlags} = actionsUserPermissionsWithRoots[actionType] || {};
  return ((actionFlags || 0) & ACTION_TYPE_FLAG__PAUSABLE) === ACTION_TYPE_FLAG__PAUSABLE;
};
