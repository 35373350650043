import React, {useRef} from 'react';
import {StyledFileItem} from './styledStep';
import MiddleEllipsis from 'react-middle-ellipsis';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

const FileItem = ({fileName, rootFileName, className}) => {
  const name = fileName.replace(rootFileName, '').replace(/^([\\/])?/, '');
  const itemRef = useRef(null);
  return (
    <StyledFileItem ref={itemRef} className={`file-item${className ? ` ${className}` : ''}`}>
      <OverlayTrigger
        placement="bottom"
        container={(itemRef || {}).current || undefined}
        overlay={<Tooltip id="delivery-full-filename-tooltip">{fileName}</Tooltip>}
      >
        <div className="ellipse-value">
          <MiddleEllipsis>
            <span data-original={name}>{name}</span>
          </MiddleEllipsis>
        </div>
      </OverlayTrigger>
    </StyledFileItem>
  );
};

export default FileItem;