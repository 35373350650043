/* eslint no-native-reassign: "off" */
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import 'react-nprogress/nprogress.css';
import 'react-select/dist/react-select.css';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/datepicker.css';
import 'react-bootstrap-datetimepicker/css/bootstrap-datetimepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-confirm-alert/src/react-confirm-alert.css'

import './assets/fonts/fontawesome/css/all.css'

import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {createBrowserHistory} from 'history';
import {syncHistoryWithStore} from 'react-router-redux';
import Cookies from 'js-cookie';
import store from './app/store';
import {getCurrentDomainObj, redirectToLoginPage} from './app/utils';
import {Router, Switch, Route} from 'react-router-dom';
import PrivateRoute from './app/components/PrivateRoute';
import LoginPage from './app/pages/Login/container';
import StorageManagerPage from './app/pages/StorageManager/container';
import AsperaClientsPage from './app/pages/AsperaClients/container';
import DownloadManagerPage from './app/pages/DownloadManager/container';
import NotificationsPage from './app/pages/Notifications/container';
import AssetDeletionPage from './app/pages/AssetDeletion/container';
import DeliveryManagerPage from './app/pages/DeliveryManager/container';
import IssueBrowserPage from './app/pages/IssueBrowser/container';
import {DELIVERY_PAGE__EDIT, DELIVERY_PAGE__APPROVE, DELIVERY_STEP} from './app/pages/DeliveryManager/constants';
import GlobalStyles from './indexStyled';
import $ from 'jquery';
import {setAutoFreeze} from 'immer';
import * as NProgress from 'react-nprogress';

/** There is some existing code that mutates props or state in reducers,
 * which is not recommended but NOT enforced by Redux.
 * It will break with Immer because it returns immutable states by default.
 * Read more: https://github.com/immerjs/immer/issues/289#issuecomment-453786739
 * Should really rewrite existing mutable code and then change this line to `setAutoFreeze(true)` */
setAutoFreeze(false);

if (process.env.NODE_ENV === 'production') {
  console.warn = () => {};
  console.error = () => {};
}

$(document).ajaxStart((t) => {
  if (window.location.pathname !== '/storage') {
    NProgress.start();
  }
});

$(document).ajaxStop(() => {
  NProgress.done();
});

$.ajaxSetup({
  headers: {
    Authorization: Cookies.get('__auth', getCurrentDomainObj())
  },
  statusCode: {
    401: () => redirectToLoginPage()
  }
});

const history = syncHistoryWithStore(createBrowserHistory(), store);

render(
  <Provider store={store}>
    <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
      <Switch>
        <Route
          exact
          path="/login"
          component={LoginPage}
        />

        <PrivateRoute
          exact
          path="/"
          component={DownloadManagerPage}
        />
        <PrivateRoute
          exact
          path="/download"
          component={DownloadManagerPage}
        />
        <PrivateRoute
          exact
          path="/storage"
          component={StorageManagerPage}
        />
        <PrivateRoute
          exact
          path="/faspex"
          component={AsperaClientsPage}
        />
        <PrivateRoute
          exact
          path="/notifications/:asperaLinkId"
          component={NotificationsPage}
        />
        <PrivateRoute
          exact
          path="/asset_deletion/:assetDeletionId"
          component={AssetDeletionPage}
        />
        <PrivateRoute
          exact
          path={`/delivery/:operationType(${DELIVERY_PAGE__EDIT}|${DELIVERY_PAGE__APPROVE})/:actionId(\\d+)/:step(${DELIVERY_STEP.SELECTING_FILES}|${DELIVERY_STEP.RENAMING_FILES})?`}
          component={DeliveryManagerPage}
        />
        <PrivateRoute
          exact
          path="/issues"
          component={IssueBrowserPage}
        />
      </Switch>
      <GlobalStyles/>
    </Router>
  </Provider>,
  document.getElementById('root')
);
