import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {isNetflixClient} from '../../../../../utils';
import {isEqualsItems} from '../../../utils';
import {confirmAlert} from 'react-confirm-alert';

class PasswordInfo extends Component {
  state = {
    editMode: false
  };

  openEditMode = () => {
    this.setState({editMode: true});
  };

  closeEditMode = () => {
    this.setState({editMode: false});
  };

  componentDidUpdate(prevProps) {
    const {link} = this.props;
    if (!isEqualsItems(prevProps.link, link)) {
      this.closeEditMode();
    }
  }

  savePassword = (newPassword, isReset) => {
    const {link, onChange} = this.props;
    const preparedPassword = newPassword.trim();
    let message = `Are you sure you want to ${isReset ? 'reset password' : 'save changes of password'}?`;
    if (preparedPassword === '' && !isReset) {
      message += `  The password will be reset because the entered string ${newPassword === '' ? 'is empty' : 'contains only spaces'}.`;
    }
    confirmAlert({
      title: 'Are You Sure?',
      message,
      buttons: [{
        label: 'No',
      }, {
        label: 'Yes',
        onClick: () => onChange(link, preparedPassword, this.closeEditMode)
      }]
    });
  };

  setNewPassword = () => {
    this.savePassword(this.inputRef.value || '');
  };

  resetPassword = () => {
    this.savePassword('', true);
  };

  render() {
    const {password, is_encrypted, client_name} = this.props.link;

    if (!password && !is_encrypted) {
      return (
        <StyledCell className="password">
          <span>Not Required</span>
        </StyledCell>
      );
    }

    if (isNetflixClient(client_name)) {
      return (
        <StyledCell className="password">
          <span className="red password">No Password</span>
        </StyledCell>
      );
    }

    const {editMode} = this.state;
    return (
      <StyledCell className="password">
        {
          !editMode ?
            <span
              className={`edit password${password ? '' : ' red'}`}
              onClick={this.openEditMode}
              title={`Click to ${password ? 'change' : 'enter'}`}
            >
              {password ? '*****' : 'Enter Password'}
            </span> :
              <>
                <input
                  type="password"
                  maxLength="100"
                  defaultValue={password || ''}
                  placeholder="Enter Password"
                  ref={input => this.inputRef = input}
                />
                <div>
                  <span className="edit" onClick={this.setNewPassword}>ok</span>
                  <span className="edit" onClick={this.closeEditMode}>cancel</span>
                  <span className="edit" onClick={this.resetPassword}>reset</span>
                </div>
              </>
        }
      </StyledCell>
    );
  }
}

const StyledCell = styled.div`
  span {
    &.red {
      color: #ff0000 !important;
      &.edit:hover {
        text-decoration: underline;
      }
    }

    &.edit {
      color: #337ab7;
      cursor: pointer;
      &:hover {
        color: #23527c;
      }
    }
  }

  input {
    margin: 0;
    width: 100%;
    & + div {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      height: 26px;
      span {
        margin-left: 5px;
        + span {
          margin-left: 10px;
        }
      }
    }
  }
`;

PasswordInfo.propTypes = {
  link: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PasswordInfo;