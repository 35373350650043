const ISSUE_TYPES = {
  REPORTED_BUG_ID: "ReportedBugID",
  MESSAGE: "Message",
  REPORTED_BY: "ReportedBy",
  ASSIGNED_TO: "AssignedToName",
  JIRA_URL: "JiraURL",
  URL: "Url",
  FILENAME: "FileName",
  CREATED_ON: "CreatedOn",
  UPDATED_ON: "UpdatedOn",
  PRIORITY: "Priority",
  CATEGORY: "Category",
  STATUS: "Status",
  COMMENTS: "Comments",
};

export default ISSUE_TYPES;
