import styled from 'styled-components';
import {FormGroup} from 'react-bootstrap';

export const PathFieldStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

   &.source + &.destination {
    min-height: 34px;
  }

  label {
    margin: 0;
    padding: 0;
    font-weight: bold;
    min-width: 115px;

    & + div:not(.text--ellipsis--advanced0) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;

      .left {
        flex-grow: 1;
        &:not(.no-flex) {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  &.move-destination label {

    & + div {
      max-width: calc(100% - 115px);

      .left:not(.without-change-dest-btn) {
        max-width: calc(100% - 47px);
      }
    }
  }

  &.dest-folder {
    align-items: flex-start;

    label {
      margin-right: 15px;
      display: block;
      min-width: unset;
      white-space: nowrap;

      + div {
        max-width: unset;
      }
    }
  }

  .form-control:not([type="checkbox"]) {
    min-width: 30%;
    width: auto;
    min-height: 34px;
    margin-right: 5px;
    &.same-name:not(:focus) {
      opacity: 0.65;
    }
  }

  button {
    height: 34px;

    &.reset-btn {
      height: 20px;
      padding: 1px 3px;
      font-size: 10px;
    }
  }

  .value {
    color: ${props => (props.color || 'blue')};
    font-weight: bold;
    &:not(.no-word-break) {
      word-break: break-all;
    }
    &:not(.is-begin-ingest, .no-monospace, .is-send-captions-to-qod) {
      font-family: monospace;
    }
    &.is-begin-ingest {
      font-weight: normal;
    }
    &.is-send-captions-to-qod {
      font-weight: normal;
    }
  }
`;

export const PathFieldStyledAdv = styled(PathFieldStyled)`
  align-items: flex-start !important;
  justify-content: flex-start !important;

  .value {
    .error, .error:focus, .error:hover {
      border: 2px solid red;
    }
  }
  
  p.bg-danger {
    max-width: 210px;
    padding: 4px;
    margin-top: 27px;
    position: absolute;
    margin-left: -4px;
  }
  
  .fr-determ {
    width: 100%;
    padding: 5px;
  }

  &, &.move-destination {
    label {
      min-width: ${props => `${props.labelWidth || 115}px`};

      & + div {
        align-items: flex-start !important;

        &.value, &.dest-wr {
          max-width: calc(100% - ${props => `${props.labelWidth || 115}px`});
        }

        .left {
          align-items: flex-start !important;
          justify-content: flex-start !important;

          > .value {
            max-width: 100%;

            &.max-w-70 {
              max-width: 70%;
            }
          }

          textarea.form-control {
            margin-top: -7px;
            margin-right: 0 !important;
            padding: 6px;
            flex-grow: 1;
            resize: vertical;
          }

          + button {
            margin-top: -7px;
            margin-left: 5px;
          }
        }
      }
    }
  }
`;

export const CheckboxStyled = styled(FormGroup)`

  .check-div {
    display: flex;
  }

  &.in-rb-group {
    margin-top: 0;
    margin-left: 21px;
  }

  
  .control-label {
    padding: 0;
    margin-left: 20px;
    min-height: unset;
    cursor: default;
  }

  .form-control {
    height: auto;
    width: auto;
    margin-left: 0 !important;

    &[disabled] + label {
      opacity: 0.55 !important;
    }
  }
`;
