import React, {Component} from 'react';
import PropTypes from 'prop-types';

export class DetailsCell extends Component {
  render() {
    const {value} = this.props;
    return (
      <td>
        {
          Array.isArray(value) && value.length ? (
            value.map((item, index) => (
              <dl key={`details-${index}`}>
                <dt>
                  {item.DetailName}
                </dt>
                <dd>
                  {item.DetailValue}
                </dd>
              </dl>
            ))
          ) : null
        }
      </td>
    );
  }
}

DetailsCell.propTypes = {
  value: PropTypes.array
};

export default DetailsCell;
