import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PageComponent from '../../components/PageComponent';
import AppLayout from '../../layouts/AppLayout';
import {DELIVERY_PAGE__EDIT} from './constants';
import DeliveryCommon from './components/DeliveryCommon';

class DeliveryManager extends PageComponent {

  componentDidMount() {
    localStorage.setItem('last_view', 'download');
  }

  render() {
    const {match} = this.props;
    const {operationType} = match.params;
    const operationName = operationType === DELIVERY_PAGE__EDIT ? 'Edit' : 'Approve';

    return (
      <AppLayout
        title={`Delivery Manager - ${operationName}`}
        className="delivery-manager-page"
      >
        <DeliveryCommon
          {...this.props}
          operationName={operationName}
        />
      </AppLayout>
    );
  }
}

DeliveryManager.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  deliveryManager: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    deliveryManager: state.deliveryManager,
    user: state.user
  };
}

export default connect(
  mapStateToProps
)(withRouter(DeliveryManager));
