import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import InfoPanelStyled from "./InfoPanel.styled";
import ISSUE_BROWSER_INFO_PANEL_FIELDS from "../../constants/fields";
import ISSUE_TYPES from "../../constants/types";
import {FilterSelectStyled} from '../../../../components/filters/FiltersStyled';
import CommentForm from '../../../../components/comments/CommentForm';
import Select from 'react-select';
import {changeStatus, addReportComment} from "../../actions";
import {getOptionByValue} from "../../utils";

class InfoPanel extends Component {

  handleAddComment = (comment) => {
    const {dispatch, selectedItem} = this.props;
    return dispatch(addReportComment(selectedItem.ReportedBugID, comment));
  };

  handleChangeStatus = (status) => {
    const {dispatch, selectedItem} = this.props;
    dispatch(changeStatus(selectedItem.ReportedBugID, status));
  };

  renderValue(field) {
    const { selectedItem, additionalData } = this.props;
    if (field.value === ISSUE_TYPES.STATUS && Array.isArray(additionalData.user_statuses) && additionalData.user_statuses.length) {
      let options = additionalData.user_statuses;
      let selOption = getOptionByValue(options, selectedItem[field.value]);
      if (!selOption && selectedItem[field.value] && Array.isArray(additionalData.all_statuses)) {
        selOption = getOptionByValue(additionalData.all_statuses, selectedItem[field.value]);
        if (selOption) {
          options = [...options, selOption];
        }
      }
      if (!selOption) {
        selOption = {};
      }
      return (
        <FilterSelectStyled>
          <Select
            value={selOption.value || null}
            options={options}
            onChange={(o) => o && o.value !== selOption.value && this.handleChangeStatus(o)}
          />
        </FilterSelectStyled>
      );
    } else if (field.value === ISSUE_TYPES.COMMENTS) {
      return (
        <Fragment>
          <CommentForm
            classNamePrefix="issue-browser--comment"
            requestId={selectedItem.ReportedBugID}
            onSave={this.handleAddComment}
            height={35}
            sendByEnter={false}
          />
          {selectedItem[field.value]}
        </Fragment>
      );
    } else if ([ISSUE_TYPES.PRIORITY, ISSUE_TYPES.CATEGORY].includes(field.value) && selectedItem[`${field.value}Alt`]) {
      return selectedItem[`${field.value}Alt`];
    }
    return selectedItem[field.value];
  }

  render() {
    return (
      <InfoPanelStyled className="issue-browser--InfoPanel">
        {ISSUE_BROWSER_INFO_PANEL_FIELDS.map((field, index) => {
          return (
            <Row key={`issue-browser-info-field-${index}`} className={`issue-browser-info-field--${field.value}`}>
              <Col md={4} xs={4}>
                <label>{field.label}:</label>
              </Col>
              <Col style={{whiteSpace: "pre-line"}} md={8} xs={8}>
                {this.renderValue(field)}
              </Col>
            </Row>
          );
        })}
      </InfoPanelStyled>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedItem: state.issueBrowser.selectedItem,
    additionalData: state.issueBrowser.additionalData,
  };
}

export default connect(mapStateToProps)(InfoPanel);
