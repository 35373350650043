import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {isCompletedStatus} from '../../../container';
import {confirmAlert} from 'react-confirm-alert';

class TargetRateInfo extends Component {
  state = {
    target_rate_kbps: this.props.link.target_rate_kbps
  };

  handleSlideRate = event => {
    const newValue = event.target.value;
    if (typeof newValue === 'undefined') {
      return;
    }
    this.setState({target_rate_kbps: +newValue});
  };

  handleChangeRate = event => {
    const newValue = event.target.value;
    if (typeof newValue === 'undefined') {
      return;
    }

    const {link, onChangeRate} = this.props;
    const oldValue = link.target_rate_kbps;
    const cancel = () => {
      this.refs.input.value = oldValue;
      this.setState({target_rate_kbps: oldValue});
    };
    confirmAlert({
      title: 'Are You Sure?',
      message: 'Are you sure you want to save changes of target rate?',
      buttons: [{
        label: 'No',
        onClick: cancel
      }, {
        label: 'Yes',
        onClick: () => onChangeRate(link, +newValue, cancel)
      }],
      onClickOutside: cancel,
      onKeypressEscape: () => {
        cancel();
        try {
          this.refs.input.blur();
        } catch(ignored) {/**/}
      }
    });
  };

  componentDidUpdate(prevProps) {
    const {target_rate_kbps} = this.props.link;
    if (target_rate_kbps !== prevProps.link.target_rate_kbps) {
      try {
        if (this.refs && this.refs.input) {
          this.refs.input.value = target_rate_kbps;
        }
      } catch (ignored) {/**/}
      this.setState({target_rate_kbps});
    }
  }

  render() {
    const {link, minRate, maxRate} = this.props;
    const {target_rate_kbps} = this.state;

    if (typeof target_rate_kbps === 'undefined' || target_rate_kbps < 0) {
      return <span style={{opacity: 0.65}}>None</span>;
    } else if (isCompletedStatus(link) || link.upload_id) {
      return <span>{target_rate_kbps}</span>;
    }

    return (
      <Fragment>
        <input
          ref="input"
          type="range"
          step="10"
          min={minRate}
          max={maxRate}
          defaultValue={target_rate_kbps}
          onInput={this.handleSlideRate}
          onSelect={this.handleChangeRate}
        />
        <br/>
        <span>{target_rate_kbps}</span>
      </Fragment>
    );
  }
}

TargetRateInfo.propType = {
  link: PropTypes.object.isRequired,
  minRate: PropTypes.number.isRequired,
  maxRate: PropTypes.number.isRequired,
  onChangeRate: PropTypes.func.isRequired
};

export default TargetRateInfo;