import React, {Component} from "react";

export default class JiraUrlCell extends Component {
  render() {
    const {value, className} = this.props;

    return (
      <td className={className}>
        {value ?
          <a
            href={'https://myeyemedia.atlassian.net/browse/' + value}
            target="_blank"
            rel="noopener noreferrer"
          >
            {value}
          </a> : null
        }
      </td>
    );
  }
}
