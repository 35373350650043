import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {StyledTable} from './TableStyled';
import {getSortedRowsBy} from '../../../../utils';
import {HeaderRow} from '../../../../components/table/HeaderRow';
import {Row} from '../../../../components/table/Row';
import DateCell from '../../../../components/table/cells/DateCell';
import {FileTypeCell} from './cells/FileTypeCell';
import {ExpandedCheckedNameCell} from './cells/ExpandedCheckedNameCell';
import FullPathCell from './cells/FullPathCell';
import {SizeCell} from './cells/SizeCell';
import {PredictiveStatusCell} from './cells/PredictiveStatusCell';
import InfoPanel from './InfoPanel';
import {
  changeCheckedItems, changeOpenedFolders, changeNavigationInto, changeNavigationIntoCurrent, changeNavigationUp,
  setCurrentFolderForNestedTable
} from '../../actions';
import classNames from 'classnames';
import _ from 'lodash';
import {
  filterDataForView, VIEW_ADVANCED_PREDICTIVE, PREDICTIVE_STATE_DISAPPEARED, PREDICTIVE_STATE_APPEARED,
  DISPLAY_CATEGORY_INIT_CONFIRM_DELETION, DISPLAY_CATEGORY_SEARCH_RESULTS, WO_MEMFIS_FILTER_NAME
} from '../../constants';
import {prepareDataIfTooManyFiles} from '../../utils';

class TableWrap extends Component {
  isEqualsItems = (item1, item2) => {
    return item1.fsRootID === item2.fsRootID && item1.contentID === item2.contentID &&
      //item1.key === item2.key &&
      (item1.parentID || 0) === (item2.parentID || 0) &&
      (item1.predictiveStateID || 0) === (item2.predictiveStateID || 0) &&
      JSON.stringify(item1.parentsPredictiveStates || []) === JSON.stringify(item2.parentsPredictiveStates || []) &&
      JSON.stringify(item1.parents || []) === JSON.stringify(item2.parents || []);
  };

  isCheckedItem = row => {
    const {checkedItems} = this.props.storageManager;
    return Boolean(checkedItems.find(item => this.isEqualsItems(item, row)));
  };

  isCheckedParent = row => {
    const {fsRootID, parents, parentsPredictiveStates} = row;
    if (parents) {
      for (let i = 0; i < parents.length; i++) {
        const parentItem = {
          fsRootID,
          contentID: parents[i],
          predictiveStateID: parentsPredictiveStates[i],
          parents: parents.slice(0, i),
          parentsPredictiveStates: parentsPredictiveStates.slice(0, i)
        };
        if (this.isCheckedItem(parentItem)) {
          return true;
        }
      }
    }
    return false;
  };

  isChecked = row => {
    return this.isCheckedItem(row) || this.isCheckedParent(row);
  };

  isHeaderChecked = () => {
    const {data, storageManager} = this.props;
    const {checkedItems} = storageManager;
    if (checkedItems.length) {
      const checkedData = data.filter(item => this.isCheckedItem(item));
      return checkedData.length === data.length;
    }
    return false;
  };

  state = {
    tableSort: {
      field: 'name',
      direction: 'asc'
    }
  };

  buildFolderKey = folder => {
    const {fsRootID, contentID, parents, predictiveStateID} = folder;
    return `${fsRootID}--${JSON.stringify(parents || [])}--${contentID}--${predictiveStateID || 0}`;
  };

  isOpenedFolder = folder => {
    const {openedFolders} = this.props.storageManager;
    const key = this.buildFolderKey(folder);
    return openedFolders[key] === true;
  };

  checkDisplayedChildren = (checkedItems, children) => {
    if (children) {
      const {currentView} = this.props.storageManager;
      const dataForView = filterDataForView(children, currentView)[0];
      dataForView.forEach(item => {
        if (!checkedItems.find(t => this.isEqualsItems(t, item))) {
          checkedItems.push(item);
        }
        if (this.isOpenedFolder(item)) {
          this.checkDisplayedChildren(checkedItems, item.children);
        }
      });
    }
  };

  uncheckChildren = (checkedItems, children) => {
    if (children) {
      children.forEach(item => {
        _.remove(checkedItems, row => this.isEqualsItems(item, row));
        this.uncheckChildren(checkedItems, item.children);
      });
    }
  };

  closeChildrenFolders = (openedFolders, checkedItems, children) => {
    if (children) {
      children.forEach(item => {
        if (this.isOpenedFolder(item)) {
          const key = this.buildFolderKey(item);
          openedFolders[key] = false;
          this.uncheckChildren(checkedItems, item.children);
        }
      });
    }
  };

  openFolder = (folder, isOpen) => {
    const {children, isDirectory} = folder;
    if (isDirectory) {
      const {dispatch, storageManager} = this.props;
      const openedFolders = {...storageManager.openedFolders};
      const key = this.buildFolderKey(folder);
      openedFolders[key] = isOpen;
      if (!isOpen) {
        const checkedItems = [...storageManager.checkedItems];
        this.uncheckChildren(checkedItems, children);
        this.closeChildrenFolders(openedFolders, checkedItems, children);
        dispatch(changeCheckedItems(checkedItems));
      }
      dispatch(changeOpenedFolders(openedFolders));
    }
  };

  handleChangeCheck = (checked, row) => {
    const {dispatch, storageManager} = this.props;
    const checkedItems = [...storageManager.checkedItems];
    if (checked) {
      dispatch(setCurrentFolderForNestedTable({contentID: row.parentID, fsRootID: row.fsRootID}))
      checkedItems.push(row);
      if (this.isOpenedFolder(row)) {
        this.checkDisplayedChildren(checkedItems, row.children);
      }
    } else {
      _.remove(checkedItems, item => this.isEqualsItems(item, row));
      if (row.parents) {
        const {fsRootID} = row;
        row.parents.forEach((parentID, index) => {
          const parentItem = {
            fsRootID,
            contentID: parentID,
            predictiveStateID: row.parentsPredictiveStates[index],
            parents: row.parents.slice(0, index),
            parentsPredictiveStates: row.parentsPredictiveStates.slice(0, index)
          };
          _.remove(checkedItems, item => this.isEqualsItems(item, parentItem));
        });
      }
      this.uncheckChildren(checkedItems, row.children);
    }
    dispatch(changeCheckedItems(checkedItems));
  };

  handleChangeHeaderCheck = checked => {
    const {dispatch, data} = this.props;
    const checkedItems = checked ? [...data] : [];
    if (checked) {
      checkedItems.forEach(row => {
        if (this.isOpenedFolder(row)) {
          this.checkDisplayedChildren(checkedItems, row.children);
        }
      });
    }
    dispatch(changeCheckedItems(checkedItems));
  };

  handleOpenFolderClick = row => {
    const {dispatch, history} = this.props;
    if (this.isOpenedFolder(row)) {
      this.openFolder(row, false);
    } else {
      dispatch(changeNavigationInto(history, row, this.isCheckedItem(row)));
      this.openFolder(row, true);
    }
  };

  handleNameCellClick = row => {
    const {dispatch, history} = this.props;
    dispatch(changeNavigationIntoCurrent(history, row));
  };

  handleFullPathCellClick = row => {
    const {dispatch, history} = this.props;
    dispatch(changeNavigationUp(history, row));
  };

  getPredictiveStatus = statusId => {
    const {predictiveStates} = this.props.storageManager;
    return predictiveStates.find(s => s.PredictiveStateID === statusId);
  };

  getClassForNameCell = row => {
    const {currentView} = this.props.storageManager;
    if (currentView === VIEW_ADVANCED_PREDICTIVE) {
      const {predictiveStateID} = row;
      if (predictiveStateID === PREDICTIVE_STATE_APPEARED) {
        return 'red';
      } if (predictiveStateID === PREDICTIVE_STATE_DISAPPEARED) {
        return 'strikethrough';
      }
    }
    return '';
  };

  headers = {
    name: {
      title: 'Name',
      className: 'name',
      component: ExpandedCheckedNameCell,
      componentArgs: {
        dispatch: this.props.dispatch,
        getConnectDriveComponent: this.props.getConnectDriveComponent,
        getTextClassName: this.getClassForNameCell,
        history: this.props.history,
        isChecked: this.isChecked,
        isNeedToConnectDrive: this.props.isNeedToConnectDriveToRoot,
        isOpened: this.isOpenedFolder,
        onCheck: this.handleChangeCheck,
        onClick: this.handleNameCellClick,
        onExpand: this.handleOpenFolderClick,
      }
    },
    fileExtensionP: {
      title: 'File Type',
      className: 'file-type',
      component: FileTypeCell
    },
    fullPath: {
      title: 'Full Path',
      component: FullPathCell,
      className: 'full-path',
      componentArgs: {
        onClick: this.handleFullPathCellClick,
      }
    },
    fsRootUpdated: {
      title: 'Last Updated',
      className: 'updated',
      component: DateCell,
      componentArgs: {
        toFormat: 'M/D/YYYY hh:mm:ss A'
      }
    },
    modifiedOn: {
      title: 'Date Modified',
      className: 'modified',
      component: DateCell,
      componentArgs: {
        toFormat: 'M/D/YYYY hh:mm:ss A',
        defaultValue: '-'
      }
    },
    size: {
      title: 'Size',
      className: 'size',
      component: SizeCell
    },
    predictiveStateID: {
      title: 'Predictive Status',
      className: 'predictive-status',
      component: PredictiveStatusCell,
      componentArgs: {
        getStatus: this.getPredictiveStatus
      }
    }
  };

  sortAltFields = {
    modifiedOn: 'modified'
  };

  handleHeaderCellClick = (field, direction) => {
    if (field !== 'expanded') {
      this.setState({tableSort: {field, direction}});
    }
  };

  handleRowClick = row => {
    !this.props.isNeedToConnectDriveToRoot(row) && this.handleChangeCheck(!this.isChecked(row), row);
  };

  renderRows = (rows, emptyText, isFilteredForView) => {
    if (!rows) {
      return null;
    }

    const {tableSort} = this.state;
    const {currentView} = this.props.storageManager;
    const dataForView = isFilteredForView ? rows : filterDataForView(rows, currentView)[0];
    const sortedData = getSortedRowsBy(dataForView, tableSort.field, tableSort.direction, this.sortAltFields);
    const displayedData = prepareDataIfTooManyFiles(sortedData);
    return (
      <Fragment>
        {displayedData.map((row, i) => (
          <Fragment key={i}>
            <Row
              headers={this.headers}
              row={row}
              key={i}
              onRowClick={this.handleRowClick}
              getRowClassNames={r => r.isEllipsis ? 'tr-ellipsis' : undefined}
            />
            {this.isOpenedFolder(row) ? this.renderRows(row.children, emptyText) : null}
          </Fragment>
        ))}
        {
          !displayedData.length && emptyText ? (
            <tr>
              <td className="data-table-empty" colSpan={Object.keys(this.headers).length - (currentView === VIEW_ADVANCED_PREDICTIVE ? 0 : 1)}>{emptyText}</td>
            </tr>
          ) : null
        }
      </Fragment>
    );
  };

  render() {
    const {dispatch, history, storageManager, loading, data} = this.props;
    const {checkedItems, currentView, displayCategory, usedFilters} = storageManager;
    const isShowSummaryOfDeletion = displayCategory === DISPLAY_CATEGORY_INIT_CONFIRM_DELETION;
    const isInfoPanelCollapsed = !isShowSummaryOfDeletion && Boolean(!checkedItems.length);
    const {tableSort} = this.state;
    const emptyText = 'No Files';
    const [dataForView] = filterDataForView(data, currentView);
    return (
      <StyledTable
        className={classNames({
          'storage-content-table': true,
          'table-wrapper': true,
          'info-panel-collapsed': isInfoPanelCollapsed
        })}
        >
        <div className="table-container">
          <div className="table-block">
            <table
              className={classNames({
                table: true,
                [currentView.toLowerCase()]: true,
                'n--table': true
              })}
            >
              <HeaderRow headers={this.headers} sort={tableSort} onHeaderCellClick={this.handleHeaderCellClick}/>
              <tbody>
                {!!dataForView.length && this.renderRows(dataForView, loading ? null : emptyText, true)}
              </tbody>
              <caption>{!dataForView.length ? emptyText : <span>&nbsp;</span>}</caption>
            </table>
          </div>
        </div>
        <InfoPanel
          dispatch={dispatch}
          history={history}
          storageManager={storageManager}
          items={checkedItems}
          isSummaryOfDeletion={isShowSummaryOfDeletion}
          isSearchWoMemfis={displayCategory === DISPLAY_CATEGORY_SEARCH_RESULTS && (usedFilters[WO_MEMFIS_FILTER_NAME] || '').match(/^wo[0-9]+$/i)}
          allowRefresh={displayCategory === DISPLAY_CATEGORY_SEARCH_RESULTS}
        />
      </StyledTable>
    );
  }
}

TableWrap.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  storageManager: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired
}

export default TableWrap;
