import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button} from 'react-bootstrap';
import styled from 'styled-components';
import {setFoldersInModal} from '../../../StorageManager/actions';
import FolderExplorerNew from '../../../StorageManager/components/forms/FolderExplorerNew';
import Loader from '../../../../components/Loader';
import ReportBugButton from '../../../../components/ReportBugButton';
import RndModal from '../../../../components/modals/RndModal';

class DeliveryModal extends Component {
  state = {
    selectedFolder: null
  };

  componentDidMount() {
    const {dispatch, storageManager} = this.props;
    const {fsRoots} = storageManager;
    const folders = fsRoots.map(i => ({...i}));
    dispatch(setFoldersInModal(folders));
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  handleClickConfirm = () => {
    const {onConfirm, woMemfisId} = this.props;
    const {selectedFolder} = this.state;
    onConfirm(woMemfisId, selectedFolder);
  };

  render() {
    const {onClose, storageManager, isSaving, woMemfisId, isForbiddenMakeDirectory, dispatch} = this.props;
    const {foldersInModal, isLoadingModal, loaders, tempDirectories, currentView, openedFoldersInModal} = storageManager;
    const {selectedFolder} = this.state;
    return (
      <RndModal
        bsSize="large"
        className="move-modal"
        show={true}
        onHide={onClose}
        backdrop="static"
        enforceFocus={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>
            Delivery <strong>{woMemfisId}</strong>
            <ReportBugButton isForModal />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContainerStyled className="container-fluid">
            {isSaving ?
                <div>
                  <Loader className="full-screen"/>
                  <ReportBugButton isForModal className="loader-report-bug-btn"/>
                </div> : isLoadingModal ?
                <div>
                  <Loader/>
                  <ReportBugButton isForModal className="loader-report-bug-btn"/>
                </div> : null}
            <FolderExplorerNew
              dispatch={dispatch}
              loaders={loaders}
              folders={foldersInModal}
              openedFolders={openedFoldersInModal}
              currentView={currentView}
              tempDirectories={tempDirectories}
              selectedFolder={selectedFolder}
              onSelectFolder={selFolder => this.setState({selectedFolder: selFolder})}
              withoutNewFolders={isForbiddenMakeDirectory}
              selectedFolderLabel="Destination Folder:"
              selectedFolderLabelWidth={150}
              storageManager={storageManager}
            />
          </ModalContainerStyled>
        </Modal.Body>
        <ModalFooterStyled>
          <Button
            bsStyle="primary"
            onClick={this.handleClickConfirm}
            disabled={!selectedFolder}
          >
            Confirm
          </Button>
          <Button
            bsStyle="default"
            onClick={onClose}
          >
            Cancel
          </Button>
        </ModalFooterStyled>
      </RndModal>
    );
  }
}

const ModalFooterStyled = styled(Modal.Footer)`
  display: flex;
  justify-content: flex-end;
`;

const ModalContainerStyled = styled.div`
  min-height: 200px;
  .loader-container {
    z-index: 1051;
  }
`;

DeliveryModal.propTypes = {
  dispatch: PropTypes.func,
  storageManager: PropTypes.object.isRequired,
  woMemfisId: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isForbiddenMakeDirectory: PropTypes.bool.isRequired
};

export default DeliveryModal;
