/* eslint "jsx-a11y/anchor-is-valid":"off" */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, FormGroup, ControlLabel } from 'react-bootstrap';
import styled from 'styled-components';
import { FilterSelectStyled } from '../../../../components/filters/FiltersStyled';
import Select from 'react-select';
import { closeActionSubscriberView, addSubscribers } from '../../actions';
import RndModal from '../../../../components/modals/RndModal';

class ActionSubscriberModal extends Component {
  state = {
    selectedOption: this.props.data.subscribers
  };

  handleSelectOption = selectedOption => {
    this.setState({ selectedOption });
  };

  handleClose = () => {
    const { dispatch } = this.props;
    dispatch(closeActionSubscriberView());
  };

  componentWillUnmount() {
    this.handleClose();
  }

  handleSubscription = () => {
    const { dispatch, data } = this.props;
    const { selectedOption } = this.state;
    const arrayData = [];

    if(selectedOption !== null){
        for(var i =0;i<selectedOption.length;i++){
          if(selectedOption[i].value === undefined){
            arrayData.push(selectedOption[i]);
          }else{
            arrayData.push(selectedOption[i].value);
          }
        }
    }

    dispatch(addSubscribers(data.actionID, arrayData));
    this.handleClose();
  }

  clearAllSubscriptions = () => {
    this.handleSelectOption(null);
  }

  render() {
    const { data: { actionID, actionType }, storageManager: { allActiveUsers } } = this.props;
    const options = [];
    if (allActiveUsers) {
      Object.entries(allActiveUsers).forEach(([i, map], index) => {
        options.push({ label: map.label, value: map.value })
      });
    }
    const { selectedOption } = this.state;

    return (
      <RndModal
        show={true}
        onHide={this.handleClose}
        backdrop="static"
        enforceFocus={false}
        bsSize="large"
      >
        <Modal.Header closeButton>
          <Modal.Title>Subscribe to Action # <strong>{actionID} - {actionType}</strong></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <FormGroupStyled>
              <ControlLabel>
                (Subscribers will get email when action succeeds or fails)
              </ControlLabel>
            </FormGroupStyled>
            <FormGroupStyled>
              <ControlLabel>
                Subscriber Emails:
              </ControlLabel>
              <SelectStyled>
                <Select
                  options={options}
                  multi={true}
                  placeholder=""
                  value={selectedOption}
                  onChange={this.handleSelectOption}
                />
              </SelectStyled>
            </FormGroupStyled>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <StyledFooter>
            <a className="subscriber-clear" href="#" rel="noopener noreferrer" onClick={this.clearAllSubscriptions}>Clear All</a>
            <Button bsStyle="primary" onClick={this.handleSubscription}>Save</Button>
            <Button bsStyle="default" onClick={this.handleClose}>Cancel</Button>
          </StyledFooter>
        </Modal.Footer>
      </RndModal>
    );
  }
}

const StyledFooter = styled.div`
  .subscriber-clear {
    float:left
  }
`;


const SelectStyled = styled(FilterSelectStyled)`
  width: 80%;
  position: fixed;
  top: 100px;
  left: 165px;
  .Select.has-value.Select--single,
  .Select.Select--single,
  .Select.is-searchable.Select--single,
  .Select--multi {

    > .Select-control {
      height: 34px;      
      .Select-multi-value-wrapper {
        height: 32px;
        .Select-placeholder,
        .Select-value {
          font-size: 13px !important;
          line-height: 14px !important;
          .Select-value-label {
            font-size: 13px !important;
            line-height: 14px !important;
            opacity: 0.65;
          }
        }
        .Select-input {
          padding-left: 9px;
          height: 32px;
          input {
            font-size: 13px !important;
          }
        }
      }
    }

    &.is-open {
      > .Select-control {
        .Select-multi-value-wrapper {
          .Select-placeholder,
          .Select-value {
            .Select-value-label {
              opacity: 1;
            }
          }
        }
      }
    }
  }
`;

const FormGroupStyled = styled(FormGroup)`
  display: flex;
  align-items: center;

  label {
    margin: 0;
    padding: 0 8px 0 0;
    font-weight: bold;
  }



  .form-control {
    width: 100%;
    font-size: 13px;

    &:not(:focus) {
      opacity: 0.65;
    }
  }
`;

ActionSubscriberModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

export default ActionSubscriberModal;
