import React, {Component, useRef} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button} from 'react-bootstrap';
import styled, {createGlobalStyle} from 'styled-components';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {parseName} from '../../utils';
import {formatBytes} from '../../../../utils';
import MiddleEllipsis from 'react-middle-ellipsis';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import RndModal from '../../../../components/modals/RndModal';

const DragHandle = SortableHandle(() => <i className="fas fa-bars" title="Drag to sort"/>);

const FileItem = ({file, index, showDragHandle}) => {
  const filename = parseName(file.key);
  const itemRef = useRef(null);
  return (
    <div ref={itemRef} className="file-item">
      {showDragHandle && <DragHandle/>}
      <div className="file-item-name">
        <strong>{index + 1}.</strong>
        <OverlayTrigger
          placement="bottom"
          container={(itemRef || {}).current || undefined}
          overlay={<Tooltip id="full-filename-tooltip">{filename}</Tooltip>}
        >
          <div className="ellipse-value">
            <MiddleEllipsis>
              <span data-original={filename}>{filename}</span>
            </MiddleEllipsis>
          </div>
        </OverlayTrigger>
      </div>
      <div className="file-item-size">({formatBytes(file.size)})</div>
    </div>
  );
};

const SortableItem = SortableElement(({value, sortedIndex, showDragHandle}) => (
  <FileItem file={value} index={sortedIndex} showDragHandle={showDragHandle}/>
));

const SortableList = SortableContainer(({children}) => {
  return <div>{children}</div>;
});

class ManuallyReconcileModal extends Component {
  state = {
    showSizesMatch: this.props.data.copiedAsset.barcodableFiles.length === 1,
    barcodableFiles: this.props.data.copiedAsset.barcodableFiles
  };

  onSortEnd = ({oldIndex, newIndex}) => {
    if (oldIndex === newIndex) {
      this.setState({showSizesMatch: true});
      return;
    }
    this.setState(({barcodableFiles}) => ({
      barcodableFiles: arrayMove(barcodableFiles, oldIndex, newIndex),
      showSizesMatch: true
    }));
  };

  handleSave = () => {
    const {onSave, data} = this.props;
    const {originalAsset, copiedAsset} = data;
    const {barcodedFiles} = originalAsset;
    const {barcodableFiles} = this.state;
    const filePairs = {};
    barcodedFiles.forEach((barcodedFile, index) => {
      filePairs[barcodedFile.contentID] = barcodableFiles[index].contentID;
    });
    const {fsRootID, contentID, memfisWoID} = originalAsset.rootDir;
    const values = {
      fs_root_id: fsRootID,
      wo_memfis_id: memfisWoID,
      content_dir_pairs: JSON.stringify({[contentID]: copiedAsset.rootDir.contentID}),
      content_file_pairs: JSON.stringify(filePairs)
    };
    onSave(values);
  };

  handleClose = () => {
    const {onClose} = this.props;
    onClose();
  };

  componentWillUnmount() {
    this.handleClose();
  }

  render() {
    const {data} = this.props;
    const {originalAsset} = data;
    const {barcodedFiles} = originalAsset;
    const {barcodableFiles, showSizesMatch} = this.state;
    const disabledReconcile = barcodableFiles.length < barcodedFiles.length;
    const disabledSortable = disabledReconcile || barcodableFiles.length <= 1;
    return (
      <RndModal
        show={true}
        onHide={this.handleClose}
        backdrop="static"
        enforceFocus={false}
        bsSize="large"
        width="90%"
        dialogClassName="barcoded-files-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Barcoded Files</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContainerStyled className="container-fluid">
            <div className="container-files">
              <div className="col-files">
                <div className="header-col"><strong>Original Asset</strong></div>
                <div>
                  {!barcodedFiles.length ? <div className="no-files">No files</div> :
                    barcodedFiles.map((file, index) => (
                      <FileItem file={file} index={index} key={`barcodedFile${index}`}/>
                    ))
                  }
                </div>
              </div>
              <div className="col-sizes-match">
                <div>&nbsp;</div>
                {!disabledReconcile && showSizesMatch && barcodedFiles.map((file, index) => {
                  const sizesMatch = file.size === barcodableFiles[index].size;
                  return (
                    <div key={`f-size-m-${index}`}>
                      <i
                        className={`fas fa-arrows-alt-h size-${sizesMatch ? '' : 'don-t-'}match`}
                        title={`File sizes ${sizesMatch ? '' : "don't "}match`}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="col-files">
                <div className="header-col"><strong>Copied Asset</strong></div>
                <SortableList
                  helperClass="sortable-list-item-helper"
                  onSortEnd={this.onSortEnd}
                  useDragHandle={!disabledSortable}
                >
                  {!barcodableFiles.length ? <div className="no-files">No files</div> :
                    barcodableFiles.map((file, index) => (
                      <SortableItem
                        key={`barcodedFile${index}`}
                        index={index}
                        sortedIndex={index}
                        value={file}
                        disabled={disabledSortable}
                        showDragHandle={!disabledSortable}
                      />
                    ))
                }
                </SortableList>
              </div>
            </div>
          </ModalContainerStyled>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.handleSave} disabled={disabledReconcile}>Reconcile</Button>
          <Button bsStyle="default" onClick={this.handleClose}>Cancel</Button>
        </Modal.Footer>
        <GlobalStyled/>
      </RndModal>
    );
  }
}

const GlobalStyled = createGlobalStyle`
  .file-item.sortable-list-item-helper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 1051;

    i.fa-bars {
      margin-right: 7px;
    }

    .file-item-name {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      overflow: hidden;
      white-space: nowrap;
      strong {
        padding-right: 5px;
      }
    }
    .file-item-size {
      padding-left: 5px;
      white-space: nowrap;
    }
  }

  .barcoded-files-modal {
    width: 90%;
  }
`;

const ModalContainerStyled = styled.div`
  min-height: 200px;

  .container-files {
    height: calc(100vh - 220px);
    overflow-y: auto;
    display: flex;
    flex-direction: row;

    > div {
      &.col-sizes-match {
        width: 50px;
        > div {
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        i {
          font-size: 30px;
          line-height: 30px;
          &.size-match {
            color: green;
          }
          &.size-don-t-match {
            color: red;
          }
        }
      }

      &.col-files {
        width: calc((100% - 50px) / 2);
        .header-col {
          height: 30px;
          text-align: center;
        }
        .no-files {
          height: 30px;
          line-height: 30px;
          opacity: 0.7;
          text-align: center;
        }
        .file-item {
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          position: relative;

          .tooltip {
            margin-top: 0;
            .tooltip-inner {
              max-width: unset;
              text-align: left;
              word-break: break-all;
            }
          }

          .ellipse-value {
            white-space: nowrap;
            overflow: hidden;
          }

          i.fa-bars {
            margin: 0 7px 0 15px;
            opacity: 0.75;
            cursor: pointer;
            &:hover {
              opacity: 1;
            }
          }

          .file-item-name {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            overflow: hidden;
            white-space: nowrap;
            strong {
              padding-right: 5px;
            }
          }
          .file-item-size {
            padding-left: 5px;
            white-space: nowrap;
          }
        }
      }
    }
  }
`;

ManuallyReconcileModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

export default ManuallyReconcileModal;
