/* eslint "jsx-a11y/anchor-is-valid":"off" */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TableHeader from './headers/table.js';
import TableFolder from './folders/table.js';
import TableFile from './files/table.js';
import GroupByFolder from './groupers/by-folder.js';
import SortByName from './sorters/by-name.js';
import styled from 'styled-components';
import {Button} from 'react-bootstrap';
import Paginate from '../table/Paginate';
import Toolbar from '../table/Toolbar';
import _ from 'lodash';
import {getSortedRowsBy, compareRows, getFileAbsPath} from '../../../../utils';

const getItemProps = (loadingPath, file, browserProps) => {
  const fileAbsolutePath = browserProps.rootPath.slice(0, -1) + (!file.relativeKey.startsWith('/') ? '/' : '') + file.relativeKey;

  return {
    key: `file-${fileAbsolutePath}`,
    fileKey: file.key,
    info: file,
    isSelected: (fileAbsolutePath.replace(/^[/]/, '') === browserProps.selection),
    isOpen: (file.key in browserProps.openFolders),
    inProgress: [loadingPath, `${loadingPath}/`].includes(file.key)
  };
};

class DefaultDetail extends Component {
  handleCloseClick = event => {
    if (event) {
      event.preventDefault();
    }
    this.props.close();
  };

  render() {
    let name = this.props.file.key.split('/');
    name = name.length ? name[name.length - 1] : '';

    return (
      <div>
        <h2>Item Detail</h2>
        <dl>
          <dt>Key</dt>
          <dd>{this.props.file.key}</dd>
          <dt>Name</dt>
          <dd>{name}</dd>
        </dl>
        <a href="#" onClick={this.handleCloseClick}>Close</a>
      </div>
    );
  }
}

class FileBrowser extends Component {
  state = {
    checkedItems: this.props.checkedAssets || [],
    openFolders: {},
    selection: null,
    previewFile: null
  };

  componentDidMount() {
    if (this.props.renderStyle === 'table' && this.props.nestChildren) {
      console.warn('Invalid settings: Cannot nest table children in file browser');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedPage !== prevProps.selectedPage || this.props.pageSize !== prevProps.pageSize) {
      this.setState({
        checkedItems: [],
        openFolders: {},
        selection: null,
        previewFile: null
      });
    }
    if (this.props.applyingFilters !== prevProps.applyingFilters) {
      this.setState({
        checkedItems: []
      });
    }
  }

  select = key => {
    this.setState(state => {
      state.selection = key;
      return state;
    });
  };

  preview = file => {
    this.setState(state => {
      state.previewFile = file;
      return state;
    });
  };

  closeDetail = () => {
    this.setState(state => {
      state.previewFile = null;
      return state;
    });
  };

  toggleFolder = folderKey => {
    this.setState(state => {
      if (folderKey in state.openFolders) {
        delete state.openFolders[folderKey];
        Object.keys(state.openFolders).forEach(key => {
          if (key.startsWith(folderKey)) {
            delete state.openFolders[key];
          }
        });
      } else {
        state.openFolders[folderKey] = true;
      }
      return state;
    });
  };

  getParentContentId = contentId => {
    const {contentChildren} = this.props;
    return Number(Object.keys(contentChildren).find(key => contentChildren[key].includes(contentId)));
  };

  uncheckAsset = (fsRootId, mainContentId, checkedItems, contentId) => {
    const id = mainContentId === contentId ?
      `${fsRootId}--${mainContentId}` :
      `${fsRootId}--${mainContentId}--${contentId}`;
    if (checkedItems.includes(id)) {
      checkedItems.splice(checkedItems.findIndex(item => item === id), 1);
    }
  };

  uncheckAssetChildren = (fsRootId, mainContentId, checkedItems, parentContentId) => {
    const {contentChildren} = this.props;
    const children = contentChildren[parentContentId];
    if (children) {
      children.forEach(contentId => {
        this.uncheckAsset(fsRootId, mainContentId, checkedItems, contentId);
        this.uncheckAssetChildren(fsRootId, mainContentId, checkedItems, contentId);
      });
    }
  };

  checkAssetParents = (checkedItems, fsRootId, mainContentId, contentId) => {
    const {contentChildren} = this.props;
    let parentContentId = this.getParentContentId(contentId);
    if (parentContentId && contentChildren[parentContentId]) {
      const items = checkedItems
        .filter(item => item.startsWith(`${fsRootId}--${mainContentId}--`))
        .map(item => Number(item.split('--').reverse()[0]));
      if (_.intersection(contentChildren[parentContentId], items).length === contentChildren[parentContentId].length) {
        const id = parentContentId === mainContentId ?
          `${fsRootId}--${mainContentId}` :
          `${fsRootId}--${mainContentId}--${parentContentId}`;
        checkedItems.push(id);
        _.remove(checkedItems, item => item.startsWith(`${fsRootId}--${parentContentId}--`));
      }
      this.checkAssetParents(checkedItems, fsRootId, mainContentId, parentContentId);
    }
  };

  isCheckedParentAsset = (fsRootId, mainContentId, contentId) => {
    const {checkedItems} = this.state;
    let parentContentId = contentId;
    let result = false;
    do {
      parentContentId = this.getParentContentId(parentContentId);
      result =
        checkedItems.includes(`${fsRootId}--${mainContentId}`) ||
        checkedItems.includes(`${fsRootId}--${mainContentId}--${parentContentId}`);
    } while (parentContentId && !result);
    return result;
  };

  getCheckedProps = mainContentId => {
    return {
      isChecked: row => {
        const {checkedItems} = this.state;
        if (checkedItems.includes(`${row.fsRootID}--${mainContentId}`) ||
            checkedItems.includes(`${row.fsRootID}--${mainContentId}--${row.contentID}`)) {
          return true;
        }
        return this.isCheckedParentAsset(row.fsRootID, mainContentId, row.contentID);
      },
      onChange: (checked, row) => {
        const fsRootId = row.fsRootID;
        const {contentChildren} = this.props;
        let checkedItems = [...this.state.checkedItems];
        if (checked) {
          const id = row.contentID === mainContentId ?
             `${fsRootId}--${mainContentId}` :
             `${fsRootId}--${mainContentId}--${row.contentID}`;
          checkedItems.push(id);
          if (row.contentID === mainContentId) {
            _.remove(checkedItems, item => item.startsWith(`${fsRootId}--${mainContentId}--`));
          }
          this.checkAssetParents(checkedItems, row.fsRootID, mainContentId, row.contentID);
        } else {
          let contentId = row.contentID;
          do {
            if (this.isCheckedParentAsset(fsRootId, mainContentId, contentId)) {
                contentId = this.getParentContentId(contentId);
                if (contentId && contentChildren[contentId]) {
                  contentChildren[contentId].forEach(item => {
                    if (item !== row.contentID) {
                      const id = `${fsRootId}--${mainContentId}--${item}`;
                      if (!checkedItems.includes(id)) {
                        checkedItems.push(id);
                      }
                    }
                  });
                }
            } else {
              break;
            }
          } while (contentId);
          contentId = row.contentID;
          do {
            this.uncheckAsset(fsRootId, mainContentId, checkedItems, contentId);
            contentId = this.getParentContentId(contentId);
          } while (contentId);
          this.uncheckAssetChildren(fsRootId, mainContentId, checkedItems, row.contentID);
        }
        this.setState({checkedItems});
      }
    };
  };

  getBrowserProps() {
    return {
      // browser config
      nestChildren: this.props.nestChildren,
      folderRenderer: this.props.folderRenderer,
      fileRenderer: this.props.fileRenderer,
      // browser state
      openFolders: this.state.openFolders,
      selection: this.state.selection,

      // browser manipulation
      select: this.select,
      toggleFolder: this.toggleFolder,
      preview: this.preview,

      getItemProps: getItemProps,

      rootPath: this.props.rootPath,
    };
  }

  handleClickDeleteAssets = () => {
    const {checkedItems} = this.state;
    const values = _.uniq(
      checkedItems.map(item => Number(item.split('--').reverse()[0]))
    );
    const contentIDs = values.filter(item => item > 0);
    const {onDeleteAssets} = this.props;
    onDeleteAssets(contentIDs, Number(checkedItems[0].split('--')[0]));
  };

  handleHeaderCellClick = (field, direction) => {
    const {total, pageSize, onHeaderCellClick} = this.props;
    if (total > pageSize) {
      this.setState({checkedItems: []});
    }
    onHeaderCellClick({field, direction});
  };

  renderActionBar() {
    const {deleteBtnLabel, otherBtn} = this.props;
    const {checkedItems} = this.state;
    const checkedRoots = _.uniq(checkedItems.map(item => Number(item.split('--')[0])));
    return (
      <ActionBarStyled className="action-bar">
        <Button
          bsStyle={otherBtn ? 'primary' : 'default'}
          className="delete-assets"
          disabled={!checkedItems.length || checkedRoots.length !== 1}
          title={checkedRoots.length > 1 ? 'More than 1 FS root selected' : undefined}
          onClick={this.handleClickDeleteAssets}
          >
          {deleteBtnLabel || 'Send for Delete Approval'}
        </Button>
        {otherBtn}
      </ActionBarStyled>
    );
  }

  compareFilesByAbsPath = (fa, fb, direction) => {
    let a = getFileAbsPath(fa), b = getFileAbsPath(fb);
    if (direction === 'desc') {
      const tmp = a;
      a = b;
      b = tmp;
    }
    return (a || '').localeCompare(b || '');
  };

  sortFiles = files => {
    const {tableSort} = this.props;
    if (!tableSort || !tableSort.field || !tableSort.direction) {
      return files;
    }

    let sortedFiles = [...files];
    if (['size', 'modified'].includes(tableSort.field)) {
      sortedFiles = getSortedRowsBy(files, tableSort.field, tableSort.direction);
    } else if (['file', 'absolute_path'].includes(tableSort.field)) {
      sortedFiles.sort((fa, fb) => this.compareFilesByAbsPath(fa, fb, tableSort.direction));
    }
    return sortedFiles;
  };

  sortRootFiles = files => {
    const {tableSort} = this.props;
    if (!tableSort || !tableSort.field || !tableSort.direction) {
      return files;
    }

    const sortedFiles = [...files];
    sortedFiles.sort((fa, fb) => {
      let a = fa.root ? fa.root : fa.files.length ? fa.files[0] : null;
      let b = fb.root ? fb.root : fb.files.length ? fb.files[0] : null;
      if (['size', 'modified'].includes(tableSort.field)) {
        return compareRows(a, b, tableSort.field, tableSort.direction);
      } else if (['file', 'absolute_path'].includes(tableSort.field)) {
        return this.compareFilesByAbsPath(a, b, tableSort.direction);
      }
      return 0;
    });
    return sortedFiles;
  };

  renderFiles(onFolderClick, files, depth, mainContentId) {
    const {loadingPath} = this.props;
    let browserProps = this.getBrowserProps();
    let renderedFiles = [];
    const sortedFiles = this.sortFiles(files);
    sortedFiles.forEach((file) => {
      let thisItemProps = {
        ...browserProps.getItemProps(loadingPath, file, browserProps),
        depth,
        ...this.getCheckedProps(mainContentId)
      };

      if (file.isDirectory) {
        renderedFiles.push(
          <this.props.folderRenderer
            {...file}
            {...thisItemProps}
            browserProps={browserProps}
            onFolderClick={onFolderClick}
            />
        );
        if (thisItemProps.isOpen && !browserProps.nestChildren) {
          renderedFiles = renderedFiles.concat(this.renderFiles(onFolderClick, file.children, depth + 1, mainContentId));
        }
      } else {
        renderedFiles.push(
          <this.props.fileRenderer
            {...file}
            {...thisItemProps}
            browserProps={browserProps}
          />
        );
      }
    });
    return renderedFiles;
  }

  renderContents(data, mainContentId) {
    const {loadingPath} = this.props;
    let files = data.files.concat([]);
    if (typeof this.props.group === 'function') {
      files = this.props.group(files, this.props.rootPath || '', data);
    } else {
      const newFiles = [];
      files.forEach((file) => {
        if (file.size) {
          newFiles.push(file);
        }
      });
      files = newFiles;
    }
    const findSelected = (item) => {
      if (item.children) {
        item.children.map(findSelected);
      }
    }
    files.map(findSelected);

    const sortedFiles = this.sortFiles(files);

    let contents = this.renderFiles(data.onFolderClick, sortedFiles, 0, mainContentId);
    if (!contents.length) {
      const browserProps = this.getBrowserProps();
      const root = data.root ? {...data.root, key: `${data.root.key}/`, relativeKey: `${data.root.key}/`} : null;
      contents = root ? (
        <this.props.folderRenderer
          {...root}
          {...browserProps.getItemProps(loadingPath, root, browserProps)}
          browserProps={browserProps}
          name={data.root.key}
          onFolderClick={() => {}}
          {...this.getCheckedProps(mainContentId)}
          depth={0}
          />
      ) : (
        <tr className="folder-no-files">
          {
            data.rootName ? (
              <td colSpan="100" className="name">
                <i className="far fa-folder" aria-hidden="true"/>{data.rootName}<i>>> No files</i>
              </td>
            ) : (
              <td colSpan="100">
                No files
              </td>
            )
          }
        </tr>
      );
    }
    return contents;
  }

  render() {
    const {
      files, total, selectedPage, onPageClick, pageSize, onChangePageSize, className,
      tableSort, onHeaderCellClick
    } = this.props;
    const mainItems = _.compact(files.map(item => {
      if (item.root) {
        return `${item.root.fsRootID}--${item.root.contentID}`;
      }
      return item.files.length ? `${item.files[0].fsRootID}--${item.files[0].contentID}` : undefined;
    }));
    const headerProps = {
      isChecked: () =>
        mainItems.length &&
        mainItems.length === this.state.checkedItems.filter(item => mainItems.includes(item)).length,
      onChange: checked => this.setState({checkedItems: (checked ? mainItems : [])}),
      sort: tableSort && onHeaderCellClick ? tableSort : undefined,
      onHeaderCellClick: tableSort && onHeaderCellClick ? this.handleHeaderCellClick : undefined
    };

    let header;
    if (this.props.headerRenderer) {
      header = <this.props.headerRenderer {...headerProps}/>
    }

    const sortedFiles = this.sortRootFiles(files);
    const pagedData = sortedFiles.length > pageSize ?
      sortedFiles.slice(selectedPage * pageSize, selectedPage * pageSize + pageSize) : sortedFiles;

    const renderedFiles = (
      <table cellSpacing="0" cellPadding="0">
        {header}
        {
          pagedData.map((data, index) => (
            <tbody key={`tbody-${index}`}>
              {this.renderContents(data, data.root ? data.root.contentID : data.files.length ? data.files[0].contentID : 0)}
            </tbody>
          ))
        }
      </table>
    );

    return (
      <FileBrowserStyled className={`rendered-react-keyed-file-browser ${className || ''}`}>
        {this.props.actions}
        <div className="rendered-file-browser" ref="browser">
          {
            onChangePageSize ?
              <Toolbar
                pageSize={pageSize}
                onChangePageSize={onChangePageSize}
              />
            : null
          }
          {this.props.showActionBar && this.renderActionBar()}
          <div className="files">
            {renderedFiles}
          </div>
          {
            onPageClick ?
              <Paginate
                pageCount={Math.ceil(total / pageSize) || 1}
                selectedPage={selectedPage}
                onPageChange={onPageClick}
                nextLabel=">"
                previousLabel="<"
                containerClassName="pagination"
              /> : null
          }
        </div>
        {this.state.previewFile !== null && (
          <this.props.detailRenderer
            file={this.state.previewFile}
            close={this.closeDetail}
          />
        )}
      </FileBrowserStyled>
    );
  }
}

const ActionBarStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 5px 0 15px;
  .form-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
  }
  .form-control {
    height: auto;
    width: auto;
    cursor: pointer;
    border: 1px solid #E9E9E9;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    display: inline-block;
    margin: 0 5px 0 0;
  }
  .control-label {
    cursor: pointer;
    display: inline-block;
    margin: 0;
  }
  .right-checkboxes {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
`;

const FileBrowserStyled = styled.div`
  .files {
    max-width: 100%;
    overflow: auto;
  }
  table {
    width: 100%;
    margin-bottom: 2rem;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    tbody {
      border-top: 1px solid #ddd;
    }

    th, td {
      font-size: 1.4rem;
      font-weight: normal;
      text-align: left;
      margin: 0;
      padding: 0.5rem;
      &.size, &.modified {
        text-align: right;
      }
      &.checkbox-cell {
        width: 30px;
      }
    }

    th {
      font-weight: bold;
      border-bottom: 0.1rem solid #ddd;
    }

    tr:not(:last-child) td {
      border-bottom: 0.1rem solid #eee;
    }

    td.name, td.abs-path {
      word-break: break-all;
    }

    td.name {
      padding-left: 0.8rem;
      i {
        padding-right: 0.5rem;
        &:nth-child(2) {
          padding-left: 1rem;
        }
      }
    }

    tr:not(.folder-no-files) td {
      cursor: pointer;
    }

    tr.selected td {
      font-weight: bold;

      &.name {
        position: relative;

        &:after {
          content: ' ';
          position: absolute;
          left: 0;
          top: 0;
          width: 0.3rem;
          height: 100%;
          background: #337ab7;
        }
      }
    }
  }

  &.asset-deletion-table {
    table {
      th, td {
        word-break: break-all;
        &.checkbox-cell {
          input {
            cursor: pointer;
          }
        }
        &:nth-child(2) {
          width: 70%;
        }
        &:nth-child(3), &:nth-child(4) {
          width: calc(15% - 15px);
        }
      }
      tbody {
        tr:not(:first-child) {
          td.checkbox-cell {
            pointer-events: none;
            opacity: 0.5;
          }
        }
      }
    }
    &.only-browse table {
      th.checkbox-cell input {
        visibility: hidden;
      }
      td.checkbox-cell {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
`;

FileBrowser.defaultProps = {
  showActionBar: true,
  canFilter: true,

  group: GroupByFolder,
  sort: SortByName,

  nestChildren: false,
  renderStyle: 'table',

  startOpen: false,

  headerRenderer: TableHeader,
  folderRenderer: TableFolder,
  fileRenderer: TableFile,
  detailRenderer: DefaultDetail,
};

FileBrowser.propTypes = {
  showActionBar: PropTypes.bool.isRequired,
  canFilter: PropTypes.bool.isRequired,

  group: PropTypes.func.isRequired,
  sort: PropTypes.func.isRequired,

  nestChildren: PropTypes.bool.isRequired,
  renderStyle: PropTypes.oneOf([
    'table',
  ]).isRequired,

  startOpen: PropTypes.bool.isRequired,

  headerRenderer: PropTypes.func,
  folderRenderer: PropTypes.func.isRequired,
  fileRenderer: PropTypes.func.isRequired,
  detailRenderer: PropTypes.func.isRequired,

  onFolderClick: PropTypes.func,
  rootPath: PropTypes.string
};

export default FileBrowser;
