import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import calendarImg from '../../../assets/images/filter/calendar.svg';
import getSeconds from "date-fns/getSeconds";
import setSeconds from "date-fns/setSeconds";
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";

class DateField extends Component {
  stepTimer = null;
  defaultTimer = null;
  valueTimer = null;

  clearDefaultTimer = () => {
    if (this.defaultTimer) {
      clearInterval(this.defaultTimer);
      this.defaultTimer = null;
    }
  };

  clearValueTimer = () => {
    if (this.valueTimer) {
      clearInterval(this.valueTimer);
      this.valueTimer = null;
    }
  };

  setDefaultTime = () => {
    const {id} = this.props;
    const inputs = document.getElementsByClassName('react-datepicker-time__input');
    if (inputs) {
      for (let input of inputs) {
        if (input.tagName === 'INPUT'/* && ['start', 'end'].includes(id)*/) {
          input.value = id === 'start' ? '00:00:00' : '23:59:59';
          break;
        }
      }
    }
  };

  setDefaultTimer = () => {
    this.clearDefaultTimer();
    this.defaultTimer = setInterval(this.setDefaultTime, 100);
  };

  getSecondsFromTimeInput = () => {
    const inputs = document.getElementsByClassName('react-datepicker-time__input');
    if (inputs) {
      for (let input of inputs) {
        if (input.tagName === 'INPUT') {
          if (input.value) {
            return Number(input.value.split(':').reverse()[0]);
          }
          break;
        }
      }
    }
    return null;
  };

  setSecondsInTimeInput = () => {
    const {selected} = this.props;
    if (selected) {
      const s = getSeconds(selected);
      if (s) {
        const inputs = document.getElementsByClassName('react-datepicker-time__input');
        if (inputs) {
          for (let input of inputs) {
            if (input.tagName === 'INPUT' && input.value) {
              const t = input.value.split(':');
              input.value = `${t[0]}:${t[1]}:${s < 10 ? '0' : ''}${s}`;
              break;
            }
          }
        }
      }
    }
  };

  setValueTimer = () => {
    this.clearValueTimer();
    this.valueTimer = setInterval(this.setSecondsInTimeInput, 100);
  };

  componentDidMount() {
    this.stepTimer = setInterval(() => {
        const inputs = document.getElementsByClassName('react-datepicker-time__input');
        if (inputs) {
          for (let input of inputs) {
            if (input.tagName === 'INPUT') {
              input.step = '1';
              break;
            }
          }
        }
    }, 50);
  }

  componentWillUnmount() {
    this.clearDefaultTimer();
    this.clearValueTimer();
    clearInterval(this.stepTimer);
  }

  render () {
    const {id, onChange, selected, showTime, dateFormat, styles} = this.props;
    const preparedProps = {...this.props};
    delete preparedProps.onChange;
    delete preparedProps.showTime;
    delete preparedProps.dateFormat;
    delete preparedProps.styles;

    const DatePickerCustomInput = React.forwardRef((inputProps, inputRef) => (
      <input
        {...inputProps}
        readOnly
        ref={inputRef}
      />
    ));

    return (
     <DateFieldStyled style={styles}>
      <DatePicker
        {...preparedProps}
        timeInputLabel="Time:"
        showTimeInput={typeof showTime !== 'boolean' || showTime}
        timeFormat="HH:mm:ss"
        dateFormat={dateFormat || 'MM/dd/yyyy, HH:mm:ss'}
        timeInputStep={1}
        onFocus={() => {
          if (!selected) {
            this.setDefaultTimer();
            this.clearValueTimer();
          } else {
            this.clearDefaultTimer();
            this.setValueTimer();
          }
        }}
        onClickOutside={() => {
          this.clearDefaultTimer();
          this.clearValueTimer();
        }}
        onChange={(date, t) => {
          this.clearDefaultTimer();
          this.clearValueTimer();
          if (!date) {
            this.setDefaultTimer();
            this.setValueTimer();
          }
          if (date && !selected && id === 'end' && t !== undefined) {
            date = setHours(setMinutes(setSeconds(date, 59), 59), 23);
          } else if (date && t === undefined) {
            const sec = this.getSecondsFromTimeInput();
            if (sec >=0 && sec <= 59) {
              date = setSeconds(date, sec);
            }
          }
          onChange(date);
        }}
        customInput={<DatePickerCustomInput/>}
        isClearable
      />
     </DateFieldStyled>
    );
  }
}

DateField.propTypes = {
  id: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
};

const DateFieldStyled = styled.div`
  .react-datepicker-wrapper, .react-datepicker__input-container {
    display: block;
  }
  input {
    background: url(${calendarImg}) no-repeat right 5px center;
    border: 1px solid #E9E9E9;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    border-radius: 3px;
    font-size: 10px;
    line-height: 11px;
    height: 27px;
    cursor: pointer;
    padding: 7px 40px 7px 9px;
    width: 100%;
  }
  .react-datepicker__close-icon {
    top: calc((100% - 16px) / 2);
    right: 23px;
  }
  .react-datepicker__input-time-container {
    margin-left: 0;
    > .react-datepicker-time__caption {
      margin-left: 10px;
    }
    > .react-datepicker-time__input-container {
      > .react-datepicker-time__input {
        margin-left: 0;
        input[type="time"] {
          background-image: none;
          padding-right: 0;
          padding-left: 2px;
          width: 95px;
          &::-webkit-datetime-edit-fields-wrapper {
            margin-left: 3px;
          }
          &::-webkit-inner-spin-button, &::-webkit-outer-spin-button, &::-webkit-clear-button {
            display: none;
          }
        }
      }
    }
  }

  .react-datepicker {
    font-size: 0.8em;
  }
  .react-datepicker__header {
    padding-top: 0.8em;
  }
  .react-datepicker__month {
    margin: 0.4em 1em;
  }
  .react-datepicker__day-name, .react-datepicker__day {
    width: 1.7em;
    line-height: 1.7em;
    margin: 0.166em;
  }
  .react-datepicker__current-month {
    font-size: 0.9em;
  }
  .react-datepicker__navigation {
    line-height: 1.7em;
    border: 0.45em solid transparent;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
  }
  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
  }
  .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
    width: unset;
  }
`;

export default DateField;
