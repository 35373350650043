import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import {
  setBugScreenshotUrl,
  setReportAvailable,
  setReportBugModalInNewTabData,
  showError,
  showSuccess,
  showWarning,
  toggleReportBugModal,
} from "../../actions";
import styled from "styled-components";
import ReportBugModalBody from "./ReportBugModalBody";
import config from "../../../../config";
import { getErrorMessage } from "../../../utils";
import {getAdditionalDataAjax, getBugReports} from "../../../pages/IssueBrowser/actions";
import RndModal from '../../../components/modals/RndModal';

class ReportBugModal extends Component {
  state = {
    description: (this.props.inNewTabData || {}).description || "",
    blockingIssue: (this.props.inNewTabData || {}).blockingIssue || false,
    category: (this.props.inNewTabData || {}).category || null,
    additionalData: {},
  };

  componentDidMount() {
    getAdditionalDataAjax()
      .done((res) => {
        this.setState({additionalData: res || {}});
      })
      .fail((error) => {
        console.log(error);
      });
  }

  componentDidUpdate(prevProps) {
    const {inNewTabData} = this.props;
    if (inNewTabData && !prevProps.inNewTabData) {
      const {description, blockingIssue, category} = inNewTabData;
      this.setState({description, blockingIssue, category});
    }
  }

  onClose = async () => {
    const { dispatch } = this.props;
    dispatch(toggleReportBugModal(false));
    this.clearData();
  };

  b64toBlob = (dataURI) => {
    var byteString = atob(dataURI.split(",")[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: "image/png" });
  };

  onReport = () => {
    const { dispatch, bugScreenshotUrl, reportAvailable, appliedNavigationString, inNewTabData } = this.props;
    const { description, blockingIssue, category } = this.state;

    const formData = new FormData();

    formData.append("file", this.b64toBlob(bugScreenshotUrl), "ReportImg.png");
    formData.append("message", description);
    formData.append("url", document.location.href);
    if (appliedNavigationString) {
      formData.append("fileName", appliedNavigationString);
    }
    if (blockingIssue) {
      formData.append("blocking_issue", blockingIssue);
    }
    if (category) {
      formData.append("category", category);
    }

    if (reportAvailable) {
      return $.ajax({
        method: "POST",
        url: `${config.apiUrl}/v1/bug_reports/report_bug`,
        data: formData,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done((res) => {
          dispatch(toggleReportBugModal(false));
          dispatch(showSuccess("Bug report sent successfully"));
          this.clearData();
          if (inNewTabData) {
            dispatch(getBugReports());
          }
        })
        .fail((error) => {
          dispatch(toggleReportBugModal(false));
          dispatch(
            showError(
              `Could not send the bug report. ${getErrorMessage(error)}`
            )
          );
          this.clearData();
        });
    }

    dispatch(showWarning("Unable to submit report, fill in description"));
  };

  handleDescriptionChange = (value) => {
    this.setState({ description: value });
  };

  handleChangeBlockingIssue = ({target: {checked}}) => {
    this.setState({ blockingIssue: checked });
  };

  handleChangeCategory = ({value}) => {
    this.setState({ category: value });
  };

  handleClickReportLaterInNewTab = () => {
    const {dispatch, bugScreenshotUrl, reportAvailable} = this.props;
    const {description, blockingIssue, category} = this.state;
    console.log(localStorage.setItem('mamReportLaterInNewTab', JSON.stringify({
      bugScreenshotUrl, description, blockingIssue, category, reportAvailable
    })));
    const newTab = window.open('/issues','_blank');
    if (newTab) {
      this.onClose();
    } else {
      localStorage.removeItem('mamReportLaterInNewTab');
      dispatch(showWarning('Unable to move report in new tab'));
    }
  };

  clearData() {
    const { dispatch } = this.props;
    dispatch(setReportAvailable(false));
    dispatch(setBugScreenshotUrl(""));
    dispatch(setReportBugModalInNewTabData(null));
    this.setState({ description: "", blockingIssue: false, category: null });
  }

  render() {
    const { isOpen, bugScreenshotUrl, loading, reportAvailable, inNewTabData } = this.props;
    const { description, blockingIssue, category, additionalData } = this.state;

    const reportDisabled = loading || !reportAvailable;

    return (
      <div>
        <RndModalStyled
          className="report-bug-modal"
          show={isOpen}
          onHide={this.onClose}
          backdrop="static"
          enforceFocus={false}
          bsSize="large"
        >
          <Modal.Header closeButton>
            <Modal.Title>Report an Issue</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ModalContainer>
              <ReportBugModalBody
                loading={loading}
                bugScreenshotUrl={bugScreenshotUrl}
                description={description}
                onChangeDescription={this.handleDescriptionChange}
                onChangeBlockingIssue={this.handleChangeBlockingIssue}
                blockingIssue={blockingIssue}
                category={category}
                onChangeCategory={this.handleChangeCategory}
                additionalData={additionalData}
              />
            </ModalContainer>
          </Modal.Body>
          <Modal.Footer>
            {!inNewTabData && !loading &&
              <Button
                className="btn-report-later-in-new-tab"
                bsStyle="primary"
                onClick={this.handleClickReportLaterInNewTab}
              >
                Report later in new tab
              </Button>
            }
            <Button
              bsStyle="primary"
              disabled={reportDisabled}
              onClick={() => this.onReport()}
            >
              Report
            </Button>
            <Button bsStyle="default" disabled={loading} onClick={this.onClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </RndModalStyled>
      </div>
    );
  }
}

const ModalContainer = styled.div`
  min-height: 200px;
  .loader-container {
    z-index: 1051;
  }
`;
const RndModalStyled = styled(RndModal)`
    z-index: 100001;
`;
function mapStateToProps(state) {
  return {
    inNewTabData: state.main.reportBugModalState.inNewTabData,
    isOpen: state.main.reportBugModalState.isOpen,
    bugScreenshotUrl: state.main.reportBugModalState.bugScreenshotUrl,
    loading: state.main.reportBugModalState.loading,
    reportAvailable: state.main.reportBugModalState.reportAvailable,
    appliedNavigationString: state.storageManager.appliedNavigationString
  };
}

export default connect(mapStateToProps)(ReportBugModal);
