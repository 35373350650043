import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styles from 'styled-components';
import Alerts from './components/Alerts';
import Header from './components/Header';
import 'bootstrap';
import {getActionsUserPermissionsWithoutRoots} from '../pages/StorageManager/actions';
import {FS_ACTION_TYPE__UPLOAD_TO_ASPERA} from '../pages/StorageManager/constants';
import {isForbiddenActionByUserPermissions} from '../utils';
import ReportBugModal from './components/ReportBugModal/index';
import SuccessPopup from './components/SuccessPopup';

class AppLayout extends Component {

  componentDidMount() {
    const {title, dispatch, history, match} = this.props;
    document.title = `MAM: ${title}`;
    if (match.path !== '/storage') {
      dispatch(getActionsUserPermissionsWithoutRoots())
        .then(
          permissions => {
            try {
              if (match.path === '/faspex' &&
                  isForbiddenActionByUserPermissions(permissions || [], FS_ACTION_TYPE__UPLOAD_TO_ASPERA)) {
                history.push('/storage');
              }
            } catch {}
          }
        );
    }
  }

  render() {
    const {className, user, filters} = this.props;
    return (
      <div className={className}>
        {!user.isLogin ? null :
        <Fragment>
          <Header/>
          {filters || null}
          <LayoutStyled className="main-layout">
            <main>
              <ReportBugModal />
              {this.props.children}
            </main>
          </LayoutStyled>
          <Alerts/>
          <SuccessPopup/>
        </Fragment>
        }
      </div>
    );
  }
}

const LayoutStyled = styles.div`
  overflow: hidden;
  position: relative;

  main {
    width: 100%;
  }
`;

AppLayout.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  title: PropTypes.string,
  className: PropTypes.string,
  filters: PropTypes.element,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(
  mapStateToProps
)(withRouter(AppLayout));
