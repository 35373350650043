import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class LoginForm extends Component {
  render() {
    const {onLogin, email, onChange} = this.props;
    return (
      <LoginFormContainer className="container">
        <div className="wrapper">
          <form className="form-signin" action="#">
            <h3 className="form-signin-heading">Eurofins DMS</h3>
            <br/>
            <input
              type="email"
              className="form-control"
              name="email"
              placeholder="EDMS\username"
              autoComplete="off"
              required
              autoFocus
              pattern="[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,4}$"
              value={email}
              onChange={onChange}
              />
            <input
              type="password"
              className="form-control"
              name="password"
              placeholder="Password"
              autoComplete="off"
              required
              onChange={onChange}
              />
            <button
              className="btn btn-lg btn-primary btn-block"
              name="Submit"
              value="Login"
              onClick={onLogin}
              >
              Login
            </button>
          </form>
        </div>
      </LoginFormContainer>
    );
  }
}

const LoginFormContainer = styled.div`
  .wrapper {
    margin-top: 120px;
    margin-bottom: 20px;
  }

  .form-signin {
    max-width: 420px;
    padding: 30px 38px 66px;
    margin: 0 auto;
    background-color: #fff;
  }

  .form-signin-heading {
    text-align: center;
    margin-bottom: 30px;
  }

  .form-control {
    position: relative;
    font-size: 16px;
    height: auto;
    padding: 10px;
  }

  input[type="email"] {
    margin-bottom: 20px;
  }

  input[type="password"] {
    margin-bottom: 20px;
  }
`;

LoginForm.propTypes = {
  email: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  onHideMessage: PropTypes.func.isRequired
};

export default LoginForm;
