import {USER_SET_IS_LOGOUT} from '../../pages/Login/actions';
import {
  NOTIFICATIONS_IS_LOADING, NOTIFICATIONS_RECEIVE
} from './actions';

const initialState = {
  loading: false,
  notifications: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SET_IS_LOGOUT: {
      return {...initialState};
    }

    case NOTIFICATIONS_IS_LOADING: {
      return {...state, loading: action.loading};
    }

    case NOTIFICATIONS_RECEIVE: {
      return {
        ...state,
        loading: false,
        notifications: action.notifications
      };
    }

    default:
      return state;
  }
}
