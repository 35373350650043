import styled from "styled-components";

const InfoPanelStyled = styled.div`
  padding: 5px 15px 75px 15px;
  border-left: 3px solid #ddd;
  min-height: 100%;
  max-height: calc(100vh - 71px - 150px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  transition: width 0.3s ease-in-out 0s, display 0s ease-in-out 0.3s;
  font-size: 1.2rem;

  @media (max-width: 767px) {
    max-height: calc(100vh - 71px - 210px);
  }

  .info-panel-collapsed & {
    width: 0;
    display: none;
  }

  .col-md-8,
  .col-xs-8 {
    word-break: break-all;
  }

  .issue-browser-info-field--Status {
    .Select {
      margin-bottom: 10px;
      > .Select-control {
        border-radius: 3px !important;
      }
    }
  }

  .issue-browser-info-field--Comments {
    margin-top: 10px;
    .issue-browser--comment-form {
      margin-bottom: 10px;
      border-radius: 3px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.05), 0 0 0 1px #e9e9e9 !important;
      &.is-focused {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15), 0 0 0 1px #e9e9e9 !important;
      }
      &.has-toolbar {
        margin-bottom: 37px;
      }
      i {
        display: none !important;
      }
      textarea.form-control {
        padding: 10px 12px !important;
        border-radius: 4px !important;
      }
    }
  }
`;

export default InfoPanelStyled;
