import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import Table from '../table/UploadFiles/Table';
import RndModal from '../../../../components/modals/RndModal';

class UploadedFilesModal extends Component {
  componentWillUnmount() {
    this.props.onClose();
  }

  render() {
    const {onClose, data} = this.props;
    return (
      <RndModal
        bsSize="large"
        show={true}
        onHide={onClose}
        backdrop="static"
        enforceFocus={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>Uploaded files</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <Table
              data={data}
            />
          </div>
        </Modal.Body>
      </RndModal>
    );
  }
}

UploadedFilesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired
};

export default UploadedFilesModal;
