import React from 'react';
import PropTypes from 'prop-types';

export const PredictiveStatusCell = ({value, className, getStatus}) => {
  const status = getStatus(value) || {};
  return (
    <td className={className} title={status.Description}>
      {status.StateName}
    </td>
  )
};

PredictiveStatusCell.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string,
  getStatus: PropTypes.func.isRequired
};
