import React, { Component } from "react";
import TextEllipsisAdvanced from '../../../../../components/TextEllipsis/TextEllipsisAdvanced';

export default class DescriptionCell extends Component {
  render() {
    const { value, className } = this.props;

    return (
      <td className={className}>
        <TextEllipsisAdvanced
          data={value}
          noCopyCtrl
          nonMonospaceFont
        />
      </td>
    );
  }
}
