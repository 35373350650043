import React, { Component } from 'react';
import PropTypes from "prop-types";
import {Button, Checkbox, Modal} from "react-bootstrap";
import {convertCaptions, showCaptionsModal} from "../../actions";
import TextEllipsisAdvanced from "../../../../components/TextEllipsis/TextEllipsisAdvanced";
import { parseFileExtension } from '../../utils';
import RndModal from "../../../../components/modals/RndModal";

const CONVERTED_FILE_EXTENSION = '.vtt';

class CaptionsModal extends Component {
  state = {
    isSendToQOD: false,
    newFileName: ''
  };

  handleConvertCaptions = () => {
    const {dispatch} = this.props;
    const {isSendToQOD, newFileName} = this.state;
    dispatch(convertCaptions(isSendToQOD, newFileName));
  }

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(showCaptionsModal(false));
  };

  componentDidMount() {
    const {file} = this.props;
    const ext = parseFileExtension(file.key);
    const fileName = file.key.replace('.' + ext, CONVERTED_FILE_EXTENSION);
    this.setState({newFileName: fileName})
  }

  componentWillUnmount() {
    this.handleClose();
  }

  render() {
    const {isSendToQOD, newFileName} = this.state;

    return (
      <RndModal
        show={true}
        onHide={this.handleClose}
        backdrop="static"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Convert to VTT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{color: 'blue', fontWeight: 'bold', marginBottom: '28px'}}>
            <TextEllipsisAdvanced className="value" data={newFileName} noCopyCtrl/>
          </div>
          <div>
            <Checkbox
              checked={isSendToQOD}
              onChange={({target: {checked}}) => this.setState({isSendToQOD: checked})}
            >
              Send to QOD
            </Checkbox>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.handleConvertCaptions}>
            Convert
          </Button>
          <Button bsStyle="default" onClick={this.handleClose}>Cancel</Button>
        </Modal.Footer>
      </RndModal>
    );
  }
}

CaptionsModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired
};

export default CaptionsModal;
