import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {FormControl} from 'react-bootstrap';
import DateField from '../../../components/filters/DateField';

class DaysBackFrom extends Component {

  handleChange = (name, value) => {
    const {onChange} = this.props;
    onChange(name, value);
  };

  handleChangeDaysBack = event => {
    let value = parseInt(event.target.value, 10);
    if (isNaN(value)) {
      value = 3;
    }
    this.handleChange('daysBack', value);
  };

  handleChangeFromDate = date => {
    let value = null;
    if (date) {
      try {
        value = date.getTime();
      } catch(ignored) {/**/}
    }
    this.handleChange('fromDate', value);
  };

  prepareDate = value => {
    const date = new Date(value);
    const res = date.getTime() ? date : null;
    return res || null;
  };

  render() {
    const {fromDate, daysBack} = this.props;
    return (
      <DaysBackFromStyled>
        <FormControl
          value={daysBack}
          min={0}
          type="number"
          onChange={this.handleChangeDaysBack}
          onBlur={this.handleChangeDaysBack}
          inputRef={ref => {
            this._input = ref;
          }}
        />
        <span>
          days back from
        </span>
        <DateField
          id="end"
          placeholderText="Now"
          selected={this.prepareDate(fromDate)}
          selectsStart
          maxDate={new Date()}
          onChange={this.handleChangeFromDate}
        />
      </DaysBackFromStyled>
    );
  }
}

const DaysBackFromStyled = styled.div`
  display: flex;
  align-items: center;

  input[type="number"] {
    width: unset;
    max-width: 30%;
  }

  span {
    flex-grow: 1;
    padding: 0 10px;
    min-width: fit-content;
    text-align: center;
    font-size: 10px;

    + div {
      flex-grow: 2;
    }
  }
`;

DaysBackFrom.propTypes = {
  daysBack: PropTypes.number.isRequired,
  fromDate: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

export default DaysBackFrom;