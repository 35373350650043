import {createGlobalStyle} from 'styled-components';

import segoeUiBoldFont from './assets/fonts/segoe-ui/Segoe UI Bold.woff';
import segoeUiBoldItalicFont from './assets/fonts/segoe-ui/Segoe UI Bold Italic.woff';
import segoeUiRegularFont from './assets/fonts/segoe-ui/Segoe UI.woff';
import segoeUiRegularItalicFont from './assets/fonts/segoe-ui/Segoe UI Italic.woff';

export default createGlobalStyle`
  @font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    src: local('Segoe UI'), url(${segoeUiRegularFont}) format('woff');
  }
  @font-face {
    font-family: 'Segoe UI';
    font-style: italic;
    font-weight: 400;
    src: local('Segoe UI Italic'), url(${segoeUiRegularItalicFont}) format('woff');
  }
  @font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    src: local('Segoe UI Bold'), url(${segoeUiBoldFont}) format('woff');
  }
  @font-face {
    font-family: 'Segoe UI';
    font-style: italic;
    font-weight: 700;
    src: local('Segoe UI Bold Italic'), url(${segoeUiBoldItalicFont}) format('woff');
  }

  body:not(.login-layout) {
    padding-top: 70px;
    overflow-y: scroll;

    div.container {
      width: 100%;
    }

    .nprogress-busy & {
      * :not('.report-bug-modal'){
        pointer-events: none;
        cursor: wait;
      }
      nav + div, nav + div + div {
        opacity: 0.8;
      }
    }

    .popover.message-details-popover {
      z-index: 1020;

      .popover-content {
        font-size: 10px;
      }

      &.link-popover {

        div.msg-link {
          word-break: break-all;
        }

        input.copy-link {
          background-color: transparent;
          color: transparent;
          border: 0;
          outline: 0;
          margin: 0;
          padding: 0;
          width: 1px;
          height: 0;
        }
      }

      &.message-popover {
        left: unset !important;
        right: calc(25% + 8px);
        max-width: unset;
        width: calc(75% - 25px);

        .row {

          &:not(:last-child) {
            margin-bottom: 5px;
          }

          .field {
            font-weight: bold;

            & + div {

              iframe {
                width: 100%;
                overflow: auto;
                border: 1px solid #ccc;
                border-radius: 3px;
              }
            }
          }
        }
      }
    }

    .filter-popover {
      .popover-content {
        font-size: 11px;
      }
    }

    > #text-ellipsis-tooltip {
      .tooltip-inner {
        text-align: left;
        word-break: break-all;
      }
    }
  }

  .react-confirm-alert-body {
    word-break: break-word;
    h1 {
      font-size: 20px;
      margin-bottom: 20px;
    }
    .react-confirm-alert-button-group {
      display: flex;
      justify-content: flex-end;
      button {
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
        background: transparent;
        font-weight: bold;
        font-size: 15px;
        line-height: 21px;
        &:first-child {
          margin-right: 25px;
          color: #bbb;
          &:hover {
            color: #888;
          }
        }
        &:last-child {
          color: #2e6da4;
          &:hover {
            color: #265a88;
          }
        }
      }
    }
    .custom-msg {
      font-size: 15px;
    }
  }
  .react-confirm-alert-overlay {
    z-index: 1051;
  }

  .tooltip {
    font-size: 13px;
  }
  .tooltip.right .tooltip-arrow {
    border-right-color: #282828;
  }
  .tooltip.bottom .tooltip-arrow {
    border-bottom-color: #282828;
  }
  .tooltip .tooltip-inner {
    background-color: #282828;
  }
`;
