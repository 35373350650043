import styled from 'styled-components';
import expandImg from '../../../assets/images/filter/expand.svg';

const HEIGHT = '25px';

const FilterSelectStyled = styled.div`
  .Select.has-value.Select--single,
  .Select.Select--single,
  .Select--multi {
    &.is-focused {
      outline: none;
    }
    &.is-disabled > .Select-control {
      background-color: #F3F3F3;
      cursor: not-allowed;
    }
    > .Select-control {
      background: #fff;
      border: 1px solid #E9E9E9;
      border-radius: 3px;
      box-shadow: none;
      cursor: pointer;
      height: ${HEIGHT};

      .Select-multi-value-wrapper {
        height: ${HEIGHT};

        .Select-placeholder,
        .Select-value {
          line-height: 10px;
          font-size: 10px;
          padding: 9px;

          .Select-value-label {
            font-size: 10px !important;
            font-weight: normal;
          }
        }

        .Select-input {
          height: ${HEIGHT};
          input {
            font-size: 10px !important;
            height: 100%;
            padding: 0;
          }
        }
      }

      .Select-clear-zone {
        display: none;
      }
      .Select-arrow-zone .Select-arrow {
        background: url(${expandImg}) no-repeat right 5px center transparent;
        background-size: 7px;
        border: none;
        height: 10px;
        width: 100%;
      }
    }

    &.is-open {
      .Select-control {
        border-radius: 3px;
        .Select-control {
          border-radius: 3px;
        }
        .Select-arrow {
          top: 0;
        }
      }
    }
  }

  .Select-menu-outer {
    border-radius: 3px;

    .Select-menu {
      border-radius: 3px;
      border: 1px solid #E9E9E9;
    }

    .Select-option {
      background-color: #F5F5F5;
      font-size: 10px !important;
      &.is-selected, &.is-focused {
        background-color: #fff;
        font-weight: bold;
      }

      &.is-disabled {
        cursor: not-allowed;
        background-color: #F1F1F1;
        opacity: 0.3;
        &.is-selected {
          background-color: #F1F1F1;
        }
      }

      span:after {
        content: attr(data-title);
      }
    }
  }
`;

export default FilterSelectStyled;
