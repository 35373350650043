import React from 'react';
import PropTypes from 'prop-types';
import {HeaderCell} from '../../components/table/cells/HeaderCell';

export const HeaderRow = ({headers, sort, onHeaderCellClick}) => (
  <thead>
    <tr>
      {Object.keys(headers).map((k, i) => {
        const customHeaderCell = headers[k].headCell;
        return customHeaderCell && customHeaderCell.component && customHeaderCell.componentArgs ?
          <customHeaderCell.component {...customHeaderCell.componentArgs} key={i}/> :
          <HeaderCell headers={headers} sort={sort} onClick={onHeaderCellClick} field={k} key={i}/>;
      })}
    </tr>
  </thead>
);
HeaderRow.propTypes = {
  headers: PropTypes.object.isRequired,
  sort: PropTypes.object,
  onHeaderCellClick: PropTypes.func
};
