import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { FormGroup, ControlLabel, FormControl, Checkbox } from "react-bootstrap";
import Loader from "../../../components/Loader";
import { setReportAvailable } from "../../actions";
import CharCounter from "./CharCounter";
import {FilterSelectStyled} from '../../../components/filters/FiltersStyled';
import Select from 'react-select';

class ReportBugModalBody extends Component {
  handleChangeDescription = (event) => {
    const {
      dispatch,
      minCharacterCount,
      onChangeDescription,
      reportAvailable,
    } = this.props;

    if (event.target.value.length >= minCharacterCount) {
      if (!reportAvailable) {
        dispatch(setReportAvailable(true));
      }
    } else if (event.target.value.length < minCharacterCount) {
      if (reportAvailable) {
        reportAvailable && dispatch(setReportAvailable(false));
      }
    }

    onChangeDescription(event.target.value);
  };

  render() {
    const {
      loading,
      bugScreenshotUrl,
      reportAvailable,
      minCharacterCount,
      description,
      blockingIssue,
      onChangeBlockingIssue,
      category,
      onChangeCategory,
      additionalData,
    } = this.props;

    const validationState = reportAvailable ? "success" : "error";
    const categories = (additionalData.categories || []).map(({CategoryName, CategoryLabel}) => ({value: CategoryName, label: CategoryLabel}));

    if (loading) {
      return <Loader />;
    }
    return (
      <div>
        <ScreenshotContainerStyled>
          <BugScreenshotStyled src={bugScreenshotUrl} />
        </ScreenshotContainerStyled>
        <FormContainerStyled>
          <FormGroup
            controlId="formControlsTextarea"
            validationState={validationState}
          >
            <ControlLabel>Bug description</ControlLabel>
            <FormControl
              componentClass="textarea"
              placeholder="Description..."
              value={description}
              onChange={this.handleChangeDescription}
              style={{resize: 'vertical'}}
            />
          </FormGroup>
          <CharCounter
            minCharacterCount={minCharacterCount}
            currentCharacterCount={description.length}
            valid={reportAvailable}
          />
          <CheckboxStyled
            checked={blockingIssue}
            onChange={onChangeBlockingIssue}
          >
            {"It's a blocking issue"}
          </CheckboxStyled>
          {!!categories.length &&
            <SelectStyled>
              <label>Category</label>
              <Select
                value={category}
                options={categories}
                onChange={onChangeCategory}
              />
            </SelectStyled>
          }
        </FormContainerStyled>
      </div>
    );
  }
}

const ScreenshotContainerStyled = styled.div`
  border: 2px solid #337ab7;
`;

const FormContainerStyled = styled.div`
  padding: 20px 0;
`;

const BugScreenshotStyled = styled.img`
  width: 100%;
`;

const CheckboxStyled = styled(Checkbox)`
  margin-top: 20px;
`;

const SelectStyled = styled(FilterSelectStyled)`
  box-shadow: none;
`;

function mapStateToProps(state) {
  return {
    bugScreenshotUrl: state.main.reportBugModalState.bugScreenshotUrl,
    loading: state.main.reportBugModalState.loading,
    reportAvailable: state.main.reportBugModalState.reportAvailable,
    minCharacterCount: state.main.reportBugModalState.minCharacterCount,
  };
}

export default connect(mapStateToProps)(ReportBugModalBody);
