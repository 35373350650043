import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, FormGroup, FormControl, ControlLabel, ButtonGroup } from 'react-bootstrap';
import { PathFieldStyled } from './ModalStyled';
import styled from 'styled-components';
import {
  renameOfContentItem,
  checkSpecSymbolsOrGetRenameData,
  openDeliveryRenamePopup,
  renameSpecSymbols,
} from '../../actions';
import { confirmAlert } from 'react-confirm-alert';
import { isAsciiString, isValidFileName } from '../../../../utils';
import { parseFileExtension, isTooLongName, MAX_FILENAME_LENGTH_TO_UPLOAD, getValueForOS } from '../../utils';
import TextEllipsisAdvanced from '../../../../components/TextEllipsis/TextEllipsisAdvanced';
import RndModal from '../../../../components/modals/RndModal';
import { FS_ACTION_TYPE__MOVE } from '../../constants';

class RenameModal extends Component {
  state = {
    inputValue: this.props.item.name,
    showInvalidPassword: false,
    encPassword: '',
    encState: false
  };

  componentDidMount() {
    const { dispatch, item, onRename } = this.props;
    if (!onRename) {
      dispatch(checkSpecSymbolsOrGetRenameData(FS_ACTION_TYPE__MOVE, [item], item.memfisWoID))
        .then(res => {
          const { needs_rename, rename_mapping, delivery_rename_available } = res;
          this.setState({ needs_rename, rename_mapping, delivery_rename_available });
        });
    }
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  isInvalidName = () => {
    const { isAlreadyExistsContent, item } = this.props;
    const { inputValue } = this.state;
    return !isValidFileName(inputValue) || !isAsciiString(inputValue) || (!!isAlreadyExistsContent && isAlreadyExistsContent(inputValue, item.isDirectory));
  };

  handleChangeName = event => {
    this.setState({ inputValue: event.target.value });
  };

  handleOnChange = () => {
    if (document.getElementById("checkbox-decrypt") != null) {
      this.setState({ encState: document.getElementById("checkbox-decrypt").checked });
    } else {
      this.setState({ encState: false });
    }
  };

  handleClickRename = () => {
    const { dispatch, item, onClose, onRename } = this.props;
    const { inputValue, encState, encPassword } = this.state;
    confirmAlert({
      title: 'Are You Sure?',
      message: `You're about to rename "${item.name}" to "${inputValue}". Are you sure?`,
      buttons: [{
        label: 'No'
      }, {
        label: 'Yes',
        onClick: () => {
          if (onRename) {
            onRename(inputValue);
            onClose();
          } else {
            dispatch(renameOfContentItem(inputValue, encState, encPassword)).then(() => onClose());
          }
        }
      }]
    });
  };

  handleClickRenameSpecSymbols = () => {
    const { dispatch, item, onClose } = this.props;
    const { rename_mapping } = this.state;
    confirmAlert({
      childrenElement: () =>
        <div className="custom-msg" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
          Needs to rename the following content:
          <ul>
            {Object.entries(rename_mapping).map(([k, v], i) => (
              <li key={i} style={{ marginTop: '4px', fontSize: '12px' }}>
                <span style={{ opacity: 0.8 }}>{k}</span>
                <span style={{ margin: '0 7px' }}><i className="fa-duotone fa-square-right"></i></span>
                <span>{v}</span>
              </li>
            ))}
          </ul>
        </div>,
      buttons: [{
        label: 'Cancel'
      }, {
        label: 'Rename',
        onClick: () => {
          dispatch(renameSpecSymbols(FS_ACTION_TYPE__MOVE, [item], onClose));
        }
      }]
    });
  };

  handleClickDeliveryRename = () => {
    const { dispatch, item } = this.props;
    dispatch(openDeliveryRenamePopup(FS_ACTION_TYPE__MOVE, [item], item.memfisWoID));
  };

  getValidationState = () => {
    const { item: { name, isDirectory } } = this.props;
    const { inputValue, encPassword } = this.state;
    if (this.isInvalidName()) {
      return 'error';
    }
    if (isTooLongName(inputValue, isDirectory)) {
      return 'warning';
    }
    if (inputValue.toLowerCase() !== name.toLowerCase()) {
      if (document.getElementById("checkbox-decrypt") != null) {
        if (document.getElementById("checkbox-decrypt").checked && encPassword === "") {
          return 'error-checkbox'
        } else {
          return 'success';
        }
      } else {
        return 'success';
      }
    }

    return null;
  };

  render() {
    const { onClose, item, onRename } = this.props;
    const { isDirectory } = item;
    const { inputValue, needs_rename, rename_mapping, delivery_rename_available, encState, showInvalidPassword, isVisiblePassword, encPassword } = this.state;
    const validationState = this.getValidationState();
    const allowRenameSpecSymbols = Boolean(needs_rename && rename_mapping && Object.keys(rename_mapping).length);
    const allowDeliveryRename = !!delivery_rename_available;
    return (
      <RndModal
        className={`move-modal${onRename ? '' : ' rename-modal'}`}
        show={true}
        onHide={onClose}
        backdrop="static"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Rename
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <FormGroupStyled>
              <ControlLabel>Path</ControlLabel>
              <TextEllipsisAdvanced data={getValueForOS(item, 'fullPath')} />
            </FormGroupStyled>
            <FormGroupStyled>
              <ControlLabel>Name</ControlLabel>
              <TextEllipsisAdvanced data={getValueForOS(item, 'name')} />
            </FormGroupStyled>
            <FormGroupStyled validationState={validationState}>
              <ControlLabel>New Name</ControlLabel>
              <FormControl
                value={inputValue}
                onChange={this.handleChangeName}
                onBlur={this.handleChangeName}
                maxLength={MAX_FILENAME_LENGTH_TO_UPLOAD + (isDirectory ? 0 : (parseFileExtension(item.name).length + 1))}
              />
            </FormGroupStyled>
            {inputValue.toUpperCase().startsWith("ENC-") && item.encData == null &&
              <CustomPathFieldStyled color="black">
                <div className="checkbox-div">
                  <CheckboxStyled className="checkbox-label">
                    <FormControl
                      id="checkbox-decrypt"
                      name="checkbox-decrypt"
                      type="checkbox"
                      defaultChecked={encState}
                      onChange={() => this.handleOnChange()}
                    />
                  </CheckboxStyled>
                </div>
                <label className="decrypt-label">Store encryption password</label>

                <PasswordField className="password-div" validationState={showInvalidPassword ? 'error' : null}>
                  <FormControl
                    id="enc-password"
                    name="enc-password"
                    type={isVisiblePassword ? 'text' : 'password'}
                    value={encPassword}
                    autoComplete="off"
                    onChange={event => this.setState({ encPassword: event.target.value || '' })}
                    className="enc-password"
                  />
                  <i
                    className={`far fa-eye${isVisiblePassword ? '' : '-slash'}`}
                    onClick={() => this.setState({ isVisiblePassword: !isVisiblePassword })}
                  />
                </PasswordField>
              </CustomPathFieldStyled>
            }
            {(allowRenameSpecSymbols || allowDeliveryRename) &&
              <ButtonGroup>
                {allowRenameSpecSymbols &&
                  <Button
                    onClick={this.handleClickRenameSpecSymbols}
                  >
                    Rename Spec Symbols
                  </Button>
                }
                {allowDeliveryRename &&
                  <Button
                    onClick={this.handleClickDeliveryRename}
                  >
                    Delivery Rename
                  </Button>
                }
              </ButtonGroup>
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="primary"
            onClick={this.handleClickRename}
            disabled={validationState !== 'success'}
          >
            Rename
          </Button>
          <Button bsStyle="default" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
      </RndModal>
    );
  }
}

const CustomPathFieldStyled = styled(PathFieldStyled)`
  align-items: center;

  .decrypt-label{
    flex: 0 0 190px;
    margin-left:5px;
  }

  .checkbox-label{
    margin-left:5px;
    display: flex;
  }

  .checkbox-div{
    margin-top:10px;
    white-space:nowrap;
    font-weight: bold;
  }

  .span-div{
    margin-left:5px;
  }

  .password-div{
    margin-top: 0px;
  }

  .fs-root-select,
  .fs-file-type-select {
    width: 65px;
  }

  label {
    min-width: ${props => `${props.labelWidth || 115}px`};
    + div {
      max-width: calc(100% - ${props => `${props.labelWidth || 115}px`});
      &.value {
        &:not(.text--ellipsis--advanced) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-grow: 1;
          > .text--ellipsis--advanced {
            max-width: 100%;
          }
          &.with-btn {
            > .text--ellipsis--advanced {
              max-width: calc(100% - 55px);
            }
          }
        }
      }
    }

    &.min-w-80 {
      min-width: 80px;
      + div {
        max-width: calc(100% - 80px);
      }
    }
  }

  .fs-project-type-select {
    margin-right: 5px;
  }

  button.reset-btn {
    height: 27px;
    padding: 1px 10px;
  }
`;

const PasswordField = styled(FormGroup)`
  margin: 20px 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  label {
    margin: 0 5px 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: default;
  }
  input {
    padding-left: 8px;
    padding-right: 30px;
    height: 30px;
    cursor: text;
    width: 100% !important;
  }
  i[class*="fa-eye"] {
    position: absolute;
    top: 0;
    right: 6px;
    line-height: 30px;
    width: 18px;
    text-align: center;
    cursor: pointer;
    opacity: 0.45;
    :hover {
      opacity: 0.6;
    }
  }
  i[class*="fa-question"] {
    margin-left: 2px;
    color: #333 !important;
    opacity: 0.8;
  }
`;

const FormGroupStyled = styled(FormGroup)`
  display: flex;
  align-items: center;
  .control-label {
    margin: 0;
    padding: 0;
    flex: 0 0 110px;
    + div, + .form-control {
      flex-grow: 1;
      max-width: calc(100% - 110px);
    }
  }
  .form-control {
    font-family: monospace;
  }
`;

export const CheckboxStyled = styled(FormGroup)`
  &.in-rb-group {
    margin-top: 0;
    margin-left: 21px;
  }

  .control-label {
    padding: 0;
    margin-left: 20px;
    min-height: unset;
    cursor: default;
  }

  .form-control {
    height: auto;
    width: auto;
    margin-left: 0 !important;

    &[disabled] + label {
      opacity: 0.55 !important;
    }
  }
`;



RenameModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onRename: PropTypes.func,
  isAlreadyExistsContent: PropTypes.func,
  item: PropTypes.object.isRequired,
};

export default RenameModal;
