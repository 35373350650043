import styled from 'styled-components';

export const InputStyled = styled.input`
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0,0,0,0.05);
  font-size: 10px;
  font-weight: normal;
  height: auto;
  padding: 9px;

  &::placeholder {
    font-style: italic;
    font-weight: normal;
    font-size: 10px;
    color: grey;
  }
`;

export const LabelStyled = styled.label`
  background-color: #eeeeee;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0,0,0,0.05);
  font-size: 10px;
  font-weight: bold;
  height: auto;
  width: auto;
  padding: 9px;
`;
