import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import qs from 'qs';
import {NavLink, withRouter} from 'react-router-dom';
import {Navbar} from 'react-bootstrap';
import {logout} from '../../pages/Login/actions';
import styled from 'styled-components';
import {switchMyActionsModal, switchSettingsModal, checkMyActions} from '../../pages/StorageManager/actions';
import {FS_ACTION_TYPE__UPLOAD_TO_ASPERA} from '../../pages/StorageManager/constants';
import {isForbiddenActionByUserPermissions} from '../../utils';
import ReportBugButton from '../../components/ReportBugButton';

class Header extends Component {
  checkMyActionsInterval = null;

  componentDidMount() {
    const {dispatch} = this.props;
    this.checkMyActionsInterval = setInterval(function(dispatch) {
      dispatch(checkMyActions());
    }, 15000, dispatch);
  }

  componentWillUnmount() {
    clearInterval(this.checkMyActionsInterval);
  }

  handleLogout = () => {
    const {dispatch, history} = this.props;
    dispatch(logout(history));
  };

  handleSwitchSettings = () => {
    const {dispatch} = this.props;
    dispatch(switchSettingsModal(true));
  };

  handleMyAction = () => {
    const {dispatch} = this.props;
    dispatch(switchMyActionsModal(true));
  }

  handleLinkClick = link => {
    const {history} = this.props;
    history.push(link);
    history.go();
  };

  render() {
    const {urlFilters: {advanced}, storageManager: {actionsUserPermissions, hasActiveMyActions}} = this.props;

    return (
      <Navbar inverse fixedTop>
        {/*<Navbar.Brand><a href={`/${advanced ? '?advanced=1' : ''}`}>MAM</a></Navbar.Brand>*/}
        <Navbar.Toggle aria-controls="navbar-collapse"/>
        <Navbar.Collapse id="navbar-collapse">
          <ul className="nav navbar-nav" data-toggle="collapse" data-target="#navbar-collapse">
            <li>
              <NavLinkStyled
                activeClassName="active"
                to={`/download${advanced ? '?advanced=1' : ''}`}
                onClick={() => this.handleLinkClick(`/download${advanced ? '?advanced=1' : ''}`)}
                >
                Asset Manager
              </NavLinkStyled>
            </li>
            <li>
              <NavLinkStyled
                activeClassName="active"
                to="/storage"
                onClick={() => this.handleLinkClick('/storage')}
                >
                Storage Manager
              </NavLinkStyled>
            </li>
            <li>
              <NavLinkStyled
                activeClassName="active"
                to="/issues"
              >
              Issue Browser
              </NavLinkStyled>
            </li>
            {!isForbiddenActionByUserPermissions(actionsUserPermissions, FS_ACTION_TYPE__UPLOAD_TO_ASPERA) &&
              <li>
                <NavLinkStyled
                  activeClassName="active"
                  to="/faspex"
                  onClick={() => this.handleLinkClick('/faspex')}
                  >
                  Aspera clients
                </NavLinkStyled>
              </li>
            }
          </ul>
          <NavStyled className="nav navbar-nav" data-toggle="collapse" data-target="#navbar-collapse">
            <li className="my-action">
              <button className="btn-my-action" onClick={this.handleMyAction} title="My action">
                { hasActiveMyActions ? <i className="fa-solid fa-circle-notch fa-spin fa-xl"></i> : <i className="fa-solid fa-list-check fa-xl"></i>}
              </button>
            </li>

            <li className="report-bug-btn">
              <ReportBugButton isIcon={true}/>
            </li>
            {
              window.location.pathname === '/storage' &&
                <li className="settings">
                  <button className="btn-settings" onClick={this.handleSwitchSettings} title="Settings">
                    <i className="fa-regular fa-gear fa-xl"></i>
                  </button>
                </li>
            }
            <li className="logout">
              <NavLink to="/login" onClick={this.handleLogout} activeClassName="active" title="Log out">
                <i className="fa-regular fa-right-from-bracket fa-xl"></i>
              </NavLink>
            </li>
          </NavStyled>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

const NavLinkStyled = styled(NavLink)`
  &.active {
    text-decoration: underline;
  }
`;

const NavStyled = styled.ul`
  @media (min-width: 768px) {
    float: right !important;
    display: flex;
  }
  overflow: hidden;
  .btn-settings, .btn-my-action {
    padding: 15px;
    line-height: 20px;
    background-color: transparent !important;
    border: 0;
    outline: none !important;
    color: #9d9d9d;
    :hover, :focus {
      color: #fff;
    }
  }
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  storageManager: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    storageManager: state.storageManager,
    urlFilters: qs.parse(window.location.search.substr(1))
  };
}

export default connect(
  mapStateToProps
)(withRouter(Header));
