import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button} from 'react-bootstrap';
import styled, {createGlobalStyle} from 'styled-components';
import StyledFramePlayer from './issue-screenshot/StyledFramePlayer';

import {
    toggleIssueScreenshotToQodModal,
    getIssueListForWorkOrder,
    executeScreenToQodAction, getIssueFramesJson, readIssueScreenshot, sendCanvasToQod, buildIssueScreenshotLink, getStatsForIssueScreenshots,
    sendFirstScreenshotsToQOD
} from '../../actions';
import {showError} from '../../../../layouts/actions';
import ReportBugButton from '../../../../components/ReportBugButton';
import RndModal from '../../../../components/modals/RndModal';
import {connect} from "react-redux";
import Loader from "../../../../components/Loader";
import PinchZoomPan from 'react-responsive-pinch-zoom-pan';
import { fabric } from 'fabric';
import {getErrorMessage} from "../../../../utils";

const styles = {
    canvas: {
        width: '100%',
        height: '100%',
    },
}

const screens = {
    ISSUE_LIST_STEP: 1,
    PREPARING_SCREEN: 2,
    FRAME_PLAYER_SCREEN: 3,
    VIEW_IMAGE_SCREEN: 4,
}

const SECONDS_PER_HOUR = 60 * 60;
const SECONDS_PER_MINUTE = 60;
const smpteToFrame = (smpte, ratePlayback) => {
    const parts = smpte.split(/:|;/);
    let h, m, s, f;

    if (parts.length === 3) {
        h = 0;
        m = parseInt(parts[0]);
        s = parseInt(parts[1]);
        f = parseInt(parts[2]);
    } else if (parts.length === 4) {
        h = parseInt(parts[0]);
        m = parseInt(parts[1]);
        s = parseInt(parts[2]);
        f = parseInt(parts[3]);
    } else {
        return 0;
    }

    const seconds = h * SECONDS_PER_HOUR + m * SECONDS_PER_MINUTE + s;
    return seconds * ratePlayback + f;
}

function pad(n) {
    if (n < 10) {
        return "0" + n.toString(10);
    }
    return n.toString(10);
}

function frameToSmpte(frame, ratePlayback) {
    const seconds = frame / ratePlayback;
    const f = Math.round((seconds - Math.floor(seconds)) * ratePlayback);
    const h = Math.floor(seconds / SECONDS_PER_HOUR);
    const m = Math.floor(seconds % SECONDS_PER_HOUR / SECONDS_PER_MINUTE);
    const s = Math.floor(seconds % SECONDS_PER_MINUTE);
    return pad(h) + ":" + pad(m) + ":" + pad(s) + ":" + pad(f);
}

class Screen3FramePlayer extends React.PureComponent {

    componentDidMount() {
        const {framesJson} = this.props;

        const framePlayerOptions = ({
            'rate': 24,
            'controls': true,
            'autoplay': false,
            'backwards': false,
            'startFrame': 1
        });
        /*global FramePlayer*/ // for disable React error
        const framePlayer = new FramePlayer('frame-player', framePlayerOptions, this.props.startTimecode);

        framePlayer.setJson(framesJson);
        framePlayer.play();
    }

    showFullSizeFrame = () => {
        const currentFrameNum = parseInt(document.getElementById('issue-screen-frame-number').value, 10);
        this.props.onSelectScreen(currentFrameNum);
    }

    render() {
        return (
            <div id="frame-player-wrapper">
                <div>
                    <Button onClick={this.props.onBack}>
                        Back
                    </Button>
                    <Button onClick={this.showFullSizeFrame}>
                        View image
                    </Button>
                </div>
                <StyledFramePlayer id="frame-player" data-vidsrc=""/>
            </div>
        );
    }
}

function Screen4Canvas(props) {
    const {imageLink, currentFrame, totalFrames, currentTimecode} = props;

    const [currentFrameValue, setCurrentFrameValue] = useState(currentFrame);
    const [timecodeValue, setTimecodeValue] = useState('');
    const canvasRef = useRef(null);
    const canvasWrapperRef = useRef(null);
    const fabricCanvasRef = useRef(null);

    const allowAddRect = useRef(false);
    const allowAddText = useRef(false);

    useEffect(() => {
        fabricCanvasRef.current = new fabric.Canvas('issue-screen-canvas');
        fabricCanvasRef.current.on('mouse:down', handleClickCanvas);

        return function cleanup() {
            fabricCanvasRef.current.dispose();
            fabricCanvasRef.current = null;
        };
    }, []);

    useEffect(() => {
        setCurrentFrameValue(currentFrame);
        if (currentTimecode) {
            setTimecodeValue(currentTimecode);
        }
        loadImageToCanvas();
    }, [imageLink]);

    const handleClickCanvas = (options) => {
        if (allowAddRect.current) {
            const rect = new fabric.Rect({
                left: options.pointer.x,
                top: options.pointer.y,
                fill: 'transparent',
                width: 100,
                height: 100,
                hasBorder: true,
                stroke: 'red',
                strokeWidth: 5,
                strokeUniform: true
            });

            fabricCanvasRef.current.add(rect);
            allowAddRect.current = false;
            canvasWrapperRef.current.setAttribute('class', '');
        }

        if (allowAddText.current) {
            const text = new fabric.IText('Tap and Type', {
                fontFamily: 'arial',
                fill: 'red',
                left: options.pointer.x,
                top: options.pointer.y ,
            });

            fabricCanvasRef.current.add(text);
            allowAddText.current = false;
            canvasWrapperRef.current.setAttribute('class', '');
        }
    }

    const loadImageToCanvas = () => {
        // fix scroll conflict between PinchZoomPan and Fabric
        document.getElementsByClassName('upper-canvas')[0].removeEventListener('wheel', fabricCanvasRef.current._onMouseWheel);

        fabricCanvasRef.current.clear();
        fabric.Image.fromURL(imageLink, function(oImg, error) {
            props.onImageLoaded(error);

            if (error) {
                alert('Failed load image');
                return;
            }

            canvasWrapperRef.current.style.width = oImg.width + 'px';
            canvasWrapperRef.current.style.height = oImg.height + 'px';

            const canvasWrapper = document.getElementById('issue-screen-canvas-wrapper');
            canvasWrapper.tabIndex = 1000;
            canvasWrapper.onkeydown = function(e) {
                if (e.key === 'Delete' || e.code === 'Delete' || e.key === 'Backspace') {
                    if (fabricCanvasRef.current.getActiveObject()) {
                        if (fabricCanvasRef.current.getActiveObject().isEditing) {
                            return
                        }
                        fabricCanvasRef.current.remove(fabricCanvasRef.current.getActiveObject())
                    }
                }
            }

            canvasWrapper.onmousemove = function(e) {
                if (!e.buttons) return null;

                if (fabricCanvasRef.current.getActiveObject()) {
                    fabricCanvasRef.current._onMouseMove(e);
                    e.stopPropagation();
                }
            }

            canvasWrapper.ondblclick = function(e) {
                if (fabricCanvasRef.current.getActiveObject()) {
                    e.stopPropagation();
                }
            }

            canvasRef.current.style.width = `${oImg.width}px`;
            canvasRef.current.style.height = `${oImg.height}px`;

            fabricCanvasRef.current.setDimensions({
                width: oImg.width,
                height: oImg.height
            });

            fabricCanvasRef.current.setBackgroundImage(oImg, fabricCanvasRef.current.renderAll.bind(fabricCanvasRef.current), {
                originX: 'left',
                originY: 'top',
                left: 0,
                top: 0,
                width: oImg.width,
                height: oImg.height,
            });

            // hack for correct scale - fit screenshot to canvas size
            document.getElementsByClassName('iconButton')[1].click();
            for (let i = 0; i < 20; i++) {
                document.getElementsByClassName('iconButton')[0].click();
            }
        }, { crossOrigin: 'use-credentials' });
    }

    const startAddRectToCanvas = () => {
        allowAddRect.current = true;
        allowAddText.current = false;
        canvasWrapperRef.current.setAttribute('class', 'edit-mode');
    }

    const startAddTextToCanvas = () => {
        allowAddText.current = true;
        allowAddRect.current = false;
        canvasWrapperRef.current.setAttribute('class', 'edit-mode');
    }

    const handleNextFrame = () => {
        props.onShowFrameByNumber(currentFrame + 1);
    }

    const handlePreviousFrame = () => {
        props.onShowFrameByNumber(currentFrame - 1);
    }

    const handleChangeCurrentFrame = (event) => {
        if (currentFrameValue === currentFrame) {
            return;
        }
        if (event.key === 'Enter') {
            const res = props.onShowFrameByNumber(+currentFrameValue);
            if (res === false) {
                setCurrentFrameValue(currentFrame)
            }
        }
    }

    const handleBlurFrameNumber = () => {
        if (currentFrameValue === currentFrame) {
            return;
        }
        const res = props.onShowFrameByNumber(+currentFrameValue);
        if (res === false) {
            setCurrentFrameValue(currentFrame)
        }
    }

    const handleChangeTimecode = (event) => {
        if (timecodeValue === currentTimecode) {
            return;
        }
        if (event.key === 'Enter' || event.type === 'blur') {
            const res = props.onShowFrameBySmpte(timecodeValue);
            if (res === false) {
                setTimecodeValue(currentTimecode);
            }
        }
    }

    const handleSave = () => {
        const dataURL = fabricCanvasRef.current.toDataURL("image/png").replace('data:image/png;base64,', '');
        props.onSave(dataURL);
    }

    const canvasWrapperClass = allowAddRect.current || allowAddText.current ? 'edit-mode' : null;

    return (
        <div id="issue-screen-view">
            <div>
            <Button onClick={props.onBack}>
                Back
            </Button>
            <Button onClick={startAddRectToCanvas} title="Click this button, then click on the image to add a rectangle">
                Rectangle
            </Button>
            <Button onClick={startAddTextToCanvas} title="click this button, then click on the image to add a text">
                Text
            </Button>
            <span>Frame</span>
            <input
                type="number"
                title="Edit frame number to go"
                value={currentFrameValue}
                min="1"
                max={totalFrames}
                onChange={(event) => setCurrentFrameValue(+event.target.value)}
                onKeyDown={handleChangeCurrentFrame}
                onBlur={handleBlurFrameNumber}
            />
            <span>of {totalFrames}</span>
            {currentTimecode &&
            <>
                <span>| SMPTE</span>
                <input
                    type="text"
                    title="Edit SMPTE time code to go"
                    value={timecodeValue || currentTimecode}
                    onChange={(event) => setTimecodeValue(event.target.value)}
                    onKeyDown={handleChangeTimecode}
                    onBlur={handleChangeTimecode}
                />
            </>
            }
            <Button bsStyle="primary" onClick={handleSave}>
                Send to QOD
            </Button>
            </div>
            <div className="image-wrapper">
                <LeftNavBtn
                  disabled={currentFrame < 2}
                  onClick={handlePreviousFrame}
                  title="Previous frame"
                >
                  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.5" d="M11.5 0C8.44974 0 5.5246 1.21199 3.36835 3.36835C1.2121 5.52471 0 8.44997 0 11.5C0 14.55 1.21199 17.4754 3.36835 19.6317C5.52471 21.7879 8.44997 23 11.5 23C14.55 23 17.4754 21.788 19.6317 19.6317C21.7879 17.4753 23 14.55 23 11.5C23 8.44997 21.788 5.5246 19.6317 3.36835C17.4753 1.2121 14.55 0 11.5 0V0ZM9.29704 17.25L7.95388 15.9095L12.3625 11.5L7.95296 7.09042L9.29704 5.74995L15.047 11.5L9.29704 17.25Z" fill="#BCBCBC"/>
                  </svg>
                </LeftNavBtn>
                <StyledZoomImage id="custom-zoom-image-container">
                    <PinchZoomPan
                        position="center"
                        maxScale={30}
                    >
                        <div id="issue-screen-canvas-wrapper" className={canvasWrapperClass} ref={canvasWrapperRef} >
                            <canvas id="issue-screen-canvas" ref={canvasRef} style={styles.canvas} />
                        </div>
                    </PinchZoomPan>
                </StyledZoomImage>
                <RightNavBtn
                  disabled={currentFrame === totalFrames}
                  onClick={handleNextFrame}
                  title="Next frame"
                >
                  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.5" d="M11.5 0C8.44974 0 5.5246 1.21199 3.36835 3.36835C1.2121 5.52471 0 8.44997 0 11.5C0 14.55 1.21199 17.4754 3.36835 19.6317C5.52471 21.7879 8.44997 23 11.5 23C14.55 23 17.4754 21.788 19.6317 19.6317C21.7879 17.4753 23 14.55 23 11.5C23 8.44997 21.788 5.5246 19.6317 3.36835C17.4753 1.2121 14.55 0 11.5 0V0ZM9.29704 17.25L7.95388 15.9095L12.3625 11.5L7.95296 7.09042L9.29704 5.74995L15.047 11.5L9.29704 17.25Z" fill="#BCBCBC"/>
                  </svg>
                </RightNavBtn>
            </div>
        </div>
    );
}

class IssueScreenshotToQodModal extends React.PureComponent {
    state = {
        issueList: [],
        selectedIssue: null,
        selectedIssueList: [],
        loadIssueFrames: false,
        showLoader: false,
        framesJson: null,
        pngFiles: [],
        currentUIStep: screens.ISSUE_LIST_STEP,
        defaultMode: false,
        selectedIssueImageLink: null,
        frameRatePlayback: null,
        frameNumber: 1,
        totalFrames: 1
    }

    handleClose = () => {
        const {dispatch} = this.props;
        dispatch(toggleIssueScreenshotToQodModal(false));
    };

    componentWillUnmount() {
        this.handleClose();
    }

    componentDidMount() {
        const {dispatch, contentItem} = this.props;

        this.setState({
            showLoader: true
        });

        dispatch(getIssueListForWorkOrder(contentItem.memfisWoID)).then((res) => {
            this.setState({issueList: res});
        }).catch(e => dispatch(showError('Failed get issue list. ' + getErrorMessage(e)))
        ).always(() => {this.setState({showLoader: false})});
    }

    handleOnClickIssue = (checked, issue) => {
        const {selectedIssueList} = this.state;
        if (checked) {
            const newSelectedIssueList = [...selectedIssueList, issue];
            this.setState({
                selectedIssueList: newSelectedIssueList,
                selectedIssue: newSelectedIssueList.length === 1 ? issue : null
            });
            return;
        }

        const newSelectedIssueList = selectedIssueList.filter(({IssueID}) => IssueID !== issue.IssueID);
        this.setState({
            selectedIssueList: newSelectedIssueList,
            selectedIssue: newSelectedIssueList.length === 1 ? newSelectedIssueList[0] : null
        });


        //this.setState({selectedIssue: issue});
    };

    getTimecodeStartFromSelectedIssue = (selectedIssue) => {
        let timecodeStart = selectedIssue.ProxyTimeCodeStart || selectedIssue.TimeCodeStart;
        if (/^[0-9][:].*$/.test(timecodeStart)) {
            timecodeStart = "0" + timecodeStart;
        }
        return timecodeStart;
    };

    getTimecodeEndFromSelectedIssue = (selectedIssue) => {
        let timecodeEnd = selectedIssue.ProxyTimeCodeEnd || selectedIssue.TimeCodeEnd;
        if (/^[0-9][:].*$/.test(timecodeEnd)) {
            timecodeEnd = "0" + timecodeEnd;
        }
        return timecodeEnd;
    };

    handleOnSubmit = () => {
        const {dispatch, contentItem} = this.props;
        const {selectedIssue} = this.state;

        const timecodeStart = this.getTimecodeStartFromSelectedIssue(selectedIssue);
        const timecodeEnd = this.getTimecodeEndFromSelectedIssue(selectedIssue);
        dispatch(executeScreenToQodAction(contentItem.memfisWoID, selectedIssue.IssueID, timecodeStart, timecodeEnd));
    };

    handleCreateScreenshotWithReplace = () => {
        const {dispatch, contentItem} = this.props;
        const {selectedIssue} = this.state;

        const timecodeStart = this.getTimecodeStartFromSelectedIssue(selectedIssue);
        const timecodeEnd = this.getTimecodeEndFromSelectedIssue(selectedIssue);
        dispatch(executeScreenToQodAction(contentItem.memfisWoID, selectedIssue.IssueID, timecodeStart, timecodeEnd, true));
    }

    handleShowFramePlayer = () => {
        const {dispatch, contentItem} = this.props;
        const {selectedIssue} = this.state;

        const timecodeStart = this.getTimecodeStartFromSelectedIssue(selectedIssue);
        const timecodeEnd = this.getTimecodeEndFromSelectedIssue(selectedIssue);

        this.setState({
            currentUIStep: screens.PREPARING_SCREEN,
            loadIssueFrames: true
        });

        dispatch(getIssueFramesJson(contentItem.fsRootID, contentItem.contentID, contentItem.memfisWoID, selectedIssue.IssueID, timecodeStart, timecodeEnd)).then((res) => {
            // set state for res.content_ids
            let ratePlayback = null;
            if (res.ratePlayback) {
                ratePlayback = parseInt(res.ratePlayback, 10);
                if (ratePlayback > 1000) {
                    ratePlayback = ratePlayback / 1000;
                }
                res.ratePlayback = ratePlayback;
            }

            const frames = res.frames || [];
            this.setState({
                currentUIStep: screens.FRAME_PLAYER_SCREEN,
                loadIssueFrames: false,
                framesJson: res,
                frameRatePlayback: ratePlayback,
                totalFrames: frames.length
            });
        }).catch(e => {
            this.setState({currentUIStep: screens.ISSUE_LIST_STEP, loadIssueFrames: false});
            dispatch(showError('Failed get frames for selected issue. ' + getErrorMessage(e)));
        });
    }

    closeScreen3 = () => {
        this.setState({
            currentUIStep: screens.ISSUE_LIST_STEP
        });
    }

    handleShowFullSizeFrame = (currentFrameNum) => {
        const {dispatch, contentItem} = this.props;
        //const currentFrameNum = document.getElementById('issue-screen-frame-number').value;
        const {selectedIssue} = this.state;

        //const pngFile = this.state.pngFiles[currentFrameNum-1];

        //const fsRootId = contentItem.fsRootID;
        //const contentId = pngFile.contentID;
        //const fileName = pngFile.pathName;
        //dispatch(readIssueScreenshot(fsRootId, contentId, fileName, (fileLink) => {
        //    this.setState({
        //        currentUIStep: screens.VIEW_IMAGE_SCREEN,
        //        showLoader: true,
        //        selectedIssueImageLink: fileLink,
        //        frameNumber: currentFrameNum
        //    });
        //}));


        const timecodeStart = this.getTimecodeStartFromSelectedIssue(selectedIssue);
        const timecodeEnd = this.getTimecodeEndFromSelectedIssue(selectedIssue);

        dispatch(buildIssueScreenshotLink(contentItem.fsRootID, contentItem.contentID, contentItem.memfisWoID, selectedIssue.IssueID, timecodeStart, timecodeEnd, currentFrameNum)).then((imageLink) => {
            this.setState({
                currentUIStep: screens.VIEW_IMAGE_SCREEN,
                showLoader: true,
                frameNumber: currentFrameNum,
                selectedIssueImageLink: imageLink
            });
        });
    }

    closeScreen4 = () => {
        const {defaultMode} = this.state;

        if (defaultMode) {
            this.setState({
                selectedIssueImageLink: null,
                currentUIStep: screens.ISSUE_LIST_STEP,
                defaultMode: false
            });
            return;
        }

        this.setState({
            selectedIssueImageLink: null,
            currentUIStep: screens.FRAME_PLAYER_SCREEN
        });
    }

    handleSendCanvasToQod = (dataURL) => {
        const {dispatch, contentItem} = this.props;
        const {selectedIssue} = this.state;

        //const dataURL = this.canvasA.toDataURL("image/png").replace('data:image/png;base64,', '');
        dispatch(sendCanvasToQod(contentItem.memfisWoID, selectedIssue.IssueID, dataURL));
    }

    onShowFrameByNumber = (newFrameNumber) => {
        const {dispatch, contentItem} = this.props;
        const {selectedIssue, defaultMode, totalFrames} = this.state;

        if (newFrameNumber > totalFrames || newFrameNumber < 1) {
            return false;
        }

        this.setState({
            showLoader: true
        });

        //if (defaultMode) {
            const timecodeStart = this.getTimecodeStartFromSelectedIssue(selectedIssue);
            const timecodeEnd = this.getTimecodeEndFromSelectedIssue(selectedIssue);

            dispatch(buildIssueScreenshotLink(contentItem.fsRootID, contentItem.contentID, contentItem.memfisWoID, selectedIssue.IssueID, timecodeStart, timecodeEnd, newFrameNumber)).then((imageLink) => {
                this.setState({
                    frameNumber: newFrameNumber,
                    selectedIssueImageLink: imageLink
                });
            });
        //    return;
        //}

        //const pngFile = this.state.pngFiles[newFrameNumber-1];

        //const fsRootId = contentItem.fsRootID;
        //const contentId = pngFile.contentID;
        //const fileName = pngFile.pathName;
        //dispatch(readIssueScreenshot(fsRootId, contentId, fileName, (fileLink) => {
        //    this.setState({
        //        frameNumber: newFrameNumber,
        //        selectedIssueImageLink: fileLink
        //    });
        //}));
    }

    onShowFrameBySmpte = (newSmpte) => {
        const {selectedIssue, frameRatePlayback} = this.state;

        const startTimecodeFrame = smpteToFrame(selectedIssue.TimeCodeStart, frameRatePlayback);
        const newFrameNumber = smpteToFrame(newSmpte, frameRatePlayback);
        if (newFrameNumber < startTimecodeFrame) {
            return false;
        }
        return this.onShowFrameByNumber(newFrameNumber - startTimecodeFrame + 1);
    }

    handleImageLoaded = (error) => {
        const {dispatch, contentItem} = this.props;
        const {selectedIssue} = this.state;

        this.setState({
            showLoader: false
        });

        if (error || !this.state.defaultMode) {
            return;
        }

        dispatch(getStatsForIssueScreenshots(contentItem.memfisWoID, selectedIssue.IssueID)).then(res => {
            let ratePlayback = null;
            if (res.rate_playback) {
                ratePlayback = parseInt(res.rate_playback, 10);
                if (ratePlayback > 1000) {
                    ratePlayback = ratePlayback / 1000;
                }
            }

            this.setState({
                frameRatePlayback: ratePlayback,
                totalFrames: res.total_frames
            });
        });
    }

    handleSendDefault = () => {
        const {dispatch, contentItem} = this.props;
        const {selectedIssue} = this.state;

        const timecodeStart = this.getTimecodeStartFromSelectedIssue(selectedIssue);
        const timecodeEnd = this.getTimecodeEndFromSelectedIssue(selectedIssue);

        dispatch(buildIssueScreenshotLink(contentItem.fsRootID, contentItem.contentID, contentItem.memfisWoID, selectedIssue.IssueID, timecodeStart, timecodeEnd, 1)).then((imageLink) => {
            this.setState({
                currentUIStep: screens.VIEW_IMAGE_SCREEN,
                defaultMode: true,
                showLoader: true,
                frameNumber: 1,
                selectedIssueImageLink: imageLink
            });
        });
    }

    handleSendFirstScreenshot = () => {
        const {dispatch, contentItem} = this.props;
        const {selectedIssueList} = this.state;

        const issuesForScreenshot = [];
        for (const issue of selectedIssueList) {
            const timecodeStart = this.getTimecodeStartFromSelectedIssue(issue);
            const timecodeEnd = this.getTimecodeEndFromSelectedIssue(issue);

            issuesForScreenshot.push({
                issueID: issue.IssueID,
                timecodeStart,
                timecodeEnd
            });
        }

        dispatch(sendFirstScreenshotsToQOD(contentItem.fsRootID, contentItem.contentID, contentItem.memfisWoID, issuesForScreenshot));
    }

    isSelectedIssue = (row) => {
        const {selectedIssueList} = this.state;
        return selectedIssueList.some(({IssueID}) => IssueID === row.IssueID);
    }

    isSelectedAllIssues = () => {
        const {issueList, selectedIssueList} = this.state;
        const allLength = issueList.length;
        return selectedIssueList.length === allLength && allLength > 0;
    }

    render() {
        const {issueList, selectedIssue, selectedIssueList} = this.state;
        const selectedIssueID = selectedIssue && selectedIssue.IssueID;

        let currentTimecode = null;
        if (this.state.frameRatePlayback) {
            const currentFrame = smpteToFrame(selectedIssue.TimeCodeStart, this.state.frameRatePlayback);
            currentTimecode = frameToSmpte(currentFrame + this.state.frameNumber - 1, this.state.frameRatePlayback);
        }

        return (
            <RndModal
                bsSize="large"
                className="move-modal"
                show={true}
                onHide={this.handleClose}
                backdrop="static"
                enforceFocus={false}
            >
                <Modal.Header>
                    <Modal.Title>
                        Send Issue Screenshot to QOD
                        <ReportBugButton isForModal={true} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.loadIssueFrames ?
                        <div>
                            <Loader/>
                        </div> : null}
                    <ModalContainerStyled className="container-fluid">
                        {this.state.showLoader ?
                        <div>
                            <Loader/>
                        </div> : null}
                        {this.state.currentUIStep === screens.PREPARING_SCREEN && <div>Preparing...</div>}
                        {this.state.currentUIStep === screens.FRAME_PLAYER_SCREEN &&
                            <Screen3FramePlayer
                                framesJson={this.state.framesJson}
                                onBack={this.closeScreen3}
                                onSelectScreen={this.handleShowFullSizeFrame}
                                startTimecode={selectedIssue.TimeCodeStart}
                            />
                        }
                        {this.state.selectedIssueImageLink &&
                            <Screen4Canvas
                                imageLink={this.state.selectedIssueImageLink}
                                currentFrame={this.state.frameNumber}
                                totalFrames={this.state.totalFrames}
                                currentTimecode={currentTimecode}
                                onImageLoaded={this.handleImageLoaded}
                                onBack={this.closeScreen4}
                                onSave={this.handleSendCanvasToQod}
                                onShowFrameByNumber={this.onShowFrameByNumber}
                                onShowFrameBySmpte={this.onShowFrameBySmpte}
                            />
                        }
                        {this.state.currentUIStep === screens.ISSUE_LIST_STEP ? issueList.length > 0 ?
                            <>
                                <div>Select issue for which we will create and send screenshot to QOD:</div>
                                <table className="issues-table">
                                    <tbody>
                                    <tr>
                                        <th className="checkbox-table-cell">
                                          <input
                                            type="checkbox"
                                            checked={ this.isSelectedAllIssues() }
                                            onChange={ (event) => this.setState({selectedIssue: null, selectedIssueList: event.currentTarget.checked ? issueList : []}) }
                                          />
                                        </th>
                                        <th className="category-cell">Category</th>
                                        <th className="table-cell">Type</th>
                                        <th className="timecode-head-cell">Start Timecode</th>
                                        <th className="timecode-head-cell">End Timecode</th>
                                        <th className="timecode-head-cell">Duration</th>
                                        <th className="table-cell">Description</th>
                                        <th className="has-image-cell">Has Image</th>
                                    </tr>
                                    {issueList.map(item => (
                                        <tr key={item.IssueID}>
                                            <td className="checkbox-table-cell">
                                                <input
                                                    type="checkbox"
                                                    checked={ this.isSelectedIssue(item) }
                                                    onChange={ (event) => this.handleOnClickIssue(event.currentTarget.checked, item) }
                                                />
                                            </td>
                                            <td>{ item.Category || '-'}</td>
                                            <td>{ item.IssueType || '-'}</td>
                                            <td>{ item.TimeCodeStart || '-'}</td>
                                            <td>{ item.TimeCodeEnd || '-'}</td>
                                            <td>{ item.Duration || '-'}</td>
                                            <td>{ item.Description || '-'}</td>
                                            <td>{ item.HasImage ? 'Yes' : 'No'}</td>

                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </>
                            : <span>Sorry, there is no available issues</span> : null
                        }

                    </ModalContainerStyled>
                </Modal.Body>
                <Modal.Footer>
                    {this.state.currentUIStep === screens.ISSUE_LIST_STEP ?
                    <>
                    <Button bsStyle="primary" onClick={this.handleSendFirstScreenshot} disabled={selectedIssueList.length === 0} title="Click to send first screenshot for all selected issues">
                        Send First Screenshot
                    </Button>
                    <Button bsStyle="primary" onClick={this.handleShowFramePlayer} disabled={!selectedIssueID} title="Click to select screenshot from frames">
                        Select Screenshot
                    </Button>
                    <Button bsStyle="primary" onClick={this.handleSendDefault} disabled={!selectedIssueID} title="Click to view first screenshot for issue">
                        View First Screenshot
                    </Button>
                    {/*<Button bsStyle="primary" onClick={this.handleCreateScreenshotWithReplace} disabled={!selectedIssueID} title="We take a new screenshot and it will be replace exist the issue screenshot on QOD">
                    Send Default (replace)
                    </Button>*/}
                    </> : null}
                    {/*this.state.currentUIStep === 4 ?
                    <Button bsStyle="primary" onClick={this.handleSendCanvasToQod}>
                        Send to QOD
                    </Button> : null*/}
                    <Button bsStyle="default" onClick={this.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
                <ModalGlobalStyled/>
            </RndModal>
        );
    }
}

const ModalContainerStyled = styled.div`
  min-height: 400px;

  position: relative;
  height: 100%;

  padding-left: 7px;
  display: flex;
  flex-direction: column;

  .issues-table {
    border-collapse: collapse;
    width: 100%;
    display: table;
    margin-top: 5px;
    
    tr {
      td, th {
        border: 2px solid grey;
        padding: 4px;
        word-break: break-word;
        
        &.category-cell {
          width: 75px;
        }

        &.has-image-cell {
          width: 55px;
        }

        &.checkbox-head-cell {
          width: 40px;
        }

        &.timecode-head-cell {
          width: 90px;
        }

        &.checkbox-table-cell {
          text-align: center;
          padding: 0 7px;
        }
      }
    }
  }
  .loader-container {
    z-index: 1051;
  }
  .warning-renamed {
    display: flex;
    align-items: center;
    padding-bottom: 20px;

    i {
      font-size: 22px;
      color: red;
      margin-right: 5px;
    }
  }

  .image-wrapper {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: rgb(26, 26, 26);
    box-shadow: none;

    position: absolute;
    inset: 40px 7px 0;
  }

  .player-wrapper, #frame-player-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  
  #frame-player-wrapper, #issue-screen-view {
    .btn {
      margin-right: 5px;
      margin-bottom: 5px;
    }

    span, input {
      margin-right: 5px;
    }

    input {
      width: 55px;

      &:not(:first-of-type) {
        width: 100px;
      }
    }
  }
  
  #issue-screen-canvas-wrapper.edit-mode canvas {
    cursor: crosshair !important;
  }
`;

const StyledZoomImage = styled.div`
  max-width: calc(100% - 60px);
  max-height: 100%;
  height: 100%;
  @media (max-width: 767px) {
    max-width: calc(100vw - 52px);
  }
  .iconButton {
    display: none;
  }
  > div {
    position: relative;
  }
`;

const RightNavBtn = styled.button`
  margin: 0;
  padding: 0;
  position: absolute;
  right: 4px;
  border-radius: 50%;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  z-index: 1;
  &:not(:hover) {
    background: none !important;
  }
  &:hover {
    background: #e6e6e6 !important;
  }
  &:disabled {
    opacity: 0.45;
    pointer-events: none;
  }
  &,svg {
    width: 23px;
    height: 23px;
  }
`;

const LeftNavBtn = styled(RightNavBtn)`
  left: 4px;
  right: unset;
  transform: rotate(180deg);
`;

const ModalGlobalStyled = createGlobalStyle`
  .react-draggable {
    max-height: calc(100vh - 50px) !important;
  }

  .modal-body {
    padding: 5px !important;
  }
`;

IssueScreenshotToQodModal.propTypes = {
    dispatch: PropTypes.func.isRequired,
    contentItem: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const {checkedItems} = state.storageManager;
    return {
        contentItem: checkedItems[0]
    };
}

export default connect(
    mapStateToProps
)(IssueScreenshotToQodModal);
