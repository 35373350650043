import $ from 'jquery';
import config from '../../../config';
import {showError, showSuccess} from '../../layouts/actions';
import {getErrorMessage} from '../../utils';
import { FS_ACTION_TYPE__DOWNLOAD_ASPERA_PACKAGE } from './constants';
import {
  getActiveFsRoots, STORAGE_MNG_ACTIONS_REQUESTORS_SHOW
} from '../StorageManager/actions';

export const ASPERA_CLIENTS_SET_LOADING = 'ASPERA_CLIENTS_SET_LOADING';
export const ASPERA_CLIENTS_LOAD_PACKAGES = 'ASPERA_CLIENTS_LOAD_PACKAGES';
export const ASPERA_CLIENTS_CLEAR_ALL = 'ASPERA_CLIENTS_CLEAR_ALL';
export const ASPERA_CLIENTS_CHANGE_CHECKED_ITEMS = 'ASPERA_CLIENTS_CHANGE_CHECKED_ITEMS';
export const ASPERA_CLIENTS_SHOW_DOWNLOAD_ASPERA_PACKAGE_MODAL = 'ASPERA_CLIENTS_SHOW_DOWNLOAD_ASPERA_PACKAGE_MODAL';

export const clearAllAsperaClients = () => ({type: ASPERA_CLIENTS_CLEAR_ALL});

export const getAllPackages = () => (dispatch) => {
  const name = 'loadAllPackages';
  dispatch({
    type: ASPERA_CLIENTS_SET_LOADING,
    name,
    loading: true
  });
  return $.ajax({
      method: 'GET',
      url: `${config.apiUrl}/v1/aspera_faspex/packages`,
    })
    .done(res => {
      dispatch({
        type: ASPERA_CLIENTS_SET_LOADING,
        name,
        loading: false
      });
      dispatch({
        type: ASPERA_CLIENTS_LOAD_PACKAGES,
        res
      });
    })
    .fail(error => {
      dispatch({
        type: ASPERA_CLIENTS_SET_LOADING,
        name,
        loading: false
      });
      dispatch(showError(`Error when geting packages request.  ${getErrorMessage(error)}`));
    });
}

export const changeCheckedItems = items => dispatch => {
  dispatch({type: ASPERA_CLIENTS_CHANGE_CHECKED_ITEMS, items});
};

export const showDownloadPackageModal = (packageID) => dispatch => {
  const name = 'getPackageLinksRequest';
  dispatch({
    type: ASPERA_CLIENTS_SET_LOADING,
    name,
    loading: true
  });
  dispatch(getActiveFsRoots());
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/aspera_faspex/package_links?package_id=${packageID}`,
  })
  .done(res => {
    dispatch({
      type: ASPERA_CLIENTS_SET_LOADING,
      name,
      loading: false
    });
    let href;
    res.forEach(element => {
      if(element.rel === 'package') {
        href = element.href;
      }
    });
    dispatch({
      type: ASPERA_CLIENTS_SHOW_DOWNLOAD_ASPERA_PACKAGE_MODAL,
      isShow: true,
      href,
    });
  })
  .fail(error => {
    dispatch({
      type: ASPERA_CLIENTS_SET_LOADING,
      name,
      loading: false
    });
    dispatch(showError(`Error when geting package links.  ${getErrorMessage(error)}`));
  });
};

export const closeDownloadPackageModal = () => ({
  type: ASPERA_CLIENTS_SHOW_DOWNLOAD_ASPERA_PACKAGE_MODAL,
  isShow: false,
})

export const executeDownloadPackageAction = (selectedFolder) => (dispatch, getState) => {
  const { checkedItems, downloadPackageHref } = getState().asperaClients;
  const { fsRootID, key } = selectedFolder;
  const name = 'executeDownloadAction';
  const closeModal = () => {
    dispatch({
      type: ASPERA_CLIENTS_SET_LOADING,
      name,
      loading: false
    });
    dispatch(closeDownloadPackageModal());
  }

  dispatch({
    type: ASPERA_CLIENTS_SET_LOADING,
    name,
    loading: true
  });

  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__DOWNLOAD_ASPERA_PACKAGE,
      fsRootID,
      parameters: {
        FASPEX_PACKAGE_URL: downloadPackageHref,
        DESTINATION: key,
        FASPEX_ACCOUNT_ID: checkedItems[0].faspexAccountID,
      }
    })
  })
    .done(({ActionID}) => {
      if (ActionID > 0) {
        closeModal();
        dispatch(showSuccess(`Download Package action has been successfully created. Action ID = ${ActionID}`));
      }
      // setTimeout(function request() {
      //   makeActionAjax(ActionID)
      //     .done((response) => {
      //       const { actionStatus } = response.action;
      //       if (actionStatus === 'DONE') {
      //         closeModal();
      //         dispatch(showSuccess('Download Package action has been successfully completed.'));
      //       } else if (actionStatus === 'FAILED' || actionStatus === 'EXPIRED' || actionStatus === 'REJECTED' || actionStatus === 'INCOMPLETE' || actionStatus === 'DISABLED_BY_FAILURE') {
      //         closeModal();
      //         dispatch(showError(`Could not download package.  ${getErrorMessage(actionStatus)}`));
      //       } else {
      //         setTimeout(request, 3000);
      //       }
      //     })
      //     .fail(error => {
      //       closeModal();
      //       dispatch(showError(`Error when cheking Download Package action response.  ${getErrorMessage(error)}`));
      //     });
      // }, 3000);
    })
    .fail(error => {
      closeModal();
      dispatch(showError(`Error when sending Download Package action request.  ${getErrorMessage(error)}`));
    });
};

export const getActionRequestors = () => (dispatch) => {
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/actions_requestors`
  })
  .done(res => {
    const actionsRequestorsMap = {};
    (res.requestors || []).forEach(r => actionsRequestorsMap[r.value] = r.name);

    dispatch({
      type: STORAGE_MNG_ACTIONS_REQUESTORS_SHOW,
      actionsRequestorsMap
    });
  })
  .fail(error => {

  });
}
