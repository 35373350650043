import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

class Paginate extends Component {
  render() {
    const {pageCount, selectedPage, onPageChange, previousLabel, nextLabel, containerClassName,
     marginPagesDisplayed, pageRangeDisplayed} = this.props;
    return (
      <ReactPaginate
        activeClassName="active"
        breakClassName="break-me"
        breakLabel="..."
        onPageChange={onPageChange}
        containerClassName={containerClassName}
        forcePage={selectedPage}
        marginPagesDisplayed={marginPagesDisplayed}
        nextLabel={nextLabel}
        previousLabel={previousLabel}
        pageCount={pageCount}
        pageRangeDisplayed={pageRangeDisplayed}
        subContainerClassName="pages pagination"
        />
    );
  }
}

Paginate.propTypes = {
  pageCount: PropTypes.number.isRequired,
  selectedPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  previousLabel: PropTypes.string,
  nextLabel: PropTypes.string,
  containerClassName: PropTypes.string,
  marginPagesDisplayed: PropTypes.number,
  pageRangeDisplayed: PropTypes.number
};

Paginate.defaultProps = {
  onPageChange: () => {},
  containerClassName: 'pagination-container',
  marginPagesDisplayed: 2,
  pageRangeDisplayed: 4
};

export default Paginate;
