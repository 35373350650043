import React, {Component} from "react";
import {CheckboxCell} from "../../../../../components/table/cells/CheckboxCell";
import styled from "styled-components";
import Table from "../../../../../components/table/Table";
import PropTypes from "prop-types";

class TableWrap extends Component {
  state = {
    change: false
  }

  handleIsChecked = (row) => {
    return row.selected;
  };

  handleIsAllChecked = () => {
    const {files} = this.props;
    if (files === null) {
      return false;
    }
    let selected = true;
    for (let file of files) {
      if (!file.selected) {
        selected = false;
        break;
      }
    }

    return selected;
  }

  handleOnCheckAll = () => {
    const {files} = this.props;
    let selected = this.handleIsAllChecked();
    for (let file of files) {
      file.selected = !selected;
    }

    this.setState({change: true});
  }

  handleOnCheck = (checked, row) => {
    const { onChangeIngestFiles } = this.props;
    row.selected = checked;
    onChangeIngestFiles(row);
    this.setState({change: true});
  };

  proxyIsCheck = (row) => {
    return row.has_proxy;
  }

  metadataIsCheck = (row) => {
    return row.has_metadata;
  }

  deliveredIsCheck = (row) => {
    return row.is_delivered;
  }

  sameMd5HashIsCheck = (row) => {
    return row.same_md5_hash;
  }

  defaultOnCheck = () => {
    return;
  };

  defaultIsDisabled = () => {
    return true;
  };

  headers = {
    selected: {
      title: '',
      className: 'checkbox-cell without-sort',
      component: CheckboxCell,
      isAllCheckbox: true,
      componentArgs: {
        isChecked: this.handleIsChecked,
        onChange: this.handleOnCheck,
        isAllChecked: this.handleIsAllChecked,
        onChangeAll: this.handleOnCheckAll,
      },
    },
    file_name: {
      title: 'Name'
    },
    wo_memfis: {
      title: 'WO#'
    },
    has_proxy: {
      title: 'Proxy',
      component: CheckboxCell,
      componentArgs: {
        isDisabled: this.defaultIsDisabled,
        isChecked: this.proxyIsCheck,
        onChange: this.defaultOnCheck,
      }
    },
    has_metadata: {
      title: 'Metadata',
      component: CheckboxCell,
      componentArgs: {
        isDisabled: this.defaultIsDisabled,
        isChecked: this.metadataIsCheck,
        onChange: this.defaultOnCheck,
      }
    }
  }

  render() {
    const {files} = this.props;

    if (files && files.length > 0) {
      if (files[0].is_delivered !== null) {
        this.headers.is_delivered = {
          title: 'Delivered',
          component: CheckboxCell,
          componentArgs: {
            isDisabled: this.defaultIsDisabled,
            isChecked: this.deliveredIsCheck,
            onChange: this.defaultOnCheck,
          }
        };
      }
      if (files[0].same_md5_hash !== null) {
        this.headers.same_md5_hash = {
          title: 'Md5 hash',
          component: CheckboxCell,
          componentArgs: {
            isDisabled: this.defaultIsDisabled,
            isChecked: this.sameMd5HashIsCheck,
            onChange: this.defaultOnCheck,
          }
        };
      }
    }

    return (
      <StyledTable className="table-wrapper">
        <div className="table-container">
          <div className="table-block">
            <Table
              headers={this.headers}
              rows={files === null || files.length === 0 ? [] : files}
              emptyText="No Files"
            />
          </div>
        </div>
      </StyledTable>
    );
  }
}

const StyledTable = styled.div`
  .table-container {
    width: 100%;
  }

  .table-block {
    max-width: 100%;
    max-height: calc(100vh - 350px);
    min-height: 100px;
    overflow-y: auto;
    margin-bottom: 0;
    border: 1px solid #ddd;

    .table {
      color: #282828;
      margin-bottom: 0;

      th {
        position: sticky;
        top: 0;
        background-color: #fff;
        border-right: 1px solid #ddd;
        border-bottom-width: 1px;
        &.link-to-wo {
          pointer-events: none;
        }
        &.title {
          width: 31%;
        }
      }

      td {
        border-right: 1px solid #ddd;
        &.word-break-all {
          word-break: break-all;
        }
        &.data-table-empty {
          text-align: center;
          width: 100% !important;
        }
      }
    }
  }

  + .pagination {
    margin: 10px 0 0;
  }
`;

TableWrap.propTypes = {
  files: PropTypes.array.isRequired
}

export default TableWrap;
