import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PageComponent from '../../components/PageComponent';
import AppLayout from '../../layouts/AppLayout';
import {getNotifications} from './actions';
import Table from './components/table/Table';

class NotificationsPage extends PageComponent {
  componentWillMount() {
    localStorage.setItem('last_view', 'download');
    const {dispatch, match} = this.props;
    dispatch(getNotifications(match.params.asperaLinkId));
  }

  render() {
    const {dispatch} = this.props;
    const {loading, notifications} = this.props.notifications;
    return (
      <AppLayout title="Notifications">
        <div className="container">
          <Table
            dispatch={dispatch}
            loading={loading}
            data={notifications}
            />
        </div>
      </AppLayout>
    );
  }
}

NotificationsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  notifications: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    notifications: state.notifications,
    user: state.user
  };
}

export default connect(
  mapStateToProps
)(withRouter(NotificationsPage));
