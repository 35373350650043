import React from 'react';
import PropTypes from 'prop-types';
import LinkToMemfisWoModal from './modal/link-to-wo/LinkToMemfisWoModal';
import IngestModal from './modal/IngestModal';

export const LinkToMemfisWoAndIngest = props => {
  const {
    dispatch, storageManager, requestedPage, isLoading, linkToMemfisWOCallback, onCompleteDownloadForMultipleWOs
  } = props;
  const {
    checkedItems, availableManualMemfisWOs, linkToMemfisWOIsSaving, finalDestinationIsLoading, finalDestinationData,
    useFinalDestinationDataReserved, availableFsRoots, ingestOperationTypes, ingestWorkOrder, linkInBurbankModalItem, clientNameIsLoading,
    ingestFromLinkToWo, addFileToWoModalState, LKFSModalState, woSteps, isWoStepsEmpty
  } = storageManager;

  if (linkInBurbankModalItem || availableManualMemfisWOs) {
    return (
      <LinkToMemfisWoModal
        dispatch={dispatch}
        storageManager={storageManager}
        item={checkedItems[0]}
        workOrders={availableManualMemfisWOs}
        saving={linkToMemfisWOIsSaving}
        availableFsRoots={availableFsRoots}
        finalDestinationIsLoading={finalDestinationIsLoading}
        finalDestinationData={finalDestinationData}
        useFinalDestinationDataReserved={useFinalDestinationDataReserved}
        isLoading={isLoading}
        clientNameIsLoading={clientNameIsLoading}
        startsWithLinkInBurbank={!!linkInBurbankModalItem}
        onCompleteDownloadForMultipleWOs={onCompleteDownloadForMultipleWOs}
        linkToMemfisWOCallback={linkToMemfisWOCallback}
        requestedPage={requestedPage}
        woSteps={woSteps}
        isWoStepsEmpty={isWoStepsEmpty}
      />
    );
  }
  if (ingestOperationTypes) {
    return (
      <IngestModal
        dispatch={dispatch}
        data={ingestOperationTypes}
        workOrder={ingestWorkOrder}
        fromLinkToWo={ingestFromLinkToWo}
        item={checkedItems[0]}
        isSending={linkToMemfisWOIsSaving}
        isLoading={isLoading}
        showFileSelection={addFileToWoModalState ? addFileToWoModalState.showFileSelection && addFileToWoModalState.ingestFiles : false}
        showIngestSettings={addFileToWoModalState ? addFileToWoModalState.showIngestSettings : false}
        lkfsModalState={LKFSModalState}
        storageManager={storageManager}
      />
    );
  }
  return null;
};

LinkToMemfisWoAndIngest.propTypes = {
  dispatch: PropTypes.func,
  storageManager: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  linkToMemfisWOCallback: PropTypes.func
};
