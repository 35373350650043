import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const ResponseCell = ({value, row, className}) => {
  const lines = (value || '').split(/\r?\n/g).filter(line => Boolean(line));
  const id = `action-${row.actionID}-response`;
  return (
    <StyledCell className={className}>
      {
        lines.slice(0, 3).map((line, index) => (
          <Fragment key={`log-line-${index}`}>
            {line}
            <br/>
          </Fragment>
        ))
      }
      {
        lines.length > 3 ?
          <Fragment>
            <div id={id} className="more-action-response collapse">
              {
                lines.slice(3).map((line, index) => (
                  <Fragment key={`log-line-${3 + index}`}>
                    {line}
                    <br/>
                  </Fragment>
                ))
              }
            </div>
            <div
              className="more-action-response-label collapsed"
              data-toggle="collapse"
              data-target={`#${id}`}
              >
               <span className="expand-label">show more...</span>
               <span className="collapse-label">collapse</span>
            </div>
          </Fragment> : null
      }
    </StyledCell>
  );
};

const StyledCell = styled.td`
  word-break: break-all;
  .collapse-label {
    display: none;
  }
  .expand-label, .collapse-label {
    font-weight: bold;
    font-size: 10px;
    line-height: 11px;
    color: #0DB4D9;
    padding: 0 0 0 1px;
    &:hover {
      color: #004A8D;
      cursor: pointer;
    }
  }
  .collapse.in {
    + .more-action-response-label {
      padding-top: 7px;
      .expand-label {
        display: none;
      }
      .collapse-label {
        display: unset;
      }
    }
  }
  .collapsing {
    + .more-action-response-label {
      span {
        visibility: hidden;
      }
    }
  }
`;

ResponseCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object,
  className: PropTypes.string
};
