import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {getSortedRowsBy} from '../../../../utils';
import {
  closeActionsView, getFsEntityListFromAction, getActions, getChildrenOfAction, getParentAction, showActionProgress,
  showScheduledPauseModal, showActionSubscriberView
} from '../../actions';
import {HeaderRow} from '../../../../components/table/HeaderRow';
import {Row} from '../../../../components/table/Row';
import DateCell from '../../../../components/table/cells/DateCell';
import {FsRootCell} from './FsRootCell';
import {ParametersCell} from './ParametersCell';
import {ResponseCell} from './ResponseCell';
import {PaddingCell} from './PaddingCell';
import {StatusCell} from './StatusCell';
import {HierarchyCell} from './HierarchyCell';
import {RequestorCell} from './RequestorCell';
import {RunnerCell} from './RunnerCell';
import {PauseCell} from './PauseCell';
import { ActionCell } from './ActionCell';
import Paginate from '../table/Paginate';
import Filters from '../../../../components/filters/Filters';
import {
  ACTIONS_FILTERS,
  ACTION_FILTER_ACTION_ID,
  ACTION_FILTER_RUNNER_ID,
  SELECTABLE_ACTION_FILTER_NAMES,
  ACTION__SOURCE_OF_REQUEST__ACTIONSBAR,
  ACTION__SOURCE_OF_REQUEST__ACTIONSVIEW,
  ACTION_DEFAULT_PAGE_SIZE,
  ACTION_DEFAULT_SORT,
} from './constants';
import _ from 'lodash';
import Loader from '../../../../components/Loader';
import {DISPLAY_CATEGORY_ACTION_CONTENTS} from '../../constants';
import {Collapse} from 'react-bootstrap';
import ReportBugButton from "../../../../components/ReportBugButton";
import ScheduledPauseModal from '../modal/ScheduledPauseModal';

class ActionsView extends Component {

  state = {
    pageSize: ACTION_DEFAULT_PAGE_SIZE,
    currentPage: 0,
    usedFilters: {...this.props.storageManager.usedActionsFilters},
    tableSort: {
      ...ACTION_DEFAULT_SORT
    },
    expandedActionsToDown: {},
    expandedActionsToUp: {},
    refreshing: false,
    isExpanded: true
  };

  componentDidUpdate(prevProps) {
    const {usedActionsFilters, actionsIsLoading} = this.props.storageManager;
    if ((JSON.stringify(usedActionsFilters) !== JSON.stringify(prevProps.storageManager.usedActionsFilters))) {
      this.setState({
        usedFilters: {...usedActionsFilters}
      });
    }
    if (!actionsIsLoading && prevProps.storageManager.actionsIsLoading) {
      this.setState({isExpanded: true});
    }
  }

  sortAltFields = {
    createdOn: 'createdOnLong',
    performedOn: 'performedOnLong',
    expirationDate: 'expirationDateLong',
    lastActiveOn: 'lastActiveOnLong'
  };

  handleHeaderCellClick = (field, direction) => {
    const tableSort = {field, direction};
    const {usedFilters, pageSize} = this.state;
    const {dispatch, history} = this.props;
    const paging = {
      start: 0,
      limit: pageSize,
    };
    dispatch(getActions(history, usedFilters, ACTION__SOURCE_OF_REQUEST__ACTIONSVIEW, paging, tableSort)).then(
      () => {
        this.setState({
          currentPage: 0,
          tableSort,
          expandedActionsToDown: {},
          expandedActionsToUp: {}
        });
      }
    );
  };

  handleClickListOfContentIDs = action => {
    const {dispatch, history} = this.props;
    dispatch(getFsEntityListFromAction(history, action, true)).then(
      () => {
        this.setState({isExpanded: false});
        /*const contentTable = document.getElementsByClassName('storage-content-table')[0];
        try {
          contentTable.scrollIntoView();
        } catch (ignored) {*//**//*}*/
      }
    );
  };

  handleActionSubscriptions = action => {
    const {dispatch} = this.props;
    dispatch(showActionSubscriberView(action));
  }

  handleShowProgress = action => {
    const {dispatch} = this.props;
    dispatch(showActionProgress(action));
  };

  isExpandedActionToDown = (depth, actionID) => {
    return this.state.expandedActionsToDown[`${depth}--${actionID}`] === true;
  };

  handleToggleChildren = action => {
    const key = `${action.depth}--${action.actionID}`;
    const isExpandedNew = !this.isExpandedActionToDown(action.depth, action.actionID);
    this.setState({
      expandedActionsToDown: {...this.state.expandedActionsToDown, [key]: isExpandedNew}
    });
    if (isExpandedNew) {
      const {dispatch} = this.props;
      dispatch(getChildrenOfAction(action.actionID));
    }
  };

  isExpandedActionToUp = (depth, actionID) => {
    return this.state.expandedActionsToUp[`${depth}--${actionID}`] === true;
  };

  handleToggleParent = action => {
    const key = `${action.depth}--${action.actionID}`;
    const isExpandedNew = !this.isExpandedActionToUp(action.depth, action.actionID);
    this.setState({
      expandedActionsToUp: {...this.state.expandedActionsToUp, [key]: isExpandedNew}
    });
    if (isExpandedNew) {
      const {dispatch} = this.props;
      dispatch(getParentAction(action.actionID, action.parentActionID));
    }
  };

  getActionRequestorName = requestorId => {
    const {actionsRequestorsMap} = this.props.storageManager;
    return (actionsRequestorsMap || {})[requestorId] || requestorId;
  };

  getRunners = () => {
    const {storageManager: {lockAndServicesStatuses}} = this.props;
    const runners = {};
    if (lockAndServicesStatuses) {
      const {MEMFISService, DATService, OtherRunners} = lockAndServicesStatuses;
      const addRunner = runner => {
        if (runner && runner.RunnerID) {
          runners[runner.RunnerID] = {...runner};
        }
      };
      addRunner(MEMFISService);
      addRunner(DATService);
      Object.keys(OtherRunners || {}).forEach(k => {
        addRunner(OtherRunners[k]);
      });
    }
    return runners;
  };

  renderScheduledPauseModal = (isShow, selectedAction) => {
    const {dispatch} = this.props;
    dispatch(showScheduledPauseModal(isShow, selectedAction));
  }

  headers = {
    toggleHierarchy: {
      title: '',
      className: 'toggle-hierarchy',
      component: HierarchyCell,
      componentArgs: {
        onClickUp: this.handleToggleParent,
        OnClickDown: this.handleToggleChildren
      }
    },
    actionID: {
      title: <span>Action&nbsp;ID</span>,
      className: 'action-id',
      component: ActionCell,
      componentArgs: {
        onClickBtn: this.handleActionSubscriptions
      }
    },
    actionType: {
      title: 'Type',
      className: 'action-type',
      component: PaddingCell
    },
    actionStatus: {
      title: 'Status',
      className: 'action-status',
      component: StatusCell,
      componentArgs: {
        onShowProgress: this.handleShowProgress
      }
    },
    fsRootPath: {
      title: <span>FS&nbsp;Root</span>,
      className: 'fs-root',
      component: FsRootCell
    },
    runnerID: {
      title: 'Runner',
      className: 'action-runner',
      component: RunnerCell,
      componentArgs: {
        runners: this.getRunners()
      }
    },
    actionRequestorName: {
      title: 'Requestor',
      className: 'action-requestor',
      component: RequestorCell,
      componentArgs: {
        getRequestorName: this.getActionRequestorName
      }
    },
    actionResponse: {
      title: 'Response',
      className: 'action-response',
      component: ResponseCell
    },
    createdOn: {
      title: <span>Created&nbsp;On</span>,
      className: 'created-on',
      component: DateCell,
      componentArgs: {
        toFormat: 'M/D/YYYY hh:mm:ss A'
      }
    },
    scheduledFor: {
      title: 'Scheduled For',
      className: 'scheduled-for',
      component: DateCell,
      componentArgs: {
        toFormat: 'M/D/YYYY hh:mm:ss A'
      }
    },
    scheduledPauseTime: {
      title: 'Pause On',
      className: 'pause-on',
      component: PauseCell,
      componentArgs: {
        toFormat: 'M/D/YYYY hh:mm:ss A',
        openScheduledPauseModal: (row) => this.renderScheduledPauseModal(true, row),
      }
    },
    expirationDate: {
      title: <span>Expiration&nbsp;Date</span>,
      className: 'expiration-date',
      component: DateCell,
      componentArgs: {
        toFormat: 'M/D/YYYY hh:mm:ss A'
      }
    },
    performedOn: {
      title: <span>Performed&nbsp;On</span>,
      className: 'performed-on',
      component: DateCell,
      componentArgs: {
        toFormat: 'M/D/YYYY hh:mm:ss A'
      }
    },
    lastActiveOn: {
      title: <span>Last&nbsp;Active&nbsp;On</span>,
      className: 'last-active-on',
      component: DateCell,
      componentArgs: {
        toFormat: 'M/D/YYYY hh:mm:ss A'
      }
    },
    parameters: {
      title: 'Parameters',
      className: 'parameters',
      component: ParametersCell,
      componentArgs: {
        onClickListOfContentIDs: this.handleClickListOfContentIDs
      }
    }
  };

  handleCloseView = action => {
    const {dispatch} = this.props;
    dispatch(closeActionsView());
  };

  handleChangeActionsNumber = event => {
    const {usedFilters, tableSort} = this.state;
    const {dispatch, history} = this.props;
    const pageSize = Number(event.target.value);
    const paging = {
      start: 0,
      limit: pageSize,
    };
    dispatch(getActions(history, usedFilters, ACTION__SOURCE_OF_REQUEST__ACTIONSVIEW, paging, tableSort)).then(
      () => {
        this.setState({
          currentPage: 0,
          pageSize,
          expandedActionsToDown: {},
          expandedActionsToUp: {}
        });
      }
    );
  };

  handlePageClick = page => {
    const {usedFilters, tableSort, pageSize} = this.state;
    const {dispatch, history} = this.props;
    const currentPage = page.selected;
    const paging = {
      start: currentPage * pageSize,
      limit: pageSize,
    };
    dispatch(getActions(history, usedFilters, ACTION__SOURCE_OF_REQUEST__ACTIONSVIEW, paging, tableSort)).then(
      () => {
        this.setState({
          currentPage,
          expandedActionsToDown: {},
          expandedActionsToUp: {}
        });
      }
    );
  };

  handleRefresh = () => {
    const {dispatch, history, storageManager} = this.props;
    const {usedActionsFilters, actionsParent, actions} = storageManager;
    const {expandedActionsToDown, expandedActionsToUp, tableSort, pageSize, currentPage} = this.state;
    const actionsToUp = _.uniq(_.compact(Object.keys(expandedActionsToUp).map(k => {
      if (expandedActionsToUp[k]) {
        const id = Number(k.split('--')[1]);
        const parentId = (actionsParent[id] || actions.find(a => a.actionID === id) || {}).parentActionID;
        if (parentId) {
          return parentId;
        }
      }
      return 0;
    })));
    const actionsToDown = _.uniq(_.compact(Object.keys(expandedActionsToDown).map(k => {
      if (expandedActionsToDown[k]) {
        const id =  Number(k.split('--')[1]);
        if (!actionsToUp.includes(id)) {
          return id;
        }
      }
      return 0;
    })));

    const paging = {
      start: currentPage * pageSize,
      limit: pageSize,
    };

    dispatch(getActions(history, usedActionsFilters, ACTION__SOURCE_OF_REQUEST__ACTIONSVIEW, paging, tableSort)).then(
      () => {
        this.setState({
          refreshing: (actionsToDown.length + actionsToUp.length) > 0,
          usedFilters: {...usedActionsFilters}
        });

        actionsToDown.forEach((id, i) => {
          dispatch(getChildrenOfAction(id)).always(() => {
            if (!actionsToUp.length && (i + 1) === actionsToDown.length) {
              this.setState({refreshing: false});
            }
          });
        });
        actionsToUp.forEach((id, i) => {
          dispatch(getParentAction(null, id)).always(() => {
            if ((i + 1) === actionsToUp.length) {
              this.setState({refreshing: false});
            }
          });
        });
      }
    );
  };

  handleApplyFilters = () => {
    const {usedFilters, tableSort, pageSize} = this.state;
    const {dispatch, history} = this.props;
    const paging = {
      start: 0,
      limit: pageSize,
    };
    dispatch(getActions(history, usedFilters, ACTION__SOURCE_OF_REQUEST__ACTIONSVIEW, paging, tableSort)).then(
      () => {
        this.setState({
          currentPage: 0,
          expandedActionsToDown: {},
          expandedActionsToUp: {}
        });
      }
    );
  };

  handleResetFilters = () => {
    const {tableSort, pageSize} = this.state;
    const {dispatch, history} = this.props;
    const paging = {
      start: 0,
      limit: pageSize,
    };
    dispatch(getActions(history, {}, ACTION__SOURCE_OF_REQUEST__ACTIONSVIEW, paging, tableSort)).then(
      () => {
        this.setState({
          usedFilters: {},
          currentPage: 0,
          expandedActionsToDown: {},
          expandedActionsToUp: {}
        });
      }
    );
  };

  handleChangeFilters = (name, value) => {
    if (SELECTABLE_ACTION_FILTER_NAMES.includes(name) && value && value.length) {
      value = value.map(item => item.value);
    } else if (name === ACTION_FILTER_ACTION_ID) {
      value = String(value).trim() === '' ? null : String(value).trim();
    }
    this.setState({usedFilters: {...this.state.usedFilters, [name]: value}});
  };

  renderRows = (rows, emptyText, depth, absDepth) => {
    if (!rows) {
      return null;
    }

    const {actionsChildren} = this.props.storageManager;
    const {tableSort} = this.state;
    const data = depth === null ? rows : getSortedRowsBy(rows, tableSort.field, tableSort.direction, this.sortAltFields);
    return (
      <Fragment>
        {
          data.map((row, i) => {
            const rowDepth = depth === null ? row.depth : depth;
            const isExpandedToDown = this.isExpandedActionToDown(rowDepth, row.actionID);
            const isExpandedToUp = this.isExpandedActionToUp(rowDepth, row.actionID);
            return (
              <Fragment key={i}>
                <Row
                  headers={this.headers}
                  row={{...row, depth: rowDepth, absDepth, isExpandedToDown, isExpandedToUp}}
                />
                {
                  (isExpandedToDown || rowDepth < 0) ?
                    this.renderRows(actionsChildren[row.actionID], emptyText, rowDepth + 1, absDepth + 1) : null
                }
              </Fragment>
            );
          })
        }
        {
          !data.length && emptyText ? (
            <tr>
              <td className="data-table-empty" colSpan={Object.keys(this.headers).length}>{emptyText}</td>
            </tr>
          ) : null
        }
      </Fragment>
    );
  };

  prepareFilters = () => {
    const {storageManager: {actionsFilters}} = this.props;
    const filters = [...actionsFilters || ACTIONS_FILTERS];
    (filters.find(f => f.name === ACTION_FILTER_RUNNER_ID) || {}).values = Object.values(this.getRunners()).map(runner => {
      const {RunnerID, MachineName, IPAddress} = runner;
      return {
        value: RunnerID,
        name: MachineName ? `${MachineName} (${RunnerID}${IPAddress ? `, ${IPAddress}` : ''})` : '(' + RunnerID + ')'
      };
    }).sort((a, b) => a.name.localeCompare(b.name));
    return filters;
  };

  renderActiveModal() {
    const {storageManager, dispatch} = this.props;
    const {isShowScheduledPauseModal, scheduledPauseSelectedAction} = storageManager;

    if (isShowScheduledPauseModal) {
      return (
        <ScheduledPauseModal
          dispatch={dispatch}
          storageManager={storageManager}
          item={scheduledPauseSelectedAction}
          onClose={() => this.renderScheduledPauseModal(false)}
          refreshFilters={this.handleRefresh}
        />
      );
    }

    return null;
  }

  render() {
    const {tableSort, pageSize, currentPage, usedFilters, refreshing, isExpanded} = this.state;
    const {
      actions, usedActionsFilters, actionsChildren, actionsParent, displayCategory, sourceOfActionsRequest,
      actionsTotal
    } = this.props.storageManager;
    const pageCount = Math.ceil(actionsTotal / pageSize) || 1;
    const selectedPage = (currentPage + 1) > pageCount ? 0 : currentPage;
    const isChangedFilters = JSON.stringify(usedActionsFilters) !== JSON.stringify(usedFilters);
    const preparedData = actions.map(row => {
      const parentIDs = [];
      let action = {...row};
      let depth = 0;
      while (action.parentActionID && this.isExpandedActionToUp(depth, action.actionID) &&
        actionsParent[action.parentActionID] && actionsChildren[action.parentActionID]) {
        parentIDs.push(action.parentActionID);
        --depth;
        action = {...actionsParent[action.parentActionID]};
      }
      return {...action, depth};
    });
    const atFirstTime = !(sourceOfActionsRequest && sourceOfActionsRequest !== ACTION__SOURCE_OF_REQUEST__ACTIONSBAR);
    return (
      <StyledView>
        {this.renderActiveModal()}
        {refreshing ?
            <div>
              <Loader className="full-screen"/>
              <ReportBugButton isForModal className="loader-report-bug-btn"/>
            </div> : null}
        <div className="top-container">
          <div className="actions-number">
            <label>
              Show
              <select value={pageSize} onChange={this.handleChangeActionsNumber}>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
              </select>
              actions
            </label>
          </div>
          <i className="fas fa-times" title="Close Actions View" onClick={this.handleCloseView}/>
        </div>
        <Collapse in={isExpanded || displayCategory !== DISPLAY_CATEGORY_ACTION_CONTENTS}>
          <div id="actions-view">
            <Filters
              loading={false}
              filters={this.prepareFilters()}
              usedFilters={usedFilters}
              onChange={this.handleChangeFilters}
              applyLabel={(isChangedFilters || atFirstTime) ? 'Apply' : 'Refresh'}
              applyFilters={(isChangedFilters || atFirstTime) ? this.handleApplyFilters : this.handleRefresh}
              resetFilters={this.handleResetFilters}
              title="Filters"
              bodyMd={12}
            />
            {!atFirstTime ?
              <>
                <div className="table-container">
                  <div className="table-block">
                    <table className="table">
                      <HeaderRow headers={this.headers} sort={tableSort} onHeaderCellClick={this.handleHeaderCellClick}/>
                      <tbody>
                        {this.renderRows(preparedData, 'No actions', null, 0)}
                      </tbody>
                    </table>
                  </div>
                </div>
                <Paginate
                  pageCount={pageCount}
                  selectedPage={selectedPage}
                  onPageChange={this.handlePageClick}
                  nextLabel=">"
                  previousLabel="<"
                  containerClassName="pagination"
                />
              </> : null
            }
          </div>
        </Collapse>
        {
          displayCategory === DISPLAY_CATEGORY_ACTION_CONTENTS ?
            <div className="collapse-action-bar">
              <span
                aria-controls="actions-view"
                aria-expanded={isExpanded}
                onClick={() => this.setState({isExpanded: !isExpanded})}
              >
              <i className="fa fa-angle-up"/>
              {`${isExpanded ? 'Collapse' : 'Expand'} Actions View`}
              </span>
            </div> : null
        }
      </StyledView>
    );
  }
}

const StyledView = styled.div`
  box-shadow: 0px 4px 10px rgba(0,0,0,0.05);
  padding: 15px;
  margin-bottom: 30px;
  .top-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
    i {
      border-radius: 50%;
      padding: 0;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      &:hover {
        cursor: pointer;
        background-color: #e0e0e0;
      }
      &.fa-times {
        margin-left: 10px;
      }
    }
    .actions-number {
      select {
        margin: 0 5px;
      }
    }
  }
  .collapse-action-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 15px;
    span {
      padding: 0 6px;
      border-radius: 5px;
      cursor: pointer;
      font-weight: bold;
      line-height: 20px;
      &:hover {
        background-color: #e0e0e0;
      }
      i {
        padding: 0 5px 0 0;
        height: 20px;
        font-weight: bold;
        line-height: 20px;
        transition: transform 0.2s ease 0s;
        will-change: transform;
      }
      &[aria-expanded="false"] i {
        padding: 0 0 0 5px;
        transform: rotate(180deg);
      }
    }
  }
  .table-container {
    .table-block {
      max-width: 100%;
      overflow-x: auto;
      .table {
        border-collapse: separate;
        color: #282828;
        margin-bottom: 0;
        tr {
          &:first-child {
            td {
              border-top: none;
            }
          }
          &:last-child {
            td {
              border-bottom: 1px solid #ddd;
            }
          }
          th {
            white-space: nowrap;
            cursor: pointer;
            &.filtered {
              display: flex;
              align-items: center;
            }
            &.parameters, &.toggle-hierarchy {
              pointer-events: none;
            }
          }
          td {
            &.data-table-empty {
              text-align: center;
              width: 100% !important;
            }
          }
        }
      }
    }
  }

  .filters-block {
    margin: 0;

    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;

      >.form-group {
        width: 48%;

        &:nth-of-type(even) {
          margin-left: 2%;
        }
        &:nth-of-type(odd) {
          margin-right: 2%;
        }
      }
    }

    input#action_id {
      &::-webkit-inner-spin-button, &::-webkit-outer-spin-button, &::-webkit-clear-button {
        display: none;
      }
    }

    .Select {
      .Select-menu-outer {
        max-height: 122px;
        .Select-menu {
          max-height: 120px;
        }
      }
    }
  }

  .filters-btn-toolbar {
    margin: 0 -32px;
    .btn-primary {
      min-width: 76px;
    }
  }
`;

ActionsView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  storageManager: PropTypes.object.isRequired
}

export default ActionsView;
