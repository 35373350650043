import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  applyNavigation, changeSearchInput, applyAdvancedSearch, showAdvSearchModal, changeNavigationBack, changeNavigationUp,
  changeNavigationForward, changeNavigationInput, closeInitialConfirmationOfDeletion, refreshCurrentNavigation, instantScan
} from '../actions';
import NavigationInput from './forms/NavigationInput';
import SearchInput from './forms/SearchInput';
import SearchModal from './modal/SearchModal';
import {Row, Col, Button} from 'react-bootstrap';
import {
  DISPLAY_CATEGORY_FOLDER, DISPLAY_CATEGORY_SEARCH_RESULTS, DISPLAY_CATEGORY_ACTION_CONTENTS,
  DISPLAY_CATEGORY_INIT_CONFIRM_DELETION, DISPLAY_CATEGORY_ROOTS,
  NAME_FILTER_NAME, SIZE_FILTER_NAME, LAST_MODIFIED_DATE_FILTER_NAME, USE_MAX_MODIFIED_ON_FILTER_NAME,
  FILENAME_AS_WELL_FILTER_NAME, LAST_SEGMENT_ONLY_FILTER_NAME, WO_MEMFIS_FILTER_NAME, DELETED_AS_WELL_FILTER_NAME,
  SEARCH_IN_FILTER_NAME, SEARCH_IN_OPTION_CURRENT_FOLDER, SEARCH_IN_OPTION_SELECTIONED_ITEMS,
  SYMLINKS_TOO_FILTER_NAME,
  getDefaultBooleanFiltersFromUsedFilters,
  filterNamesInString,
  FS_ACTION_TYPE__INSTANT_SCAN
} from '../constants';
import {isMacOsPathView} from '../utils';
import {formatDate, parseSize, isForbiddenAction, isHiddenActionBtn} from '../../../utils';

class Toolbar extends Component {
  state = {
    lastRefreshedFolderKey: null
  };

  componentDidUpdate(prevProps) {
    const {storageManager: {currentFolder, instantScanning}} = this.props;
    if ((currentFolder || {}).key !== (prevProps.storageManager.currentFolder || {}).key ||
        (instantScanning && !prevProps.storageManager.instantScanning)) {
      this.setState({lastRefreshedFolderKey: null});
    }
  }

  handleNavigation = (value, callback) => {
    if (value.slice(-1) === '\\') {
      value = value.slice(0, -1);
    }
    const {dispatch, history, storageManager: {appliedNavigationString}} = this.props;
    if (!value) {
      dispatch(changeNavigationInput(appliedNavigationString));
    } else {
      dispatch(applyNavigation(history, value, callback));
    }
  };

  handleChangeNavigationInput = value => {
    const {dispatch} = this.props;
    dispatch(changeNavigationInput(value));
  };

  handleClickNavigationInput = (event) => {
    event.target.select();
  };

  getCheckedDirectories = () => {
    const {storageManager} = this.props;
    const {checkedItems} = storageManager;
    return checkedItems.filter(item => item.isDirectory);
  };

  addCurrentFolderIntoFilters = filters => {
    const {storageManager} = this.props;
    const {currentFolder} = storageManager;
    if (currentFolder) {
      filters[SEARCH_IN_FILTER_NAME] = [{
        fs_root_id: currentFolder.fsRootID,
        content_id: currentFolder.contentID,
        file_name: currentFolder.key
      }];
    }
  };

  addCheckedDirectoriesFolderIntoFilters = filters => {
    const checkedDirectories = this.getCheckedDirectories();
    if (checkedDirectories.length) {
      filters[SEARCH_IN_FILTER_NAME] = checkedDirectories.map(item => ({
        fs_root_id: item.fsRootID,
        content_id: item.contentID > 0 ? item.contentID : null,
        file_name: item.key
      }));
    }
  };

  handleAdvancedSearch = (filters, inputSearchString, callback) => {
    const {dispatch, history, storageManager} = this.props;
    const {usedFilters, displayCategory} = storageManager;
    if (inputSearchString === undefined) {
      if (filters[SEARCH_IN_FILTER_NAME] === SEARCH_IN_OPTION_CURRENT_FOLDER) {
        this.addCurrentFolderIntoFilters(filters);
      } else if (filters[SEARCH_IN_FILTER_NAME] === SEARCH_IN_OPTION_SELECTIONED_ITEMS) {
        this.addCheckedDirectoriesFolderIntoFilters(filters);
      } else {
        delete filters[SEARCH_IN_FILTER_NAME];
      }
    }
    if (!Object.keys(filters).includes(SEARCH_IN_FILTER_NAME)) {
      if (displayCategory === DISPLAY_CATEGORY_SEARCH_RESULTS && (usedFilters[SEARCH_IN_FILTER_NAME] || []).length) {
        filters[SEARCH_IN_FILTER_NAME] = [...usedFilters[SEARCH_IN_FILTER_NAME]];
      } else if (displayCategory === DISPLAY_CATEGORY_FOLDER) {
        this.addCurrentFolderIntoFilters(filters);
      }
    }
    dispatch(applyAdvancedSearch(history, filters, inputSearchString, true)).then(() => {
      if (callback) {
        callback();
      }
    });
  };

  handleSearch = (inputSearchString, callback) => {
    const {usedFilters} = this.props.storageManager;
    const filters = getDefaultBooleanFiltersFromUsedFilters(usedFilters);
    const searchString = [];

    const temp = inputSearchString.trim().split(new RegExp('\\s?\\b(' + filterNamesInString.join('|') + ')\\b[:]'));
    if (!temp[0]) {
      temp.shift();
    }
    for (let i = 0; i < temp.length - 1; i += 2) {
      const name = temp[i];
      const value = temp[i + 1].trim();
      switch (name) {
        case filterNamesInString[0]:
          if (value) {
            filters[NAME_FILTER_NAME] = value;
            searchString.push(`${name}: ${value}`);
          }
          break;
        case filterNamesInString[1]:
          const sizeMin = parseSize(value, 'min');
          if (sizeMin !== null) {
            filters[SIZE_FILTER_NAME] = {...filters[SIZE_FILTER_NAME], from: sizeMin.value, from_unit: sizeMin.unit};
            searchString.push(`${name}: ${value}`);
          }
          break;
        case filterNamesInString[2]:
          const sizeMax = parseSize(value, 'max');
          if (sizeMax !== null) {
            filters[SIZE_FILTER_NAME] = {...filters[SIZE_FILTER_NAME], to: sizeMax.value, to_unit: sizeMax.unit};
            searchString.push(`${name}: ${value}`);
          }
          break;
        case filterNamesInString[3]:
          const dateMin = new Date(value);
          if (dateMin.getTime()) {
            filters[LAST_MODIFIED_DATE_FILTER_NAME] = {...filters[LAST_MODIFIED_DATE_FILTER_NAME], start: dateMin};
            searchString.push(`${name}: ${value}`);
          }
          break;
        case filterNamesInString[4]:
          const dateMax = new Date(value);
          if (dateMax.getTime()) {
            filters[LAST_MODIFIED_DATE_FILTER_NAME] = {...filters[LAST_MODIFIED_DATE_FILTER_NAME], end: dateMax};
            searchString.push(`${name}: ${value}`);
          }
          break;
        case USE_MAX_MODIFIED_ON_FILTER_NAME:
        case FILENAME_AS_WELL_FILTER_NAME:
        case DELETED_AS_WELL_FILTER_NAME:
        case LAST_SEGMENT_ONLY_FILTER_NAME:
        case SYMLINKS_TOO_FILTER_NAME:
          if (['true', 'false'].includes(value)) {
            filters[name] = value === 'true';
            searchString.push(`${name}: ${value}`);
          }
          break;
        case WO_MEMFIS_FILTER_NAME:
          if (value) {
            filters[name] = value;
            searchString.push(`${name}: ${value}`);
          }
          break;
        default:
          break;
      }
    }

    /*if (Object.keys(filters).length === 1 && inputSearchString) {
      filters[NAME_FILTER_NAME] = inputSearchString;
    }*/

    this.handleAdvancedSearch(filters, searchString.join(' ') || inputSearchString, callback);
  };

  handleClickSearch = () => {
    const {storageManager} = this.props;
    this.handleSearch(storageManager.inputSearchString);
  };

  handleChangeSearchInput = value => {
    const {dispatch} = this.props;
    dispatch(changeSearchInput(value));
  };

  handleClickAdvancedSearch = () => {
    const {dispatch} = this.props;
    dispatch(showAdvSearchModal(true));
  };

  handleCloseAdvancedSearch = () => {
    const {dispatch} = this.props;
    dispatch(showAdvSearchModal(false));
  };

  handleClickNavigationBack = () => {
    const {dispatch, history} = this.props;
    dispatch(changeNavigationBack(history));
  };

  handleClickNavigationForward = () => {
    const {dispatch, history} = this.props;
    dispatch(changeNavigationForward(history));
  };

  handleClickNavigationUp = () => {
    const {dispatch, history} = this.props;
    dispatch(changeNavigationUp(history));
  };

  handleCloseInitialConfirmationOfDeletion = () => {
    const {dispatch, history} = this.props;
    dispatch(closeInitialConfirmationOfDeletion(history));
  };

  handleClickRefresh = () => {
    const {dispatch, history, storageManager: {currentFolder}} = this.props;
    dispatch(refreshCurrentNavigation(history));
    this.setState({lastRefreshedFolderKey: (currentFolder || {}).key || null});
  };

  
  handleInstantScan = () => {
    const {dispatch, history} = this.props;
    dispatch(instantScan(history));
  };

  renderInstantScanBtn() {
    if (isHiddenActionBtn(FS_ACTION_TYPE__INSTANT_SCAN)) {
      return null;
    }
    const {lastRefreshedFolderKey} = this.state;
    const {storageManager: {currentFolder, displayCategory}} = this.props;
    const isAllowed = displayCategory === DISPLAY_CATEGORY_FOLDER && !!currentFolder;
    const isDisabled = !isAllowed ||
      lastRefreshedFolderKey !== currentFolder.key ||
      isForbiddenAction(FS_ACTION_TYPE__INSTANT_SCAN, currentFolder.fsRootID);
    return (
      <Button
        bsStyle={isDisabled ? 'default' : 'primary'}
        title={isDisabled && isAllowed ? 'Need to "Refresh" before "Instant scan"' : 'Instant scan'}
        disabled={isDisabled}
        onClick={this.handleInstantScan}
      >
        <i className="fas fa-history"/>
      </Button>
    );
  }

  render() {
    const {storageManager, lockStatusesHeight} = this.props;
    const {inputSearchString, appliedSearchString, isVisibleAdvSearchModal, allFilters, usedFilters,
      currentFolder, displayCategory, navigationHistory, navigationHistoryForward,
      inputNavigationString, inputNavigationStringForMacOS, appliedNavigationString, appliedNavigationStringForMacOS
    } = storageManager;
    const isMacOsPath = isMacOsPathView();
    const appliedNavString = isMacOsPath && appliedNavigationStringForMacOS ? appliedNavigationStringForMacOS : appliedNavigationString;
    const inputNavString = isMacOsPath && inputNavigationStringForMacOS ? inputNavigationStringForMacOS : inputNavigationString;
    const preparedUsedFilters = {...usedFilters};
    delete preparedUsedFilters[SEARCH_IN_FILTER_NAME];
    const fsRootLastUpdated = displayCategory === DISPLAY_CATEGORY_FOLDER ?
      formatDate(currentFolder.fsRootUpdated, '', 'M/D/YYYY hh:mm:ss A') : null;
    return (
      <StyledToolbar top={lockStatusesHeight}>
        {
          isVisibleAdvSearchModal ? (
            <SearchModal
              onSearch={this.handleAdvancedSearch}
              onClose={this.handleCloseAdvancedSearch}
              allFilters={allFilters}
              usedFilters={preparedUsedFilters}
              isSelectedMultipleItems={this.getCheckedDirectories().length > 0}
              displayCategory={displayCategory}
            />
          ) : null
        }
        <div className="container">
          <Row>
            <Col md={8} xs={12} className="col-navigation">
              <Button
                bsStyle="primary"
                title="Back"
                onClick={this.handleClickNavigationBack}
                disabled={!navigationHistory.length}
                >
                <i className="fa fa-arrow-left"/>
              </Button>
              <Button
                bsStyle="primary"
                title="Forward"
                onClick={this.handleClickNavigationForward}
                disabled={!navigationHistoryForward.length}
                >
                <i className="fa fa-arrow-right"/>
              </Button>
              <Button
                bsStyle="primary"
                title="Up"
                onClick={this.handleClickNavigationUp}
                disabled={displayCategory !== DISPLAY_CATEGORY_FOLDER}
                >
                <i className="fa fa-arrow-up"/>
              </Button>
              <div className="current-directory">
                {
                  fsRootLastUpdated ?
                    <div className="fs-root-last-updated">Last Updated: {fsRootLastUpdated}</div> : null
                }
                <NavigationInput
                  value={inputNavString}
                  appliedValue={appliedNavString}
                  onChange={this.handleChangeNavigationInput}
                  onEnter={this.handleNavigation}
                  onClick={this.handleClickNavigationInput}
                />
                {
                  displayCategory === DISPLAY_CATEGORY_INIT_CONFIRM_DELETION ?
                    <div className="init-confirm-delete-close" title="Click &times; to close">
                      Initial confirmation of deletion
                      <i
                        className="fas fa-times"
                        title="Close initial confirmation of deletion"
                        onClick={this.handleCloseInitialConfirmationOfDeletion}
                      />
                    </div> : null
                }
              </div>
              <Button
                bsStyle="primary"
                title="Refresh"
                onClick={this.handleClickRefresh}
                disabled={![DISPLAY_CATEGORY_FOLDER, DISPLAY_CATEGORY_ROOTS].includes(displayCategory)}
                >
                <i className="fas fa-sync"/>
              </Button>
              {this.renderInstantScanBtn()}
            </Col>
            <Col md={4} xs={12} className="col-search">
              <div
                className="advanced-search-btn"
                onClick={this.handleClickAdvancedSearch}
                >
                Advanced Search
              </div>
              <SearchInput
                title={`Advanced search query fields: ${filterNamesInString.join(', ')}`}
                value={inputSearchString}
                appliedValue={appliedSearchString}
                onChange={this.handleChangeSearchInput}
                onEnter={this.handleSearch}
                placeholder={
                  [
                    DISPLAY_CATEGORY_SEARCH_RESULTS,
                    DISPLAY_CATEGORY_ACTION_CONTENTS,
                    DISPLAY_CATEGORY_INIT_CONFIRM_DELETION
                  ].includes(displayCategory) ? '' : appliedNavString
                }
              />
              <Button
                id="search-btn-primary"
                bsStyle="primary"
                title="Search"
                onClick={this.handleClickSearch}
                >
                <i className="fas fa-search"/>
              </Button>
            </Col>
          </Row>
        </div>
      </StyledToolbar>
    );
  }
}

const StyledToolbar = styled.div`
  position: fixed;
  top: ${props => `${props.top + 51}px`};
  right: 0;
  left: 0;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 1020;

  @media (max-width: 767px) {
    height: 130px;
  }

  .col-navigation, .col-search {
    display: flex;
    padding-top: 26px;

    button {

      & + button {
        margin-left: 10px;
      }
    }

    input {
      flex-grow: 1;

      & + button {
        margin-left: 10px;
      }
    }
  }

  .advanced-search-btn {
    position: absolute;
    right: 65px;
    top: 3px;
    color: #337ab7;
    text-decoration: underline;
    text-transform: lowercase;

    &:hover, &:focus {
      color: #23527c;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .current-directory {
    flex-grow: 1;
    margin-left: 20px;
    position: relative;

    .fs-root-last-updated {
      position: absolute;
      top: -15px;
      right: 0;
      font-size: 11px;
      line-height: 12px;
      color: grey;
    }

    .init-confirm-delete-close {
      position: absolute;
      top: 0;
      left: 9px;
      color: #555;
      font-size: 10px;
      line-height: 34px;
      &:hover {
        color: #282828;
      }
      i {
        margin-left: 6px;
        padding: 0 1px;
        cursor: pointer;
        &:hover {
          text-shadow: 0 0 2px #ccc;
        }
      }
    }

    input:focus + .init-confirm-delete-close {
      display: none;
    }

    & + button {
      margin-left: 10px;
    }
  }
`;

Toolbar.propsTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  storageManager: PropTypes.object.isRequired
};

export default Toolbar;
