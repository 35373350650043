import React, {Component} from 'react';
import {Checkbox} from "react-bootstrap";
import DateField from "../filters/DateField";
import {showError} from "../../layouts/actions";
import {isSchedulableActionType, isPausableActionType} from "../../utils";
import {FS_ACTION_TYPE__MIGRATE_PORTAL, FS_ACTION_TYPE__COPY, FS_ACTION_TYPE__SIMPLE_COPY} from "../../pages/StorageManager/constants";
import PropTypes from "prop-types";

class SchedulerForm extends Component {
  addTime = 1000*1*60;

  state = {
    isScheduledFor: null,
    dateScheduledFor: null,
    isScheduledPauseTime: null,
    scheduledPauseTime: null,
    isDailyRepeat: false
  }

  isFolderToMigrate = () => {
    const {item, selectedFolder} = this.props;
    return !!selectedFolder && selectedFolder.fsRootID !== item.fsRootID;
  };

  isMigrate = () => {
    const {allowedActions} = this.props;
    return allowedActions.includes(FS_ACTION_TYPE__MIGRATE_PORTAL) && (allowedActions.length === 1 || this.isFolderToMigrate());
  };

  isCopy = () => {
    const {allowedActions} = this.props;
    return allowedActions.includes(FS_ACTION_TYPE__COPY) && (allowedActions.length === 1 || this.isFolderToMigrate());
  };

  isSimpleCopy = () => {
    const {allowedActions} = this.props;
    return allowedActions.includes(FS_ACTION_TYPE__SIMPLE_COPY);
  };

  isAccessActionTypes = () => {
    return this.isMigrate() || this.isCopy() || this.isSimpleCopy();
  }

  isAccessSchedulableActionType = () => {
    return isSchedulableActionType(FS_ACTION_TYPE__MIGRATE_PORTAL)
      || isSchedulableActionType(FS_ACTION_TYPE__COPY)
      || isSchedulableActionType(FS_ACTION_TYPE__SIMPLE_COPY);
  }

  isAccessPausableActionType = () => {
    return isPausableActionType(FS_ACTION_TYPE__MIGRATE_PORTAL)
      || isPausableActionType(FS_ACTION_TYPE__COPY)
      || isPausableActionType(FS_ACTION_TYPE__SIMPLE_COPY);
  }

  isShowScheduledFor = () => {
    return this.isAccessActionTypes() && this.isAccessSchedulableActionType();
  };

  isShowScheduledPauseTime = () => {
    return this.isAccessActionTypes() && this.isAccessPausableActionType();
  };

  renderScheduledFor = () => {
    const {dispatch, onChangeScheduledFor, title} = this.props;
    const {isScheduledFor, dateScheduledFor, scheduledPauseTime, isDailyRepeat} = this.state;
    if (this.isShowScheduledFor()) {
      return (
        <>
          <Checkbox
            checked={!!isScheduledFor}
            onChange={
              ({target: {checked}}) => {
                this.setState({isScheduledFor: checked, dateScheduledFor: null});
                onChangeScheduledFor(checked, null);
              }
            }
          >
            Add Scheduled Start Time for "{title}" action
          </Checkbox>
          {!!isScheduledFor &&
          <DateField
            id="date-schedulable-for"
            styles={{width: '190px'}}
            placeholderText=""
            selected={dateScheduledFor}
            selectsStart
            minDate={new Date()}
            startDate={dateScheduledFor || new Date()}
            onChange={date => {
              if (date && date.getTime() < Date.now()) {
                date = new Date(Date.now() + this.addTime);
              }

              if (date && scheduledPauseTime && scheduledPauseTime.getTime() <= date.getTime()) {
                dispatch(showError(`Pause On value cannot be greater than Scheduled For`));
              }

              if (isDailyRepeat && date && scheduledPauseTime && (scheduledPauseTime.getTime() - date.getTime() > 86400000 || scheduledPauseTime.getTime() - date.getTime() < -86400000)) {
                dispatch(showError(`The difference cannot be more than a day`));
              }

              this.setState({dateScheduledFor: date});
              onChangeScheduledFor(isScheduledFor, date);
            }}
          />
          }
        </>
      );
    }
    return null;
  };

  renderScheduledPauseTime = () => {
    const {dispatch, onChangeScheduledPauseTime, title} = this.props;
    const {isScheduledPauseTime, scheduledPauseTime, dateScheduledFor, isDailyRepeat} = this.state;
    if (this.isShowScheduledPauseTime()) {
      return (
        <>
          <Checkbox
            checked={!!isScheduledPauseTime}
            onChange={
              ({target: {checked}}) => {
                this.setState({isScheduledPauseTime: checked, scheduledPauseTime: null});
                onChangeScheduledPauseTime(checked, null);
              }
            }
          >
            Add Scheduled Pause Time for "{title}" action
          </Checkbox>
          {!!isScheduledPauseTime &&
            <DateField
              id="scheduled-pause-time"
              styles={{width: '190px'}}
              placeholderText=""
              selected={scheduledPauseTime}
              selectsStart
              minDate={new Date()}
              startDate={scheduledPauseTime || new Date()}
              onChange={date => {
                if (date && date.getTime() < Date.now()) {
                  date = new Date(Date.now() + this.addTime);
                }

                if (dateScheduledFor && date && date.getTime() <= dateScheduledFor.getTime()) {
                  dispatch(showError(`Pause On value cannot be greater than Scheduled For`));
                }

                if (isDailyRepeat && date && dateScheduledFor && (dateScheduledFor.getTime() - date.getTime() > 86400000 || dateScheduledFor.getTime() - date.getTime() < -86400000)) {
                  dispatch(showError(`The difference cannot be more than a day`));
                }

                this.setState({scheduledPauseTime: date});
                onChangeScheduledPauseTime(isScheduledPauseTime, date);
              }}
            />
          }
        </>
      );
    }
    return null;
  };

  renderCheckbox = () => {
    if (this.isShowScheduledFor() && this.isShowScheduledPauseTime()) {
      const {onChangeDailyRepeat, dispatch} = this.props;
      const {isDailyRepeat, isScheduledPauseTime, isScheduledFor, dateScheduledFor, scheduledPauseTime} = this.state;
      const isEnabled = isScheduledPauseTime && isScheduledFor;

      return (
        <>
          <Checkbox
            checked={!!isDailyRepeat}
            onChange={
              ({target: {checked}}) => {
                if (checked && scheduledPauseTime && dateScheduledFor && (dateScheduledFor.getTime() - scheduledPauseTime.getTime() > 86400000 || dateScheduledFor.getTime() - scheduledPauseTime.getTime() < -86400000)) {
                  dispatch(showError(`The difference cannot be more than a day`));
                }

                this.setState({isDailyRepeat: checked});
                onChangeDailyRepeat(checked);
              }
            }
            disabled={!isEnabled}
          >
            Daily Repeat
          </Checkbox>
        </>
      )
    }
    return null;
  }

  render() {
    return (
      <>
        {this.renderScheduledFor()}
        {this.renderScheduledPauseTime()}
        {this.renderCheckbox()}
      </>
    )
  }
}

SchedulerForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  allowedActions: PropTypes.array.isRequired,
  onChangeScheduledFor: PropTypes.func.isRequired,
  onChangeScheduledPauseTime: PropTypes.func.isRequired,
  onChangeDailyRepeat: PropTypes.func.isRequired,
  selectedFolder: PropTypes.object.isRequired
};

export default SchedulerForm;
