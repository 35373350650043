import React, {Component, Fragment} from "react";
import {produce} from 'immer';
import PropTypes from 'prop-types';
import {Panel, PanelGroup, Row, Col, Checkbox, FormGroup, Radio, ControlLabel} from 'react-bootstrap';
import {Button, Modal} from "react-bootstrap";
import {saveSettings, setCurrentView, switchSettingsModal, setHideTempFiles, setAttachedVolumes} from "../../actions";
import styled from "styled-components";
import Select from 'react-select';
import { ALL_VIEWS, PATH_VIEW_DOS, PATH_VIEW_MAC_OS } from "../../constants";
import {getPathView, setPathView} from '../../utils';
import RndModal from '../../../../components/modals/RndModal';

function removeSettingsFieldsUnnecessaryForSave(settings) {
  return Object.entries(settings).reduce((fullCopy, [categoryCodeName, categoryObj]) => {
    fullCopy[categoryCodeName] = {
      values: Object.entries(categoryObj.values).reduce((valueCopy, [valueCodeName, valueObj]) => {
        valueCopy[valueCodeName] = {
          value: String(valueObj.value),
        }
        return valueCopy;
      }, {}),
    };
    return fullCopy;
  }, {});
}

const LocalSettings = {
  currentView: {
    label: 'View',
    values: {
      attachedVolumes: {
        type: 'local_checkbox',
        title: 'Attached Volumes',
      },
      currentView: {
        type: 'local_select',
        options: ALL_VIEWS
      },
      pathView: {
        type: 'local_radio',
        title: 'Path View',
        options: [{
          value: PATH_VIEW_DOS,
          label: 'DOS'
        }, {
          value: PATH_VIEW_MAC_OS,
          label: 'MacOS'
        }]
      },
    }
  },
  /*hideTempFiles: {
    label: 'Hide Temp Files',
    values: {
      hideTempFiles: {
        type: 'local_checkbox'
      }
    }
  }*/
};

const isNumber = (value) => typeof value === 'number' && !Number.isNaN(value);

class SettingsModal extends Component {
  state = {
    localSettingsValues: {},
    editedSettings: {},
    expandedCategories: [], /* Keys of editedSettings */
  };

  componentDidMount() {
    const {settings, currentView, hideTempFiles, attachedVolumes} = this.props;
    const settingsDeepCopy = JSON.parse(JSON.stringify(settings));
    const localSettingsValues = {
      currentView,
      hideTempFiles,
      attachedVolumes,
      pathView: getPathView(),
    };
    this.setState({
      startLocalSettingsValues: {...localSettingsValues},
      localSettingsValues,
      editedSettings: {...settingsDeepCopy, ...LocalSettings},
      expandedCategories: [...Object.keys(settings), ...Object.keys(LocalSettings)],
    });
  }

  handleChangeLocalSetting = (name, val) => {
    this.setState({localSettingsValues: {...this.state.localSettingsValues, [name]: val}});
  };

  render() {
    const {settings} = this.props;
    const {editedSettings, localSettingsValues, startLocalSettingsValues} = this.state;
    const nonLocalSettings = {...editedSettings};
    Object.keys(localSettingsValues).forEach(k => {
      delete nonLocalSettings[k];
    });
    const isChangedNonLocalSettings = JSON.stringify(settings) !== JSON.stringify(nonLocalSettings);
    const isChangedLocalSettings = JSON.stringify(startLocalSettingsValues) !== JSON.stringify(localSettingsValues);
    const submitDisabled = !isChangedNonLocalSettings && !isChangedLocalSettings;

    return (
      <RndModal
        className="move-modal"
        show={true}
        onHide={this.onClose}
        backdrop="static"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PanelGroup id='storage-manager-settings-panel-group'>
            { Object.keys(editedSettings).map((categoryCodeName) => {
              return this.renderCollapsableSettingsCategory(categoryCodeName);
            }) }
          </PanelGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary"
                  disabled={submitDisabled}
                  onClick={() => this.onSubmit(isChangedNonLocalSettings && nonLocalSettings, isChangedLocalSettings)}>
            Save
          </Button>
          <Button bsStyle="default"
                  onClick={this.onClose}>Cancel</Button>
        </Modal.Footer>
      </RndModal>
    );
  }
  renderCollapsableSettingsCategory(categoryCodeName) {
    const {expandedCategories, localSettingsValues} = this.state;
    const {values, label} = this.state.editedSettings[categoryCodeName];
    const isExpanded = expandedCategories.includes(categoryCodeName);
    return <StyledPanel expanded={isExpanded} key={categoryCodeName} onToggle={() => {}}>
      <StyledPanelHeading onClick={() => this.onSwitchCollapsed(categoryCodeName)}
      ><h4>{label}</h4></StyledPanelHeading>
      <StyledPanelBody collapsible={true}>
        {Object.entries(values).map(([valueCodeName, valueObj]) => {
          switch (valueObj.type) {
            case 'number': {
              return <NumericSetting
                key={valueCodeName}
                valueObj={valueObj}
                onChange={(value) => this.onChangeNumeric(categoryCodeName, valueCodeName, value)}
              />;
            }
            case 'local_select': {
              return <Select
                key={valueCodeName}
                value={localSettingsValues[valueCodeName]}
                options={valueObj.options}
                onChange={o => !!o && this.handleChangeLocalSetting(valueCodeName, o.value)}
                searchable={false}
                clearable={false}
              />;
            }
            case 'local_checkbox': {
              return <Checkbox
                key={valueCodeName}
                checked={localSettingsValues[valueCodeName]}
                onChange={({target: {checked}}) => this.handleChangeLocalSetting(valueCodeName, checked)}
              >{valueObj.title || label}</Checkbox>;
            }
            case 'local_radio': {
              return (
                <RadioFormGroup key={valueCodeName}>
                  <ControlLabel>
                    {valueObj.title}
                  </ControlLabel>
                  {valueObj.options.map((o, i) => (
                    <Fragment key={`${valueCodeName}--${i}`}>
                      {i > 0 ? ' ' : ''}
                      <Radio
                        inline
                        name={valueCodeName}
                        value={o.value}
                        checked={localSettingsValues[valueCodeName] === o.value}
                        onChange={({target: {value}}) => this.handleChangeLocalSetting(valueCodeName, value)}
                      >
                        {o.label}
                      </Radio>
                    </Fragment>
                  ))}
                </RadioFormGroup>
              );
            }
            default:
              return null;
          }
        })}
      </StyledPanelBody>
    </StyledPanel>;
  }
  onSwitchCollapsed(categoryCodeName) {
    this.setState(produce(this.state, (draft) => {
      if (draft.expandedCategories.includes(categoryCodeName)) {
        draft.expandedCategories = draft.expandedCategories.filter((i) => i !== categoryCodeName);
      } else {
        draft.expandedCategories.push(categoryCodeName);
      }
    }));
  }
  onChangeNumeric(categoryCodeName, valueCodeName, value) {
    this.setState(produce(this.state, (draft) => {
      draft.editedSettings[categoryCodeName].values[valueCodeName].value = value;
    }));
  }
  onSubmit = (nonLocalSettings, isChangedLocalSettings) => {
    const {dispatch, history} = this.props;
    if (nonLocalSettings) {
      dispatch(saveSettings(removeSettingsFieldsUnnecessaryForSave(nonLocalSettings)));
    }
    if (isChangedLocalSettings) {
      const {localSettingsValues, startLocalSettingsValues} = this.state;
      const {currentView, hideTempFiles, attachedVolumes, pathView} = localSettingsValues;
      if (hideTempFiles !== startLocalSettingsValues.hideTempFiles) {
        dispatch(setHideTempFiles(hideTempFiles));
      }
      if (currentView !== startLocalSettingsValues.currentView) {
        dispatch(setCurrentView(history, currentView));
      }
      if (attachedVolumes !== startLocalSettingsValues.attachedVolumes) {
        dispatch(setAttachedVolumes(attachedVolumes));
      }
      if (pathView !== startLocalSettingsValues.pathView) {
        setPathView(pathView);
      }
    }
    dispatch(switchSettingsModal(false));
  }
  onClose = () => {
    const {dispatch} = this.props;
    dispatch(switchSettingsModal(false));
  }
}

class NumericSetting extends Component {
  state = {
    showMore: false,
  }
  render() {
    const {showMore} = this.state;
    const {
      value,
      editable,
      description,
      minValue,
      maxValue,
      label,
    } = this.props.valueObj;
    const minmax = (isNumber(minValue) && isNumber(maxValue)) ? ` (${minValue} - ${maxValue})` : '';
    return (<NumericSettingRow>
      <Col md={8}>
        <span className='field-label'
           title={description}>{`${label}${minmax}`}</span>
        <button className='moreLessButton'
                onClick={this.toggleShowMore}>{showMore ? '(less)' : '(more)'}</button>
        {showMore && <p>{description}</p>}
      </Col>
      <Col md={4}>
        <InputStyled type='number'
                     min={minValue}
                     max={maxValue}
                     disabled={!editable}
                     onChange={this.verifyThenSubmitNewValue}
                     value={value}
                     title={description}
        />
      </Col>
    </NumericSettingRow>);
  }
  toggleShowMore = () => {
    this.setState({showMore: !this.state.showMore});
  }
  verifyThenSubmitNewValue = (event) => {
    const {
      minValue,
      maxValue,
    } = this.props.valueObj;
    const {onChange} = this.props;
    const value = parseFloat(event.target.value);
    if (
      isNumber(value)
      && (isNumber(minValue) ? (value >= minValue) : true)
      && (isNumber(maxValue) ? (value <= maxValue) : true)
    ) {
      onChange(value);
    }
  }
}

const InputStyled = styled.input`
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0,0,0,0.05);
  font-size: 14px;
  font-weight: normal;
  height: 30px;
  padding: 3px 6px;
  line-height: 30px;
  width:100%;

  &::placeholder {
    font-style: italic;
    font-weight: normal;
    color: grey;
  }
`;

const StyledPanel = styled(Panel)`
h4 {
  margin:0;
}
.field-label {
  margin: auto;
  line-height:30px;
  font-weight:600;
}
`;

const StyledPanelHeading = styled(Panel.Heading)`
cursor:pointer;
h4 {
  user-select:none; 
  &:hover {
    text-decoration:underline;  
  }
}
`;

const StyledPanelBody = styled(Panel.Body)`
  display:grid;
  grid-gap:1rem;
  padding:5px 15px;
`;

const NumericSettingRow = styled(Row)`
.moreLessButton {
  font-size:80%;
  background:transparent;
  border:none;
  margin-left:5px;
  &:hover {
    text-decoration:underline;
  }
}
`;

const RadioFormGroup = styled(FormGroup)`
  margin-top: 15px;
  label {
    &.control-label {
      margin: 0 20px 0 0;
    }
  }
`;

SettingsModal.propTypes = {
  dispatch: PropTypes.func,
  settings: PropTypes.object,
};

export default React.memo(SettingsModal);