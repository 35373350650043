import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {StyledTable} from './TableStyled';
import {getSortedRowsBy} from '../../../../utils';
import Table from '../../../../components/table/Table';
import {CheckboxHeaderCell} from '../../../../components/table/cells/CheckboxHeaderCell';
import {CheckboxCell} from '../../../../components/table/cells/CheckboxCell';
import DateCell from '../../../../components/table/cells/DateCell';
import {FileTypeCell} from './cells/FileTypeCell';
import {NameCell} from './cells/NameCell';
import FullPathCell from './cells/FullPathCell';
import {SizeCell} from './cells/SizeCell';
import {PredictiveStatusCell} from './cells/PredictiveStatusCell';
import InfoPanel from './InfoPanel';
import {changeCheckedItems, changeNavigationIntoCurrent} from '../../actions';
import classNames from 'classnames';
import _ from 'lodash';
import {
  filterDataForView, VIEW_ADVANCED_PREDICTIVE, PREDICTIVE_STATE_DISAPPEARED, PREDICTIVE_STATE_APPEARED,
  DISPLAY_CATEGORY_INIT_CONFIRM_DELETION, DISPLAY_CATEGORY_ROOTS, DISPLAY_CATEGORY_FOLDER
} from '../../constants';
import {prepareDataIfTooManyFiles, isRootItem} from '../../utils';

class TableWrap extends Component {

  isEqualsItems = (item1, item2) => {
    return item1.fsRootID === item2.fsRootID && item1.contentID === item2.contentID &&
      item1.key === item2.key &&
      (item1.parentID || 0) === (item2.parentID || 0) &&
      (item1.predictiveStateID || 0) === (item2.predictiveStateID || 0) &&
      JSON.stringify(item1.parents || []) === JSON.stringify(item2.parents || []);
  };

  isCheckedItem = row => {
    const {checkedItems} = this.props.storageManager;
    return Boolean(checkedItems.find(item => this.isEqualsItems(item, row)));
  };

  handleChangeCheck = (checked, row, shiftKey) => {
    const {dispatch, storageManager, data} = this.props;
    const {tableSort, lastCheckedItem} = this.state;
    const {currentView} = storageManager;
    const [dataForView] = filterDataForView(data, currentView);
    const sortedData = getSortedRowsBy(dataForView, tableSort.field, tableSort.direction, this.sortAltFields);

    const checkedItems = [...storageManager.checkedItems];
    if (checked) {
      if (shiftKey && !this.isEqualsItems(lastCheckedItem, row)) {
        let startAddingItems = false;

        for (const dataItem of sortedData) {
          if (this.isEqualsItems(dataItem, lastCheckedItem) || this.isEqualsItems(dataItem, row)) {
            startAddingItems = !startAddingItems;

            // add last item in range
            if (!startAddingItems) {
              const notChecked = _.findIndex(checkedItems, item => this.isEqualsItems(item, dataItem)) < 0;
              if (notChecked) {
                checkedItems.push(dataItem);
              }
            }
          }

          if (startAddingItems) {
            const notChecked = _.findIndex(checkedItems, item => this.isEqualsItems(item, dataItem)) < 0;
            if (notChecked) {
              checkedItems.push(dataItem);
            }
          }
        }
      } else {
        checkedItems.push(row);
      }
    } else {
      if (shiftKey && !this.isEqualsItems(lastCheckedItem, row)) {
        let startRemoveItems = false;

        for (const dataItem of sortedData) {
          if (this.isEqualsItems(dataItem, lastCheckedItem) || this.isEqualsItems(dataItem, row)) {
            startRemoveItems = !startRemoveItems;

            if (!startRemoveItems) {
              _.remove(checkedItems, item => this.isEqualsItems(item, dataItem));
            }
          }
          if (startRemoveItems) {
            _.remove(checkedItems, item => this.isEqualsItems(item, dataItem));
          }
        }
      } else {
        _.remove(checkedItems, item => this.isEqualsItems(item, row));
      }
    }
    dispatch(changeCheckedItems(checkedItems));

    this.setState({lastCheckedItem: row});
  };

  handleChangeHeaderCheck = checked => {
    const {dispatch, data} = this.props;
    dispatch(changeCheckedItems(checked ? data : []));
  };

  isChecked = row => {
    return this.isCheckedItem(row);
  };

  isHeaderChecked = () => {
    const {data, storageManager} = this.props;
    const {checkedItems} = storageManager;
    return checkedItems.length && checkedItems.length === data.length;
  };

  handleNameCellClick = row => {
    const {dispatch, history} = this.props;
    dispatch(changeNavigationIntoCurrent(history, row));
  };

  state = {
    lastCheckedItem: null,
    tableSort: {
      field: 'name',
      direction: 'asc'
    }
  };

  getPredictiveStatus = statusId => {
    const {predictiveStates} = this.props.storageManager;
    return predictiveStates.find(s => s.PredictiveStateID === statusId);
  };

  getClassForNameCell = row => {
    const {currentView} = this.props.storageManager;
    if (currentView === VIEW_ADVANCED_PREDICTIVE) {
      const {predictiveStateID} = row;
      if (predictiveStateID === PREDICTIVE_STATE_APPEARED) {
        return 'red';
      } if (predictiveStateID === PREDICTIVE_STATE_DISAPPEARED) {
        return 'strikethrough';
      }
    }
    return '';
  };

  headers = {
    selected: {
      title: '',
      component: CheckboxCell,
      componentArgs: {
        isDisabled: this.props.isNeedToConnectDriveToRoot,
        isChecked: this.isChecked,
        onChange: this.handleChangeCheck
      },
      headCell: {
        component: CheckboxHeaderCell,
        componentArgs: {
          isChecked: this.isHeaderChecked,
          onChange: this.handleChangeHeaderCheck
        }
      }
    },
    name: {
      title: 'Name',
      className: 'name',
      component: NameCell,
      componentArgs: {
        dispatch: this.props.dispatch,
        getConnectDriveComponent: this.props.getConnectDriveComponent,
        getTextClassName: this.getClassForNameCell,
        history: this.props.history,
        isNeedToConnectDrive: this.props.isNeedToConnectDriveToRoot,
        isOpened: () => false,
        onClick: this.handleNameCellClick
      }
    },
    fileExtensionP: {
      title: 'File Type',
      className: 'file-type',
      component: FileTypeCell
    },
    fullPath: {
      title: 'Full Path',
      component: FullPathCell,
      className: 'full-path'
    },
    fsRootUpdated: {
      title: 'Last Updated',
      className: 'updated',
      component: DateCell,
      componentArgs: {
        toFormat: 'M/D/YYYY hh:mm:ss A',
        defaultValue: '-'
      }
    },
    fsRootScanned: {
      title: 'Last Scanned',
      className: 'last-scanned',
      component: DateCell,
      componentArgs: {
        toFormat: 'M/D/YYYY hh:mm:ss A',
        defaultValue: '-'
      }
    },
    modifiedOn: {
      title: 'Date Modified',
      className: 'modified',
      component: DateCell,
      componentArgs: {
        toFormat: 'M/D/YYYY hh:mm:ss A',
        defaultValue: '-'
      }
    },
    size: {
      title: 'Size',
      className: 'size',
      component: SizeCell
    },
    predictiveStateID: {
      title: 'Predictive Status',
      className: 'predictive-status',
      component: PredictiveStatusCell,
      componentArgs: {
        getStatus: this.getPredictiveStatus
      }
    }
  };

  sortAltFields = {
    modifiedOn: 'modified'
  };

  handleHeaderCellClick = (field, direction) => {
    this.setState({tableSort: {field, direction}});
  };

  handleRowClick = (row, clickEvent) => {
    !this.props.isNeedToConnectDriveToRoot(row) && this.handleChangeCheck(!this.isChecked(row), row, clickEvent.shiftKey);
  };

  render() {
    const {dispatch, history, storageManager, data} = this.props;
    const {checkedItems, currentView, displayCategory} = storageManager;
    const isShowSummaryOfDeletion = displayCategory === DISPLAY_CATEGORY_INIT_CONFIRM_DELETION;
    const isShowFullPathInTable = displayCategory !== DISPLAY_CATEGORY_FOLDER;
    const isShowFilePathOnInfoPanel = ![DISPLAY_CATEGORY_ROOTS, DISPLAY_CATEGORY_FOLDER].includes(displayCategory);
    const isInfoPanelCollapsed = !isShowSummaryOfDeletion && Boolean(!checkedItems.length);
    const {tableSort} = this.state;
    const [dataForView] = filterDataForView(data, currentView);
    const sortedData = getSortedRowsBy(dataForView, tableSort.field, tableSort.direction, this.sortAltFields);
    const displayedData = prepareDataIfTooManyFiles(sortedData);
    const isNoRoots = !dataForView.find(isRootItem);
    return (
      <StyledTable
        className={classNames({
          'storage-content-table': true,
          'table-wrapper': true,
          'info-panel-collapsed': isInfoPanelCollapsed
        })}
        >
        <div className="table-container">
          <div className="table-block">
            <Table
              className={classNames({
                [currentView.toLowerCase()]: true,
                'without-full-path': !isShowFullPathInTable,
                'without-file-type': !isNoRoots,
                'no-roots': isNoRoots
              })}
              headers={this.headers}
              rows={displayedData}
              sort={tableSort}
              onHeaderCellClick={this.handleHeaderCellClick}
              onRowClick={this.handleRowClick}
              getRowClassNames={r => r.isEllipsis ? 'tr-ellipsis' : undefined}
              stripped={false}
              emptyTextInCaption="No Files"
              />
          </div>
        </div>
        <InfoPanel
          dispatch={dispatch}
          history={history}
          storageManager={storageManager}
          items={checkedItems}
          isSummaryOfDeletion={isShowSummaryOfDeletion}
          isShowFilePath={isShowFilePathOnInfoPanel}
          allowRefresh={[DISPLAY_CATEGORY_FOLDER, DISPLAY_CATEGORY_ROOTS].includes(displayCategory)}
          isShowNumberOfRecords={displayCategory === DISPLAY_CATEGORY_ROOTS}
        />
      </StyledTable>
    );
  }
}

TableWrap.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  storageManager: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired
}

export default TableWrap;
