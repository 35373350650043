import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {isDeletedStatus} from '../../../utils';
import {prepareFileName, prepareFileIcon} from './NameCell';
import {defaultSymlinkTraits, getSymlinkCellTraits} from "./symlinkCellTraits";

export const ExpandedCheckedNameCell = (props) => {
  const {
    className,
    dispatch,
    getConnectDriveComponent,
    getTextClassName,
    history,
    isChecked,
    isNeedToConnectDrive,
    isOpened,
    onCheck,
    onClick,
    onExpand,
    row,
  } = props;

  const cellRef = useRef(null);

  const depth = !row.parents ? 0 : row.parents.length;
  const paddingLeft = depth > 0 ? depth * 17 + 8 : undefined;

  if (row.isEllipsis) {
    return (
      <StyledCell
        ref={cellRef}
        className={`${className || ''} td-ellipsis`}
        paddingLeft={paddingLeft}
      >
        &hellip;
      </StyledCell>
    );
  }
  const isDirectory = row.isDirectory !== false;
  const isExpanded = isDirectory ? isOpened(row) : null;
  let symlinkTraits = defaultSymlinkTraits;
  if (row.isSymlink) {
    symlinkTraits = getSymlinkCellTraits(row, dispatch, history);
  }
  const contentId = row.contentID > 0 ? (!isDirectory ? row.parentID : row.contentID) : undefined;
  const textClassName = getTextClassName(row);
  const isDeleted = isDeletedStatus(row);
  const isNeedsDriveConnection = isNeedToConnectDrive(row);
  return (
    <StyledCell
      ref={cellRef}
      className={`${
        className || ''}${
        !isDirectory ? '' : ' is-directory'}${
        isDeleted ? ' deleted' : ''}${
        row.isSymlink ? ' is-symlink' : ''}`}
      paddingLeft={paddingLeft}
      >
      <div className={`cell-cont${isNeedsDriveConnection ? ' needs-drive-connection' : ''}`}>
        {isNeedsDriveConnection && getConnectDriveComponent(row)}
        <div className="cell-icons">
          {isDirectory ?
            <i
              className={`fas fa-${isExpanded ? 'minus' : 'plus'}-square${isDeleted ? ' deleted' : ''}`}
              title={isDeleted ? undefined : isExpanded ? 'Collapse' : 'Expand'}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                !isDeleted && onExpand(row);
              }}
            /> : <i className="far fa-square"/>
          }
          {isChecked && onCheck ?
            <input
              type="checkbox"
              checked={isChecked(row)}
              onClick={event => event.stopPropagation()}
              onChange={event => onCheck(event.target.checked, row)}
              /> : null
          }
          {symlinkTraits.iconClassName ?
            <i
              className={symlinkTraits.iconClassName + (isDeleted ? ' deleted' : '')}
              role="button"
              onClick={symlinkTraits.onClickSymlink}
            /> : prepareFileIcon(row, isOpened, isDeleted)
          }
        </div>
        <div className="cell-value">
          {
            isDeleted ? <span className={`deleted ${textClassName}`}>{prepareFileName(row)}</span> :
              <a
                className={textClassName}
                title={symlinkTraits.symlinkRelativePath}
                href={`/storage?root_id=${row.fsRootID}${contentId ? `&content_id=${contentId}` : ''}`}
                onClick={event => {
                  if (event.ctrlKey) {
                    event.stopPropagation();
                  } else {
                    event.preventDefault();
                    if (isDirectory) {
                      event.stopPropagation();
                      onClick(row);
                    }
                  }
                }}
                >
                {prepareFileName(row, symlinkTraits.symlinkRelativePath)}
              </a>
          }
        </div>
      </div>
    </StyledCell>
  );
};

const StyledCell = styled.td`
  ${props => props.paddingLeft && css`
    padding-left: ${props.paddingLeft}px !important;
  `}

  position: relative;
  .strikethrough {
    text-decoration:line-through;
  }
  &.is-symlink a {
    color: #bb38bb;
  }

  .cell-cont {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .cell-icons {
      margin-top: 3px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      .file--type--icon {
        margin-top: -4px;
      }

      & + .cell-value {
        margin-top: 0;
        flex-grow: 1;
        white-space: nowrap;
        /*overflow: hidden;*/
        max-width: calc(100% - 60px);
        ${props => props.paddingLeft && css`
           max-width: calc(100% - 60px - ${props.paddingLeft}px);
        `}
        /*display: flex;
        align-items: flex-start;
        justify-content: flex-start;*/

        a, span.deleted {
          max-width: 100%;
        }
      }
    }

    &.needs-drive-connection {
      position: relative;
      &:not(:hover) {
        .connect-drive {
          display: none;
        }
      }
      .connect-drive {
        position: absolute;
        top: -4px;
        left: -4px;
        width: calc(100% + 8px);
        height: 100%;
        button {
          width: 100%;
          background-color: #efefefd6;
          border-color: #8a8a8a;
          border-radius: 4px;
          &:hover {
            background-color: #efefef;
            border-color: #ccc;
          }
        }
      }
      i, input, a {
        pointer-events: none;
      }
    }
  }

  i {
    margin-right: 0.5rem;

    &.deleted {
      color: red;
    }

    &.fa-square {
      visibility: hidden;
    }

    &.fa-minus-square, &.fa-plus-square {
      color: #757575;

      &:not(.deleted):hover {
        color: #282828;
        cursor: pointer;
      }
    }
  }

  &.is-directory {
    span {
      &:hover {
        cursor: pointer;
      }
    }
  }

  input[type="checkbox"] {
    margin: 0 0.5rem 0 0;
  }

  a, span {
    &.deleted {
      /*pointer-events: none;*/
      color: red !important;
      text-decoration: line-through;
    }
    &.red {
      color: red !important;
    }
    &.strikethrough {
      text-decoration: line-through;
    }
  }

  .std--folder--icon, .std--symlink--icon, .file--type--icon {
    margin-right: 0.5rem;
    width: 20px;
    text-align: center;
  }
`;

ExpandedCheckedNameCell.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  getTextClassName: PropTypes.func,
  history: PropTypes.object.isRequired,
  isChecked: PropTypes.any,
  isOpened: PropTypes.func,
  onCheck: PropTypes.func,
  onClick: PropTypes.func,
  onExpand: PropTypes.func,
  row: PropTypes.object,
  value: PropTypes.any,
};
