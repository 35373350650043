import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import styled from 'styled-components';
import {executeAllOfficesAction} from "../../actions";
import {
  FS_ACTION_TYPE__START_DAT_SERVICE_RUNNER,
  FS_ACTION_TYPE__STOP_DAT_SERVICE_RUNNER
} from "../../constants";
import RndModal from '../../../../components/modals/RndModal';

class AllOfficesActionModal extends Component {

  componentWillUnmount() {
    this.props.onClose();
  }

  onExecute = () => {
    const {dispatch, DATService} = this.props;
    let RunnerID, StatusBits;
    if (DATService) {
      RunnerID = DATService.RunnerID;
      StatusBits = DATService.StatusBits;
    } else {
      return;
    }
    const isShut = Boolean(StatusBits & 2);
    const action = isShut ? FS_ACTION_TYPE__START_DAT_SERVICE_RUNNER : FS_ACTION_TYPE__STOP_DAT_SERVICE_RUNNER;
    dispatch(executeAllOfficesAction(action, RunnerID));
    this.props.onClose();
  }

  render() {
    const {DATService, RunnerStatusBit} = this.props;
    if (!DATService || !DATService.StatusBits) {
      return null;
    }
    const StatusBitsObj = RunnerStatusBit[DATService.StatusBits];
    const {actionLabel, stateLabel, iconStateForFS, iconActionForFS} = StatusBitsObj;
    return (
      <RndModal
        show={this.props.show}
        onHide={this.props.onClose}
        backdrop="static"
        enforceFocus={false}
        bsSize="small"
      >
        <Modal.Header closeButton>
          <Modal.Title>Runners in all offices</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContainerStyled className="container-fluid">
            <div className={"runner-state"}>
              <div className="runner-icon">
                <i className={iconStateForFS}/>
              </div>
              {stateLabel.map(label =>
                <div key={label} className="runner-label">
                  {label}
                </div>
              )}
            </div>
            <div className={"runner-action"}>
              <div
                className="runner-icon"
                onClick={this.onExecute}
                title={`Click to ${actionLabel}`}
              >
                <i className={iconActionForFS}/>
              </div>
              <div className="runner-label">
                {actionLabel}
              </div>
            </div>
          </ModalContainerStyled>
        </Modal.Body>

      </RndModal>
    );
  }
}

const ModalContainerStyled = styled.div`
  min-height: 150px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  i {
    position: relative;

    &.icon-run {
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-left: 17px solid #000;
      border-bottom: 10px solid transparent;

      &.being-stopped:after {
        content: '';
        position: absolute;
        top: -3px;
        left: -14px;
        width: 6px;
        height: 6px;
        border: 1px solid #000;
        background: #fff;
      }
    }

    &.icon-stop {
      width: 18px;
      height: 18px;
      background-color: #000;

      &.being-started {
        &:before {
          content: '';
          position: absolute;
          top: 4px;
          left: 5px;
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-left: 8px solid #000;
          border-bottom: 5px solid transparent;
          z-index: 2;
        }
        &:after {
          content: '';
          position: absolute;
          top: 6px;
          left: 6px;
          width: 0;
          height: 0;
          border-top: 3px solid transparent;
          border-left: 5px solid #fff;
          border-bottom: 3px solid transparent;
          z-index: 3;
        }
      }
    }
  }

  .runner-state, .runner-action {
    margin: 25px 0;
    padding: 0 10px;
    flex: 0 0 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .runner-icon {
      margin-bottom: 7px;
      padding: 5px;
      width: 30px;
      height: 30px;
      border: 1px solid transparent;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .runner-label {
      color: #282828;
      font-weight: bold;
      font-size: 13px;
      text-transform: capitalize;
    }
  }

  .runner-state {
    i {
      &.icon-run {
        border-left-color: #757575;
      }
      &.icon-stop {
        background-color: #757575;
      }
    }

    .runner-label {
      color: #757575;
    }
  }

  .runner-action {
    .runner-icon {
      cursor: pointer;
      :hover {
        border-color: #ccc;
        background-color: #ececec;
      }
    }
  }
`;

AllOfficesActionModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  DATService: PropTypes.object,
  RunnerStatusBit: PropTypes.object.isRequired,
};

export default AllOfficesActionModal;
