import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {ACTION_STATUS_ACTIVE} from './constants';

export const StatusColor = {
  NEW: '#07d4f5',
  ACTIVE: '#17a2b8',
  DONE: '#00aa00',
  FAILED: '#ff4400',
  MIA: '',
  EXPIRED: 'black',
  WAITING_SUB_ACTION: '#2007ec',
  WAITING_EXECUTOR_ACTION: '#2007ec',
  REJECTED: '#888888',
  INCOMPLETE: '#1ed08e',
  POST_RESTARTING: '#e8a100',
  WAITING_RESTART: '#e8a100',
  DISABLED_BY_FAILURE: '#d63c04',
};

export const StatusCell = ({value, className, row, onShowProgress}) => {
  const {showProgress, progress} = row;
  const allowClickByProgress = !!onShowProgress && value === ACTION_STATUS_ACTIVE;
  return (
    <StyledCell>
      <div
        className="status"
        style={StatusColor[value] ? {backgroundColor: StatusColor[value]} : undefined}
        >
        {value}
      </div>
      {!!showProgress &&
        <div
          className={`progress${allowClickByProgress ? '' : ' disabled'}`}
          onClick={allowClickByProgress ? () => onShowProgress(row) : undefined}
          title={`Progress: ${progress || 0}%`}
        >
          <div
            className="progress-bar"
            style={{background: StatusColor.DONE, width: `${progress || 0}%`}}
          >
            {progress >= 25 ? `${progress}%` : ''}
          </div>
        </div>
      }
    </StyledCell>
  );
};

const StyledCell = styled.td`
  .status {
    background-color: #343a40;
    border: 1px solid transparent;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    margin: 0 3px 3px 0;
    padding: 0 4px;
  }
  .progress {
    min-width: 50px;
    width: 100%;
    height: 20px;
    margin: 20px 0 0;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
    cursor: pointer;
    .progress-bar {
      float: left;
      width: 0%;
      height: 100%;
      font-size: 12px;
      line-height: 20px;
      color: #fff;
      text-align: center;
      background-color: #337ab7;
      box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
      transition: width .6s ease;
    }
    &.disabled {
      cursor: default;
    }
  }
`;

StatusCell.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string,
  row: PropTypes.object,
  onShowProgress: PropTypes.func,
};
