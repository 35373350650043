import config from "../../../../config";
import { ISSUE_FILTERS_TYPES } from "../constants/filters";
import ISSUE_TYPES from "../constants/types";
import { formatXDate } from "../../../utils";

export const getOptionByValue = (options, value) => {
  return (options || []).find(o => o.value === value || (o.label || o.name || '').toUpperCase() === (value || '').toUpperCase()) || null;
};

export const getUrlForScreenshot = (id) => {
  let url = config.apiUrl.replace(/\/services$/, "");

  if (url.includes("localhost")) {
    url = url.replace("localhost", "127.0.0.1");
  }

  return `${url}/reported_bug_screenshots/screenshot_${id}.png`;
};

export const isEqualsBugReports = (r1, r2) => {
  const isBugIdEquals = r1.ReportedBugID === r2.ReportedBugID;
  return isBugIdEquals;
};

export const isStatusResolved = (status) => {
  return (status || '').toUpperCase() === 'RESOLVED';
};


export const getStatusLabelStyle = (status) => {
  const preparedStatus = (status || '').toUpperCase().replace(/[ _]/g, '');
  switch(preparedStatus) {
    case 'RESOLVED':
      return 'success';
    case 'FEEDBACK':
      return 'danger';
    case 'INPROGRESS':
      return 'warning';
    case 'CREATED':
      return 'primary';
    default:
      return 'default';
  }
};

export const filterBugReports = (data, filters) => {
  let filteredData = [...data];

  if (filters[ISSUE_FILTERS_TYPES.ID]) {
    filteredData = filteredData.filter((item) => item[ISSUE_FILTERS_TYPES.ID] === +filters[ISSUE_FILTERS_TYPES.ID]);
  }


  if (filters[ISSUE_FILTERS_TYPES.HIDE_RESOLVED]) {
    filteredData = filteredData.filter((item) => !isStatusResolved(item[ISSUE_FILTERS_TYPES.STATUS]));
  }

  [
    ISSUE_FILTERS_TYPES.STATUS,
  ].forEach(key => {
    if (Array.isArray(filters[key]) && filters[key].every(Boolean)) {
      const preparedValues = filters[key].map(o => o.toUpperCase().replace(/[ _]/g, ''));
      filteredData = filteredData.filter((item) => preparedValues.includes((item[key] || '').toUpperCase().replace(/[ _]/g, '')));
    }
  });

  [
    ISSUE_FILTERS_TYPES.PRIORITY,
    ISSUE_FILTERS_TYPES.CATEGORY,
  ].forEach(key => {
    if (filters[key]) {
      filteredData = filteredData.filter((item) => (item[key] || '').toUpperCase().replace(/[ _]/g, '') === filters[key].toUpperCase().replace(/[ _]/g, ''));
    }
  });

  [
    ISSUE_FILTERS_TYPES.REPORTED_BY,
    ISSUE_FILTERS_TYPES.ASSIGNED_TO,
    ISSUE_FILTERS_TYPES.JIRA_URL,
    ISSUE_FILTERS_TYPES.SEARCH,
  ].forEach(key => {
    if (filters[key]) {
      filteredData = filteredData.filter((item) => (item[key] || '').toUpperCase().includes(filters[key].toUpperCase()));
    }
  });

  if (filters[ISSUE_FILTERS_TYPES.DAYS_BACK_FROM]) {
    const filterValue = filters[ISSUE_FILTERS_TYPES.DAYS_BACK_FROM];
    const { daysBack, fromDate } = filterValue;
    const maxDate = fromDate && fromDate > 0 ? fromDate : Date.now();
    const minDate = maxDate - ((daysBack && daysBack > 0 ? daysBack : 0) * 24 * 60 * 60 * 1000);
    filteredData = filteredData.filter((item) => {
      const xDate = item[ISSUE_TYPES.UPDATED_ON] && formatXDate(item[ISSUE_TYPES.UPDATED_ON], 0);
      if (xDate) {
        return xDate >= minDate && xDate <= (maxDate + 10000);
      }
      return false;
    });
  }

  return filteredData;
};

export const saveSettingsToStorage = (name, value) => {
  const localSettings = JSON.parse(localStorage.getItem('mamIssueBrowserSettings'));
  localStorage.setItem('mamIssueBrowserSettings', JSON.stringify({...localSettings, [name]: value}));
  const sessionSettings = JSON.parse(sessionStorage.getItem('mamIssueBrowserSettings'));
  sessionStorage.setItem('mamIssueBrowserSettings', JSON.stringify({...sessionSettings, [name]: value}));
};

export const getSettingsFromStorage = (name) => {
  const localStorageSettings = JSON.parse(localStorage.getItem('mamIssueBrowserSettings')) || {};
  const sessionStorageSettings = JSON.parse(sessionStorage.getItem('mamIssueBrowserSettings')) || {};
  return sessionStorageSettings[name] || localStorageSettings[name];
};
