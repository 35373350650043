import {
  USER_HIDE_LOGIN_MESSAGE, USER_SET_IS_LOGIN, USER_SET_IS_LOGOUT, USER_SAVE_PREVIOUS_PAGE,
  USER_SHOW_LOGIN_ERROR_MESSAGE, USER_SHOW_LOGIN_MESSAGE, USER_SET_DATA_FIELD
} from './actions';

const initialState = {
  isLogin: false,
  loginMessage: '',
  errorLoginMessage: '',
  previousPage: ''
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SHOW_LOGIN_MESSAGE: {
      return {...state, loginMessage: action.message};
    }

    case USER_SHOW_LOGIN_ERROR_MESSAGE: {
      return {...state, loginErrorMessage: action.message};
    }

    case USER_HIDE_LOGIN_MESSAGE: {
      return {
        ...state,
        loginMessage: '',
        loginErrorMessage: ''
      };
    }

    case USER_SET_IS_LOGIN: {
      return {...state, isLogin: true};
    }

    case USER_SET_IS_LOGOUT: {
      return {...state, isLogin: false};
    }

    case USER_SAVE_PREVIOUS_PAGE: {
      return {...state, previousPage: action.previousPage};
    }

    case USER_SET_DATA_FIELD: {
      return {...state, [action.name]: action.value};
    }

    default: {
      return state;
    }
  }
}
