import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const NameCell = ({value, row, className}) => {
  let iconClassName;
  if (row.IsDirectory === 'false') {
    const ext = /^.+[.](pdf|bmp|png|jpg|rar|zip)$/.exec(value);
    if (ext && ext.length > 1) {
      iconClassName = ext[1] === 'pdf' ? 'file-pdf' : ['jpg', 'png', 'bmp'].includes(ext[1]) ? 'file-image' :
        ['rar', 'zip'].includes(ext[1]) ? 'file-archive' : '';
    }
    if (!iconClassName) {
      iconClassName = 'file';
    }
  } else {
    iconClassName = 'folder-open';
  }
  return (
    <StyledCell className={className}>
      <i className={`far fa-${iconClassName}`}/>
      {value}
    </StyledCell>
  );
};

const StyledCell = styled.td`
  i {
    padding-right: 0.5rem;
  }
`;

NameCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object,
  className: PropTypes.string
};
