import React, {useRef} from 'react';
import styled, {css} from 'styled-components';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import MiddleEllipsis from 'react-middle-ellipsis';

const prepareFileName = (fileName, name, treeItemRef) => {
  return (
    <OverlayTrigger
      placement="bottom"
      container={(treeItemRef || {}).current || undefined}
      overlay={<Tooltip id="delivery-full-filename-tooltip">{fileName}</Tooltip>}
    >
      <div className="ellipse-value">
        <MiddleEllipsis>
          <span data-original={name}>{name}</span>
        </MiddleEllipsis>
      </div>
    </OverlayTrigger>
  );
};

export const TreeItem = (props) => {
  const {
    isDirectory, fileName, parsedName, depth, _children, isCheckedToSelect, onSelect, onExpand, isExpandedDir,
    onHide, isHiddenDir, isShowSelected, isFiltered
  } = props;

  const treeItemRef = useRef(null);

  if (isHiddenDir(props) || !isFiltered(props)) {
    return null;
  }

  const isExpanded = isExpandedDir(props);
  const isChecked = isCheckedToSelect(props);

  return (
    <>
      <StyledTreeItem
        ref={treeItemRef}
        paddingLeft={depth > 0 ? depth * 17 + 1 : undefined}
      >
        <div className="tree-item-cont">
          <div className="tree-item-icons">
            {isDirectory ?
              <i
                className={`far fa-${isExpanded ? 'minus' : 'plus'}-square`}
                title={isExpanded ? 'Collapse' : 'Expand'}
                onClick={() => onExpand(props)}
              /> : <i className="fas fa-square"/>
            }
            {isDirectory ? null :
              <i
                className={isChecked ? 'far fa-check-square' : 'far fa-square'}
                onClick={() => onSelect(props, isChecked)}
              />
            }
            <i className={`far fa-${!isDirectory ? 'file' : isExpanded ? 'folder-open' : 'folder'}`}/>
          </div>
          <div className={`tree-item-value${isDirectory && onHide ? ' directory-name' : ''}`}>
            <span>
              {
                isDirectory ?
                  <>
                    {parsedName}
                    {!!onHide &&
                      <i
                        className="far fa-eye-slash"
                        title="Hide directory"
                        onClick={() => onHide(props)}
                      />
                    }
                  </> : prepareFileName(fileName, parsedName, treeItemRef)
              }
            </span>
          </div>
        </div>
      </StyledTreeItem>
      {isDirectory && isExpanded && (_children || []).map(child => (child.isDirectory || child.isSelected === isShowSelected) ?
        <TreeItem
          key={child._id}
          {...child}
          isHiddenDir={isHiddenDir}
          isExpandedDir={isExpandedDir}
          onHide={onHide}
          onExpand={onExpand}
          onSelect={onSelect}
          isCheckedToSelect={isCheckedToSelect}
          isShowSelected={isShowSelected}
          isFiltered={isFiltered}
        /> : null
      )}
    </>
  );
};

const StyledTreeItem = styled.div`
  ${props => props.paddingLeft && css`
    padding-left: ${props.paddingLeft}px !important;
  `}

  position: relative;

  .tooltip {
    left: 60px !important;
    margin-top: 0;
    z-index: 1;
    .tooltip-inner {
      max-width: unset;
      text-align: left;
      word-break: break-all;
    }
  }

  .tree-item-cont {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .tree-item-icons {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      & + .tree-item-value {
        /*flex-grow: 1;*/
        max-width: calc(100% - 55px);
        white-space: nowrap;
        overflow: hidden;
        &.directory-name {
          max-width: calc(100% - 55px - 24px);
        }
      }
    }
  }

  i {
    margin-right: 5px;

    &.fas.fa-square {
      visibility: hidden;
    }

    &.fa-minus-square, &.fa-plus-square {
      color: #757575;

      &:hover {
        color: #282828;
        cursor: pointer;
      }
    }
  }
`;
