import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {getSortedRowsBy} from '../../../../../utils';
import {isEqualsItems} from '../../../utils';
import Table from '../../../../../components/table/Table';
import {CheckboxCell} from '../../../../../components/table/cells/CheckboxCell';
import {NameCell} from './cells/NameCell';
import {StatusCell} from '../cells/StatusCell';
import InfoPanel from '../infopanel/InfoPanel';

class TableWrap extends Component {
  state = {
    tableSort: {
      field: '',
      direction: ''
    },
    checkedRow: null
  };

  isChecked = row => {
    const {checkedRow} = this.state;
    return !!checkedRow && isEqualsItems(checkedRow, row);
  };

  handleChangeCheck = (checked, row) => {
    if (checked) {
      this.setState({checkedRow: row});
    } else {
      this.setState({checkedRow: null});
    }
  };

  headers = {
    selected: {
      title: '',
      className: 'without-sort',
      component: CheckboxCell,
      componentArgs: {
        isChecked: this.isChecked,
        onChange: this.handleChangeCheck
      }
    },
    status: {
      title: 'Status',
      component: StatusCell
    },
    file_name: {
      title: 'File',
      className: 'file-name',
      component: NameCell
    },
    file_id: {
      title: <span>File&nbsp;ID</span>,
      className: 'file-id word-break-all'
    },
    file_size: {
      title: 'Size'
    }
  };

  sortAltFields = {
    file_size: 'file_size_bytes',
    updated_on: 'updated_on_sec'
  };

  handleHeaderCellClick = (field, direction) => {
    this.setState({tableSort: {field, direction}});
  };

  render() {
    const {data} = this.props;
    const {tableSort, checkedRow} = this.state;
    const isInfoPanelCollapsed = !checkedRow;
    return (
      <StyledTable className="table-wrapper">
        <div className="table-container">
          <div className={`table-block${isInfoPanelCollapsed ? ' info-panel-collapsed' : ''}`}>
            <div className="col-table">
              <Table
                headers={this.headers}
                rows={getSortedRowsBy(data, tableSort.field, tableSort.direction, this.sortAltFields)}
                sort={tableSort}
                onHeaderCellClick={this.handleHeaderCellClick}
                emptyText="No Data"
              />
            </div>
            <div className="col-info-panel">
              {
                isInfoPanelCollapsed ? null :
                  <InfoPanel
                    items={[checkedRow]}
                    isUploadedFile
                  />
              }
            </div>
          </div>
        </div>
      </StyledTable>
    );
  }
}

const StyledTable = styled.div`
  .table-container {
    width: 100%;
  }

  .table-block {
    max-width: 100%;
    max-height: calc(100vh - 200px);
    min-height: 300px;
    overflow-y: auto;
    margin-bottom: 20px;

    display: flex;
    position: relative;

    .col-table {
      transition: width 0.3s ease-in-out 0s, padding 0s ease-in-out 0.3s;
      padding-right: 15px;
      width: 75%;
      max-height: 100%;
      overflow-y: auto;
    }

    .col-info-panel {
      transition: width 0.3s ease-in-out 0s, padding 0s ease-in-out 0.3s, border 0s ease-in-out 0.3s;
      padding-left: 15px;
      padding-right: 15px;
      width: 25%;
      max-height: 100%;
      overflow-y: auto;
      border-left: 3px solid #ddd;
    }

    &.info-panel-collapsed {
      .col-table {
        padding: 0;
        width: 100%;
      }
      .col-info-panel {
        padding: 0;
        width: 0;
        border: 0;
      }
    }


    .table {
      border-collapse: separate;
      color: #282828;
      margin-bottom: 0;

      th {
        position: sticky;
        top: 0;
        background-color: #fff;
        &.without-sort {
          pointer-events: none;
        }
        &:not(.without-sort) {
          cursor: pointer;
        }
        &:not(:first-child) {
          min-width: 90px;
        }
      }

      td {
        &.word-break-all {
          word-break: break-all;
        }
        &.data-table-empty {
          text-align: center;
          width: 100% !important;
        }
      }
    }
  }
`;

TableWrap.propTypes = {
  data: PropTypes.array.isRequired
}

export default TableWrap;
