import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {
  doSearch,
  getFilters,
  changeFilter,
  clearAll,
  WO_CHOOSER__SET_FS_ROOT_ID
} from "./actions";
import Filters from './Filters';
import {Row, Col, Grid} from "react-bootstrap";
import Table from "./Table";
import HeaderRow from "./HeaderRow";

class WOChooser extends React.Component {
  constructor(props) {
    super(props);
    const {dispatch, fsRootID} = props;
    dispatch(getFilters({})).then(() => {
      dispatch(doSearch());
    });
    this.state = {
      isUpdatedHeight: false,
      needsBackToAutoHeight: false,
    };
    dispatch({type: WO_CHOOSER__SET_FS_ROOT_ID, fsRootID});
  }

  componentDidUpdate(prevProps) {
    const {requests, startHeightProps} = this.props;
    if (startHeightProps && !requests.loaders.requests && prevProps.requests.loaders.requests && !this.state.isUpdatedHeight) {
      this.setState({isUpdatedHeight: true});
      try {
        const elm = document.getElementsByClassName(startHeightProps.selector)[0];
        if (elm.style.height === 'auto') {
          this.setState({needsBackToAutoHeight: true});
        }
        elm.style.height = startHeightProps.value;
      } catch {}
    }
  }

  componentWillUnmount() {
    const {dispatch, startHeightProps} = this.props;
    const {needsBackToAutoHeight} = this.state;
    if (startHeightProps && needsBackToAutoHeight) {
      try {
        const elm = document.getElementsByClassName(startHeightProps.selector)[0];
        elm.style.height = 'auto';
      } catch {}
    }
    dispatch(clearAll());
  }

  handleChangeFilters = (filterName, value) => {
    this.updateFilter(filterName, value);
  };

  handleChangeSearchFilter = (search, callback) => {
    this.updateFilter('search', search, callback);
  };

  handleLoadMore = () => {
    const {dispatch, requests} = this.props;
    const {more} = requests.loaders;
    if (!more) {
      this.setLoadMore(true);
      setTimeout(() => {
        dispatch(doSearch( true)).then(
          () => this.setLoadMore(false),
          err => this.setLoadMore(false)
        );
      }, 100);
    }
  };

  setLoadMore = isLoadMore => {
    const tableContainer = document.getElementsByClassName('std-requests');
    if (tableContainer && tableContainer.length > 0) {
      if (isLoadMore) {
        tableContainer[0].className += ' load-more';
      } else {
        tableContainer[0].className = tableContainer[0].className.replace(/load-more/g, '').trim();
      }
    }
  };

  updateFilter = (filterName, filterValue, callback) => {
    const {dispatch, history} = this.props;
    document.getElementsByTagName('tbody')[0].scrollTop = 0;
    dispatch(changeFilter(history, filterName, filterValue)).then(callback);
  };

  render() {
    const {requests, onChoose} = this.props;
    const {filters, expectResults} = requests;
    const columns = filters.find((filter) => filter.name === 'columns');
    const searchTitle = ((filters.find((i) => i.name === 'search')) || {}).value;

    return (
      <StyledAppLayout title="Work Orders">
        <HeaderRow
          total={requests.data.length}
          searchTitle={searchTitle}
          onSearch={this.handleChangeSearchFilter}
        />
        <Grid>
          <Row className="requests-content">
            <Col xs={12} md={2}>
              <StyledWrapFilter className='custom-scrollbar'>
                <Filters
                  loading={requests.loaders.filters}
                  onChangeFilters={this.handleChangeFilters}
                  filters={filters}
                />
              </StyledWrapFilter>
            </Col>
            <Col xs={12} md={10}>
              <Table
                columns={columns.value}
                data={requests.data}
                expectResults={expectResults}
                loading={requests.loaders.requests || requests.loaders.more}
                onLoadMore={this.handleLoadMore}
                onSearch={this.handleChangeSearchFilter}
                onSubmitRow={onChoose}
                searchTitle={requests.filters.search}
                tableSort={ {} }
              />
            </Col>
          </Row>
        </Grid>
      </StyledAppLayout>
    );
  }
}

const StyledWrapFilter = styled.div`
  height:100%;
  overflow-y:auto;
  @media (max-width: 991px) {
    height: auto;
  }
`;

const StyledAppLayout = styled.div`
  > div:first-of-type {
    color: #282828;
    padding: 0;
    position: relative;

    @media (max-width: 991px) {
      background: linear-gradient(transparent, transparent), linear-gradient(#f1f1f1, #f1f1f1);
      background-position: 0 0, 0 55px;
      background-size: auto 55px, auto auto;
      background-repeat: no-repeat;
      margin-top: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .requests-content {
    background-color: #fff;
    > div {
      height:80%;
    }
    @media (max-width: 991px) {
      background-color: transparent;
      > div:first-child {
        padding: 0 !important;
        background-color: #fff;
      }
      > div:last-child {
        margin-top: 10px;
        background-color: #fff;
      }
    }

    @media (max-width: 767px) {
      background-color: transparent;
      > div:last-child {
        padding: 0 !important;
      }
    }
  }
`;

const WOChooserConnected = connect(
  function mapState(state, dispatch) {
    return {
      requests: state.WOChooserComponent,
      user: state.user,
      dispatch,
    }
  },
)(WOChooser);

WOChooserConnected.propTypes = {
  onChoose: PropTypes.func.isRequired,
  fsRootID: PropTypes.any
};

export default WOChooserConnected;
