import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Collapse, Col, Button} from 'react-bootstrap';
import {
  FiltersStyled, FilterHeaderStyled, FilterBodyStyled, FilterBottomStyled
} from './FiltersStyled';
import Filter from './Filter';

class Filters extends Component {
  state = {
    open: true
  };

  toggleFiltersOpen = () => {
    this.setState({
      open: !this.state.open
    });
  };

  resetFilters = () => {
    const {resetFilters} = this.props;
    resetFilters();
  };

  applyFilters = () => {
    const {applyFilters} = this.props;
    applyFilters();
  };

  render() {
    const {open} = this.state;
    const {
      filters, onChange, usedFilters, title, applyFilters, resetFilters, applyLabel, bodyMd, headerVisible, filterTop,
      isShowApplyBtnInHeaderWhenCollapsed
    } = this.props;
    const showApplyBtnInHeader = !!isShowApplyBtnInHeaderWhenCollapsed && !open;
    const applyBtn = applyFilters ?
      <Button
        bsStyle={showApplyBtnInHeader ? 'default' : 'primary'}
        className={showApplyBtnInHeader ? 'is-visible' : undefined}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          this.applyFilters();
        }}
      >
        {applyLabel || 'Apply'}
      </Button> : null;
    return (
      <FiltersStyled>
        {
          headerVisible === false ? null : (
            <FilterHeaderStyled
              onClick={this.toggleFiltersOpen}
              aria-controls="filters"
              aria-expanded={open}
              >
              <div className="container">
                <span className="title pull-left">{title || 'Search'}</span>
                {!!isShowApplyBtnInHeaderWhenCollapsed && applyBtn}
                <i className="pull-right fa fa-angle-down"/>
              </div>
            </FilterHeaderStyled>
          )
        }
        <Collapse in={open}>
          <div id="filters" className="clearfix container">
            <FilterBodyStyled>
              {!!filterTop &&
                <Col md={bodyMd || 6} xs={12}>
                  <div className="block block-top">
                    <div className="filters-block">
                      {filterTop}
                    </div>
                  </div>
                </Col>
              }
              <Col md={bodyMd || 6} xs={12}>
                <div className="block">
                  {filters && filters.length ? (
                    <Fragment>
                      <div className="filters-block">
                        {filters.map((filter, index) => {
                          let value = usedFilters[filter.name];
                          if (!value && value !== 0 && value !== false) {
                            value = '';
                          }
                          return (
                            <Filter
                              key={index}
                              filter={filter}
                              value={value}
                              onChange={onChange}
                            />
                          )
                        })}
                      </div>
                    </Fragment>
                  ) : null}
                </div>
              </Col>
            </FilterBodyStyled>
            {
              (applyFilters || resetFilters) ? (
                <FilterBottomStyled>
                  <div className="filters-btn-toolbar">
                    {!showApplyBtnInHeader && applyBtn}
                    {resetFilters ?
                      <Button bsStyle={applyFilters ? 'default' : 'primary'} onClick={this.resetFilters}>Reset</Button> :
                      null
                    }
                  </div>
                </FilterBottomStyled>
              ) : null
            }
          </div>
        </Collapse>
      </FiltersStyled>
    );
  }
}

Filters.propTypes = {
  loading: PropTypes.bool.isRequired,
  filters: PropTypes.array.isRequired,
  usedFilters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  applyFilters: PropTypes.func,
  resetFilters: PropTypes.func,
  applyLabel: PropTypes.string,
  title: PropTypes.string,
  bodyMd: PropTypes.number,
  headerVisible: PropTypes.bool,
  filterTop: PropTypes.any,
  isShowApplyBtnInHeaderWhenCollapsed: PropTypes.bool
};

export default Filters;
