import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
    applyChangeDirectory,
    changeDirectoryInput,
    prepareFile,
} from "../pages/StorageManager/actions";
import {isMacOsPathView, convertPathToWindows} from "../pages/StorageManager/utils";
import NavigationInput from "../pages/StorageManager/components/forms/NavigationInput";
import {FS_ACTION_TYPE__MIGRATE_PORTAL} from "../pages/StorageManager/constants";
import styled from "styled-components";

class ChangeDirectoryInput extends Component {
    resetValuesOnBlur = false;

    state = {
        value: this.props.value
    };
    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.value) !== JSON.stringify(prevProps.value)) {
            this.setState({
                value: this.props.value
            });
        }
    }

    handleChangeDirectory = value => {
        if(this.state.value !== value){
            const {dispatch, storageManager: {appliedNavigationString, fsRoots}} = this.props;
            const isMacOsPath = isMacOsPathView();
            value = (isMacOsPath ? '/' : '') + (value || '').split(/[\\/]/).filter(Boolean).join(isMacOsPath ? '/' : '\\');
            if (value.length <= 1) {
                dispatch(changeDirectoryInput(appliedNavigationString));
            } else {
                value = convertPathToWindows(value, fsRoots);
                let valueArray = value.split('\\');
                let openPath = "";
                let openPaths = [];
                const allParents = [];
                const parents = [];

                for(let i=0; i<valueArray.length; i++){
                    openPath+=i===0? valueArray[i]: `\\${valueArray[i]}`;
                    openPaths.push(openPath);
                }
                this.openNextFolder(0, openPaths, allParents, parents, value);
            }
        }
    };
    openNextFolder = (n, openPaths, allParents, parents, value) => {
        const {dispatch, storageManager: {fsRoots}} = this.props;

        dispatch(applyChangeDirectory(openPaths[n], value)).then((res) => {
            const preparedContent = prepareFile(JSON.parse(JSON.stringify(res.data.content)), fsRoots);
            if (!preparedContent.contentID) {
              return;
            }
            const {contentID, predictiveStateID} = preparedContent;
            allParents.push({contentID, predictiveStateID});
            parents.push(contentID);
            preparedContent.allParents = [];
            preparedContent.parents = [];
            allParents.forEach((item)=>{
                if(item.contentID !== preparedContent.contentID){
                    preparedContent.allParents.push(item);
                    preparedContent.parents.push(item.contentID);
                }
            })
            this.handleGoToSelectDestStepFromFirstStep(preparedContent, value);
            if(n<openPaths.length-1){
                n++;
                this.openNextFolder(n, openPaths, allParents, parents, value);
            }
        })
    }
    handleBackToSelectDestStep = () => {
        this.handleClickSelectDestination('selected');
    };
    handleClickSelectDestination = () => {
        this.props.scrollToItem('selected');
    };
    handleClickNavigationInput = (event) => {
        event.target.select();
    };
    /*handleChangeNavigationInput = (changedValue) => {
        this.changedValue = changedValue;
    };*/

    handleGoToSelectDestStepFromFirstStep = (selectedFolder, value) => {
        this.props.renderRows(this.props.storageManager.displayedItemsInPopup);
        let displayedFolders = document.getElementsByClassName('fs-item-name');
        let selectedFolders = selectedFolder.key.split('\\');
        for(let i=0; i<selectedFolders.length; i++){
            for(let j=0; j<displayedFolders.length; j++){
                if(displayedFolders[j].innerHTML === selectedFolders[i]){
                         this.props.onSelectFolder(selectedFolder);
                         this.props.scrollToItem('selected');
                    if(value === selectedFolder.key){
                         return;
                    } else{
                        let child = displayedFolders[j].parentElement.children[0];
                        if(child.title === 'Expand'){
                            displayedFolders[j].scrollIntoView();
                            this.props.getFolderToExpand(selectedFolder);
                            this.props.handleClickOpenFolder(selectedFolder);
                            return;
                        }
                    }
                }
            }
        }
        if (selectedFolder) {
            return this.handleBackToSelectDestStep();
        }
        this.handleClickSelectDestination(this.isMigrate() ? 'selection-would-be-invalid' : 'current');
    };

    isMigrate = () => {
        const {allowedActions} = this.props;
        return allowedActions.includes(FS_ACTION_TYPE__MIGRATE_PORTAL) && (allowedActions.length === 1 || this.isFolderToMigrate());
    };
    render() {
        const {storageManager} = this.props;
        const {inputNavigationString, inputNavigationStringForMacOS, appliedNavigationString, appliedNavigationStringForMacOS
        } = storageManager;
        const isMacOsPath = isMacOsPathView();
        const appliedNavString = isMacOsPath && appliedNavigationStringForMacOS ? appliedNavigationStringForMacOS : appliedNavigationString;
        const inputNavString = isMacOsPath && inputNavigationStringForMacOS ? inputNavigationStringForMacOS : inputNavigationString;

        const appliedNavStringValue = this.props.value && this.props.value.trim()? this.props.value: appliedNavString;
        const inputNavStringValue = this.props.value && this.props.value.trim()? this.props.value: inputNavString;
        return (
            <StyledInputBlock>
            <NavigationInput
                id="navigation-input--change-dir"
                value={inputNavStringValue}
                appliedValue={appliedNavStringValue}
                onChange={/*this.handleChangeNavigationInput*/() => {}}
                onEnter={this.handleChangeDirectory}
                onClick={this.handleClickNavigationInput}
            />
                <button
                    id="handleChangeBtn"
                    className="checkBtn"
                    title="Enter"
                    onClick={() => this.handleChangeDirectory(/*this.changedValue*/document.getElementById("navigation-input--change-dir").value)}
                >
                    <i className="far fa-check-circle nf-btn"/>
                </button>
            </StyledInputBlock>


        );
    }
}

const StyledInputBlock = styled.div`
  display: flex;
  .checkBtn{
    margin: 10px 0 0 10px;
    padding: 0;
    border: none;
    border-radius: 50%;
    background: none;
    width: 14px;
    height: 14px;
    line-height: 14px;
    font-size: 14px;
    text-align: center;
    :hover {
      background-color: #e1e1e1;
      box-shadow: 0 0 0 5px #e1e1e1;
    }
    + button {
      margin-right: 10px;
    }
    i {
      margin: 0;
      &.fa-check-circle {
        border-radius: 50%;
        background-color: #fff;
        color: green;
      }
    }
  }
`;

ChangeDirectoryInput.propTypes = {
    value: PropTypes.string.isRequired,
    storageManager: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    scrollToItem:  PropTypes.func.isRequired,
    renderRows:  PropTypes.func.isRequired,
    onSelectFolder:  PropTypes.func.isRequired,
    handleClickOpenFolder:  PropTypes.func.isRequired,
    getFolderToExpand:  PropTypes.func.isRequired,
};

export default ChangeDirectoryInput;