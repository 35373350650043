import React, { Component } from "react";
import CommentsCellStyled from "./styled/CommentsCell.styled";

export default class CommentsCell extends Component {
  render() {
    const { value, className } = this.props;
    return (
      <CommentsCellStyled className={className}>
        <div>
          <span>{value}</span>
        </div>
      </CommentsCellStyled>
    );
  }
}
