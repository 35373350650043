import React, {useState} from 'react';
import styled from 'styled-components';
import {FilterStyled} from '../../../components/filters/Filter';
import {FilterSelectStyled} from '../../../components/filters/FiltersStyled';
import Select from 'react-select';
import {FormControl, Row, Col, ButtonToolbar, DropdownButton, MenuItem} from 'react-bootstrap';
import {selectPreset, savePreset, setPresetAsDefault, deletePresets, setAdvancedModeForPreset} from '../actions';
import SavePresetAsModal from './modal/SavePresetAsModal';
import DeletePresetsModal from './modal/DeletePresetsModal';

const FiltersPresets = props => {
  const {dispatch, history, loading, presets, currentPreset, disabledMenu, isSaved, isAdvancedMode} = props;

  const [showSaveAsModal, setShowSaveAsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const closeMenu = () => {
    try {
      const btn = document.getElementById('menu-presets');
      if (btn.getAttribute('aria-expanded') === 'true') {
        btn.click();
      }
    } catch {}
  };

  const handleSelect = preset => {
    if (!preset || preset.value === currentPreset.value) {
      return;
    }
    dispatch(selectPreset(history, preset));
  };

  const handleDelete = presetIDs => {
    dispatch(deletePresets(presetIDs));
  };

  const handleSave = (presetName, isDefault) => {
    dispatch(savePreset(history, presetName, isDefault));
  };

  const handleSetAsDefault = () => {
    dispatch(setPresetAsDefault());
    closeMenu();
  };

  const handleToggleAdvancedMode = (newIsAdvancedMode) => {
    dispatch(setAdvancedModeForPreset(history, newIsAdvancedMode));
    closeMenu();
  };

  return (
    <FilterStyled>
      {showSaveAsModal &&
        <SavePresetAsModal
          presetName={currentPreset.label}
          presets={presets}
          onClose={() => setShowSaveAsModal(false)}
          onSave={handleSave}
        />
      }
      {showDeleteModal &&
        <DeletePresetsModal
          presets={presets.filter(p => p.value !== currentPreset.value)}
          onClose={() => setShowDeleteModal(false)}
          onDelete={handleDelete}
        />
      }
      <Row>
        <Col md={4} xs={12}>
          <FormControl
            value="Preset"
            disabled={true}
            className="filter-field-label presets-field-label"
          />
        </Col>
        <Col md={8} xs={12}>
          <FilterSelectStyled className={`filter-select-with-button${(!currentPreset || isSaved) ? '' : ' unsaved-right'}`}>
            <Select
              value={(currentPreset || {}).value || null}
              options={presets}
              optionRenderer={({label, is_default, is_advanced}) => {
                const markAsAdvanced = is_advanced && isAdvancedMode;
                return (
                  <PresetOption
                    title={markAsAdvanced ? 'Advanced Mode' : is_default ? 'This is the default preset' : undefined}
                  >
                    {label}
                    <span>
                      {is_default ? <i className="default-preset" title="This is the default preset">Default</i> : null}
                      {markAsAdvanced ? <i className="advanced-mode">A</i> : null}
                    </span>
                  </PresetOption>
                );
              }}
              onChange={handleSelect}
              placeholder={loading ? '' : 'None'}
              isLoading={loading}
            />
            <StyledButtonToolbar>
              <StyledDropdownButton
                bsStyle="default"
                title={<i className="fas fa-ellipsis-v" title="More"/>}
                noCaret
                pullRight
                disabled={disabledMenu}
                id="menu-presets"
              >
                <StyledMenuItem
                  eventKey="save"
                  onClick={() => handleSave()}
                  disabled={isSaved}
                >
                  Save
                </StyledMenuItem>
                {!!currentPreset &&
                  <>
                    <StyledMenuItem
                      eventKey="save-as"
                      onClick={() => setShowSaveAsModal(true)}
                    >
                      Save as...
                    </StyledMenuItem>
                    {!currentPreset.is_default &&
                      <StyledMenuItem
                        eventKey="set-as-default"
                        onClick={() => handleSetAsDefault()}
                      >
                        Set as default preset
                      </StyledMenuItem>
                    }
                    {isAdvancedMode &&
                      <StyledMenuItem
                        eventKey="toggle-advanced-mode"
                        onClick={() => handleToggleAdvancedMode(!currentPreset.is_advanced)}
                      >
                        {currentPreset.is_advanced ? 'Reset' : 'Set'} advanced mode
                      </StyledMenuItem>
                    }
                    {presets.length > 1 &&
                      <StyledMenuItem
                        eventKey="delete"
                        onClick={() => setShowDeleteModal(true)}
                      >
                        Delete others...
                      </StyledMenuItem>
                    }
                  </>
                }
              </StyledDropdownButton>
            </StyledButtonToolbar>
          </FilterSelectStyled>
        </Col>
      </Row>
    </FilterStyled>
  );
};

const PresetOption = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  i {
    margin-left: 7px !important;
    opacity: 0.8 !important;
    font-weight: normal !important;
    font-style: normal !important;
    :before {
      content: '[';
    }
    :after {
      content: ']';
    }
  }
`;

const StyledButtonToolbar = styled(ButtonToolbar)`
  > .btn, > .btn-group {
    margin-left: 6px;
    height: 100%;
    float: none;
  }
  > .btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledDropdownButton = styled(DropdownButton)`
  margin: 0;
  padding: 0;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 1;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  &[disabled] {
    background: transparent !important;
  }
  &:not([disabled]) {
    opacity: 0.75;
    :not(:hover) {
      background: transparent;
    }
    :hover, :focus, :active {
      opacity: 1;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 10px;
`;

export default FiltersPresets;