import React from 'react';
import Draggable from 'react-draggable';
import styled, {css} from 'styled-components';

const InstructionsPopup = props => {
  const {header, text, onHide, general, index = 0} = props;
  return (
    <Draggable handle=".popup-draggable-handle" defaultPosition={{x: 10 * (index + 1), y: 10 * (index + 1)}}>
      <Popup
        general={!!general}
        style={{
          maxWidth: `calc(90vw - (${index + 1} * 10px))`,
          width: `calc(40vw - (${index + 1} * 10px))`
        }}
      >
        <Header className="popup-draggable-handle" general={!!general}>
          <strong>
            {header}
          </strong>
          <i
            className="fas fa-times"
            title="Close"
            onClick={onHide}
          />
        </Header>
        <Instruction className="popup-draggable-handle">
          {text}
        </Instruction>
      </Popup>
    </Draggable>
  );
};

const Popup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 10px;
  background-color: #F3C19D;
  border: 1px solid #E8833A;
  border-radius: 6px;
  box-shadow: 0 5px 10px #E8833A33;
  ${props => props.general && css`
    background-color: #FBE192;
    border-color: #F7C325;
    box-shadow: 0 5px 10px #F7C32533;
  `}
  resize: both;
  overflow: hidden;
  max-width: 90vw;
  min-width: min-content;
  min-height: 87px;
  height: fit-content;
  max-height: calc(100vh - 100px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
`;

const Header = styled.div`
  padding: 9px 14px 8px;
  background-color: #f1b387;
  border-bottom: 1px solid #E8833A;
  ${props => props.general && css`
    background-color: #ffdf7e;
    border-color: #F7C325;
  `}
  display: flex;
  align-items: center;
  justify-content: space-between;
  strong {
    line-height: 20px;
    white-space: nowrap;
  }
  i {
    margin-left: 15px;
    color: #757575;
    cursor: pointer;
    &:hover {
      color: #282828;
    }
  }
`;

const Instruction = styled.div`
  padding: 5px 14px 0;
  max-height: calc(100% - 45px);
  min-height: 32px;
  overflow-y: auto;
  white-space: pre-line;
  word-break: break-word;
`;

export default InstructionsPopup;