/* eslint jsx-a11y/anchor-is-valid: "off" */
import React from 'react';
import PropTypes from 'prop-types';
import {isNetflixClient} from '../../../../../utils';

export const ClientCell = ({link}) => {
  const {requestID, client_name, is_nam} = link;
  return (
    <td>
      {
        isNetflixClient(client_name) && requestID && requestID.indexOf('WO') !== 0 ?
          <a
            title="Go to request"
            href={'https://assetqc.netflix.com/qc?preventRedirect=true&request_id=' + requestID}
            target="_blank"
            rel="noopener noreferrer"
          >
            {client_name}
          </a> : client_name
      }
      {!!is_nam && ' (NAM)'}
    </td>
  );
};

ClientCell.propTypes = {
  link: PropTypes.object.isRequired
};
