import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  ACTION_URL_FILTER_RUNNER_ID,
  ACTION_URL_FILTER_TYPE_ID,
  ACTION_URL_FILTER_DAYS_BACK,
} from './constants';
import qs from 'qs';

export const RunnerCell = ({value, row, className, runners}) => {
  if (typeof value ==='number' && value > 0) {
    const {MachineName, IPAddress} = (runners || {})[value] || {};
    const urlParams = qs.stringify({
      [ACTION_URL_FILTER_RUNNER_ID]: value,
      [ACTION_URL_FILTER_TYPE_ID]: row.actionTypeID,
      [ACTION_URL_FILTER_DAYS_BACK]: 1,
    });
    return (
      <StyledCell className={className}>
        <div>
          <a
            className="btn-external-link"
            title="Open in new tab"
            href={`/storage?${urlParams}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-regular fa-arrow-up-right-from-square"/>
          </a>
          <span>
            {`${MachineName || ''} (${value}${IPAddress ? `, ${IPAddress}` : ''})`}
          </span>
        </div>
      </StyledCell>
    );
  }
  return <StyledCell className={className}/>;
};

const StyledCell = styled.td`
  > div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    column-gap: 7px;
  }
  .btn-external-link {
    color: #337ab7;
    text-decoration: none !important;
    cursor: pointer;
    &:hover {
      color: #23527c;
    }
  }
`;

RunnerCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object,
  className: PropTypes.string,
  runners: PropTypes.object,
};
