import React from 'react';

export default class WarningIcon extends React.PureComponent<React.HTMLAttributes<HTMLSpanElement>> {
  render() {
    return (
      <span {...this.props}>
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.335 13.7855L9.73537 1.09023C9.22986 0.243924 8.40192 0.255841 7.89635 1.10213L0.296671 13.7798C-0.208831 14.6261 0.168928 15.2799 1.13623 15.2799H16.4947C17.4627 15.2799 17.8405 14.6303 17.335 13.7854L17.335 13.7855ZM8.80974 3.90009C9.29611 3.90009 9.6732 4.45098 9.64793 5.12438L9.49354 9.18165C9.46895 9.85435 9.15949 10.4052 8.80769 10.4052C8.4552 10.4052 8.14574 9.85435 8.12115 9.18165L7.96882 5.12438C7.94423 4.45098 8.32199 3.90009 8.80974 3.90009ZM8.80974 13.4613C8.19562 13.4613 7.77413 13.0014 7.7871 12.3728C7.7871 11.7302 8.20928 11.2843 8.80974 11.2843C9.43547 11.2843 9.83099 11.7309 9.84467 12.3728C9.84398 13.0014 9.43549 13.4613 8.80974 13.4613Z" fill="currentColor"/>
        </svg>
      </span>
    );
  }
}