import React, {Component} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import {Alert, OverlayTrigger, Popover, Row, Col, Button} from 'react-bootstrap';
import styled from 'styled-components';

class DetailsInfo extends Component {
  state = {
    showAlert: false
  };

  onCopyToClipboard = () => {
    $(this.refs.input).select();
    document.execCommand('copy');
    this.setState({showAlert: true});
    setTimeout(() => {
      this.setState({showAlert: false});
    }, 2000);
  };

  render() {
    const {showAlert} = this.state;
    const {data, onMarkCompleted} = this.props;
    const {msg_subject, msg_from, msg_to, msg_body, msg_link, message_id} = data;
    const markCompletedBtn = onMarkCompleted ? (
      <Button
        bsSize="xsmall"
        onClick={() => onMarkCompleted(data)}
      >
        Mark Completed
      </Button>
    ) : null;
    if (!message_id) {
      return <StyledDetails className="details">{markCompletedBtn}</StyledDetails>;
    }
    const linkPopover = (
      <Popover id={`popover-link-${message_id}`} className="message-details-popover link-popover">
        <div className="msg-link">{msg_link}</div>
        <input
          ref="input"
          type="text"
          value={msg_link}
          readOnly
          className="copy-link"
          onClick={() => this.onCopyToClipboard()}
          />
        <button onClick={() => this.onCopyToClipboard()}>Copy</button>
      </Popover>
    );
    const messagePopover = (
      <Popover id={`popover-message-${message_id}`} className="message-details-popover message-popover">
        <Row>
          <Col md={2} xs={2} className="field">
            From
          </Col>
          <Col md={10} xs={10}>
            {msg_from}
          </Col>
        </Row>
        <Row>
          <Col md={2} xs={2} className="field">
            To
          </Col>
          <Col md={10} xs={10}>
            {msg_to}
          </Col>
        </Row>
        <Row>
          <Col md={2} xs={2} className="field">
            Subject
          </Col>
          <Col md={10} xs={10}>
            {msg_subject}
          </Col>
        </Row>
        <Row>
          <Col md={2} xs={2} className="field">
            Body
          </Col>
          <Col md={10} xs={10}>
            <iframe srcDoc={msg_body} title={`body-message-${message_id}`} height="300"/>
          </Col>
        </Row>
      </Popover>
    );
    return (
      <StyledDetails className="details">
        {showAlert ? <Alert bsStyle="success">Copied to clipboard: <b>{msg_link}</b></Alert> : null}
        {
          msg_link ? (
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="left"
              overlay={linkPopover}
              >
              <span title="Click to view">Link</span>
            </OverlayTrigger>
          ) : <span className="no-link">No Link</span>
        }
        <br/>
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="left"
          overlay={messagePopover}
          >
          <span title="Click to view">Message</span>
        </OverlayTrigger>
        <br/>
        {markCompletedBtn}
      </StyledDetails>
    );
  }
}

const StyledDetails = styled.div`

  span {
    color: #337ab7;

    &.no-link {
      opacity: 0.5;
    }

    &:not(.no-link){
      cursor: pointer;

      &:hover {
        color: #23527c;
        text-decoration: underline;
      }
    }
  }

  .alert {
    position: fixed;
    top: 60px;
    left: 5px;
    width: calc(100% - 10px);
    margin: 0;
    z-index: 10000;

    animation: alert-animation 2s 1s ease-in;
    @keyframes alert-animation {
      from {
       opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }
`;

DetailsInfo.propType = {
  data: PropTypes.object.isRequired,
  onMarkCompleted: PropTypes.func,
};

export default DetailsInfo;