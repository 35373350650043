import React from "react";
import Switch from "react-switch";
import PropTypes from "prop-types";

function Toggle({ onChange, checked }) {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      onColor="#337ab7"
      handleDiameter={20}
      height={22}
      width={40}
      uncheckedIcon={false}
      checkedIcon={false}
    />
  );
}

Toggle.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Toggle;
