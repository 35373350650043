import React from 'react';
import PropTypes from "prop-types";

export const WoMemfisCell = (props) => {
  const {
    row,
    className,
  } = props;

  return (
    <td className={className}>
      {row.woMemfisList && row.woMemfisList.map(woMemfis => (
        <a
          href={`/storage?use_max_modified_on=true&last_segment_only=true&symlinks_too=true&wo_memfis=${woMemfis}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {woMemfis} {' '}
        </a>
      ))}
    </td>
  );
}

WoMemfisCell.propTypes = {
  className: PropTypes.string,
  row: PropTypes.object,
  value: PropTypes.any,
};

