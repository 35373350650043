import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, FormGroup, FormControl, ControlLabel, Checkbox} from 'react-bootstrap';
import {isValidFileName} from '../../../../utils';
import RndModal from '../../../../components/modals/RndModal';

class SavePresetAsModal extends Component {
  state = {
    inputValue: this.props.presetName + ' - Copy...',
    isDefault: false
  };

  isInvalidName = () => {
    const {presets} = this.props;
    const {inputValue} = this.state;
    return !isValidFileName(inputValue) || presets.some(p => p.label.toLowerCase() === inputValue.trim().toLowerCase());
  };

  handleChange = event => {
    this.setState({inputValue: event.target.value});
  };

  handleSave = () => {
    const {onSave} = this.props;
    const {inputValue, isDefault} = this.state;
    onSave(inputValue.trim(), isDefault);
    this.handleClose();
  };

  handleClose = () => {
    const {onClose} = this.props;
    onClose();
  };

  componentWillUnmount() {
    this.handleClose();
  }

  render() {
    const {inputValue, isDefault} = this.state;
    const isInvalid = this.isInvalidName();
    return (
      <RndModal
        show={true}
        onHide={this.handleClose}
        backdrop="static"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Save preset as...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <FormGroup validationState={isInvalid ? 'error' : null}>
              <ControlLabel>New Preset Name</ControlLabel>
              <FormControl
                type="text"
                placeholder="Enter preset name"
                value={inputValue}
                onChange={this.handleChange}
                onBlur={this.handleChange}
              />
            </FormGroup>
            <Checkbox
              checked={isDefault}
              onChange={({target: {checked}}) => this.setState({isDefault: checked})}
            >
              Set new preset as default
            </Checkbox>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.handleSave} disabled={isInvalid}>Save</Button>
          <Button bsStyle="default" onClick={this.handleClose}>Cancel</Button>
        </Modal.Footer>
      </RndModal>
    );
  }
}

SavePresetAsModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  presetName: PropTypes.string.isRequired,
  presets: PropTypes.array.isRequired
};

export default SavePresetAsModal;
