import React from 'react';
import PropTypes from 'prop-types';

export const RequestorCell = ({value, row, className, getRequestorName}) => (
  <td className={className}>
    {value || getRequestorName(row.actionRequestor)}
  </td>
);

RequestorCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object,
  className: PropTypes.string,
  getRequestorName: PropTypes.func
};
