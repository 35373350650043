import {
  natural_sort_comparer,
} from './utils.js'

const natural_sort = allFiles => {
  let folders = [];
  let files = [];

  for (let fileIndex = 0; fileIndex < allFiles.length; fileIndex++) {
    let file = allFiles[fileIndex];
    const re = /[\\/]/;
    let keyFolders = (file.newKey || file.key).split(re);
    if (file.children) {
      if (!file.name) {
        file.name = keyFolders[keyFolders.length - 2];
      }
      folders.push(file);
    }
    else {
      if (!file.name) {
        file.name = file.isRoot ? file.fsRootName : keyFolders[keyFolders.length - 1];
      }
      files.push(file);
    }
  }

  files = files.sort(natural_sort_comparer);

  for (let folderIndex = 0; folderIndex < folders.length; folderIndex++) {
    let folder = folders[folderIndex];
    folder.children = natural_sort(folder.children);
  }

  let sortedFiles = [];
  sortedFiles = sortedFiles.concat(folders);
  sortedFiles = sortedFiles.concat(files);
  return sortedFiles;
};

export default function(files) {
  return natural_sort(files);
}
