import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import PageComponent from '../../components/PageComponent';
import AppLayout from '../../layouts/AppLayout';
import {getAssets, approveDeletion, rejectDeletion, getFiles} from './actions';
import TableHeader from './components/table/TableHeader';
import TableFolder from './components/table/TableFolder';
import TableFile from './components/table/TableFile';
import styled from 'styled-components';
import FileBrowser from '../StorageManager/components/react-keyed-file-browser/browser';
import {confirmAlert} from 'react-confirm-alert';

class AssetDeletionPage extends PageComponent {
  state = {
    tableSort: {
      field: '',
      direction: ''
    },
    loadingPath: null,
    selected: [],
    isDeleted: false,
    isRejected: false
  };

  componentWillMount() {
    localStorage.setItem('last_view', 'storage');
    const {dispatch, match} = this.props;
    dispatch(getAssets(match.params.assetDeletionId));
  }

  handleClickCancelConfirmAlert = () => {
    this.setState({selected: []});
  };

  showConfirmAlert = isApprove => {
    confirmAlert({
      title: 'Are You Sure?',
      message: `Are you sure you want to ${isApprove ? 'delete these assets' : 'reject this request'}?`,
      buttons: [{
        label: 'Cancel',
        onClick: this.handleClickCancelConfirmAlert
      }, {
        label: isApprove ? 'Approve' : 'Reject',
        onClick: isApprove ? this.handleConfirmDelete : this.handleConfirmReject
      }],
      onClickOutside: this.handleClickCancelConfirmAlert
    });
  };

  handleClickApproveDeletion = selected => {
    this.setState({selected});
    this.showConfirmAlert(true);
  };

  handleClickRejectDeletion = () => {
    this.showConfirmAlert(false);
  };

  handleConfirmDelete = () => {
    const {dispatch, match} = this.props;
    const {selected} = this.state;
    dispatch(approveDeletion(match.params.assetDeletionId, selected)).then(() => this.setState({isDeleted: true}));
  };

  handleConfirmReject = () => {
    const {dispatch, match} = this.props;
    dispatch(rejectDeletion(match.params.assetDeletionId)).then(() => this.setState({isRejected: true}));
  };

  handleGetFiles = (rootKey, root, path, contentId, incremental) => {
    const {dispatch} = this.props;
    this.setState({loadingPath: path || root});
    dispatch(getFiles(rootKey, root, path, contentId, incremental)).always(() => {
      this.setState({loadingPath: null});
    });
  };

  handleHeaderCellClick = tableSort => {
    this.setState({tableSort});
  };

  renderTable() {
    const {loadingPath, tableSort} = this.state;
    const {contentChildren, files} = this.props.assetDeletion;
    const {loading, assets} = this.props.assetDeletion;
    const data = assets.length ? assets.map(item => {
      const rootFiles = files[item.key];
      if (!rootFiles) {
        item.browseName = item.key;
      } else {
        rootFiles.forEach(f => {
          f.browseName = item.key;
        });
      }
      return (rootFiles ? {
        root: item,
        files: rootFiles,
        onFolderClick: (path, contentId) => this.handleGetFiles(item.key, item.fsRootName, path, contentId, true)
      } : {
        rootName: item.key,
        files: [item],
        onFolderClick: (_, contentId) => this.handleGetFiles(item.key, item.fsRootName, item.key, contentId)
      });
    }) : [{files: []}];
    const onlyBrowse = assets.some(i => i.memfisWoID);
    return loading ? null : (
      <FileBrowser
        total={data.length}
        files={data}
        contentChildren={contentChildren}
        tableSort={tableSort}
        onHeaderCellClick={this.handleHeaderCellClick}
        onDeleteAssets={this.handleClickApproveDeletion}
        loadingPath={loadingPath}
        rootPath=""
        headerRenderer={TableHeader}
        folderRenderer={TableFolder}
        fileRenderer={TableFile}
        className={`asset-deletion-table${onlyBrowse ? ' only-browse' : ''}`}
        deleteBtnLabel="Approve Deletion"
        otherBtn={
          <Button
            disabled={!assets.length}
            onClick={this.handleClickRejectDeletion}
          >
            Reject Deletion
          </Button>
        }
        checkedAssets={assets.map(item => `${item.fsRootID}--${item.contentID}`)}
       />
    );
  }

  render() {
    const {isDeleted, isRejected} = this.state;
    return (
      <AppLayout title="Asset Deletion - Confirmation Page">
        <StyledContainer
          className={`container${isDeleted ? ' deleted' : isRejected ? ' rejected' : ''}`}
          title={isDeleted ? 'Asset Deletion Approved' : isRejected ? 'Asset Deletion Rejected' : undefined}
          msgColor={isDeleted ? 'green' : isRejected ? 'red' : undefined}
        >
          {this.renderTable()}
        </StyledContainer>
      </AppLayout>
    );
  }
}

const StyledContainer = styled.div`
  &.deleted, &.rejected {
    &:before {
      content: attr(title);
      display: flex;
      align-items: flex-start;
      justify-content: center;
      color: ${props => props.msgColor};
      font-weight: bold;
      font-size: 23px;
      position: fixed;
      width: 100%;
      height: 100%;
    }
    .asset-deletion-table {
      pointer-events: none;
      opacity: 0.7;
      outline: none;
    }
  }

  .asset-deletion-table {
    .action-bar {
      justify-content: flex-start;
      .btn-primary {
        margin-right: 10px;
      }
    }
  }
`;

AssetDeletionPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  assetDeletion: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    assetDeletion: state.assetDeletion,
    user: state.user
  };
}

export default connect(
  mapStateToProps
)(withRouter(AssetDeletionPage));
