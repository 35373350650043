import React, {Component} from 'react';
import {CheckboxHeaderCell} from '../../../../components/table/cells/CheckboxHeaderCell';
import {HeaderRow} from '../../../../components/table/HeaderRow';

class TableHeader extends Component {
  render() {
    const {sort, onHeaderCellClick, isChecked, onChange} = this.props;
    const headers = {
      selected: {
        title: '',
        headCell: {
          component: CheckboxHeaderCell,
          componentArgs: {
            isChecked: isChecked,
            onChange: onChange
          }
        }
      },
      file: {
        title: 'Title',
        className: 'name'
      },
      memfis_wo_id: {
        title: 'WO#',
        className: 'memfis-wo-id'
      },
      bar_code: {
        title: 'Bar-code',
        className: 'bar-code'
      }
    };
    return (
      <HeaderRow headers={headers} sort={sort} onHeaderCellClick={onHeaderCellClick}/>
    );
  }
}

export default TableHeader;
