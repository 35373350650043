import React, { Component } from "react";
import { connect } from "react-redux";
import Filters from "../../../components/filters/Filters";
import DaysBackFrom from "../../DownloadManager/components/DaysBackFrom";
import { changeFilterValue, applyFilters, getBugReports } from "../actions";
import {isStatusResolved} from "../utils";
import ISSUE_FILTERS, { ISSUE_FILTERS_TYPES } from "../constants/filters";

class IssueBrowserFilters extends Component {
  constructor() {
    super();
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
  }

  handleChangeFilter = (name, value) => {
    const { dispatch } = this.props;
    if (name === ISSUE_FILTERS_TYPES.STATUS) {
      value = (value || []).map(o => typeof o === 'object' ? o.value : o);
      if (value.length > 1) {
        if (!value[0]) {
          value = value.filter(Boolean);
        } else if (!value[value.length - 1]) {
          value = [value[value.length - 1]];
        }
      }
      dispatch(changeFilterValue(name, value));
      if (value.some(o => o && isStatusResolved(o))) {
        dispatch(changeFilterValue(ISSUE_FILTERS_TYPES.HIDE_RESOLVED, false));
      }
    } else {
      dispatch(changeFilterValue(name, value));
    }
  };

  handleApplyFilters = () => {
    const { dispatch } = this.props;
    dispatch(applyFilters());
  };

  handleRefresh = () => {
    const { dispatch } = this.props;
    dispatch(getBugReports());
  };

  isAppliedUsedFilters = () => {
    const {appliedUsedFiltersJson, usedFilters} = this.props.issueBrowser;
    return appliedUsedFiltersJson === JSON.stringify(usedFilters);
  };

  prepareFilters = () => {
    const { usedFilters, additionalData: {all_statuses, priorities, categories} } = this.props.issueBrowser;
    const preparedFilters = [...ISSUE_FILTERS];

    const filterStatus = preparedFilters.find(
      (filter) => filter.name === ISSUE_FILTERS_TYPES.STATUS
    );
    if (filterStatus && all_statuses) {
      filterStatus.values = [filterStatus.values[0], ...all_statuses];
    }

    const filterPriority = preparedFilters.find(
      (filter) => filter.name === ISSUE_FILTERS_TYPES.PRIORITY
    );
    if (filterPriority && priorities) {
      filterPriority.values = [filterPriority.values[0], ...priorities];
    }

    const filterCategory = preparedFilters.find(
      (filter) => filter.name === ISSUE_FILTERS_TYPES.CATEGORY
    );
    if (filterCategory && categories) {
      filterCategory.values = [filterCategory.values[0], ...categories];
    }

    const filterDaysBackFrom = preparedFilters.find(
      (filter) => filter.name === ISSUE_FILTERS_TYPES.DAYS_BACK_FROM
    );

    if (filterDaysBackFrom) {
      const filterValue = usedFilters[ISSUE_FILTERS_TYPES.DAYS_BACK_FROM];
      const { daysBack, fromDate } = filterValue;
      filterDaysBackFrom.field = (
        <DaysBackFrom
          daysBack={daysBack}
          fromDate={fromDate}
          onChange={(name, value) => {
            return this.handleChangeFilter(ISSUE_FILTERS_TYPES.DAYS_BACK_FROM, {
              ...filterValue,
              [name]: value,
            });
          }}
        />
      );
    }

    return preparedFilters;
  };

  render() {
    const { issueBrowser } = this.props;
    const { usedFilters, loaders } = issueBrowser;
    const isShowRefreshBtn = this.isAppliedUsedFilters();
    return (
      <Filters
        loading={!!loaders.gettingAdditionalData}
        filters={this.prepareFilters()}
        usedFilters={usedFilters}
        onChange={this.handleChangeFilter}
        applyLabel={isShowRefreshBtn ? 'Refresh' : 'Apply'}
        applyFilters={isShowRefreshBtn ? this.handleRefresh : this.handleApplyFilters}
        isShowApplyBtnInHeaderWhenCollapsed
        title="Filters"
        bodyMd={12}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    issueBrowser: state.issueBrowser,
  };
}

export default connect(mapStateToProps)(IssueBrowserFilters);
