import React from 'react';
import classNames from 'classnames';
import BaseFile from '../../../StorageManager/components/react-keyed-file-browser/base-file.js';
import {CheckboxCell} from '../../../../components/table/cells/CheckboxCell';

class TableFile extends BaseFile {
  render() {
    const {memfisWoID, barCode, isChecked, onChange} = this.props;

    let icon;
    if (this.isImage()) {
      icon = (<i className="far fa-file-image" aria-hidden="true"></i>);
    }
    else if (this.isPdf()) {
      icon = (<i className="far fa-file-pdf" aria-hidden="true"></i>);
    }
    else {
      icon = (<i className="far fa-file" aria-hidden="true"></i>);
    }

    let name = (
        <a
          href={this.props.url || undefined}
          download="download"
          onClick={(event) => {
            event.preventDefault();
            this.handleFileClick();
          }}
        >
          {icon}
          {this.getName()}
        </a>
    );

    let draggable = (
      <div>
        {name}
      </div>
    );

    let row = (
      <tr
        className={classNames({'file': true,
          pending: (this.props.action),
          selected: (this.props.isSelected),
        })}
        onClick={this.handleItemClick}
        >
        <CheckboxCell row={this.props} isChecked={isChecked} onChange={onChange}/>
        <td className="name">
          <div style={{paddingLeft: (this.props.depth * 16) + 'px'}}>
            {draggable}
          </div>
        </td>
        <td className="memfis-wo-id">{memfisWoID}</td>
        <td className="bar-code">{barCode}</td>
      </tr>
    );

    return this.connectDND(row);
  }
}

export default TableFile;
