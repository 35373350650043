import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FileNameCellStyled from "./styled/FileNameCell.styled";
import TextEllipsisAdvanced from '../../../../../components/TextEllipsis/TextEllipsisAdvanced';

class FileNameCell extends Component {
  render() {
    const { value, className } = this.props;

    return (
      <FileNameCellStyled className={className}>
        {value ? (
          <Link
            to={`/storage?nav=${encodeURIComponent(value)}`}
            target="_blank"
          >
            <TextEllipsisAdvanced
              data={value}
              nonMonospaceFont
            />
          </Link>
        ) : null}
      </FileNameCellStyled>
    );
  }
}

export default connect()(FileNameCell);
