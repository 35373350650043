import {USER_SET_IS_LOGOUT} from '../../pages/Login/actions';
import {
  DELIVERY_MNG_CLEAR, DELIVERY_MNG_IS_LOADING, DELIVERY_MNG_FILES_CHANGE, DELIVERY_MNG_FILES_RECEIVE,
  DELIVERY_MNG_SELECTING_START, DELIVERY_MNG_RENAMING_START, DELIVERY_MNG_RENAMING_END, DELIVERY_MNG_APPLY_SAVED_DATA,
  DELIVERY_MNG_FILE_EXT_SELECT, DELIVERY_MNG_IS_LIST_VIEW, DELIVERY_MNG_SET_SEARCH_STR, DELIVERY_MNG_SELECT_WO_TAB,
  DELIVERY_MNG_OPEN_INSTRUCTIONS
} from './actions';
import {DELIVERY_STEP, FILE_EXTENSION__ALL_OPTION, FILE_EXTENSION__ALL, TAB_WO__ALL} from './constants';
import {produce} from "immer";

const initialState = {
  categoryDataForTreeView: null,
  fileExtensionsList: [FILE_EXTENSION__ALL_OPTION],
  isListView: false,
  loading: false,
  macrosFields: {},
  openedInstructions: [],
  reviewDeliveryData: null,
  savedDataJson: null,
  searchString: '',
  selectedFileExtensions: [FILE_EXTENSION__ALL_OPTION],
  step: null,
  workOrders: {},
  woTab: TAB_WO__ALL,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SET_IS_LOGOUT:
    case DELIVERY_MNG_CLEAR: {
      return {...initialState};
    }

    case DELIVERY_MNG_IS_LOADING: {
      return {...state, loading: action.loading};
    }

    case DELIVERY_MNG_FILES_CHANGE: {
      return {
        ...state,
        reviewDeliveryData: action.reviewDeliveryData,
        categoryDataForTreeView: action.categoryDataForTreeView || state.categoryDataForTreeView
      };
    }

    case DELIVERY_MNG_FILES_RECEIVE: {
      return {
        ...state,
        loading: false,
        categoryDataForTreeView: action.categoryDataForTreeView || [],
        reviewDeliveryData: action.reviewDeliveryData || {},
        savedDataJson: JSON.stringify(action.reviewDeliveryData || {}),
        step: action.currentStep || state.step,
        macrosFields: action.macrosFields || state.macrosFields,
        workOrders: action.workOrders || state.workOrders,
        fileExtensionsList: action.fileExtensions || state.fileExtensionsList,
        selectedFileExtensions: action.selectedFileExtensions || state.selectedFileExtensions
      };
    }

    case DELIVERY_MNG_SELECTING_START: {
      return {
        ...state,
        step: DELIVERY_STEP.SELECTING_FILES
      };
    }

    case DELIVERY_MNG_RENAMING_START: {
      return {
        ...state,
        step: DELIVERY_STEP.RENAMING_FILES,
        openedInstructions: initialState.openedInstructions
      };
    }

    case DELIVERY_MNG_RENAMING_END: {
      return {
        ...state,
        step: initialState.step
      };
    }

    case DELIVERY_MNG_APPLY_SAVED_DATA: {
      return {
        ...state,
        reviewDeliveryData: JSON.parse(state.savedDataJson)
      };
    }

    case DELIVERY_MNG_FILE_EXT_SELECT: {
      let {fileExtensions} = action;
      if (fileExtensions.length > 1) {
        if (fileExtensions[fileExtensions.length - 1].value === FILE_EXTENSION__ALL) {
          fileExtensions = initialState.selectedFileExtensions;
        } else {
          fileExtensions = fileExtensions.filter(ext => ext.value !== FILE_EXTENSION__ALL);
        }
      } else if (!fileExtensions.length) {
        fileExtensions = initialState.selectedFileExtensions;
      }
      return {
        ...state,
        selectedFileExtensions: fileExtensions
      };
    }

    case DELIVERY_MNG_IS_LIST_VIEW: {
      return {...state, isListView: action.isListView};
    }

    case DELIVERY_MNG_SET_SEARCH_STR: {
      return {...state, searchString: action.searchString};
    }

    case DELIVERY_MNG_SELECT_WO_TAB: {
      return {...state, woTab: action.tab};
    }

    case DELIVERY_MNG_OPEN_INSTRUCTIONS: {
      return produce(state, (draft) => {
        const {openedInstructions} = draft;
        const {key, text} = action;
        const index = openedInstructions.findIndex(i => i.key === key);
        if (index >= 0) {
          openedInstructions.splice(index, 1);
        }
        if (text) {
          openedInstructions.push({key, text});
        }
      });
    }

    default:
      return state;
  }
}
