import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const FileTypeIcon = ({value, className}) => {
  return !!value && (
    <StyledIcon className={`file--type--icon${className ? ` ${className}` : ''}`}>
      <i className="fa-duotone fa-file"/>
      <div>
        <span className="file-type-text">{value.substr(0, 4)}</span>
      </div>
    </StyledIcon>
  );
};

const StyledIcon = styled.div`
  position: relative;
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  text-align: center;
  i {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 18px;
  }
  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .file-type-text {
      margin-top: 3px;
      padding: 1px 4px;
      height: 9px;
      min-width: 18px;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      background-color: #e08d8d;
      color: #282828;
      font-size: 7px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: -0.2px;
      text-transform: uppercase;
    }
  }
`;

FileTypeIcon.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
};
