import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {parseName} from '../../StorageManager/utils';

const FileNameCell = ({className, fileName}) => {
  return (
    <td className={className}>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="delivery-full-filename-tooltip-in-cell">{fileName}</Tooltip>}
      >
        <span>{parseName(fileName)}</span>
      </OverlayTrigger>
    </td>
  );
};

export default FileNameCell;