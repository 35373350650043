import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {confirmAlert} from 'react-confirm-alert';
import {isEqualsItems} from '../../../utils';

class Priority extends Component {
  state = {
    editMode: false
  };

  openEditMode = () => {
    this.setState({editMode: true});
  };

  closeEditMode = () => {
    this.setState({editMode: false});
  };

  componentDidUpdate(prevProps) {
    const {link} = this.props;
    if (!isEqualsItems(prevProps.link, link)) {
      this.closeEditMode();
    }
  }

  savePriority = value => {
    const {link, onChange} = this.props;
    let priority = Number(this.inputRef.value);
    if (priority < 0) {
      priority = 0;
      this.inputRef.value = 0;
    }
    if (priority === link.Priority) {
      this.closeEditMode();
    } else {
      confirmAlert({
        title: 'Are You Sure?',
        message: 'Are you sure you want to save changes of priority?',
        buttons: [{
          label: 'No',
        }, {
          label: 'Yes',
          onClick: () => onChange(link, priority, this.closeEditMode)
        }]
      });
    }
  };

  render() {
    const {link, onChange} = this.props;
    const {Priority} = link;
    const noValue = typeof Priority !== 'number';
    const {editMode} = this.state;
    return (
      <StyledCell>
        {
          !editMode ?
            <span
              className={`${onChange ? 'edit' : ''}${noValue ? ' no-value' : ''}`}
              onClick={this.openEditMode}
              title={`Click to ${noValue ? 'set' : 'change'}`}
            >
              {noValue ? 'None' : Priority}
            </span> :
              <>
                <input
                  type="number"
                  min={0}
                  defaultValue={Priority || 0}
                  placeholder="Enter Priority"
                  ref={input => this.inputRef = input}
                />
                <div>
                  <span className="edit" onClick={this.savePriority}>ok</span>
                  <span className="edit" onClick={this.closeEditMode}>cancel</span>
                </div>
              </>
        }
      </StyledCell>
    );
  }
}

const StyledCell = styled.div`
  span {
    cursor: pointer;
    &[title]:hover {
      text-decoration: underline;
    }
    &.no-value {
      opacity: 0.65
      &:hover {
        opacity: 1;
      }
    }
    &.edit:not(.no-value) {
      color: #337ab7;
      &:hover {
        color: #23527c;
      }
    }
    &:not(.edit) {
      pointer-events: none;
    }
  }

  input {
    margin: 0;
    width: 100%;
    & + div {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      height: 26px;
      span {
        margin-left: 5px;
        + span {
          margin-left: 10px;
        }
      }
    }
  }
`;

Priority.propTypes = {
  link: PropTypes.object.isRequired,
  onChange: PropTypes.func
};

export default Priority;