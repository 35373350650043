import $ from 'jquery';
import config from '../../../config';
import Ajv from 'ajv';

export const WO_CHOOSER__CLEAR = 'WO_CHOOSER__CLEAR';
export const WO_CHOOSER__SET_LOADING = 'WO_CHOOSER__SET_LOADING';
export const WO_CHOOSER__GET_FILTERS = 'WO_CHOOSER__GET_FILTERS';
export const WO_CHOOSER__CHANGE_FILTER = 'WO_CHOOSER__CHANGE_FILTER';
export const WO_CHOOSER__GET_REQUESTS = 'WO_CHOOSER__GET_REQUESTS';
export const WO_CHOOSER__SET_FS_ROOT_ID = 'WO_CHOOSER__SET_FS_ROOT_ID';

const ajv = Ajv({
  allErrors:true,
});
export const clearAll = () => ({type: WO_CHOOSER__CLEAR});

const prepareSingleFiltersParams = filters => {
  const preparedFilters = {...filters};
  ['from', 'to', 'state', 'period', 'date_type', 'last_updated'].forEach(key => {
    if (Array.isArray(preparedFilters[key])) {
      preparedFilters[key] = preparedFilters[key].length > 0 ? preparedFilters[key][0] : null;
    }
  });
  return preparedFilters;
};

export const getFilters = () => (dispatch, getState) => {
  dispatch({type: WO_CHOOSER__SET_LOADING, name: 'filters', loading: true});

  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/all_memfis_wo/filters`,

  })
    .then((initialRes) => {
      const filters = [{}];
      Object.entries(initialRes).forEach(([name, valueArr], index) => {
        filters[index].name = name;
        filters[index].title = name;
        filters[index].required = true;
        filters[index].values = valueArr.filter(i => i).map((name2) => ({
          name: name2,
        }));
      });
      dispatch({type: WO_CHOOSER__GET_FILTERS, additionalFilters: filters});
    })
    .catch((error) => {

    })
    .always(() => {
      dispatch({type: WO_CHOOSER__SET_LOADING, name: 'filters', loading: false});
    });
};

export const changeFilter = (history, name, value) => dispatch => {
  dispatch({type: WO_CHOOSER__CHANGE_FILTER, name, value});
  return dispatch(doSearch());
};

const prepareFiltersParams = filters => {
  if (!filters) {
    return {};
  }
  const filtersObj = {
    on_page: 40,
  };
  filters.forEach(filter => {
    if (filter.name === 'columns') {

    } else {
      const name = filter.name.replace(' ', '_').toLowerCase();
      filtersObj[name] = filter.value;
    }
  });

  return prepareSingleFiltersParams(filtersObj);
};

export const doSearch = (isLoadMore) => (dispatch, getState) => {
  const state = getState();
  const {isAssignNewWo, isShowAttachToWoModal} = getState().storageManager.addFileToWoModalState;
  if (state.WOChooserComponent.loaders.more) return { then: (successCallback) => successCallback() };
  const filtersObj = prepareFiltersParams(state.WOChooserComponent.filters);
  if (isAssignNewWo && !isShowAttachToWoModal) {
    filtersObj.not_ingested_only = true;
  }
  if (state.WOChooserComponent.fsRootID) {
    filtersObj.fsRootID = state.WOChooserComponent.fsRootID;
  }
  const loadingName = isLoadMore ? 'more' :'requests' ;
  dispatch({type: WO_CHOOSER__SET_LOADING, name: loadingName, loading: true});
  const requestTimeFiltersJSON = JSON.stringify(state.WOChooserComponent.filters);
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/all_memfis_wo?`,
    data: {
      filters: JSON.stringify(filtersObj)
    }
  })
    .done(res => {
      const isValid = ajv.validate({
        "type": "array",
        "items": {
          "type": "object",
          "properties": {
            "woMemfisId": {
              "type": "string",
              "pattern": "^WO\\d+$"
            },
          }
        }
      }, res)
      let data = []
      if (isValid) {
        data = res
      } else {
        data = [];
        console.error('Data is invalid in doSearch request');
      }

      dispatch({type: WO_CHOOSER__GET_REQUESTS, data, requestTimeFiltersJSON});
    })
    .fail(() => {
      dispatch({type: WO_CHOOSER__SET_LOADING, name: loadingName, loading: false});
    });
};
