import React, { Component } from "react";
import PriorityCellStyled from "./styled/PriorityCell.styled";

export default class PriorityCell extends Component {
  render() {
    const { row, className } = this.props;
    const value = row.PriorityAlt;
    const cellProps = {
      className,
      isEscalated: (value || '').toUpperCase() === 'ESCALATED'
    };

    return <PriorityCellStyled {...cellProps}>{value}</PriorityCellStyled>;
  }
}
