import { createSelector } from 'reselect';
import {getUniqueNumberValues} from "./utils";

const selectStorageManager = (state) => state.storageManager;
const selectLKFS = (state) => state.storageManager.LKFSModalState;
const selectAddFileToWO = (state) => state.storageManager.addFileToWoModalState;
const selectCheckedItems = (state) => state.storageManager.checkedItems;


const isCurrentDestinationValid =
  (fsRoots, modalState) => {
    const {currentDestination} = modalState;
    if (fsRoots.some((root) => root.fsRootID === currentDestination.fsRootID)) {
      if (currentDestination && currentDestination.key) {
        const regex = /^[a-z]:\\?(?:[^\\/:*?"<>|\r\n]+\\)*[^\\/:*?"<>|\r\n]*$/gi;
        return regex.test(currentDestination.key);
      }
    }
    return false;
  };

export const selectIsCurrentDestinationValid = createSelector(
  selectStorageManager,
  ({fsRoots, LKFSModalState}) => isCurrentDestinationValid(fsRoots, LKFSModalState)
);

export const selectIsCurrentDestinationValidForAddFileToWO = createSelector(
  selectStorageManager,
  ({fsRoots, addFileToWoModalState}) => isCurrentDestinationValid(fsRoots, addFileToWoModalState)
);

export const selectIsSaveEnabled = createSelector(
  selectLKFS,
  selectIsCurrentDestinationValid,
  (modalState, isValid) => {
    return modalState.AMBCurrent !== 0
      && isValid
      && modalState.currentDestination
      && modalState.currentDestination.key.length !== 0
      && modalState.currentWOMemfis.length !== 0
      && modalState.isLKFSSubmit
      && !modalState.isErrorNumberFrameRate
      && !modalState.errorQcProject;

  }
);

export const selectIsSaveEnabledForAddFileToWO = createSelector(
  selectAddFileToWO,
  selectIsCurrentDestinationValidForAddFileToWO,
  (modalState, isValid) => {
    return isValid
      && modalState.currentDestination
      && modalState.currentDestination.key.length !== 0
      && modalState.currentWOMemfis.length !== 0;
  }
);

export const selectCheckedItemsContentIDs = createSelector(
  selectCheckedItems,
  (items) => {
    return items.map((item) => String(item.contentID));
  }
);

export const selectCheckedItemContentID = createSelector(
  selectCheckedItems,
  (items) => {
    return items[0].contentID;
  }
);

export const selectSharedFSRoot = createSelector(
  selectCheckedItems,
  (checkedItems) => {
    const uniqueFsRootIDs = getUniqueNumberValues(checkedItems, 'fsRootID');
    return uniqueFsRootIDs[0];
  }
);
