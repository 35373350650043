import React, {Component,} from 'react';
import PropTypes from 'prop-types';
import {InputGroup, Modal, Button, FormControl, ControlLabel, Row, Col, FormGroup, Checkbox} from 'react-bootstrap';
import styled from 'styled-components';
import {sendUpdatedGroup, sendUpdatedPermissions, showSetContentPermissionsModal} from '../../actions';
import {formatDate} from '../../../../utils';
import {convertSymbolicToNumeric, getValueForOS} from "../../utils";
import TextEllipsisAdvanced from '../../../../components/TextEllipsis/TextEllipsisAdvanced';
import RndModal from '../../../../components/modals/RndModal';

const permissionTypes = ['r', 'w', 'x'];
const DEFAULT_PERMISSIONS = '----------';

class ContentPermissionsModal extends Component {

  state = {
    labelColumnWidth: 3,
    contentColumnWidth: 9,
    initialGroup: '',
    modifiedGroup: '',
    initialPermissions:  DEFAULT_PERMISSIONS,
    modifiedPermissions: DEFAULT_PERMISSIONS,
  };

  generateLastChangedInfo() {
    const item = this.props.item;
    return formatDate(item.modifiedOn, '', 'MM/DD/YYYY');
  }

  renderSpecialFlagList = () => {
    const {permissionTypes} = this.props;
    return (
      <FormGroup>
        <Checkbox disabled={true || !permissionTypes.includes('permissions')}>Set user ID</Checkbox>
        <Checkbox disabled={true || !permissionTypes.includes('permissions')}>Set group ID</Checkbox>
        <Checkbox disabled={true || !permissionTypes.includes('permissions')}>Sticky</Checkbox>
      </FormGroup>
    );
  }

  renderRowPathValue(label, contentItem, secondColumnClass = '') {
    return (
      <Row className="size-group with-units">
        <FormGroup>
          <Col md={this.state.labelColumnWidth}
               xs={this.state.labelColumnWidth}
               className="field">
            <ControlLabel>{label}:</ControlLabel>
          </Col>
          <Col md={this.state.contentColumnWidth}
               xs={this.state.contentColumnWidth}
               className={secondColumnClass}
          >
          <TextEllipsisAdvanced className={secondColumnClass} data={getValueForOS(contentItem, 'key')}/>
          </Col>
        </FormGroup>
      </Row>
    );
  }

  renderRow(label, getContent, secondColumnClass = '') {
    return (
      <Row className="size-group with-units">
        <FormGroup>
          <Col md={this.state.labelColumnWidth}
               xs={this.state.labelColumnWidth}
               className="field">
            <ControlLabel>{label}:</ControlLabel>
          </Col>
          <Col md={this.state.contentColumnWidth}
               xs={this.state.contentColumnWidth}
               className={secondColumnClass}
          >
            {getContent()}
          </Col>
        </FormGroup>
      </Row>
    );
  }
  renderFileGroup() {
    const {permissionTypes} = this.props;
    const {initialGroup, modifiedGroup} = this.state;
    const onEditGroup = (event) => {
      this.setState({
        ...this.state,
        modifiedGroup: event.target.value,
      });
    };
    const onReset = () => {
      this.setState({
        ...this.state,
        modifiedGroup: initialGroup,
      });
    };
    return (
      <Row className="size-group with-units">
        <FormGroup>
          <Col md={this.state.labelColumnWidth}
               xs={this.state.labelColumnWidth}
               className="field">
            <ControlLabel className='file-group'>Group:</ControlLabel>
          </Col>
          <Col md={this.state.contentColumnWidth}
               xs={this.state.contentColumnWidth}
          >
            <InputGroup className={'flex ' + (this.state.modifiedGroup.length === 0 ? 'has-error' : '')}>
              <FormControl
                value={this.state.modifiedGroup}
                disabled={!permissionTypes.includes('group')}
                onChange={onEditGroup}
                onKeyDown={this.onGroupKeyDown}
              />
                <Button variant='outline-secondary'
                  onClick={onReset}
                  disabled={modifiedGroup === initialGroup}
                  className='btn-reset'>reset</Button>
            </InputGroup>

          </Col>
        </FormGroup>
      </Row>
    )
  }

  onGroupKeyDown = (event) => {
    const {key} = event;
    if (key === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
      this.onSave();
    }
  }

  isCheckedPermission(triadID, permissionID) {
    const {modifiedPermissions} = this.state;
    const index = 1 + (triadID * 3) + permissionID;
    const checkedChar = permissionTypes[permissionID];
    const char = modifiedPermissions.charAt(index);
    return checkedChar === char;
  }

  onPermissionCheck(triadID, permissionID) {
    let {modifiedPermissions} = this.state;
    const index = 1 + (triadID * 3) + permissionID;
    const checkedChar = permissionTypes[permissionID];
    const char = modifiedPermissions.charAt(index);
    const arr = modifiedPermissions.split('');
    if (char === '-') {
      arr[index] = checkedChar;
    } else {
      arr[index] = '-';
    }
    this.setState({
      ...this.state,
      modifiedPermissions: arr.join(''),
    });
  }

  renderTriadCheckboxes(triadID) {
    const {permissionTypes} = this.props;
    return (
      <FormGroup>
        {
        [
          [0, 'Read'],
          [1, 'Write'],
          [2, 'Execute']
        ]
          .map(([permissionID, permissionName]) => (
            <Checkbox 
              defaultChecked={this.isCheckedPermission(triadID, permissionID)}
              key={`_ef_${triadID}_${permissionID}`}
              className='no-select'
              disabled={!permissionTypes.includes('permissions')}
              onChange={() => this.onPermissionCheck(triadID, permissionID)}
              inline
            >
              {permissionName}
            </Checkbox>
        )) }
      </FormGroup>
    );
  }

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(showSetContentPermissionsModal(false));
  };

  onSave = () => {
    const {dispatch, item} = this.props;
    if (this.state.modifiedPermissions !== this.state.initialPermissions) {
      dispatch(sendUpdatedPermissions(item, this.state.modifiedPermissions));
    }
    if (this.state.modifiedGroup !== this.state.initialGroup
        && this.state.modifiedGroup.length) {
      dispatch(sendUpdatedGroup(item, this.state.modifiedGroup));
    }
    this.handleClose();
  };

  renderTextView = (label, getContent, secondColumnClass = '') => {
    const {initialPermissions, modifiedPermissions} = this.state;
    return (
      <Row className="size-group with-units">
        <FormGroup>
          <Col md={this.state.labelColumnWidth}
               xs={this.state.labelColumnWidth}
               className="field">
            <ControlLabel>{label}:</ControlLabel>
          </Col>
          <Col md={this.state.contentColumnWidth}
               xs={this.state.contentColumnWidth}
               className={secondColumnClass}
          >
            {getContent().split('').map((char, index) => {
              const isSame = initialPermissions[index] === modifiedPermissions[index];
              return (<span
                key={`_dlu${index}`}
                title={isSame ? '' : 'Modified permission'}
                className={isSame ? '' : 'underline'}>{char}</span>)
            })}
          </Col>
        </FormGroup>
      </Row>
    );
  }

  componentDidMount() {
    const {item} = this.props;
    const permissions = (item.permissions && item.permissions.length === 10) ? item.permissions : DEFAULT_PERMISSIONS;
    this.setState({
      initialPermissions: permissions,
      modifiedPermissions: permissions,
      initialGroup: item.group || '',
      modifiedGroup: item.group || '',
    });
  }

  render() {
    const {item} = this.props;
    const isSubmitDisabled = !(
         this.state.modifiedPermissions !== this.state.initialPermissions
         || (this.state.modifiedGroup.length && (this.state.modifiedGroup !== this.state.initialGroup))
      );

    return (
      <RndModal
        className="move-modal"
        show={true}
        onHide={this.handleClose}
        backdrop="static"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Permissions and group
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContainerStyled className="container-fluid">
            <form>
              {this.renderRowPathValue('Path', item, 'path-value')}
              {this.renderRow("Owner",
                () => item.owner)}
              {this.renderFileGroup()}
              <hr/>
              {this.renderRow("Owner",
                () => this.renderTriadCheckboxes(0))}
              {this.renderRow("Group",
                () => this.renderTriadCheckboxes(1))}
              {this.renderRow("Others",
                () => this.renderTriadCheckboxes(2))}
              <hr/>
              {this.renderRow("Special flags",this.renderSpecialFlagList, 'special-flags')}
              <hr/>
              {this.renderTextView("Text view",
                () => this.state.modifiedPermissions, 'monospace')}
              {this.renderRow("Numeric view",
                () => convertSymbolicToNumeric(this.state.modifiedPermissions), 'monospace')}
              {this.renderRow("Last changed",
                () => this.generateLastChangedInfo(),
                'monospace')}
            </form>
          </ModalContainerStyled>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="primary"
            disabled={isSubmitDisabled}
            onClick={this.onSave}
          >
            Save
          </Button>
          <Button bsStyle="default" onClick={this.handleClose}>Cancel</Button>
        </Modal.Footer>
      </RndModal>
    );
  }
}


const ModalContainerStyled = styled.div`
  min-height: 200px;
  
  .path-value {
    color: blue;
    font-weight: bold;
  }
  .field {
    letter-spacing: .3px;
  }
  .monospace {
    font-family:monospace;
  }
  .break-all {
    word-break: break-all;
  }
  .underline {
    text-decoration: underline;
  }
  .flex {
    display:flex;
  }
  .btn-reset {
    position:relative;
    left:-1px;
    border-radius: 0 4px 4px 0;
  }
  .no-select {
    user-select:none;
  }
  .checkbox-inline {
    padding: 0 20px;
  }
  .file-group {
    padding-top:5px;
  }
  .special-flags {
    margin-top:-10px;
  }
`;

ContentPermissionsModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  permissionTypes: PropTypes.array.isRequired,
};

export default ContentPermissionsModal;
