import React, { Component } from "react";
import TextEllipsisAdvanced from '../../../../../components/TextEllipsis/TextEllipsisAdvanced';

export default class UrlCell extends Component {
  render() {
    const { value, className } = this.props;

    return (
      <td className={className}>
        {value ?
          <a
            href={value}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TextEllipsisAdvanced
              data={value}
              noCopyCtrl
              nonMonospaceFont
            />
          </a> : null
        }
      </td>
    );
  }
}
