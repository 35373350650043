import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {Button} from 'react-bootstrap';
import styled from 'styled-components';

export const ClientCell = ({value, row, onLoad, onSelect, className}) => {
  const [selected, setSelected] = useState(null);

  if (value) {
    return (
      <td className={className}>
        {value}
      </td>
    );
  }

  const {clientList} = row;
  return (
    <td className={className}>
      {
        !clientList ?
          <NoClient className="no-client-info-block">
            <span title="No client info">[none]</span>
            {!!(onLoad && onSelect) &&
              <Button
                title="Click to try set client name"
                bsStyle="default"
                onClick={() => onLoad(row)}
              >
                <i className="fas fa-plus"/>
              </Button>
            }
          </NoClient> :
            <Select
              value={selected}
              options={clientList}
              onChange={v => {
                if (v) {
                  setSelected(v.value);
                  onSelect(row, v.value);
                }
              }}
              clearable={false}
            />
      }
    </td>
  );
};

const NoClient = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  span {
    color: red;
  }
  button {
    margin-left: 15px;
  }
  &:not(:hover) button {
    visibility: hidden;
  }
`;

ClientCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object,
  onLoad: PropTypes.func,
  onSelect: PropTypes.func,
  className: PropTypes.string
};
