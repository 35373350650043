import React, { Component } from "react";
import config from "../../../../../../config";
import { getUrlForScreenshot } from "../../../utils";
import BugIdCellStyled from "./styled/BugIdCell.styled";

class BugIdCell extends Component {
  getUrl() {
    return config.apiUrl.replace(/\/services$/, "");
  }

  render() {
    const { value, className } = this.props;
    const url = getUrlForScreenshot(value);
    return (
      <BugIdCellStyled className={className}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      </BugIdCellStyled>
    );
  }
}

export default BugIdCell;
