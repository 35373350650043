import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Row, Col, Button} from 'react-bootstrap';
import {formatDate} from '../../../../utils';
import {
  showDownloadPackageModal
} from '../../actions';
import { FASPEX_FLAGS } from '../../constants';

class InfoPanel extends Component {
  addFieldInfo = (fields, label, value, render) => {
    fields.push({label, value, render});
  };

  renderFields(fields) {
    return (
      <Fragment>
        {
          fields.map((field, index) => {
            const {render} = field;
            return render ? render(field) : <Row key={`info-field-${index}`}>
              <Col md={4} xs={4}>
                <label>
                  {`${field.label}:`}
                </label>
              </Col>
              <Col md={8} xs={8}>
                { field.label === 'WO Memfis' ? this.renderWoMemfis(field.value) : field.value}
              </Col>
            </Row>
            }
          )
        }
      </Fragment>
    );
  };

  renderMultiFileInfo(items) {
    const selected = [];
    const selectedPackages = items.filter(item => item.packageID).length;
    const selectedFolders = items.length - selectedPackages;
    if (selectedFolders) {
      selected.push(`${selectedFolders} Folder${selectedFolders > 1 ? 's' : ''}`);
    }
    if (selectedPackages) {
      selected.push(`${selectedPackages} Package${selectedPackages > 1 ? 's' : ''}`);
    }
    // let size = 0;
    // items.forEach(item => {
    //   size += item.size;
    // });
    const fields = [];
    this.addFieldInfo(fields, 'Selected', selected.join(', '));
    // this.addFieldInfo(fields, 'Size', formatBytes(size));
    return this.renderFields(fields);
  }

  renderSingleFileInfo(item) {
    const fieldsDescription = [
      {
        label: 'Author Email',
        value: 'authorEmail'
      },
      {
        label: 'Author Name',
        value: 'authorName',
      },
      {
        label: 'Package ID',
        value: 'packageID'
      },
      {
        label: 'Published',
        value: formatDate(item.published, '', 'M/D/YYYY hh:mm:ss A')
      },
      {
        label: 'Recipient Email',
        value: 'recipientEmail'
      },
      {
        label: 'Recipient Name',
        value: 'recipientName'
      },
      {
        label: 'Updated',
        value: formatDate(item.published, '', 'M/D/YYYY hh:mm:ss A')
      },
      {
        label: 'Updated On',
        value: formatDate(item.published, '', 'M/D/YYYY hh:mm:ss A')
      },
      {
        label: 'WO Memfis',
        value: 'woMemfisList'
      }
    ];

    const fields = [];
    fieldsDescription.forEach(field => {
      const value = ['Published', 'Updated', 'Updated On'].includes(field.label) ? field.value
        : item[field.value];
      if (!field.skip) {
        this.addFieldInfo(fields, field.label, value);
      }
    });
    return this.renderFields(fields);
  }

  renderWoMemfis(woMemfisList) {
    return woMemfisList && woMemfisList.map(woMemfis => (
      <>
        <a
          href={`/storage?use_max_modified_on=true&last_segment_only=true&symlinks_too=true&wo_memfis=${woMemfis}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {woMemfis} {' '}
        </a>
        <br/>
      </>
      ));
  }

  // renderRefreshButton() {
  //   const {dispatch, items} = this.props;
  //   return (items.length === 1 && items[0].packageID) ? (
  //     <Button
  //       bsStyle="default"
  //       title="Refresh"
  //       onClick={() => dispatch(refreshCheckedContent())}
  //       >
  //       <i className="fas fa-sync"/>
  //     </Button>
  //   ) : null;
  // }

  handleClickDownloadButton(packageID) {
    const {dispatch} = this.props;
    dispatch(showDownloadPackageModal(packageID))
  }

  renderDownloadButton() {
    const {items} = this.props;
    return (items.length === 1 && items[0].packageID) ? (
      <Button
        bsStyle="default"
        title="Download"
        onClick={() => this.handleClickDownloadButton(items[0].packageID)}
        >
        <i className="fas fa-download"/>
      </Button>
    ) : null;
  }

  render() {
    const {items} = this.props;
    const isDownloadAvailable = items.length === 1 && (Number(items[0].packageFlags.toString(2)) & FASPEX_FLAGS['Inbox']) > 0;
    return (
      <StyledInfoPanel>
        {/* {this.renderRefreshButton()} */}
        { isDownloadAvailable && this.renderDownloadButton(items[0])}
        {items.length === 1 ? this.renderSingleFileInfo(items[0]) : this.renderMultiFileInfo(items)}
      </StyledInfoPanel>
    );
  }
}

const StyledInfoPanel = styled.div`
  padding: 5px 0 5px 15px;
  border-left: 3px solid #ddd;
  max-height: calc(100vh - 71px - 150px);
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(25% - 15px);
  transition: width 0.3s ease-in-out 0s ease-in-out 0s display 0s ease-in-out 0.3s;

  @media (max-width: 767px) {
    max-height: calc(100vh - 71px - 210px);
  }

  .table-wrapper.info-panel-collapsed & {
    width: 0;
    display: none;
  }

  .col-md-8, .col-xs-8 {
    word-break: break-all;
  }

  button {
    margin-bottom: 20px;
    margin-right: 5px;
  }
`;

InfoPanel.propsTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  selectedTab: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

export default InfoPanel;
