import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import TextEllipsis from './TextEllipsis';

const TextEllipsisAdvanced = (props) => {
  const {data, colors, className, tooltipLeft, noCopyCtrl, nonMonospaceFont, defaultText, tooltipText, style} = props;
  const fullText = Array.isArray(data) ? data.join('') : data || '';

  const tRef = useRef(null);

  const [copied, setCopied] = useState(false);

  let timeoutId = null;

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    }
  }, [timeoutId]);

  const onCopyToClipboard = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const text = fullText;
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(text)
      setCopied(true);
      timeoutId = setTimeout(() => {
        setCopied(false);
      }, 1000);
      return;
    }

    const body = document.body;
    if (!body) {
      return;
    }

    const node = createNode(text);
    body.appendChild(node);

    const selection = getSelection();
    if (selection == null) {
      return;
    }

    selection.removeAllRanges();

    const range = document.createRange();
    range.selectNodeContents(node);
    selection.addRange(range);

    document.execCommand('copy');
    selection.removeAllRanges();

    body.removeChild(node);

    setCopied(true);
    timeoutId = setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const createNode = (text) => {
    const node = document.createElement('pre');
    node.style.width = '1px';
    node.style.height = '1px';
    node.style.position = 'fixed';
    node.style.top = '5px';
    node.textContent = text;
    return node;
  };

  const copyCtrl = !noCopyCtrl && !!fullText && (
    <div
      className="copy-btn"
      title="Copy to clipboard"
      onClick={onCopyToClipboard}
    >
      <i className="fa-regular fa-fw fa-copy"/>
      {copied &&
        <span className="copy-success-msg">
          <i className="fa-regular fa-fw fa-check-circle"/>
          Copied
        </span>
      }
    </div>
  );

  const isShowTooltip = !!fullText && (fullText.length > 5 || fullText.indexOf('\\') !== -1 || fullText.indexOf('/') !== -1);

  return (
    <StyledContainer className={`text--ellipsis--advanced${className ? ` ${className}` : ''}`} style={style}>
      <StyledText
        ref={tRef}
        tooltipLeft={tooltipLeft}
        isShowCopyBtn={!!copyCtrl}
      >
        <div className="ellipse--value">
          {
            fullText ?
              <>
                <TextEllipsis
                  data={data}
                  colors={colors}
                  tooltipText={tooltipText}
                  tooltipContainer={(tRef || {}).current}
                  noTooltip={!isShowTooltip}
                  useMonospaceFont={!nonMonospaceFont}
                />
                {copyCtrl}
              </> : defaultText
          }
        </div>
      </StyledText>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
`;

const StyledText = styled.div`
  position: relative;
  max-width: 100%;
  width: 100%;

  .ellipse--value {
    white-space: nowrap;
    /*overflow: hidden;*/
    max-width: 100%;
    width: fit-content;
    ${props => props.isShowCopyBtn && css`
      position: relative;
      > div:not(.copy-btn) {
        margin-right: 29px;
      }
    `}
    > div:not(.copy-btn) {
      cursor: default;
    }
  }

  .tooltip {
    left: ${props => `${props.tooltipLeft || 0}px`} !important;
    margin-top: 0;
    z-index: 3;
    .tooltip-inner {
      max-width: unset;
      font-size: 12px !important;
      line-height: 1.42 !important;
      text-align: left;
      word-break: break-all;
    }
  }

  .copy-btn {
    position: absolute;
    top: 0;
    right: 2px;
    padding: 0 2px;
    border-radius: 2px;
    cursor: pointer;
    opacity: 0;
    transform: translate(-8px, 0px);
    transition: opacity 0.2s ease 0s, transform 0.2s ease 0s;
    i.fa-copy {
      color: #757575;
      opacity: 0.8;
    }
    :hover {
      background-color: #f3f3f3;
      box-shadow: 0 0 0 1px #ccc;
      i.fa-copy {
        color: #333;
        opacity: 1;
      }
    }
  }
  .ellipse--value:hover {
    .copy-btn {
      opacity: 1;
      transform: translate(-0px, 0px);
    }
  }

  .copy-success-msg {
    color: #1d8102;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    position: absolute;
    left: calc(100% - 90px);
    width: auto;
    border: 1px solid #ccc;
    padding: 4px 5px;
    background: #fff;
    border-radius: 2px;
    i {
      margin-right: 4px;
    }
  }
`;

TextEllipsisAdvanced.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  colors: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  style: PropTypes.object,
  tooltipLeft: PropTypes.number,
  tooltipText: PropTypes.string,
  noCopyCtrl: PropTypes.bool,
  nonMonospaceFont: PropTypes.bool,
  defaultText: PropTypes.string,
};

export default TextEllipsisAdvanced;