import styled from "styled-components";

const StyledBtn = styled.button`
  border: none;
  background-color: transparent;
  box-shadow: none;
  color: #279DC2;
  font-size: 10px;
  font-weight: bold;
  outline: none;
  &:hover, &:focus {
    background-color: transparent;
  }
  &:hover {
    color: #228daf;
  }

  @media (max-width: 991px) {
    .requests-content & {
      line-height: 12px;
      display: flex;
      align-items: center;
      height: 40px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`;

export default StyledBtn;