import {produce} from "immer";
import {
  WO_CHOOSER__CHANGE_FILTER,
  WO_CHOOSER__CLEAR,
  WO_CHOOSER__GET_FILTERS,
  WO_CHOOSER__GET_REQUESTS,
  WO_CHOOSER__SET_LOADING,
  WO_CHOOSER__SET_FS_ROOT_ID
} from "./actions";
import {USER_SET_IS_LOGOUT} from "../../pages/Login/actions";

const initialState = {
  data: [],
  fsRootID: null,
  /* Stop attempting to load new data for this filter if data was once [] */
  expectResults: true,
  filters: [
    {
      name: 'search',
      value: null
    },
    {
      name: 'page_num',
      value: 0,
    },
    {
      name: 'columns',
      value: {
        woMemfisId: {
          title: 'WO#',
          className: 'work-order',
          sort: 0,
        },
        title: {
          title: 'Title',
          className: 'title',
          sort: 0,
        },
        clientName: {
          title: 'Client',
          className: 'client',
          sort: 0,
        },
        format: {
          title: 'Format',
          className: 'word-break-all',
          sort: 0,
        },
        episode: {
          title: 'Episode',
          className: 'episode',
          sort: 0,
        },
      }
    }
  ],
  loaders: {
    filters: false,
    requests: false,
    more: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SET_IS_LOGOUT:
    case WO_CHOOSER__CLEAR: {
      return {...initialState};
    }

    case WO_CHOOSER__SET_LOADING: {
      return produce(state, (draft) => {
        draft.loaders[action.name] = action.loading;
      });
    }

    case WO_CHOOSER__SET_FS_ROOT_ID: {
      return produce(state, (draft) => {
        draft.fsRootID = action.fsRootID;
      });
    }

    case WO_CHOOSER__GET_FILTERS: {
      return produce(state, (draft) => {
        const filterNames = state.filters.map((f) => f.name);
        for (let filter of (action.additionalFilters || [])) {
          if (!filterNames.includes(filter.name)) {
            draft.filters.push(filter);
          }
        }
      });
    }

    case WO_CHOOSER__CHANGE_FILTER: {
      return produce(state, (draft) => {
        /* Reset additional filters on search. */
        if (action.name === 'search') {
          const initialFilterNames = initialState.filters.map((i) => i.name);
          for (let filter of draft.filters) {
            if (!initialFilterNames.includes(filter.name) && Array.isArray(filter.values)) {
              filter.value = undefined;
            }
          }
        }
        draft.filters.forEach(item => {
          if (item.name === action.name) {
            if (item.value === action.value) {
              item.value = null;
            } else {
              item.value = action.value || null;
            }
          }
          if (item.name === 'page_num') {
            item.value = 0;
          }
          if (item.name === 'search' && action.name !== 'search') {
            item.value = '';
          }
        });
        draft.data = [];
        draft.expectResults = true;
      });
    }
    case WO_CHOOSER__GET_REQUESTS: {
      const {requestTimeFiltersJSON} = action;
      const currentTimeFiltersJSON = JSON.stringify(state.filters);
      const isRelevantNow = requestTimeFiltersJSON === currentTimeFiltersJSON;
      if (isRelevantNow) {
        return produce(state, (draft) => {
          draft.loaders.requests = false;
          draft.loaders.more = false;

          if (action.data.length) {
            const woIDs = state.data.map((item) => item.woMemfisId);
            for (let item of action.data) {
              if (!woIDs.includes(item.woMemfisId)) {
                draft.data.push(item);
              }
            }
            const page = draft.filters.find((filter) => filter.name === 'page_num');
            if (page) {
              page.value += 1;
            } else {
              console.error('No page')
            }
          } else {
            draft.expectResults = false;
          }
        });
      } else {
        return {...state};
      }
    }

    default: {
      return state;
    }
  }
}
