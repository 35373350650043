import React from 'react';
import PropTypes from 'prop-types';
import FileNameCell from '../../cells/FileNameCell';

export const NameCell = ({value, row}) => <FileNameCell link={row} inPackage />;

NameCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object
};
