import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import Loader from "../Loader";
import sortUpImg from '../../../assets/images/table/sort-up.svg';
import sortDownImg from '../../../assets/images/table/sort-down.svg';
import sortUpActiveImg from '../../../assets/images/table/sort-up-active.svg';
import sortDownActiveImg from '../../../assets/images/table/sort-down-active.svg';
import ActionCell from "./ActionCell";
import ReportBugButton from "../ReportBugButton";

export const Cell = ({value, className, title}) => (
  <td className={className} title={typeof title === 'string' ? title : ''}>{value}</td>
);
Cell.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.any
};


export const Row = ({headers, row, rowKey, onRowClick, getRowClassNames, isSeparatedRows}) => {
  const cells = Object.keys(headers)
    .map(k => {
      const CustomComponent = headers[k].component;
      const customArgs = headers[k].componentArgs || {};
      const className = headers[k].className;
      return CustomComponent ?
        <CustomComponent {...customArgs} value={row[k]} row={row} key={k} rowKey={rowKey} className={className}/> :
        <Cell value={row[k]} key={k} title={headers[k].title} className={className}/>;
    });

  return (
    <Fragment>
      <tr className={getRowClassNames(row)} onClick={onRowClick ? () => onRowClick(row) : undefined}>{cells}</tr>
      {isSeparatedRows ? <tr className="tr-separate"><th colSpan={cells.length}/></tr> : null}
    </Fragment>
  );
};

Row.propTypes = {
  headers: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  rowKey: PropTypes.any,
  onRowClick: PropTypes.func,
  getRowClassNames: PropTypes.func,
  isSeparatedRows: PropTypes.bool
};

Row.defaultProps = {
  getRowClassNames: () => {}
};


export const HeaderRow = ({headers, sort}) => (
  <thead>
  <tr>
    {Object.keys(headers).map((k, i) => {
      const customHeaderCell = headers[k].headCell;
      return customHeaderCell && customHeaderCell.component && customHeaderCell.componentArgs ?
        <customHeaderCell.component {...customHeaderCell.componentArgs} key={i}/> :
        <HeaderCell headers={headers} sort={sort} field={k} key={i}/>;
    })}
  </tr>
  </thead>
);
HeaderRow.propTypes = {
  headers: PropTypes.object.isRequired,
  sort: PropTypes.object,
};

export const HeaderCell = ({headers, field, sort = {}}) => {
  const filtered = sort.field === field;

  const sortEl = sort.direction === 'asc' ?
    <SortArrowStyled className="sort-up"/> :
    <SortArrowStyled className="sort-down"/>;

  const hint = filtered ? 'Click to Sort' : typeof headers[field].hint === 'string' ? headers[field].hint :
    typeof headers[field].title === 'string' ? headers[field].title : field;
  return (
    <th className={headers[field].className} title={hint}>
      {headers[field].title} {filtered && sortEl}{!filtered && headers[field].sortPlaceholder}
    </th>
  );
};

const SortArrowStyled = styled.span`
  display: inline-block;
  height: 10px;
  width: 10px;
  &.sort-down {
    background-image: url(${sortUpImg}), url(${sortDownActiveImg});
  }
  &.sort-up {
    background-image: url(${sortUpActiveImg}), url(${sortDownImg});
  }
  background-position: right top, right bottom;
  background-repeat: no-repeat, no-repeat;
`;

HeaderCell.propTypes = {
  headers: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  sort: PropTypes.object,
};

class Table extends Component {
  componentDidMount() {
    if (typeof this.props.onLoadMore === 'function') {
      this.refs.tbody.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (typeof this.props.onLoadMore === 'function') {
      this.refs.tbody.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = () => {
    const {expectResults, loading} = this.props;
    const {tbody} = this.refs;

    const prevScrollTop = Number(tbody.getAttribute('data-scrolltop'));
    tbody.setAttribute('data-scrolltop', tbody.scrollTop);

    const pagingDistanceToBottom = tbody.scrollHeight / 8;
    if (
      !loading
      && expectResults
      && tbody.scrollTop > prevScrollTop
      && tbody.offsetHeight + tbody.scrollTop + pagingDistanceToBottom > tbody.scrollHeight) {
      this.props.onLoadMore();
    }
  };

  render() {
    const {
      headerRow, headers, rows, sort, onRowClick, getRowClassNames,
      keyName, stripped, emptyText, isSeparatedRows, className
    } = this.props;
    return (
      <table className={`table${stripped ? ' table-striped' : ''} ${className || ''}`}>
        {headerRow || <HeaderRow headers={headers} sort={sort}/>}
        <tbody ref="tbody">
        {rows.map((row, i) =>
          <Row
            getRowClassNames={getRowClassNames}
            headers={headers}
            isSeparatedRows={isSeparatedRows}
            key={keyName ? `${row[keyName]}_${i}` : i}
            onRowClick={onRowClick}
            row={row}
            rowKey={keyName}
          />
        )}
        {
          !rows.length && emptyText ? (
            <tr><td className="data-table-empty" colSpan={Object.keys(headers).length}>{emptyText}</td></tr>
          ) : null
        }
        </tbody>
      </table>
    );
  }
}

Table.propTypes = {
  className: PropTypes.string,
  emptyText: PropTypes.string,
  getRowClassNames: PropTypes.func,
  headerRow: PropTypes.any,
  headers: PropTypes.object.isRequired,
  isSeparatedRows: PropTypes.bool,
  keyName: PropTypes.string,
  onLoadMore: PropTypes.func,
  onRowClick: PropTypes.func,
  rows: PropTypes.array.isRequired,
  sort: PropTypes.object,
  stripped: PropTypes.bool,
  expectResults: PropTypes.bool.isRequired,
};

Table.defaultProps = {
  stripped: true
};


class RequestsTable extends Component {
  constructor(props, context) {
    super(props, context);

    this.headers = this.getHeaders();
  }

  getHeaders() {
    const {onSubmitRow, columns} = this.props;
    return {
      ...columns,
      Selector: {
        title: 'Action',
        className: 'actions',
        component: ActionCell,
        componentArgs: {
          onSubmit: (row) => onSubmitRow(row.woMemfisId),
        }
      }
    }
  }

  render() {
    const {loading, data, tableSort, onLoadMore, expectResults} = this.props;
    const tableClassNames = classNames({
      'table-contaner': true,
      'std-requests-table': true,
    });
    return (
      <StyledTable className="std-requests">
        <div className={tableClassNames}>
          {loading ?
              <div>
                <Loader/>
                <ReportBugButton isForModal className="loader-report-bug-btn"/>
              </div> : null}
          <div className="table-block">
            <Table
              headers={this.headers}
              rows={data}
              stripped={false}
              sort={tableSort}
              emptyText={loading ? null : 'No Data'}
              onLoadMore={onLoadMore}
              expectResults={expectResults}
            />
          </div>
        </div>
      </StyledTable>
    );
  }
}

const StyledTable = styled.div`
  .loader-container {
    width: 100%;
    z-index: 3;
  }

  &.load-more, .table-body--load-more {
    pointer-events: none;
    opacity: 0.65;
  }

  &.std-requests {
    height: 100%;
    .std-requests-table {
      height: 100%;
      margin-bottom: 0;
  
      .table-block {
        height: 100%;
      }
      .table {
        height: 100%;
        display: block;
    
        thead {
          position: sticky;
          top: 0;
          display: block;
    
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: none;
            background-color: transparent;
          }
    
          &::-webkit-scrollbar {
            width: 8px;
            background-color: transparent;
          }
    
          &::-webkit-scrollbar-thumb {
            -webkit-box-shadow: none;
            background-color: transparent;
          }
        }
    
        tbody {
          max-height: calc(100% - 45px);
          display: block;
          overflow-y: scroll;
    
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: none;
            background-color: transparent;
          }
    
          &::-webkit-scrollbar {
            width: 8px;
            background-color: transparent;
          }
    
          &::-webkit-scrollbar-thumb {
            border-radius: 100px;
            -webkit-box-shadow: none;
            background-color: #c4c4c4;
          }

          @media (max-width: 767px) {
            .requests-content & {
              &::-webkit-scrollbar {
                width: 0;
              }
              &::-webkit-scrollbar-thumb {
                background-color: transparent;
              }
            }
          }

          tr:hover {
            background-color: #F9F9F9;
            cursor: default;
          }
        }
    
        tr {
          display: block;
          margin-right: 5px;
          
          &:first-of-type {
            border-top: 0;
            td {
              border: 0;
            }
          }
    
          &:before, &:after {
            display: table;
            content: " ";
            clear: both;
          }

          th, td {
            --column-width: calc(100% / 6);
            display: block;
            float: left;
            width: var(--column-width);
            color: #282828;
            word-break: break-all;

            &:first-child {
              padding-left: 23px;
              padding-right: 16px;
            }

            &.request-title {
              width: 20%;
              &.is-client {
                width: 36%;
              }
            }

            &.submitted-on {
              width: var(--column-width);
              &.is-client {
                width: 16%;
              }
            }
          }
    
          th {
            font-weight: bold;
            font-size: 12px;
            line-height: 13px;
            background: #fff;
            border-radius: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);
            padding-top: 15px;
            padding-bottom: 15px;
            text-align: left;
            display: flex;
          }
    
          td {
            padding-top: 10px;
            padding-bottom: 10px;
            font-weight: normal;
            font-size: 13px;
            line-height: 14px;
    
            a {
              font-weight: bold;
              color: #29A4CB;
    
              &:hover {
                color: #228daf;
              }
            }

            &.request-format, &.womemfis {
              word-break: break-all;
            }

            &.data-table-empty {
              text-align: center;
              width: 100% !important;
            }
          }
        }
      }
    
      &.client-service .table tr {
        th, td {
          &.submitted-on {
            white-space: pre;
          }

          &.created-by, &.submitted-on {
            width: 11%;
          }
          &.request-title {
            width: 14%;
          }
          &.request-format {
            width: 9%;
          }
          &.womemfis {
            width: 7%;
          }
          &.studio, &.distributor, &.status {
            width: 9%;
          }
          &.client {
            width: 8%;
          }
        }
        th.actions {
          color: transparent;
          pointer-events: none;
        }
      }
    }

    @media (max-width: 991px) {
      .requests-content & {
        padding: 2px 0 0;
      }
    }

    @media (max-width: 767px) {
      .requests-content & {
        padding: 7px 0 0;

        .std-requests-table {

          .table {
            thead {
              display: none;
            }
            tbody {
              tr {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                border-bottom: 0.5px solid #D3D3D3;
                &:hover {
                  background-color: #fff;
                  box-shadow: none;
                }
                td {
                  border: none;
                  padding: 0 0 0 15px;
                  width: 100% !important;
                  float: none;
                  &.due, &.requested-by, &.assigned-to {
                    display: none;
                  }
                }
              }
            }
          }

          &.post-partner .table tbody tr td {
            &.request-title {
              order: 1;
            }
            &.studio {
              order: 2;
              font-weight: bold;
              font-size: 11px !important;
              line-height: 12px !important;
              color: #282828 !important;
              padding-bottom: 5px !important;
              &:before {
                content: attr(title);
                color: #282828;
                font-weight: normal;
                font-size: 11px;
                padding-right: 5px;
              }
            }
            &.submitted-on {
              order: 3;
            }
            &.status {
              order: 4;
            }
          }

          &.client-service {
            .table tbody {
              tr {
                td {
                  width: 100% !important;
                  padding-bottom: 5px;
                  font-weight: bold;

                  &:first-child {
                    padding-top: 15px !important;
                  }
                  &:last-child {
                    padding-bottom: 15px !important;
                    a:first-child {
                      margin-bottom: 10px;
                    }
                  }

                  &.client, &.studio, &.request-title, &.request-format, &.distributor, &.womemfis, &.submitted-on,
                  &.status, &.created-by {
                    &:before {
                      content: attr(title);
                      color: #282828;
                      font-weight: normal;
                      font-size: 11px;
                      padding-right: 10px;
                    }
                  }
                  &.womemfis {
                    &:before {
                      content: 'WO#';
                    }
                  }
                  &.submitted-on {
                    &:before {
                      content: 'Submitted On';
                    }
                  }
                  &.status {
                    &:before {
                      content: 'Status';
                    }
                  }
                  &.created-by {
                    &:before {
                      content: 'Created By';
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

RequestsTable.defaultProps = {
  data: []
};

RequestsTable.propTypes = {
  columns: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  tableSort: PropTypes.object.isRequired,
  onSearch: PropTypes.func,
  onLoadMore: PropTypes.func.isRequired,
  expectResults: PropTypes.bool.isRequired,
};

export default RequestsTable;

