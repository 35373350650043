/* eslint "jsx-a11y/anchor-is-valid":"off" */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const LogDataCell = ({value, row, onDownload}) => {
  const lines = (value || '').split(/\r?\n/g).filter(line => Boolean(line));
  const errorLines = lines.filter(line => line.match(/\bERR\b/));
  return (
    <StyledCell>
      {
        (errorLines.length ? errorLines : lines).slice(0, 5).map((line, index) => (
          <Fragment key={`log-line-${index}`}>
            {line}
            <br/>
          </Fragment>
        ))
      }
      {lines.length > (errorLines.length || 5) ? <Fragment>...<br/></Fragment> : null}
      {
        value ?
          <a onClick={() => onDownload(row)} title="Download the full log file">
            <i className="fas fa-download"/>
          </a> : null
      }
    </StyledCell>
  );
};

const StyledCell = styled.td`
  a {
    &:hover {
      cursor: pointer;
    }
  }
`;

LogDataCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object,
  onDownload: PropTypes.func
};
