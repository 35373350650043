import React, {Component} from 'react';
import styled from 'styled-components';
import {Modal} from 'react-bootstrap';
import {Rnd} from 'react-rnd';

export const RND_STYLE = {
  backgroundColor: '#fff',
  backgroundClip: 'padding-box',
  border: '1px solid rgba(0, 0, 0, 0.2)',
  borderRadius: '6px',
  /*boxShadow: '0 3px 9px rgba(0, 0, 0, 0.5)',*/
  boxShadow: '0 5px 15px rgba(0, 0, 0, 0.5)',
  outline: '0',
};

class RndModal extends Component {
  state = {
    skipUpdateComponent: false
  };

  getDefaultWidth = (asNumber) => {
    const {bsSize, width} = this.props;
    if (width) {
      if (asNumber && typeof width === 'string' && width.endsWith('%')) {
        try {
          const bodyWidth = document.body.getBoundingClientRect().width;
          return bodyWidth * +width.replace(/[^0-9]/g, '') / 100;
        } catch {}
      }
      return width;
    } else if (['large', 'lg'].includes(bsSize)) {
      return 900;
    } else if (['small', 'sm'].includes(bsSize)) {
      return 300;
    } else if (['largest', 'xl'].includes(bsSize)) {
      return 1200;
    }
    return 600;
  };

  updateComponent = () => {
    if (this.state.skipUpdateComponent) {
      this.setState({skipUpdateComponent: false});
      return;
    }
    try {
      const bodyRect = document.body.getBoundingClientRect();
      const bodyWidth = bodyRect.width;
      const self = this.rndRef.getSelfElement();
      const selfRect = self.getBoundingClientRect();
      const selfWidth = selfRect.width;
      const maxWidthMobile = bodyWidth - 20;
      if (bodyWidth <= 767 && selfWidth > maxWidthMobile) {
        this.rndRef.updateSize({width: maxWidthMobile, height: 'auto'});
        this.rndRef.updatePosition({x: 0, y: 0});
        self.parentElement.parentElement.classList.add('width--auto');
      } else if (bodyWidth > 767) {
        const maxWidth = bodyWidth - 40;
        if ((selfWidth + selfRect.left) > maxWidth) {
          this.rndRef.updateSize({width: maxWidth, height: selfRect.height});
          this.rndRef.updatePosition({x: 0, y: selfRect.top});
          self.parentElement.parentElement.style.width = `${maxWidth}px`;
        } else {
          self.parentElement.parentElement.classList.remove('width--auto');
        }
      }
    } catch {}
  };

  handleWindowResize = () => {
    this.updateComponent();
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentDidUpdate(prevProps) {
    const {bsSize, width} = this.props;
    if (bsSize !== prevProps.bsSize || width !== prevProps.width) {
      try {
        const bodyRect = document.body.getBoundingClientRect();
        const bodyWidth = bodyRect.width;
        const self = this.rndRef.getSelfElement();
        const selfRect = self.getBoundingClientRect();
        const selfWidth = selfRect.width;
        let defaultWidth = this.getDefaultWidth();
        if (typeof width === 'number') {
          defaultWidth = width;
        } else if (typeof width === 'string' && width.endsWith('%')) {
          defaultWidth = bodyWidth * +width.replace(/[^0-9]/g, '') / 100;
        } else if (typeof defaultWidth === 'string' && defaultWidth.endsWith('%')) {
          defaultWidth = bodyWidth * +defaultWidth.replace(/[^0-9]/g, '') / 100;
        }
        const calcWidth = Math.min(bodyWidth - (bodyWidth <= 767 ? 20 : 40), defaultWidth);
        if (selfWidth !== calcWidth) {
          if (bodyWidth > 767) {
            this.rndRef.updateSize({width: calcWidth, height: 'auto'/*selfRect.height*/});
            this.rndRef.updatePosition({x: 0, y: 0/*selfRect.top*/});
            self.parentElement.parentElement.style.width = `${calcWidth}px`;
            self.parentElement.parentElement.classList.remove('width--auto');
          } else {
            this.rndRef.updateSize({width: calcWidth, height: 'auto'});
            this.rndRef.updatePosition({x: 0, y: 0});
            self.parentElement.parentElement.classList.add('width--auto');
          }
        }
      } catch {}
      //this.updateComponent();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleResizeStart = () => {
    try {
      const self = this.rndRef.getSelfElement();
      const elms = self && self.getElementsByClassName('text--ellipsis--advanced');
      if (elms) {
        for (let elm of elms) {
          elm.classList.add('text--ellipsis--overflow--hidden');
        }
      }
    } catch {}
  };

  handleResizeStop = () => {
    this.setState({skipUpdateComponent: true});
    try {
      let event;
      if (typeof (Event) === 'function') {
        event = new Event('resize');
      } else { /*IE*/
        event = document.createEvent('Event');
        event.initEvent('resize', true, true);
      }
      window.dispatchEvent(event);
    } catch (ignored) {
      this.setState({skipUpdateComponent: false});
    }
    try {
      const elms = document.getElementsByClassName('text--ellipsis--overflow--hidden');
      if (elms) {
        for (let elm of elms) {
          elm.classList.remove('text--ellipsis--overflow--hidden');
        }
      }
    } catch {}
  };

  render() {
    const modalProps = {...this.props};
    delete modalProps.children;
    delete modalProps.width;
    delete modalProps.minHeight;

    const defaultWidth = this.getDefaultWidth(true);
    let minWidth = 450;
    if (typeof defaultWidth === 'number' && defaultWidth < minWidth) {
      minWidth = defaultWidth;
    }

    return (
      <RndModalStyled
        {...modalProps}
      >
        <Rnd
          ref={r => {this.rndRef = r;}}
          style={RND_STYLE}
          minWidth={minWidth}
          minHeight={this.props.minHeight || 250}
          bounds="window"
          enableUserSelectHack={false}
          cancel=".modal-body, .modal-footer"
          default={{
            x: 0,
            y: 0,
            width: defaultWidth,
            height: 'auto',
          }}
          onResizeStart={this.handleResizeStart}
          onResizeStop={this.handleResizeStop}
        >
          {this.props.children}
        </Rnd>
      </RndModalStyled>
    );
  }
}

const RndModalStyled = styled(Modal)`
  .react-draggable {
    display: flex !important;
    flex-direction: column !important;
  }
  .modal-xl {
    width: 1200px;
  }
  .modal-dialog {
    &.width--auto {
      width: auto;
    }
  }
  .modal-content {
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: 6px;
    box-shadow: none;
    outline: 0;
  }
  .modal-body {
    flex-grow: 1 !important;
    overflow: auto;
    cursor: auto;
  }
  .modal-footer {
    cursor: auto;
  }
  .text--ellipsis--advanced {
    padding-top: 1px;
    padding-bottom: 1px;
    &.text--ellipsis--overflow--hidden {
      overflow: hidden;
    }
  }
`;

export default RndModal;
