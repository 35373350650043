import React from 'react';
import PropTypes from 'prop-types';

export const Cell = ({value, className}) => (
  <td className={className}>{value}</td>
);
Cell.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string
};
