/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import DeliveryCommon from './DeliveryCommon';
import {
  DELIVERY_PAGE__RENAME_ONLY,
  DELIVERY_STEP,
} from '../constants';

const DeliveryRenamePopup = (props) => {
  const {
    dispatch,
    deliveryManager,
    onClose,
    paramsToGetFiles,
    paramsToSaveFiles,
    isSingleFile,
  } = props;

  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  return (
    <StyledPopup
      className={deliveryManager.loading && !deliveryManager.reviewDeliveryData ? 'is-loading' : undefined}
      isHidden={!!isSingleFile}
    >
      <div
        className="popup-close-btn"
        title="Close"
        onClick={onClose}
      >
        <i className="fa-solid fa-circle-xmark"/>
      </div>
      <div
        className="popup-main-layout"
      >
        <DeliveryCommon
          dispatch={dispatch}
          history={{}}
          match={{params: {operationType: DELIVERY_PAGE__RENAME_ONLY, actionId: 0, step: DELIVERY_STEP.RENAMING_FILES}}}
          deliveryManager={deliveryManager}
          operationName="Rename"
          paramsToGetFiles={paramsToGetFiles}
          paramsToSaveFiles={paramsToSaveFiles}
          onExitDeliveryRename={isSingleFile ? onClose : undefined}
        />
      </div>
    </StyledPopup>
  );
};

const StyledPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1052;
  background: rgba(255, 255, 255, 1);
  display: flex;
  /*height: 100vh;*/
  flex-direction: column;
  ${props => props.isHidden && css`
    visibility: hidden !important;
  `}
  &.is-loading {
    background: rgba(255, 255, 255, 0.1);
  }
  .popup-main-layout {
    flex: 1;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    >div>div:not(#delivery-step-btns) {
      margin-top: 20px;
      max-height: calc(100vh - 20px - 65px);
    }
  }
  .popup-close-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1053;
    i {
      font-size: 17px;
    }
    &:hover i {
      opacity: 0.8;
    }
  }
`;

DeliveryRenamePopup.propTypes = {
  dispatch: PropTypes.func.isRequired,
  deliveryManager: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  isSingleFile: PropTypes.bool,
  paramsToGetFiles: PropTypes.object,
  paramsToSaveFiles: PropTypes.object,
};

export default DeliveryRenamePopup;
