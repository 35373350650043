import produce from "immer";
import {
  ISSUE_BROWSER_CHANGE_FILTER_VALUE,
  ISSUE_BROWSER_CHANGE_SELECTED_ITEM,
  ISSUE_BROWSER_GET_BUG_REPORTS,
  ISSUE_BROWSER_GET_ADDITIONAL_DATA,
  ISSUE_BROWSER_SET_LOADING,
  ISSUE_BROWSER_UPDATE_BUG_REPORT,
} from "./actions";
import { getDefaultsOfFilters, ISSUE_FILTERS_TYPES } from "./constants/filters";
import { isEqualsBugReports, filterBugReports, saveSettingsToStorage } from "./utils";

const applyFilters = (filters, updatedState) => {
  if (filters && updatedState) {
    updatedState.usedFilters = {...getDefaultsOfFilters()};
    Object.keys(updatedState.usedFilters).forEach(key => {
      if (key in filters && filters[key]) {
        if (key === ISSUE_FILTERS_TYPES.DAYS_BACK_FROM) {
          updatedState.usedFilters[key] = {...filters[key]};
        } else {
          updatedState.usedFilters[key] = filters[key];
        }
      }
    });
    updatedState.appliedUsedFiltersJson = JSON.stringify(updatedState.usedFilters);
  }
  return updatedState;
};

const initialState = {
  originalDataJson: '[]',
  filteredData: [],
  additionalData: {},
  loaders: {
  },
  selectedItem: null,
  usedFilters: getDefaultsOfFilters(),
  appliedUsedFiltersJson: null,
};

export default function issueBrowserReducer(state = initialState, action) {
  switch (action.type) {
    case ISSUE_BROWSER_SET_LOADING: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.name]: action.loading
        }
      };
    }

    case ISSUE_BROWSER_GET_ADDITIONAL_DATA: {
      return {
        ...state,
        additionalData: action.data || initialState.additionalData
      };
    }

    case ISSUE_BROWSER_GET_BUG_REPORTS: {
      return produce(state, (draftState) => {
        if (action.startFilters) {
          draftState = applyFilters(action.startFilters, draftState);
        } else {
          draftState.appliedUsedFiltersJson = JSON.stringify(draftState.usedFilters);
          saveSettingsToStorage('filters', draftState.appliedUsedFiltersJson);
        }
        let reportsToFilter;
        if (action.data) {
          draftState.originalDataJson = JSON.stringify(action.data);
          reportsToFilter = action.data;
        } else {
          reportsToFilter = JSON.parse(draftState.originalDataJson);
        }
        draftState.filteredData = filterBugReports(reportsToFilter, draftState.usedFilters);
        if (draftState.selectedItem) {
          draftState.selectedItem = draftState.filteredData.find((item) => isEqualsBugReports(item, draftState.selectedItem)) || null;
        } else if (action.selectedItemId) {
          draftState.selectedItem = draftState.filteredData.find((item) => isEqualsBugReports(item, {ReportedBugID: action.selectedItemId})) || null;
          if (draftState.selectedItem) {
            draftState.selectedItem = {...draftState.selectedItem, needToScroll: true};
          }
        }
      });
    }

    case ISSUE_BROWSER_CHANGE_SELECTED_ITEM: {
      return produce(state, (draftState) => {
        draftState.selectedItem = action.item;
      });
    }

    case ISSUE_BROWSER_CHANGE_FILTER_VALUE: {
      let value = action.value;

      if (Array.isArray(value) && !value.length) {
        value = initialState.usedFilters[action.name];
      }

      return produce(state, (draftState) => {
        draftState.usedFilters[action.name] = value;
      });
    }

    case ISSUE_BROWSER_UPDATE_BUG_REPORT: {
      return produce(state, (draftState) => {
        const originalData = JSON.parse(draftState.originalDataJson);
        const reportIndex = originalData.findIndex((item) => item.ReportedBugID === action.reportId);
        if (reportIndex >= 0) {
          originalData[reportIndex] = {...originalData[reportIndex], ...action.data};
          draftState.originalDataJson = JSON.stringify(originalData);
          draftState.filteredData = filterBugReports(originalData, draftState.usedFilters);
          if (draftState.selectedItem) {
            draftState.selectedItem = draftState.filteredData.find((item) => isEqualsBugReports(item, draftState.selectedItem)) || null;
          }
        }
      });
    }

    default: {
      return state;
    }
  }
}
