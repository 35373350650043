import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import styled from 'styled-components';

export const LinkCell = ({row, onClick, className}) => (
  <td className={className} title={!row.clientName ? 'Link to WO without client info!' : undefined}>
    <Button
      bsStyle="default"
      onClick={() => onClick(row)}
    >
      Link To WO
      {!row.clientName && <Info className="fas fa-exclamation-circle"/>}
    </Button>
  </td>
);

const Info = styled.i`
  margin-left: 3px;
  color: red;
  font-size: 15px;
`;

LinkCell.propTypes = {
  row: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string
};
