import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button} from 'react-bootstrap';
import Filters from '../../../../components/filters/Filters';
import {
  LAST_SEGMENT_ONLY_FILTER_NAME, NAME_FILTER_NAME, SEARCH_IN_FILTER_NAME, DISPLAY_CATEGORY_ROOTS,
  DISPLAY_CATEGORY_INIT_CONFIRM_DELETION, SEARCH_IN_OPTION_CURRENT_FOLDER
} from '../../constants';
import styled from 'styled-components';
import RndModal from '../../../../components/modals/RndModal';

class SearchModal extends Component {
  state = {
    usedFilters: {...this.props.usedFilters}
  };

  componentWillUnmount() {
    this.props.onClose();
  }

  handleSearch = () => {
    const {usedFilters} = this.state;
    const {onSearch} = this.props;
    onSearch(usedFilters);
  };

  handleChange = (id, value) => {
    const usedFilters = {...this.state.usedFilters};
    if (id === LAST_SEGMENT_ONLY_FILTER_NAME && value && usedFilters[NAME_FILTER_NAME]) {
      usedFilters[NAME_FILTER_NAME] = usedFilters[NAME_FILTER_NAME].replace(/[\\]/g, '');
    } else if (id === NAME_FILTER_NAME && value && usedFilters[LAST_SEGMENT_ONLY_FILTER_NAME]) {
      value = value.replace(/[\\]/g, '');
    }
    usedFilters[id] = value;
    this.setState({
      usedFilters
    });
  };

  prepareAllFilters = () => {
    const {allFilters, isSelectedMultipleItems, displayCategory} = this.props;
    const preparedFilters = [...allFilters];
    const nameFilterIndex = preparedFilters.findIndex(filter => filter.name === NAME_FILTER_NAME);
    if (nameFilterIndex >= 0) {
      const isCheckedFullPathNames = !Boolean(this.state.usedFilters[LAST_SEGMENT_ONLY_FILTER_NAME]);
      const overlay = isCheckedFullPathNames ? (
          <div>
            <b>?</b> means any character
            <br/>
            <b>*</b> means any sequence of characters
          </div>
        ) : (
          <div>
            <b>?</b> means any character
            <br/>
            <b>*</b> means any sequence of characters
            <br/>
            <br/>
            don&apos;t enter the character <b>\</b> to search by path pattern
          </div>
        );
      const tooltip = isCheckedFullPathNames ?
        '? means any character, * means any sequence of characters' :
        '? means any character, * means any sequence of characters\n\ndon\'t enter the character "\\" to search by path pattern';
      preparedFilters[nameFilterIndex] = {...preparedFilters[nameFilterIndex], overlay, tooltip};
    }

    if (!isSelectedMultipleItems) {
      const searchInFilterIndex = preparedFilters.findIndex(filter => filter.name === SEARCH_IN_FILTER_NAME);
      if (searchInFilterIndex >= 0) {
        preparedFilters.splice(searchInFilterIndex, 1);
      }
    } else {
      preparedFilters.find(filter => filter.name === SEARCH_IN_FILTER_NAME).values[0].name =
        [DISPLAY_CATEGORY_ROOTS, DISPLAY_CATEGORY_INIT_CONFIRM_DELETION].includes(displayCategory) ? 'All' : 'Current Folder';
    }

    return preparedFilters;
  };

  render() {
    const {onClose} = this.props;
    const {usedFilters} = this.state;
    const preparedFilters = this.prepareAllFilters();
    const searchInFilter = preparedFilters.find(filter => filter.name === SEARCH_IN_FILTER_NAME);
    const searchInFilterValue = searchInFilter && searchInFilter.values[0].name !== 'All' && !usedFilters[SEARCH_IN_FILTER_NAME] ?
      {[SEARCH_IN_FILTER_NAME]: SEARCH_IN_OPTION_CURRENT_FOLDER} : {};
    return (
      <RndModal
        className="search-modal"
        show={true}
        onHide={onClose}
        backdrop="static"
        enforceFocus={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>Advanced Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContainerStyled className="container-fluid">
            <Filters
              loading={false}
              filters={preparedFilters}
              usedFilters={{...usedFilters, ...searchInFilterValue}}
              onChange={this.handleChange}
              bodyMd={12}
              headerVisible={false}
            />
          </ModalContainerStyled>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.handleSearch}>Search</Button>
          <Button bsStyle="default" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
      </RndModal>
    );
  }
}

const ModalContainerStyled = styled.div`
  padding: 0;

  > div {
    margin: 0;
    box-shadow: none;
  }

  .filters-block {
    margin: 0;
  }

  .container {
    padding: 0;

    .block {
      padding: 15px;
    }
  }
`;

SearchModal.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  allFilters: PropTypes.array.isRequired,
  usedFilters: PropTypes.object.isRequired,
  isSelectedMultipleItems: PropTypes.bool.isRequired,
  displayCategory: PropTypes.string.isRequired
};

export default SearchModal;
