import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, FormGroup, ControlLabel} from 'react-bootstrap';
import {generateMd5Checksum, generateSha1Checksum, showChecksumModal} from '../../actions';
import TextEllipsisAdvanced from '../../../../components/TextEllipsis/TextEllipsisAdvanced';
import {getValueForOS} from '../../utils';
import RndModal from '../../../../components/modals/RndModal';

class GenerateChecksum extends Component {

  handleGenerateMD5 = () => {
    const {dispatch} = this.props;
    dispatch(generateMd5Checksum());
  };

  handleGenerateSHA1 = () => {
    const {dispatch} = this.props;
    dispatch(generateSha1Checksum());
  };

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(showChecksumModal(false));
  };

  componentWillUnmount() {
    this.handleClose();
  }

  render() {
    const {file, md5ChecksumOpening, sha1ChecksumOpening, checksumOnlyType, isGeneratingMD5, isGeneratingSHA1} = this.props;
    let isMd5View = (checksumOnlyType && md5ChecksumOpening) || !checksumOnlyType;
    let isSHA1View = (checksumOnlyType && sha1ChecksumOpening) || !checksumOnlyType;
    return (
      <RndModal
        show={true}
        onHide={this.handleClose}
        backdrop="static"
        enforceFocus={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>Generate Checksum</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div style={{color: 'blue', fontWeight: 'bold', marginBottom: '28px'}}>
              <TextEllipsisAdvanced className="value" data={getValueForOS(file, 'key')} noCopyCtrl/>
            </div>
            {isMd5View &&
              <FormGroup>
                <ControlLabel>MD5 Checksum</ControlLabel>
                <div>{file.md5Checksum}</div>
              </FormGroup>
            }
          </div>
        </Modal.Body>
        {isSHA1View &&
          <Modal.Body>
            <div className="container-fluid">
              <FormGroup>
                <ControlLabel>SHA1 Checksum</ControlLabel>
                <div>{file.sha1Checksum}</div>
              </FormGroup>
            </div>
          </Modal.Body>
        }
        <Modal.Footer>
          {isMd5View &&
            <Button bsStyle="primary" onClick={this.handleGenerateMD5} disabled={isGeneratingMD5}>
              {isGeneratingMD5 ? <i className="fa fa-spinner fa-spin"/> : null}{checksumOnlyType ? 'Generate' : 'Regenerate'} MD5
            </Button>
          }
          {isSHA1View &&
            <Button bsStyle="primary" onClick={this.handleGenerateSHA1} disabled={isGeneratingSHA1}>
              {isGeneratingSHA1 ? <i className="fa fa-spinner fa-spin"/> : null}{checksumOnlyType ? 'Generate' : 'Regenerate'} SHA1
            </Button>
          }
          <Button bsStyle="default" onClick={this.handleClose} disabled={isGeneratingMD5 || isGeneratingSHA1}>Cancel</Button>
        </Modal.Footer>
      </RndModal>
    );
  }
}

GenerateChecksum.propTypes = {
  dispatch: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
  isGeneratingMD5: PropTypes.bool.isRequired,
  isGeneratingSHA1: PropTypes.bool.isRequired
};

export default GenerateChecksum;
