import React from 'react';
import PropTypes from 'prop-types';

export const CheckboxHeaderCell = ({isChecked, onChange}) => (
  <th className="checkbox-cell">
    <input type="checkbox" checked={isChecked()} onChange={event => onChange(event.target.checked)}/>
  </th>
);
CheckboxHeaderCell.propTypes = {
  isChecked: PropTypes.any,
  onChange: PropTypes.func
};
