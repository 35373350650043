import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormGroup, FormControl, ControlLabel} from 'react-bootstrap';
import styled from 'styled-components';
import {PathFieldStyled, CheckboxStyled} from '../ModalStyled';

export const CONTINUE_COMPLETE_DOWNLOAD = 1;
const CONTINUE_LINK_WO = 2;
export const CONTINUE_SINGLE_LINK_WO = 3;

class InBurbankStep extends Component {

  renderCheckboxSingleLink() {
    const {data: {singleLinking}, onChange} = this.props;
    return (
      <PathFieldStyled color="black">
        <div className="value">
          <CheckboxStyled className="checkbox">
            <FormControl
              id="checkbox-single-link"
              name="checkbox-single-link"
              type="checkbox"
              checked={singleLinking}
              onChange={event => onChange({searchWOsForContent: false, singleLinking: event.target.checked})}
            />
            <ControlLabel htmlFor="checkbox-single-link">
              Single Link WO
            </ControlLabel>
          </CheckboxStyled>
        </div>
      </PathFieldStyled>
    );
  }

  renderCheckboxSearchWOs(inRadioButtonsGroup, label, disabled) {
    const {data: {searchWOsForContent}, onChange} = this.props;
    return (
      <PathFieldStyled color="black">
        <div className="value">
          <CheckboxStyled className={`checkbox${inRadioButtonsGroup ? ' in-rb-group' : ''}`}>
            <FormControl
              id="checkbox-linked-in-burbank"
              name="checkbox-linked-in-burbank"
              type="checkbox"
              checked={searchWOsForContent}
              onChange={event => onChange({searchWOsForContent: event.target.checked, singleLinking: false})}
              disabled={disabled}
            />
            <ControlLabel htmlFor="checkbox-linked-in-burbank">
              {label}
            </ControlLabel>
          </CheckboxStyled>
        </div>
      </PathFieldStyled>
    );
  }

  renderRadioButton(label, value) {
    const {data: {continueVariant}, onChange} = this.props;
    const id = `link-continue-rb-${value}`;
    return (
      <RadioButtonStyled>
        <input
          id={id}
          name="link-continue"
          type="radio"
          value={value}
          checked={value === continueVariant}
          onChange={() => onChange({searchWOsForContent: false, continueVariant: value})}
        />
        <label htmlFor={id}>
          {label}
        </label>
      </RadioButtonStyled>
    );
  }

  renderCompleteDownloadAndLinkWoRadioBtns() {
    const {continueVariant} = this.props.data;
    return (
      <RadioButtonsGroupStyled>
        {this.renderRadioButton('Complete Download for Multiple WO-s', CONTINUE_COMPLETE_DOWNLOAD)}
        {this.renderRadioButton('Single Link WO', CONTINUE_SINGLE_LINK_WO)}
        {this.renderRadioButton('Link WO', CONTINUE_LINK_WO)}
        {this.renderCheckboxSearchWOs(true, 'This WO was already linked in Burbank', continueVariant !== CONTINUE_LINK_WO)}
      </RadioButtonsGroupStyled>
    );
  }

  render() {
    const {isRequiredContinueVariant, data: {singleLinking}} = this.props;
    return (
      <ContainerStyled>
        {
          isRequiredContinueVariant ?
            this.renderCompleteDownloadAndLinkWoRadioBtns() :
              <>
                {this.renderCheckboxSingleLink()}
                {this.renderCheckboxSearchWOs(false, 'Link WO already linked in Burbank', singleLinking)}
              </>
        }
      </ContainerStyled>
    );
  }
}

const ContainerStyled = styled.div`
  > div {
    margin: 0;
  }
`;

const RadioButtonsGroupStyled = styled.div`
`;

const RadioButtonStyled = styled(FormGroup)`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  label {
    margin: 0 0 0 7px;
  }

  input {
    margin: 0;
  }
`;

InBurbankStep.propTypes = {
  isRequiredContinueVariant: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default InBurbankStep;
