import $ from 'jquery';
import config from '../../../config';
import {showError, showSuccess, showWarning} from '../../layouts/actions';
import {getErrorMessage} from '../../utils';
import {
  DELIVERY_PAGE__RENAME_ONLY,
  DELIVERY_PAGE__EDIT,
  FILE_EXTENSION__ALL_OPTION,
} from './constants';
import {
  makeActionAjax,
} from '../StorageManager/actions';
import _ from 'lodash';
// import arrayToTree from 'array-to-tree';

export const DELIVERY_MNG_CLEAR = 'DELIVERY_MNG_CLEAR';
export const DELIVERY_MNG_IS_LOADING = 'DELIVERY_MNG_IS_LOADING';
export const DELIVERY_MNG_FILES_CHANGE = 'DELIVERY_MNG_FILES_CHANGE';
export const DELIVERY_MNG_FILES_RECEIVE = 'DELIVERY_MNG_FILES_RECEIVE';
export const DELIVERY_MNG_SELECTING_START = 'DELIVERY_MNG_SELECTING_START';
export const DELIVERY_MNG_RENAMING_START = 'DELIVERY_MNG_RENAMING_START';
export const DELIVERY_MNG_RENAMING_END = 'DELIVERY_MNG_RENAMING_END';
export const DELIVERY_MNG_APPLY_SAVED_DATA = 'DELIVERY_MNG_APPLY_SAVED_DATA';
export const DELIVERY_MNG_FILE_EXT_SELECT = 'DELIVERY_MNG_FILE_EXT_SELECT';
export const DELIVERY_MNG_IS_LIST_VIEW = 'DELIVERY_MNG_IS_LIST_VIEW';
export const DELIVERY_MNG_SET_SEARCH_STR = 'DELIVERY_MNG_SET_SEARCH_STR';
export const DELIVERY_MNG_SELECT_WO_TAB = 'DELIVERY_MNG_SELECT_WO_TAB';
export const DELIVERY_MNG_OPEN_INSTRUCTIONS = 'DELIVERY_MNG_OPEN_INSTRUCTIONS';

export const clearAllDeliveryMng = () => ({type: DELIVERY_MNG_CLEAR});

export const selectFileExtensions = fileExtensions => ({type: DELIVERY_MNG_FILE_EXT_SELECT, fileExtensions});

const toggleLoading = loading => ({type: DELIVERY_MNG_IS_LOADING, loading});

export const selectWoTab = tab => ({type: DELIVERY_MNG_SELECT_WO_TAB, tab});

export const openInstructions = (key, text) => ({type: DELIVERY_MNG_OPEN_INSTRUCTIONS, key, text});

export const toggleListView = isListView => ({type: DELIVERY_MNG_IS_LIST_VIEW, isListView});

export const changeSearchString = searchString => ({type: DELIVERY_MNG_SET_SEARCH_STR, searchString});

export const startSelecting = () => ({type: DELIVERY_MNG_SELECTING_START});

export const startRenaming = () => ({type: DELIVERY_MNG_RENAMING_START});

const endRenaming = () => ({type: DELIVERY_MNG_RENAMING_END});

export const applySavedData = () => ({type: DELIVERY_MNG_APPLY_SAVED_DATA});

export const changeFiles = (reviewDeliveryData, categoryDataForTreeView) =>
  ({type: DELIVERY_MNG_FILES_CHANGE, reviewDeliveryData, categoryDataForTreeView});

const prepareCategoryData = categoryData => {
  let separator;
  return categoryData.map(cat => {
    const {category, woDataList} = cat;
    const preparedWoDataList = (woDataList || []).map(wo => {
      const {woMemfis, categoryID, rootFileName, description, contentDataList} = wo;
      const tempList = [];
      const preparedContentDataList = (contentDataList || []).map(content => {
        const {fileName, contentID} = content;
        if (!separator) {
          separator = fileName.indexOf('\\') !== -1 ? '\\' : '/';
        }
        const fileNameWithoutRoot = fileName.replace(rootFileName, '').replace(/^([\\/])?/, '');
        const tempNameArr = fileNameWithoutRoot.split(/[\\/]/);
        const parsedName = tempNameArr.pop();
        const parentFolders = tempNameArr;
        parentFolders.forEach((k, i) => {
          tempList.push({
            isDirectory: true,
            parsedName: parentFolders[i],
            depth: i,
            categoryID,
            woMemfis,
            isSelected: false,
            isExpanded: false,
            _id: (i > 0 ? parentFolders.slice(0, i).join(separator) + separator : '') + k,
            _parent: parentFolders.slice(0, i).join(separator)
          });
        });
        return {
          ...content,
          isDirectory: false,
          parsedName,
          fileNameWithoutRoot,
          depth: parentFolders.length,
          parentFolders,
          _id: `${categoryID}---${woMemfis}---${contentID}`,
          _parent: parentFolders.join(separator)
        };
      });
      tempList.push(...preparedContentDataList);
//      console.log('tempList',tempList);
      let sortedUniqTree;
      try {
        // const tree = arrayToTree(tempList, {
        //   childrenProperty : '_children',
        //   parentProperty: '_parent',
        //   customID: '_id'
        // });
        const arrToTree = items => {
          if (items && items.length > 0) {
            const data = [];
            const map = {};
            items.forEach((item) => {
              const id = item._id;
              if (!map.hasOwnProperty(id)) {
                map[id] = {
                  ...item,
                  _children: [],
                };
              }
            });
            for (const id in map) {
              if (map.hasOwnProperty(id)) {
                let mappedElem = [];
                mappedElem = map[id];
                if (
                  mappedElem._parent &&
                  typeof map[mappedElem._parent] !== "undefined"
                ) {
                  map[mappedElem._parent]._children.push(mappedElem);
                } else {
                  data.push(mappedElem);
                }
              }
            }
            return data;
          }
          return [];
        }
        const tree = arrToTree(tempList);
        const sortTreeItems = (a, b) => {
          if (a.isDirectory !== b.isDirectory) {
            return a.isDirectory ? -1 : 1;
          }
          const compRes = a.parsedName.localeCompare(b.parsedName);
          if (a.isDirectory || compRes !== 0) {
            return compRes;
          }
          return (b.modified || 0) - (a.modified || 0);
        };
        sortedUniqTree = _.uniqWith(tree, _.isEqual).sort(sortTreeItems);
        const uniqChildren = (item) => {
          if (item.isDirectory) {
            item.isSelected = item._children.every(child => !!child.isSelected);
            item._children = _.uniqWith(item._children, _.isEqual).sort(sortTreeItems);
            item._children.forEach(uniqChildren);
          }
        };
        sortedUniqTree.forEach(uniqChildren);
      } catch(ex) {
        console.error('Failed building of tree DIR/files', ex);
      }
//      console.log('sortedUniqTree',sortedUniqTree);
      return {woMemfis, rootFileName, description, contentDataList: sortedUniqTree || preparedContentDataList};
    });
    return {category, woDataList: preparedWoDataList};
  });
};

const getOperationName = operationType => {
  if (operationType === DELIVERY_PAGE__RENAME_ONLY) {
    return 'delivery renaming';
  }
  return operationType === DELIVERY_PAGE__EDIT ? 'editing' : 'approval';
};

export const getFiles = (history, operationType, actionId, currentStep, paramsToGetFiles) => dispatch => {
  dispatch(toggleLoading(true));
  const urlPath = operationType === DELIVERY_PAGE__RENAME_ONLY ? `rename_data` : `${operationType}/${actionId}`;
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/delivery/${urlPath}`,
    data: paramsToGetFiles
  })
  .done(res => {
    const {warning, reviewDeliveryData, macrosFields} = res;
    if (warning) {
      dispatch(showWarning(warning));
    }

    const {categoryData} = reviewDeliveryData;
    const categoryDataForTreeView = prepareCategoryData(categoryData || []);

    const fileExtensionsValues = [], workOrders = {};
    (categoryData || []).forEach(cat => {
      (cat.woDataList || []).forEach(wo => {
        const {woMemfis, categoryID} = wo;
        workOrders[woMemfis] = [...workOrders[woMemfis] || [categoryID]];
        (wo.contentDataList || []).forEach(content => {
          const ext = (content.fileName || '').toLowerCase().split('.').pop();
          if (ext && !fileExtensionsValues.includes(`*.${ext}`)) {
            fileExtensionsValues.push(`*.${ext}`);
          }
        });
      });
    });
    const fileExtensions = fileExtensionsValues.sort((a, b) => a.localeCompare(b)).map(ext => ({label: ext, value : ext}));

    dispatch({
      type: DELIVERY_MNG_FILES_RECEIVE,
      reviewDeliveryData,
      categoryDataForTreeView,
      currentStep,
      macrosFields,
      workOrders,
      fileExtensions: [FILE_EXTENSION__ALL_OPTION, ...fileExtensions],
      selectedFileExtensions: fileExtensions.length ? fileExtensions : [FILE_EXTENSION__ALL_OPTION]
    });
  })
  .fail(error => {
    dispatch(toggleLoading(false));
    dispatch(showError(`Could not get files for ${getOperationName(operationType)}.  ${getErrorMessage(error)}`));
  });
};

const deliveryRename = (renameData, {actionRequestParams, successCallback, errorCallback}) => (dispatch, getState) => {
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      ...actionRequestParams,
      parameters: {
        ...actionRequestParams.parameters,
        RENAME_DATA: renameData
      }
    })
  })
  .done(({ActionID}) => {
    setTimeout(function request() {
      makeActionAjax(ActionID).done((response) => {
        const {actionStatus} = response.action;
        if (actionStatus === 'DONE') {
          dispatch(toggleLoading(false));
          successCallback && successCallback();
        } else if(['FAILED', 'EXPIRED', 'REJECTED', 'INCOMPLETE', 'DISABLED_BY_FAILURE'].includes(actionStatus)) {
          dispatch(toggleLoading(false));
          dispatch(showError(`We had trouble renaming.  Action status: ${actionStatus}`));
        } else {
          setTimeout(request, 3000);
        }
      })
      .fail(error => {
        dispatch(toggleLoading(false));
        dispatch(showError(`We had trouble renaming.  ${getErrorMessage(error)}`));
      });
    }, 3000);
  })
  .fail(error => {
    dispatch(toggleLoading(false));
    errorCallback && errorCallback();
    dispatch(showError(`We had trouble renaming.  ${getErrorMessage(error)}`));
  });
};

export const saveFiles = (operationType, actionId, paramsToSaveFiles) => (dispatch, getState) => {
  const {reviewDeliveryData} = getState().deliveryManager;
  const preparedData = JSON.stringify({reviewDeliveryData, approve: false});
  dispatch(toggleLoading(true));
  if (operationType === DELIVERY_PAGE__RENAME_ONLY) {
    return dispatch(deliveryRename(preparedData, paramsToSaveFiles));
  }
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/delivery/${operationType}/${actionId}`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: preparedData
  })
  .done(res => {
    const {reviewDeliveryData} = res;
    const {categoryData} = reviewDeliveryData;
    const categoryDataForTreeView = prepareCategoryData(categoryData || []);
    dispatch({type: DELIVERY_MNG_FILES_RECEIVE, reviewDeliveryData, categoryDataForTreeView});
  })
  .fail(error => {
    dispatch(toggleLoading(false));
    dispatch(showError(`Could not save files for ${getOperationName(operationType)}.  ${getErrorMessage(error)}`));
  });
};

export const approveFiles = (history, operationType, actionId) => (dispatch, getState) => {
  const {reviewDeliveryData} = getState().deliveryManager;
  dispatch(toggleLoading(true));
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/delivery/${operationType}/${actionId}`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({reviewDeliveryData, approve: true})
  })
  .done(() => {
    dispatch(endRenaming());
    dispatch(toggleLoading(false));
    dispatch(showSuccess(operationType === DELIVERY_PAGE__EDIT ? 'Sent for approval!' : 'Approved successfully!'));
    history.push('/download');
  })
  .fail(error => {
    dispatch(toggleLoading(false));
    dispatch(showError(`Could not ${operationType === DELIVERY_PAGE__EDIT ? 'send for approval' : 'approve'}.  ${getErrorMessage(error)}`));
  });
};
