import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Modal, Button, FormGroup, Radio, ControlLabel} from 'react-bootstrap';
import Select from 'react-select';
import {unlinkToMemfisWO, showUnlinkModal, getAssociatedFsRootsForWO} from '../../actions';
import RndModal from '../../../../components/modals/RndModal';

class UnlinkModal extends Component {
  state = {
    keepBarkoded: null,
    selectedRoots: [],
    availableRoots: [],
  }

  handleChangeKeepBarcoded = ({target: {value}}) => {
    this.setState({keepBarkoded: !!value});
  };

  handleSelectRoot = (v) => {
    console.log(v);
    this.setState({selectedRoots: v});
  };

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(showUnlinkModal(false));
  };

  componentDidMount() {
    const {dispatch, availableFsRoots} = this.props;
    dispatch(getAssociatedFsRootsForWO()).then(
      rootIDs => {
        if (Array.isArray(rootIDs) && rootIDs.length) {
          this.setState({availableRoots: availableFsRoots.filter(r => rootIDs.includes(r.value)).sort((a, b) => a.label.localeCompare(b.label))});
        }
      }
    );
  }

  render() {
    const {dispatch} = this.props;
    const {keepBarkoded, selectedRoots, availableRoots} = this.state;
    const needToSelectRoots = !!availableRoots.length;
    return (
      <RndModal
        className="media-info-modal"
        bsSize="large"
        show={true}
        onHide={this.handleClose}
        backdrop="static"
        enforceFocus={false}
        >
        <Modal.Header>
          <Modal.Title>
            Unlink
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            {needToSelectRoots &&
              <SelectFormGroup>
                <ControlLabel>
                  FS Root
                </ControlLabel>
                <Select
                  multi
                  value={selectedRoots}
                  options={availableRoots}
                  onChange={this.handleSelectRoot}
                  placeholder="Select roots for unlink"
                />
              </SelectFormGroup>
            }
            <FormGroup>
              <Radio
                name="radio--proxy-copy"
                value={''}
                checked={keepBarkoded === false}
                onChange={this.handleChangeKeepBarcoded}
              >
                Remove barcodes and move away
              </Radio>
              <Radio
                name="radio--proxy-copy"
                value={1}
                checked={keepBarkoded === true}
                onChange={this.handleChangeKeepBarcoded}
              >
                Keep files barcoded and move to "do_not_use" folder next to WO
              </Radio>
            </FormGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            bsStyle="primary"
            onClick={() => {
              dispatch(unlinkToMemfisWO(keepBarkoded, needToSelectRoots ? selectedRoots.map(r => r.value) : undefined));
            }}
            disabled={keepBarkoded === null}
          >
              Unlink
          </Button>
          <Button bsStyle="default" onClick={this.handleClose}>Cancel</Button>
        </Modal.Footer>
      </RndModal>
    );
  }
}

const SelectFormGroup = styled(FormGroup)`
  display: flex;
  align-items: center;

  label {
    margin: 0;
    padding: 0 10px 0 0;
  }

  .Select {
    flex-grow: 1;
  }
`;

UnlinkModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  availableFsRoots: PropTypes.array.isRequired,
};

export default UnlinkModal;
