import styled, {css} from 'styled-components';
import {Nav} from 'react-bootstrap';

const Item = styled.div`
  z-index: 1;

  i {
    margin-right: 5px;
    cursor: pointer;

    &.fa-info-circle {
      margin-left: 5px;
    }

    &.fa-eye-slash {
      margin-left: 6px;
    }

    &.fa-minus-square, &.fa-plus-square, &.fa-info-circle, &.fa-eye-slash {
      color: #757575;
      &:hover {
        color: #282828;
      }
    }
  }
`;

export const StyledCategory = styled(Item)`
`;

export const StyledWorkOrder = styled(Item)`
  padding-left: 16px;
`;

export const Tree = styled(Item)`
  padding-left: 32px;
  &.only-files {
    padding-left: 0;
  }
`;

export const TreeItem = styled.div`
  ${props => props.paddingLeft && css`
    padding-left: ${props.paddingLeft}px !important;
  `}
`;

export const File = styled(Item)`
  position: relative;
  margin-bottom: 5px;
  padding-left: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &.only-files {
    padding-left: 0;
  }
`;

export const StyledFileItem = styled.div`
  position: relative;
  max-width: calc(100% - 20px);
  /*overflow: hidden;*/
  white-space: nowrap;

  &.for-renaming {
    /*padding-right: 5px;*/
    max-width: calc(100% - 25px);
  }

  .tooltip {
    margin-top: 0;
    .tooltip-inner {
      max-width: unset;
      text-align: left;
      word-break: break-all;
    }
  }

  .ellipse-value {
    white-space: nowrap;
    overflow: hidden;
  }
`;

const ICON_COLOR = {
  RED: 'red',
  GREEN: '#00c33c',
  BLUE: 'blue'
};

const ICON_COLOR_HOVER = {
  RED: '#ff4400',
  GREEN: '#01b338',
  BLUE: '#4444ea'
};

export const SelectingHeader = styled.div`
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  .col-files {
    padding: 0 15px;
    width: 50%;
    &.files-left {
      padding-right: 55px;
    }
    &.files-right {
      padding-left: 55px;
    }
  }
`;

export const SelectingMain = styled.div`
  padding: 0 15px;
  height: calc(100vh - 71px - 48px - 110px - 65px);
  display: flex;
  flex-direction: row;
  .col-files {
    padding: 0 15px 5px;
    width: calc((100% - 80px) / 2);
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    &.files-left {
      i {
        &.fa-check-square {
          color: ${ICON_COLOR.BLUE};
        }
        &.fa-check-square, &.fa-square {
          &:hover {
            color: ${ICON_COLOR_HOVER.BLUE};
          }
        }
      }
    }
    &.files-right {
      i {
        &.fa-check-square {
          color: ${ICON_COLOR.GREEN};
        }
        &.fa-check-square, &.fa-square {
          &:hover {
            color: ${ICON_COLOR_HOVER.GREEN};
          }
        }
      }
    }
  }
  .col-buttons {
    padding-top: 30px;
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .btn {
      margin: 0;
      :not(:disabled) {
        color: ${ICON_COLOR.BLUE};
        &:hover {
          color: ${ICON_COLOR_HOVER.BLUE};
        }
      }
      + .btn {
        margin-top: 15px;
        :not(:disabled) {
          color: ${ICON_COLOR.GREEN};
          &:hover {
            color: ${ICON_COLOR_HOVER.GREEN};
          }
        }
      }
    }
  }
`;

export const RenamingTable = styled.div`
  margin: 0 30px;
  width: calc(100% - 60px);
  max-width: calc(100% - 60px);
  max-height: calc(100vh - 71px - 65px);
  overflow-y: auto;
  overflow-x: hidden;

  table {
    margin: 0;
    width: 100%;
    max-width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    color: #282828;

    .instructions {
      line-height: 20px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      > div {
        :first-child {
          margin-right: 6px;
          font-weight: bold;
          white-space: nowrap;
        }
        :last-child {
          font-weight: normal;
          white-space: pre-line;
          word-break: break-word;
          max-width: calc(100vw - 60px - 22px - 34px - 150px);
          width: calc(40vw - 150px);
          height: 40px;
          min-height: 40px;
          resize: both;
          overflow-y: auto;
          overflow-x: hidden;
          &.wo-desc {
            max-width: calc(100vw - 60px - 22px - 34px - 95px);
            width: calc(40vw - 95px);
            height: fit-content;
            min-height: 20px;
          }
        }
      }
    }

    thead {
      &:after {
        content: "";
        display: block;
        height: 1em;
        width: 100%;
        background: transparent;
      }
      tr {
        th {
          border: none;
          text-align: left;
          vertical-align: top;
          position: sticky;
          top: 0;
          background-color: #fff;
          z-index: 2;
          &.col-title {
            font-weight: bold;
            font-size: 21px;
            line-height: 23px;
            text-align: center;
            padding: 5px 10px 21px;
          }
          &.col-instr {
            top: 49px;
            padding: 4px 10px;
            border: 1.5px solid #F7C325;
            background-color: #FBE192;
            box-shadow: 0 0.5em 0 0 #fff;
          }
        }
      }
    }
    tbody {
      &:before {
        content: "";
        display: block;
        height: .5em;
        width: 100%;
        background: transparent;
      }
      tr {
        td {
          padding: 4px 10px;
          border: 1.5px solid #C3CFD9;
          border-top: none;
          text-align: left;
          vertical-align: top;
          z-index: 1;
          &.col-title {
            font-weight: bold;
            padding: 5px 10px
            border: 1.5px solid #E8833A;
            background-color: #F3C19D;
          }
          &.col-instr {
            border: 1.5px solid #E8833A;
            border-top: none;
            background-color: #F3C19D;
          }
          &.col-action {
            padding-left: 3px;
            padding-right: 4px;
            width: 24px;
            text-align: center;
            i {
              color: #757575;
              &:hover {
                color: #282828;
                cursor: pointer;
              }
              &.disabled {
                pointer-events: none;
                opacity: 0.65;
              }
              &.v-hidden {
                visibility: hidden;
              }
            }
          }
          &.col-filename {
            width: calc((100vw - 60px - (3 * 24px) - 17px) / 2);
            word-break: break-all;
            position: relative;
          }
          &:not(:last-child) {
            border-right: none;
          }
        }
      }
    }
  }
`;

export const H2 = styled.h2`
  display: block;
  font-weight: bold;
  font-size: 21px;
  line-height: 23px;
  text-align: center;
  margin: 5px 0 21px;
`;

export const H4 = styled.h4`
  display: block;
  font-weight: bold;
  font-size: 16.8px;
  line-height: 18px;
  text-align: center;
  margin: 0 0 15px;
  padding-bottom: 1.8px;
  white-space: nowrap;
`;

export const StyledNav = styled(Nav)`
  margin: 0 0 15px;
  padding: 0 15px;
`;

export const MenuRow = styled.div`
  margin: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const MenuCol = styled.div`
  flex: 2;
  & + & {
    margin-left: 15px;
  }
  &.col-flex-1 {
    flex: 1;
    max-width: fit-content;
    label {
      display: block;
    }
  }
  &.list-view {
    .btn.unhide-all {
      visibility: hidden;
    }
  }
  .btn {
    outline: 0 !important;
  }
`;

export const CommonInstructions = styled.div`
  width: fit-content;
  color: #00a3ff;
  &:hover {
    color: #007eff;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const DestinationBlock = styled.div`
  padding-bottom: 10px;
  span {
    padding-right: 5px;
    text-decoration: underline;
  }
`;
