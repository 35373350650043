import React, {Component} from 'react';
import {Button, Checkbox, Modal} from "react-bootstrap";
import {
  checkPseFileType,
  executePSEanalysisAction,
  showPseModal,
  STORAGE_MNG_PSE_HDR_FILE_TYPE
} from "../../actions";
import PropTypes from "prop-types";
import RndModal from "../../../../components/modals/RndModal";
import Select from "react-select";
import {parseFileExtension, parseName} from "../../utils";
import styled from "styled-components";
import {PathFieldStyled} from "./ModalStyled";
import AlertModal from "./AlertModal";

const PseTypes = [
  {label: 'SDR', value: 'sdr'},
  {label: 'HDR', value: 'hdr'},
  {label: 'DOLBY VISION', value: 'dolby'}
];

class PseModal extends Component {
  state = {
    isDefaultSetup: false,
    selectedPseType: '',
    companionFile: 0,
    showError: null,
    companionFileDisabled: false,
    woMemfis: null,
    isSendToQOD: false
  }

  handleGeneratePse = () => {
    const { companionFile, selectedPseType, woMemfis, isSendToQOD } = this.state;
    const {dispatch} = this.props;
    dispatch(executePSEanalysisAction(selectedPseType, companionFile, woMemfis, isSendToQOD));
    this.handleClose();
  }

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(showPseModal(false));
    dispatch({ type: STORAGE_MNG_PSE_HDR_FILE_TYPE, data: null });
  };

  handleCloseError = (showError) => {
    this.setState({
      showError: null
    });
  }

  componentWillMount() {
    const {dispatch} = this.props;
    dispatch(checkPseFileType());
  }

  componentWillUnmount() {
    this.handleClose();
  }

  showErrorModal() {
    const { dispatch, storageManager } = this.props;

    return (
      <>
        <AlertModal
          dispatch={dispatch}
          storageManager={storageManager}
          closeModal={this.handleCloseError}
          type={"error"}
          message={"Xml file is missing"}
        />
      </>
    );
  }

  checkPseType(selectedPseType, companionFile, onlyVideoFile, isPseHdrFileType, viewXmlFiles) {
    if (selectedPseType === '') {
      if (isPseHdrFileType) {
        const xml = viewXmlFiles.filter(file => file.label.toLowerCase() === onlyVideoFile + ".xml");
        if (xml && xml.length > 0) {
          this.setState({
            companionFile: xml[0].value,
            selectedPseType: 'dolby',
            companionFileDisabled: true
          });
        } else {
          this.setState({
            selectedPseType: 'hdr',
            companionFile: 0,
            companionFileDisabled: false
          });
        }
      } else {
        this.setState({
          selectedPseType: 'sdr',
          companionFile: 0,
          companionFileDisabled: false
        });
      }
    } else if (selectedPseType === 'dolby' && companionFile === 0 && !isPseHdrFileType) {
      const xml = viewXmlFiles.filter(file => file.label.toLowerCase() === onlyVideoFile + ".xml");
      if (xml && xml.length > 0) {
        if (selectedPseType !== 'dolby' || companionFile !== xml[0].value) {
          this.setState({
            companionFile: xml[0].value,
            selectedPseType: 'dolby',
            companionFileDisabled: true
          });
        }
      } else if (viewXmlFiles.length > 0) {
        if (selectedPseType !== 'dolby' || companionFile !== 0) {
          this.setState({
            selectedPseType: 'dolby',
            companionFile: 0,
            companionFileDisabled: false
          });
        }
      } else {
        this.setState({
          selectedPseType: 'hdr',
          companionFile: 0,
          companionFileDisabled: false
        });
      }
    } else if (selectedPseType === 'dolby' && companionFile === 0 && isPseHdrFileType) {
      const xml = viewXmlFiles.filter(file => file.label.toLowerCase() === onlyVideoFile + ".xml");
      if (xml && xml.length > 0) {
        if (selectedPseType !== 'dolby' || companionFile !== xml[0].value) {
          this.setState({
            companionFile: xml[0].value,
            selectedPseType: 'dolby',
            companionFileDisabled: true
          });
        }
      }
    }
  }

  renderCompanionFiles() {
    const { companionFile, selectedPseType, showError, companionFileDisabled, isDefaultSetup } = this.state;

    const { storageManager } = this.props;
    const { checkedItems, displayedItems, isPseHdrFileType } = storageManager;

    if (isPseHdrFileType === null && selectedPseType === '' && !isDefaultSetup) {
      this.setState({
        isDefaultSetup: true,
        showError: false,
        selectedPseType: 'sdr',
        companionFile: 0,
        companionFileDisabled: false
      });
    }

    if (isDefaultSetup && isPseHdrFileType && selectedPseType !== null) {
      this.setState({
        isDefaultSetup: false,
        showError: false,
        selectedPseType: '',
        companionFile: 0,
        companionFileDisabled: false
      });
    }

    if (selectedPseType === 'sdr' || selectedPseType === 'hdr' || (isPseHdrFileType === null && selectedPseType !== 'dolby')) {
      return;
    }

    const videoFile = parseName(checkedItems[0].key).toLowerCase();
    const videoFileExt = parseFileExtension(videoFile);
    const onlyVideoFile = videoFile.replace('.' + videoFileExt, '');

    let xmlFiles = displayedItems.filter(file => !file.isDirectory && file.key.toLowerCase().endsWith(".xml"));
    if (xmlFiles.length === 0) {
      xmlFiles = this.findXmlFile(checkedItems[0], displayedItems)
    }
    const viewXmlFiles = xmlFiles.map(file => ({value: file.contentID, label: parseName(file.key)}));

    if (selectedPseType === 'dolby' && viewXmlFiles.length === 0 && showError === null) {
      this.setState({
        showError: true,
        selectedPseType: 'hdr',
        companionFile: 0,
        companionFileDisabled: false
      });
    }

    if (((selectedPseType === 'dolby' || selectedPseType === '') && isPseHdrFileType !== null)
      || (selectedPseType === 'dolby' && companionFile === 0 && viewXmlFiles.length > 0 && isPseHdrFileType === null)) {
      this.checkPseType(selectedPseType, companionFile, onlyVideoFile, isPseHdrFileType, viewXmlFiles);
    }

    return (
      <>
        <PathField className="companion">
          <label>Companion:</label>
          <Select
            placeholder="Choose [companion] XML file"
            value={companionFile}
            options={viewXmlFiles}
            onChange={selectedCompanionFile => {
              if (selectedCompanionFile) {
                this.setState({
                  companionFile: selectedCompanionFile.value
                });
              }
            }}
            disabled={companionFileDisabled}
            searchable={false}
            clearable={false}
          />
        </PathField>
        <br/>
      </>
    );
  }

  findXmlFile(file, displayedItems) {
    const parenFolders = displayedItems.filter(f => file.parents.indexOf(f.contentID) > -1 && f.children)
    if (parenFolders.length === 0) {
      return [];
    }
    let xmlFiles = [];
    for (let index in parenFolders) {
      const folder = parenFolders[index];
      if (folder.contentID === file.parentID && folder.children) {
        xmlFiles = folder.children.filter(file => !file.isDirectory && file.key.toLowerCase().endsWith(".xml"));
        if (xmlFiles.length > 0) {
          return xmlFiles;
        }
      }
      if (folder.children) {
        xmlFiles = this.findXmlFile(file, folder.children);
        if (xmlFiles.length > 0) {
          return xmlFiles;
        }
      }
    }

    return xmlFiles;
  }

  renderPseTypes() {
    const { selectedPseType } = this.state;

    return (
      <>
        <PathField className="type">
          <label>Type:</label>
          <Select
            placeholder="Choose Video ColorSpace type"
            value={selectedPseType}
            options={PseTypes}
            onChange={selectedPseType => {
              if (selectedPseType) {
                this.setState({
                  selectedPseType: selectedPseType.value
                });
                if (selectedPseType.value !== 'dolby') {
                  this.setState({
                    showError: null,
                    companionFile: 0
                  });
                }
              }
            }}
            searchable={false}
            clearable={false}
          />
        </PathField>
      </>
    );
  }

  renderWoMemfis() {
    const { storageManager } = this.props;
    const { pseWoMemfis } = storageManager;
    let options = [];
    if (pseWoMemfis) {
      options = pseWoMemfis.map(u => ({label: u, value: u}));
    }

    const { woMemfis } = this.state;
    if (!woMemfis && pseWoMemfis && pseWoMemfis.length > 0) {
      this.setState({
        woMemfis: pseWoMemfis[0]
      });
    }

    return (
      <>
        <PathField className="type">
          <label>WO:</label>
          <Select
            placeholder="Choose work order"
            value={woMemfis}
            options={options}
            onChange={selectedWo => {
              this.setState({
                woMemfis: selectedWo.value
              });
            }}
            disabled={pseWoMemfis && pseWoMemfis.length === 1}
            searchable={false}
            clearable={false}
          />
        </PathField>
      </>
    );
  }

  renderSendToQOD() {
    const { isSendToQOD } = this.state;

    return (
      <>
        <PathField className="type">
          <label>&nbsp;&nbsp;&nbsp;</label>
          <Checkbox
            checked={isSendToQOD}
            onChange={({target: {checked}}) => this.setState({isSendToQOD: checked})}
          >
            Send to QOD
          </Checkbox>
        </PathField>
      </>
    );
  }

  render() {
    const { selectedPseType, companionFile, showError } = this.state;

    const minHeight = selectedPseType !== 'dolby' ? 300 : 450;

    const isSubmit = selectedPseType === 'sdr' || selectedPseType === 'hdr' || (selectedPseType === 'dolby' && companionFile > 0);

    return (
      <>
        {showError ? this.showErrorModal() : null}
        <RndModal
          show={true}
          onHide={this.handleClose}
          minHeight={minHeight}
          backdrop={false}
          enforceFocus={false}
          bsSize="large"
        >
          <Modal.Header closeButton>
            <Modal.Title>PSE Analysis</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.renderPseTypes()}
            {this.renderCompanionFiles()}
            {this.renderWoMemfis()}
            {this.renderSendToQOD()}
            <br/>
            <br/>
            <br/>
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="primary" onClick={this.handleGeneratePse} disabled={!isSubmit}>
              Submit
            </Button>
            <Button bsStyle="default" onClick={this.handleClose}>Cancel</Button>
          </Modal.Footer>
        </RndModal>
      </>
    );
  }
}

const PathField = styled(PathFieldStyled)`
  align-items: flex-start !important;
  justify-content: flex-start !important;

  .checkbox {
    padding-left: 20px;
  }

  .Select {
    .Select-menu-outer {
      z-index: 9999;
      max-height: 122px;
      .Select-menu {
        max-height: 120px;
      }
    }
  }

  label {
    flex: 0 0 115px;

    & + div {
      align-items: flex-start !important;

      &.value {
        max-width: calc(100% - 115px);
        font-family: monospace;
      }

      .left {

        + button {
          margin-top: 0;
          margin-left: 5px;
        }

        &.word-break-all {
          word-break: break-all;
        }

        .value + .editable-filename {
          margin-left: 2px;
          padding: 0;
          color: #757575;
          outline: 1px auto #e0dede;
          outline-offset: 1px;
          font-family: monospace;
          :hover {
            color: #555;
            outline-color: #ccc;
          }
          :focus {
            color: #282828;
            outline-color: #aaa;
          }
          &.invalid {
            outline-color: #a94442;
            &:focus {
              outline-color: #ffdddd;
            }
          }
          * {
            margin: 0 !important;
            outline: none !important;
            outline-offset: 0 !important;
          }
        }
      }
    }
  }
`;

PseModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
  storageManager: PropTypes.object.isRequired
};

export default PseModal;
