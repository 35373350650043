import { createGlobalStyle } from "styled-components";

const GlobalStyled = createGlobalStyle`
  .issue-browser-page {

    .filters-block {
      margin: 0 25px;

      .Select-menu-outer, .react-datepicker-popper {
        z-index: 2;
      }

      @media (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;

        >.form-group {
          width: 48%;

          &:nth-of-type(even) {
            margin-left: 2%;
          }
          &:nth-of-type(odd) {
            margin-right: 2%;
          }
        }
      }
    }

    .filters-btn-toolbar {
      margin: 0 -7px;
      padding-top: 0;
      border-top: none;
    }
  }
`;

export default GlobalStyled;