import {Component} from 'react';

const IMAGE_EXTENSIONS = [
  'jpg',
  'png',
  'bmp',
];

class BaseFile extends Component {
  state = {};

  getName = () =>{
    let name = this.props.newKey || this.props.fileKey;
    const slashIndex = name.lastIndexOf('/');
    if (slashIndex !== -1) {
      name = name.substr(slashIndex + 1);
    }
    return name;
  };

  getExtension = () => {
    const blobs = this.props.fileKey.split('.');
    return blobs[blobs.length - 1].toLowerCase().trim();
  };

  isImage = () => {
    const extension = this.getExtension();
    return IMAGE_EXTENSIONS.includes(extension);
  };

  isPdf = () => {
    const extension = this.getExtension();
    return extension === 'pdf';
  };

  handleFileClick = event => {
    if (event) {
      event.preventDefault();
    }
    this.props.browserProps.preview({
      url: this.props.url,
      name: this.getName(),
      key: this.props.fileKey,
      extension: this.getExtension(),
    });
  };

  handleItemClick = event => {
    event.stopPropagation();

    const fileAbsolutePath = this.props.browserProps.rootPath.slice(0, -1) + this.props.relativeKey;

    this.props.browserProps.select(fileAbsolutePath);
  };

  handleItemDoubleClick = event => {
    event.stopPropagation();
    this.handleFileClick();
  };

  connectDND = render => {
    return render;
  };
}

export default BaseFile;
