/* eslint-disable react-hooks/exhaustive-deps */
/* copied from LKFSModal */
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import styled from 'styled-components';
import Select from 'react-select';
import {Button, FormGroup, Radio, Checkbox, Popover} from 'react-bootstrap';
import {selectIsSaveEnabledForAddFileToWO, selectIsCurrentDestinationValidForAddFileToWO} from '../../selectors';
import {FS_ACTION_TYPE__ADD_TO_MEMFIS_WO, FS_ACTION_TYPE__COPY} from '../../constants';
import {
  closeModalAddFileToWO,
  addFileToWO,
  selectMemfisWoToAddFile,
  chooseDestinationToAddFile,
  makeValidateDirectoryAjax,
  onGotoScreenToAddFile,
  changeNewFolderCheckboxModalAddFileToWO,
  toggleNewFolderCheckboxModalAddFileToWO,
  toggleCreateProxyCheckboxModalAddFileWO,
  getCopyData,
  resetAddToWoDestination,
  setSelectedRoot,
  clearAddToWoModal,
  setAddFileToWOSuffix,
  setAddFileToWOSuffixFile,
  setAssignNewWo,
  setNewWO,
  STORAGE_MNG_ADD_FILE_TO_WO_RESET_AMB,
  STORAGE_MNG_ADD_FILE_TO_WO_UPDATE_AMB,
  STORAGE_MNG_ADD_FILE_TO_WO_SET_RUN_LKFS_ANALYSYS,
  STORAGE_MNG_SET_IS_CREATE_SYMLINK_CHECKED,
  STORAGE_MNG_SET_REPLACEMENT_TYPE_VALUE,
  checkDeliveryRenameForAddToWO,
  openDeliveryRenamePopup,
  changeIngestFiles,
  showIngestModal,
  changeStatusToAddFile,
  loadDisneyFiles,
  copyForAddFileToWO,
  toggleCaptionsSendToQod,
  showFrameRateModal,
  setNewFrameRate,
  setErrorNumberFrameRateValue,
  setFrameRate,
  showWoStepsModal,
  setWoStepsAddToWoNext, setWoStepsAddToWoBack, showWoStepsAddToWoModal
} from '../../actions';
import {preparePathForOsWithoutRoot, isNearlineDrive} from '../../utils';
import Loader from '../../../../components/Loader';
import FolderExplorerNew from '../forms/FolderExplorerNew';
import WOChooser from '../../../../components/WOChooser/WOChooser';
import {StyledLkfsModal} from './LKFSModal';
import TextEllipsisAdvanced from '../../../../components/TextEllipsis/TextEllipsisAdvanced';
import ReportBugButton from "../../../../components/ReportBugButton";
import TableWrap from "./add-to-wo/Table";
import {showError} from "../../../../layouts/actions";

const CustomPathFieldStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  .value {
    .error, .error:focus, .error:hover {
      border: 2px solid red;
    }
  }
  
  p.bg-danger {
    max-width: 200px;
    padding: 4px;
    margin-top: 1px;
    position: absolute;
    margin-left: -4px;
  }
  
  .fr-determ {
    width: 100% !important;
    padding: 5px !important;
    position: relative !important;
    max-width: 100% !important;
  }

  > label, > span {
    margin: 0 16px 0 0;
    padding: 0;
    flex: 0 0 140px;
    color: #282828;
    font-size: 14px;
    font-weight: bold;

    + div:not(.checkbox) {
      max-width: calc(100% - 140px - 16px);
      flex-grow: 1;
      &.destination-wrapper {
        display: flex;
        align-items: center;
        .destination-value {
          flex-grow: 1;
          max-width: calc(100% - 42px/*button width*/ - 16px);
        }
        &.with-reset-btn {
          .destination-value {
            max-width: calc(100% - 42px/*button width*/ - 16px - 41px/*button width*/ - 16px);
          }
        }
      }
    }
  }

  > .checkbox {
    margin: 0;
    label {
      color: #282828;
      font-weight: bold;
    }
  }

  .checkbox-label {
    input[type="checkbox"] {
      margin: 0 7px 0 0;
    }
  }

  #unavailableCreateSymlinkAlert.popover {
    border-color: red;
    &.bottom > .arrow {
      border-bottom-color: red;
    }
  }
`;

const SelectRootScreenLayoutStyled = styled.div`
  --invalid-color: #ec4a4a;

  input[type=text] {
    padding:4px;
    font-family:monospace;
    &.invalid-input {
      border-color: var(--invalid-color);
      outline: var(--invalid-color);
      border-style: solid;
      border-radius: 3px;
    }
  }
  .select-destination,
  .reset-destination {
    max-height:34px;
  }
  .destination {
    max-width: 70%;
    min-width: 150px;
    min-height: 32px;
    width: 380px;
    height: 55px;
  }

  button {
    margin-left: 16px;
    width: fit-content;
  }

  .content[data-warning] {
    position: relative;
    :after {
      content: attr(data-warning);
      position: absolute;
      left: 5px;
      bottom: -14px;
      font-size: 10px;
      color: var(--invalid-color);
    }
  }

`;

/*#region InitialScreen */
const InitialLayoutStyled = styled.div`
  --invalid-color: #ec4a4a;

  .nowrap {
    white-space: nowrap;
  }
  .label-margin {
    margin-right: 15px;
  }
  input[type=text] {
    padding:4px;
    font-family:monospace;
    &.invalid-input {
      border-color: var(--invalid-color);
      outline: var(--invalid-color);
      border-style: solid;
      border-radius: 3px;
    }
  }
  .select-destination,
  .reset-destination {
    max-height: 34px;
  }
  .destination {
    min-height: 32px;
    width: 380px;
    border: 2px solid grey;
    border-radius: 4px;
    padding: 4px;
    word-break: break-all;
  }
  button {
    margin-left: 16px;
    width: fit-content;
  }
`;


function SelectRootScreen(props) {
  const {
    selectedRoot,
    currentCopyDestination,
    default_copy_destination,
    default_copy_destination_v,
    fsRoots,
    isCurrentDestinationValid,
    onGotoScreen,
    onSetSelectedRoot,
    onSetSelectedRootOnly,
    WOMemfis,
    currentFolder,
    ADD_TO_MEMFIS_WO,
    rootSelected,
    isFilesScreen,
    disneySelection,
    disneySelectionWO,
    addToWoFileRootFolderWO,
    warningNotReplacementForMultiFolders,
    replacementData,
    deliveryRenameAvailable,
    checkedItems,
    onOpenDeliveryRenamePopup,
    ingestFiles,
    ingestCopyFolder,
    onChangeIngestFiles,
    onSubmitWO,
    onSetAddFileToWOSuffix,
    onSetAddFileToWOSuffixFile,
    setRunLKFSanalysis,
    checkDeliveryRenameAfterSelectWO,
    onResetNewFolderCheckbox,
    setCreateSymlinkChecked,
    isCreateQODProxyChecked,
    isInnerFileIngestScreen,
    onCreateProxyCheckboxChange
  } = props;

  const isInitialRoot = currentFolder.fsRootID === selectedRoot.value;
  let selectOptions;
  let crossRoots = null;
  if (ADD_TO_MEMFIS_WO.crossRootMapping) {
    crossRoots = ADD_TO_MEMFIS_WO.crossRootMapping[currentFolder.fsRootID];
  }
  if (crossRoots) {
    selectOptions = crossRoots.map(rootId => {
      const currentRoot = fsRoots.find(item => item.id === rootId);
      return currentRoot ? {value: currentRoot.id, label: currentRoot.name, contentID: currentRoot.contentID} : null;
    });
  } else {
    selectOptions = fsRoots.map(item => ({value: item.id, label: item.name, contentID: item.contentID}));
  }

  let destinationFieldView = (selectedRoot, selectOptions, WOMemfis) => {
    return (
      <>
      <CustomPathFieldStyled>
        <label>Destination:</label>
        <div className='content'>
          <Select
            placeholder="Select Root"
            value={selectedRoot.label ? selectedRoot : null}
            options={selectOptions}
            onChange={selectedOption => {
              if (disneySelectionWO) {
                onSetSelectedRootOnly(selectedOption);
                onSubmitWO(disneySelectionWO, selectedOption.value);
                onSetAddFileToWOSuffix(false);
                onSetAddFileToWOSuffixFile(false);
                setCreateSymlinkChecked(false);
                setRunLKFSanalysis(false);
                checkDeliveryRenameAfterSelectWO(disneySelectionWO, checkedItems);
                onResetNewFolderCheckbox();
              } else {
                onSetSelectedRoot(selectedOption, WOMemfis);
              }
            }}
            searchable={false}
            clearable={false}
          />
        </div>
      </CustomPathFieldStyled>
    </>
    )
  }

  let startStepView = () => {
    if (!WOMemfis && addToWoFileRootFolderWO) {
      onSubmitWO(addToWoFileRootFolderWO, selectedRoot.value);
      onSetAddFileToWOSuffix(false);
      onSetAddFileToWOSuffixFile(false);
      setCreateSymlinkChecked(false);
      setRunLKFSanalysis(false);
      checkDeliveryRenameAfterSelectWO(addToWoFileRootFolderWO, checkedItems);
      onResetNewFolderCheckbox();
    }
    return (
      <>
        { isInitialRoot ? null : destinationFieldView(selectedRoot, selectOptions, WOMemfis) }
        <CustomPathFieldStyled>
          <label>WO#</label>
          <div className='content'
               data-warning={warningNotReplacementForMultiFolders && WOMemfis && replacementData.replacementWOMemfis === WOMemfis ? 'WO# not for replacement' : undefined}
          >
            <input type='text' disabled value={WOMemfis}
                   className={warningNotReplacementForMultiFolders && WOMemfis && replacementData.replacementWOMemfis === WOMemfis ? 'invalid-input' : undefined}
            />
            <Button
              bsStyle='primary'
              onClick={() => onGotoScreen('isSelectingWOMemfis')}
              disabled={!selectedRoot.label || disneySelectionWO != null}>
              Select WO#
            </Button>
            {!!(deliveryRenameAvailable || {}).value && deliveryRenameAvailable.woMemfis === WOMemfis &&
            <Button
              bsStyle='primary'
              onClick={() => onOpenDeliveryRenamePopup(WOMemfis, checkedItems)}
            >
              Delivery Rename
            </Button>
            }
          </div>
        </CustomPathFieldStyled>
      </>
    )
  }

  let endStepView = () => {
    return (
      <>
        <CustomPathFieldStyled>
          <label>Copy Destination</label>
          <div className='content destination-wrapper'>
            <TextEllipsisAdvanced
              className={classNames({
                'destination-value': true,
                'invalid-input': !isCurrentDestinationValid,
              })}
              data={
                default_copy_destination_v && default_copy_destination === (currentCopyDestination || {}).key ?
                  [
                    preparePathForOsWithoutRoot(default_copy_destination_v.body, fsRoots),
                    preparePathForOsWithoutRoot(default_copy_destination_v.tail, fsRoots, default_copy_destination_v.body)
                  ] :
                  preparePathForOsWithoutRoot((currentCopyDestination || {}).key, fsRoots)
              }
              colors={
                default_copy_destination_v && default_copy_destination === (currentCopyDestination || {}).key ?
                  ['green','red'] :
                  undefined
              }
              defaultText="[No Folder Selected]"
            />
            <Button
              bsStyle='primary'
              className='select-destination'
              title="Select Destination"
              disabled={isInitialRoot || !selectedRoot.label}
              onClick={() => {
                onGotoScreen('isSelectingDestination', fsRoots.filter(r => r.fsRootID === selectedRoot.value));
              }}
            ><i className="far fa-folder-tree"/></Button>
          </div>
        </CustomPathFieldStyled>
      </>
    )
  }

  let fileStepView = () => {
    return (
      <>
        <TableWrap
          files={ingestFiles}
          onChangeIngestFiles={onChangeIngestFiles}
        />
        <br />
        { disneySelection &&
          <CustomPathFieldStyled>
            <label>Destination Folder:</label>
            <div className='content destination-wrapper'>
              <TextEllipsisAdvanced
                className={classNames({
                  'destination-value': true
                })}
                data={
                  [ingestCopyFolder]
                }
                colors={
                  ['green']
                }
                defaultText="[No Folder Selected]"
              />
            </div>
          </CustomPathFieldStyled>
        }
      </>
    )
  }

  const CreateQODProxyCheckbox = () => {
      return (
        <CustomPathFieldStyled>
          <label />
          <Checkbox
            checked={isCreateQODProxyChecked}
            onChange={({ target: { checked } }) =>
              onCreateProxyCheckboxChange(checked)
            }
          >
            Create QOD Proxy
          </Checkbox>
        </CustomPathFieldStyled>
      );
  };

  return <SelectRootScreenLayoutStyled>
    {
      disneySelection ?
        fileStepView() :
        (
          !rootSelected ?
          (
            isFilesScreen ?
              fileStepView()
              : startStepView()
          )
          :
          endStepView()
        )
    }
    {
      isInnerFileIngestScreen && rootSelected ? CreateQODProxyCheckbox() : null
    }
  </SelectRootScreenLayoutStyled>;
}

const SelectRootScreenConnected = connect(
  function mapState(state) {
    const {
      fsRoots,
      currentFolder,
      addFileToWoModalState: {
        selectedRoot,
        currentCopyDestination,
        initialDestination,
        currentWOMemfis,
        isDirectoryAsRootChecked,
        isDirectoryAsRootDisabled,
        default_copy_destination,
        default_copy_destination_v,
        deliveryRenameAvailable,
        isCreateQODProxyChecked,
        isInnerFileIngestScreen,
      },
      checkedItems,
      actionsUserPermissionsWithRoots
    } = state.storageManager;
    return {
      selectedRoot,
      WOMemfis: currentWOMemfis,
      currentCopyDestination,
      initialDestination,
      fsRoots,
      isCurrentDestinationValid: selectIsCurrentDestinationValidForAddFileToWO(state),
      isDirectoryAsRootChecked,
      isDirectoryAsRootDisabled,
      default_copy_destination,
      default_copy_destination_v,
      deliveryRenameAvailable,
      checkedItems,
      currentFolder,
      isCreateQODProxyChecked,
      isInnerFileIngestScreen,
      ADD_TO_MEMFIS_WO: actionsUserPermissionsWithRoots.ADD_TO_MEMFIS_WO
    };
  },

  function mapDispatch(dispatch) {
    return {
      onOpenDeliveryRenamePopup: (woMemfis, checkedItems) => {
        dispatch(openDeliveryRenamePopup(FS_ACTION_TYPE__ADD_TO_MEMFIS_WO, checkedItems, woMemfis));
      },
      onGotoScreen: (type, fsRoots) => {
        dispatch(onGotoScreenToAddFile(type, fsRoots));
      },
      onSetSelectedRoot: (selection, woMemfis) => {
        dispatch(setSelectedRoot(selection));
        if (woMemfis && selection) {
          dispatch(selectMemfisWoToAddFile(woMemfis, selection.value));
        }
      },
      onSetSelectedRootOnly: (selection) => {
        dispatch(setSelectedRoot(selection));
      },
      onReset: () => dispatch(resetAddToWoDestination()),
      onSubmitWO: (value, fsRootID) => dispatch(selectMemfisWoToAddFile(value, fsRootID)),
      onSetAddFileToWOSuffix: (isChecked) => dispatch(setAddFileToWOSuffix(isChecked)),
      onSetAddFileToWOSuffixFile: (isChecked) => dispatch(setAddFileToWOSuffixFile(isChecked)),
      setRunLKFSanalysis: (value) => dispatch({ type: STORAGE_MNG_ADD_FILE_TO_WO_SET_RUN_LKFS_ANALYSYS, runLKFSanalysis: value}),
      checkDeliveryRenameAfterSelectWO: (woMemfis, checkedItems) => dispatch(checkDeliveryRenameForAddToWO(woMemfis, checkedItems)),
      onResetNewFolderCheckbox: () => {
        dispatch(changeNewFolderCheckboxModalAddFileToWO(null));
      },
      onCreateProxyCheckboxChange: (isChecked) => {
        dispatch(toggleCreateProxyCheckboxModalAddFileWO(isChecked));
        if (isChecked) {
          dispatch(setAddFileToWOSuffix(false));
        }
      }
    };
  }
)(SelectRootScreen);

const REPLACEMENT_TYPE_REEL = "REEL";
const REPLACEMENT_TYPE_PATCH = "PATCH";

function InitialScreen(props) {
  const {
    WOMemfis,
    newWOMemfis,
    destination,
    copyDestination,
    initialDestination,
    fsRoots,
    isDestinationChanged,
    isDirectoryAsRootChecked,
    isDirectoryAsRootDisabled,
    isCreateQODProxyChecked,
    proxyCopyAvailable,
    onGotoScreen,
    onCheckboxReset,
    onCheckboxChange,
    onCreateProxyCheckboxChange,
    onCaptionsSendToQodChange,
    currentFolder,
    onReset,
    isCreateSymlinkChecked,
    addToWoFileRootFolderWO,
    setCreateSymlinkChecked,
    source_already_linked,
    existed_WO_root_directory,
    existed_symlink_root_directory,
    checkedItems,
    replacementData,
    replacementTypeValue,
    setReplacementTypeValue,
    addFileToWOSuffix,
    addFileToWOSuffixFile,
    onSetAddFileToWOSuffix,
    onSetAddFileToWOSuffixFile,
    isAssignNewWo,
    onSetAssignNewWo,
    default_symlink_root_directory,
    default_symlink_root_directory_body,
    default_symlink_root_directory_tail,
    AMBCurrent,
    AMBInitial,
    onResetAMB,
    onUpdateAMB,
    runLKFSanalysis,
    setRunLKFSanalysis,
    showCaptionsSendToQod,
    checkedCaptionsSendToQod,
    setNewFrameRate,
    openFrameRateModal,
    lkfsModalState
  } = props;
  const {isReplacement, replacementSource, replacementDestination} = replacementData;
  const isResetDisabled = destination.fsRootID !== initialDestination.fsRootID || destination.key === initialDestination.key;
  const copyDestinationRoot = fsRoots.find(r => r.fsRootID === copyDestination.fsRootID).fsRootName;

  const isCheckedFoldersOnly = checkedItems.every(({isDirectory}) => isDirectory);
  const isMultiFolders = isCheckedFoldersOnly && checkedItems.length > 1;

  var isCreateSymlinkDisabled = !isCheckedFoldersOnly || source_already_linked || existed_WO_root_directory;
  isCreateSymlinkDisabled = isCreateSymlinkDisabled ? true : false;
  var isCreateSymlinkCheckedLocal = isReplacement ? false : (isCreateSymlinkDisabled ? true : isCreateSymlinkChecked);

  if (addToWoFileRootFolderWO && isCreateSymlinkCheckedLocal) {
    isCreateSymlinkCheckedLocal = false;
  }

  //let str = Number(AMBCurrent).toString(2);
  //if (str.length === 1) str = '0' + str;
  const checked1 = Boolean(Number(AMBCurrent) & 1);
  const checked3 = Boolean(Number(AMBCurrent) & 2);
  const checked4 = Boolean(Number(AMBCurrent) & 8192);

  const isAllowMarkDirectoryAsWoSymlinkRootCheckbox = !(isDirectoryAsRootDisabled || (isCreateSymlinkCheckedLocal && existed_symlink_root_directory));

  const {frameRate, errorType, newFrameRate, isErrorNumberFrameRate, errorQcProject, isEnable} = lkfsModalState;
  const className = (errorType !== null && errorType !== "success") && newFrameRate === null || isErrorNumberFrameRate ? "error" : "";
  const btnClassName = (errorType !== null && errorType !== "success") && newFrameRate === null || isErrorNumberFrameRate ? 'btn-danger' : 'bg-info';

  useEffect(() => {
    setCreateSymlinkChecked(isCreateSymlinkCheckedLocal);
    if (isCreateSymlinkCheckedLocal) {
      setRunLKFSanalysis(false);
      onUpdateAMB(false, false, false);
    }
  },[setCreateSymlinkChecked, isCreateSymlinkCheckedLocal, setRunLKFSanalysis, onUpdateAMB]);

  useEffect(() => {
    if (isAllowMarkDirectoryAsWoSymlinkRootCheckbox && isCreateSymlinkCheckedLocal) {
      if (isDirectoryAsRootChecked === null) {
        onCheckboxChange(true);
      }
    } else if (isDirectoryAsRootChecked !== null) {
      onCheckboxReset();
    }
  },[isDirectoryAsRootChecked, isAllowMarkDirectoryAsWoSymlinkRootCheckbox, isCreateSymlinkCheckedLocal]);

  const CreateQODProxyCheckbox = () => {
    if (proxyCopyAvailable) {
      return (
        <CustomPathFieldStyled
          style={isCreateSymlinkCheckedLocal ? null : { display: "none" }}
        >
          <label />
          <Checkbox
            checked={isCreateQODProxyChecked}
            onChange={({ target: { checked } }) =>
              onCreateProxyCheckboxChange(checked)
            }
          >
            Create QOD Proxy
          </Checkbox>
        </CustomPathFieldStyled>
      );
    }
    return null;
  };

  const SendCaptionsToQODCheckbox = () => {
    if (showCaptionsSendToQod) {
      return (
        <CustomPathFieldStyled>
          <label />
          <Checkbox
            checked={checkedCaptionsSendToQod}
            onChange={({ target: { checked } }) =>
              onCaptionsSendToQodChange(checked)
            }
          >
            Send captions to QOD
          </Checkbox>
        </CustomPathFieldStyled>
      );
    }
    return null;
  };

  const MarkDirectoryAsWoSymlinkRootCheckbox = () => {
    if (isAllowMarkDirectoryAsWoSymlinkRootCheckbox) {
      return (
        <CustomPathFieldStyled style={isCreateSymlinkCheckedLocal ? undefined : {display: 'none'}}>
          <label/>
          <Checkbox
            checked={isDirectoryAsRootChecked || false}
            onChange={({target: {checked}}) => onCheckboxChange(checked)}
            disabled={isShowUnavailableCreateSymlinkAlert()}
          >
            Mark directory as WO Symlink Root
          </Checkbox>
        </CustomPathFieldStyled>
      );
    }
    return null;
  };

  const isShowUnavailableCreateSymlinkAlert = () => {
    return (checkedItems || []).some(({isDirectory}) => isDirectory) && isCreateSymlinkCheckedLocal && !isCreateSymlinkDisabled;
  };

  return <InitialLayoutStyled>
    <CustomPathFieldStyled>
      <label>WO#</label>
      <div>{WOMemfis}</div>
    </CustomPathFieldStyled>
    {isReplacement ?
      <>
        <CustomPathFieldStyled>
          <span>Replacement Type:</span>
          <div>
            <FormGroup>
              <Radio
                name="radio--proxy-copy"
                value={REPLACEMENT_TYPE_PATCH}
                checked={replacementTypeValue === REPLACEMENT_TYPE_PATCH}
                onChange={setReplacementTypeValue}
                disabled={isMultiFolders && replacementTypeValue === REPLACEMENT_TYPE_PATCH}
              >
                Patch
              </Radio>
              <Radio
                name="radio--proxy-copy"
                value={REPLACEMENT_TYPE_REEL}
                checked={replacementTypeValue === REPLACEMENT_TYPE_REEL}
                onChange={setReplacementTypeValue}
                disabled={isMultiFolders}
              >
                Reel
              </Radio>
            </FormGroup>
          </div>
        </CustomPathFieldStyled>
        <CustomPathFieldStyled>
          <span>Replacement (Source):</span>
          <div>{replacementSource.key}</div>
        </CustomPathFieldStyled>
        <CustomPathFieldStyled>
          <span>Old Content (Destination):</span>
          <div>{replacementDestination.key}</div>
        </CustomPathFieldStyled>
        <CustomPathFieldStyled>
          <label/>
          <Checkbox
            checked={isAssignNewWo}
            onChange={({target: {checked}}) => onSetAssignNewWo(checked)}
          >
            After replacement - assign new WO:
          </Checkbox>
        </CustomPathFieldStyled>
        <CustomPathFieldStyled>
          <label/>
          <div className='content'>
            <input type='text' disabled value={newWOMemfis} />
            <Button
              bsStyle='primary'
              onClick={() => onGotoScreen('isSelectingWOMemfis')}
              disabled={!isAssignNewWo}>
              Select new WO#
            </Button>
          </div>
        </CustomPathFieldStyled>
      </> :
      <>
        <CustomPathFieldStyled>
          <label/>
          <div>
            <label className="checkbox-label nowrap label-margin" style={{color: isCreateSymlinkDisabled ? 'grey' : 'inherit', position: 'relative'}}>
              <input type = "checkbox" checked={isCreateSymlinkCheckedLocal} disabled={isCreateSymlinkDisabled} onChange={(event) => {
                setCreateSymlinkChecked(event.target.checked);
              }}/>
              Create Symlink
              {isShowUnavailableCreateSymlinkAlert() &&
                <Popover
                  id="unavailableCreateSymlinkAlert"
                  placement="bottom"
                  style={{width: '200px', fontSize: '10px', lineHeight: '11px', color: 'red', top: '17px'}}
                >
                  Sorry, this functionality is currently unavailable. Please unselect "Create Symlink" to continue.
                </Popover>
              }
            </label>
              <label className="checkbox-label" style={(!!proxyCopyAvailable && !!isCreateSymlinkCheckedLocal && isCreateQODProxyChecked) ? {color: 'grey'} : undefined}>
                <input
                  type = "checkbox"
                  checked={addFileToWOSuffix}
                  onChange={(event) => {
                    onSetAddFileToWOSuffix(event.target.checked);
                  }}
                  disabled={(!!proxyCopyAvailable && !!isCreateSymlinkCheckedLocal && isCreateQODProxyChecked) || isShowUnavailableCreateSymlinkAlert()}
                />
                Add "_WO..._MEM..." suffix{isCreateSymlinkCheckedLocal ? "" : " to Folder"}
              </label>
          </div>
        </CustomPathFieldStyled>

				<CustomPathFieldStyled style={!isCreateSymlinkCheckedLocal ? undefined : {display: 'none'}}>
					<label/>
					<div>
						<label className="checkbox-label nowrap label-margin" style={{visibility:'hidden'}}>
							<input type = "checkbox" checked={isCreateSymlinkCheckedLocal} disabled={isCreateSymlinkDisabled} onChange={(event) => {
								setCreateSymlinkChecked(event.target.checked);
							}}/>
							Create Symlink
						</label>
						<label className="checkbox-label">
							<input type = "checkbox" checked={addFileToWOSuffixFile} onChange={(event) => {
								onSetAddFileToWOSuffixFile(event.target.checked);
							}}/>
							Add "_WO..._MEM..." suffix to Files
						</label>
					</div>
				</CustomPathFieldStyled>

				<CustomPathFieldStyled style={isCreateSymlinkCheckedLocal ? undefined : {display: 'none'}}>
					<label>
						Symlink Destination
            </label>
            <div className="content destination-wrapper with-reset-btn">
              {(isDestinationChanged && (!default_symlink_root_directory || default_symlink_root_directory !== destination.key)) || !default_symlink_root_directory_body ?
                <TextEllipsisAdvanced
                  className={classNames({
                    'destination-value': true,
                    // 'invalid-input': !!destination && !!destination.key && !isCurrentDestinationValid
                  })}
                  data={preparePathForOsWithoutRoot(destination.key || default_symlink_root_directory || copyDestinationRoot || currentFolder.fsRootName, fsRoots)}
                /> :
                <TextEllipsisAdvanced
                  className={classNames({'destination-value': true})}
                  data={[
                    preparePathForOsWithoutRoot(default_symlink_root_directory_body, fsRoots),
                    preparePathForOsWithoutRoot(default_symlink_root_directory_tail, fsRoots, default_symlink_root_directory_body)
                  ]}
                  colors={['green','red']}
                />
              }
              <Button
                bsStyle='primary'
                className='select-destination'
                title="Select Destination"
                disabled={!WOMemfis || !destination || (!isCreateSymlinkCheckedLocal && !isCreateSymlinkDisabled) || isShowUnavailableCreateSymlinkAlert()}
                onClick={() => {
                  onGotoScreen('isSelectingDestination', fsRoots.filter(r => r.fsRootID === (copyDestination.fsRootID || destination.fsRootID || currentFolder.fsRootID)));
                }}>
                <i className="far fa-folder-tree"/>
              </Button>
              <Button
                bsStyle='default'
                className='reset-destination'
                title="Reset"
                disabled={isResetDisabled}
                onClick={onReset}>
                <i className="fa-light fa-folder-xmark"/>
              </Button>
            </div>
        </CustomPathFieldStyled>
        <MarkDirectoryAsWoSymlinkRootCheckbox/>
        <SendCaptionsToQODCheckbox/>
        <CreateQODProxyCheckbox />
        {checkedItems && checkedItems.every(({isDirectory}) => isDirectory) && !isCreateSymlinkCheckedLocal ? <CustomPathFieldStyled>
          <label/>
          <Checkbox
            checked={runLKFSanalysis}
            onChange={() => setRunLKFSanalysis(!runLKFSanalysis)}
          >
            Run LKFS Analysis
          </Checkbox>
        </CustomPathFieldStyled> : null}
      {runLKFSanalysis ?
        <CustomPathFieldStyled>
          <label>AMB Settings</label>
          <div className='content amb-settings'>
            <label className="checkbox-label label-margin">
              <input type='checkbox'
                    id='amb_1'
                    name='amb_1'
                    checked={checked1}
                    onChange={() => onUpdateAMB(!checked1, checked3, checked4)}
              />
              1770_1
            </label>
            <label className="checkbox-label">
              <input type='checkbox'
                    id='amb_3'
                    name='amb_3'
                    checked={checked3}
                    onChange={() => onUpdateAMB(checked1, !checked3, checked4)}
              />
              1770_3
            </label>
            <label className="checkbox-label">
              <input type='checkbox'
                    id='amb_4'
                    name='amb_4'
                    checked={checked4}
                    onChange={() => onUpdateAMB(checked1, checked3, !checked4)}
              />
              1770_4
            </label>
            <Button
              disabled={AMBInitial === AMBCurrent}
              onClick={onResetAMB}
            >
              Reset
            </Button>
          </div>
        </CustomPathFieldStyled> : null
      }
      { runLKFSanalysis && isEnable ?
        <CustomPathFieldStyled>
          <label>Frame Rate:</label>
          <div className="value no-monospace">
            <input
              type='text'
              className={className}
              value={newFrameRate !== null ? newFrameRate : frameRate}
              onChange={(e) => setNewFrameRate(e.target.value)}
            />
            &nbsp;
            { errorType !== null && errorType !== "success" ?
              <a
                className={'btn ' + btnClassName}
                onClick={() => openFrameRateModal()}
                title="See error details"
              >
                explain
              </a> : null
            }
            { isErrorNumberFrameRate && !errorQcProject  ?
              <p className="bg-danger">Frame rate must be a number</p>
              : null
            }
          </div>
        </CustomPathFieldStyled> : null
      }
      {runLKFSanalysis && isEnable && errorQcProject ?
        <CustomPathFieldStyled>
          <p className="bg-danger fr-determ">Frame rate can not be determined</p>
        </CustomPathFieldStyled> : null
      }
    </>}
  </InitialLayoutStyled>;
}

const InitialScreenConnected = connect(
  function mapState(state) {
    const {fsRoots, currentFolder, checkedItems, LKFSModalState,
      addFileToWoModalState: {
      currentDestination,
      currentCopyDestination,
      initialDestination,
      currentDestinationShort,
      currentWOMemfis,
      newWOMemfis,
      isDestinationChanged,
      isDirectoryAsRootChecked,
      isDirectoryAsRootDisabled,
      isCreateQODProxyChecked,
      proxyCopyAvailable,
      source_already_linked,
      existed_WO_root_directory,
      replacementData,
      addFileToWOSuffix,
      addFileToWOSuffixFile,
      isAssignNewWo,
      default_symlink_root_directory,
      default_symlink_root_directory_body,
      default_symlink_root_directory_tail,
      default_copy_destination,
      default_copy_destination_v,
      AMBCurrent,
      AMBInitial,
      runLKFSanalysis,
      showCaptionsSendToQod,
      checkedCaptionsSendToQod
    }} = state.storageManager;

    return {
      WOMemfis: currentWOMemfis,
      destination: currentDestination,
      copyDestination: currentCopyDestination,
      destinationShort: currentDestinationShort,
      initialDestination,
      fsRoots,
      isCurrentDestinationValid: selectIsCurrentDestinationValidForAddFileToWO(state),
      isDestinationChanged,
      isDirectoryAsRootChecked,
      isDirectoryAsRootDisabled,
      isCreateQODProxyChecked,
      proxyCopyAvailable,
      currentFolder,
      source_already_linked,
      existed_WO_root_directory,
      replacementData,
      checkedItems,
      addFileToWOSuffix,
      addFileToWOSuffixFile,
      isAssignNewWo,
      newWOMemfis,
      default_symlink_root_directory,
      default_symlink_root_directory_body,
      default_symlink_root_directory_tail,
      default_copy_destination,
      default_copy_destination_v,
      AMBCurrent,
      AMBInitial,
      runLKFSanalysis,
      showCaptionsSendToQod,
      checkedCaptionsSendToQod,
      lkfsModalState: LKFSModalState
    };
  },

  function mapDispatch(dispatch) {
    return {
      onResetAMB: () => dispatch({ type: STORAGE_MNG_ADD_FILE_TO_WO_RESET_AMB }),
      onUpdateAMB: (new1, new3, new4) => dispatch({ type: STORAGE_MNG_ADD_FILE_TO_WO_UPDATE_AMB, new1, new3, new4 }),
      setRunLKFSanalysis: (value) => dispatch({ type: STORAGE_MNG_ADD_FILE_TO_WO_SET_RUN_LKFS_ANALYSYS, runLKFSanalysis: value}),
      onGotoScreen: (type, fsRoots) => {
        dispatch(onGotoScreenToAddFile(type, fsRoots));
      },
      onCheckboxReset: () => {
        dispatch(changeNewFolderCheckboxModalAddFileToWO(null));
      },
      onCheckboxChange: (isChecked) => {
        dispatch(toggleNewFolderCheckboxModalAddFileToWO(isChecked));
      },
      onCreateProxyCheckboxChange: (isChecked) => {
        dispatch(toggleCreateProxyCheckboxModalAddFileWO(isChecked));
        if (isChecked) {
          dispatch(setAddFileToWOSuffix(false));
        }
      },
      onCaptionsSendToQodChange: (isChecked) => {
        dispatch(toggleCaptionsSendToQod(isChecked));
      },
      onReset: () => dispatch(resetAddToWoDestination()),
      onSetAddFileToWOSuffix: (isChecked) => dispatch(setAddFileToWOSuffix(isChecked)),
      onSetAddFileToWOSuffixFile: (isChecked) => dispatch(setAddFileToWOSuffixFile(isChecked)),
      onSetAssignNewWo: (isChecked) => dispatch(setAssignNewWo(isChecked)),
      openFrameRateModal: () => {
        dispatch(showFrameRateModal(true));
      },
      setNewFrameRate: (value) => {
        if (!/^-?(0|[1-9]\d*)(\.[0-9]{1,4})?(\/-?(0|[1-9]\d*)(\.[0-9]{1,4})?)?$/.test(value) || value === "0") {
          dispatch(setErrorNumberFrameRateValue(true));
        } else {
          dispatch(setErrorNumberFrameRateValue(false));
        }
        dispatch(setNewFrameRate(value));
      }
    };
  }
)(InitialScreen);
/*#endregion */

/*#region WOMemfis */

const WOMemfisLayout = styled.div`
  min-height: 300px;
`;

function SelectWOMemfisScreen(props) {
  const {
    isWOMemfisLoading,
    onSubmitWO,
    onSubmitNewWO,
    oneCheckedFsRootID,
    currentCopyDestination,
    isAssignNewWo,
    onSetAddFileToWOSuffix,
    onSetAddFileToWOSuffixFile,
    setCreateSymlinkChecked,
    setRunLKFSanalysis,
    checkDeliveryRenameAfterSelectWO,
    checkedItems,
    onResetNewFolderCheckbox,
    currentFolder,
    selectedRoot
  } = props;
  const isInitialRoot = currentFolder.fsRootID === selectedRoot.value;
  return <WOMemfisLayout>
    { isWOMemfisLoading &&
    <div>
      <Loader/>
      <ReportBugButton isForModal className="loader-report-bug-btn"/>
    </div> }
    <WOChooser
      startHeightProps={{selector: 'react-draggable', value: '80vh'}}
      fsRootID={isInitialRoot ? null : currentCopyDestination.fsRootID || oneCheckedFsRootID}
      onChoose={(value) => {
        if(isAssignNewWo) {
          onSubmitNewWO(value, currentCopyDestination.fsRootID || oneCheckedFsRootID);
        } else {
          onSubmitWO(value, currentCopyDestination.fsRootID || oneCheckedFsRootID);
          onSetAddFileToWOSuffix(false);
          onSetAddFileToWOSuffixFile(false);
          setCreateSymlinkChecked(false);
          setRunLKFSanalysis(false);
          checkDeliveryRenameAfterSelectWO(value, checkedItems);
          onResetNewFolderCheckbox();
        }
      }}
    />
  </WOMemfisLayout>;
}

const SelectWOMemfisScreenConnected = connect(
  function mapState(state) {
    return {
      selectedRoot: state.storageManager.addFileToWoModalState.selectedRoot,
      currentFolder: state.storageManager.currentFolder,
      checkedItems: state.storageManager.checkedItems,
      oneCheckedFsRootID: (state.storageManager.checkedItems[0] || {}).fsRootID,
      currentCopyDestination: state.storageManager.addFileToWoModalState.currentCopyDestination,
      isAssignNewWo: state.storageManager.addFileToWoModalState.isAssignNewWo,
    }
  },
  function mapDispatchToProps(dispatch) {
    return {
      onSubmitWO: (value, fsRootID) => dispatch(selectMemfisWoToAddFile(value, fsRootID)),
      onSubmitNewWO: (value, fsRootID) => dispatch(setNewWO(value, fsRootID)),
      onSetAddFileToWOSuffix: (isChecked) => dispatch(setAddFileToWOSuffix(isChecked)),
      onSetAddFileToWOSuffixFile: (isChecked) => dispatch(setAddFileToWOSuffixFile(isChecked)),
      setRunLKFSanalysis: (value) => dispatch({ type: STORAGE_MNG_ADD_FILE_TO_WO_SET_RUN_LKFS_ANALYSYS, runLKFSanalysis: value}),
      checkDeliveryRenameAfterSelectWO: (woMemfis, checkedItems) => dispatch(checkDeliveryRenameForAddToWO(woMemfis, checkedItems)),
      onResetNewFolderCheckbox: () => {
        dispatch(changeNewFolderCheckboxModalAddFileToWO(null));
      },
    }
  }
)(SelectWOMemfisScreen);

/*#endregion */

/*#region Destination */
function SelectDestinationLayout(props) {
  const {dispatch, storageManager, selectedFolder, setSelectedFolder} = props;
  const {
    currentView,
    openedFoldersInModal,
    fsRoots,
    foldersInModal,
    currentFolder,
    addFileToWoModalState: {
      isInitialScreen,
      default_copy_destination,
      default_copy_destination_v,
      default_symlink_root_directory_body,
      selectedRoot,
    },
  } = storageManager;
  const defaultDirToExpand = isInitialScreen ? default_symlink_root_directory_body :
    (default_copy_destination_v ? default_copy_destination_v.body : default_copy_destination);
  return (
      <FolderExplorerNew
        dispatch={dispatch}
        loaders={storageManager.loaders}
        folders={foldersInModal}
        fsRoots={fsRoots}
        openedFolders={openedFoldersInModal}
        currentView={currentView}
        selectedRoot={selectedRoot}
        directoryToExpand={selectedFolder ? null : defaultDirToExpand}
        folderToExpand={selectedFolder || currentFolder}
        selectedFolder={selectedFolder}
        onSelectFolder={setSelectedFolder}
        storageManager={storageManager}
      />
  );
}

const SelectDestinationLayoutConnected = connect(
  function mapState(state) {
    return {
      storageManager: state.storageManager,
    }
  },
)(SelectDestinationLayout);

/*#endregion */

/*#region AddFileToWoModal */

class AddFileToWoModal extends React.Component {
  state = {
    selectedFolder: null,
    rootSelected: false,
    wasBackBtnClicked: false,
    lastWO: null
  };

  componentWillMount() {
    const { checkedItems, loadDisneyFiles, currentWOMemfis } = this.props;
    loadDisneyFiles(checkedItems[0].contentID, checkedItems[0].fsRootID, currentWOMemfis || checkedItems[0].memfisWoID);
  }

  componentDidMount() {
    const {currentFolder, selectedRoot, onSetSelectedRoot, fsRoots, ADD_TO_MEMFIS_WO} = this.props;

    if (ADD_TO_MEMFIS_WO.crossRootMapping) {
      const  crossRoots = ADD_TO_MEMFIS_WO.crossRootMapping[currentFolder.fsRootID];
      if (crossRoots && crossRoots.length === 1) {
        selectedRoot.value = crossRoots[0];
        const preselectedRoot = fsRoots.find(item => item.id === crossRoots[0]);
        onSetSelectedRoot({value: preselectedRoot.id, label: preselectedRoot.name, contentID: preselectedRoot.contentID});
      }
    }

    const isInitialRoot = currentFolder.fsRootID === selectedRoot.value;
    if (isInitialRoot && selectedRoot !== currentFolder.fsRootID) {
      const currentRoot = fsRoots.find(item => item.id === currentFolder.fsRootID);
      onSetSelectedRoot({value: currentRoot.id, label: currentRoot.name, contentID: currentRoot.contentID});
    }
  }

  setSelectedFolder(folder) {
    const {checkFolderAvailability} = this.props;
    if (folder !== null) {
      const {key, fsRootID, contentID} = folder;
      checkFolderAvailability(key, fsRootID, contentID);
      this.setState({
        ...this.state,
        selectedFolder: folder,
      });
    }
  }

  setRootSelected(isSelected) {
    this.setState({
      ...this.state,
      rootSelected: isSelected,
    });
  }

  setCreateSymlinkChecked = (isChecked) => {
    const {onSetIsCreateSymlinkChecked} = this.props;
    onSetIsCreateSymlinkChecked(isChecked);
  };

  setReplacementTypeValue = ({target: {value}}) => {
    const {onSetReplacementTypeValue} = this.props;
    onSetReplacementTypeValue(value);
  };

  preselectReplacementType = () => {
    const {checkedItems, replacementData: {replacementType, isReplacement}, availableActionsBySources} = this.props;
    const isCheckedFoldersOnly = checkedItems.every(({isDirectory}) => isDirectory);
    const isMultiFolders = isCheckedFoldersOnly && checkedItems.length > 1;
    let replTypeValue = replacementType;
    if (isReplacement && isMultiFolders) {
      const actionData = availableActionsBySources[FS_ACTION_TYPE__ADD_TO_MEMFIS_WO];
      if (actionData && actionData.action_sub_type === 'REPLACEMENT') {
        const {replacement_type} = actionData;
        replTypeValue = [REPLACEMENT_TYPE_PATCH, REPLACEMENT_TYPE_REEL].includes(replacement_type) ? replacement_type : REPLACEMENT_TYPE_PATCH;
      }
    }
    this.setReplacementTypeValue({target: {value: replTypeValue}});
  };

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.replacementData) !== JSON.stringify(prevProps.replacementData)) {
      this.preselectReplacementType();
    }
    const {
      isInitialScreen,
      isDestinationChanged,
      currentDestination,
      currentCopyDestination,
    } = this.props;
    if (isInitialScreen !== prevProps.isInitialScreen) {
      let selectedFolder = isInitialScreen ? currentDestination : currentCopyDestination;
      if (!selectedFolder || !selectedFolder.key || !selectedFolder.contentID || !selectedFolder.fsRootID) {
        selectedFolder = null;
      }
      this.setState({selectedFolder});
    } else if (isInitialScreen && !isDestinationChanged && prevProps.isDestinationChanged) {
      this.setState({selectedFolder: null});
    }
  }

  handleClickBackBtn = (newScreen) => {
    const {onGotoScreen, currentDestination: {key, fsRootID, contentID}, checkFolderAvailability, isInitialScreen} = this.props;
    onGotoScreen(newScreen);
    if (newScreen && isInitialScreen) {
      checkFolderAvailability(key, fsRootID, contentID);
    } else {
      this.setState({...this.state, wasBackBtnClicked: true});
    }
  };

  handleClickCancelBtn = () => {
    const {onCloseModal} = this.props;
    onCloseModal();
  };

  handleClickNextBtn = () => {
    const {
      onGotoScreen,
      currentDestination,
      currentFolder,
      fsRoots,
      checkedItems,
      source_already_linked,
      existed_WO_root_directory
    } = this.props;

    this.preselectReplacementType();
    onGotoScreen('isInitialScreen', fsRoots.filter(r => r.fsRootID === (currentDestination.fsRootID || currentFolder.fsRootID)));
    const isCheckedFoldersOnly = checkedItems.every(({isDirectory}) => isDirectory);
    const isCreateSymlinkEnabled = isCheckedFoldersOnly && !source_already_linked && !existed_WO_root_directory;
    if (isCreateSymlinkEnabled) {
      this.setCreateSymlinkChecked(false);
    }
    if(this.state.wasBackBtnClicked) {
      setTimeout(() => {
        this.setState({...this.state, wasBackBtnClicked: false});
      });
    }
  };

  isReplacingRootFolderStep = (isInitialRoot) => {
    const {rootSelected} = this.state;
    const {
      fsRoots,
      checkedItems,
      existed_WO_root_directory,
      isInitialScreen,
      selectedRoot,
      replacementData: {isReplacement},
    } = this.props;
    return Boolean(
      !isReplacement &&
      (isInitialScreen || (!isInitialRoot && rootSelected)) &&
      checkedItems.length === 1 && checkedItems[0].isDirectory &&
      existed_WO_root_directory &&
      selectedRoot && selectedRoot.label && existed_WO_root_directory.startsWith(selectedRoot.label) && !isNearlineDrive(checkedItems[0], fsRoots)
    );
  };

  buildSteps = (isSubmitEnabled, isInitialRoot, warningNotReplacementForMultiFolders) => {
    const {selectedFolder, rootSelected} = this.state;

    const {
      fsRoots,
      checkedItems,
      currentFolder,
      currentDestination,
      currentCopyDestination,
      default_copy_destination,
      existed_WO_root_directory,
      isInitialScreen,
      isIngestScreen,
      isInnerFileIngestScreen,
      isFilesScreen,
      isSelectingWOMemfis,
      isSelectingDestination,
      isDirectoryAsRootChecked,
      isDestinationAvailable,
      isCreateQODProxyChecked,
      checkedCaptionsSendToQod,
      proxyCopyAvailable,
      onSubmit,
      submitData,
      onSubmitCopy,
      copySubmitData,
      onConfirm,
      onCreateProxy,
      onGotoScreen,
      selectedRoot,
      currentWOMemfis,
      isCreateSymlinkChecked,
      replacementData,
      replacementTypeValue,
      ingestFiles,
      ingestCopyFolder,
      showIngestSettings,
      ingestOperationTypes,
      addToWoIngestData,
      onShowIngestModal,
      onChangeStatusToAddFile,
      disneySelection,
      addToWoFileRootFolderWO,
      frameRateValue,
      runLKFSanalysis,
      showWoStepsAddToWo,
      onShowWoStepsModal,
      woSteps
    } = this.props;

    if (!rootSelected && (isIngestScreen || isInnerFileIngestScreen) && !ingestFiles && !isFilesScreen) {
      this.setRootSelected(true);
    }

    const isNextEnabled =
      (currentFolder.fsRootID === selectedRoot.value &&
        selectedRoot.label !== undefined &&
        currentWOMemfis.length > 0) ||
      (currentFolder.fsRootID !== selectedRoot.value &&
        currentCopyDestination.contentID) ||
      (currentFolder.fsRootID !== selectedRoot.value &&
        currentCopyDestination.key &&
        currentCopyDestination.fsRootID);

    const isReplacingRootFolderStep = this.isReplacingRootFolderStep(isInitialRoot);
    const modalSubTitle = isReplacingRootFolderStep ? 'Replacing root folder' :
      isInitialScreen ? 'Select Link Destination Folder' : isInitialRoot ? 'Select WO#' :
        (rootSelected ? 'Select Copy Destination Folder' :
          (ingestCopyFolder ? 'Select files to copy to a new folder' : 'Select Destination and WO#'));

    const needToAddWoWithoutSymlinkForRootFolderOrSubfolders =
      !addToWoFileRootFolderWO && existed_WO_root_directory &&
      currentCopyDestination && currentCopyDestination.key &&
      (existed_WO_root_directory === currentCopyDestination.key ||
       currentCopyDestination.key.startsWith(`${existed_WO_root_directory}\\`)
      );

    const skipSelectCopyDestinationFolderStep = Boolean(replacementData.isReplacement && default_copy_destination);

/*console.log('------------------->')
console.log('isSelectingWOMemfis',isSelectingWOMemfis)
console.log('isInitialRoot',isInitialRoot)
console.log('rootSelected',rootSelected)
console.log('selectedRoot',JSON.stringify(selectedRoot))
console.log('isInitialScreen',isInitialScreen)
console.log('isSelectingDestination',isSelectingDestination)
console.log('skipSelectCopyDestinationFolderStep',skipSelectCopyDestinationFolderStep)
console.log('isReplacingRootFolderStep',isReplacingRootFolderStep)
console.log('<-------------------')*/

    return {
      '-': {
        stepName: modalSubTitle,
        allowBack: () => {
          if (isReplacingRootFolderStep) {
            return true;
          }
          if (isSelectingWOMemfis) {
            return true;
          } else if (rootSelected && !isInitialScreen && !isSelectingDestination) {
            return true;
          } else if (isSelectingDestination && isInitialScreen) {
            return true;
          } else if ((isSelectingDestination && !isInitialScreen) || (isInitialScreen && !isSelectingWOMemfis && !isSelectingDestination)) {
            return true;
          } else if (isIngestScreen || (ingestFiles && isFilesScreen)) {
            return true;
          }
          return false;
        },
        onBack: () => {
          if (isSelectingWOMemfis) {
            onGotoScreen();
          } else if (isIngestScreen && addToWoFileRootFolderWO) {
            onChangeStatusToAddFile('isIngestScreen', false);
            setTimeout(() => {
              this.setRootSelected(false);
            });
          } else if (isInnerFileIngestScreen && addToWoFileRootFolderWO) {
            onChangeStatusToAddFile('isInnerFileIngestScreen', false);
            setTimeout(() => {
              this.setRootSelected(false);
            });
          } else if (rootSelected && isIngestScreen) {
            onShowIngestModal(addToWoIngestData, currentWOMemfis);
            this.setRootSelected(false);
          } else if (rootSelected && !isInitialScreen && !isSelectingDestination) {
            this.setRootSelected(false);
          } else if (!rootSelected && isFilesScreen && ingestFiles) {
            onChangeStatusToAddFile('isFilesScreen', false);
            onShowIngestModal(addToWoIngestData, currentWOMemfis);
          } else if (!rootSelected && isIngestScreen) {
            onChangeStatusToAddFile('isIngestScreen', false);
          } else if (isSelectingDestination && isInitialScreen) {
            onGotoScreen('isInitialScreen', fsRoots.filter(r => r.fsRootID === (currentDestination.fsRootID || currentFolder.fsRootID)));
          } else if (isReplacingRootFolderStep) {
            this.handleClickBackBtn();
          } else if ((isInitialScreen && !isSelectingWOMemfis && !isSelectingDestination) && (!showWoStepsAddToWo && woSteps && woSteps.length > 0)) {
            onGotoScreen('isWoStepsScreen');
            onShowWoStepsModal();
          } else if ((isSelectingDestination && !isInitialScreen) || (isInitialScreen && !isSelectingWOMemfis && !isSelectingDestination) || skipSelectCopyDestinationFolderStep) {
            this.handleClickBackBtn();
            if (skipSelectCopyDestinationFolderStep) {
              this.setRootSelected(false);
            }
          }
          return '-';
        },
        allowContinue: () => {
          if (disneySelection) {
            return true;
          }
          if (isReplacingRootFolderStep) {
            return true;
          }
          if (isSelectingDestination) {
            return !(!selectedFolder || !isDestinationAvailable);
          }
          if (isSelectingWOMemfis) {
            return false;
          }
          if (isInitialScreen) {
            return isSubmitEnabled;
          }
          if (rootSelected || isInitialRoot) {
            return !(!isNextEnabled || !!warningNotReplacementForMultiFolders);
          }
          return !(!selectedRoot.label || !currentWOMemfis.length > 0 || !!warningNotReplacementForMultiFolders);
        },
        getLabelContinue: () => {
          if (isInitialScreen && proxyCopyAvailable && isCreateQODProxyChecked) {
          } else if (isSelectingDestination) {
          } else if (isSelectingWOMemfis) {
          } else if (isReplacingRootFolderStep) {
            return 'Submit';
          } else if (isInitialScreen) {
            return 'Submit';
          } else if(disneySelection) {
            return 'Submit';
          } else if (rootSelected || isInitialRoot || skipSelectCopyDestinationFolderStep) {
            if (needToAddWoWithoutSymlinkForRootFolderOrSubfolders || (addToWoFileRootFolderWO && isInnerFileIngestScreen)) {
              return 'Submit';
            }
          }
          return 'Continue';
        },
        onContinue: () => {
          if (isInitialScreen && proxyCopyAvailable && isCreateQODProxyChecked) {
            onCreateProxy(checkedItems[0]);
          } else if (isSelectingDestination) {
            onConfirm(selectedFolder);
          } else if (isSelectingWOMemfis) {
          } else if (isReplacingRootFolderStep) {
            onSubmit(frameRateValue, {
              ...submitData,
              parameters: {
                ...submitData.parameters,
                DESTINATION: existed_WO_root_directory,
                REPLACE_ROOT_FOLDER: true,
                ADD_WO_MEM_SUFFIX: false,
                ADD_WO_MEM_SUFFIX_FILE: false,
                CREATE_WO_SYMLINK_ROOT_DIRECTORY: false,
                SEND_CAPTIONS_TO_QOD: checkedCaptionsSendToQod
              }
            }, false, false, null, woSteps);
          } else if (isInitialScreen) {
            onSubmit(frameRateValue, submitData, isDirectoryAsRootChecked, isCreateSymlinkChecked, replacementTypeValue);
          } else if (rootSelected || isInitialRoot || skipSelectCopyDestinationFolderStep) {
            if (skipSelectCopyDestinationFolderStep) {
              this.setRootSelected(true);
            }
            if (showWoStepsAddToWo) {
              onGotoScreen('isWoStepsScreen');
              onShowWoStepsModal();
            } else if (addToWoFileRootFolderWO) {
              if (!isInnerFileIngestScreen) {
                onGotoScreen('isInnerFileIngestScreen');
            if (needToAddWoWithoutSymlinkForRootFolderOrSubfolders) {
                  onSubmit(frameRateValue, submitData, isDirectoryAsRootChecked, false, replacementTypeValue, woSteps);
            } else if (addToWoFileRootFolderWO) {
              if (!isIngestScreen) {
                onGotoScreen('isIngestScreen');
              } else {
                    onSubmit(frameRateValue, submitData, isDirectoryAsRootChecked, false, replacementTypeValue, woSteps);
              }
            } else {
              if (needToAddWoWithoutSymlinkForRootFolderOrSubfolders) {
                    onSubmit(frameRateValue, submitData, isDirectoryAsRootChecked, false, replacementTypeValue, woSteps);
              } else {
                this.handleClickNextBtn();
              }
            }
              } else {
                onSubmit(frameRateValue, submitData, isDirectoryAsRootChecked, false, replacementTypeValue, woSteps);
              }
            } else {
              if (needToAddWoWithoutSymlinkForRootFolderOrSubfolders) {
                onSubmit(frameRateValue, submitData, isDirectoryAsRootChecked, false, replacementTypeValue, woSteps);
              } else {
                this.handleClickNextBtn();
              }
            }
          } else if (showWoStepsAddToWo) {
            onGotoScreen('isWoStepsScreen');
            onShowWoStepsModal();
          } else if (showIngestSettings && (!isIngestScreen || !isFilesScreen)) {
            if (!isIngestScreen) {
              onGotoScreen('isIngestScreen');
              onShowIngestModal(ingestOperationTypes, currentWOMemfis);
            }
          } else if (disneySelection) {
            onSubmitCopy(copySubmitData);
          } else {
            this.setRootSelected(true);
          }
          return '-';
        }
      }
    };
  };

  renderReplacingRootFolderStepBody() {
    const {fsRoots, checkedItems, currentWOMemfis, existed_WO_root_directory} = this.props;
    return (
      <InitialLayoutStyled>
        <CustomPathFieldStyled>
          <label>WO#</label>
          <div>
            {currentWOMemfis}
          </div>
        </CustomPathFieldStyled>
        <CustomPathFieldStyled>
          <label>Old root</label>
          <div className="content destination-wrapper">
            <TextEllipsisAdvanced
              className="destination-value"
              data={preparePathForOsWithoutRoot(existed_WO_root_directory, fsRoots)}
            />
          </div>
        </CustomPathFieldStyled>
        <CustomPathFieldStyled>
          <label>New root</label>
          <div className="content destination-wrapper">
            <TextEllipsisAdvanced
              className="destination-value"
              data={preparePathForOsWithoutRoot(checkedItems[0].key, fsRoots)}
            />
          </div>
        </CustomPathFieldStyled>
      </InitialLayoutStyled>
    );
  }

  render() {
    const {selectedFolder, rootSelected, wasBackBtnClicked} = this.state;
    const {
      checkedItems,
      currentFolder,
      isInitialScreen,
      isFilesScreen,
      disneySelection,
      disneySelectionWO,
      addToWoFileRootFolderWO,
      isSelectingWOMemfis,
      isSelectingDestination,
      isDirectoryAsRootChecked,
      isDestinationAvailable,
      ifNotAvailableMessage,
      isSaveEnabled,
      currentWOMemfis,
      replacementData,
      runLKFSanalysis,
      AMBCurrent,
      ADD_TO_MEMFIS_WO,
      isCreateSymlinkChecked,
      replacementTypeValue,
      source_already_linked,
      existed_WO_root_directory,
      ingestFiles,
      ingestCopyFolder,
      onChangeIngestFiles,
      isLKFSSubmit,
      isErrorNumberFrameRate,
      errorQcProject,
      isWoStepsAddToWoNext,
      isWoStepsAddToWoBack,
      showWoStepsAddToWo,
      woSteps,
      setWoStepsAddToWoNext,
      setWoStepsAddToWoBack,
      showWoStepsAddToWoModal
    } = this.props;

    if (isWoStepsAddToWoNext) {
      this.handleClickNextBtn();
      setWoStepsAddToWoNext(false);
    }

    if (isWoStepsAddToWoBack) {
      this.handleClickBackBtn()
      setWoStepsAddToWoBack(false);
      if (!showWoStepsAddToWo && woSteps && woSteps.length > 0) {
        showWoStepsAddToWoModal(true);
      }
    }

    const isCheckedFoldersOnly = checkedItems.every(({isDirectory}) => isDirectory);

    var isCreateSymlinkDisabled = !isCheckedFoldersOnly || source_already_linked || existed_WO_root_directory;
    isCreateSymlinkDisabled = isCreateSymlinkDisabled ? true : false;
    var isCreateSymlinkCheckedLocal = replacementData.isReplacement ? false : (isCreateSymlinkDisabled ? true : isCreateSymlinkChecked);

    const isShowUnavailableCreateSymlinkAlert = () => {
        return (checkedItems || []).some(({isDirectory}) => isDirectory) && isCreateSymlinkCheckedLocal && !isCreateSymlinkDisabled;
    };

    const isSubmitEnabled = !isShowUnavailableCreateSymlinkAlert()
                              && ((runLKFSanalysis && isLKFSSubmit && !isErrorNumberFrameRate && !errorQcProject) || !runLKFSanalysis)
                              && (
                                  (isSaveEnabled || (replacementData.isReplacement && currentWOMemfis))
                                  && ((runLKFSanalysis && AMBCurrent > 0) || !runLKFSanalysis)
                              );

    const isMultiFolders = isCheckedFoldersOnly && checkedItems.length > 1;
    const warningNotReplacementForMultiFolders = isMultiFolders && replacementData.isReplacement === false;

    let preselectedRoot;
    if (ADD_TO_MEMFIS_WO.crossRootMapping) {
      const  crossRoots = ADD_TO_MEMFIS_WO.crossRootMapping[currentFolder.fsRootID];
      if (crossRoots && crossRoots.length === 1) {
        preselectedRoot = crossRoots[0];
      }
    }
    const isInitialRoot = currentFolder.fsRootID === rootSelected.value || (preselectedRoot && currentFolder.fsRootID === preselectedRoot);

    return <StyledLkfsModal
      onRef={ref => {
        this.wizard = ref;
      }}
      wizardName="Add to WO"
      bsSize="large"
      width={isSelectingWOMemfis ? '90%' : undefined}
      dialogClassName={isSelectingWOMemfis ? 'lkfs-modal-large' : `lkfs-modal${!isSelectingDestination && !isInitialScreen && !rootSelected && !isInitialRoot ? ' add-to-wo--first-step' : ''}`}
      onCancel={this.handleClickCancelBtn}
      forceConfirmOnCancel={isSelectingDestination || isSelectingWOMemfis || isInitialScreen || isSubmitEnabled}
      steps={this.buildSteps(isSubmitEnabled, isInitialRoot, warningNotReplacementForMultiFolders)}
      getChildren={step => (
        <>
          {
            this.isReplacingRootFolderStep(isInitialRoot) ? this.renderReplacingRootFolderStepBody() :
            isSelectingDestination ?
              <SelectDestinationLayoutConnected
                selectedFolder={selectedFolder}
                setSelectedFolder={(folder) => this.setSelectedFolder(folder)}
              /> :
                isSelectingWOMemfis ?
                  <SelectWOMemfisScreenConnected
                    setCreateSymlinkChecked={this.setCreateSymlinkChecked}
                  /> :
                    isInitialScreen ?
                      <InitialScreenConnected
                        isCreateSymlinkChecked={isCreateSymlinkChecked}
                        setCreateSymlinkChecked={this.setCreateSymlinkChecked}
                        replacementTypeValue={replacementTypeValue}
                        setReplacementTypeValue={this.setReplacementTypeValue}
                        wasBackBtnClicked={wasBackBtnClicked}
                        addToWoFileRootFolderWO={addToWoFileRootFolderWO}
                      /> :
                        <SelectRootScreenConnected
                          rootSelected={rootSelected}
                          isFilesScreen={isFilesScreen}
                          disneySelection={disneySelection}
                          disneySelectionWO={disneySelectionWO}
                          addToWoFileRootFolderWO={addToWoFileRootFolderWO}
                          ingestFiles={ingestFiles}
                          ingestCopyFolder={ingestCopyFolder}
                          isInitialRoot={isInitialRoot}
                          warningNotReplacementForMultiFolders={warningNotReplacementForMultiFolders}
                          replacementData={replacementData}
                          onChangeIngestFiles={onChangeIngestFiles}
                          setCreateSymlinkChecked={this.setCreateSymlinkChecked}
                        />
          }
          {!isDestinationAvailable &&
            isDirectoryAsRootChecked &&
            selectedFolder &&
            !isSelectingWOMemfis &&
            <span className='error-message'>{ifNotAvailableMessage}    </span>
          }
        </>
      )}
    />;
  }
}

export default connect(
  function mapState(state) {
    const {
      disneySelection,
      disneySelectionWO,
      addToWoFileRootFolderWO,
      addFileToWoModalState,
      checkedItems,
      fsRoots,
      currentFolder,
      availableActionsBySources,
      addToWoIngestData,
      actionsUserPermissionsWithRoots: {ADD_TO_MEMFIS_WO},
      LKFSModalState,
      showWoStepsAddToWo,
      isWoStepsAddToWoNext,
      isWoStepsAddToWoBack,
      woSteps
    } = state.storageManager;
    const {
      currentDestination,
      currentCopyDestination,
      currentWOMemfis,
      initialWOMemfis,
      isInitialScreen,
      isIngestScreen,
      isInnerFileIngestScreen,
      isFilesScreen,
      isSelectingWOMemfis,
      isSelectingDestination,
      isDirectoryAsRootChecked,
      isDestinationAvailable,
      isDestinationChanged,
      isCreateQODProxyChecked,
      proxyCopyAvailable,
      ifNotAvailableMessage,
      selectedRoot,
      source_already_linked,
      default_copy_destination,
      existed_WO_root_directory,
      replacementData,
      addFileToWOSuffix,
      addFileToWOSuffixFile,
      runLKFSanalysis,
      AMBCurrent,
      isCreateSymlinkChecked,
      replacementTypeValue,
      deliveryRenameAvailable,
      ingestFiles,
      ingestCopyFolder,
      showIngestSettings,
      ingestOperationTypes,
      showCaptionsSendToQod,
      checkedCaptionsSendToQod
    } = addFileToWoModalState;
    const {isLKFSSubmit, isErrorNumberFrameRate, errorQcProject, frameRate, dbFrameRate, newFrameRate, errorType, isEnable} = LKFSModalState;
    return {
      fsRoots,
      checkedItems,
      currentFolder,
      selectedRoot,
      availableActionsBySources,
      isInitialScreen,
      isIngestScreen,
      isInnerFileIngestScreen,
      isFilesScreen,
      disneySelection,
      disneySelectionWO,
      addToWoFileRootFolderWO,
      isSelectingWOMemfis,
      isSelectingDestination,
      isSaveEnabled: selectIsSaveEnabledForAddFileToWO(state),
      initialWOMemfis,
      isDirectoryAsRootChecked,
      isDestinationAvailable,
      isDestinationChanged,
      isCreateQODProxyChecked,
      proxyCopyAvailable,
      ifNotAvailableMessage,
      currentDestination,
      currentCopyDestination,
      default_copy_destination,
      existed_WO_root_directory,
      currentWOMemfis,
      source_already_linked,
      replacementData,
      runLKFSanalysis,
      AMBCurrent,
      ADD_TO_MEMFIS_WO,
      isCreateSymlinkChecked,
      replacementTypeValue,
      deliveryRenameAvailable,
      ingestFiles,
      ingestCopyFolder,
      showIngestSettings,
      ingestOperationTypes,
      showCaptionsSendToQod,
      showWoStepsAddToWo,
      addToWoIngestData,
      isWoStepsAddToWoNext,
      isWoStepsAddToWoBack,
      woSteps,
      isLKFSSubmit,
      isErrorNumberFrameRate,
      errorQcProject,
      frameRateValue: {
        'frameRate': frameRate,
        'dbFrameRate': dbFrameRate,
        'newFrameRate': newFrameRate,
        'errorType': errorType,
        'woMemfis': currentWOMemfis,
        'isEnable': isEnable
      },
      submitData: {
        actionType: FS_ACTION_TYPE__ADD_TO_MEMFIS_WO,
        fsRootID: checkedItems[0].fsRootID,
        parameters: {
          ContentIDs: checkedItems.map(({contentID}) => String(contentID)),
          SOURCE: checkedItems.length === 1 ? checkedItems[0].key : checkedItems.map(({key}) => `"${key}"`).join(','),
          WO_MEMFIS: currentWOMemfis,
          DESTINATION: currentDestination.key,
          ADD_WO_MEM_SUFFIX: addFileToWOSuffix,
          ADD_WO_MEM_SUFFIX_FILE: addFileToWOSuffixFile,
          SEND_CAPTIONS_TO_QOD: checkedCaptionsSendToQod
        }
      },
      /*dtk TODO: need add correct parameters for multiple copy files to directories */
      copySubmitData: {
        actionType: FS_ACTION_TYPE__COPY,
        fsRootID: checkedItems[0].fsRootID,
        fsRootID2: checkedItems[0].fsRootID,
        parameters: {
          ContentIDs: checkedItems.map(({contentID}) => String(contentID)),
          //ContentIDs: ingestFiles ? ingestFiles.filter(item => item.selected).map(item => item.content_id) : null,
          SOURCE: checkedItems.length === 1 ? checkedItems[0].key : checkedItems.map(({key}) => `"${key}"`).join(','),
          DESTINATION: ingestCopyFolder,
          //COMMON_SOURCE_PATH: checkedItems.length === 1 ? checkedItems[0].key : checkedItems.map(({key}) => `"${key}"`).join(','),
        }
      }
    };
  },
  function mapDispatch(dispatch) {
    return {
      onCloseModal: () => dispatch(closeModalAddFileToWO()),
      onGotoScreen: (newScreen, fsRoots) => {
        dispatch(onGotoScreenToAddFile(newScreen, fsRoots))
      },
      onConfirm: (selectedFolder) => dispatch(chooseDestinationToAddFile(selectedFolder, true)),
      onCreateProxy: (contentItem) => dispatch(getCopyData(contentItem, true)),
      checkFolderAvailability: (key, fsRootID, contentID) => dispatch(makeValidateDirectoryAjax(key, fsRootID, contentID)),
      onSubmit: (frameRateValue, data, isDirectoryAsRootChecked, isCreateSymlinkChecked, replacementTypeValue, woSteps) => {
        if (frameRateValue && frameRateValue.isEnable) {
          const newFrameRate = frameRateValue.newFrameRate;
          const frameRate = frameRateValue.frameRate;
          const woMemfis = frameRateValue.woMemfis;
          const errorType = frameRateValue.errorType;
          if (errorType !== null && errorType !== "success") {
            if (newFrameRate !== null) {
              dispatch(setFrameRate(newFrameRate, true, woMemfis));
            } else if (frameRate !== null) {
              dispatch(setFrameRate(frameRate, false, woMemfis));
            } else {
              dispatch(showError(`Frame Rate not set`));
              return;
            }
          } else if (errorType === "success") {
            if (newFrameRate !== null && frameRate !== null && newFrameRate !== frameRate) {
              dispatch(setFrameRate(newFrameRate, true, woMemfis));
            } else if (newFrameRate === null && frameRate !== null) {
              dispatch(setFrameRate(frameRate, false, woMemfis));
            } else {
              dispatch(showError(`Frame Rate not set`));
              return;
            }
          }
        }

        let steps = null;
        if (woSteps) {
          steps = woSteps
          .filter((step) => step.activate)
          .map(({step_id}) => step_id);
          data.woSteps = steps;
        }

        dispatch(addFileToWO(data, isDirectoryAsRootChecked || false, isCreateSymlinkChecked, replacementTypeValue));
        dispatch(closeModalAddFileToWO());
      },
      onSubmitCopy: (data) => {
        dispatch(copyForAddFileToWO(data));
        dispatch(closeModalAddFileToWO());
      },
      onClearAddToWoModal: () => dispatch(clearAddToWoModal()),
      onSetSelectedRoot: (selection) => {
        dispatch(setSelectedRoot(selection));
      },
      onSetIsCreateSymlinkChecked: (value) =>
        dispatch({
          type: STORAGE_MNG_SET_IS_CREATE_SYMLINK_CHECKED,
          isCreateSymlinkChecked: value,
        }),
      onSetReplacementTypeValue: (value) =>
        dispatch({
          type: STORAGE_MNG_SET_REPLACEMENT_TYPE_VALUE,
          replacementTypeValue: value,
        }),
      onChangeIngestFiles: (row) => dispatch(changeIngestFiles(row)),
      onShowIngestModal: (ingestOperationTypes, woMemfisId) => dispatch(showIngestModal(ingestOperationTypes,  {woMemfisId: woMemfisId}, false)),
      onShowWoStepsModal: () => dispatch(showWoStepsModal(true)),
      setWoStepsAddToWoNext: (isNext) => dispatch(setWoStepsAddToWoNext(isNext)),
      setWoStepsAddToWoBack: (isBack) => dispatch(setWoStepsAddToWoBack(isBack)),
      showWoStepsAddToWoModal: (isShow) => dispatch(showWoStepsAddToWoModal(isShow)),
      onChangeStatusToAddFile: (newScreen, status) => dispatch(changeStatusToAddFile(newScreen, status)),
      loadDisneyFiles: (contentID, fsRootID, woMemfisId) => dispatch(loadDisneyFiles(contentID, fsRootID, woMemfisId))
    };
  }
)(AddFileToWoModal);
/*#endregion */
