const buildItemKey = item => {
  return [item.link_id, item.file_name, item.requestID, item.WOMemfis, item.CategoryName, item.package_id, item.RootActionID].join('__');
};

const buildUploadItemKey = item => {
  return ['upload-item', item.upload_id, item.mam_file_id, item.file_id].join('__');
};

export const isEqualsItems = (item1, item2) => {
  if (item1.is_manual || item2.is_manual) {
    return item1.is_manual === item2.is_manual && item1.message_id === item2.message_id;
  }
  if (item1.upload_id || item2.upload_id) {
    return buildUploadItemKey(item1) === buildUploadItemKey(item2);
  }
  return buildItemKey(item1) === buildItemKey(item2);
};


export const parseStaleOrFailedStatus = status => {
  return /\b(STALE|FAILED)\b:\s*(\d+)/i.exec(status);
};

export const WoQcStatus = {
  UNKNOWN: {label: ['UNKNOWN']},
  UNLINK_IN_PROGRESS: {label: ['UNLINK IN PROGRESS']},
  UNKNOWN_ERROR: {label: ['UNKNOWN ERROR'], color: '#ff0000'},
  DOWNLOADING_FAILED: {label: ['DOWNLOADING FAILED'], color: '#884400'},
  CANCELLED: {label: ['CANCELLED']},
  ASSET_REQUIRED: {label: ['ASSET REQUIRED'], color: '#2007ec'},
  ASSET_REQUESTED: {label: ['ASSET REQUESTED'], color: '#2007ec'},
  NOT_STARTED: {label: ['NOT STARTED'], color: '#ff4400'},
  DOWNLOADING: {label: ['DOWNLOADING'], color: '#17a2b8'},
  DOWNLOADED: {label: ['DOWNLOADED']},
  PENDING_MOVE: {label: ['PENDING MOVE']},
  PENDING_FINAL_MOVE: {label: ['PENDING FINAL MOVE']},
  FAILED: {label: ['FAILED'], color: '#ff4400'},
  BARCODING_PLUS_MOVE_STARTED: {label: ['BARCODING+MOVE STARTED'], color: '#07d4f5'},
  BARCODED_AND_MOVED: {label: ['BARCODED AND MOVED'], color: '#07d4f5'},
  INGEST_COMPLETED: {label: ['INGEST COMPLETED', 'COMPLETED'], color: 'green'},
  QC_IN_PROGRESS: {label: ['QC IN PROGRESS'], color: 'grey'},
  READY_FOR_DELIVERY: {label: ['READY FOR DELIVERY'], color: 'black'},
  DELIVERY_EDITING: {label: ['DELIVERY EDITING'], color: 'blue'},
  DELIVERY_APPROVAL: {label: ['DELIVERY APPROVAL'], color: 'blue'},
  DELIVERY_COPY: {label: ['DELIVERY COPY'], color: 'blue'},
  DELIVERY_READY_FOR_UPLOAD: {label: ['DELIVERY READY FOR UPLOAD'], color: 'blue'},
  DELIVERY_UPLOAD_STARTED: {label: ['DELIVERY UPLOAD STARTED'], color: 'black'},
  DELIVERY_COMPLETED: {label: ['DELIVERY COMPLETED'], color: 'green'},
  QC_COMPLETE: {label: ['QC COMPLETE'], color: 'green'},
  DONE: {label: ['DONE'], color: '#018674'},
};

export const WoQcStatuses = Object.fromEntries(Object.keys(WoQcStatus).map(i => ([i, i])));

const getStatusStyleAttr = status => {
  const STATUS_BG_COLOR = {
    'action failed': 'red',
    'action stale': 'yellow',
    'downloading failed': WoQcStatus.DOWNLOADING_FAILED.color,
    'd/l token expired': WoQcStatus.FAILED.color,
    'failed': WoQcStatus.FAILED.color,
    'error': WoQcStatus.FAILED.color,
    'unknown error': WoQcStatus.UNKNOWN_ERROR.color,
    'asset requested': WoQcStatus.ASSET_REQUESTED.color,
    'asset required': WoQcStatus.ASSET_REQUIRED.color,
    'downloading': WoQcStatus.DOWNLOADING.color,
    'not started': WoQcStatus.NOT_STARTED.color,
    'pending': '#888888',
    'ready to move': '#444444',
    'barcoding+move started': WoQcStatus.BARCODING_PLUS_MOVE_STARTED.color,
    'barcoded and moved': WoQcStatus.BARCODED_AND_MOVED.color,
    'moved & marked d/l-ed': '#066022',
    'manually d/l-ed': '#aaaaaa',
    'completed': WoQcStatus.INGEST_COMPLETED.color,
    'uploaded': '#282828'
  };
  let cleanedStatus = status.replace(/[(][^()]*[)]/, '').trim().toLowerCase();
  let color = STATUS_BG_COLOR[cleanedStatus];
  if (!color) {
    const temp = parseStaleOrFailedStatus(cleanedStatus);
    if (temp && temp.length > 1) {
      cleanedStatus = `action ${temp[1]}`;
      color = STATUS_BG_COLOR[cleanedStatus];
    }
    if (!color) {
      const woQcStatusKey = Object.keys(WoQcStatus).find(k => WoQcStatus[k].label.includes(cleanedStatus.toUpperCase()));
      if (woQcStatusKey) {
        color = WoQcStatus[woQcStatusKey].color;
      }
    }
  }
  return color;
};

export const getAdvancedStatuses = data => {
  const resultStatuses = [];
  const isUploadData = !!data.upload_id;
  const statuses = (data.ingest_status_advanced || data.status || '').split(', ');
  const sz = statuses.length;
  const statusesNew = [];
  for (let idx=0; idx<sz; idx++) {
    const status = statuses[idx];
    let statusNew = status.replace(/[(][^()]*[)]/, '').trim().toLowerCase();
    if (!isUploadData && !data.ingest_status_advanced) {
      if (statusNew === 'not started') {
        const secsSinceStarted = data.secs_since_started;
        if (typeof secsSinceStarted !== 'undefined' && secsSinceStarted < 1200) {
          statusNew = 'pending';
        }
      }
    }
    if (statusesNew.indexOf(statusNew) >= 0) {
      continue;
    }
    if (data.is_manual) {
      statusNew = 'manual';
    }
    statusesNew.push(statusNew);
    resultStatuses.push(statusNew.toUpperCase());
  }
  return resultStatuses;
};

export const getStatusAndColor = data => {
  const statusColor = [];
  const url = document.location.href;
  const advanced = url.indexOf('advanced=1') > 0;
  const isUploadData = !!data.upload_id;
  const useIngestStatus = isUploadData || !!data.useIngestStatus;
  const ingestStatus = advanced ? data.ingest_status_advanced : data.ingest_status;
  const linkStatus = (useIngestStatus ? ingestStatus : '') || data.status || '';
  const statuses = linkStatus.split(', ');
  const sz = statuses.length;
  const statusesNew = [];
  for (let idx=0; idx<sz; idx++) {
    const status = statuses[idx];
    let statusNew = status.replace(/[(][^()]*[)]/, '').trim().toLowerCase();
    const extraPos = status.indexOf('(');
    const extra = extraPos < 0 ? '' : ' ' +  status.substring(extraPos);
    if (!isUploadData && (!data.useIngestStatus || !ingestStatus)) {
      if (statusNew === 'not started') {
        const secsSinceStarted = data.secs_since_started;
        if (typeof secsSinceStarted !== 'undefined' && secsSinceStarted < 1200) {
          statusNew = 'pending';
        }
      }
      if (!advanced) {
        if (statusNew === 'downloading failed' || statusNew === 'd/l token expired') {
          statusNew = 'failed';
        } else if (statusNew === 'completed') {
          statusNew = 'ready to move';
        } else if (statusNew === 'moved & marked d/l-ed' || statusNew === 'manually d/l-ed') {
          statusNew = 'ingest completed';
        }
      }
    }
    if (statusesNew.indexOf(statusNew) >= 0) {
      continue;
    }
    if (data.is_manual) {
      statusNew = 'manual';
    }
    const color = getStatusStyleAttr(statusNew);
    statusesNew.push(statusNew);
    statusColor.push({status: statusNew.toUpperCase(), color, extra});
  }
  return statusColor;
};

export const calcMaxPriorityAndMarkLinksToChangePriority = (links, statusesBeforeDownloading, statusesWithChangePriority) => {
  let priority;
  links.forEach(link => {
    const linkStatuses = getAdvancedStatuses(link);
    if (linkStatuses.length) {
      if (typeof link.Priority === 'number' && statusesBeforeDownloading.find(s => s.statusNames.includes(linkStatuses[0]))) {
        priority = Math.max(priority || 0, link.Priority);
      }
      if (statusesWithChangePriority && statusesWithChangePriority.find(s => s.statusNames.includes(linkStatuses[0]))) {
        link.allowChangePriority = true;
      }
    }
  });
  return priority;
};
