/* eslint "jsx-a11y/anchor-is-valid":"off" */
import React from 'react';
import classNames from 'classnames';
import BaseFolder from '../../../StorageManager/components/react-keyed-file-browser/base-folder.js';
import {CheckboxCell} from '../../../../components/table/cells/CheckboxCell';

class TableFolder extends BaseFolder {
  render() {
    const {isOpen, inProgress, children, isRoot, fsRootName, memfisWoID, barCode, isChecked, onChange} = this.props;

    let icon;
    if (inProgress) {
      icon = <i className="fa fa-cog fa-spin"/>;
    } else if (isOpen) {
      icon = <i className="far fa-folder-open" aria-hidden="true"/>;
    } else {
      icon = <i className="far fa-folder" aria-hidden="true"/>;
    }

    const name = (
      <div>
        <a>
          {icon}
          {isRoot ? fsRootName : this.getName()}
        </a>
      </div>
    );

    const folder = (
      <tr
        className={classNames({'folder': true,
          pending: (this.props.action),
          selected: this.props.isSelected,
          'folder-root': isRoot
        })}
        onClick={this.handleFolderClick}
        >
        <CheckboxCell row={this.props} isChecked={isChecked} onChange={onChange}/>
        <td className="name">
          <div style={{paddingLeft: (this.props.depth * 16) + 'px'}}>
            {name}
            {
              !inProgress && isOpen && (!children || !children.length) ? (
                <div>
                  No files
                </div>
              ) : null
            }
          </div>
        </td>
        <td className="memfis-wo-id">{memfisWoID}</td>
        <td className="bar-code">{barCode}</td>
      </tr>
    );

    return this.connectDND(folder);
  }
}

export default TableFolder;
