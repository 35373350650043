import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, FormGroup, ControlLabel} from 'react-bootstrap';
import styled from 'styled-components';
import {FilterSelectStyled} from '../../../../components/filters/FiltersStyled';
import Select from 'react-select';
import {closeAvailableDrivesModal, connectDrive} from '../../actions';
import {confirmAlert} from 'react-confirm-alert';
import RndModal from '../../../../components/modals/RndModal';

class AvailableDrivesModal extends Component {
  state = {
    availableDrives: this.props.data.availableDrives.map(drive =>
      ({value: drive.DriveLabel, label: drive.DriveLabelAlias, drive})).sort((a, b) => a.label.localeCompare(b.label)),
    selectedDrive: null
  };

  handleSelectDrive = selectedDrive => {
    this.setState({selectedDrive});
  };

  handleConnect = () => {
    const {dispatch, data} = this.props;
    const {fsRoot} = data;
    const {selectedDrive} = this.state;
    const {drive} = selectedDrive;
    confirmAlert({
      title: 'Are You Sure?',
      message: `Are you sure you want to connect external drive ${drive.DriveLabelAlias} to ${fsRoot.label}?`,
      buttons: [{
        label: 'No',
      }, {
        label: 'Yes',
        onClick: () => dispatch(connectDrive(drive, fsRoot))
      }]
    });
  };

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(closeAvailableDrivesModal());
  };

  componentWillUnmount() {
    this.handleClose();
  }

  render() {
    const {data} = this.props;
    const {fsRoot} = data;
    const {availableDrives, selectedDrive} = this.state;
    return (
      <RndModal
        show={true}
        onHide={this.handleClose}
        backdrop="static"
        enforceFocus={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>Connect external drive to <strong>{fsRoot.label}</strong></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <FormGroupStyled>
              <ControlLabel>
                Drive:
              </ControlLabel>
              <SelectStyled>
                <Select
                  value={selectedDrive}
                  options={availableDrives}
                  onChange={this.handleSelectDrive}
                  placeholder="Select Drive"
                />
              </SelectStyled>
            </FormGroupStyled>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.handleConnect} disabled={!selectedDrive}>Connect</Button>
          <Button bsStyle="default" onClick={this.handleClose}>Cancel</Button>
        </Modal.Footer>
      </RndModal>
    );
  }
}


const SelectStyled = styled(FilterSelectStyled)`
  width: 100%;

  .Select.has-value.Select--single,
  .Select.Select--single,
  .Select.is-searchable.Select--single,
  .Select--multi {

    > .Select-control {
      height: 34px;
      .Select-multi-value-wrapper {
        height: 32px;
        .Select-placeholder,
        .Select-value {
          font-size: 13px !important;
          line-height: 14px !important;
          .Select-value-label {
            font-size: 13px !important;
            line-height: 14px !important;
            opacity: 0.65;
          }
        }
        .Select-input {
          padding-left: 9px;
          height: 32px;
          input {
            font-size: 13px !important;
          }
        }
      }
    }

    &.is-open {
      > .Select-control {
        .Select-multi-value-wrapper {
          .Select-placeholder,
          .Select-value {
            .Select-value-label {
              opacity: 1;
            }
          }
        }
      }
    }
  }
`;

const FormGroupStyled = styled(FormGroup)`
  display: flex;
  align-items: center;

  label {
    margin: 0;
    padding: 0 8px 0 0;
    font-weight: bold;
  }

  .form-control {
    width: 100%;
    font-size: 13px;

    &:not(:focus) {
      opacity: 0.65;
    }
  }
`;

AvailableDrivesModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

export default AvailableDrivesModal;
