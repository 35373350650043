import React from 'react';
import styled from 'styled-components';
import {Button} from 'react-bootstrap';

export default function ActionCell({row, onSubmit}) {
  return <TdStyled>
    <Button
      bsStyle='primary'
      bsSize='small'
      title='Choose'
      onClick={() => onSubmit(row)}
    >Choose</Button>
  </TdStyled>;
}

const TdStyled = styled.td`
display: block;
margin: auto;
`