import React, {Component} from 'react';
import {Button, ControlLabel, Modal} from "react-bootstrap";
import {executeMetafierAction, showMetafierModal} from "../../actions";
import PropTypes from "prop-types";
import Select from "react-select";
import styled from "styled-components";
import {PathFieldStyled} from "./ModalStyled";
import RndModal from "../../../../components/modals/RndModal";
import TextEllipsisAdvanced from "../../../../components/TextEllipsis/TextEllipsisAdvanced";

const MetafierTypes = [
  {label: 'Extract metadata XML from Dolby', value: 'EXTRACT_METADATA'},
  {label: 'Modify metadata XML start frame', value: 'MODIFY_START_FRAME'}
];

class MetafierModal extends Component {
  state = {
    selectedMetafierType: 'EXTRACT_METADATA'
  }

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(showMetafierModal(false));
  };

  componentWillMount() {
    const { file } = this.props;

    let selectedMetafierType = 'EXTRACT_METADATA';
    if (file.key.endsWith(".xml")) {
      selectedMetafierType = 'MODIFY_START_FRAME';
    }

    this.setState({
      selectedMetafierType: selectedMetafierType
    });
  }

  componentWillUnmount() {
    this.handleClose();
  }

  handleGenerateMetafier = () => {
    const { selectedMetafierType } = this.state;
    const {file, dispatch} = this.props;
    dispatch(executeMetafierAction(selectedMetafierType, file));
    this.handleClose();
  }

  render() {
    const { selectedMetafierType } = this.state;
    const {file} = this.props;

    return (
      <>
        <RndModal
          show={true}
          onHide={this.handleClose}
          //minHeight={minHeight}
          backdrop={false}
          enforceFocus={false}
          bsSize="large"
        >
          <Modal.Header closeButton>
            <Modal.Title>Metafier</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PathField className="type">
              <label>Type:</label>
              <Select
                placeholder="Choose Metafier type"
                value={selectedMetafierType}
                options={MetafierTypes}
                disabled={true}
                searchable={false}
                clearable={false}
              />
            </PathField>
            <ControlLabel>
              Source:
            </ControlLabel>
            <div style={{color: 'blue', fontWeight: 'bold', marginBottom: '28px'}}>
              <TextEllipsisAdvanced className="value" data={file.key} noCopyCtrl/>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="primary" onClick={this.handleGenerateMetafier}>
              Submit
            </Button>
            <Button bsStyle="default" onClick={this.handleClose}>Cancel</Button>
          </Modal.Footer>
        </RndModal>
      </>
    );
  }
}

const PathField = styled(PathFieldStyled)`
  align-items: flex-start !important;
  justify-content: flex-start !important;

  .Select {
    .Select-menu-outer {
      z-index: 9999;
      max-height: 122px;
      .Select-menu {
        max-height: 120px;
      }
    }
  }

  label {
    flex: 0 0 115px;

    & + div {
      align-items: flex-start !important;

      &.value {
        max-width: calc(100% - 115px);
        font-family: monospace;
      }
    }
  }
`;

MetafierModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
  storageManager: PropTypes.object.isRequired
};

export default MetafierModal;
