import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import classNames from 'classnames';
import {getFolderChildren, changeOpenedFoldersInModal, makeDirectory} from '../../actions';
import {
  filterDataForView, VIEW_ADVANCED_PREDICTIVE, PREDICTIVE_STATE_DISAPPEARED, PREDICTIVE_STATE_APPEARED
} from '../../constants';
import {getValueForOS} from '../../utils';
import {getSortedRowsBy, isValidFileName} from '../../../../utils';
import ChangeDirectoryInput from '../../../../components/ChangeDirectoryInput';
import {ButtonToolbar, Button, FormGroup, FormControl, ControlLabel} from 'react-bootstrap';

export const isEqualsItems = (item1, item2) => {
  return !!item1 && !!item2 && item1.fsRootID === item2.fsRootID && item1.contentID === item2.contentID &&
    (item1.predictiveStateID || 0) === (item2.predictiveStateID || 0) &&
    JSON.stringify(item1.parentsPredictiveStates || []) === JSON.stringify(item2.parentsPredictiveStates || []) &&
    JSON.stringify(item1.parents || []) === JSON.stringify(item2.parents || []);
};

export const isEqualsItemsByKey = (item1, item2) => {
  return !!item1 && !!item2 && item1.key === item2.key && (item1.predictiveStateID || 0) === (item2.predictiveStateID || 0);
};

export const isStartedItemsByKey = (key, item) => {
  return !!key && !!item && item.key !== item.fsRootName && key.startsWith(item.fsRootName) && !item.key.startsWith(key);
};

class FolderExplorerNew extends Component {

  timerId = null;

  state = {
    newFolderNames: {}
  };

  buildFolderKey = folder => {
    const {fsRootID, contentID, parents, predictiveStateID} = folder;
    return `${fsRootID}--${JSON.stringify(parents || [])}--${contentID}--${predictiveStateID || 0}`;
  };

  isOpenedFolder = folder => {
    const {openedFolders} = this.props;
    const key = this.buildFolderKey(folder);
    return openedFolders[key] === true;
  };

  closeChildrenFolders = (openedFolders, children) => {
    if (children) {
      children.forEach(item => {
        if (this.isOpenedFolder(item)) {
          const key = this.buildFolderKey(item);
          openedFolders[key] = false;
        }
      });
    }
  };

  openFolder = (folder, isOpen) => {
    const {children, isDirectory} = folder;
    if (isDirectory) {
      const {dispatch} = this.props;
      const openedFolders = {...this.props.openedFolders};
      const key = this.buildFolderKey(folder);
      openedFolders[key] = isOpen;
      if (!isOpen) {
        this.closeChildrenFolders(openedFolders, children);
      }
      dispatch(changeOpenedFoldersInModal(openedFolders));
    }
  };

  scrollToItem = (elmClass, onScroll) => {
    const {onScrollToItem} = this.props;
    if (elmClass) {
      try {
        document.getElementsByClassName('available--fs-items')[0].getElementsByClassName(elmClass)[0].scrollIntoView();
        if (onScroll) {
          onScroll();
        }
        if (onScrollToItem) {
          onScrollToItem();
        }
      } catch {}
    }
  };

  getFolderToExpand = (folder) =>{
    this.folderToExpand = folder;
  }
  handleClickOpenFolder = (row, segments, forceOpen, needToClickByLastSegment) => {
    const {dispatch, folderToExpand, onSelectFolder, isScrollToElmClass} = this.props;
    if (!forceOpen && this.isOpenedFolder(row)) {
      this.openFolder(row, false);
    } else {
      if (row.contentID >= 0) {
        const callback = segments && segments.length ? (
          (child, isLastSegment) => {
            this.openFolder(child, true);
            if (folderToExpand || this.folderToExpand) {
              if (isLastSegment === true && needToClickByLastSegment === true) {
                this.handleClickSelectFolder(child, true);
                if (isScrollToElmClass) {
                  setTimeout(this.scrollToItem, 100, isScrollToElmClass);
                }
              } else {
                onSelectFolder(child);
              }
            }
          }
        ) : undefined;
        dispatch(getFolderChildren(row, segments, callback, {isCheckSubdirectories: true}));
        if (callback && isScrollToElmClass && this.timerId === null) {
          this.timerId = setInterval(this.scrollToItem, 500, isScrollToElmClass, () => {
            clearInterval(this.timerId);
            this.timerId = null;
          });
        }
      }
      this.openFolder(row, true);
    }
  };

  componentDidMount() {
    const {contentItems, fsRoots, folders, folderToExpand, directoryToExpand, onOpenParentFolder,
      checkChildWithSameFileName
    } = this.props;
    let segments, needToClickByLastSegment;
    if (directoryToExpand) {
      segments = directoryToExpand.split(/[\\/]/).slice(1);
    } else if (folderToExpand) {
      segments = folderToExpand.key.split(/[\\/]/).slice(1);
      if (contentItems && contentItems.length === 1 && !contentItems[0].isDirectory && checkChildWithSameFileName) {
        if (!folderToExpand.isDirectory) {
          segments = segments.slice(0, -1);
        }
        needToClickByLastSegment = true;
      }
    } else if (contentItems && contentItems.length && contentItems[0].parentID && contentItems[0].parentID > 0) {
      let isSameParent = true;
      if (contentItems.length > 1) {
        const firstParent = contentItems[0].parentID;
        for (const item of contentItems) {
          if (item.parentID !== firstParent) {
            isSameParent = false;
            break;
          }
        }
      }
      if (isSameParent) {
        segments = contentItems[0].key.split(/[\\/]/).slice(1).slice(0, -1);
      }
    }
    if (segments) {
      let directoryToExpandRoot;
      if (directoryToExpand && fsRoots) {
        directoryToExpandRoot = fsRoots.find(i => directoryToExpand.startsWith(i.fsRootName));
      }
      const parentFolder =
        folders.find(i => i.fsRootID === (directoryToExpandRoot || folderToExpand || (contentItems || [])[0] || {}).fsRootID);
      if (parentFolder) {
        this.handleClickOpenFolder(parentFolder, segments, true, needToClickByLastSegment);
        if (onOpenParentFolder) {
          onOpenParentFolder();
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {isScrollToElmClass} = this.props;
    if (isScrollToElmClass && isScrollToElmClass !== prevProps.isScrollToElmClass) {
      setTimeout(this.scrollToItem, 100, isScrollToElmClass);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  handleClickSelectFolder = (row, forceGetChildren) => {
    const {
      selectedFolder,
      onSelectFolder,
      isGetChildrenOnSelect,
      selectedRoot,
      dispatch,
    } = this.props;

    if (selectedRoot) {
      const isRoot = row.contentID === selectedRoot.contentID;
      if (isRoot) {
        return;
      }
    }

    const isCurrentSelect = selectedFolder && isEqualsItemsByKey(selectedFolder, row);

    if (isCurrentSelect) {
      return;
    }
    onSelectFolder(row);
    if (isGetChildrenOnSelect && row.contentID >= 0 && row.hasSubDirectory !== false && (forceGetChildren || !this.isOpenedFolder(row))) {
      dispatch(getFolderChildren(row, null, null, {onSelectFolder, isCheckSubdirectories: true}));
    }
  };

  handleClickCreateNewFolder = () => {
    const {newFolderNames} = this.state;
    const {dispatch, selectedFolder} = this.props;
    const newFolderContentID = -Date.now();
    const newFolderName = `NewFolder${Math.abs(newFolderContentID)}`;
    const newFolder = {
      isNewFolder: true,
      isDirectory: true,
      contentID: newFolderContentID,
      fsRootID: selectedFolder.fsRootID,
      parentID: selectedFolder.contentID,
      key: `${selectedFolder.key}\\${newFolderName}`,
      name: newFolderName,
    };
    if (selectedFolder.contentID >= 0) {
      dispatch(getFolderChildren(selectedFolder, null, null, {
        isCheckSubdirectories: true,
        onSelectFolder: () => {
          this.setState({newFolderNames: {...newFolderNames, [newFolderContentID]: newFolderName}});
          setTimeout(() => {
            try {
              const item = document.getElementsByClassName('available--fs-items')[0].getElementsByClassName('is-new-folder')[0];
              const input = item.getElementsByTagName('input')[0];
              item.scrollIntoView();
              input.focus();
            } catch {}
          }, 100);
        },
        newChild: newFolder
      }));
      this.openFolder(selectedFolder, true);
    }
  };

  handleConfirmCreateNewFolder = row => {
    const {dispatch, selectedFolder, onSelectFolder} = this.props;
    const {newFolderNames} = this.state;
    const newFolderName = newFolderNames[row.contentID];
    dispatch(makeDirectory(null, newFolderName, {
      parentFolderInTree: selectedFolder,
      callback: createdFolder => {
        onSelectFolder(createdFolder);
      }
    }));
  };

  handleCancelCreateNewFolder = row => {
    const {dispatch, selectedFolder} = this.props;
    const {newFolderNames} = this.state;
    if (selectedFolder.contentID >= 0) {
      dispatch(getFolderChildren(selectedFolder, null, null, {
        isCheckSubdirectories: true,
        onSelectFolder: () => {
          this.setState({newFolderNames: {...newFolderNames, [row.contentID]: undefined}});
        }
      }));
    }
  };

  hasChildWithSameNameLikeContentItems = () => {
    const {contentItems, selectedFolder} = this.props;
    const children = (selectedFolder || {}).children || [];
    const isEqualsByName = (item1, item2) => item1.name.toLowerCase() === item2.name.toLowerCase();
    return !!contentItems && children.some(child => contentItems.some(cItem => isEqualsByName(child, cItem)));
  };

  hasExistingChildWithSameNameLikeItem = (itemName) => {
    const {selectedFolder} = this.props;
    const children = (selectedFolder || {}).children || [];
    return !!itemName && children.some(i => !i.isNewFolder && i.name.toLowerCase() === itemName.toLowerCase());
  };

  getTextClassName = row => {
    const {currentView, tempDirectories} = this.props;
    if (currentView === VIEW_ADVANCED_PREDICTIVE) {
      const {predictiveStateID, fsRootID, parentID, key} = row;
      const isTempDeleted = !!tempDirectories && Object.keys(tempDirectories.deleted).includes(`r${fsRootID}-p${parentID}-${key}`);
      if (predictiveStateID === PREDICTIVE_STATE_DISAPPEARED || isTempDeleted) {
        return 'strikethrough';
      }
      if (predictiveStateID === PREDICTIVE_STATE_APPEARED) {
        return 'red';
      }
    }
    return '';
  };

  renderRow = (row, index) => {
    const {newFolderNames} = this.state;
    const {contentItems, selectedFolder, checkChildWithSameName, checkChildWithSameFileName,
      loaders, isParentOfSimilarToMigrated
    } = this.props;
    const isCurrentSelect = !!selectedFolder && isEqualsItemsByKey(selectedFolder, row);
    const isCurrent = !!contentItems && contentItems.some(cItem => isEqualsItemsByKey(cItem, row));
    const isDisabled = false; // !!downloadRootDirs && downloadRootDirs.some(gItem => isStartedItemsByKey(gItem, row));
    const isOpened = this.isOpenedFolder(row);
    const depth = !row.parents ? 0 : row.parents.length;
    const textClassName = this.getTextClassName(row);
    const allowStdActions = !!row.isDirectory && !isCurrent && !row.isNewFolder && !isDisabled;
    const newFolderName = newFolderNames[row.contentID] || '';

    const noChildDirs = Array.isArray(row.children) ? !(row.children || []).some((r) => r.isDirectory) : row.hasSubDirectory === false;

    const isParentOfSimilarToMigratedItems = !!isParentOfSimilarToMigrated && isParentOfSimilarToMigrated(row);

    const exclamation = isCurrentSelect && !!(checkChildWithSameName || checkChildWithSameFileName) && this.hasChildWithSameNameLikeContentItems() ?
      `Includes content that has the same name as ${contentItems && contentItems.length > 1 ? 'one of the sources' : 'the source'}` :
        isParentOfSimilarToMigratedItems ? 'Includes content that has the same name as migrated item' : null;

    const onClickFsItemName = (allowStdActions && !isParentOfSimilarToMigratedItems) ? () => this.handleClickSelectFolder(row) : undefined;
    const onClickChevronIcon = allowStdActions ? () => this.handleClickOpenFolder(row) : undefined;
    const popUpItems = this.props.storageManager.displayedItemsInPopup.length? this.props.storageManager.displayedItemsInPopup: this.props.storageManager.displayedItems;
    popUpItems.forEach((item) => {
      if(item.key === row.key){
        item.allParents = row.allParents;
      }
    })

    const disableTitle = isDisabled ? 'Disabled FS item' : undefined;
    const currentTitle = isCurrent ? 'Current FS item' : disableTitle;
    return (
      <FsItem
        key={index}
        className={classNames({
          [textClassName]: true,
          current: isCurrent,
          'is-directory': !!row.isDirectory,
          'is-new-folder': !!row.isNewFolder,
          'is-disabled': !!isDisabled,
          selected: isCurrentSelect,
          'selection-would-be-invalid': isParentOfSimilarToMigratedItems,
          'hidden-chevron-icon': !row.isNewFolder && noChildDirs
        })}
        depth={depth}
        title={row.isNewFolder ? undefined : currentTitle}
      >
        <i
          className={row.isNewFolder ? ((loaders.mkDir || loaders.afterMkDir) ? 'fas fa-spinner fa-spin fa-xs fa-fw' : 'fas fa-pen-field fa-xs fa-fw') : `fas fa-chevron-${isOpened ? 'down' : 'right'} fa-xs fa-fw`}
          title={(row.isNewFolder || isCurrent || isDisabled) ? undefined : isOpened ? 'Collapse' : 'Expand'}
          onClick={onClickChevronIcon}
        />
        <i className="far fa-folder"/>
        {
          !row.isNewFolder ? <span className="fs-item-name" onClick={onClickFsItemName}>{!depth ? getValueForOS(row, 'name') : row.name}</span> :
            (loaders.mkDir || loaders.afterMkDir) ? <span>{newFolderName}</span> :
            <>
              <FormGroup validationState={!isValidFileName(newFolderName) ? 'error' : this.hasExistingChildWithSameNameLikeItem(newFolderName) ? 'warning' : 'success'}>
                <FormControl
                  type="text"
                  placeholder="Enter folder name"
                  value={newFolderName}
                  onChange={({target: {value}}) => {
                    this.setState({newFolderNames: {...newFolderNames, [row.contentID]: value}});
                  }}
                  title={this.hasExistingChildWithSameNameLikeItem(newFolderName) ? 'The parent folder contains content that has the same name' : undefined}
                />
              </FormGroup>
              <button
                title="Save"
                onClick={() => this.handleConfirmCreateNewFolder(row)}
                disabled={!isValidFileName(newFolderName)}
              >
                <i className="far fa-check-circle nf-btn"/>
              </button>
              <button
                title="Cancel"
                onClick={() => this.handleCancelCreateNewFolder(row)}
              >
                <i className="fas fa-trash nf-btn"/>
              </button>
            </>
        }
        {
          !!exclamation && <i className={`fas fa-exclamation-${checkChildWithSameFileName ? 'triangle' : 'circle'}`} title={exclamation}/>
        }
      </FsItem>
    );
  };

  renderRows = (rows, depth = 0, parent) => {
    if (!rows) {
      return null;
    }

    const {currentView, tempDirectories, selectedFolder} = this.props;
    const tempAdded = [];
    if (parent && tempDirectories) {
      Object.keys(tempDirectories.added).forEach(k => {
        if (k.startsWith(`r${parent.fsRootID}-p${parent.contentID}-`) &&
            !rows.some(row => row.isDirectory && row.key === tempDirectories.added[k].key)) {
          tempAdded.push({
            ...tempDirectories.added[k],
            predictiveStateID: PREDICTIVE_STATE_APPEARED,
            parents: [...parent.parents || [], parent]
          });
        }
      });
    }
    const dataForView = filterDataForView([...rows, ...tempAdded], currentView)[0];
    const data = dataForView.filter(r => r.isDirectory && (!r.isNewFolder || (selectedFolder && r.fsRootID === selectedFolder.fsRootID && r.parentID === selectedFolder.contentID)));
    const sortedRows = getSortedRowsBy(data, 'name', 'asc');
    return (
      <Fragment>
        {
          !sortedRows.length ? null :
            sortedRows.map((row, i) => (
              <Fragment key={`f-${i}`}>
                {this.renderRow(row, i)}
                {this.isOpenedFolder(row) ? this.renderRows(row.children, !row.parents ? 0 : row.parents.length, row) : null}
              </Fragment>
            ))
        }
      </Fragment>
    );
  };

  render() {
    const {folders, linkToWo, selectedFolder, withoutNewFolders, subHeight, selectedFolderLabel, className, isHidden,
      emptyAvailableFsItemsText, selectedFolderLabelWidth, storageManager, dispatch, onSelectFolder
    } = this.props;
    const isShowEmptyText = !folders.length && emptyAvailableFsItemsText;
    return (
      <StyledExplorer className={`available--fs-items${className ? ` ${className}` : ''}${isHidden ? ' hidden' : ''}`}>
        <StyledSelectedFolderField style={selectedFolderLabelWidth ? {gridTemplateColumns: `${selectedFolderLabelWidth}px calc(100% - ${selectedFolderLabelWidth}px)`} : undefined}>
          <ControlLabel className="selected-folder--label">{selectedFolderLabel || 'Selected Folder:'}</ControlLabel>
          <FormControl.Static componentClass="div">
            <ChangeDirectoryInput value={selectedFolder? getValueForOS(selectedFolder, 'key'): ' '}
                                  storageManager={storageManager}
                                  dispatch={dispatch}
                                  onSelectFolder={onSelectFolder}
                                  selectedFolder={selectedFolder}
                                  scrollToItem={this.scrollToItem}
                                  renderRows={this.renderRows}
                                  handleClickOpenFolder = {this.handleClickOpenFolder}
                                  getFolderToExpand = {this.getFolderToExpand}
            />
          </FormControl.Static>
        </StyledSelectedFolderField>
        {!withoutNewFolders &&
          <StyledButtonToolbar>
            <Button
              bsSize="small"
              title="Create New Folder"
              disabled={
                !selectedFolder ||
                !!selectedFolder.isNewFolder ||
                (selectedFolder.children || []).some(i => !!i.isNewFolder)
              }
              onClick={this.handleClickCreateNewFolder}
            >
              <i className="fas fa-folder-plus"/>
            </Button>
          </StyledButtonToolbar>
        }
        <StyledContainer
          subHeight={`${subHeight || (linkToWo ? 500 : 360)}px`}
          isShowEmptyText={isShowEmptyText}
        >
          {isShowEmptyText ? emptyAvailableFsItemsText : this.renderRows(folders)}
        </StyledContainer>
      </StyledExplorer>
    );
  }
}

const StyledExplorer = styled.div`
  &.hidden {
    display: none;
  }
`;

const StyledButtonToolbar = styled(ButtonToolbar)`
  margin-bottom: 5px;
`;

const StyledSelectedFolderField = styled(FormGroup)`
  display: grid;
  grid-template-columns: 130px calc(100% - 130px);

  label {
    margin-bottom: 0;
    white-space: nowrap;
    height: 34px;
    line-height: 34px;
    padding: 0;
  }

  .form-control-static {
    padding: 0;
    min-height: 20px;
    font-weight: bold;
    color: blue;
  }
`;

const StyledContainer = styled.div`
  height: calc(100vh - ${props => props.subHeight});
  overflow-y: auto;
  border: 2px solid #e2e2e2;
  padding: 10px 0 10px 15px;

  ${props => props.isShowEmptyText && css`
    font-size: 12px;
    opacity: 0.65;
    text-align: center;
  `}

  .loader-container {
    z-index: 1051;
  }

  .empty {
    font-size: 9px;
  }
`;

const FsItem = styled.div`
  margin-left: -15px;
  padding-left: ${props => props.depth * (13/*chevron icon width*/ /* + 3*//*icon margin-right*/ - 5/*left shift*/)}px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;

  i {
    margin-right: 3px;

    :first-child {
      margin-left: 13px;
    }

    &[class*="exclamation"] {
      margin-left: 5px;
      margin-right: 0;
      border-radius: 50%;
      cursor: default !important;
      color: red;
      &.fa-exclamation-triangle {
        color: #ff8b00;
      }
    }

    &[class*="chevron"] {
      color: #757575;
    }
    &:not([class*="chevron"]) {
      height: fit-content;
      background-color: #fff;
    }
    &[class*="square"] {
      border-radius: 2px;
    }
  }

  &.hidden-chevron-icon {
    i[class*="chevron"] {
      visibility: hidden;
    }
  }

  &.is-new-folder {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    i[class*="pen"] {
      color: #ff8b00;
      cursor: default !important;
    }
    i.fa-spinner {
      border-radius: 50%;
      color: #ff8b00;
      cursor: default !important;
    }
    .form-group {
      margin: 3px 2px;
      flex-grow: 1;
      .form-control {
        padding: 0 5px;
        height: auto;
      }
    }
    button {
      margin-left: 10px;
      padding: 0;
      border: none;
      border-radius: 50%;
      background: none;
      width: 14px;
      height: 14px;
      line-height: 14px;
      font-size: 14px;
      text-align: center;
      :not(:disabled):hover {
        background-color: #e1e1e1;
        box-shadow: 0 0 0 5px #e1e1e1;
      }
      :disabled {
        cursor: not-allowed;
        opacity: 0.65;
      }
      + button {
        margin-right: 10px;
      }
      i {
        margin: 0;
        &.fa-check-circle {
          border-radius: 50%;
          background-color: #fff;
          color: green;
        }
        &.fa-trash {
          background-color: transparent;
        }
      }
    }
  }

  &.is-directory:not(.current):not(.selection-would-be-invalid):not(.is-disabled) {
    i {
      &[class*="chevron"] {
        cursor: pointer;
        &:hover {
          color: blue;
          background-color: #f0f2ff;
          box-shadow: 0 0 7px 5px #f0f2ff;
        }
      }
    }
    span {
      &.fs-item-name {
        cursor: pointer;
        :hover {
          color: #00118e;
          background-color: #f0f2ff;
          box-shadow: 2px 0 2px 0 #f0f2ff, -2px 0 2px 0 #f0f2ff;
        }
      }
    }
  }

  &.selected {
    color: blue;
    font-weight: bold;
    :hover {
      span {
        &.fs-item-name {
          color: blue !important;
        }
      }
    }
  }

  &.current {
    color: green;
    font-weight: bold;
    cursor: default;
  }
  
  &.is-disabled {
    color: grey;
    cursor: default;
  }

  &.red {
    span {
      color: red !important;
    }
  }

  &.strikethrough {
    span {
      text-decoration: line-through;
    }
  }
`;

FolderExplorerNew.propTypes = {
  className: PropTypes.string,
  isHidden: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  contentItems: PropTypes.array,
  loaders: PropTypes.object.isRequired,
  folders: PropTypes.array.isRequired,
  openedFolders: PropTypes.object.isRequired,
  currentView: PropTypes.any.isRequired,
  tempDirectories: PropTypes.any,
  checkChildWithSameName: PropTypes.bool,
  checkChildWithSameFileName: PropTypes.bool,
  folderToExpand: PropTypes.object,
  selectedFolder: PropTypes.object,
  onSelectFolder: PropTypes.func.isRequired,
  isGetChildrenOnSelect: PropTypes.bool,
  isScrollToElmClass: PropTypes.string,
  onScrollToItem: PropTypes.func,
  linkToWo: PropTypes.bool,
  withoutNewFolders: PropTypes.bool,
  subHeight: PropTypes.number,
  selectedFolderLabelWidth: PropTypes.number,
  selectedFolderLabel: PropTypes.string,
  isParentOfSimilarToMigrated: PropTypes.func,
  onOpenParentFolder: PropTypes.func,
  emptyAvailableFsItemsText: PropTypes.string,
  directoryToExpand: PropTypes.string,
  fsRoots: PropTypes.array,
  storageManager: PropTypes.object.isRequired,
  downloadRootDirs: PropTypes.array,
};

export default FolderExplorerNew;
