import React from 'react';
import PropTypes from 'prop-types';
import {formatBytes} from '../../../../../utils';

export const SizeCell = ({value, className}) => (
  <td className={className}>
    {formatBytes(value, '-')}
  </td>
);

SizeCell.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string
};
