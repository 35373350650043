import {
  activateWoStep,
  changeStatusToAddFile,
  onGotoScreenToAddFile, setWoStepsAddToWoBack,
  setWoStepsAddToWoNext,
  showIngestModal,
  showWoStepsAddToWoModal,
  showWoStepsModal
} from "../../actions";
import PropTypes from "prop-types";
import * as React from "react";
import {Button, Modal} from "react-bootstrap";
import Checkbox from "react-bootstrap/lib/Checkbox";
import RndModal from "../../../../components/modals/RndModal";
import {Component} from "react";

class WoStepsModal extends Component {
  state = {
    isAll: false,
  };

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(showWoStepsModal(false));
  };

  handleContinue = () => {
    const { dispatch, storageManager } = this.props;
    dispatch(showWoStepsModal(false));
    dispatch(showWoStepsAddToWoModal(false));

    const {addFileToWoModalState} = storageManager;
    const {currentWOMemfis, ingestOperationTypes, showIngestSettings} = addFileToWoModalState;
    if (showIngestSettings) {
      dispatch(onGotoScreenToAddFile('isIngestScreen', null));
      dispatch(showIngestModal(ingestOperationTypes,  {woMemfisId: currentWOMemfis}, false))
    } else {
      dispatch(setWoStepsAddToWoNext(true));
    }
  }

  handleBack = () => {
    const { dispatch } = this.props;
    dispatch(showWoStepsModal(false));
    dispatch(showWoStepsAddToWoModal(false));

    dispatch(changeStatusToAddFile('isWoStepsScreen', false));
    dispatch(setWoStepsAddToWoBack(true));
  }

  handleSetActiveStep = (step_id, checked) => {
    const { dispatch } = this.props;
    dispatch(activateWoStep(step_id, checked));
    if (!checked) {
      this.setState({isAll: false});
    }
  }

  handleActivateAll = (checked) => {
    const { dispatch, woSteps } = this.props;
    woSteps.map(step => {
      dispatch(activateWoStep(step.step_id, checked));
    });

    this.setState({isAll: checked});
  }

  componentWillUnmount() {
    this.handleClose();
  }

  render() {
    const { woSteps } = this.props;
    const { isAll } = this.state;

    return (
      <RndModal
        show={true}
        onHide={this.handleClose}
        backdrop="static"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>WO Step</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Checkbox
            key={`step-all`}
            checked={isAll}
            onChange={({target: { checked }}) => this.handleActivateAll(checked)}
          >
            All
          </Checkbox>
          {
            woSteps.map(step => (
              <Checkbox
                key={`step-${step.step_id}-${step.step_uuid}`}
                label={step.step_label}
                title={step.step_description}
                checked={step.activate}
                onChange={({target: { checked }}) => this.handleSetActiveStep(step.step_id, checked)}
                disabled={false}
              >
                {step.step_label} ({step.step_description})
              </Checkbox>
            ))
          }
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.handleBack}>
            Back
          </Button>
          <Button bsStyle="primary" onClick={this.handleContinue}>
            Continue
          </Button>
          <Button bsStyle="default" onClick={this.handleClose}>Cancel</Button>
        </Modal.Footer>
      </RndModal>
    )
  }
}

WoStepsModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  storageManager: PropTypes.object.isRequired,
  woSteps: PropTypes.object.isRequired,
};

export default WoStepsModal;
