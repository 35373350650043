import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button} from 'react-bootstrap';
import styled from 'styled-components';
import Loader from '../../../../components/Loader';
import FolderExplorerNew from '../forms/FolderExplorerNew';
import { executeCreateSymlinkAction, setFoldersInModal } from '../../actions';
import RndModal from '../../../../components/modals/RndModal';
import ReportBugButton from "../../../../components/ReportBugButton";

class CreateSymlinkModal extends Component {
  state = {
    selectedFolder: null,
  };


  componentWillUnmount() {
    this.props.onClose();
  }

  componentDidMount() {
    const {dispatch, storageManager: {fsRoots, currentFolder}} = this.props;
    const folders = fsRoots.filter(i => i.fsRootID === currentFolder.fsRootID).map(i => ({...i}));
    if (folders) {
      dispatch(setFoldersInModal(folders));
    }
  }

  handleSetSelectDestination(selectedFolder) {
    this.setState({
      selectedFolder
    });
  }

  handleCreateSymlink = () => {
    const {dispatch} = this.props;
    const {selectedFolder} = this.state;
    dispatch(executeCreateSymlinkAction(selectedFolder.key));
  }

  render() {
    const {selectedFolder} = this.state;
    const {onClose, isLoading, dispatch, storageManager} = this.props;
    const {foldersInModal, openedFoldersInModal, currentView, currentFolder, loaders} = storageManager;
    return (
      <RndModal
        className="link-to-memfis-wo-modal"
        show={true}
        onHide={onClose}
        backdrop="static"
        enforceFocus={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>Select Parent Folder for Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContainerStyled className="container-fluid">
            {isLoading ?
                <div>
                  <Loader/>
                  <ReportBugButton isForModal className="loader-report-bug-btn"/>
                </div> : null}
            <FolderExplorerNew
              dispatch={dispatch}
              loaders={loaders}
              folders={foldersInModal}
              openedFolders={openedFoldersInModal}
              currentView={currentView}
              folderToExpand={currentFolder}
              selectedFolder={selectedFolder}
              onSelectFolder={(folder) => this.handleSetSelectDestination(folder)}
              withoutNewFolders
              storageManager={storageManager}
            />
          </ModalContainerStyled>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="primary"
            onClick={this.handleCreateSymlink}
            disabled={!selectedFolder}
            title='Create Symlink'
          >
            Create Symlink
          </Button>
          <Button bsStyle="default" onClick={onClose}>Back</Button>
        </Modal.Footer>
      </RndModal>
    );
  }
}

const ModalContainerStyled = styled.div`
  .work-orders {
    display: flex;
    > .details {
      width: 75%;
      word-break: break-all;
      margin-left: 15px;
    }
    > .list {
      flex-grow: 1;
      .form-group input {
        font-size: 10px;
        height: 29px;
        padding: 9px;
      }
      .filtered-data {
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
      }
      .wo-item {
        &:hover {
          background-color: #f0f2ff;
        }
        &.selected {
          font-weight: bold;
        }
      }
    }
  }
  .fs-root {
    min-height: 150px;
    &.none {
      visibility: hidden;
    }
    .fs-root-select {
      box-shadow: none;
      width: 25%;
    }
    .final-destination {
      word-break: break-all;
      &.none {
        font-style: italic;
      }
    }
  }
  .pagination {
    margin: 10px 0 0;
  }
  .selected-folder--label + .form-control-static {
    color: red;
  }
`;

CreateSymlinkModal.propTypes = {
  isLoading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  storageManager: PropTypes.object.isRequired,
};

export default CreateSymlinkModal;
