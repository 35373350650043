import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, FormGroup, FormControl, ControlLabel, Row, Col, Clearfix} from 'react-bootstrap';
import styled from 'styled-components';
import {FilterSelectStyled} from '../../../../../components/filters/FiltersStyled';
import Select from 'react-select';
import {PathFieldStyled, CheckboxStyled} from '../ModalStyled';
import Table from './table/Table';
import {buildWOInfo, getValueForOS, preparePathForOsWithoutRoot} from '../../../utils';
import _ from 'lodash';
import Paginate from '../../table/Paginate';
import {confirmAlert} from 'react-confirm-alert'
import FolderExplorerNew from '../../forms/FolderExplorerNew';
import {
  changeOpenedFoldersInModal,
  setFoldersInModal,
  showAvailableManualMemfisWOsModal,
  linkToMemfisWO,
  getClientListByWoMemfisId,
  setClientNameForWoMemfisId,
  getAssetFinalDestination,
  createArtworkWorkOrderByFName,
  setNewWOByFName,
  setUseFinalDestinationDataReserved, activateWoStep, clearWoStepsData
} from '../../../actions';
import TextEllipsisAdvanced from '../../../../../components/TextEllipsis/TextEllipsisAdvanced';
import Wizard from '../../../../../components/Wizard/Wizard';
import InBurbankStep, {CONTINUE_COMPLETE_DOWNLOAD, CONTINUE_SINGLE_LINK_WO} from './InBurbankStep';
import Checkbox from "react-bootstrap/lib/Checkbox";
import WarningIcon from "../../../../../components/icons/WarningIcon";

const Steps = {
  IN_BURBANK: 'In Burbank',
  SELECT_WO: 'Select WO#',
  CONFIRM: 'Confirm',
  WO_STEPS: 'WO Steps to mark as complete',
  ARTWORK_WO: 'Artwork Work Order'
};

const CLIENTS_OPTION_ALL = {value: 'All', label: 'All'};
const ARTWORK_WO_TYPE = {
  GENERAL: 'general',
  CUSTOM: 'custom'
};

class LinkToMemfisWoModal extends Component {

  getDefaultStateForSelectWo = () => {
    return {
      currentPage: 0,
      filterText: '',
      filterClient: CLIENTS_OPTION_ALL.value,
      clients: [CLIENTS_OPTION_ALL],
      selectedWorkOrder: null,
    };
  };

  state = {
    ...this.getDefaultStateForSelectWo(),
    isBeginIngest: true,
    sendCaptionsToQod: true,
    fsRootId: this.props.item.fsRootID,
    fileType: null,
    projectType: null,
    isDefaultFileType: true,
    selectedFolder: null,
    confirmedDestination: false,
    isSelectingDestination: false,
    inputValue: '',
    resultValue: '',
    inBurbankStepData: {
      singleLinking: false,
      searchWOsForContent: false,
      continueVariant: null
    },
    isAllWoSteps: false,
    artworkWoCustomType: false
  };

  initFoldersInExplorer = () => {
    const {dispatch, storageManager: {fsRoots}, workOrders} = this.props;
    if (!workOrders || !dispatch) {
      return;
    }
    if (this.state.clients.length <= 1) {
      const options =
        _.sortBy(
          _.uniqBy(
            workOrders.filter(i => i.clientName).map(i => ({value: i.clientName, label: i.clientName})),
          'value'),
        ['value']);
      this.setState({clients: [CLIENTS_OPTION_ALL, ...options]});
    }
    let folders = fsRoots.map(i => ({...i}));
    dispatch(changeOpenedFoldersInModal({}));
    dispatch(setFoldersInModal(folders));
  };

  componentDidMount() {
    this.initFoldersInExplorer();
  }

  componentWillUnmount() {
    const {dispatch} = this.props;
    dispatch && dispatch(setNewWOByFName(null));
    this.wizard.setActiveStepKey(Steps.SELECT_WO);
  }

  componentDidUpdate(prevProps) {
    const {projectType} = this.state;
    const {finalDestinationData, useFinalDestinationDataReserved, dispatch, workOrders, item, storageManager: {newWoByFName}} = this.props;
    if(projectType === null && finalDestinationData && finalDestinationData.default_project_type) {
      this.setState({
        ...this.state,
        projectType: finalDestinationData.default_project_type
      })
    }
    if (JSON.stringify(workOrders) !== JSON.stringify(prevProps.workOrders) || (dispatch && !prevProps.dispatch)) {
      this.initFoldersInExplorer();
    }

    if (this.wizard.getActiveStepKey() !== Steps.CONFIRM && newWoByFName) {
      this.setState({
        fsRootId: item.fsRootID
      });
      this.onSelectMemfisWoIdOrVolume(newWoByFName, null, () => {
        const {finalDestinationData} = this.props;
        let root_id = item.fsRootID;
        if (finalDestinationData && finalDestinationData.root_id) {
          root_id = finalDestinationData.root_id;
        }
        this.setState({fsRootId: root_id});
        this.setFoldersInModal(root_id);
      });
      this.wizard.setActiveStepKey(Steps.CONFIRM);
    }

    const {fsRootId} = this.state;
    if (useFinalDestinationDataReserved && finalDestinationData && fsRootId !== finalDestinationData.root_id) {
      this.setState({fsRootId: finalDestinationData.root_id});
      this.setFoldersInModal(finalDestinationData.root_id);
      dispatch && dispatch(setUseFinalDestinationDataReserved());
    }
  }

  handlePageClick = page => {
    this.setState({currentPage: page.selected});
  };

  getDefaultFsRootID = selectedWorkOrder => {
    const {finalDestinationData, item, storageManager: {newWoByFName}} = this.props;
    if ((!selectedWorkOrder && !newWoByFName) ||
        !finalDestinationData || (selectedWorkOrder && finalDestinationData.woMemfisId !== selectedWorkOrder.woMemfisId)) {
      return item.fsRootID;
    }
    return finalDestinationData.root_id;
  }

  getCurrentFinalDestinationPath = () => {
    const {finalDestinationData, storageManager: {newWoByFName}} = this.props;
    const {selectedWorkOrder, fsRootId} = this.state;
    if ((!selectedWorkOrder && !newWoByFName) || !fsRootId ||
        !finalDestinationData || (!newWoByFName && finalDestinationData.woMemfisId !== selectedWorkOrder.woMemfisId)) {
      return null;
    }
    return finalDestinationData.final_destination;
  };

  getCurrentFinalDestinationRootId = () => {
    const {finalDestinationData, storageManager: {newWoByFName}} = this.props;
    const {selectedWorkOrder, fsRootId} = this.state;
    if ((!selectedWorkOrder && !newWoByFName) || !fsRootId ||
        !finalDestinationData || finalDestinationData.woMemfisId !== selectedWorkOrder.woMemfisId) {
      return null;
    }
    return finalDestinationData.root_id;
  };

  getCurrentFinalDestinationKey = () => {
    const {finalDestinationData, storageManager: {newWoByFName}} = this.props;
    const {selectedWorkOrder, fsRootId} = this.state;
    if ((!selectedWorkOrder && !newWoByFName) || !fsRootId ||
        !finalDestinationData || (selectedWorkOrder && finalDestinationData.woMemfisId !== selectedWorkOrder.woMemfisId)) {
      return null;
    }
    return finalDestinationData.default_final_destination;
  };

  isWorkOrderWithoutClient = workOrder => {
    return !!workOrder && !workOrder.clientName;
  };

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch && dispatch(showAvailableManualMemfisWOsModal(false));
    dispatch && dispatch(setNewWOByFName(null));
  };

  handleNewArtworkWorkOrder = () => {
    this.wizard.setActiveStepKey(Steps.ARTWORK_WO);
  }

  handleChangeArtworkWoType = (event) => {
    this.setState({artworkWoCustomType: ARTWORK_WO_TYPE.CUSTOM === event.target.value});
  }

  handleCreateArtworkWorkOrder = () => {
    const {item, dispatch} = this.props;
    const {artworkWoCustomType} = this.state;
    const {fsRootID, contentID} = item;
    if (artworkWoCustomType) {
      const artworkWoCustomSeries = this.customSeriesInput.value;
      const artworkCustomConceptName = this.conceptNameInput.value;
      dispatch && dispatch(createArtworkWorkOrderByFName(fsRootID, contentID, artworkWoCustomSeries, artworkCustomConceptName));
      return;
    }
    dispatch && dispatch(createArtworkWorkOrderByFName(fsRootID, contentID));
  }

  handleLinkToWO = () => {
    const {dispatch, requestedPage, linkToMemfisWOCallback, storageManager: {newWoByFName}, woSteps} = this.props;
    const {selectedWorkOrder, isBeginIngest, sendCaptionsToQod, fsRootId, confirmedDestination, resultValue} = this.state;
    let steps = null;
    if (woSteps) {
      steps = woSteps
        .filter((step) => step.activate)
        .map(({step_id}) => step_id);
    }

    const submit = () => {
      const destinationPath = confirmedDestination ? resultValue : this.getCurrentFinalDestinationKey();
      const params = {
        requestedPage,
        memfisWO: newWoByFName ? {woMemfisId: newWoByFName} : selectedWorkOrder,
        fsRootID2: fsRootId,
        destinationPath,
        isBeginIngest,
        sendCaptionsToQod,
        successCallback: linkToMemfisWOCallback,
        steps
      };
      this.setState({isAllWoSteps: false});
      dispatch && dispatch(linkToMemfisWO(params));
    };
    if (this.isWorkOrderWithoutClient(selectedWorkOrder)) {
      confirmAlert({
        title: 'Are You Sure?',
        message: 'Are you sure you want to link to work order without client info?',
        buttons: [{
          label: 'No'
        }, {
          label: 'Yes',
          onClick: submit
        }]
      });
    } else {
      submit();
    }
  };

  handleLoadClientList = woMemfisId => {
    const {dispatch} = this.props;
    dispatch && dispatch(getClientListByWoMemfisId(woMemfisId));
  };

  handleSelectClientName = (woMemfisId, clientName) => {
    const {dispatch} = this.props;
    dispatch && dispatch(setClientNameForWoMemfisId(woMemfisId, clientName)).then(
      () => {
        const {clients} = this.state;
        if (!clients.find(i => i.value === clientName)) {
          this.setState({clients: [...clients, {value: clientName, label: clientName}]});
        }
      }
    );
  };

  setFoldersInModal = (rootId) => {
    const {dispatch, storageManager: {fsRoots}} = this.props;
    const folders = fsRoots.filter(i => i.fsRootID === rootId).map(i => ({...i}));
    if (folders && dispatch) {
      dispatch(setFoldersInModal(folders));
    }
  }

  onSelectMemfisWoIdOrVolume = (woMemfisId, preferredRootId, callback, projectType) => {
    const {dispatch} = this.props;
    dispatch && dispatch(getAssetFinalDestination(woMemfisId, preferredRootId, projectType)).then(
      () => {
        callback && callback();
      }
    );
  };

  handleSelectWO = selectedWorkOrder => {
    this.setState({
      selectedWorkOrder,
      fsRootId: this.getDefaultFsRootID(selectedWorkOrder)
    });
    this.onSelectMemfisWoIdOrVolume(selectedWorkOrder.woMemfisId, null, () => {
      this.setState({fsRootId: this.getDefaultFsRootID(selectedWorkOrder)});
      this.setFoldersInModal(this.getDefaultFsRootID(selectedWorkOrder));
    });
    this.wizard.setActiveStepKey(Steps.WO_STEPS);
  };

  handleSelectFsRoot = selectedRootId => {
    const {selectedWorkOrder, fsRootId} = this.state;
    const { storageManager: {newWoByFName}} = this.props;
    const woMemfisId = newWoByFName ? newWoByFName : selectedWorkOrder.woMemfisId
    if (fsRootId !== selectedRootId) {
      this.setState({
        fsRootId: selectedRootId
      });
      this.onSelectMemfisWoIdOrVolume(woMemfisId, selectedRootId);
      this.setFoldersInModal(selectedRootId);
    }
  };

  handleSelectFileType = type => {
    const {finalDestinationData} = this.props;
    const {selectedWorkOrder, fsRootId} = this.state;
    const { storageManager: {newWoByFName}} = this.props;
    const woMemfisId = newWoByFName ? newWoByFName : selectedWorkOrder.woMemfisId
    this.setState({
      ...this.state,
      fileType: type,
      projectType: finalDestinationData.default_project_type,
      isDefaultFileType: true
    })
    this.onSelectMemfisWoIdOrVolume(woMemfisId, fsRootId, null, finalDestinationData.default_project_type);
  };

  handleSelectProjectType = type => {
    const {finalDestinationData, storageManager: {newWoByFName}} = this.props;
    const {selectedWorkOrder, fsRootId} = this.state;
    const woMemfisId = newWoByFName ? newWoByFName : selectedWorkOrder.woMemfisId
    this.setState({
      ...this.state,
      projectType: type,
      isDefaultFileType: type === finalDestinationData.default_project_type
    })
    this.onSelectMemfisWoIdOrVolume(woMemfisId, fsRootId, null, type);
  };

  handleSearchFilter = event => {
    this.setState({
      currentPage: 0,
      filterText: event.target.value,
      selectedWorkOrder: null,
      fsRootId: this.getDefaultFsRootID(null)
    });
  };

  handleClientFilter = value => {
    this.setState({
      currentPage: 0,
      filterClient: (value || {}).value || CLIENTS_OPTION_ALL.value,
      selectedWorkOrder: null,
      fsRootId: this.getDefaultFsRootID(null)
    });
  };

  handleBackToSelectWoFromConfirm = () => {
    this.setState({
      selectedWorkOrder: null,
      isBeginIngest: true,
      sendCaptionsToQod: true,
      fsRootId: this.getDefaultFsRootID(null)
    });
    const {dispatch} = this.props;
    dispatch && dispatch(setNewWOByFName(null));
    dispatch && dispatch(clearWoStepsData());
  };

  handleBackToInBurbankFromSelectWo = () => {
    const {dispatch} = this.props;
    dispatch && dispatch(showAvailableManualMemfisWOsModal(false, null, {isBackToInBurbankStep: true}));
    this.setState({
      ...this.getDefaultStateForSelectWo(),
      isBeginIngest: true,
      sendCaptionsToQod: true,
      fsRootId: this.getDefaultFsRootID(null)
    });
  };

  renderWorkOrders() {
    const {storageManager} = this.props;
    const workOrders = this.props.workOrders || [];
    const {filterText, filterClient, clients, currentPage} = this.state;
    let filteredData = [...workOrders];
    if (filterClient !== 'All') {
      filteredData = filteredData.filter(item => item.clientName === filterClient);
    }
    filteredData = filteredData.filter(item => item.woMemfisId);
    if (filterText && filterText.trim()) {
      filteredData = filteredData.filter(item => {
        for (const key in item) {
          if (item.hasOwnProperty(key) && String(item[key] || '').toLowerCase().includes(filterText.toLowerCase())) {
            return true;
          }
        }
        return false;
      });
    }
    const pageSize = 100;
    const pageCount = Math.ceil(filteredData.length / pageSize) || 1;
    const needPaging = pageCount > 1;
    const selectedPage = (currentPage + 1) > pageCount ? 0 : currentPage;
    const pagedData = needPaging ? filteredData.slice(selectedPage * pageSize, selectedPage * pageSize + pageSize) :
      filteredData;
    return (
      <>
        <Row>
          <Col xs={12} sm={4}>
            <FormGroupSearchStyled>
              <ControlLabel>
                Search:
              </ControlLabel>
              <FormControl
                value={filterText}
                onChange={this.handleSearchFilter}
              />
            </FormGroupSearchStyled>
          </Col>
          <Col xs={12} sm={2} className="hidden-xs"/>
          <Col xs={12} sm={6}>
            <FormGroupSearchStyled>
              <ControlLabel>
                Client:
              </ControlLabel>
              <ClientFilterSelectStyled>
                <Select
                  value={filterClient}
                  options={clients}
                  onChange={this.handleClientFilter}
                  placeholder=""
                />
              </ClientFilterSelectStyled>
            </FormGroupSearchStyled>
          </Col>
        </Row>
        <Table
          data={pagedData}
          emptyText={workOrders.length > filteredData.length ? 'No work orders match' : 'No work orders'}
          onLinkToWO={this.handleSelectWO}
          onLoadClientList={this.handleLoadClientList}
          onSelectClientName={this.handleSelectClientName}
        />
        {
          needPaging ?
            <Paginate
              pageCount={pageCount}
              selectedPage={selectedPage}
              onPageChange={this.handlePageClick}
              nextLabel=">"
              previousLabel="<"
              containerClassName="pagination"
            /> : null
        }
        { storageManager.XxHashIssue
            ? <XXHashWarningDiv>
                <WarningIcon />
                <span>
                  WARNING: XxHash is disabled - {storageManager.XxHashIssue}
                </span>
              </XXHashWarningDiv>
            : null
        }
      </>
    );
  }

  handleSetIsSelectDestination(isSelecting, confirmedDestination) {
    const {dispatch} = this.props;
    this.setState({
      isSelectingDestination: isSelecting,
      confirmedDestination: confirmedDestination || this.state.confirmedDestination,
      // selectedFolder: isBack ? null : this.state.selectedFolder,
    });
    dispatch && dispatch(changeOpenedFoldersInModal({}));
  }

  handleSetSelectDestination(folder) {
    const {finalDestinationData, storageManager: {newWoByFName}} = this.props;
    const woMemfisId = newWoByFName ? newWoByFName : this.state.selectedWorkOrder.woMemfisId
    const str = finalDestinationData.final_destination.split(/[\\/]/).pop();
    let prefix;
    if (!str.startsWith('WO')) {
      prefix = str.slice(0, str.indexOf('WO'));
    }
    this.setState({
      ...this.state,
      selectedFolder: folder,
      resultValue: !folder ? '' : `${folder.key}\\${prefix || ''}${woMemfisId}`,
    })
  }

  handleChangeInput = (event) => {
    const {storageManager: {newWoByFName}} = this.props;
    const woMemfisId = newWoByFName ? newWoByFName : this.state.selectedWorkOrder.woMemfisId
    this.setState({
      ...this.state,
      inputValue: event.target.value,
      resultValue: `${this.state.selectedFolder.key}\\${event.target.value}_${woMemfisId}`,
    });
  }

  renderDestination() {
    const {isBeginIngest, sendCaptionsToQod, fsRootId, fileType, projectType, isDefaultFileType, confirmedDestination, resultValue} = this.state;
    const {availableFsRoots, finalDestinationData, storageManager: {fsRoots}} = this.props;
    const fileTypeOptions = [];
    const projectTypeOptions = {
      default: null
    };

    if (finalDestinationData && finalDestinationData.project_type_exist) {
      const {project_type_file_settings} = finalDestinationData;
      if (project_type_file_settings && Object.keys(project_type_file_settings).length > 0) {
        Object.keys(project_type_file_settings).forEach(key => {
          fileTypeOptions.push({value: key, label: key});
          projectTypeOptions[key] = [];
          project_type_file_settings[key].forEach(item => {
            projectTypeOptions[key].push({value: item.projectType, label: item.projectType});
          })
        });
      }
    }

    const currentFinalDestinationPath = this.getCurrentFinalDestinationPath();
    return (
      <>
        <CustomPathFieldStyled color="black">
          <label>Volume:</label>
          <div className="value no-monospace">
            <FilterSelectStyled className="fs-root-select">
              <Select
                value={fsRootId}
                options={availableFsRoots}
                onChange={v => this.handleSelectFsRoot(v ? v.value : fsRootId)}
                placeholder=""
              />
            </FilterSelectStyled>
          </div>
        </CustomPathFieldStyled>
        {finalDestinationData && finalDestinationData.project_type_exist && (fileType || (fileTypeOptions && fileTypeOptions[0])) &&
        <CustomPathFieldStyled color="black">
          <label>File type:</label>
          <div className="value no-monospace">
            <FilterSelectStyled className="fs-file-type-select">
              <Select
                value={fileTypeOptions.length === 1 ? (fileTypeOptions && fileTypeOptions[0] ? fileTypeOptions[0].value : null) : fileType}
                options={fileTypeOptions}
                onChange={(e) => this.handleSelectFileType(e ? e.value : null)}
                disabled={fileTypeOptions.length === 1}
              />
            </FilterSelectStyled>
          </div>
          <label>Project type:</label>
          <div className="value no-monospace">
            <FilterSelectStyled className="fs-project-type-select">
              <Select
                value={projectType}
                options={fileType === null ? (fileTypeOptions && fileTypeOptions[0] && fileTypeOptions[0].value ? projectTypeOptions[fileTypeOptions[0].value] : null) : projectTypeOptions[fileType]}
                onChange={(e) => this.handleSelectProjectType(e ? e.value : null)}
                placeholder={finalDestinationData && finalDestinationData.default_project_type}
              />
            </FilterSelectStyled>
            <div className="reset">
              <button
                className="btn btn-default reset-btn"
                onClick={() => this.handleSelectProjectType(finalDestinationData && finalDestinationData.default_project_type)}
                disabled={isDefaultFileType}>
                  reset
              </button>
            </div>
          </div>
        </CustomPathFieldStyled>
        }
        <CustomPathFieldStyled color="red">
          <label>Destination:</label>
          <div className={`value${currentFinalDestinationPath ? ' with-btn' : ''}`}>
            <TextEllipsisAdvanced data={preparePathForOsWithoutRoot(confirmedDestination ? resultValue : currentFinalDestinationPath, fsRoots)}/>
            {currentFinalDestinationPath && <Button
                bsStyle="default"
                title="Select Destination"
                onClick={() => this.handleSetIsSelectDestination(true)}
              >
                <i className="far fa-folder-tree"/>
              </Button>}
          </div>
        </CustomPathFieldStyled>
        <CustomPathFieldStyled color="black">
          <label/>
          <div className="value is-begin-ingest">
            <CheckboxStyled className="checkbox">
              <FormControl
                id="checkbox-begin-ingest"
                name="checkbox-begin-ingest"
                type="checkbox"
                checked={isBeginIngest}
                onChange={event => this.setState({isBeginIngest: event.target.checked})}
              />
              <ControlLabel htmlFor="checkbox-begin-ingest">
                Begin file ingest process immediately
              </ControlLabel>
            </CheckboxStyled>
          </div>
        </CustomPathFieldStyled>
        {finalDestinationData && finalDestinationData.show_captions_send_to_qod &&
        <CustomPathFieldStyled color="black">
          <label/>
          <div className="value is-send-captions-to-qod">
            <CheckboxStyled className="checkbox">
              <FormControl
                id="checkbox-send-captions-to-qod"
                name="checkbox-send-captions-to-qod"
                type="checkbox"
                checked={sendCaptionsToQod}
                onChange={event => this.setState({sendCaptionsToQod: event.target.checked})}
              />
              <ControlLabel htmlFor="checkbox-begin-ingest">
                Send captions to QOD
              </ControlLabel>
            </CheckboxStyled>
          </div>
        </CustomPathFieldStyled>
        }
      </>
    );
  }

  handleSetActiveStep = (step_id, checked) => {
    const { dispatch } = this.props;
    dispatch(activateWoStep(step_id, checked));
    if (!checked) {
      this.setState({isAllWoSteps: false});
    }
  }

  handleActivateAll = (checked) => {
    const { dispatch, woSteps } = this.props;
    woSteps.map(step => {
      dispatch(activateWoStep(step.step_id, checked));
    });

    this.setState({isAllWoSteps: checked});
  }

  renderSteps() {
    const { woSteps, isWoStepsEmpty } = this.props;
    const { isAllWoSteps } = this.state;

    return (
      <>
        { !woSteps && !isWoStepsEmpty ?
          <p>Loading...</p>
          : null
        }
        { woSteps ?
          <table>
          {
            woSteps.map(step => (
               <tr>
                <td>
                <StyledCheckbox
                  key={`step-${step.step_id}-${step.step_uuid}`}
                  label={step.step_label}
                  title={step.step_description}
                  checked={step.activate}
                  onChange={({target: { checked }}) => this.handleSetActiveStep(step.step_id, checked)}
                  disabled={false}
                >
                  {step.step_label}
                </StyledCheckbox>
                </td>
                <td>
                  <span title={step.step_description}>&nbsp; {step.step_description}</span>
                </td>
               </tr>
              ))
          }
          </table>
          : null
        }

        {
          isWoStepsEmpty ?
            <p className="bg-danger">WO Steps info is not available</p>
            : null
        }
      </>
    );
  }

  renderNewArtworkWorkOrder() {
    const {artworkWoCustomType} = this.state;
    return (
        <ArtworkWorkOrderDiv>
          <span>Select type for new artwork work order:</span>
          <Clearfix>
            <div className="field">
              <div className="radio-wrapper">
                <input
                    name="artwork_wo_type"
                    type="radio"
                    value={ARTWORK_WO_TYPE.GENERAL}
                    checked={!artworkWoCustomType}
                    onChange={this.handleChangeArtworkWoType}
                />
                <label>
                  General
                </label>
              </div>
              <div className="radio-wrapper">
                <input
                    name="artwork_wo_type"
                    type="radio"
                    value={ARTWORK_WO_TYPE.CUSTOM}
                    checked={artworkWoCustomType}
                    onChange={this.handleChangeArtworkWoType}
                />
                <label>
                  Custom
                </label>
              </div>
            </div>
          </Clearfix>
          <FormGroup>
            {artworkWoCustomType ?
              <>
                <span>Enter custom series name (project title):</span>
                <input
                    type="text"
                    className="form-control"
                    ref={r => this.customSeriesInput = r}
                />
                <span>and concept name:</span>
                <input
                    type="text"
                    className="form-control"
                    ref={r => this.conceptNameInput = r}
                />
              </> : <>
                <span>Series name, concept name and year/episode numbers will be obtained from file names</span>
              </>
            }
          </FormGroup>
        </ArtworkWorkOrderDiv>
    );
  }

  renderContent(step) {
    const {item, storageManager: {newWoByFName}} = this.props;
    const {selectedWorkOrder} = this.state;
    return (
      <>
        <CustomPathFieldStyled>
          <label className={step === Steps.SELECT_WO ? 'min-w-80' : undefined}>Linking:</label>
          <TextEllipsisAdvanced className="value" data={getValueForOS(item, 'key')}/>
        </CustomPathFieldStyled>
        {
          step === Steps.CONFIRM ?
            <CustomPathFieldStyled color="black">
              <label>To:</label>
              <div className="value no-monospace">{newWoByFName ? newWoByFName : buildWOInfo(selectedWorkOrder)}</div>
            </CustomPathFieldStyled> : null
        }
        {step === Steps.SELECT_WO ? this.renderWorkOrders() : null}
        {step === Steps.CONFIRM ? this.renderDestination() : null}
        {step === Steps.WO_STEPS ? this.renderSteps() : null}
        {step === Steps.ARTWORK_WO ? this.renderNewArtworkWorkOrder() : null}
      </>
    );
  }

  handleWoSteps = () => {
    this.wizard.setActiveStepKey(Steps.CONFIRM);
  }

  buildSteps = () => {
    const {
      item, startsWithLinkInBurbank, onCompleteDownloadForMultipleWOs, dispatch, storageManager: {isLoadingModal, clientByFNameForWO, newWoByFName},
      isLoading, saving, finalDestinationIsLoading, clientNameIsLoading
    } = this.props;
    const {inBurbankStepData, selectedWorkOrder, isSelectingDestination, selectedFolder} = this.state;

    const currentFinalDestinationPath = this.getCurrentFinalDestinationPath();
    const woWithoutClient = this.isWorkOrderWithoutClient(selectedWorkOrder);
    return {
      ...(startsWithLinkInBurbank ? {
        [Steps.IN_BURBANK]: {
          stepName: Steps.IN_BURBANK,
          onContinue: () => {
            const {singleLinking, searchWOsForContent, continueVariant} = inBurbankStepData;
            if (continueVariant === CONTINUE_COMPLETE_DOWNLOAD) {
              onCompleteDownloadForMultipleWOs(item);
              return Steps.IN_BURBANK;
            } else {
              const {fsRootID, contentID} = item;
              const ajaxParams = searchWOsForContent ? {fs_root_id: fsRootID, content_id: contentID} :
                (singleLinking || continueVariant === CONTINUE_SINGLE_LINK_WO) ? {single_linking: true} : undefined;
              dispatch && dispatch(showAvailableManualMemfisWOsModal(true, ajaxParams));
            }
          }
        }
      } : {}),
      [Steps.SELECT_WO]: {
        stepName: clientByFNameForWO ? 'Select or create a new WO#' : Steps.SELECT_WO,
        allowBack: () => startsWithLinkInBurbank,
        onBack: () => this.handleBackToInBurbankFromSelectWo(),
        allowContinue: () => false,
        newBtnTitle: "New Artwork WO",
        allowNewBtn: clientByFNameForWO
      },
      [Steps.CONFIRM]: {
        stepName: isSelectingDestination ? 'Select Destination' : Steps.CONFIRM,
        ...(isSelectingDestination ? {
          onBack: () => {
            //this.handleSetIsSelectDestination(false, false);
            return Steps.WO_STEPS;
          },
          getTitleContinue: () => 'Confirm selected destination',
          getLabelContinue: () => 'Continue',
          allowContinue: () => {
            return !(
              !selectedFolder || isLoading || isLoadingModal
            );
          },
          onContinue: () => {
            this.handleSetIsSelectDestination(false, true);
            return Steps.CONFIRM;
          }
        } : {
          allowBack: () => {
            return !(
              saving || isLoading
            );
          },
          onBack: () => {
            //this.handleBackToSelectWoFromConfirm()
            return Steps.WO_STEPS;
          },
          getTitleContinue: () => woWithoutClient ? 'Link to WO without client info!' : undefined,
          getInfoContinue: () => woWithoutClient ? <Info className="fas fa-exclamation-circle"/> : null,
          allowContinue: () => {
            return !(
              (!selectedWorkOrder && !newWoByFName) || saving || isLoading || finalDestinationIsLoading || !currentFinalDestinationPath ||
              clientNameIsLoading
            );
          },
          onContinue: () => this.handleLinkToWO()
        })
      },
      [Steps.WO_STEPS]: {
        stepName: Steps.WO_STEPS,
        allowBack: () => {
          return true;
        },
        onBack: () => {
          if (isSelectingDestination) {
            this.handleSetIsSelectDestination(false, false);
          } else {
            this.handleBackToSelectWoFromConfirm();
          }
          this.setState({isAllWoSteps: false});
          dispatch(clearWoStepsData());
          return Steps.SELECT_WO;
        },
        getLabelContinue: () => 'Continue',
        allowContinue: () => {
          return true;
        },
        onContinue: () => {return Steps.CONFIRM;}
      },
      [Steps.ARTWORK_WO]: {
        stepName: Steps.ARTWORK_WO,
        allowBack: () => {
          return true;
        },
        onBack: () => {
          return Steps.SELECT_WO;
        },
        getTitleContinue: () => 'Create new artwork work order',
        getLabelContinue: () => 'Create artwork work order',
        allowContinue: () => {
          return true;
        },
        onContinue: this.handleCreateArtworkWorkOrder
      }
    };
  };

  render() {
    const {isLoading, clientNameIsLoading, dispatch, storageManager, onCompleteDownloadForMultipleWOs,
      finalDestinationIsLoading, saving
    } = this.props;
    const {isSelectingDestination, selectedFolder, confirmedDestination, inputValue, resultValue, inBurbankStepData
    } = this.state;
    const {foldersInModal, openedFoldersInModal, currentView, loaders, isLoadingModal, fsRoots} = storageManager;
    return (
      <Wizard
        onRef={ref => {
          this.wizard = ref;
        }}
        wizardName="Link to WO"
        bsSize={(step) => step === Steps.SELECT_WO ? 'large' : undefined}
        className="link-to-memfis-wo-modal"
        onCancel={this.handleClose}
        onNew={this.handleNewArtworkWorkOrder}
        isLoading={isLoading || clientNameIsLoading || (!!onCompleteDownloadForMultipleWOs && (finalDestinationIsLoading || saving || isLoadingModal))}
        steps={this.buildSteps()}
        getChildren={step => (
          step === Steps.IN_BURBANK ?
            <InBurbankStep
              isRequiredContinueVariant={!!onCompleteDownloadForMultipleWOs}
              data={inBurbankStepData}
              onChange={newValues => {
                this.setState({inBurbankStepData: {...inBurbankStepData, ...newValues}});
              }}
            /> :
          <>
            {isSelectingDestination && selectedFolder && <>
              <CustomPathFieldStyled color="red" labelWidth="145">
                <label>Default destination:</label>
                <TextEllipsisAdvanced className="value" data={preparePathForOsWithoutRoot(this.getCurrentFinalDestinationPath(), fsRoots)}/>
              </CustomPathFieldStyled>
              <CustomPathFieldStyled color="red" labelWidth="145">
                <label>Parent Folder:</label>
                <TextEllipsisAdvanced className="value" data={getValueForOS(selectedFolder, 'key')}/>
              </CustomPathFieldStyled>
              <FormGroupStyled>
                <ControlLabel>Prefix:</ControlLabel>
                <FormControl
                  value={inputValue}
                  onChange={this.handleChangeInput}
                  onBlur={this.handleChangeInput}
                  inputRef={ref => {
                    this._input = ref;
                  }}
                />
              </FormGroupStyled>
              <CustomPathFieldStyled color="red" labelWidth="145">
                <label>Result:</label>
                <TextEllipsisAdvanced className="value" data={preparePathForOsWithoutRoot(resultValue, fsRoots)}/>
              </CustomPathFieldStyled>
              <hr/>
            </>}
            {isSelectingDestination && dispatch ?
              <FolderExplorerNew
                dispatch={dispatch}
                loaders={loaders}
                folders={foldersInModal}
                openedFolders={openedFoldersInModal}
                currentView={currentView}
                folderToExpand={confirmedDestination ? selectedFolder : {key: this.getCurrentFinalDestinationPath(), fsRootID: this.getCurrentFinalDestinationRootId()}}
                selectedFolder={selectedFolder}
                onSelectFolder={(folder) => this.handleSetSelectDestination(folder)}
                linkToWo={true}
                selectedFolderLabelWidth={145}
                storageManager={storageManager}
              /> :
              this.renderContent(step)}
          </>
        )}
      />
    );
  }
}

const CustomPathFieldStyled = styled(PathFieldStyled)`
  align-items: center;

  .fs-root-select,
  .fs-file-type-select {
    width: 65px;
  }

  label {
    min-width: ${props => `${props.labelWidth || 115}px`};
    + div {
      max-width: calc(100% - ${props => `${props.labelWidth || 115}px`});
      &.value {
        &:not(.text--ellipsis--advanced) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-grow: 1;
          > .text--ellipsis--advanced {
            max-width: 100%;
          }
          &.with-btn {
            > .text--ellipsis--advanced {
              max-width: calc(100% - 55px);
            }
          }
        }
      }
    }

    &.min-w-80 {
      min-width: 80px;
      + div {
        max-width: calc(100% - 80px);
      }
    }
  }

  .fs-project-type-select {
    margin-right: 5px;
  }

  button.reset-btn {
    height: 27px;
    padding: 1px 10px;
  }
`;

const FormGroupStyled = styled(FormGroup)`
  margin-bottom: 6px;
  .control-label {
    font-size: 10px;
    margin-left: 0;
    padding: 0;
  }
`;

const Info = styled.i`
  margin-left: 3px;
  color: red;
  font-size: 15px;
  background-color: white;
  border-radius: 50%;
  box-shadow: none;
  text-shadow: none;
`;

const ClientFilterSelectStyled = styled(FilterSelectStyled)`
  width: 100%;

  .Select.has-value.Select--single,
  .Select.Select--single,
  .Select.is-searchable.Select--single,
  .Select--multi {

    > .Select-control {
      height: 34px;
      .Select-multi-value-wrapper {
        height: 32px;
        .Select-placeholder,
        .Select-value {
          font-size: 13px !important;
          line-height: 14px !important;
          .Select-value-label {
            font-size: 13px !important;
            line-height: 14px !important;
            opacity: 0.65;
          }
        }
        .Select-input {
          padding-left: 9px;
          height: 32px;
          input {
            font-size: 13px !important;
          }
        }
      }
    }

    &.is-open {
      > .Select-control {
        .Select-multi-value-wrapper {
          .Select-placeholder,
          .Select-value {
            .Select-value-label {
              opacity: 1;
            }
          }
        }
      }
    }
  }
`;

const FormGroupSearchStyled = styled(FormGroup)`
  display: flex;
  align-items: center;

  label {
    margin: 0;
    padding: 0;
    font-weight: bold;
    min-width: 80px;
  }

  .form-control {
    width: 100%;
    font-size: 13px;

    &:not(:focus) {
      opacity: 0.65;
    }
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin: 0;
`;

const ArtworkWorkOrderDiv = styled.div`
  .radio-wrapper {
    display: flex;

    input {
      margin: 0;
    }

    label {
      margin: 0 0 0 5px;
    }
  }

  .form-group span {
    display: block;
    margin-top: 7px;
  }
`;

const XXHashWarningDiv = styled.div`
  margin-top: 5px;
  background-color: #ffbf03;
  padding: 5px;
  border-radius: 5px;
  font-size: 15px;

  span {
    margin-left: 5px;

    svg {
      color: white;
      vertical-align: middle;
    }
  }
`

LinkToMemfisWoModal.propTypes = {
  dispatch: PropTypes.func,
  storageManager: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  item: PropTypes.object.isRequired,
  workOrders: PropTypes.array/*.isRequired*/,
  saving: PropTypes.bool.isRequired,
  availableFsRoots: PropTypes.array.isRequired,
  finalDestinationIsLoading: PropTypes.bool.isRequired,
  finalDestinationData: PropTypes.object,
  clientNameIsLoading: PropTypes.bool,
  startsWithLinkInBurbank: PropTypes.bool.isRequired,
  onCompleteDownloadForMultipleWOs: PropTypes.func,
  linkToMemfisWOCallback: PropTypes.func,
  requestedPage: PropTypes.string,
  woSteps: PropTypes.object.isRequired,
  isWoStepsEmpty: PropTypes.bool.isRequired
};

export default LinkToMemfisWoModal;
