import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {
  prepareFile,
  initializeContent,
  getActionsUserPermissionsWithRoots,
  getAvailablePredictiveStates,
  getLockStatusesForRoots,
  setCurrentView,
  clearAllStorageMng,
  closeManuallyReconcileModal,
  manuallyReconcileFsItem,
  closeSelectFolderModal,
  getAndShowBarcodedFilesToManuallyReconcile,
  showAvailableDrivesModal,
  loadSettings,
  loadActionType,
  setHideTempFiles,
  setAttachedVolumes,
  closeDeliveryRenamePopup,
  setDeliveryRenameAvailableForAddToWO,
  showRenameModal,
  refreshSelectedContentWithoutChildren,
} from './actions';
import Toolbar from './components/Toolbar';
import Actionbar from './components/Actionbar';
import Table from './components/table/Table';
import NestedTable from './components/table/TableNested';
import PageComponent from '../../components/PageComponent';
import AppLayout from '../../layouts/AppLayout';
import qs from 'qs';
import styled, {createGlobalStyle} from 'styled-components';
import Loader from '../../components/Loader';
import ActionsView from './components/fs-actions/View';
import LockAndServicesStatuses from './components/LockAndServicesStatuses';
import ManuallyReconcileModal from './components/modal/ManuallyReconcileModal';
import SelectFolderModal from './components/modal/SelectFolderModal';
import SettingsModal from './components/modal/SettingsModal';
import AvailableDrivesModal from './components/modal/AvailableDrivesModal';
import ActionProgressModal from './components/modal/ActionProgressModal';
import ActionSubscriberModal from './components/modal/ActionSubscriberModal';
import {
  ALL_VIEWS, VIEW_DEFAULT, DISPLAY_CATEGORY_SEARCH_RESULTS, DISPLAY_CATEGORY_FOLDER,
  DISPLAY_CATEGORY_INIT_CONFIRM_DELETION, FS_ACTION_TYPE__CONNECT_DRIVE,
  FS_ACTION_TYPE__DELIVERY_RENAME_PORTAL,
  FS_ACTION_TYPE__ADD_TO_MEMFIS_WO,
  FS_ACTION_TYPE__MOVE,
} from './constants';
import {isForbiddenActionByPermissions, isReadOnlyFsRootWithoutConnectedDrive} from '../../utils';
import ReportBugButton from "../../components/ReportBugButton";
import DeliveryRenamePopup from '../DeliveryManager/components/DeliveryRename';
import MyActionsModal from "./components/modal/MyActionsModal";
import {createSelector} from "reselect";


const selectDisplayedItems = (props) => props.storageManager.displayedItems
const selectFsRoots = (props) => props.storageManager.fsRoots

const getDisplayedItemsSelector = createSelector(
    [selectDisplayedItems, selectFsRoots],
    (displayedItems, fsRoots) => {
      return displayedItems.map(item => prepareFile(item, fsRoots));
    }
)

class StorageManager extends PageComponent {
  lockStatusesInterval = null;

  componentWillMount() {
    localStorage.setItem('last_view', 'storage');

    const {dispatch, history, urlFilters} = this.props;

    dispatch(setHideTempFiles(localStorage.getItem('storage_hide_temp_files') === 'true'));
    dispatch(setAttachedVolumes(localStorage.getItem('storage_show_attached_volumes') === 'true'));

    const localStorageViewData = localStorage.getItem('storage_view');
    const currentView = ALL_VIEWS.find(v => v.value === localStorageViewData) ? localStorageViewData : VIEW_DEFAULT;
    dispatch(setCurrentView(null, currentView));

    dispatch(getActionsUserPermissionsWithRoots()).always(() => {
      dispatch(getAvailablePredictiveStates()).always(() => {
        dispatch(getLockStatusesForRoots(history));
        dispatch(initializeContent(history, urlFilters));
        dispatch(loadActionType());
        dispatch(loadSettings());
      });
    });
  }

  componentDidUpdate(prevProps) {
    const {storageManager: {displayedItems}, dispatch, history} = this.props;
    const itemsLength = displayedItems.length;
    const isLarge = itemsLength > 100 && itemsLength <= 500;
    const itemsLengthPrev = prevProps.storageManager.displayedItems.length;
    const isLargePrev = itemsLengthPrev > 100 && itemsLengthPrev <= 500;
    if (isLarge !== isLargePrev || !this.lockStatusesInterval) {
      if (this.lockStatusesInterval) {
        clearInterval(this.lockStatusesInterval);
      }
      this.lockStatusesInterval = setInterval(function(dispatch, history) {
        dispatch(getLockStatusesForRoots(history));
      }, 1000 * (isLarge ? 15 : 5), dispatch, history);
    }
  }

  handleWindowPopState() {
    try {
      window.location.reload();
    } catch {}
  }

  componentDidMount() {
    window.addEventListener('popstate', this.handleWindowPopState);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleWindowPopState);
    clearInterval(this.lockStatusesInterval);
    const {dispatch} = this.props;
    dispatch(clearAllStorageMng());
  }

  prepareData = () => {
    const {storageManager} = this.props;
    const {loading, searching, displayCategory} = storageManager;
    if ((loading && displayCategory !== DISPLAY_CATEGORY_FOLDER) || searching) {
      return [];
    }
    return getDisplayedItemsSelector(this.props);
  };

  isNeedToConnectDriveToRoot = row => {
    const {lockAndServicesStatuses} = this.props.storageManager;
    const {FsRootLockStatuses} = lockAndServicesStatuses;
    return isReadOnlyFsRootWithoutConnectedDrive(row, FsRootLockStatuses);
  };

  getConnectDriveComponent = row => {
    const {dispatch, storageManager} = this.props;
    const {lockAndServicesStatuses, actionsUserPermissions, actionsUserPermissionsWithRoots} = storageManager;
    const {FsRootLockStatuses} = lockAndServicesStatuses;
    const permissions = {actionsUserPermissions, actionsUserPermissionsWithRoots};
    if (FsRootLockStatuses) {
      const lockStatusOfRoot = FsRootLockStatuses.find(r => r.FSRootID === row.fsRootID);
      if (lockStatusOfRoot) {
        const {AvailableDrives, FSRootID} = lockStatusOfRoot;
        const isForbidden = isForbiddenActionByPermissions(FS_ACTION_TYPE__CONNECT_DRIVE, FSRootID, permissions);
        const isNoAvailableDrives = !Array.isArray(AvailableDrives) || !AvailableDrives.length;
        const {key, fsRootName} = row;
        const onConnect = (isForbidden || isNoAvailableDrives) ? undefined :
          () => dispatch(showAvailableDrivesModal({availableDrives: AvailableDrives, fsRoot: {label: fsRootName || key}}));
        return (
          <div className="connect-drive">
            <button
              title={(isForbidden || isNoAvailableDrives) ? undefined : 'Connect external drive'}
              disabled={isForbidden || isNoAvailableDrives}
              onClick={onConnect}
            >
              {isForbidden ? 'No drive connection' : isNoAvailableDrives ? 'No drives to connect' : 'Connect drive'}
            </button>
          </div>
        );
      }
    }
    return null;
  };

  handleCloseDeliveryRenamePopup = () => {
    const {dispatch} = this.props;
    dispatch(closeDeliveryRenamePopup());
  };

  renderModals() {
    const {dispatch, storageManager, history, deliveryManager} = this.props;
    const {
      checkedItems,
      isShowSelectFolderModal,
      fsRoots,
      manuallyReconcileData,
      availableDrivesModalData,
      isSettingsModalOpen,
      isMyActionsModalOpen,
      settings,
      currentView,
      hideTempFiles,
      attachedVolumes,
      actionInProgress,
      loadingActionProgress,
      deliveryRenamePopupData,
      displayCategory,
      actionWatchInProgress,
      loadingActionWatchInProgress,
    } = storageManager;
    if (deliveryRenamePopupData && deliveryRenamePopupData.contentItems && deliveryRenamePopupData.contentItems.length) {
      const {actionType, contentItems, woMemfis} = deliveryRenamePopupData;
      const {reviewDeliveryData} = deliveryManager;
      const isOpenedRenameFromSpecForSingleFile = contentItems.length === 1 &&
        !!reviewDeliveryData && (reviewDeliveryData.categoryData || []).length === 1 &&
        (reviewDeliveryData.categoryData[0].woDataList || []).length === 1 &&
        (reviewDeliveryData.categoryData[0].woDataList[0].contentDataList || []).length === 1 &&
        reviewDeliveryData.categoryData[0].woDataList[0].contentDataList[0].fsRootID === contentItems[0].fsRootID &&
        reviewDeliveryData.categoryData[0].woDataList[0].contentDataList[0].contentID === contentItems[0].contentID;
      return (
        <DeliveryRenamePopup
          dispatch={dispatch}
          deliveryManager={deliveryManager}
          onClose={this.handleCloseDeliveryRenamePopup}
          isSingleFile={isOpenedRenameFromSpecForSingleFile}
          paramsToGetFiles={{
            root_id: contentItems[0].fsRootID,
            action_type: actionType,
            content_ids: `[${contentItems.map(({contentID}) => contentID).join(',')}]`,
            wo_memfis: woMemfis,
          }}
          paramsToSaveFiles={{
            actionRequestParams: {
              actionType: FS_ACTION_TYPE__DELIVERY_RENAME_PORTAL,
              fsRootID: contentItems[0].fsRootID,
              parameters: {
                ContentIDs: contentItems.map(({contentID}) => String(contentID)),
              },
            },
            successCallback: () => {
              this.handleCloseDeliveryRenamePopup();
              let needToRefreshContent = false;
              if (actionType === FS_ACTION_TYPE__ADD_TO_MEMFIS_WO) {
                needToRefreshContent = true;
                dispatch(setDeliveryRenameAvailableForAddToWO(woMemfis, false));
              } else if (actionType === FS_ACTION_TYPE__MOVE) {
                needToRefreshContent = true;
                dispatch(showRenameModal(false));
              }
              if (needToRefreshContent && displayCategory === DISPLAY_CATEGORY_FOLDER) {
                dispatch(refreshSelectedContentWithoutChildren(contentItems[0]));
              }
            },
            errorCallback: () => {
              if (isOpenedRenameFromSpecForSingleFile) {
                this.handleCloseDeliveryRenamePopup();
              }
            }
          }}
        />
      );
    }
    if (actionInProgress) {
      return (
        <ActionProgressModal
          dispatch={dispatch}
          storageManager={storageManager}
          data={actionInProgress}
          loading={loadingActionProgress}
        />
      );
    }
    if (actionWatchInProgress) {
      return (
        <ActionSubscriberModal
          dispatch={dispatch}
          storageManager={storageManager}
          data={actionWatchInProgress}
          loading={loadingActionWatchInProgress}
        />
      );
    }
    if (availableDrivesModalData) {
      return (
        <AvailableDrivesModal
          dispatch={dispatch}
          data={availableDrivesModalData}
        />
      );
    }
    if (isShowSelectFolderModal === 'ManuallyReconcile' && checkedItems.length === 1) {
      const rootName = (fsRoots.find(i => i.fsRootID === checkedItems[0].fsRootID) || {}).fsRootName;
      return (
        <SelectFolderModal
          dispatch={dispatch}
          storageManager={storageManager}
          onClose={() => dispatch(closeSelectFolderModal())}
          onConfirm={selectedFolder => dispatch(getAndShowBarcodedFilesToManuallyReconcile(selectedFolder.contentID))}
          categoryName={checkedItems[0].key}
          rootName={rootName}
          isSaving={false}
          isManuallyReconcile
        />
      );
    }
    if (manuallyReconcileData) {
      return (
        <ManuallyReconcileModal
          data={manuallyReconcileData}
          onClose={() => dispatch(closeManuallyReconcileModal())}
          onSave={(values) => dispatch(manuallyReconcileFsItem(values))}
        />
      );
    }
    if (isSettingsModalOpen) {
      return (<SettingsModal
        dispatch={dispatch}
        settings={settings}
        currentView={currentView}
        hideTempFiles={hideTempFiles}
        attachedVolumes={attachedVolumes}
        history={history}
      />);
    }
    if (isMyActionsModalOpen) {
      return (
        <MyActionsModal
          dispatch={dispatch}
          storageManager={storageManager}
        />
      );
    }
    return null;
  }

  render() {
    const {dispatch, history, storageManager, deliveryManager} = this.props;
    const {
      loading, searching, instantScanning, actionsIsLoading, actions, actionContentsIsLoading, navigationIntoIsLoading,
      actionsUserPermissionsIsLoading, availableManualMemfisWOsIsLoading, predictiveStatesIsLoading, attachedVolumes,
      finalDestinationIsLoading, linkToMemfisWOIsSaving, availableFsRoots, lockAndServicesStatuses, displayCategory,
      ingestOperationTypesIsLoading, isLoadingModal, isReadingFile, loaders,
      actionsUserPermissions, actionsUserPermissionsWithRoots
    } = storageManager;
    const data = this.prepareData();
    const {OfficeServiceStatuses, FsRootLockStatuses} = lockAndServicesStatuses;
    const officesCount = Array.isArray(OfficeServiceStatuses) ? OfficeServiceStatuses.length : 0;
    const availableFsRootsForStatuses = availableFsRoots.length ? availableFsRoots : Array.isArray(FsRootLockStatuses) ?
      FsRootLockStatuses.map(r => ({value: r.FSRootID, label: r.FSRootLabel || `root ${r.FSRootID}`})) : [];
    const lockStatusesHeight = (!attachedVolumes || !(availableFsRootsForStatuses.length || officesCount)) ? 0 :
      (Math.max(availableFsRootsForStatuses.length, officesCount) * 16 + (Math.max(availableFsRootsForStatuses.length, officesCount) - 1) * 5 + 15 + 31);
    return (
      <AppLayout
        title="Storage Manager"
        >
        <div className="container">
          {
            (instantScanning || actionsIsLoading || actionContentsIsLoading || actionsUserPermissionsIsLoading ||
              availableManualMemfisWOsIsLoading || predictiveStatesIsLoading || loading || searching ||
              finalDestinationIsLoading || linkToMemfisWOIsSaving || navigationIntoIsLoading ||
              ingestOperationTypesIsLoading || isLoadingModal || isReadingFile ||
              deliveryManager.loading ||
              Object.keys(loaders).some(k => loaders[k])
            ) ?
                <div>
                  <Loader className="full-screen"/>
                  <ReportBugButton isForModal className="loader-report-bug-btn"/>
                </div>: null
          }
          {this.renderModals()}
          {
            attachedVolumes && lockStatusesHeight > 0 ?
              <LockAndServicesStatuses
                dispatch={dispatch}
                permissions={{actionsUserPermissions, actionsUserPermissionsWithRoots}}
                availableFsRoots={availableFsRootsForStatuses}
                lockAndServicesStatuses={lockAndServicesStatuses}
                height={lockStatusesHeight}
              /> : null
          }
          <Toolbar
            dispatch={dispatch}
            history={history}
            storageManager={storageManager}
            lockStatusesHeight={lockStatusesHeight}
          />
          <Actionbar
            dispatch={dispatch}
            history={history}
            storageManager={storageManager}
            lockStatusesHeight={lockStatusesHeight}
          />
          <ContentStyled top={lockStatusesHeight}>
            {
              actions ?
                <ActionsView
                  dispatch={dispatch}
                  history={history}
                  storageManager={storageManager}
                /> : null
            }
            {
              [DISPLAY_CATEGORY_SEARCH_RESULTS, DISPLAY_CATEGORY_INIT_CONFIRM_DELETION].includes(displayCategory) ?
                <NestedTable
                  dispatch={dispatch}
                  history={history}
                  storageManager={storageManager}
                  loading={loading || searching}
                  data={data}
                  isNeedToConnectDriveToRoot={this.isNeedToConnectDriveToRoot}
                  getConnectDriveComponent={this.getConnectDriveComponent}
                /> :
                <Table
                  dispatch={dispatch}
                  history={history}
                  storageManager={storageManager}
                  loading={loading || searching}
                  data={data}
                  isNeedToConnectDriveToRoot={this.isNeedToConnectDriveToRoot}
                  getConnectDriveComponent={this.getConnectDriveComponent}
                />
            }
          </ContentStyled>
        </div>
        <GlobalStyled/>
      </AppLayout>
    );
  }
}

const GlobalStyled = createGlobalStyle`
  #dropdown--link-to-wo--button {

    i {
      padding-right: 5px;
    }

    & + .dropdown-menu {
      min-width: 135px;

      > li > a {
        padding: 0;

        .btn {
          min-width: 100%;
          background: transparent !important;
          border: none !important;
          box-shadow: none !important;
          padding: 3px 20px;
          clear: both;
          font-weight: 400;
          line-height: 1.42857143;
          color: #333333;
          white-space: nowrap;
          text-align: left;
          text-shadow: none !important;
          &:after {
            content: attr(title) !important;
          }
          i {
            margin-right: 5px !important;
            min-width: 17px;
          }
        }
        & {
          &:hover, &:focus {
            .btn:not(.disabled) {
              color: #262626;
              background-color: #f5f5f5 !important;
            }
          }
        }
      }
    }
  }
`;

const ContentStyled = styled.div`
  padding-top: ${props => `${props.top + 150}px`};

  @media (max-width: 767px) {
    padding-top: ${props => `${props.top + 210}px`};
  }
`;

StorageManager.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  storageManager: PropTypes.object.isRequired,
  deliveryManager: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  urlFilters: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    storageManager: state.storageManager,
    deliveryManager: state.deliveryManager,
    user: state.user,
    urlFilters: qs.parse(ownProps.location.search.substr(1))
  };
};

export default connect(
  mapStateToProps
)(withRouter(StorageManager));
