import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button} from 'react-bootstrap';
import Select from 'react-select';
import RndModal from '../../../../components/modals/RndModal';

class DeletePresetsModal extends Component {
  state = {
    selected: null
  };

  handleDelete = () => {
    const {onDelete} = this.props;
    const {selected} = this.state;
    onDelete(selected.map(p => p.value));
    this.handleClose();
  };

  handleClose = () => {
    const {onClose} = this.props;
    onClose();
  };

  componentWillUnmount() {
    this.handleClose();
  }

  render() {
    const {presets} = this.props;
    const {selected} = this.state;
    return (
      <RndModal
        show={true}
        onHide={this.handleClose}
        backdrop="static"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete presets</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <Select
              multi
              placeholder="Select presets to delete"
              options={presets}
              value={selected}
              onChange={selected => this.setState({selected})}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.handleDelete} disabled={!selected || !selected.length}>Delete</Button>
          <Button bsStyle="default" onClick={this.handleClose}>Cancel</Button>
        </Modal.Footer>
      </RndModal>
    );
  }
}

DeletePresetsModal.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  presets: PropTypes.array.isRequired
};

export default DeletePresetsModal;
