import React from 'react';
import PropTypes from 'prop-types';

export const CheckboxCell = ({row, isChecked, onChange, isDisabled}) => (
  <td className="checkbox-cell">
    {
      isChecked && onChange ?
        <input
          type="checkbox"
          checked={isChecked(row)}
          onClick={event => event.stopPropagation()}
          onChange={event => onChange(event.target.checked, row, event.nativeEvent && event.nativeEvent.shiftKey)}
          disabled={!!isDisabled && isDisabled(row)}
          /> : null
    }
  </td>
);
CheckboxCell.propTypes = {
  row: PropTypes.object,
  isChecked: PropTypes.any,
  onChange: PropTypes.func
};
