import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Button} from 'react-bootstrap';
import {confirmAlert} from 'react-confirm-alert';
import {
  disconnectDrive, showAvailableDrivesModal, executeScriptRunnerAction, executeFileSystemRunnerAction,
  executeMachineRunnerAction,
  executeOfficeAction
} from '../actions';
import {isForbiddenActionByPermissions, isForbiddenActionByUserPermissions, isNoConnectedDrive} from '../../../utils';
import {
  FS_ACTION_TYPE__CONNECT_DRIVE,
  FS_ACTION_TYPE__DISCONNECT_DRIVE,
  FS_ACTION_TYPE__START_SCRIPT_RUNNER,
  FS_ACTION_TYPE__STOP_SCRIPT_RUNNER,
  FS_ACTION_TYPE__START_FILE_SYSTEM_RUNNERS,
  FS_ACTION_TYPE__STOP_FILE_SYSTEM_RUNNERS,
  FS_ACTION_TYPE__START_MACHINE_RUNNERS,
  FS_ACTION_TYPE__STOP_MACHINE_RUNNERS,
  FS_ACTION_TYPE__START_DAT_SERVICE_RUNNER,
  FS_ACTION_TYPE__STOP_DAT_SERVICE_RUNNER,
  FS_ACTION_TYPE__START_OFFICE_SERVICE_RUNNERS, FS_ACTION_TYPE__STOP_OFFICE_SERVICE_RUNNERS
} from '../constants';
import {isMacOsPathView} from '../utils';
import FsRunnerModal from './modal/FsRunnerModal';
import AllOfficesActionModal from './modal/AllOfficesActionModal';

const RunnerIcon = {
  RUN: 'fas fa-play',
  STOP: 'fas fa-stop'
};

const RunnerStatusBit = {
  0: {
    iconClassName: /*RunnerIcon.STOP*/'icon-stop-being-started',
    iconStateForFS: 'icon-stop being-started',
    iconActionForFS: 'icon-stop',
    actionLabel: 'Stop',
    stateLabel: ['Stopped', 'being started'],
    desc: 'Stopped, being started (but can be forced to stop - if clicked on STOP action) -- TRANSITIONAL STATE',
    actionType: FS_ACTION_TYPE__STOP_SCRIPT_RUNNER,
    actionTypeForFS: FS_ACTION_TYPE__STOP_FILE_SYSTEM_RUNNERS,
    actionTypeForMachine: FS_ACTION_TYPE__STOP_MACHINE_RUNNERS,
    actionTypeForDatService: FS_ACTION_TYPE__STOP_DAT_SERVICE_RUNNER,
  },
  1: {
    iconClassName: RunnerIcon.RUN,
    iconStateForFS: 'icon-run',
    iconActionForFS: 'icon-stop',
    actionLabel: 'Stop',
    stateLabel: ['Running'],
    desc: 'Running (but can be stopped - if clicked on STOP action) -- MAIN STATE',
    actionType: FS_ACTION_TYPE__STOP_SCRIPT_RUNNER,
    actionTypeForFS: FS_ACTION_TYPE__STOP_FILE_SYSTEM_RUNNERS,
    actionTypeForMachine: FS_ACTION_TYPE__STOP_MACHINE_RUNNERS,
    actionTypeForDatService: FS_ACTION_TYPE__STOP_DAT_SERVICE_RUNNER,
  },
  2: {
    iconClassName: RunnerIcon.STOP,
    iconStateForFS: 'icon-stop',
    iconActionForFS: 'icon-run',
    actionLabel: 'Run',
    stateLabel: ['Stopped'],
    desc: 'Stopped (but can be started - if clicked on RUN action) -- MAIN STATE',
    actionType: FS_ACTION_TYPE__START_SCRIPT_RUNNER,
    actionTypeForFS: FS_ACTION_TYPE__START_FILE_SYSTEM_RUNNERS,
    actionTypeForMachine: FS_ACTION_TYPE__START_MACHINE_RUNNERS,
    actionTypeForDatService: FS_ACTION_TYPE__START_DAT_SERVICE_RUNNER,
  },
  3: {
    iconClassName: RunnerIcon.RUN + ' being-stopped',
    iconStateForFS: 'icon-run being-stopped',
    iconActionForFS: 'icon-run',
    actionLabel: 'Run',
    stateLabel: ['Running', 'being stopped'],
    desc: 'Running, being stopped (but can be forced to run - if clicked on RUN action) -- TRANSITIONAL STATE',
    actionType: FS_ACTION_TYPE__START_SCRIPT_RUNNER,
    actionTypeForFS: FS_ACTION_TYPE__START_FILE_SYSTEM_RUNNERS,
    actionTypeForMachine: FS_ACTION_TYPE__START_MACHINE_RUNNERS,
    actionTypeForDatService: FS_ACTION_TYPE__START_DAT_SERVICE_RUNNER,
  }
};

class LockAndServicesStatuses extends Component {
  state = {
    fsRunnerModalData: null,
    machineRunnerModalData: null,
    officeActionModalOpen: false,
  };

  componentDidUpdate(prevProps) {
    const {
      lockAndServicesStatuses: {FsRootLockStatuses, FileSystemStatuses, OtherRunners}
    } = this.props;
    const {fsRunnerModalData, machineRunnerModalData} = this.state;
    if (fsRunnerModalData) {
      const lockStatusOfRoot = (FsRootLockStatuses || []).find(r => r.FSRootID === fsRunnerModalData.FSRootID);
      if (!lockStatusOfRoot || !FileSystemStatuses || !FileSystemStatuses[fsRunnerModalData.FSRootID]) {
        this.setState({fsRunnerModalData: null});
      } else if (FileSystemStatuses[fsRunnerModalData.FSRootID].BitStatus !== fsRunnerModalData.StatusBits) {
        const {BitStatus} = FileSystemStatuses[fsRunnerModalData.FSRootID];
        const statusBit = RunnerStatusBit[BitStatus];
        if (!statusBit) {
          this.setState({fsRunnerModalData: null});
        } else {
          this.setState({fsRunnerModalData: {...fsRunnerModalData, StatusBits: BitStatus, statusBit: {...statusBit}}});
        }
      }
    } else if (machineRunnerModalData) {
      if (!OtherRunners || !OtherRunners[machineRunnerModalData.runnerLabel]) {
        this.setState({machineRunnerModalData: null});
      } else if (OtherRunners[machineRunnerModalData.runnerLabel].StatusBits !== machineRunnerModalData.StatusBits) {
        const {StatusBits} = OtherRunners[machineRunnerModalData.runnerLabel];
        const statusBit = RunnerStatusBit[StatusBits];
        if (!statusBit) {
          this.setState({machineRunnerModalData: null});
        } else {
          this.setState({machineRunnerModalData: {...machineRunnerModalData, StatusBits, statusBit: {...statusBit}}});
        }
      }
    }
  }

  handleClickConnectDriveBtn = (availableDrives, fsRoot) => {
    const {dispatch} = this.props;
    dispatch(showAvailableDrivesModal({availableDrives, fsRoot}));
  };

  handleClickDisconnectDriveBtn = (drive, fsRoot) => {
    const {dispatch} = this.props;
    confirmAlert({
      title: 'Are You Sure?',
      message: `Are you sure you want to disconnect external drive ${drive.DriveLabelAlias} from ${fsRoot.fsRootLabel}?`,
      buttons: [{
        label: 'No',
      }, {
        label: 'Yes',
        onClick: () => dispatch(disconnectDrive(drive, fsRoot))
      }]
    });
  };

  isForbiddenAction = (actionType, fsRootID) => {
    return isForbiddenActionByPermissions(actionType, fsRootID, this.props.permissions);
  };

  renderReadOnlyDrive(drive, fsRoot) {
    const {DriveLabelAlias, AvailableDrives, FSRootID} = drive;
    const noConnectedDrive = isNoConnectedDrive(DriveLabelAlias, AvailableDrives);

    if (!noConnectedDrive) {
      return (
        <StyledReadOnlyDrive>
          <span>{DriveLabelAlias}</span>
          {
            !this.isForbiddenAction(FS_ACTION_TYPE__DISCONNECT_DRIVE, FSRootID) &&
              <Button
                bsStyle="default"
                title="Disconnect external drive"
                onClick={() => this.handleClickDisconnectDriveBtn(drive, fsRoot)}
              >
                Disconnect
              </Button>
          }
        </StyledReadOnlyDrive>
      );
    }

    if (this.isForbiddenAction(FS_ACTION_TYPE__CONNECT_DRIVE, FSRootID)) {
      return <i>No connected drive</i>;
    }

    if (!Array.isArray(AvailableDrives) || !AvailableDrives.length) {
      return <i>No available drives to connect</i>;
    }

    return (
      <StyledReadOnlyDrive>
        {!!DriveLabelAlias && <span>{DriveLabelAlias}<i> (NOT Available)</i></span>}
        <Button
          bsStyle="default"
          title="Connect external drive"
          onClick={() => this.handleClickConnectDriveBtn(AvailableDrives, fsRoot)}
        >
          Connect
        </Button>
      </StyledReadOnlyDrive>
    );
  }

  handleClickScriptRunnerActionBtn = (actionType, actionData) => {
    const {dispatch} = this.props;
    const {fsRootOrOfficeLabel, runnerKey} = actionData;
    const actionName = [FS_ACTION_TYPE__START_DAT_SERVICE_RUNNER, FS_ACTION_TYPE__START_SCRIPT_RUNNER].includes(actionType) ? 'start' : 'stop';
    const runnerLabel = runnerKey === 'DeltaFinderRunner' ? 'Delta-finder' : runnerKey;
    const labels = `${runnerLabel} for ${fsRootOrOfficeLabel}`;
    confirmAlert({
      title: 'Are You Sure?',
      message: `Are you sure you want to ${actionName} ${labels}?`,
      buttons: [{
        label: 'No',
      }, {
        label: 'Yes',
        onClick: () => dispatch(executeScriptRunnerAction(actionType, actionName, labels, actionData))
      }]
    });
  };

  renderActionRunnerCells(runnerKey, fsRootOrOfficeLabel, statusObj, isForbidden, isOffice, fsRootId) {
    const runner = statusObj[runnerKey] || {};
    const {FsRootID, OfficeFlag, RunnerID, StatusBits} = runner;

    const statusBit = RunnerStatusBit[StatusBits];
    if (!statusBit) {
      return <Fragment key={`${fsRootOrOfficeLabel}-${runnerKey}`}><td/><td/></Fragment>;
    }

    const {iconClassName, stateLabel, actionLabel, actionType, actionTypeForDatService} = statusBit;
    const actionData = {OfficeFlag, isOffice, FsRootID: (fsRootId || FsRootID), isService: !!fsRootId, fsRootOrOfficeLabel, runnerKey, RunnerID};
    const curActionType = runnerKey === 'DATService' ? actionTypeForDatService : actionType;
    const actionIcon = [FS_ACTION_TYPE__START_DAT_SERVICE_RUNNER, FS_ACTION_TYPE__START_SCRIPT_RUNNER].includes(curActionType) ? RunnerIcon.RUN : RunnerIcon.STOP;
    return (
      <Fragment key={`${fsRootOrOfficeLabel}-${runnerKey}`}>
        <td
          className="runner-state"
          title={stateLabel.join(', ')}
        >
          <div>
            <div>
              <i className={iconClassName}/>
            </div>
          </div>
        </td>
        <td className="runner-action">
          <div>
            <div
              className={isForbidden ? 'disabled' : undefined}
              onClick={() => this.handleClickScriptRunnerActionBtn(curActionType, actionData)}
              title={actionLabel}
            >
              <i className={actionIcon}/>
            </div>
          </div>
        </td>
      </Fragment>
    );
  }

  renderServicesRunnersForRoots = (rootLabel, lockStatusOfRoot, isForbidden, runnerKey, runners) => {
    const {FSRootID, ServiceRunners} = lockStatusOfRoot;
    if (!Array.isArray(ServiceRunners) || !ServiceRunners.includes(runnerKey)) {
      return <Fragment key={`${rootLabel}-${runnerKey}`}><td/><td/></Fragment>;
    }
    return this.renderActionRunnerCells(runnerKey, rootLabel, runners, isForbidden, false, FSRootID);
  };

  onOpenAllOfficesModal = () => {
    const {lockAndServicesStatuses: { DATService}} = this.props;
    let StatusBits;
    if (DATService) {
      StatusBits = DATService.StatusBits;
    } else {
      return;
    }
    const isShut = Boolean(StatusBits & 2);

    confirmAlert({
      title: `${isShut ? 'Start' : 'Shut down'} all runners?`,
      message: `Are you sure you want to ${isShut ? 'start' : 'shut down'} all runners in BOTH offices,`
        + ` as well as DAT Services? (DB has to be shut down manually!)`,
      buttons: [
        {
          label: 'Shut down',
          onClick: () => {
            this.setState({
              ...this.state,
              officeActionModalOpen: true,
            });
          },
        },
        {
          label: 'Cancel',
        }
      ]
    });
  }

  renderOfficeServiceStatuses() {
    const {permissions, lockAndServicesStatuses: {OfficeServiceStatuses}} = this.props;
    if (!Array.isArray(OfficeServiceStatuses) || !OfficeServiceStatuses.length) {
      return null;
    }
    return (
      <table>
        <thead>
          <tr>
            <th/>
            <StyledNameTH colSpan="2">
              <span onClick={this.onOpenAllOfficesModal}>Offices</span>
            </StyledNameTH>
            <th colSpan="2">NetflixService</th>
          </tr>
          <tr>
            <th/>
            <th>State</th><th>Action</th>
            <th>State</th><th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            OfficeServiceStatuses.map(office => {
              const {OfficeFlag, OfficeLabel, OfficeDescription} = office;
              const isForbiddenActionScriptRunner =
                isForbiddenActionByUserPermissions(permissions.actionsUserPermissions, FS_ACTION_TYPE__START_SCRIPT_RUNNER) ||
                isForbiddenActionByUserPermissions(permissions.actionsUserPermissions, FS_ACTION_TYPE__STOP_SCRIPT_RUNNER);
              const isForbiddenActionDatServiceRunner =
                isForbiddenActionByUserPermissions(permissions.actionsUserPermissions, FS_ACTION_TYPE__START_DAT_SERVICE_RUNNER) ||
                isForbiddenActionByUserPermissions(permissions.actionsUserPermissions, FS_ACTION_TYPE__STOP_DAT_SERVICE_RUNNER);
              return (
                <tr
                  key={`office-${OfficeFlag}`}
                  title={OfficeDescription}
                >
                  <StyledNameTH>{OfficeLabel}</StyledNameTH>
                  {this.renderOfficeServiceStatusesActionButtons(office, isForbiddenActionDatServiceRunner)}
                  {this.renderActionRunnerCells('NetflixService', OfficeDescription, office, isForbiddenActionScriptRunner, true)}
                </tr>
              );
            })
          }
        </tbody>
      </table>
    );
  }

  renderOfficeServiceStatusesActionButtons(office, isForbidden) {
    const {StatusBits, OfficeFlag} = office;
    const StatusBitObj = RunnerStatusBit[StatusBits];
    if (!StatusBitObj) {
      return <Fragment key={`_office-${OfficeFlag}`}><td/><td/></Fragment>;
    }
    const {iconClassName, stateLabel, actionLabel, actionTypeForDatService} = StatusBitObj;
    const actionIcon = [
        FS_ACTION_TYPE__START_DAT_SERVICE_RUNNER,
        FS_ACTION_TYPE__START_SCRIPT_RUNNER
      ].includes(actionTypeForDatService) ? RunnerIcon.RUN : RunnerIcon.STOP;
    return (
      <Fragment key={`_office-${OfficeFlag}`}>
        <td
          className="runner-state"
          title={stateLabel.join(', ')}
        >
          <div>
            <div>
              <i className={iconClassName}/>
            </div>
          </div>
        </td>
        <td className="runner-action">
          <div>
            <div
              className={isForbidden ? 'disabled' : undefined}
              onClick={() => this.confirmOfficeStateChange(actionTypeForDatService, office)}
              title={actionLabel}>
              <i className={actionIcon}/>
            </div>
          </div>
        </td>
      </Fragment>
    );
  }

  confirmOfficeStateChange(action, office) {
    const {OfficeDescription, OfficeFlag} = office;
    const {dispatch} = this.props;
    const isStartAction = action === FS_ACTION_TYPE__START_DAT_SERVICE_RUNNER;
    const actionText = isStartAction ? 'Start' : 'Shut down';
    confirmAlert({
      title: `${actionText} office runners?`,
      message: `Are you sure you want to ${isStartAction ? 'start' : 'shut down' } all runners in ${OfficeDescription}?`,
      buttons: [
        {
          label: actionText,
          onClick: () => {
            dispatch(executeOfficeAction( isStartAction ?
              FS_ACTION_TYPE__START_OFFICE_SERVICE_RUNNERS : FS_ACTION_TYPE__STOP_OFFICE_SERVICE_RUNNERS, OfficeFlag));
          },
        },
        {
          label: 'Cancel',
        }
      ]
    });
  }

  renderRootCell = (lockStatusOfRoot, rootLabel, fsRootLabelWithOfficeLabel) => {
    const {lockAndServicesStatuses: {FileSystemStatuses}} = this.props;
    const {FSRootID} = lockStatusOfRoot;
    const isForbiddenActionFsRunner =
      this.isForbiddenAction(FS_ACTION_TYPE__START_FILE_SYSTEM_RUNNERS, FSRootID) ||
      this.isForbiddenAction(FS_ACTION_TYPE__STOP_FILE_SYSTEM_RUNNERS, FSRootID);
    let cellInner = rootLabel;
    if (!isForbiddenActionFsRunner && FileSystemStatuses && FileSystemStatuses[FSRootID]) {
      const {BitStatus} = FileSystemStatuses[FSRootID];
      const statusBit = RunnerStatusBit[BitStatus];
      if (statusBit) {
        cellInner = (
          <span
           title={`${statusBit.actionLabel} "${fsRootLabelWithOfficeLabel}"`}
           onClick={() => this.setState({fsRunnerModalData: {FSRootID, runnerLabel: rootLabel, StatusBits: BitStatus, statusBit: {...statusBit}}})}
          >
            {rootLabel}
          </span>
        );
      }
    }
    return <StyledNameTH>{cellInner}</StyledNameTH>;
  };

  renderMachineCell = (machineData) => {
    const {MachineName, IPAddress, fsRootIDs, StatusBits} = machineData;
    const isForbiddenActionMachineRunner = !!fsRootIDs && fsRootIDs.length > 0 && (
      this.isForbiddenAction(FS_ACTION_TYPE__START_MACHINE_RUNNERS, fsRootIDs[0]) ||
      this.isForbiddenAction(FS_ACTION_TYPE__STOP_MACHINE_RUNNERS, fsRootIDs[0])
    );
    const statusBit = RunnerStatusBit[StatusBits];
    let cellInner = MachineName;
    if (!isForbiddenActionMachineRunner && statusBit) {
      cellInner = (
        <span
         title={`${IPAddress}\n${statusBit.actionLabel}`}
         onClick={() => this.setState({machineRunnerModalData: {FSRootID: +fsRootIDs[0], runnerLabel: MachineName, StatusBits, statusBit: {...statusBit}}})}
        >
          {MachineName}
        </span>
      );
    }
    return <StyledNameTH key={`r1-${MachineName}`} colSpan="2" title={IPAddress}>{cellInner}</StyledNameTH>;
  };

  closeAllModals = () => {
    this.setState({
      fsRunnerModalData: null,
      machineRunnerModalData: null,
      officeActionModalOpen: false,
    });
  };

  handleExecuteFsRunner = ({actionTypeForFS}, fsRootID, fsRootLabel) => {
    const {dispatch} = this.props;
    const actionName = actionTypeForFS === FS_ACTION_TYPE__START_FILE_SYSTEM_RUNNERS ? 'start' : 'stop';
    dispatch(executeFileSystemRunnerAction(actionTypeForFS, actionName, fsRootID, fsRootLabel));
    this.closeAllModals();
  };

  handleExecuteMachineRunner = ({actionTypeForMachine}, fsRootID, machineName) => {
    const {dispatch} = this.props;
    const actionName = actionTypeForMachine === FS_ACTION_TYPE__START_MACHINE_RUNNERS ? 'start' : 'stop';
    dispatch(executeMachineRunnerAction(actionTypeForMachine, actionName, fsRootID, machineName));
    this.closeAllModals();
  };

  render() {
    const {height, availableFsRoots, lockAndServicesStatuses, dispatch} = this.props;
    const {FsRootLockStatuses, OtherRunners, DATService} = lockAndServicesStatuses;
    const sortedOtherRunnersNames = Object.keys(OtherRunners || {}).sort((a, b) => a.RunnerID - b.RunnerID);
    const {fsRunnerModalData, machineRunnerModalData, officeActionModalOpen} = this.state;
    const isMacOsPath = isMacOsPathView();
    return (
      <StyledComponent height={height}>
        {!!(fsRunnerModalData || machineRunnerModalData) &&
          <FsRunnerModal
            data={fsRunnerModalData || machineRunnerModalData}
            onClose={this.closeAllModals}
            onExecuteAction={fsRunnerModalData ? this.handleExecuteFsRunner : this.handleExecuteMachineRunner}
          />
        }
        <AllOfficesActionModal
          dispatch={dispatch}
          show={officeActionModalOpen}
          onClose={this.closeAllModals}
          DATService={DATService}
          RunnerStatusBit={RunnerStatusBit}
        />
        <div className="container">
          {!!availableFsRoots.length &&
          <table>
            <thead>
              <tr>
                <th/>
                <th/>
                <th colSpan="2">Delta-finder</th>
                {sortedOtherRunnersNames.map(k => this.renderMachineCell(OtherRunners[k]))}
                <th/>
                <th/>
              </tr>
              <tr>
                <th/>
                <th/>
                <th>State</th><th>Action</th>
                {sortedOtherRunnersNames.map(k =>
                  <Fragment key={`r2-${k}`}>
                    <th title={OtherRunners[k].IPAddress}>State</th><th title={OtherRunners[k].IPAddress}>Action</th>
                  </Fragment>
                )}
                <th/>
                <th/>
              </tr>
            </thead>
            <tbody>
              {
                availableFsRoots.map((root, i) => {
                  const lockStatusOfRoot = (FsRootLockStatuses || []).find(r => r.FSRootID === root.value) || {};
                  const {LockStatus, IsAlive, FSUsedPercent, ReadOnly, FSRootID} = lockStatusOfRoot;
                  const fsRootLabel = isMacOsPath && root.macLabel ? root.macLabel : root.label;
                  const fsRootLabelWithOfficeLabel = isMacOsPath && root.macLabel ? root.nameWithOfficeLabel : (root.unixNameWithOfficeLabel || root.nameWithOfficeLabel);
                  const isNotAlive = IsAlive !== undefined && IsAlive !== null && !IsAlive;
                  let usedPercent;
                  if (FSUsedPercent !== undefined && FSUsedPercent !== null && FSUsedPercent !== '') {
                    usedPercent = `${FSUsedPercent}%`;
                  }
                  const isForbiddenActionScriptRunner =
                    this.isForbiddenAction(FS_ACTION_TYPE__START_SCRIPT_RUNNER, FSRootID) ||
                    this.isForbiddenAction(FS_ACTION_TYPE__STOP_SCRIPT_RUNNER, FSRootID);
                  return (
                    <tr
                      className={isNotAlive ? 'not-alive' : ''}
                      key={`root-${i}`}
                      title={isNotAlive ? 'FS is not alive' : undefined}
                    >
                      {this.renderRootCell(lockStatusOfRoot, fsRootLabel, fsRootLabelWithOfficeLabel)}
                      <td><span title="FS lock status">{LockStatus || 'n/a'}</span></td>
                      {this.renderActionRunnerCells('DeltaFinderRunner', fsRootLabel, lockStatusOfRoot, isForbiddenActionScriptRunner, false)}
                      {sortedOtherRunnersNames.map(k =>
                        this.renderServicesRunnersForRoots(fsRootLabel, lockStatusOfRoot, isForbiddenActionScriptRunner, k, OtherRunners)
                      )}
                      <td className="readonly-drive-cell">
                        {
                          !ReadOnly ?
                            <span title="FS used percent">{usedPercent}</span> :
                              this.renderReadOnlyDrive(lockStatusOfRoot, {...root, fsRootLabel})
                        }
                      </td>
                      <td>{isNotAlive ? 'NOT ALIVE' : ''}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
          }
          {this.renderOfficeServiceStatuses()}
        </div>
      </StyledComponent>
    );
  }
}

const StyledReadOnlyDrive = styled.div`
  span + button {
    margin-left: 5px;
  }
  button {
    padding: 0 5px;
    height: 16px;
    line-height: 1;
    font-size: 11px;
    outline: none !important;
  }
`;

const StyledNameTH = styled.th`
  span {
    padding: 0 2px;
    border: 1px solid #245580;
    border-radius: 2px;
    background-color: #296294;
    color: #fff;
    line-height: 12px;
    cursor: pointer;
    :hover {
      background-color: #204d74 !important;
      border-color: #122b40 !important;
    }
  }
`;

const StyledComponent = styled.div`
  position: fixed;
  top: 51px;
  right: 0;
  left: 0;
  height: ${props => `${props.height}px`};
  background: #fff;
  z-index: 1020;

  .container {
    --settings-padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: start;

    .btn-settings {
      margin: var(--settings-padding) var(--settings-padding) var(--settings-padding) auto;      
    }
    table {
      margin-top: 15px;
      margin-right: 50px;
      :last-child {
        margin-right: 0;
      }

      thead {
        tr {
          th {
            padding: 0 7px;
            font-size: 11px;
            line-height: 13px;
            text-align: center;
          }

          :last-child {
            th {
              padding-bottom: 5px;
            }
          }
        }
      }

      tbody {
        tr {
          th, td {
            padding: 0 7px;
            font-size: 11px;
            line-height: 13px;
            &.runner-state, &.runner-action {
              text-align: center;
              i {
                color: #282828;
              }
              &.runner-state {
                i {
                  color: #757575;
                }
              }
              > div {
                display: flex;
                align-items: center;
                justify-content: center;
                > div {
                  margin-top: -3px;
                  padding: 2px;
                  width: 17px;
                  height: 17px;
                  border: 1px solid transparent;
                  border-radius: 2px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  i {
                    position: relative;
                    font-size: 13px;
                    &.being-stopped:after {
                      content: '';
                      position: absolute;
                      top: 4px;
                      left: 2px;
                      width: 5px;
                      height: 5px;
                      border: 1px solid #000;
                      background: #fff;
                    }
                    &.icon-stop-being-started {
                      min-width: 11.83px;
                      min-height: 11.83px;
                      max-width: 11.83px;
                      max-height: 11.83px;
                      background-color: #757575;
                      border-radius: 1.5px;
                      position: relative;
                      &:before {
                        content: '';
                        width: 0;
                        height: 0;
                        border-top: 4px solid transparent;
                        border-left: 7px solid #000;
                        border-bottom: 4px solid transparent;
                        position: absolute;
                        top: 2px;
                        left: 3px;
                        z-index: 2;
                      }
                      &:after {
                        content: '';
                        width: 0;
                        height: 0;
                        border-top: 2px solid transparent;
                        border-left: 4px solid #fff;
                        border-bottom: 2px solid transparent;
                        position: absolute;
                        top: 4px;
                        left: 4px;
                        z-index: 3;
                      }
                    }
                  }
                }
              }
              &.runner-action {
                > div {
                  > div {
                    cursor: pointer;
                    :hover {
                      border-color: #ccc;
                      background-color: #ececec;
                    }
                    &.disabled {
                      pointer-events: none;
                      opacity: 0.65;
                    }
                  }
                }
              }
            }
            &.readonly-drive-cell * {
              white-space: nowrap;
            }
          }

          &:hover {
            background-color: #f0f2ff;
            box-shadow: 0 -3px 0 #f0f2ff, 0 2px 0 #f0f2ff;
            th {
              span {
                background-color: #1d69ab;
              }
            }
          }

          &:not(:last-child) {
            &:hover {
              box-shadow: 0 -5px 0 #f0f2ff;
            }

            th, td {
              padding-bottom: 5px;
            }
          }

          &.not-alive {
            color: red;
          }
        }
      }
    }
  }
`;

LockAndServicesStatuses.propsTypes = {
  dispatch: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  availableFsRoots: PropTypes.array.isRequired,
  lockAndServicesStatuses: PropTypes.object.isRequired
};

export default LockAndServicesStatuses;
