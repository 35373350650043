import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button} from 'react-bootstrap';
import styled from 'styled-components';
import {setFoldersInModal} from '../../actions';
import {PathFieldStyled} from './ModalStyled';
import Loader from '../../../../components/Loader';
import FolderExplorerNew from '../forms/FolderExplorerNew';
import RndModal from '../../../../components/modals/RndModal';
import ReportBugButton from "../../../../components/ReportBugButton";

class SelectFolderModal extends Component {
  state = {
    selectedFolder: null
  };

  componentDidMount() {
    const {dispatch, storageManager, rootName} = this.props;
    const {fsRoots} = storageManager;
    let folders = fsRoots.filter(i => i.fsRootName === rootName).map(i => ({...i}));
    if (!folders.length) {
      folders = fsRoots.map(i => ({...i}));
    }
    dispatch(setFoldersInModal(folders));
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  handleClickConfirmSelect = () => {
    const {onConfirm} = this.props;
    const {selectedFolder} = this.state;
    onConfirm(selectedFolder);
  };

  render() {
    const {onClose, storageManager, categoryName, isSaving, isManuallyReconcile, dispatch} = this.props;
    const {foldersInModal, isLoadingModal, loaders, openedFoldersInModal, currentView} = storageManager;
    const {selectedFolder} = this.state;
    return (
      <RndModal
        bsSize="large"
        className="move-modal"
        show={true}
        onHide={onClose}
        backdrop="static"
        enforceFocus={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>
            {isManuallyReconcile ? 'Manually Reconcile. Select Folder' : 'Select Downloaded Folder'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContainerStyled className="container-fluid">
            {isManuallyReconcile ? null : (isLoadingModal ?
                <div>
                  <Loader/>
                  <ReportBugButton isForModal className="loader-report-bug-btn"/>
                </div> : isSaving ?
                <div>
                  <Loader className="full-screen"/>
                  <ReportBugButton isForModal className="loader-report-bug-btn"/>
                </div> : null)}
            <CustomPathFieldStyled className="category" color="green">
              <label>{isManuallyReconcile ? 'Original Asset' : 'Category Name'}:</label>
              <div className="value">{categoryName}</div>
            </CustomPathFieldStyled>
            {/*<CustomPathFieldStyled className="folder">
              <label>{isManuallyReconcile ? 'Copied Asset' : 'Downloaded Folder'}:</label>
              <div className="value">{selectedFolder ? selectedFolder.key : '...'}</div>
            </CustomPathFieldStyled>*/}
            <FolderExplorerNew
              dispatch={dispatch}
              loaders={loaders}
              folders={foldersInModal}
              openedFolders={openedFoldersInModal}
              currentView={currentView}
              selectedFolder={selectedFolder}
              onSelectFolder={selFolder => this.setState({selectedFolder: selFolder})}
              withoutNewFolders
              selectedFolderLabel={`${isManuallyReconcile ? 'Copied Asset' : 'Downloaded Folder'}:`}
              selectedFolderLabelWidth={150}
              storageManager={storageManager}
            />
          </ModalContainerStyled>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.handleClickConfirmSelect} disabled={!selectedFolder}>
            {isManuallyReconcile ? 'Continue' : 'Confirm'}
          </Button>
          <Button bsStyle="default" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
      </RndModal>
    );
  }
}

const CustomPathFieldStyled = styled(PathFieldStyled)`
  label {
    margin: 0;
    padding: 0;
    font-weight: bold;
    min-width: 150px;
  }
`;

const ModalContainerStyled = styled.div`
  min-height: 200px;
  .loader-container {
    z-index: 1051;
  }
`;

SelectFolderModal.propTypes = {
  dispatch: PropTypes.func,
  storageManager: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  categoryName: PropTypes.string.isRequired,
  rootName: PropTypes.string.isRequired,
  isSaving: PropTypes.bool.isRequired
};

export default SelectFolderModal;
