import Cookies from 'js-cookie';
import $ from 'jquery';
import config from '../../../config';
import {getCurrentDomainObj, buildRedirectUrlToLoginPage, getCurrentPageToSave} from "../../utils";
import qs from 'qs';

export const USER_SHOW_LOGIN_ERROR_MESSAGE = 'USER_SHOW_LOGIN_ERROR_MESSAGE';
export const USER_SHOW_LOGIN_MESSAGE = 'USER_SHOW_LOGIN_MESSAGE';
export const USER_HIDE_LOGIN_MESSAGE = 'USER_HIDE_LOGIN_MESSAGE';
export const USER_SET_IS_LOGIN = 'USER_SET_IS_LOGIN';
export const USER_SET_IS_LOGOUT = 'USER_SET_IS_LOGOUT';
export const USER_SAVE_PREVIOUS_PAGE = 'USER_SAVE_PREVIOUS_PAGE';
export const USER_SET_DATA_FIELD = 'USER_SET_DATA_FIELD';

const savePreviousPage = previousPage => ({
  type: USER_SAVE_PREVIOUS_PAGE,
  previousPage
});

export const savePreviousPageUrl = () => dispatch => {
  if (window.location.pathname.indexOf('login') === -1) {
    dispatch(savePreviousPage(getCurrentPageToSave()));
  }
};

export const showLoginMessage = (loginMessage, showLoginMessage) => ({
  type: USER_SHOW_LOGIN_MESSAGE, loginMessage, showLoginMessage
});

export const showLoginErrorMessage = message => ({
  type: USER_SHOW_LOGIN_ERROR_MESSAGE, message
});

export const hideLoginMessage = () => ({
  type: USER_HIDE_LOGIN_MESSAGE
});

export const setUserDataField = (name, value) => ({
  type: USER_SET_DATA_FIELD,
  name,
  value
});

export const login = (history, email, password, previousPage) => dispatch => {
  dispatch(showLoginMessage('', false));
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/auth`,
    data: {
      email,
      password,
      resource_id: 1
    }
  })
  .done(res => {
    // Authorization
    const authorization = `Bearer${res.token}`;
    // Send token in headers for development
    $.ajaxSetup({
      headers: {
        Authorization: authorization
      }
    });
    // Send token in cookies for production
    Cookies.remove('__auth', getCurrentDomainObj());
    Cookies.set('__auth', authorization, getCurrentDomainObj());

    Cookies.set('__email', email, getCurrentDomainObj());
    dispatch(setUserDataField('email', email));

    // Redirect
    let redirectUrl = previousPage;
    if (!redirectUrl) {
      const urlFilters = qs.parse(window.location.search.substr(1));
      if (urlFilters && urlFilters.r) {
        redirectUrl = decodeURIComponent(urlFilters.r);
      }
    }
    history.push(redirectUrl || '/');
  })
  .fail(() => {
    dispatch(showLoginErrorMessage('Incorrect email, password, or IP'));
  });
};

const initAjax = history => dispatch => {
  $.ajaxSetup({
    headers: {
      Authorization: Cookies.get('__auth', getCurrentDomainObj())
    },
    statusCode: {
      401: () => {
        dispatch(savePreviousPageUrl());
        history.push(buildRedirectUrlToLoginPage());
      }
    }
  });
};

export const initAuth = (history, isLogin) => dispatch => {
  if (isLogin) {
    dispatch(initAjax(history));
    return;
  }
  $.ajax({
    method: 'GET',
    headers: {
      Authorization: Cookies.get('__auth', getCurrentDomainObj())
    },
    url: `${config.apiUrl}/auth/ping`
  })
  .done(res => {
    if (res.pong) {
      dispatch(initAjax(history));
      dispatch({type: USER_SET_IS_LOGIN});
    } else {
      dispatch(savePreviousPageUrl());
      history.push(buildRedirectUrlToLoginPage());
    }
  })
  .fail(() => {
    dispatch(savePreviousPageUrl());
    history.push(buildRedirectUrlToLoginPage());
  });
};

export const logout = history => dispatch => {
  $.ajaxSetup({
    headers: {
      Authorization: ''
    }
  });
  Cookies.remove('__auth', getCurrentDomainObj());
  Cookies.remove('__email', getCurrentDomainObj());
  history.push('/login');
  dispatch({type: USER_SET_IS_LOGOUT});
};
