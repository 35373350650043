import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Grid, Row, Alert} from 'react-bootstrap';
import styled, {createGlobalStyle} from 'styled-components';

class LoginLayout extends Component {
  componentDidMount() {
    document.title = 'MAM: Login';
    document.body.className += ' login-layout';
  }

  componentWillUnmount() {
    const {body} = document;
    body.className = body.className.replace(/ login-layout/g, '').trim();
  }

  render() {
    const {user} = this.props;
    return (
      <StyledLoginLayout>
        <Grid>
          <Row>
            <div>
              {user.loginMessage ? <Alert bsStyle="success">{user.loginMessage}</Alert> : null}
              {user.loginErrorMessage ? <Alert bsStyle="danger">{user.loginErrorMessage}</Alert> : null}
              {this.props.children}
            </div>
          </Row>
        </Grid>
        <GlobalStyled/>
      </StyledLoginLayout>
    );
  }
}

const GlobalStyled = createGlobalStyle`
  html, body {
    height: 100%;
    background: linear-gradient(122.07deg, #0DB4D9 0%, rgba(2, 80, 173, 0.25) 99.75%), #004A8D no-repeat;
  }
`;

const StyledLoginLayout = styled.div`
`;

LoginLayout.propTypes = {
  children: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(
  mapStateToProps
)(LoginLayout);
