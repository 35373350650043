import ISSUE_TYPES from "./types";

const ISSUE_BROWSER_INFO_PANEL_FIELDS = [
  {
    label: "Bug ID",
    value: ISSUE_TYPES.REPORTED_BUG_ID,
  },
  {
    label: "Description",
    value: ISSUE_TYPES.MESSAGE,
  },
  {
    label: "Reported By",
    value: ISSUE_TYPES.REPORTED_BY,
  },
  {
    label: "Assigned To",
    value: ISSUE_TYPES.ASSIGNED_TO,
  },
  {
    label: "Jira URL",
    value: ISSUE_TYPES.JIRA_URL,
  },
  {
    label: "URL",
    value: ISSUE_TYPES.URL,
  },
  {
    label: "Filename",
    value: ISSUE_TYPES.FILENAME,
  },
  {
    label: "Created On",
    value: ISSUE_TYPES.CREATED_ON,
  },
  {
    label: "Updated On",
    value: ISSUE_TYPES.UPDATED_ON,
  },
  {
    label: "Priority",
    value: ISSUE_TYPES.PRIORITY,
  },
  {
    label: "Category",
    value: ISSUE_TYPES.CATEGORY,
  },
  {
    label: "Status",
    value: ISSUE_TYPES.STATUS,
  },
  {
    label: "Comments",
    value: ISSUE_TYPES.COMMENTS,
  },
];

export default ISSUE_BROWSER_INFO_PANEL_FIELDS;
