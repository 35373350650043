import React from 'react';
import PropTypes from 'prop-types';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

/*const ellipseOld = (parentNode, childNode, txtNode) => {
  const childWidth = childNode.offsetWidth;
  const containerWidth = parentNode.offsetWidth;
  const txtWidth = txtNode.offsetWidth;
  const targetWidth = childWidth > txtWidth ? childWidth : txtWidth;

  if (targetWidth > containerWidth) {
    const firstCharsLeft = 10;
    const str = txtNode.textContent;
    const txtChars = str.length;
    const avgLetterSize = txtWidth / txtChars;
    const canFit = (containerWidth - (targetWidth - txtWidth)) / avgLetterSize;
    const delEachSide = (txtChars - canFit*//* + 5*//*) / 2;
    let endLeft = Math.floor(txtChars / 2 - delEachSide);
    let startRight = Math.ceil(txtChars / 2 + delEachSide);
    if (endLeft > firstCharsLeft && canFit > (firstCharsLeft * 2 + 2)) {
      startRight -= endLeft - firstCharsLeft;
      endLeft = firstCharsLeft;
    }
    txtNode.setAttribute('data-original', txtNode.textContent);
    txtNode.textContent = str.substr(0, endLeft) + '...' + str.substr(startRight);
  }
};*/

const ellipse = (parentNode, childNode1, childNode2) => {
  const childWidth1 = childNode1.offsetWidth;
  const childWidth2 = childNode2 ? childNode2.offsetWidth : 0;
  const containerWidth = parentNode.offsetWidth;
  const targetWidth = childWidth1 + childWidth2;

  if (targetWidth > containerWidth) {
    const firstCharsLeft = 10;
    if (!childNode2) {
      const str = childNode1.textContent;
      const txtChars = str.length;
      const avgLetterSize = targetWidth / txtChars;
      const canFit = containerWidth / avgLetterSize;
      const delEachSide = (txtChars - canFit + 5/* + 5*/) / 2;
      let endLeft = Math.floor(txtChars / 2 - delEachSide);
      let startRight = Math.ceil(txtChars / 2 + delEachSide);
      if (endLeft > firstCharsLeft && canFit > (firstCharsLeft * 2 + 2)) {
        startRight -= endLeft - firstCharsLeft;
        endLeft = firstCharsLeft;
      }
      childNode1.setAttribute('data-original', childNode1.textContent);
      childNode1.textContent = str.substr(0, endLeft) + '...' + str.substr(startRight);
    } else {
      const str1 = childNode1.textContent;
      const str2 = childNode2.textContent;
      const txtChars1 = str1.length;
      const txtChars2 = str2.length;
      const txtChars = txtChars1 + txtChars2;
      const avgLetterSize = targetWidth / txtChars;
      const canFit = containerWidth / avgLetterSize;
      const delLeftSide = (txtChars - canFit + 5/* + 5*/) * (childWidth1 / targetWidth);
      const delRightSide = (txtChars - canFit + 5/* + 5*/) * (childWidth2 / targetWidth);
      let endLeft = Math.floor(txtChars1 - delLeftSide);
      let startRight = Math.ceil(delRightSide);
      if (endLeft < 3) {
        startRight += 3 - endLeft;
        endLeft = 3;
      }
      if (endLeft > firstCharsLeft && canFit > (firstCharsLeft * 2 + 2) && (startRight - (endLeft - firstCharsLeft)) < txtChars2) {
        startRight -= endLeft - firstCharsLeft;
        endLeft = firstCharsLeft;
      }
      if (endLeft > txtChars1) {
        endLeft = txtChars1;
      }
      if (startRight >= txtChars2) {
        startRight = 0;
      } else if (startRight < 0) {
        endLeft = Math.min(txtChars1, endLeft + Math.abs(startRight));
        startRight = 0;
      }
      childNode1.setAttribute('data-original', childNode1.textContent);
      childNode1.textContent = endLeft === txtChars1 ? str1 : str1.substr(0, endLeft) + (startRight === 0 ? '...' : '');
      childNode2.setAttribute('data-original', childNode2.textContent);
      childNode2.textContent = startRight === 0 ? str2 : '...' + str2.substr(startRight);
    }
    return true;
  }
  return false;
};

const TextEllipsis = (props) => {
  const {data, colors, tooltipText, tooltipContainer, noTooltip, useMonospaceFont} = props;
  const isArrayData = Array.isArray(data);
  const fullText = isArrayData ? data.join('') : data || '';
  const isValidColors = isArrayData && Array.isArray(colors) && data.length === colors.length && colors.slice(0, 2).some(Boolean);
  const isSingleText = !isValidColors || data.filter(Boolean).length === 1;

  const prepEllipse = node => {
    let result;
    try {
      const parent = node.parentNode;
      const child1 = node.childNodes[0];
      const child2 = isSingleText ? null : node.childNodes[1];

      if (child1 !== null) {
        // (Re)-set text back to original text.
        child1.textContent = child1.getAttribute('data-original');
        if (child2) {
          child2.textContent = child2.getAttribute('data-original');
        }

        result = ellipse(
          // Use the smaller width.
          node.offsetWidth > parent.offsetWidth ? parent : node,
          child1,
          child2
        );
      }
    } catch {}
    try {
      if (node) {
        node.style.pointerEvents = result ? 'unset' : 'none';
      }
    } catch {}
  },
  measuredParent = node => {
    if (node !== null) {
      window.addEventListener('resize', () => {
        prepEllipse(node);
      });
      prepEllipse(node);
    }
  };

  const innerContent = isSingleText ? <span style={isValidColors && colors[0] ? {color: colors[0]} : undefined} data-original={fullText}>{fullText}</span> : (
    <>
      <span style={colors[0] ? {color: colors[0]} : undefined} data-original={data[0]}>{data[0]}</span>
      <span style={colors[1] ? {color: colors[1]} : undefined} data-original={data.slice(1).join('')}>{data.slice(1).join('')}</span>
    </>
  );

  const ellipsisElm = (
    <div
      ref={measuredParent}
      style={{
        ...(useMonospaceFont ? {fontFamily: 'monospace'} : {}),
        wordBreak: 'keep-all',
        overflowWrap: 'normal'
      }}
    >
      {innerContent}
    </div>
  );

  return !fullText ? fullText : (!noTooltip ?
    <OverlayTrigger
      placement="bottom"
      container={tooltipContainer}
      overlay={<Tooltip id="text-ellipsis-tooltip">{tooltipText || innerContent}</Tooltip>}
    >
      {ellipsisElm}
    </OverlayTrigger> : ellipsisElm
  );
};

TextEllipsis.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  colors: PropTypes.arrayOf(PropTypes.string),
  tooltipText: PropTypes.string,
  tooltipContainer: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.element, PropTypes.elementType]),
  noTooltip: PropTypes.bool
};

export default TextEllipsis;