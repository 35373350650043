import React from 'react';
import {StyledWorkOrder} from './styledStep';

const WorkOrder = props => {
  const {oneCategoryAndOneWorkOrder, woMemfis, description, isOpened, onOpen, onHide, categoryName, onShowDesc} = props;

  if (oneCategoryAndOneWorkOrder) {
    return null;
  }

  return (
    <StyledWorkOrder>
      <i
        className={`far fa-${isOpened ? 'minus' : 'plus'}-square`}
        title={isOpened ? 'Collapse' : 'Expand'}
        onClick={onOpen}
      />
      {woMemfis}
      {!!description &&
        <i
          className="fas fa-info-circle"
          title="View instructions"
          onClick={() => onShowDesc(`${woMemfis} - ${categoryName}`, description)}
        />
      }
      {!!onHide &&
        <i
          className="far fa-eye-slash"
          title="Hide work order"
          onClick={onHide}
        />
      }
    </StyledWorkOrder>
  );
};

export default WorkOrder;