import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button} from 'react-bootstrap';
import styled from 'styled-components';
import {executeDownloadPackageAction, closeDownloadPackageModal} from '../../actions';
import Loader from '../../../../components/Loader';
import FolderExplorerNew from '../../../StorageManager/components/forms/FolderExplorerNew';
import {setFoldersInModal, clearAllStorageMngForDownloadMng} from '../../../StorageManager/actions';
import RndModal from '../../../../components/modals/RndModal';
import ReportBugButton from "../../../../components/ReportBugButton";

class DownloadAsperaPackageModal extends Component {
  state = {
    selectedFolder: null
  };

  setSelectedFolder = (newFolder) => {
    this.setState({selectedFolder: newFolder});
  };

  componentDidMount() {
    const {dispatch, storageManager} = this.props;
    const {fsRoots} = storageManager;
    const folders = fsRoots.map(i => ({...i}));
    dispatch(setFoldersInModal(folders));
  }

  componentDidUpdate(prevProps) {
    const {dispatch, storageManager} = this.props;
    const {fsRoots} = storageManager;
    if (!prevProps.storageManager.fsRoots.length && fsRoots.length) {
      const folders = fsRoots.map(i => ({...i}));
      dispatch(setFoldersInModal(folders));
    }
  }

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(clearAllStorageMngForDownloadMng());
    dispatch(closeDownloadPackageModal(false));
  };

  componentWillUnmount() {
    this.handleClose();
  }

  handleClickConfirmSelect = () => {
    const {dispatch} = this.props;
    const {selectedFolder} = this.state;
    dispatch(executeDownloadPackageAction(selectedFolder));
  };

  render() {
    const {dispatch, storageManager} = this.props;
    const {isLoadingModal, loaders, availableFsRootsIsLoading, foldersInModal, openedFoldersInModal} = storageManager;
    const {selectedFolder} = this.state;
    return (
      <RndModal
        bsSize="large"
        className="move-modal"
        show={true}
        onHide={this.handleClose}
        backdrop="static"
        enforceFocus={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>
            Select Destination Folder
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContainerStyled className="container-fluid">
            {(isLoadingModal || availableFsRootsIsLoading) ?
                <div>
                  <Loader/>
                  <ReportBugButton isForModal className="loader-report-bug-btn"/>
                </div> : null}
            <FolderExplorerNew
              dispatch={dispatch}
              loaders={loaders}
              folders={foldersInModal}
              openedFolders={openedFoldersInModal}
              selectedFolder={selectedFolder}
              onSelectFolder={this.setSelectedFolder}
              currentView=""
              selectedFolderLabel="Destination Folder:"
              selectedFolderLabelWidth={150}
              storageManager={storageManager}
            />
          </ModalContainerStyled>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.handleClickConfirmSelect} disabled={!selectedFolder}>
            Confirm
          </Button>
          <Button bsStyle="default" onClick={this.handleClose}>Cancel</Button>
        </Modal.Footer>
      </RndModal>
    );
  }
}

const ModalContainerStyled = styled.div`
  min-height: 200px;
  .loader-container {
    z-index: 1051;
  }
`;

DownloadAsperaPackageModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  storageManager: PropTypes.object.isRequired,
};

export default DownloadAsperaPackageModal;
