import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {InputStyled} from './InputStyled';

class NavigationInput extends Component {
  resetValuesOnBlur = false;

  state = {
    value: this.props.value
  };

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.value) !== JSON.stringify(prevProps.value)) {
      this.setState({
        value: this.props.value
      });
    }
  }

  handleKeyDown = event => {
    const keyCode = event.keyCode;
    if (keyCode === 13) {
      const {value} = this.state;
      if (value && value.trim()) {
        this.props.onEnter(value.trim(), () => {
          this.resetValuesOnBlur = false;
          this._input.blur();
        });
      }
    } else if (keyCode === 27) {
      this._input.blur();
    }
  };

  handleChange = event => {
    this.resetValuesOnBlur = true;
    const value = event.target.value;
    this.setState({value});
    const {onChange} = this.props;
    onChange(value);
  };

  setAppliedValue = () => {
    const {appliedValue, onChange} = this.props;
    onChange(appliedValue);
    this.setState({value: appliedValue});
  };

  handleBlur = (e) => {
    if(e.relatedTarget && e.relatedTarget.id === 'handleChangeBtn'){
      return;
    }
    if (this.resetValuesOnBlur) {
      this.setAppliedValue();
    } else {
      this.forceUpdate();
    }
  };

  render() {
    return (
      <NavigationInputStyled
        id={this.props.id || "navigation-input"}
        className="form-control"
        type="text"
        value={this.state.value}
        autoComplete="off"
        onKeyDown={this.handleKeyDown}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        ref={r => this._input = r}
        onClick={this.props.onClick}
      />
    );
  }
}

const NavigationInputStyled = styled(InputStyled)`
  /*margin-left: 20px;*/
`;

NavigationInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  appliedValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired
};

export default NavigationInput;
