export default function(files, root, data) {
  let fileTree = {
    contents: [],
    children: {},
  };

  files.forEach((file) => {
    file.relativeKey = (file.newKey || file.key).substr(root.length);
    let currentFolder = fileTree;
    const re = /[\\/]/;
    let folders;
    let fileKey = file.relativeKey;
    if (file.browseName && fileKey.startsWith(file.browseName)) {
      fileKey = fileKey.replace(file.browseName, '').replace(/^[\\/]*/, '');
      folders = [file.browseName.replace(/[\\/]$/, '')].concat(fileKey.split(re));
    } else if (fileKey.startsWith(file.fsRootName)) {
      fileKey = fileKey.replace(file.fsRootName, '').replace(/^[\\/]*/, '');
      folders = [file.fsRootName].concat(fileKey.split(re));
    } else {
      folders = fileKey.split(re);
    }
    folders = folders.filter(f => Boolean(f));
    folders.forEach((folder, folderIndex) => {
      if (folderIndex === folders.length - 1 && file.isDirectory) {
        for (let key in file) {
          currentFolder[key] = file[key];
        }
      }
      if (folder === '') {
        return;
      }
      const isAFile = (!file.isDirectory && (folderIndex === folders.length - 1))
      if (isAFile) {
        currentFolder.contents.push({
          ...file,
          keyDerived: true,
          key: folder
        });
      } else {
        if (folder in currentFolder.children === false) {
          currentFolder.children[folder] = {
            contents: [],
            children: {},
            size: file.size,
            modified: file.modified,
            modifiedOn: file.modifiedOn,
            fsRootID: file.fsRootID,
            contentID: file.contentID,
            barCode: file.barCode,
            memfisWoID: file.memfisWoID,
            isDirectory: true
          };
        }
        currentFolder = currentFolder.children[folder];
      }
    });
  });

  let add_all_children = function(level, prefix, fsRootName, isRoot) {
    if (prefix !== '') {
      prefix += '/';
    }
    let files = [];
    for (let folder in level.children) {
      files.push({
        ...level.children[folder],
        contents: undefined,
        keyDerived: true,
        fsRootName,
        isRoot,
        name: (root + prefix + folder) === fsRootName ? fsRootName : undefined,
        key: root + prefix + folder + '/',
        relativeKey: prefix + folder + '/',
        children: add_all_children(level.children[folder], prefix + folder, fsRootName, isRoot)
      });
    }
    files = files.concat(level.contents);
    return files;
  };

  const file = files.length ? files[0] : {};
  files = add_all_children(fileTree, '', file.browseName || file.fsRootName, file.isRoot);

  if (data && data.root && files.length && files[0].isDirectory && files[0].children.length &&
    files[0].name === data.root.key) {
    files[0].size = data.root.size;
    files[0].modified = data.root.modified;
    files[0].modifiedOn = data.root.modifiedOn;
    files[0].fsRootID = data.root.fsRootID;
    files[0].contentID = data.root.contentID;
    files[0].barCode = data.root.barCode;
    files[0].memfisWoID = data.root.memfisWoID;
    const fixDirectoryData = (children, files) => {
      children.forEach(item => {
        if (item.isDirectory && item.children.length) {
          const fileInfo = files.find(f => f.absolutePath === item.key.replace(/[\\/]+$/, '').replace(/[\\/]/g, '\\'));
          if (fileInfo) {
            item.size = fileInfo.size;
            item.modified = fileInfo.modified;
            item.modifiedOn = fileInfo.modifiedOn;
            item.fsRootID = fileInfo.fsRootID;
            item.contentID = fileInfo.contentID;
            item.barCode = fileInfo.barCode;
            item.memfisWoID = fileInfo.memfisWoID;
          }
          fixDirectoryData(item.children, files);
        }
      });
    };
    fixDirectoryData(files[0].children, data.files);
  }

  return files;
}
