import {Component} from 'react';

class BaseFolder extends Component {
  state = {};

  getName = () => {
    const {name} = this.props;
    if (name) {
      return name;
    }
    const folders = this.props.fileKey.split('/');
    return folders[folders.length - 2];
  };

  handleFolderClick = event => {
    event.stopPropagation();
    this.toggleFolder();
    const {onFolderClick, fileKey, browserProps, isOpen, info} = this.props;
    if (onFolderClick && !isOpen) {
      onFolderClick(fileKey, info && info.contentID);
    }
    browserProps.select(fileKey);
  };

  handleFolderDoubleClick = event => {
    event.stopPropagation();
    this.toggleFolder();
  };

  toggleFolder = () => {
    this.props.browserProps.toggleFolder(this.props.fileKey);
  };

  connectDND = render => {
    return render;
  };
}

export default BaseFolder;
