import React from 'react';
import PropTypes from 'prop-types';

export const SenderNameCell = ({value, className}) => {
  return (
    <td className={className} title={value}>
      {value}
    </td>
  )
};

SenderNameCell.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string,
};
