import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {isDeletedStatus, isRootItem,getValueForOS} from '../../../utils';
import TextEllipsisAdvanced from '../../../../../components/TextEllipsis/TextEllipsisAdvanced';

class FullPathCell extends Component {
  render() {
    const {row, className, onClick} = this.props;
    const value = getValueForOS(row, 'fullPath');
    const preparedPath = (
      value && !isRootItem(row) ?
        <TextEllipsisAdvanced
          data={value}
          tooltipLeft={6}
          noCopyCtrl
          nonMonospaceFont
        /> : value
    );
    return (
      <StyledCell className={`${className || ''}${isDeletedStatus(row) ? ' deleted' : ''}${onClick ? ' is-clickable' : ''}`}>
        {
          !onClick ? preparedPath :
            <a
              href={`/storage?root_id=${row.fsRootID}${(row.parentID || 0) > 0 ? `&content_id=${row.parentID}` : ''}`}
              onClick={event => {
                if (event.ctrlKey) {
                  event.stopPropagation();
                } else {
                  event.preventDefault();
                  event.stopPropagation();
                  onClick(row);
                }
              }}
            >
              {preparedPath}
            </a>
        }
      </StyledCell>
    );
  }
}

const StyledCell = styled.td`
  position: relative;
  &.deleted {
    color: red !important;
    text-decoration: line-through;
  }
  a {
    cursor: default;
    .ellipse--value {
      cursor: pointer !important;
    }
  }
`;

FullPathCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default FullPathCell;