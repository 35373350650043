import React from 'react';
import styled from 'styled-components';
import {getStatusAndColor, parseStaleOrFailedStatus} from '../../../utils';
import {Link} from 'react-router-dom';
import {getActionTypeIcon} from '../infopanel/ActionsTree';

export const StatusCell = ({value, row}) => {
    const {exclamationColor, Priority, mainType, actionType} = row;
    const isWarningExclamation = exclamationColor === 'yellow';
    const statusAndColor = getStatusAndColor(row);
    const actionTypeIcon = getActionTypeIcon(mainType, actionType).iconComponent;
    return (
      <StyledCell>
        <div>
          {actionTypeIcon || <i className="am--ActionTypeIcon"/>}
          <div className="statuses-container">
            {
              statusAndColor.map((item, index) => {
                const temp = parseStaleOrFailedStatus(item.status);
                const actionId = temp && temp.length > 2 ? temp[2] : null;
                const actionIdLink = actionId ?
                  <Link
                    to={`/storage?action_id=${actionId}`}
                    target="_blank"
                  >
                    {actionId}
                  </Link> : null;
                return (
                  <div
                    key={`s-${index}-${Math.random()}`}
                    className={`status${['yellow', 'red'].includes(item.color) ? ` bg-${item.color}` : ''}`}
                    style={item.color ? {backgroundColor: item.color} : undefined}
                    >
                    {actionId ? item.status.replace(/:\s*(\d+)/i, ': ') : item.status}{actionIdLink}{item.extra}
                  </div>
                );
              })
            }
          </div>
          {
            !exclamationColor ? null :
              <i
                className={`fas fa-exclamation-${isWarningExclamation ? 'triangle' : 'circle'}`}
                style={{color: isWarningExclamation ? '#ffe000' : exclamationColor}}
                title={`Parallel asset link has ${isWarningExclamation ? 'warning' : 'error'} status`}
              />
          }
          {
            typeof Priority === 'number' && Priority > 0 &&
              <span
                className="download-priority"
                title="Priority"
              >
                {Priority}
              </span>
          }
        </div>
      </StyledCell>
    );
};

const StyledCell = styled.td`
  > div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .statuses-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  i[class*="fa-exclamation"] {
    text-shadow: 0 0 0 #282828;
    font-size: 17px;
  }

  .download-priority {
    margin-top: -2px;
    margin-left: 3px;
    padding: 2px;
    border: 1px solid green;
    border-radius: 3px;
    color: green;
    font-size: 1.4rem;
    line-height: 1;
  }

  .status {
    background-color: #343a40;
    border: 1px solid transparent;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    margin: 0 3px 3px 0;
    padding: 0 4px;
    width: fit-content;
    &.bg-yellow {
      color: #8a803c;
    }
    &.bg-red {
      a {
        color: #abceec;
        &:hover {
          color: #65b1f3;
        }
      }
    }
  }

  .am--ActionTypeIcon {
    &.am--icon--img {
      margin-left: 4px;
      margin-right: 7px;
      width: 12px;
    }
    &:not(.am--icon--img) {
      margin-left: 0;
      margin-right: 5px;
      width: 18px;
      &.fa-link-horizontal {
        margin-top: 3px;
        margin-left: 2px;
        margin-right: 3px;
      }
    }
  }
`;
