import config from '../../../config';

export const FLAG_FINAL_MOVE_WITHOUT_WO = 32;
export const FLAG_READY_FOR_MULTIPLE_WO = 64;

export const FILTERS_PRESET_ID = 'presetId';

export const FILTER_STATUS = 'status';
export const FILTER_SEARCH = 'search';
export const FILTER_OFFICE_FLAG = 'office_flag';
export const FILTER_CLIENT = 'client';
export const FILTER_HIDE_COMPLETED = 'hide_completed';
export const FILTER_HIDE_NAM_ASSETS = 'hide_nam_assets';
export const FILTER_DAYS_BACK_FROM = 'days_back_from';
export const FILTER_WO_MEMFIS = 'wo_memfis';
export const FILTER_DOWNLOAD_TYPE = 'download_type';
export const FILTER_DOWNLOAD_TYPE_AUTO = 1;
export const FILTER_DOWNLOAD_TYPE_UPLOAD = 3;

export const getDefaultsOfFilters = () => {
  return {
    [FILTER_STATUS]: [],
    [FILTER_HIDE_COMPLETED]: false,
    [FILTER_HIDE_NAM_ASSETS]: false,
    [FILTER_SEARCH]: '',
    [FILTER_CLIENT]: '',
    [FILTER_WO_MEMFIS]: '',
    [FILTER_OFFICE_FLAG]: 0,
    [FILTER_DOWNLOAD_TYPE]: [FILTER_DOWNLOAD_TYPE_AUTO],
    [FILTER_DAYS_BACK_FROM]: {
      daysBack: 3,
      fromDate: null
    }
  };
};

export const OFFICE_FLAG_NEW_YORK = config.officeFlag.NEW_YORK;

export const ALL_FILTERS =
[{
  name: FILTER_OFFICE_FLAG,
  title: 'Destination',
  type: 'select',
  values: [{
    name: 'All',
    value: 0
  }, {
    name: 'Burbank',
    value: config.officeFlag.BURBANK
  }, {
    name: 'New York',
    value: OFFICE_FLAG_NEW_YORK
  }],
  multi: false
}, {
  name: FILTER_DOWNLOAD_TYPE,
  title: 'Download Type',
  type: 'select',
  values: [{
    name: 'Auto',
    value: FILTER_DOWNLOAD_TYPE_AUTO
  }, {
    name: 'Manual',
    value: 2
  }, {
    name: 'Upload',
    value: FILTER_DOWNLOAD_TYPE_UPLOAD
  }]
}, {
  name: FILTER_WO_MEMFIS,
  title: 'WO#',
  type: 'string'
}, {
  name: FILTER_DAYS_BACK_FROM,
  title: 'Updated On',
  type: 'custom',
  fieldTypes: {
    daysBack: 'number',
    fromDate: 'date'
  }
}, {
  isLocalFilter: true,
  name: FILTER_SEARCH,
  title: 'Search',
  type: 'string'
}, {
  isLocalFilter: true,
  name: FILTER_CLIENT,
  title: 'Client',
  type: 'string'
}, {
  isLocalFilter: true,
  name: FILTER_HIDE_COMPLETED,
  title: 'Hide Completed',
  type: 'bool'
}, {
  isLocalFilter: true,
  name: FILTER_HIDE_NAM_ASSETS,
  title: 'Hide NAM',
  type: 'bool'
/*}, {
  name: FILTER_STATUS,
  title: 'Status',
  type: 'select',
  values: []*/
}];


export const STATUS_KEY_DOWNLOADING = 'DOWNLOADING';
export const DownloadStatus = {
  UNLINK_IN_PROGRESS: ["UNLINK IN PROGRESS"],
  UNKNOWN_ERROR: ["UNKNOWN ERROR"],
  DOWNLOADING_FAILED: ["DOWNLOADING FAILED", "D/L TOKEN EXPIRED", "FAILED"],
  CANCELLED: ["CANCELLED"],
  REQUEST_REQUIRED: ["ASSET REQUIRED"],
  REQUEST_REQUESTED: ["ASSET REQUESTED"],
  NOT_STARTED: ["NOT STARTED", "PENDING"],
  [STATUS_KEY_DOWNLOADING]: ["DOWNLOADING"],
  PENDING_MOVE: ["PENDING MOVE"],
  DOWNLOAD_FINISHED: ["COMPLETED"],
  READY_TO_MOVE: ["READY TO MOVE", "PENDING FINAL MOVE"],
  BARCODING_AND_MOVE_STARTED: ["BARCODING+MOVE STARTED"],
  BARCODED_AND_MOVED: ["BARCODED AND MOVED"],
  MOVED: ["MOVED & MARKED D/L-ED", "MANUALLY D/L-ED"],
};

const OrderedStatusKeys = [
  'UNLINK_IN_PROGRESS',
  'UNKNOWN_ERROR',
  'DOWNLOADING_FAILED',
  'CANCELLED',
  'REQUEST_REQUIRED',
  'REQUEST_REQUESTED',
  'NOT_STARTED',
  STATUS_KEY_DOWNLOADING,
  'PENDING_MOVE',
  'DOWNLOAD_FINISHED',
  'READY_TO_MOVE',
  'BARCODING_AND_MOVE_STARTED',
  'BARCODED_AND_MOVED',
  'MOVED'
];

const CompletedStatuses = [
  'INGEST COMPLETED',
  ...DownloadStatus.DOWNLOAD_FINISHED,
  ...DownloadStatus.MOVED,
  'QC IN PROGRESS',
  'QC COMPLETE',
  'DONE'
];

export const isCompletedDownloadStatus = status => CompletedStatuses.includes(status);

export const isUnlinkInProgressStatus = status => DownloadStatus.UNLINK_IN_PROGRESS.includes((status || '').toUpperCase());

export const isPartiallyCompletedStatus = status => /^PARTIALLY(_| |)COMPLETED$/i.test((status || '').trim());

export const getDownloadStatusOrderNum = status => {
  const statusKey = Object.keys(DownloadStatus).find(k => DownloadStatus[k].includes(status));
  return OrderedStatusKeys.indexOf(statusKey) + 1;
};
