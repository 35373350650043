import $ from 'jquery';
import config from '../../../config';
import {USE_MAX_MODIFIED_ON_FILTER_NAME} from '../StorageManager/constants';
import {showError} from '../../layouts/actions';
import {getErrorMessage} from '../../utils';

export const ASSET_DELETION_IS_LOADING = 'ASSET_DELETION_IS_LOADING';
export const ASSET_DELETION_RECEIVE = 'ASSET_DELETION_RECEIVE';

export const BROWSER_IS_LOADING_FILES = 'BROWSER_IS_LOADING_FILES';

export const PATH_BROWSER_FETCHED_FILES = 'PATH_BROWSER_FETCHED_FILES';
export const PATH_BROWSER_INCREMENTAL_FETCHED_FILES = 'PATH_BROWSER_INCREMENTAL_FETCHED_FILES';

export const getAssets = assetDeletionId => dispatch => {
  dispatch({type: ASSET_DELETION_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/assets/delete/${assetDeletionId}`
  })
  .done(assets => {
    dispatch({type: ASSET_DELETION_RECEIVE, assets: assets.filter(item => Boolean(item.contentID))});
  })
  .fail(() => {
    dispatch({type: ASSET_DELETION_IS_LOADING, loading: false});
  });
};

export const approveDeletion = (actionId, data) => dispatch => {
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/assets/deletion_approving`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      ActionID: actionId,
      IsApproved: true,
      ContentIDs: data
    })
  })
  .done(() => {})
  .fail(error => {
    dispatch(showError(`Could not approve deletion.  ${getErrorMessage(error)}`));
  });
};

export const rejectDeletion = actionId => dispatch => {
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/assets/deletion_approving`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      ActionID: actionId,
      IsApproved: false
    })
  })
  .done(() => {})
  .fail(error => {
    dispatch(showError(`Could not reject deletion.  ${getErrorMessage(error)}`));
  });
};

export const getFiles = (rootKey, root, path = '/', contentId, incremental) => dispatch => {
  dispatch({type: BROWSER_IS_LOADING_FILES, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/browse`,
    data: {
      root,
      path,
      content_id: (contentId > 0 ? contentId : undefined),
      [USE_MAX_MODIFIED_ON_FILTER_NAME]: true
    }
  })
  .done(res => {
    const files = res.data || [];
    files.forEach(i => {
      i.absolutePath = i.key;
    });
    dispatch({
      type: (incremental ? PATH_BROWSER_INCREMENTAL_FETCHED_FILES : PATH_BROWSER_FETCHED_FILES),
      root: rootKey,
      path,
      files,
      contentChildren: {contentId, children: files.map(item => (item.contentID))}
    });
  })
  .fail(() => {
    dispatch({type: BROWSER_IS_LOADING_FILES, loading: false});
  });
};
