import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';

export const InstantScanCell = ({row, className, onClick}) => (
  <td className={className}>
    {row.IsDirectory === 'true' &&
      <Button
        bsStyle="default"
        bsSize="xsmall"
        title="Instant scan"
        onClick={() => {onClick(row);}}
      >
        <i className="fas fa-history"/>
      </Button>
    }
  </td>
);

InstantScanCell.propTypes = {
  row: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
