/* eslint "jsx-a11y/anchor-is-valid":"off" */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';
import styled from 'styled-components';
import searchImg from '../../../assets/images/search.svg';
import InputAdvanced from './InputAdvanced';
import searchImg2 from '../../../assets/images/search2.svg';

class HeaderRow extends Component {
  constructor(props, context) {
    super(props, context);

    this.resetValues = false;

    this.state = {
      showSearchInput: false,
      searchTitle: props.searchTitle
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.searchTitle !== null && nextProps.searchTitle !== this.state.searchTitle) {
      this.setState({
        searchTitle: nextProps.searchTitle
      })
    }
  }

  handleBlurSearch = () => {
    this.resetValues = true;
    const {searchTitle} = this.props;
    this.setState({searchTitle, showSearchInput: false});
    this.forceUpdate();
  };

  handleChangeSearch = (_, searchTitle) => {
    this.resetValues = false;
    this.setState({searchTitle});
  };

  handleClickSearchBtn = () => {
    const {searchTitle} = this.state;
    this.setState({showSearchInput: true});
    if (!searchTitle) {
      setTimeout(() => {
        try {
          this._input._input.focus();
        } catch(ignored) {/**/}
      }, 300);
    }
  };

  handleKeyDownSearch = (keyCode, input) => {
    if (keyCode === 13) {
      const {searchTitle} = this.state;
      if (searchTitle !== this.props.searchTitle) {
        this.props.onSearch(searchTitle, () => input.blur());
      } else {
        input.blur();
      }
    } else if (keyCode === 27) {
      input.blur();
    }
  };

  handleResetSearch = () => {
    this.resetValues = true;
    const searchTitle = '';
    this.setState({searchTitle});
    this.props.onSearch(searchTitle, () => {
      try {
        this._input._input.focus();
      } catch(ignored) {/**/}
    });
  };

  render() {
    const {searchTitle} = this.state;
    const {total} = this.props;
    return (
      <StyledRow>
        <Col xs={8} className="total">
          Items shown:&nbsp;{total}
        </Col>
        <Col xs={4} className={`search${this.state.showSearchInput ? ' active' : ''}`}>
          <form action="#" onSubmit={event => {event.preventDefault();}}>
            <div className="search-btn" onClick={this.handleClickSearchBtn}/>
            <div className="search-wrap" title="Parts of words or separate words can be replaced with spaces">
              <img className="img-search" src={searchImg} alt=""/>
              <InputAdvanced
                inputClassName={`search-title-input${searchTitle ? '' : ' empty'}`}
                onBlur={this.handleBlurSearch}
                onChange={this.handleChangeSearch}
                onClear={this.handleResetSearch}
                onKeyDown={this.handleKeyDownSearch}
                placeholder="Search"
                ref={r => this._input = r}
                reset={this.resetValues}
                type="search"
                value={searchTitle}
              />
            </div>
          </form>
        </Col>
      </StyledRow>
    );
  }
}

const StyledRow = styled(Row)`
  background: #ededed;
  min-height: 64px;
  
  .total {
    color: #454545;
    font-size: 15px;
    line-height: 64px;
    padding-left: 65px;
  }
  
  .search {
    display: flex;
    justify-content: flex-end;
    .menu {
      display: flex;
      padding: 22px 0 0 0;
      justify-content: flex-end;
      width: 100%;
      li {
        list-style: none;
        margin: 0 10px;
        &:hover a span {
          max-width: 1000px;
          transition: all 1.5s ease-in-out;
        }
      }
      a {
        color: #282828;
        display: flex;
        font-size: 10px;
        line-height: 23px;
        white-space: nowrap;
        span {
          max-width: 0;
          overflow: hidden;
          transition: all 0.1s ease-in-out;
        }
      }
      .icon {
        background-repeat: no-repeat;
        display: block;
        margin: 0 3px;
        height: 21px;
        width: 21px;
      }
      .refresh-icon {
        display: inline-block;
        color: #999;
        max-width: 100%;
        margin: 0 3px;

        svg {
          max-height: 100%;
          max-width: 100%;
        }
      }
    }
    .search-wrap {
      margin: 15px 10px 15px auto;
      position: relative;
      width: fit-content;
      .form-group {
        margin-bottom: 0;
      }
      img {
        position: absolute;
        top: 8px;
        left: 5px;
      }
      input {
        border: 0;
        color: #282828;
        font-size: 13px;
        padding-left: 33px;
        padding-right: 25px;
        /*width: 253px;*/
        &.empty {
          + i {
            display: none !important;
          }
        }
      }
      i {
        position: absolute;
        top: 10px;
        right: 6px;
        font-size: 14px;
        color: #555;
        cursor: pointer;
        &:hover {
          color: #222;
        }
      }
    }
  }

  @media (max-width: 991px) {
    background: #fff;
    min-height: 55px;
    border-radius: 3px 3px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .total {
      color: #282828;
      line-height: 17px;
      padding-left: 15px;
      width: auto;
      float: none;
    }

    .search {
      width: auto;
      float: none;
      flex-grow: 1;
      align-items: center;

      .search-btn {
        background: url(${searchImg2}) no-repeat;
        background-position: 0 2px;
        height: 21px;
        width: 21px;
      }

      .menu {
        margin: 0 23px 0 0;
        padding: 4px 0 3px;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        li:last-of-type {
          margin-right: 19px;
        }
      }

      .search-wrap {
        margin: -2px 0 0;
        max-width: 0;
        input {
          width: 100%;
          &:focus {
            + i {
              display: none !important;
            }
          }
        }
        * {
          display: none;
        }
      }

      &.active {

        .search-btn {
          display: none;
        }

        .menu {
          width: auto;
          @media (max-width: 375px) {
            display: none;
          }
        }

        form {
          flex-grow: 1;
        }

        .search-wrap {
          max-width: 1000px;
          width: 100%;
          transition: all 1.5s ease-in-out;
          * {
            display: block;
            transition: all 1.5s ease-in-out;
          }
        }
      }
    }
  }
`;

HeaderRow.propTypes = {
  onSearch: PropTypes.func,
  searchTitle: PropTypes.string,
  total: PropTypes.number,
};

export default HeaderRow;