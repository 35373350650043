import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import user from './pages/Login/reducer';
import storageManager from './pages/StorageManager/reducer';
import downloadManager from './pages/DownloadManager/reducer';
import asperaClients from './pages/AsperaClients/reducer';
import deliveryManager from './pages/DeliveryManager/reducer';
import notifications from './pages/Notifications/reducer';
import assetDeletion from './pages/AssetDeletion/reducer';
import issueBrowser from './pages/IssueBrowser/reducer';
import explorerComponent from './components/Explorer/reducer'
import WOChooserComponent from './components/WOChooser/reducer';
import main from './layouts/reducer';

const rootReducer = combineReducers({
  routing: routerReducer,
  main,
  user,
  storageManager,
  downloadManager,
  asperaClients,
  issueBrowser,
  deliveryManager,
  notifications,
  assetDeletion,
  explorerComponent,
  WOChooserComponent,
});

export default rootReducer;
