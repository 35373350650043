import React, {Component, Fragment} from 'react';
import {FilterHeaderStyled, FiltersStyled, FilterBodyStyled, FilterStyled} from "./FiltersStyled";
import {Col, Row, FormControl, Button} from "react-bootstrap";
import _ from "lodash";
import PropTypes from "prop-types";
import {FilterBottomStyled, FilterSelectStyled} from "../../../../components/filters/FiltersStyled";
import Select from "react-select";
import DateField from "../../../../components/filters/DateField";

const OPTION_ALL = {value: 'All', label: 'All'};

class Filters extends Component {
  state = {
    senderValue: null,
    titleValue: null,
    completedValueTo: null,
    completedValueFrom: null,
    createdOnValueTo: null,
    createdOnValueFrom: null,
    titleList: [OPTION_ALL]
  };

  initData = () => {
    const {sortedPackages} = this.props;

    if (this.state.titleList.length <= 1 && sortedPackages) {
      const options =
        _.sortBy(
          _.uniqBy(
            sortedPackages.filter(i => i.title).map(i => ({value: i.packageID, label: i.title})),
            'value'),
          ['value']);
      this.setState({titleList: [OPTION_ALL, ...options]});
    }
  }

  componentDidMount() {
    this.initData();
  }

  onSenderFieldChange = (value) => {
    this.setState({senderValue: value});
  }

  getSenderField() {
    const {senderValue,} = this.state;

    return (
      <FormControl
        id={"sender"}
        value={senderValue || ''}
        onChange={event => this.onSenderFieldChange(event.target.value)}
        placeholder={"Enter sender"}
      />
    );
  }

  onTitleFieldChange = (value) => {
    let all = [];
    if (value && value.length) {
      all = value.filter(item => item.value === 'All');
    }
    if (all.length === 1) {
      this.setState({titleValue: all});
    } else {
      this.setState({titleValue: value});
    }
  }

  getSelectField() {
    const {titleValue, titleList} = this.state;
    return (
      <FilterSelectStyled>
        <Select
          multi={true}
          value={titleValue}
          options={titleList}
          onChange={v => this.onTitleFieldChange(v)}
        />
      </FilterSelectStyled>
    );
  }

  getCompletedDateField() {
    const {completedValueTo, completedValueFrom} = this.state;
    return this.getDateField("completedValueFrom", "completedValueTo", completedValueFrom, completedValueTo);
  }

  getCreatedOnDateField() {
    const {createdOnValueTo, createdOnValueFrom} = this.state;
    return this.getDateField("createdOnValueFrom", "createdOnValueTo", createdOnValueFrom,  createdOnValueTo);
  }

  onDateChange = (name, date) => {
    this.setState({[name]: date});
  }

  prepareDate = value => {
    const date = new Date(value);
    const res = date.getTime() ? date : null;
    return res || null;
  };

  getDateField(toName, fromName, start, end) {
    const startDate = this.prepareDate(start);
    const endDate = this.prepareDate(end);
    return (
      <Row className="date-group">
        <Col md={6} xs={6}>
          <DateField
            id={toName}
            placeholderText="From"
            selected={startDate}
            selectsStart
            maxDate={endDate}
            startDate={startDate}
            endDate={endDate}
            openToDate={startDate || new Date()}
            onChange={date => {
              this.onDateChange(toName, date);
            }}
          />
        </Col>
        <Col md={6} xs={6}>
          <DateField
            id={fromName}
            placeholderText="To"
            selected={endDate}
            selectsEnd
            minDate={startDate}
            startDate={startDate}
            endDate={endDate}
            openToDate={endDate || new Date()}
            onChange={date => {
              this.onDateChange(fromName, date);
            }}
          />
        </Col>
      </Row>
    );
  }

  applyFilters = () => {
    const {onApplyFilters} = this.props;
    onApplyFilters(
      {
        senderValue: this.state.senderValue,
        titleValue: this.state.titleValue,
        completedValueTo: this.state.completedValueTo,
        completedValueFrom: this.state.completedValueFrom,
        createdOnValueTo: this.state.createdOnValueTo,
        createdOnValueFrom: this.state.createdOnValueFrom,
      }
    );
  }

  resetFilters = () => {
    const {onResetFilters} = this.props;
    this.setState(
      {
        senderValue: null,
        titleValue: null,
        completedValueTo: null,
        completedValueFrom: null,
        createdOnValueTo: null,
        createdOnValueFrom: null,
      }
    );
    onResetFilters();
  }

  render() {
    return (
      <div id="actions-view">
        <FiltersStyled>
          <FilterHeaderStyled
            aria-controls="filters"
          >
            <div className="container">
              <span className="title pull-left">Filter</span>
            </div>
          </FilterHeaderStyled>
          <div id="filters" className="clearfix container">
            <FilterBodyStyled>
              <Col md={12} xs={12}>
                <div className="block">
                  <Fragment>
                    <div className="filters-block">

                      <FilterStyled>
                        <Row>
                          <Col md={4} xs={12}>
                            <FormControl
                              value={"Sender"}
                              disabled={true}
                              className="filter-field-label"
                            />
                          </Col>
                          <Col md={8} xs={12}>{this.getSenderField()}</Col>
                        </Row>
                      </FilterStyled>

                      <FilterStyled>
                        <Row>
                          <Col md={4} xs={12}>
                            <FormControl
                              value={"Title"}
                              disabled={true}
                              className="filter-field-label"
                            />
                          </Col>
                          <Col md={8} xs={12}>{this.getSelectField()}</Col>
                        </Row>
                      </FilterStyled>

                      <FilterStyled>
                        <Row>
                          <Col md={4} xs={12}>
                            <FormControl
                              value={"Completed"}
                              disabled={true}
                              className="filter-field-label"
                            />
                          </Col>
                          <Col md={8} xs={12}>{this.getCompletedDateField()}</Col>
                        </Row>
                      </FilterStyled>

                      <FilterStyled>
                        <Row>
                          <Col md={4} xs={12}>
                            <FormControl
                              value={"Created On"}
                              disabled={true}
                              className="filter-field-label"
                            />
                          </Col>
                          <Col md={8} xs={12}>{this.getCreatedOnDateField()}</Col>
                        </Row>
                      </FilterStyled>

                    </div>
                  </Fragment>
                </div>
              </Col>
            </FilterBodyStyled>
            <FilterBottomStyled>
              <div className="filters-btn-toolbar">
                <Button
                  bsStyle={'primary'}
                  className={undefined}
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.applyFilters();
                  }}
                >
                  Apply
                </Button>
                <Button
                  bsStyle={'default'}
                  onClick={this.resetFilters}>
                    Reset
                </Button>
              </div>
            </FilterBottomStyled>
          </div>
        </ FiltersStyled>
      </div>
    );
  }
}

Filters.propTypes = {
  sortedPackages: PropTypes.array.isRequired,
}

export default Filters;
