export default {
  versionNumber: window.CONFIG.VERSION_NUMBER,
  portalBaseUrl: window.CONFIG.PORTAL_BASE_URL,
  apiUrl: window.CONFIG.API_URL,
  alertTimeout: 10000,
  instantScanStatusFrequencyInSeconds: 5,
  officeFlag: {
    BURBANK: 1,
    NEW_YORK: 2
  }
};
