import React from 'react';
import PropTypes from 'prop-types';

export const FileTypeCell = ({value, className}) => {
  return (
    <td className={className}>
      {value ? `.${value}` : ''}
    </td>
  );
};

FileTypeCell.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
};
