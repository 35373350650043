import styled from 'styled-components';
import {FormGroup, Row} from "react-bootstrap";
import SelectStyled from "../../../../components/filters/SelectStyled";

export const FiltersStyled = styled.div`
  background-color: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;

  .clearfix {
    clear: both;
  }
`;

export const FilterSelectStyled = styled(SelectStyled)`
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

  &.fs-project-type-select {
    width: 100%;
  }
  &.fs-file-type-select {
    width: 50%;
  }

  .Select.is-searchable.Select--single .Select-control,
  .Select.is-searchable.Select--single .Select-control {
    border: 1px solid #E9E9E9;
    box-shadow: none;
    background: #fff;
    .Select-placeholder,
    .Select-value {
      color: #aaa !important;
      font-size: 10px !important;
      .Select-value-label {
        font-size: 10px !important;
      }
    }
  }
  .Select--multi .Select-control {
    border: 1px solid #E9E9E9;
    box-shadow: none;
    .Select-multi-value-wrapper {
      height: auto;
    }
    .Select-value {
      padding: 0 !important;
    }
    .Select-input {
      height: 24px;
      input {
        font-size: 10px;
        padding: 0;
      }
    }
  }

  &.filter-select-with-button {
    box-shadow: none;
    display: flex;
    justify-content: space-between;
    > .Select {
      flex-grow: 1;
    }
    > .filter-select-button {
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      i:hover {
        cursor: pointer;
      }
    }
    &.unsaved-right {
      position: relative;
      &:before {
        content: 'Unsaved changes';
        position: absolute;
        top: -12px;
        right: 45px;
        font-size: 9px;
        font-weight: bold;
        opacity: .8;
        color: #282828;
      }
    }
  }
`;

export const FilterHeaderStyled = styled.div`
  background: #575757;
  color: #FFFFFF;
  height: 44px;
  padding: 0 20px;
  cursor: pointer;
  .title {
    font-size: 13px;
    font-weight: bold;
    padding: 13px 15px;
    + .btn {
      margin-top: 8px;
      margin-left: 15px;
      padding: 6px 12px;
      font-size: 13px;
      line-height: 14px;
      outline: none !important;
      opacity: 0;
      &.is-visible {
        opacity: 1;
        transition: opacity 0.35s ease-in-out 0s;
      }
    }
  }
  i {
    font-size: 13px;
    font-weight: bold;
    padding: 13px 0;
    transition: transform 0.2s ease 0s;
    will-change: transform;
  }
  &[aria-expanded="false"] i {
    transform: rotate(-90deg);
  }
  .menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0 10px;
      padding: 10px 0;
      &:hover:not(.report-option) .btn-link span {
        max-width: 1000px;
        transition: all 1.5s ease-in-out;
      }
      &:not(.report-option) span {
        max-width: 0;
        overflow: hidden;
        transition: all 0.1s ease-in-out;
      }
      &.report-option {
        background-color: #464646;
        padding-right: 15px;
        padding-left: 15px;
      }
    }

    .btn-link {
      border: none;
      color: #ffffff;
      display: flex;
      height: 22px;
      line-height: 23px;
      white-space: nowrap;
      &:hover, &:focus {
        text-decoration: none;
        outline: none;
      }
      span {
        color: #FFFFFF;
        font-size: 10px;
        opacity: 0.5;
      }
    }
  }
`;

export const FilterBodyStyled = styled(Row)`
  .block {
    padding: 20px;
    &.block-top {
      padding-bottom: 0;
    }
  }
  .title {
    align-items: center;
    color: #282828;
    display: flex;
    font-size: 10px;
  }
  .filters-block {
    margin: 0;

    [class*="col-"] {
      padding: 0 3px;
    }

    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;

      >.form-group {
        width: 48%;

        &:nth-of-type(even) {
          margin-left: 2%;
        }
        &:nth-of-type(odd) {
          margin-right: 2%;
        }
      }
    }

    input#action_id {
      &::-webkit-inner-spin-button, &::-webkit-outer-spin-button, &::-webkit-clear-button {
        display: none;
      }
    }

    .Select {
      .Select-menu-outer {
        z-index: 3;
        max-height: 122px;
        .Select-menu {
          max-height: 120px;
        }
      }
    }
    
    .react-datepicker-popper {
      z-index: 3;
    }
  }
`;

export const FilterBottomStyled = styled.div`
  padding: 0 40px;
  > div {
    border-top: 1px solid #f0f0f0;
    padding: 20px 0;
  }
  .btn {
    font-size: 13px;
    line-height: 14px;
    outline: none !important;
    & + .btn {
      margin-left: 5px;
    }
  }
`;

export const FilterStyled = styled(FormGroup)`
  margin-bottom: 6px;
  .control-label {
    font-size: 10px;
    margin-right: 10px;
  }
  .form-control {
    border: 1px solid #E9E9E9;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    font-size: 10px;
    font-weight: bold;
    height: 27px;
    &[type="checkbox"] {
      height: auto;
      width: auto;
      cursor: pointer;
    }
    &.filter-field-label {
      cursor: default !important;
      font-weight: bold;
      padding-left: 9px;
      &.presets-field-label {
        background-color: #dedede;
        border-color: #d6d6d6;
        color: #333;
      }
    }
    i {
      cursor: pointer;
      margin-left: 7px;
    }
  }
  input.form-control {
    padding: 9px;
    font-weight: normal;
  }
  .date-group, .size-group {
    display: flex;
    justify-content: space-between;
    [class*="col-"] {
      padding: 0 15px;
    }
    &.with-units {
      [class*="col-"] {
        padding: 0 0 0 5px;
      }
      .col-md-4, .col-xs-4 {
        padding-left: 15px;
      }
      .col-md-2, .col-xs-2 {
        padding-right: 15px;
      }
    }
  }
`;
