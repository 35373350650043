import styled from "styled-components";

const CommentsCellStyled = styled.td`
  height: 100%;
  div {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
`;

export default CommentsCellStyled;