import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, FormGroup, Radio, FormControl } from 'react-bootstrap';
import styled from 'styled-components';
import { closeCopyModal, copyFsItem } from '../../actions';
import { PathFieldStyled, CheckboxStyled } from './ModalStyled';
import FolderExplorerNew from '../forms/FolderExplorerNew';
import { isValidFileName } from '../../../../utils';
import { showWarning, hideAllNotifications, showError } from '../../../../layouts/actions';
import { MAX_FILENAME_LENGTH_TO_UPLOAD, MAX_PATH_LENGTH, getValueForOS, preparePathForOsWithoutRoot } from "../../utils";
import TextEllipsisAdvanced from '../../../../components/TextEllipsis/TextEllipsisAdvanced';
import Wizard from '../../../../components/Wizard/Wizard';
import SchedulerForm from "../../../../components/SchedulerForm/SchedulerForm";

const Steps = {
  FIRST: 'Start',
  BROWSE_FOR_DESTINATION: 'Browse for destination',
  RENAME: 'Rename',
  CONFIRM: 'Confirm'
};

const ProxyCopyRadioValue = {
  COPY_FILE: 'copy-file',
  CREATE_QOD_PROXY: 'create-qod-proxy'
};

class ContentEditable extends Component {
  state = {
    initValue: this.props.value,
    showInvalidPassword: false,
    encPassword: '',
    encState: false
  };

  render() {
    const { className, onInput, onBlur, onFocus } = this.props;
    const { initValue } = this.state;    
    return (
      <span
        contentEditable={true}
        suppressContentEditableWarning={true}
        className={className}
        title="Click to Edit"
        onKeyDown={event => event.keyCode === 13 && event.preventDefault()}
        onInput={onInput}
        onBlur={onBlur}
        onFocus={onFocus}
      >{initValue}</span>
    );
  }
}

class CopyModal extends Component {
  getStartValueOfProxyCopySelected = () => {
    return false;//this.props.copyData.proxyCopyAvailable && this.props.copyData.proxyCopyDefaultSelected;
  };

  getStartFileName = proxyCopySelected => {
    const { copyData, item } = this.props;
    return proxyCopySelected ? (copyData.newFileName || item.name) : item.name;
  };

  getEncPassword = () => {
    const { copyData } = this.props;
    return copyData.encDetails === undefined ? '' : copyData.encDetails.enc_password;
  }

  isEncryptionDataExists = () => {
    const { copyData } = this.props;
    return copyData.encDetails === undefined ? false : true;
  }

  state = {
    proxyCopySelected: this.getStartValueOfProxyCopySelected(),
    proxyCopyRadioValue: this.getStartValueOfProxyCopySelected() ? ProxyCopyRadioValue.CREATE_QOD_PROXY : ProxyCopyRadioValue.COPY_FILE,
    selectedFolder: null,
    newFileName: this.getStartFileName(this.getStartValueOfProxyCopySelected()),
    startFileName: this.getStartFileName(this.getStartValueOfProxyCopySelected()),
    isLoadedStartFolders: 0,
    isScrollToElmClass: null,
    isScheduledFor: null,
    dateScheduledFor: null,
    isScheduledPauseTime: null,
    scheduledPauseTime: null,
    encPassword: this.getEncPassword(),
    encState: this.isEncryptionDataExists(),
  };

  handleClose = () => {
    const { dispatch } = this.props;
    dispatch(closeCopyModal());
  };

  // componentWillUnmount() {
  //   this.handleClose();
  // }

  showWarningIfNeeded = (isProxyCopy) => {
    const { dispatch, copyData: { warnMsg } } = this.props;
    const proxyCopySelected = typeof isProxyCopy === 'boolean' ? isProxyCopy : this.state.proxyCopySelected;
    dispatch(hideAllNotifications());
    if (warnMsg && proxyCopySelected) {
      dispatch(showWarning(warnMsg, 'Warning', 5 * 60 * 1000));
    }
  };

  isInvalidName = () => {
    const { newFileName } = this.state;
    return !isValidFileName(newFileName);
  };

  handleChangeProxyCopySelected = ({ target: { value } }) => {
    const proxyCopySelected = value === ProxyCopyRadioValue.CREATE_QOD_PROXY;
    const startFileName = this.getStartFileName(proxyCopySelected);
    this.setState({
      proxyCopyRadioValue: value,
      proxyCopySelected,
      selectedFolder: null,
      newFileName: startFileName,
      startFileName
    });
    this.showWarningIfNeeded(proxyCopySelected);
  };

  isCopyWithRename = () => {
    const { newFileName, startFileName } = this.state;
    return newFileName !== startFileName;
  }

  loadStartFolders = () => {
    const { isLoadedStartFolders } = this.state;
    if (!isLoadedStartFolders) {
      this.setState({ isLoadedStartFolders: 1 });
      /*const {item, folders} = this.props;
      if (item.parentID && item.parentID > 0) {
        const segments = item.key.split(/[\\/]/).slice(1).slice(0, -1);
        const parentFolder = folders.find(i => i.fsRootID === item.fsRootID);
        if (parentFolder) {
          //this.handleClickOpenFolder(parentFolder, segments);
        }
      }*/
    }
  };

  componentDidMount() {
    this.showWarningIfNeeded();
    const { copyData: { proxyCopyAvailable } } = this.props;
    if (!proxyCopyAvailable) {
      this.loadStartFolders();
    }
  }

  allowStepBrowseForDestination = () => {
    const { copyData: { destination } } = this.props;
    const { proxyCopySelected } = this.state;
    return !destination || !proxyCopySelected;
  };

  getDestinationFsRootId = () => {
    const { copyData } = this.props;
    const { selectedFolder } = this.state;
    return this.allowStepBrowseForDestination() ? selectedFolder.fsRootID : copyData.destFsRootID;
  };

  getDestinationPath = folder => {
    const { copyData } = this.props;
    const { selectedFolder } = this.state;
    return this.allowStepBrowseForDestination() ? (folder || selectedFolder).key : copyData.destination;
  };

  buildNewPath = folder => {
    const { newFileName } = this.state;
    const destination = this.getDestinationPath(folder);
    return `${destination}\\${newFileName}`;
  };

  handleClickConfirmCopy = () => {
    const { dispatch } = this.props;
    const { proxyCopySelected, newFileName, dateScheduledFor, scheduledPauseTime, isDailyRepeat, selectedFolder,encState, encPassword } = this.state;
    
    let preparedScheduledDate = dateScheduledFor;
    if (preparedScheduledDate && preparedScheduledDate.getTime() < Date.now()) {
      preparedScheduledDate = new Date(Date.now() + 1000 * 1 * 60);
    }

    let preparedScheduledPauseTime = scheduledPauseTime;
    if (preparedScheduledPauseTime && preparedScheduledPauseTime.getTime() < Date.now()) {
      preparedScheduledPauseTime = new Date(Date.now() + 1000 * 1 * 60);
    }

    if (preparedScheduledDate && preparedScheduledPauseTime && preparedScheduledPauseTime.getTime() <= preparedScheduledDate.getTime()) {
      dispatch(showError(`Pause On value cannot be greater than Scheduled For`));
    } else {
      const schedulerData = {
        scheduledFor: preparedScheduledDate,
        scheduledPauseTime: preparedScheduledPauseTime,
        dailyRepeat: isDailyRepeat
      }
      /*let encCheckboxChecked = false;
      if (document.getElementById("checkbox-decrypt") != null) {
        encCheckboxChecked = document.getElementById("checkbox-decrypt").checked;
      }*/
      dispatch(copyFsItem(proxyCopySelected, this.getDestinationFsRootId(), this.getDestinationPath(), newFileName, false, schedulerData, selectedFolder.officeFlag, encState, encPassword));
    }
  };

  handleClickSelectDestination = elmClass => {
    this.setState({ isScrollToElmClass: elmClass });
  };

  handleBackToSelectDestFromRenameStep = () => {
    this.handleClickSelectDestination('selected');
  };

  handleGoToSelectDestStepFromFirstStep = () => {
    const { item } = this.props;
    //if (!item.isDirectory) {
    //  return this.handleBackToSelectDestFromRenameStep();
    //}
    this.handleClickSelectDestination('current');
  };

  handleGoToStep = () => {
    this.setState({ isScrollToElmClass: null });
  };

  renderSourceField(step) {
    const { item } = this.props;
    return step === Steps.BROWSE_FOR_DESTINATION ? null : (
      <PathField className="source">
        <label>Source:</label>
        <TextEllipsisAdvanced className="value" data={getValueForOS(item, 'key')} />
      </PathField>
    );
  }

  renderDestinationField(step) {
    const { copyData: { proxyCopyAvailable }, fsRoots } = this.props;
    const { selectedFolder, newFileName } = this.state;
    let field = null, color;
    switch (step) {
      case Steps.FIRST:
        if (!selectedFolder) {
          color = 'black';
        }
        field = this.allowStepBrowseForDestination() ? (
          <div>
            <div className="left">
              <TextEllipsisAdvanced
                className="value"
                data={getValueForOS((selectedFolder || {}), 'key')}
                defaultText="[No Folder Selected]"
              />
            </div>
            <Button
              bsStyle="primary"
              onClick={() => {
                this.loadStartFolders();
                this.wizard.setActiveStepKey(Steps.BROWSE_FOR_DESTINATION);
                this.handleGoToSelectDestStepFromFirstStep();
              }}
              title="Select Destination..."
            >
              <i className="far fa-folder-tree" />
            </Button>
          </div>
        ) : <TextEllipsisAdvanced className="value" data={preparePathForOsWithoutRoot(this.buildNewPath(), fsRoots)} />;
        break;
      case Steps.RENAME:
        const showBtn = this.allowStepBrowseForDestination();
        field = (
          <div>
            <div className={`left no-flex word-break-all ${showBtn ? '' : ' without-change-dest-btn'}`}>
              <span className="value no-word-break">{preparePathForOsWithoutRoot(`${this.getDestinationPath()}\\`, fsRoots)}</span>
              <ContentEditable
                className={`editable-filename${this.isCopyWithRename() ? '' : ' same-name'}${this.isInvalidName(newFileName) ? ' invalid' : ''}`}
                value={newFileName}
                onInput={event => this.setState({ newFileName: event.target.textContent })}
                onBlur={event => this.setState({ newFileName: event.target.textContent })}
              />
            </div>
            {showBtn &&
              <Button
                bsStyle="primary"
                onClick={() => {
                  this.wizard.setActiveStepKey(Steps.BROWSE_FOR_DESTINATION);
                  this.handleBackToSelectDestFromRenameStep();
                }}
                title="Change Destination..."
              >
                <i className="far fa-folder-tree" />
              </Button>
            }
          </div>
        );
        break;
      case Steps.CONFIRM:
        field = <TextEllipsisAdvanced className="value" data={preparePathForOsWithoutRoot(this.buildNewPath(), fsRoots)} />;
        break;
      default:
        break;
    }

    const isDestFolder = proxyCopyAvailable && step === Steps.BROWSE_FOR_DESTINATION;
    const label = `Destination${isDestFolder ? ' folder' : ''}`;
    return !!field && (
      <PathField className={`destination move-destination${isDestFolder ? ' dest-folder' : ''}`} color={color}>
        <label>{label}:</label>
        {field}
      </PathField>
    );
  }

  handleOnChange = () => {
    if ( document.getElementById("checkbox-decrypt") != null ) {
      this.setState({encState: document.getElementById("checkbox-decrypt").checked});
    } else {
      this.setState({encState: false});
    }    
  };

  renderDecryptField(step) {
    const { currentFolder } = this.props;
    const { showInvalidPassword, isVisiblePassword, encPassword, selectedFolder, encState } = this.state;
    const isInterOfficeCopy = currentFolder !== null && currentFolder !== undefined && selectedFolder !== null  && selectedFolder !== undefined && currentFolder.officeFlag !== selectedFolder.officeFlag;
    let color;
    return step === Steps.RENAME && isInterOfficeCopy ? (
      <CustomPathField className={`destination move-destination checkbox-align`} color={color}>
        <CustomPathFieldStyled color="black">
          <div className="checkbox-div">
            <CheckboxStyled>
              <FormControl
                id="checkbox-decrypt"
                name="checkbox-decrypt"
                type="checkbox"
                defaultChecked={encState}
                onChange={() => this.handleOnChange()}
              />
            </CheckboxStyled>
            <label className="decrypt-label">Decrypt with password</label>
          </div>

          <PasswordField className="password-div" validationState={showInvalidPassword ? 'error' : null}>
            <FormControl
              id="enc-password"
              name="enc-password"
              type={isVisiblePassword ? 'text' : 'password'}
              value={encPassword}
              autoComplete="off"
              onChange={event => this.setState({ encPassword: event.target.value || '' })}
            />
            <i
              className={`far fa-eye${isVisiblePassword ? '' : '-slash'}`}
              onClick={() => this.setState({ isVisiblePassword: !isVisiblePassword })}
            />
          </PasswordField>
        </CustomPathFieldStyled>
      </CustomPathField>
    ) : null;
  }

  renderRadioButtonsCopyToProxy(step) {
    const { copyData: { proxyCopyAvailable } } = this.props;
    const { proxyCopyRadioValue } = this.state;
    return step === Steps.FIRST && proxyCopyAvailable ? (
      <FormGroup>
        <Radio
          name="radio--proxy-copy"
          value={ProxyCopyRadioValue.COPY_FILE}
          checked={proxyCopyRadioValue === ProxyCopyRadioValue.COPY_FILE}
          onChange={this.handleChangeProxyCopySelected}
        >
          Copy File
        </Radio>
        <Radio
          name="radio--proxy-copy"
          value={ProxyCopyRadioValue.CREATE_QOD_PROXY}
          checked={proxyCopyRadioValue === ProxyCopyRadioValue.CREATE_QOD_PROXY}
          onChange={this.handleChangeProxyCopySelected}
          disabled
        >
          Copy for QOD Proxy
        </Radio>
      </FormGroup>
    ) : null;
  }

  buildSteps = () => {
    const { item, currentFolder } = this.props;
    const { selectedFolder, newFileName, startFileName, isScheduledFor, isScheduledPauseTime, dateScheduledFor, scheduledPauseTime, isDailyRepeat, encPassword } = this.state;
    const isPathTooLong = ((selectedFolder || {}).key || '').length + newFileName.length > MAX_PATH_LENGTH;
    const isNameTooLong = (newFileName || '').length > MAX_FILENAME_LENGTH_TO_UPLOAD;
    const isInterOfficeCopy = currentFolder !== null && currentFolder !== undefined && selectedFolder !== null && selectedFolder !== undefined && currentFolder.officeFlag !== selectedFolder.officeFlag;
    const continueCondition = isInterOfficeCopy 
                                && (document.getElementById("checkbox-decrypt") == null 
                                      || (document.getElementById("checkbox-decrypt") != null 
                                          && document.getElementById("checkbox-decrypt").checked && encPassword === ""));

    return {
      [Steps.FIRST]: {
        stepName: Steps.FIRST,
        onContinue: () => {
          if (!this.allowStepBrowseForDestination()) {
            this.handleGoToStep();
            return Steps.RENAME;
          } else {
            this.loadStartFolders();
            this.handleGoToSelectDestStepFromFirstStep();
          }
        }
      },
      [Steps.BROWSE_FOR_DESTINATION]: {
        stepName: Steps.BROWSE_FOR_DESTINATION,
        onBack: () => {
          this.setState({ isScrollToElmClass: null });
        },
        allowContinue: () => {
          return !(
            !selectedFolder ||
            (selectedFolder.key === item.fullPath && newFileName !== startFileName)
          );
        },
        onContinue: () => this.handleGoToStep()
      },
      [Steps.RENAME]: {
        stepName: Steps.RENAME,
        onBack: () => {
          if (!this.allowStepBrowseForDestination()) {
            this.setState({ newFileName: startFileName });
            return Steps.FIRST;
          }
          this.handleBackToSelectDestFromRenameStep();
        },
        getTitleContinue: () => {
          return isPathTooLong ? 'File path cannot contain more than 1020 characters' :
            isNameTooLong ? 'File name cannot contain more than 218 characters' : undefined;
        },
        allowContinue: () => {
          return !(
            this.buildNewPath() === item.key ||
            this.isInvalidName()
            || ((selectedFolder || {}).children || []).some(i => i.name.toLowerCase() === newFileName.toLowerCase())
            || isPathTooLong
            || isNameTooLong
            || continueCondition
          );
        },
        onContinue: () => this.handleGoToStep()
      },
      [Steps.CONFIRM]: {
        stepName: Steps.CONFIRM,
        allowContinue: () => {
          const isDailyDisabled = isDailyRepeat && scheduledPauseTime && dateScheduledFor && (dateScheduledFor.getTime() - scheduledPauseTime.getTime() > 86400000 || dateScheduledFor.getTime() - scheduledPauseTime.getTime() < -86400000)
          return !isDailyDisabled && (!isScheduledFor || (isScheduledFor && dateScheduledFor)) && (!isScheduledPauseTime || (isScheduledPauseTime && scheduledPauseTime));
        },
        onContinue: () => this.handleClickConfirmCopy()
      }
    };
  };

  render() {
    const { folders, openedFolders, loaders, item, currentView, tempDirectories, dispatch, currentFolder, storageManager, allowedActions } = this.props;
    const { selectedFolder, isScrollToElmClass, isLoadedStartFolders, isScheduledFor, isScheduledPauseTime } = this.state;
    const downloadRootDirs = [];
    const accessFolders = [];
    for (const index in folders) {
      const folder = folders[index];
      //if (!item.isDirectory && item.officeFlag !== folder.officeFlag) {
      //  continue;
      //}
      accessFolders.push(folder);
      if (item.fsRootName !== folder.fsRootName && folder.downloadRootDir !== null && folder.downloadRootDir !== folder.fsRootName) {
        downloadRootDirs.push(folder.downloadRootDir)
      }
    }
    return (
      <Wizard
        onRef={ref => {
          this.wizard = ref;
        }}
        wizardName="Copy"
        minHeight={this.wizard && this.wizard.getActiveStepKey() === Steps.CONFIRM && (isScheduledFor || isScheduledPauseTime) ? 560 : undefined}
        bsSize="large"
        className="move-modal"
        onCancel={this.handleClose}
        steps={this.buildSteps()}
        getChildren={step => (
          <>
            {this.renderSourceField(step)}
            {this.renderRadioButtonsCopyToProxy(step)}
            {this.renderDestinationField(step)}
            {this.renderDecryptField(step)}
            {
              (step === Steps.BROWSE_FOR_DESTINATION || isLoadedStartFolders === 2) &&
              <FolderExplorerNew
                isHidden={step !== Steps.BROWSE_FOR_DESTINATION}
                dispatch={dispatch}
                contentItems={[item]}
                loaders={loaders}
                folders={accessFolders}
                openedFolders={openedFolders}
                currentView={currentView}
                tempDirectories={tempDirectories}
                folderToExpand={currentFolder || item}
                selectedFolder={selectedFolder}
                onSelectFolder={selFolder => this.setState({ selectedFolder: selFolder })}
                isGetChildrenOnSelect
                checkChildWithSameName={item.isDirectory}
                checkChildWithSameFileName={!item.isDirectory}
                isScrollToElmClass={isScrollToElmClass}
                onScrollToItem={() => { this.setState({ isScrollToElmClass: null }); }}
                onOpenParentFolder={() => { this.setState({ isLoadedStartFolders: 2 }); }}
                emptyAvailableFsItemsText="No FS roots"
                storageManager={storageManager}
                downloadRootDirs={downloadRootDirs}
              />
            }
            {
              step === Steps.CONFIRM && this.isCopyWithRename() ?
                <WarningRenamed>
                  <i className="fas fa-exclamation-circle" />The content will be renamed
                </WarningRenamed> : null
            }
            {
              step === Steps.CONFIRM && selectedFolder.officeFlag === item.officeFlag ?
                <SchedulerForm
                  item={item}
                  dispatch={dispatch}
                  allowedActions={allowedActions}
                  selectedFolder={selectedFolder}
                  title="Copy"
                  onChangeScheduledFor={
                    (checkScheduledFor, date) => {
                      this.setState(
                        {
                          isScheduledFor: checkScheduledFor,
                          dateScheduledFor: date
                        }
                      );
                    }
                  }
                  onChangeScheduledPauseTime={
                    (checkScheduledPauseTime, date) => {
                      this.setState(
                        {
                          isScheduledPauseTime: checkScheduledPauseTime,
                          scheduledPauseTime: date
                        }
                      );
                    }
                  }
                  onChangeDailyRepeat={
                    (checkDailyRepeat) => {
                      this.setState({ isDailyRepeat: checkDailyRepeat });
                    }
                  }
                /> : null
            }
          </>
        )}
      />
    );
  }
}

const CustomPathFieldStyled = styled(PathFieldStyled)`
  align-items: center;

  .decrypt-label{
    flex: 0 0 60px;
    min-width: 100px;
    margin-left:5px;
  }

  .checkbox-label{
    margin-left:5px;
    display: flex;
  }

  .checkbox-div{
    margin-top:5px;
    white-space:nowrap;
    font-weight: bold;
    display: flex;
    margin-left:115px;
  }

  .span-div{
    margin-left:5px;
  }

  .password-div{
    margin-top: 0px;
    margin-left: 5px;
  }

  .fs-root-select,
  .fs-file-type-select {
    width: 65px;
  }

  label {
    min-width: ${props => `${props.labelWidth || 115}px`};
    + div {
      max-width: calc(100% - ${props => `${props.labelWidth || 115}px`});
      &.value {
        &:not(.text--ellipsis--advanced) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-grow: 1;
          > .text--ellipsis--advanced {
            max-width: 100%;
          }
          &.with-btn {
            > .text--ellipsis--advanced {
              max-width: calc(100% - 55px);
            }
          }
        }
      }
    }

    &.min-w-80 {
      min-width: 80px;
      + div {
        max-width: calc(100% - 80px);
      }
    }
  }

  .fs-project-type-select {
    margin-right: 5px;
  }

  button.reset-btn {
    height: 27px;
    padding: 1px 10px;
  }
`;

const WarningRenamed = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;

  i {
    font-size: 22px;
    color: red;
    margin-right: 5px;
  }
`;

const PasswordField = styled(FormGroup)`
  margin: 20px 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  label {
    margin: 0 5px 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: default;
  }
  input {
    padding-left: 8px;
    padding-right: 30px;
    height: 30px;
    cursor: text;
  }
  i[class*="fa-eye"] {
    position: absolute;
    top: 0;
    right: 6px;
    line-height: 30px;
    width: 18px;
    text-align: center;
    cursor: pointer;
    opacity: 0.45;
    :hover {
      opacity: 0.6;
    }
  }
  i[class*="fa-question"] {
    margin-left: 2px;
    color: #333 !important;
    opacity: 0.8;
  }
`;

const PathField = styled(PathFieldStyled)`
  align-items: flex-start !important;
  justify-content: flex-start !important;

  label {
    flex: 0 0 115px;

    & + div {
      align-items: flex-start !important;

      &.value {
        max-width: calc(100% - 115px);
        font-family: monospace;
      }

      .left {

        + button {
          margin-top: 0;
          margin-left: 5px;
        }

        &.word-break-all {
          word-break: break-all;
        }

        .value + .editable-filename {
          margin-left: 2px;
          padding: 0;
          color: #757575;
          outline: 1px auto #e0dede;
          outline-offset: 1px;
          font-family: monospace;
          :hover {
            color: #555;
            outline-color: #ccc;
          }
          :focus {
            color: #282828;
            outline-color: #aaa;
          }
          &.invalid {
            outline-color: #a94442;
            &:focus {
              outline-color: #ffdddd;
            }
          }
          * {
            margin: 0 !important;
            outline: none !important;
            outline-offset: 0 !important;
          }
        }
      }
    }
  }
`;


export const CustomPathField = styled.div`
  display: flex;
  align-items: center;
  margin-top: -15px !important;

   &.source + &.destination {
    min-height: 34px;
  }

  label {
    margin: 0;
    padding: 0;
    font-weight: bold;
    min-width: 115px;

    & + div:not(.text--ellipsis--advanced0) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;

      .left {
        flex-grow: 1;
        &:not(.no-flex) {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  &.move-destination label {

    & + div {
      max-width: calc(100% - 115px);

      .left:not(.without-change-dest-btn) {
        max-width: calc(100% - 47px);
      }
    }
  }

  &.dest-folder {
    align-items: flex-start;

    label {
      margin-right: 15px;
      display: block;
      min-width: unset;
      white-space: nowrap;

      + div {
        max-width: unset;
      }
    }
  }

  .form-control:not([type="checkbox"]) {
    min-width: 30%;
    width: auto;
    min-height: 34px;
    margin-right: 5px;
    &.same-name:not(:focus) {
      opacity: 0.65;
    }
  }

  button {
    height: 34px;

    &.reset-btn {
      height: 20px;
      padding: 1px 3px;
      font-size: 10px;
    }
  }

  .value {
    color: ${props => (props.color || 'blue')};
    font-weight: bold;
    &:not(.no-word-break) {
      word-break: break-all;
    }
    &:not(.is-begin-ingest, .no-monospace) {
      font-family: monospace;
    }
    &.is-begin-ingest {
      font-weight: normal;
    }
  }
`;

CopyModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  copyData: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  loaders: PropTypes.object.isRequired,
  fsRoots: PropTypes.array.isRequired,
  folders: PropTypes.array.isRequired,
  openedFolders: PropTypes.object.isRequired,
  currentView: PropTypes.any.isRequired,
  tempDirectories: PropTypes.any.isRequired,
  currentFolder: PropTypes.object,
  storageManager: PropTypes.object.isRequired,
  allowedActions: PropTypes.array.isRequired
};

export default CopyModal;
