import React from 'react';
import {StyledCategory} from './styledStep';

const Category = props => {
  const {oneCategoryAndOneWorkOrder, categoryName, isOpened, onOpen, onHide} = props;
  if (oneCategoryAndOneWorkOrder) {
    return null;
  }
  return (
    <StyledCategory>
      <i
        className={`far fa-${isOpened ? 'minus' : 'plus'}-square`}
        title={isOpened ? 'Collapse' : 'Expand'}
        onClick={onOpen}
      />
      {categoryName}
      {!!onHide &&
        <i
          className="far fa-eye-slash"
          title="Hide category"
          onClick={onHide}
        />
      }
    </StyledCategory>
  );
};

export default Category;