import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
// import {isDeletedStatus, parseName} from '../../../utils';
import {isDeletedStatus} from '../../../utils';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import MiddleEllipsis from 'react-middle-ellipsis';

export const prepareFileName = (name, cellRef, symlinkRelativePath) => {
  return name && name.length > 5 ? (
    <OverlayTrigger
      placement="bottom"
      container={(cellRef || {}).current || undefined}
      overlay={
        <Tooltip id="filename-tooltip">
          {name}
        </Tooltip>
      }
    >
      <div className="ellipse-value">
        <MiddleEllipsis>
          <span data-original={name}>{name}</span>
        </MiddleEllipsis>
      </div>
    </OverlayTrigger>
  ) : <span>{name}</span>;
};

export const NameCell = (props) => {
  const {
    // dispatch,
    // history,
    value,
    row,
    getTextClassName,
    isOpened,
//    onClick,
    className,
  } = props;

  const cellRef = useRef(null);

  const depth = !row.parents ? 0 : row.parents.length;
  const paddingLeft = depth > 0 ? depth * 16 + 8 : undefined;

  if (row.isEllipsis) {
    return (
      <StyledCell
        ref={cellRef}
        className={`${className || ''} td-ellipsis`}
        paddingLeft={paddingLeft}
      >
        &hellip;
      </StyledCell>
    );
  }

  const name = value;
  // const name = parseName(value);
  let iconClassName;
  const isDirectory = row.isDirectory !== false;
  if (!isDirectory) {
    const ext = /^.+[.](pdf|bmp|png|jpg|rar|zip)$/.exec(name);
    if (ext && ext.length > 1) {
      iconClassName = ext[1] === 'pdf' ? "far fa-file-pdf" : ['jpg', 'png', 'bmp'].includes(ext[1]) ? "far fa-file-image" :
        ['rar', 'zip'].includes(ext[1]) ? "far fa-file-archive" : '';
    }
    if (!iconClassName) {
      iconClassName = "far fa-file";
    }
  } else {
    iconClassName = isOpened(row) ? "far fa-folder-open" : "far fa-folder";
  }
//  const contentId = row.contentID > 0 ? (!isDirectory ? row.parentID : row.contentID) : undefined;
  const asperaID = row.packageID;
  const textClassName = getTextClassName(row);
  const isDeleted = isDeletedStatus(row);
  return (
    <StyledCell
      ref={cellRef}
      className={`${
        className || ''}${
        !isDirectory ? '' : ' is-directory'}${
        isDeleted ? ' deleted' : ''}`
      }
      paddingLeft={paddingLeft}
      >
      <div className={'cell-cont'}>
        <div className="cell-icons"
             role='button'>
          <i className={`${iconClassName}`}/>
          {row.isDirectory ? <i className='far fa-folder'/> : null}
        </div>
        <div className="cell-value">
          {
            isDeleted ? <span className={`deleted ${textClassName}`}>{prepareFileName(name, cellRef)}</span> :
              <a
                className={textClassName}
                href={`/storage?nav=${encodeURIComponent('N:\\SAN_DIGITAL_TRANSIT\\FASPEX\\INCOMING\\' + name + ' - ' + asperaID + '.aspera-package')}`}
                target="_blank"
                rel="noopener noreferrer"
                /*
                onClick={event => {
                  if (event.ctrlKey) {
                    event.stopPropagation();
                  } else {
                    event.preventDefault();
                    if (isDirectory) {
                      event.stopPropagation();
                      onClick(row);
                    }
                  }
                }}
                */
                >
                {prepareFileName(name, cellRef)}
              </a>
          }
        </div>
      </div>
    </StyledCell>
  );
};

const StyledCell = styled.td`
  ${props => props.paddingLeft && css`
    padding-left: ${props.paddingLeft}px !important;
  `}

  position: relative;
  padding:0!important;
  height: 100%;

  .strikethrough {
    text-decoration:line-through;
  }

  &.is-symlink a {
    color: #bb38bb;
  }

  .tooltip {
    left: 20px !important;
    margin-top: 0;
    z-index: 1;
    .tooltip-inner {
      max-width: unset;
      text-align: left;
      word-break: break-all;
    }
  }


  .cell-cont {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .cell-icons {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;

      & + .cell-value {
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        height: 100%;
        
        
        a {
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          .ellipse-value {
            height: 100%;
            div:first-child {
              display: flex;
              align-items: center;
              height: 100%;              
            }  
          }        
        }
      }
    }

    &.needs-drive-connection {
      &:not(:hover) {
        .connect-drive {
          display: none;
        }
      }
      .connect-drive {
        position: absolute;
        top: -4px;
        left: -4px;
        width: calc(100% + 8px);
        height: 100%;
        button {
          width: 100%;
          background-color: #efefefd6;
          border-color: #8a8a8a;
          border-radius: 4px;
          &:hover {
            background-color: #efefef;
            border-color: #ccc;
          }
        }
      }
      i, input, a {
        pointer-events: none;
      }
    }
  }

  &.deleted {
    i {
      color: red;
    }
  }

  i {
    padding-right: 0.5rem;
  }

  &.is-directory {
    span {
      &:hover {
        cursor: pointer;
      }
    }
  }

  a, span {
    &.deleted {
      /*pointer-events: none;*/
      color: red !important;
      text-decoration: line-through;
    }
    &.red {
      color: red !important;
    }
    &.strikethrough {
      text-decoration: line-through;
    }
  }
`;

NameCell.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  className: PropTypes.string,
  getTextClassName: PropTypes.func,
  isOpened: PropTypes.func,
  onClick: PropTypes.func,
  row: PropTypes.object,
  value: PropTypes.any,
};
