import _ from 'lodash';

export const isDeletedStatus = data => {
  const {status} = data;
  return status === 'DELETED';
};

export const prepareDataIfTooManyFiles = data => {
  const maxLimit = 500;
  const partSize = 10;
  if (data.length > maxLimit && data.length > (partSize * 2 + 1)) {
    return [
      ...data.slice(0, partSize),
      {isEllipsis: true, parents: data[partSize].parents},
      ...data.slice(-partSize)
    ];
  }
  return data;
};

export const getUniqueNumberValues = (files, property) => _.uniq(
  files.map(i => i[property])
    .filter(i => typeof i === 'number' && !Number.isNaN(i))
);
