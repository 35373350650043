/* eslint "complexity":"off" */
import {USER_SET_IS_LOGOUT} from '../pages/Login/actions';
import {
  MAIN_ADD_ALERT,
  MAIN_HIDE_ALERT,
  MAIN_HIDE_ALL_ALERTS,
  MAIN_TOGGLE_REPORT_BUG_MODAL,
  MAIN_SET_BUG_SCREENSHOT_URL,
  MAIN_REPORT_BUG_MODAL_LOADING,
  MAIN_REPORT_BUG_MODAL_IN_NEW_TAB_DATA,
  MAIN_SET_REPORT_AVAILABLE,
  MAIN_SUCCESS_POPUP_TOGGLE,
} from "./actions";

const initialState = {
  alerts: [],
  reportBugModalState: {
    isOpen: false,
    bugScreenshotUrl: '',
    loading: false,
    reportAvailable: false,
    minCharacterCount: 36,
    inNewTabData: null,
  },
  successPopupState: {
    isShow: false,
    actionId: null,
    actionType: null,
    message: null,
    isProgress: false
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SET_IS_LOGOUT: {
      return {...initialState};
    }

    case MAIN_ADD_ALERT: {
      const alert = {
        id: (new Date()).getTime(),
        type: action.notificationType,
        headline: action.headline,
        message: action.message
      };
      if (action.timeout) {
        alert.timeout = action.timeout;
      }
      return {
        ...state,
        alerts: [
          ...state.alerts,
          alert
        ]
      };
    }

    case MAIN_HIDE_ALERT: {
      return {
        ...state,
        alerts: state.alerts.filter((notification, i) => notification.id !== (action.notification || {}).id)
      };
    }

    case MAIN_HIDE_ALL_ALERTS: {
      return {
        ...state,
        alerts: initialState.alerts
      };
    }

    case MAIN_TOGGLE_REPORT_BUG_MODAL: {
      const { isOpen } = action;
      return {
        ...state,
        reportBugModalState: {
          ...state.reportBugModalState,
          isOpen,
        },
      };
    }

    case MAIN_SET_BUG_SCREENSHOT_URL: {
      const { url } = action;
      return {
        ...state,
        reportBugModalState: {
          ...state.reportBugModalState,
          bugScreenshotUrl: url,
        }
      }
    }

    case MAIN_REPORT_BUG_MODAL_LOADING: {
      const { loading } = action;
      return {
        ...state,
        reportBugModalState: {
          ...state.reportBugModalState,
          loading,
        }
      }
    }

    case MAIN_REPORT_BUG_MODAL_IN_NEW_TAB_DATA: {
      return {
        ...state,
        reportBugModalState: {
          ...state.reportBugModalState,
          inNewTabData: action.data || initialState.inNewTabData,
        }
      }
    }

    case MAIN_SET_REPORT_AVAILABLE: {
      const {available} = action;
      return {
        ...state,
        reportBugModalState: {
          ...state.reportBugModalState,
          reportAvailable: available,
        }
      }
    }

    case MAIN_SUCCESS_POPUP_TOGGLE: {
      const {isShow, actionId, actionType, message, isProgress} = action;
      return {
        ...state,
        successPopupState: !isShow ? initialState.successPopupState : {
          isShow,
          actionId: actionId,
          actionType: actionType,
          message: message,
          isProgress: isProgress
        },
      };
    }

    default:
      return state;
  }
}
