import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {formatDate} from '../../../utils';

export class DateCell extends Component {
  render() {
    const {value, fromFormat, toFormat, defaultValue, className} = this.props;
    return (
      <td className={className}>
        {formatDate(value, fromFormat, toFormat, defaultValue)}
      </td>
    );
  }
}

DateCell.propTypes = {
  value: PropTypes.any,
  fromFormat: PropTypes.any,
  toFormat: PropTypes.any,
  defaultValue: PropTypes.any,
  className: PropTypes.string
};

export default DateCell;
