import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {changeCurrentPage} from '../../actions';
import ReactPaginate from 'react-paginate';

class Paging extends Component {
  onPageChange = ({selected}) => {
    const {dispatch} = this.props;
    dispatch(changeCurrentPage(selected));
  };

  render() {
    const {currentPage, pageSize, total} = this.props;

    return (
      <ReactPaginate
        activeClassName="active"
        breakClassName="break-me"
        breakLabel="..."
        onPageChange={this.onPageChange}
        containerClassName="pagination"
        forcePage={currentPage}
        marginPagesDisplayed={2}
        nextLabel=">"
        previousLabel="<"
        pageCount={Math.ceil(total/pageSize) || 1}
        pageRangeDisplayed={4}
        subContainerClassName="pages pagination"
      />
    );
  }
}

Paging.propTypes = {
  dispatch: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};

export default Paging;