import React, {Component} from 'react';
import {CheckboxHeaderCell} from '../../../../../components/table/cells/CheckboxHeaderCell';
import {HeaderRow} from '../../../../../components/table/HeaderRow';

class TableHeader extends Component {
  render() {
    const {sort, onHeaderCellClick, isChecked, onChange} = this.props;
    const headers = {
      selected: {
        title: '',
        headCell: {
          component: CheckboxHeaderCell,
          componentArgs: {
            isChecked: isChecked,
            onChange: onChange
          }
        }
      },
      file: {
        title: 'File',
        className: 'name'
      },
      absolute_path: {
        title: 'Absolute Path',
        className: 'abs-path'
      },
      size: {
        title: 'Size',
        className: 'size'
      },
      modified: {
        title: 'Last Modified',
        className: 'modified'
      }
    };
    return (
      <HeaderRow headers={headers} sort={sort} onHeaderCellClick={onHeaderCellClick}/>
    );
  }
}

export default TableHeader;
