import React, { Component } from "react";
import Toggle from "../../../../components/Toggle/Toggle";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { closeActionsView } from "../../actions";

class ActionsHistoryToggle extends Component {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = async () => {
    const { dispatch, actions, onChange } = this.props;
    if (actions) {
      dispatch(closeActionsView());
    } else {
      onChange();
    }
  };

  render() {
    const { actions } = this.props;

    return <Toggle checked={!!actions || false} onChange={this.handleChange} />;
  }
}

ActionsHistoryToggle.propTypes = {
  dispatch: PropTypes.func.isRequired,
  actions: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    actions: state.storageManager.actions,
  };
}

export default connect(mapStateToProps)(ActionsHistoryToggle);
