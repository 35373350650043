import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {getSortedRowsBy} from '../../../../../../utils';
import Table from '../../../../../../components/table/Table';
import {ClientCell} from './cells/ClientCell';
import {LinkCell} from './cells/LinkCell';

class TableWrap extends Component {
  state = {
    tableSort: {
      field: '',
      direction: ''
    }
  };

  handleClickLinkToWO = row => {
    const {onLinkToWO} = this.props;
    onLinkToWO(row);
  };

  handleLoadClientList = row => {
    const {onLoadClientList} = this.props;
    onLoadClientList(row.woMemfisId);
  };

  handleSelectClientName = (row, clientName) => {
    const {onSelectClientName} = this.props;
    onSelectClientName(row.woMemfisId, clientName);
  };

  headers = {
    woMemfisId: {
      title: 'WO#'
    },
    title: {
      title: 'Title',
      className: 'word-break-all title'
    },
    clientName: {
      title: 'Client',
      component: ClientCell,
      componentArgs: {
        onLoad: this.handleLoadClientList,
        onSelect: this.handleSelectClientName
      }
    },
    format: {
      title: 'Format',
      className: 'word-break-all'
    },
    episode: {
      title: 'Ep #'
    },
    LinkToWO: {
      title: '',
      className: 'link-to-wo',
      component: LinkCell,
      componentArgs: {
        onClick: this.handleClickLinkToWO
      }
    }
  };

  handleHeaderCellClick = (field, direction) => {
    this.setState({tableSort: {field, direction}});
  };

  render() {
    const {data, emptyText} = this.props;
    const {tableSort} = this.state;
    return (
      <StyledTable className="table-wrapper">
        <div className="table-container">
          <div className="table-block">
            <Table
              headers={this.headers}
              rows={getSortedRowsBy(data, tableSort.field, tableSort.direction)}
              sort={tableSort}
              onHeaderCellClick={this.handleHeaderCellClick}
              emptyText={emptyText}
              />
          </div>
        </div>
      </StyledTable>
    );
  }
}

const StyledTable = styled.div`
  .table-container {
    width: 100%;
  }

  .table-block {
    max-width: 100%;
    max-height: calc(100vh - 350px);
    min-height: 100px;
    overflow-y: auto;
    margin-bottom: 0;
    border: 1px solid #ddd;

    .table {
      color: #282828;
      margin-bottom: 0;

      th {
        position: sticky;
        top: 0;
        background-color: #fff;
        border-right: 1px solid #ddd;
        border-bottom-width: 1px;
        &.link-to-wo {
          pointer-events: none;
        }
        &.title {
          width: 31%;
        }
      }

      td {
        border-right: 1px solid #ddd;
        &.word-break-all {
          word-break: break-all;
        }
        &.data-table-empty {
          text-align: center;
          width: 100% !important;
        }
      }
    }
  }

  + .pagination {
    margin: 10px 0 0;
  }
`;

TableWrap.propTypes = {
  data: PropTypes.array.isRequired,
  emptyText: PropTypes.string.isRequired,
  onLinkToWO: PropTypes.func.isRequired
}

export default TableWrap;
