import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, FormGroup, FormControl, ControlLabel, Radio, OverlayTrigger, Popover } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment-timezone';
import styled, { createGlobalStyle } from 'styled-components';
import { calcNewNamePartByKey, buildFileNameFromTemplate } from '../Renaming';
import Select from 'react-select';
import RndModal from '../../../../components/modals/RndModal';
import { MACROS_FIELD_TYPE } from '../../constants';

class DeliveryFilenameModal extends Component {

  calcNewName = (template, macros) => {
    const { macrosFields } = this.props;
    return buildFileNameFromTemplate(template, macros, macrosFields);
  };

  state = {
    newName: this.calcNewName(this.props.deliveryFileName, this.props.macros),
    fields: { ...this.props.macros },
    focusedField: null,

    selectedSpec: this.props.deliveryFileNameDataList.filter((deliveryFileName) => deliveryFileName.raw === this.props.deliveryFileName)[0] !== undefined ?
      this.props.deliveryFileNameDataList.filter(
        (deliveryFileName) => deliveryFileName.raw === this.props.deliveryFileName
      )[0].raw : '',


    selectedLabel: this.props.deliveryFileNameDataList.filter((deliveryFileName) => deliveryFileName.raw === this.props.deliveryFileName)[0] !== undefined ?
      this.props.deliveryFileNameDataList.filter(
        (deliveryFileName) => deliveryFileName.raw === this.props.deliveryFileName
      )[0].template : '',
  };

  componentWillUnmount() {
    this.props.onClose(false);
  }

  handleChangeField = (name, value) => {
    const fields = { ...this.state.fields, [name]: value };
    const newName = this.calcNewName(this.state.selectedSpec.value !== undefined ? this.state.selectedSpec.value : this.state.selectedSpec, fields);
    this.setState({ newName, fields })
  };

  handleChangeSelectField = (name, option, mainFieldName) => {
    const { focusedField } = this.state;
    const { macrosFields } = this.props;

    //const regexp = /^[0-9]\d{0,2}(?:\,\d{1,3})?$/

    if (macrosFields[name].regex !== undefined) {
      const regexp = new RegExp(macrosFields[name].regex);
      if (option !== null && regexp !== undefined && !regexp.test(option.label)) {
        return;
      }
    }

    const value = (option || {}).label || null;
    const filedName = mainFieldName ? `${mainFieldName}@@@${name}` : name;
    this.handleChangeField(filedName, value);
    if (!value && focusedField && focusedField.startsWith(`${name}@@@`)) {
      this.setState({ focusedField: name });
    }
  };

  handleSpecChange = selectedSpec => {
    if (!selectedSpec) {
      return;
    }

    const { deliveryFileNameDataList } = this.props;

    const selectedDeliveryFileName = deliveryFileNameDataList.filter(
      (deliveryFileName) => deliveryFileName.raw === selectedSpec.value
    );
    this.setState({ fields: null });
    this.setState({ selectedSpec: selectedSpec, fields: selectedDeliveryFileName[0].macros, newName: this.calcNewName(selectedDeliveryFileName[0].raw, selectedDeliveryFileName[0].macros) });
    Object.keys(this.state.fields).map(k => this.renderField(k));
    this.renderNewName(this.state.newName || '')
  };

  getFieldLabel = key => {
    const { macrosFields } = this.props;
    return (macrosFields[key] || {}).label || key;
  };

  isAnySelectType = type => [MACROS_FIELD_TYPE.SELECT, MACROS_FIELD_TYPE.COMBOBOX].includes(type);

  renderField = (key, mainKey) => {
    const { macrosFields } = this.props;
    const { fields, focusedField } = this.state;
    let formControl;
    const onChange = ({ target: { value } }) => this.handleChangeField(key, value);
    const focusProps = {
      onFocus: () => this.setState({ focusedField: mainKey || key }),
      onBlur: () => this.setState({ focusedField: null })
    };
    if (key in macrosFields &&
      (this.isAnySelectType(macrosFields[key].type) || [MACROS_FIELD_TYPE.RADIO, MACROS_FIELD_TYPE.DATE].includes(macrosFields[key].type))) {
      const { type, values } = macrosFields[key];
      if (this.isAnySelectType(type)) {
        const isComboBox = type === MACROS_FIELD_TYPE.COMBOBOX;
        const options = Object.keys(values || {}).map(vk => ({ label: values[vk], value: vk })).sort((a, b) => a.value.localeCompare(b.value));;
        const fieldKey = !mainKey ? key : `${mainKey}@@@${key}`;
        if (isComboBox && fields[fieldKey] && !options.find(o => o.label === fields[fieldKey])) {
          options.push({ label: fields[fieldKey], value: fields[fieldKey] });
        }
        const optionVal = (options.find(o => o.label === fields[fieldKey]) || {}).value || null;
        const selectProps = {
          placeholder: "",
          value: optionVal,
          options,
          onChange: o => this.handleChangeSelectField(key, o, mainKey),
          ...focusProps,
          autoFocus: focusedField === fieldKey,
        };
        formControl = isComboBox ?
          <Select.Creatable
            {...selectProps}
            promptTextCreator={label => `"${label}" does not exist. Select it if needed.`}
            isValidNewOption={({ label }) => {
              return !!(label || '').trim() && !options.find(o => o.label.toLowerCase() === label.toLowerCase());
            }}
          /> : <Select {...selectProps} />;
        if (!mainKey && optionVal && optionVal in macrosFields && this.isAnySelectType(macrosFields[optionVal].type)) {
          formControl = (
            <>
              {formControl}
              {this.renderField(optionVal, key)}
            </>
          );
        }
      } else if (type === MACROS_FIELD_TYPE.RADIO) {
        formControl = (
          <>
            {Object.keys(values || {}).map(vk => (
              <Radio
                key={`${key}-${vk}`}
                name={key}
                value={vk}
                checked={fields[key] === vk}
                onChange={onChange}
                inline
                {...focusProps}
              >
                {values[vk]}
              </Radio>
            ))}
          </>
        );
      } else {
        let selectedVal = null;
        try {
          if (typeof fields[key] === 'string' && fields[key]) {
            const timezone = moment.tz.guess();
            selectedVal = (timezone ? moment(fields[key], 'YYYY-MM-DD').tz(timezone) : moment(fields[key], 'YYYY-MM-DD')).toDate();
            if (isNaN(selectedVal)) {
              selectedVal = null;
            }
          }
        } catch (ignored) {
          selectedVal = null;
        }
        formControl = (
          <DatePicker
            dateFormat="yyyyMMdd"
            selected={selectedVal}
            onChange={v => this.handleChangeField(key, v ? moment(v).format('YYYY-MM-DD') : v)}
            isClearable
            {...focusProps}
          />
        );
      }
    } else {
      formControl = (
        <FormControl
          type="text"
          value={fields[key] || ''}
          onChange={onChange}
          {...focusProps}
        />
      );
    }
    const res = (
      <>
        <ControlLabel>
          {this.getFieldLabel(key)} 
          {macrosFields[key] !== undefined && macrosFields[key].comment !== undefined &&
            <OverlayTrigger
              placement="right"
              overlay={
                <Popover id="enc-password-popover" className="filter-popover">
                  {macrosFields[key] !== undefined ? macrosFields[key].comment : ''}
                </Popover>
              }
            >
              <i className="far fa-question-circle" />
            </OverlayTrigger>
          }
        </ControlLabel>
        {formControl}
      </>
    );
    return !mainKey ? (
      <StyledFormGroup key={key}>
        {res}
      </StyledFormGroup>
    ) : res;
  };

  renderNewName = (newName) => {
    const { macrosFields } = this.props;
    const { fields, focusedField } = this.state;
    const fieldKeys = Object.keys(fields);
    if (!fieldKeys.length || !focusedField) {
      return newName;
    }
    return this.state.selectedSpec.value !== undefined ? this.state.selectedSpec.value.split(new RegExp('(' + fieldKeys.map(k => `\\[${k}\\]`).join('|') + ')'))
      .map((t, i) => {
        const k = t.substr(1, t.length - 2);
        const val = calcNewNamePartByKey(k, fields, macrosFields) || t;
        return t === `[${focusedField}]` ? <span key={`tk-${i}`} className="is-focused-macros-field">{val}</span> : val;
      }) :
      this.state.selectedSpec.split(new RegExp('(' + fieldKeys.map(k => `\\[${k}\\]`).join('|') + ')'))
        .map((t, i) => {
          const k = t.substr(1, t.length - 2);
          const val = calcNewNamePartByKey(k, fields, macrosFields) || t;
          return t === `[${focusedField}]` ? <span key={`tk-${i}`} className="is-focused-macros-field">{val}</span> : val;
        });
  };


  renderTemplate = () => {
    const { fields, focusedField, selectedSpec } = this.state;
    const fieldKeys = Object.keys(fields);
    const deliveryFileName = selectedSpec.value !== undefined ? selectedSpec.value : selectedSpec;
    if (!fieldKeys.length || !focusedField) {
      return deliveryFileName;
    }
    return deliveryFileName.split(new RegExp('(' + fieldKeys.map(k => `\\[${k}\\]`).join('|') + ')'))
      .map((t, i) => t === `[${focusedField}]` ? <span key={`tk-${i}`} className="is-focused-macros-field">{t}</span> : t);
  };

  render() {
    const { originalFileName, deliveryFileName, deliveryFileNameDataList, onClose, onApply, isValidFileName } = this.props;
    const options = Object.keys(deliveryFileNameDataList || {}).map(vk => ({ label: deliveryFileNameDataList[vk].template, value: deliveryFileNameDataList[vk].raw }));

    const { newName, fields, selectedSpec } = this.state;
    const isInvalid = !isValidFileName(newName);

    return (
      <RndModal
        bsSize="large"
        width="90%"
        dialogClassName="delivery-filename-modal"
        show={true}
        onHide={onClose}
        backdrop={false}
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <ModalTitleStyled>
            <div>Rename Using "{this.state.selectedSpec.label !== undefined
              ? this.state.selectedSpec.label
              : this.props.deliveryFileNameDataList.filter(
                (deliveryFileName) => deliveryFileName.raw === this.props.deliveryFileName
              )[0] !== undefined ? this.props.deliveryFileNameDataList.filter(
                (deliveryFileName) => deliveryFileName.raw === this.props.deliveryFileName
              )[0].template : ''
            }" Spec:</div><div>{originalFileName}</div>
          </ModalTitleStyled>
        </Modal.Header>
        <Modal.Body>
          <ModalContainerStyled className="container-fluid">
            <StyledFormGroup>
              <ControlLabel>
                Original Name
              </ControlLabel>
              <FormControl.Static componentClass="div">
                {originalFileName}
              </FormControl.Static>
            </StyledFormGroup>
            <StyledFormGroup validationState={isInvalid ? 'error' : null}>
              <ControlLabel>
                New Name
              </ControlLabel>
              <FormControl.Static componentClass="div" className="form-control textarea">
                {this.renderNewName(newName || '')}
              </FormControl.Static>
            </StyledFormGroup>
            <StyledFormGroup>
              <ControlLabel>
                Spec
              </ControlLabel>
              <FormControl.Static componentClass="div">
                <Select
                  options={options}
                  multi={false}
                  placeholder=""
                  value={selectedSpec}
                  onChange={this.handleSpecChange}
                />
              </FormControl.Static>
            </StyledFormGroup>
            <StyledFormGroup>
              <ControlLabel>
                Template
              </ControlLabel>
              <FormControl.Static componentClass="div">
                {this.renderTemplate()}
              </FormControl.Static>
            </StyledFormGroup>
            {Object.keys(fields).map(k => k.indexOf('@@@') < 0 ? this.renderField(k) : null)}
          </ModalContainerStyled>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="primary"
            onClick={() => {
              onApply(newName, fields);
              onClose(false);
            }}
            disabled={isInvalid || deliveryFileName === newName}
          >
            OK
          </Button>
          <Button
            bsStyle="default"
            onClick={onClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
        <GlobalStyled />
      </RndModal>
    );
  }
}

const GlobalStyled = createGlobalStyle`
  .delivery-filename-modal {
    width: 90%;
    .modal-content {
      max-height: calc(100vh - 100px);
      .react-draggable {
        max-height: calc(100vh - 100px) !important;
      }
    }
  }
`;

const ModalTitleStyled = styled(Modal.Title)`
  padding-right: 20px;
  word-break: break-all;
  @media (min-width: 768px) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    > div {
      :first-child {
        padding-right: 5px;
        white-space: nowrap;
      }
      :last-child {
        flex: 1;
      }
    }
  }
`;

const ModalContainerStyled = styled.div`
  /*max-height: calc(100vh - 225px);
  overflow-y: auto;*/
  .form-group .control-label {
    width: 25%;
    i {
      margin-left: 7px;
    }
  }
`;

const StyledFormGroup = styled(FormGroup)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &.has-error {
    .form-control-static {
      color: #a94442;
    }
  }
  .control-label {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .Select + .control-label {
    margin-left: 15px;
    text-align: right;
  }
  .form-control, .form-control-static, .control-label + .Select {
    flex: 1;
  }
  .form-control-static {
    word-break: break-all;
    &.form-control.textarea {
      margin: 0;
      width: 100% !important;
      height: auto;
      min-height: 34px;
      background-color: #eeeeee;
    }
  }
  textarea {
    margin: 0;
    width: 100% !important;
    min-height: 34px;
    resize: vertical;
    word-break: break-all;
  }
  .is-focused-macros-field {
    color: red;
  }
  .Select-create-option-placeholder {
    :before {
      padding-right: 5px;
      content: '!';
      color: red;
      font-weight: bold;
    }
  }
`;

DeliveryFilenameModal.propTypes = {
  originalFileName: PropTypes.string.isRequired,
  deliveryFileName: PropTypes.string,
  template: PropTypes.string.isRequired,
  macros: PropTypes.object.isRequired,
  macrosFields: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  isValidFileName: PropTypes.func.isRequired
};

export default DeliveryFilenameModal;
