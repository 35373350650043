import {PARENT_DIR_REGEX_FOR_REPLACE} from "../../../../../utils";
import {applyNavigation} from "../../../actions";
import {isSymlinkLive, isSymlinkDeleted, isSymlinkSimple} from '../../../constants';

export const defaultSymlinkTraits = {
  onClickSymlink: () => {},
  symlinkRelativePath: undefined,
  iconClassName: '',
};

export function getSymlinkCellTraits(row, dispatch, history) {
  let onClickSymlink = () => {};
  let symlinkRelativePath,
    iconClassName = '';

  iconClassName = 'far fa-link';
  const {relativeFileName, relativePath} = row;

  const parentPath = relativeFileName.replace(PARENT_DIR_REGEX_FOR_REPLACE, '');
  if (isSymlinkLive(row)) {
    symlinkRelativePath = relativePath;
    onClickSymlink = (event) => {
      event.preventDefault();
      event.stopPropagation();
      dispatch(applyNavigation(history, parentPath, () => {}, row.contentID));
    }
  } else if (isSymlinkDeleted(row)) {
    iconClassName = 'far fa-trash';
  } else if (isSymlinkSimple(row)) {
    iconClassName = 'far fa-link-simple';
  }
  if (iconClassName) {
    iconClassName += ' std--symlink--icon';
  }
  return {
    onClickSymlink,
    symlinkRelativePath,
    iconClassName,
  };
}