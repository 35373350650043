import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button, FormGroup, FormControl, ControlLabel, NavItem} from 'react-bootstrap';
import {
  SelectingHeader, SelectingMain, File, H4, StyledNav, Tree, MenuRow, MenuCol, CommonInstructions, DestinationBlock
} from './styledStep';
import {TreeItem} from './TreeItem';
import FileItem from './FileItem';
import WorkOrder from './WorkOrder';
import Category from './Category';
import Select from 'react-select';
import {FILE_EXTENSION__ALL, TAB_WO__ALL, INSTRUCTIONS__GENERAL} from '../constants';
import {
  changeSearchString, toggleListView, selectFileExtensions, selectWoTab
} from '../actions';

const LS_KEY__DELIVERY_HIDDEN_DIRS = 'deliveryHiddenItems';

const getDeliveryHiddenDirsFromLocalStorage = () => {
  try {
    const hiddenDirs = JSON.parse(localStorage.getItem(LS_KEY__DELIVERY_HIDDEN_DIRS));
    if (Array.isArray(hiddenDirs)) {
      return hiddenDirs;
    }
  } catch (ignored) {/**/}
  return [];
};

const Selecting = props => {
  const {dispatch, deliveryManager, onSelect, onToggleOpenInstructions} = props;
  const {
    reviewDeliveryData, categoryDataForTreeView, selectedFileExtensions, fileExtensionsList, isListView, searchString,
    workOrders, woTab
  } = deliveryManager;
  const {categoryData, commonInstructions, destinationPath} = reviewDeliveryData;
  const oneCategoryAndOneWorkOrder = Object.keys(workOrders).length === 1 && workOrders[Object.keys(workOrders)[0]].length === 1;

  const extensionsToFilter = selectedFileExtensions[0].value === FILE_EXTENSION__ALL ? [] :
    selectedFileExtensions.map(ext => ext.value.trim().toLowerCase().replace(/^[*]/, '').replace(/^[.]/, '')).filter(ext => ext.trim());

  const [hiddenDirs, setHiddenDirs] = useState(getDeliveryHiddenDirsFromLocalStorage());
  const [openedCategories, setOpenedCategories] = useState({});
  const [openedWOs, setOpenedWOs] = useState({});
  const [expandedDirs, setExpandedDirs] = useState({});
  const [checkedNonSelectedFiles, setCheckedNonSelectedFiles] = useState({});
  const [checkedSelectedFiles, setCheckedSelectedFiles] = useState({});

  useEffect(() => {
    function updateHeight() {
      try {
        const elm = document.getElementById('selecting-block-main');
        const elmTop = elm.getBoundingClientRect().top;
        const tbElm = document.getElementById('delivery-step-btns');
        elm.style.height = `calc(100vh - ${elmTop}px - ${tbElm.clientHeight}px)`;
      } catch(ignored) {/**/}
    }

    function handleWindowResize() {
      updateHeight();
    }

    updateHeight();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleSelectWoTab = tab => {
    dispatch(selectWoTab(tab));
  };

  const handleChangeSearchString = str => {
    dispatch(changeSearchString(str));
  };

  const handleSelectExtensions = fileExtensions => {
    dispatch(selectFileExtensions(fileExtensions));
    setTimeout(() => {
      try {
        const elm = document.getElementById('selecting-block-main');
        const elmTop = elm.getBoundingClientRect().top;
        const tbElm = document.getElementById('delivery-step-btns');
        elm.style.height = `calc(100vh - ${elmTop}px - ${tbElm.clientHeight}px)`;
      } catch(ignored) {/**/}
    }, 50);
  };

  const handleCheckFile = (fileKey, isCheckedToSelect, isSelected) => {
    if (isSelected) {
      setCheckedSelectedFiles({...checkedSelectedFiles, [fileKey]: !isCheckedToSelect})
    } else {
      setCheckedNonSelectedFiles({...checkedNonSelectedFiles, [fileKey]: !isCheckedToSelect})
    }
  };

  const markAsSelected = (isSelected, checkedFiles) => {
    const newData = {...reviewDeliveryData};
    const newTreeView = [...categoryDataForTreeView];
    Object.keys(checkedFiles).forEach(fileKey => {
      const [, catId, woMem, contentId] = /([^-]+)[-]{3}([^-]+)[-]{3}([^-]+)/.exec(fileKey) || [];
      try {
        const file = newData.categoryData.find(cat => cat.category.categoryID === +catId)
          .woDataList.find(wo => wo.woMemfis === woMem)
          .contentDataList.find(f => f.contentID === +contentId);
        if (file) {
          file.isSelected = isSelected;
          if (!isSelected) {
            file.isChecked = false;
          }
        } else {
          console.log(`Not found ${fileKey} in newData`);
        }
      } catch(ignored) {/**/}
      try {
        const updChildren = (item) => {
          if (item.isDirectory) {
            item.isSelected = item._children.every(child => !!child.isSelected);
            item._children.forEach(updChildren);
          } else if (item.categoryID === +catId && item.woMemfis === woMem && item.contentID === +contentId) {
            item.isSelected = isSelected;
            if (!isSelected) {
              item.isChecked = false;
            }
          }
        };
        newTreeView.forEach(cat => {
          const {category: {categoryID}, woDataList} = cat;
          if (categoryID === +catId) {
            woDataList.forEach(wo => {
              const {woMemfis, contentDataList} = wo;
              if (woMemfis === woMem) {
                contentDataList.forEach(updChildren);
              }
            });
          }
        });
      } catch(ignored) {/**/}
    });
    onSelect(newData, newTreeView);
  };

  const handleSelectContent = (content, isCheckedToSelect) => {
    const {isDirectory, woMemfis, categoryID, /*_id, */isSelected, contentID} = content;
    if (!isDirectory) {
      const fileKey = `${categoryID}---${woMemfis}---${contentID}`;
      handleCheckFile(fileKey, isCheckedToSelect, isSelected);
    }
  };

  const handleSelect = () => {
    markAsSelected(true, checkedNonSelectedFiles);
    setCheckedNonSelectedFiles({});
  };

  const handleDeselect = () => {
    markAsSelected(false, checkedSelectedFiles);
    setCheckedSelectedFiles({});
  };

  const isHiddenCat = catId => {
    return hiddenDirs.includes(String(catId)) || (woTab !== TAB_WO__ALL && !workOrders[woTab].includes(catId));
  };

  const isHiddenWO = (key, woMemfis) => {
    return hiddenDirs.includes(key) || (!!woMemfis && woTab !== TAB_WO__ALL && woTab !== woMemfis);
  };

  const isHiddenDir = directory => {
    const {isDirectory, woMemfis, categoryID, _id} = directory;
    const key = `${categoryID}---${woMemfis}---${_id}`;
    return isDirectory ? hiddenDirs.includes(key) : null;
  };

  const hideDirByKey = key => {
    const updHiddenDirs = [...hiddenDirs, key];
    localStorage.setItem(LS_KEY__DELIVERY_HIDDEN_DIRS, JSON.stringify(updHiddenDirs));
    setHiddenDirs(updHiddenDirs);
  };

  const handleHideDirectory = directory => {
    const {isDirectory, woMemfis, categoryID, _id} = directory;
    if (isDirectory) {
      const key = `${categoryID}---${woMemfis}---${_id}`;
      hideDirByKey(key);
    }
  };

  const handleUnhideAllDirs = () => {
    localStorage.removeItem(LS_KEY__DELIVERY_HIDDEN_DIRS);
    setHiddenDirs([]);
  };

  const handleToggleView = () => {
    dispatch(toggleListView(!isListView));
  };

  const isExpandedDir = directory => {
    const {isDirectory, woMemfis, categoryID, _id} = directory;
    const key = `${categoryID}---${woMemfis}---${_id}`;
    return isDirectory ? expandedDirs[key] !== false : null;
  };

  const handleExpandDirectory = directory => {
    const {isDirectory, woMemfis, categoryID, _id} = directory;
    if (isDirectory) {
      const key = `${categoryID}---${woMemfis}---${_id}`;
      const isExpanded = expandedDirs[key] !== false;
      setExpandedDirs({...expandedDirs, [key]: !isExpanded});
    }
  };

  const renderWorkOrder = (isShowSelectedFiles, woKey, woMemfis, description, isOpened, categoryName) => {
    const woProps = {
      oneCategoryAndOneWorkOrder,
      categoryName,
      woMemfis,
      description,
      isOpened,
      onOpen: () => setOpenedWOs({...openedWOs, [woKey]: !isOpened}),
      onHide: isShowSelectedFiles || woTab !== TAB_WO__ALL ? undefined : () => hideDirByKey(woKey),
      onShowDesc: onToggleOpenInstructions
    };
    return <WorkOrder {...woProps}/>;
  };

  const renderCategory = (isShowSelectedFiles, categoryID, categoryName, isOpened) => {
    const catProps = {
      oneCategoryAndOneWorkOrder,
      categoryName,
      isOpened,
      onOpen: () => setOpenedCategories({...openedCategories, [categoryID]: !isOpened}),
      onHide: isShowSelectedFiles || woTab !== TAB_WO__ALL ? undefined : () => hideDirByKey(String(categoryID))
    };
    return <Category {...catProps}/>;
  };

  const renderFilesInListView = isShowSelected => {
    return categoryData.map(cat => {
      const {category, woDataList} = cat;
      const {categoryID, categoryName} = category;
      if (isHiddenCat(categoryID)) {
        return null;
      }
      const isOpenedCat = oneCategoryAndOneWorkOrder || openedCategories[categoryID] !== false;
      return (
        <Fragment key={`cat-${categoryID}`}>
          {renderCategory(isShowSelected, categoryID, categoryName, isOpenedCat)}
          {isOpenedCat && !!woDataList && woDataList.map(wo => {
            const {woMemfis, rootFileName, contentDataList, description} = wo;
            const woKey = `${categoryID}---${woMemfis}`;
            if (isHiddenWO(woKey, woMemfis)) {
              return null;
            }
            const isOpenedWo = oneCategoryAndOneWorkOrder || openedWOs[woKey] !== false;
            let filteredFiles = contentDataList || [];
            if (extensionsToFilter.length) {
              filteredFiles = filteredFiles.filter(file => extensionsToFilter.some(ext => file.fileName.toLowerCase().endsWith(ext)));
            }
            if (searchString.trim()) {
              filteredFiles = filteredFiles.filter(file => file.fileName.replace(rootFileName, '').replace(/^([\\/])?/, '').toLowerCase().indexOf(searchString.trim().toLowerCase()) >= 0);
            }
            filteredFiles = filteredFiles.sort((a, b) => {
              const compRes = a.fileName.localeCompare(b.fileName);
              if (compRes !== 0) {
                return compRes;
              }
              return (b.modified || 0) - (a.modified || 0);
            });
            return (
              <Fragment key={woKey}>
                {renderWorkOrder(isShowSelected, woKey, woMemfis, description, isOpenedWo, categoryName)}
                {isOpenedWo && filteredFiles.map(file => {
                  const {contentID, fileName, isSelected} = file;
                  const fileKey = `${woKey}---${contentID}`;
                  const isCheckedToSelect = !!(isSelected ? checkedSelectedFiles[fileKey] : checkedNonSelectedFiles[fileKey]);
                  return !!isSelected === isShowSelected && (
                    <File key={fileKey} className={oneCategoryAndOneWorkOrder ? 'only-files' : undefined}>
                      <i
                        className={isCheckedToSelect ? 'far fa-check-square' : 'far fa-square'}
                        title={isCheckedToSelect ? 'Uncheck' : 'Check'}
                        onClick={() => handleCheckFile(fileKey, isCheckedToSelect, isSelected)}
                      />
                      <FileItem fileName={fileName} rootFileName={rootFileName}/>
                    </File>
                  );
                })}
              </Fragment>
            );
          })}
        </Fragment>
      );
    });
  };

  const renderFiles = isShowSelected => {
    if (isListView) {
      return renderFilesInListView(isShowSelected);
    }
    return categoryDataForTreeView.map(cat => {
      const {category, woDataList} = cat;
      const {categoryID, categoryName} = category;
      if (isHiddenCat(categoryID)) {
        return null;
      }
      const isOpenedCat = oneCategoryAndOneWorkOrder || openedCategories[categoryID] !== false;
      return (
        <Fragment key={`cat-${categoryID}`}>
          {renderCategory(isShowSelected, categoryID, categoryName, isOpenedCat)}
          {isOpenedCat && !!woDataList && woDataList.map(wo => {
            const {woMemfis, contentDataList, description} = wo;
            const woKey = `${categoryID}---${woMemfis}`;
            if (isHiddenWO(woKey, woMemfis)) {
              return null;
            }
            const isOpenedWo = oneCategoryAndOneWorkOrder || openedWOs[woKey] !== false;
            return (
              <Fragment key={woKey}>
                {renderWorkOrder(isShowSelected, woKey, woMemfis, description, isOpenedWo, categoryName)}
                {isOpenedWo && (contentDataList || []).length > 0 &&
                  <Tree className={oneCategoryAndOneWorkOrder ? 'only-files' : undefined}>
                    {contentDataList.map(content => {
                      return (content.isDirectory || content.isSelected === isShowSelected) ? (
                        <TreeItem
                          key={content._id}
                          {...content}
                          isHiddenDir={isHiddenDir}
                          isExpandedDir={isExpandedDir}
                          onHide={isShowSelected ? undefined : handleHideDirectory}
                          onExpand={handleExpandDirectory}
                          onSelect={handleSelectContent}
                          isCheckedToSelect={contentData => {
                            const fileKey = `${contentData.categoryID}---${contentData.woMemfis}---${contentData.contentID}`;
                            return !!(contentData.isSelected ? checkedSelectedFiles[fileKey] : checkedNonSelectedFiles[fileKey]);
                          }}
                          isShowSelected={isShowSelected}
                          isFiltered={contentData => {
                            if (!contentData.isDirectory) {
                              return (!extensionsToFilter.length || extensionsToFilter.some(ext => contentData.fileName.toLowerCase().endsWith(ext))) &&
                                     (!searchString.trim() || contentData.fileNameWithoutRoot.toLowerCase().indexOf(searchString.trim().toLowerCase()) >= 0);
                            }
                            return true;
                          }}
                        />
                      ) : null;
                    })}
                  </Tree>
                }
              </Fragment>
            );
          })}
        </Fragment>
      );
    });
  };

  return (
    <>
      {!oneCategoryAndOneWorkOrder &&
        <StyledNav bsStyle="tabs" activeKey={woTab} onSelect={handleSelectWoTab}>
          <NavItem eventKey={TAB_WO__ALL}>
            All WO-s
          </NavItem>
          {Object.keys(workOrders).filter(k => workOrders[k].some(c => !isHiddenWO(`${c}---${k}`))).sort((a, b) => a.localeCompare(b)).map(k => (
            <NavItem eventKey={k} key={k}>
              {k}
            </NavItem>
          ))}
        </StyledNav>
      }
      <SelectingHeader className="block-header for-selecting">
        <div className="col-files files-left">
          <H4>Non-Selected Files</H4>
          <MenuRow>
            <MenuCol>
              <FormGroup>
                <ControlLabel>
                  File Extensions:
                </ControlLabel>
                <Select.Creatable
                  promptTextCreator={label => `Filter by "${label}"`}
                  placeholder=""
                  multi
                  value={selectedFileExtensions}
                  options={fileExtensionsList}
                  onChange={handleSelectExtensions}
                />
              </FormGroup>
            </MenuCol>
            <MenuCol>
              <FormGroup>
                <ControlLabel>
                  Search:
                </ControlLabel>
                <FormControl
                  title="Search by the portion of the file name"
                  type="text"
                  value={searchString}
                  onChange={({target: {value}}) => handleChangeSearchString(value || '')}
                />
              </FormGroup>
            </MenuCol>
            {hiddenDirs.length > 0 &&
              <MenuCol className={`col-flex-1 ${isListView ? 'list' : 'tree'}-view`}>
                <FormGroup>
                  <ControlLabel>
                    &nbsp;
                  </ControlLabel>
                  <Button
                    title="Unhide all"
                    className="unhide-all"
                    onClick={handleUnhideAllDirs}
                  >
                    Unhide ALL
                  </Button>
                </FormGroup>
              </MenuCol>
            }
            <MenuCol className="col-flex-1">
              <FormGroup>
                <ControlLabel>
                  &nbsp;
                </ControlLabel>
                <Button
                  title="Toggle view"
                  onClick={handleToggleView}
                >
                  <i className={`fas fa-${isListView ? 'list' : 'stream'}`}/>
                </Button>
              </FormGroup>
            </MenuCol>
          </MenuRow>
        </div>
        <div className="col-files files-right">
          <H4>Selected Files</H4>
          {!!destinationPath &&
            <DestinationBlock>
              <span>
                Copying to
              </span>
              <strong>
                {destinationPath}
              </strong>
            </DestinationBlock>
          }
          {!!commonInstructions &&
            <CommonInstructions
              title="Click to view"
              onClick={() => onToggleOpenInstructions(INSTRUCTIONS__GENERAL, commonInstructions)}
            >
              Common Instructions
            </CommonInstructions>
          }
        </div>
      </SelectingHeader>
      <SelectingMain id="selecting-block-main" className="block-main">
        <div className="col-files files-left">
          {renderFiles(false)}
        </div>
        <div className="col-buttons">
          <Button
            title="Select"
            onClick={handleSelect}
            disabled={!Object.keys(checkedNonSelectedFiles).filter(k => checkedNonSelectedFiles[k]).length}
          >
            <i className="fas fa-arrow-right"/>
          </Button>
          <Button
            title="Deselect"
            onClick={handleDeselect}
            disabled={!Object.keys(checkedSelectedFiles).filter(k => checkedSelectedFiles[k]).length}
          >
            <i className="fas fa-arrow-left"/>
          </Button>
        </div>
        <div className="col-files files-right">
          {renderFiles(true)}
        </div>
      </SelectingMain>
    </>
  );
};

Selecting.propTypes = {
  dispatch: PropTypes.func.isRequired,
  deliveryManager: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  onToggleOpenInstructions: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    deliveryManager: state.deliveryManager
  };
}

export default connect(
  mapStateToProps
)(Selecting);
