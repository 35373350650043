import React, {Component} from 'react';
import styled from 'styled-components';
import Spinner from 'react-spinner';

class Loader extends Component {
  render() {
    const {className} = this.props;
    return (
      <StyledLoader className={`loader-container${className ? ` ${className}` : ''}`}>
        <Spinner/>
      </StyledLoader>
    );
  }
}

const StyledLoader = styled.div`
  background-color: #ffffff;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;

  .react-spinner {
    position: relative;
    top: calc(50% - 16px);
    width: 32px;
    height: 32px;
    display: inline-block;
    animation: fade-in .3s ease-in;
  }
  
  .react-spinner_bar {
    animation: react-spinner_spin 1.2s linear infinite;
    border-radius: 5px;
    background-color: #29d;
    position: absolute;
    width: 20%;
    height: 7.8%;
    top: 39.9%;
    left: 40%;
  }
  
  @keyframes react-spinner_spin {
   0% { opacity: 1; }
   100% { opacity: 0.15; }
  }
  
  @-moz-keyframes react-spinner_spin {
   0% { opacity: 1; }
   100% { opacity: 0.15; }
  }
  
  @-webkit-keyframes react-spinner_spin {
   0% { opacity: 1; }
   100% { opacity: 0.15; }
  }

  &.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    z-index: 100000;
    display: flex;
    align-items: center;
    justify-content: center;

    .react-spinner {
      top: 0;
    }
  }
`;

export default Loader;
