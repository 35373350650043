import React, {Component} from 'react';
import {Route, Redirect} from 'react-router-dom';
import Cookies from 'js-cookie';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {savePreviousPageUrl} from '../pages/Login/actions';
import {buildRedirectUrlToLoginPage,getCurrentDomainObj} from '../utils';

const hasAccess = resourceToCheck => {
  const auth = Cookies.get('__auth', getCurrentDomainObj());
  const authIsPresent = auth && auth.length;
  return authIsPresent;
};

class PrivateRoute extends Component {
  componentWillMount() {
    const {dispatch} = this.props;
    const authIsPresent = hasAccess();
    if (!authIsPresent) {
      dispatch(savePreviousPageUrl());
    }
  }

  render() {
    const {...props} = this.props;
    const {path} = props;
    const lastView = localStorage.getItem('last_view');
    return hasAccess() ? (
      path === '/' && ['download', 'storage', 'issues'].includes(lastView) ? <Redirect to={`/${lastView}`}/> : <Route {...props}/>
    ) : (
      <Redirect to={buildRedirectUrlToLoginPage()}/>
    );
  }
}

PrivateRoute.propTypes = {
  dispatch: PropTypes.func.isRequired
};

export default connect()(PrivateRoute);
