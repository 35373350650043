import $ from "jquery";
import config from "../../../config";
import { /*showSuccess, */showError } from "../../layouts/actions";
import {getSettingsFromStorage, getOptionByValue} from "./utils";
import {getErrorMessage} from '../../utils';
import {ISSUE_FILTERS_TYPES} from './constants/filters';
import {STORAGE_MNG_ACTIONS_REQUESTORS_SHOW} from "../StorageManager/actions";

export const ISSUE_BROWSER_SET_LOADING = "ISSUE_BROWSER_SET_LOADING";
export const ISSUE_BROWSER_GET_ADDITIONAL_DATA = "ISSUE_BROWSER_GET_ADDITIONAL_DATA";
export const ISSUE_BROWSER_GET_BUG_REPORTS = "ISSUE_BROWSER_GET_BUG_REPORTS";
export const ISSUE_BROWSER_CHANGE_SELECTED_ITEM = "ISSUE_BROWSER_CHANGE_SELECTED_ITEM";
export const ISSUE_BROWSER_CHANGE_FILTER_VALUE = "ISSUE_BROWSER_CHANGE_FILTER_VALUE";
export const ISSUE_BROWSER_UPDATE_BUG_REPORT = "ISSUE_BROWSER_UPDATE_BUG_REPORT";

const setLoading = (name, loading) => ({
  type: ISSUE_BROWSER_SET_LOADING,
  name,
  loading,
});

export const getAdditionalDataAjax = () => $.ajax({
  method: "GET",
  url: `${config.apiUrl}/v1/bug_reports/additional_data`,
});

export const getAdditionalData = () => (dispatch) => {
  const loadingName = 'gettingAdditionalData'
  dispatch(setLoading(loadingName, true));

  return getAdditionalDataAjax()
    .done((res) => {
      const data = res || {};
      Object.keys(data).forEach(dk => {
        data[dk].forEach(item => {
          Object.keys(item).forEach(ik => {
            if (ik.endsWith('Name')) {
              item.value = item[ik];
            } else if (ik.endsWith('Label')) {
              item.label = item[ik];
              item.name = item[ik];
            }
          });
        });
      });
      dispatch({ type: ISSUE_BROWSER_GET_ADDITIONAL_DATA, data });
    })
    .fail((error) => {
      dispatch(
        showError(
          `An error occurred while loading the additional data. ${getErrorMessage(error)}`
        )
      );
    })
    .always(() => {
      dispatch(setLoading(loadingName, false));
    });
};

export const applyFilters = () => ({ type: ISSUE_BROWSER_GET_BUG_REPORTS });

export const getBugReports = (isInitial = false, urlFilters) => (dispatch, getState) => {
  const {additionalData: {all_statuses, priorities, categories}} = getState().issueBrowser;

  const loadingName = 'gettingBugReports'
  dispatch(setLoading(loadingName, true));

  let startFilters = null;
  if (isInitial) {
    const filtersJsonStr = getSettingsFromStorage('filters');
    if (typeof filtersJsonStr === 'string') {
      try {
        startFilters = JSON.parse(filtersJsonStr);
        if (startFilters && !Array.isArray(startFilters[ISSUE_FILTERS_TYPES.STATUS])) {
          delete startFilters[ISSUE_FILTERS_TYPES.STATUS];
        }
      } catch {}
    }
  }

  return $.ajax({
    method: "GET",
    url: `${config.apiUrl}/v1/bug_reports`,
  })
    .done((res) => {
      const reports = (res || []).map((report) => {
        const status = getOptionByValue(all_statuses, report.Status);
        report.Status    = status ? status.value : report.Status;
        report.StatusAlt = status ? status.label : report.Status;

        const priority = getOptionByValue(priorities, report.Priority);
        report.Priority    = priority ? priority.value : report.Priority;
        report.PriorityAlt = priority ? priority.label : report.Priority;

        const category = getOptionByValue(categories, report.Category);
        report.Category    = category ? category.value : report.Category;
        report.CategoryAlt = category ? category.label : report.Category;

        return {...report};
      });

      let selectedItemId = isInitial && urlFilters && +urlFilters.id ? +urlFilters.id : null;
      if (selectedItemId) {
        if (reports.find((item) => item.ReportedBugID === selectedItemId)) {
          startFilters = null;
        } else {
          selectedItemId = null;
        }
      }

      dispatch({ type: ISSUE_BROWSER_GET_BUG_REPORTS, data: reports, startFilters, selectedItemId });
      /*isInitial && dispatch(
        showSuccess(
          `Bug reports loaded successfully`,
          null,
          1500
        )
      );*/
    })
    .fail((error) => {
      dispatch(
        showError(
          `An error occurred while loading the bug reports. ${getErrorMessage(error)}`
        )
      );
    })
    .always(() => {
      dispatch(setLoading(loadingName, false));
    });
};

export const changeSelectedItem = (item) => (dispatch) => {
  dispatch({ type: ISSUE_BROWSER_CHANGE_SELECTED_ITEM, item });
};

export const changeFilterValue = (name, value) => (dispatch) => {
  let preparedValue = value;
  if (Array.isArray(value)) {
    preparedValue = value.map((item) => item.value || item);
  }
  dispatch({
    type: ISSUE_BROWSER_CHANGE_FILTER_VALUE,
    name,
    value: preparedValue,
  });
};

export const changeStatus = (reportId, status) => (dispatch) => {
  const loadingName = 'changeStatus'
  return $.ajax({
    method: "POST",
    url: `${config.apiUrl}/v1/bug_reports/report_bug/${reportId}/change_status`,
    data: {
      status: status.value
    }
  })
    .done(() => {
      dispatch({type: ISSUE_BROWSER_UPDATE_BUG_REPORT, reportId, data: {Status: status.value, StatusAlt: status.label}});
    })
    .fail((error) => {
      dispatch(
        showError(
          `Could not change status. ${getErrorMessage(error)}`
        )
      );
    })
    .always(() => {
      dispatch(setLoading(loadingName, false));
    });
};

export const addReportComment = (reportId, comment) => (dispatch) => {
  const loadingName = 'addReportComment'
  return $.ajax({
    method: "POST",
    url: `${config.apiUrl}/v1/bug_reports/report_bug/${reportId}/add_comment`,
    data: {
      comment
    }
  })
    .done((res) => {
      dispatch({type: ISSUE_BROWSER_UPDATE_BUG_REPORT, reportId, data: res});
    })
    .fail((error) => {
      dispatch(
        showError(
          `Could not add comment. ${getErrorMessage(error)}`
        )
      );
    })
    .always(() => {
      dispatch(setLoading(loadingName, false));
    });
};

export const getActionRequestors = () => (dispatch) => {
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/actions_requestors`
  })
  .done(res => {
    const actionsRequestorsMap = {};
    (res.requestors || []).forEach(r => actionsRequestorsMap[r.value] = r.name);

    dispatch({
      type: STORAGE_MNG_ACTIONS_REQUESTORS_SHOW,
      actionsRequestorsMap
    });
  })
  .fail(error => {

  });
}
