export const MAIN_ADD_ALERT = 'MAIN_ADD_ALERT';
export const MAIN_HIDE_ALERT = 'MAIN_HIDE_ALERT';
export const MAIN_HIDE_ALL_ALERTS = 'MAIN_HIDE_ALL_ALERTS';
export const MAIN_TOGGLE_REPORT_BUG_MODAL = 'MAIN_TOGGLE_REPORT_BUG_MODAL';
export const MAIN_SET_BUG_SCREENSHOT_URL = 'MAIN_SET_BUG_SCREENSHOT_URL';
export const MAIN_REPORT_BUG_MODAL_LOADING = 'MAIN_REPORT_BUG_MODAL_LOADING';
export const MAIN_REPORT_BUG_MODAL_IN_NEW_TAB_DATA = 'MAIN_REPORT_BUG_MODAL_IN_NEW_TAB_DATA';
export const MAIN_SET_REPORT_AVAILABLE = 'MAIN_SET_REPORT_AVAILABLE';
export const MAIN_SUCCESS_POPUP_TOGGLE = 'MAIN_SUCCESS_POPUP_TOGGLE';

export const showSuccess = (message, headline, timeout) => dispatch => {
  dispatch({
    type: MAIN_ADD_ALERT,
    notificationType: 'success',
    headline: headline || 'Success',
    message,
    timeout
  });
};

export const showError = (message, headline, timeout) => dispatch => {
  dispatch({
    type: MAIN_ADD_ALERT,
    notificationType: 'danger',
    headline: headline || 'Error',
    message,
    timeout
  });
};

export const showInfo = (message, headline, timeout) => dispatch => {
  dispatch({
    type: MAIN_ADD_ALERT,
    notificationType: 'info',
    headline: headline || 'Info',
    message,
    timeout
  });
};

export const showWarning = (message, headline, timeout) => dispatch => {
  dispatch({
    type: MAIN_ADD_ALERT,
    notificationType: 'warning',
    headline: headline || 'Warning',
    message,
    timeout
  });
};

export const hideNotification = notification => dispatch => {
  dispatch({type: MAIN_HIDE_ALERT, notification});
};

export const hideAllNotifications = () => ({type: MAIN_HIDE_ALL_ALERTS});

export const toggleReportBugModal = (isOpen) => ({
  type: MAIN_TOGGLE_REPORT_BUG_MODAL,
  isOpen,
});

export const setBugScreenshotUrl = (url) => ({
  type: MAIN_SET_BUG_SCREENSHOT_URL,
  url,
});

export const setReportBugModalLoading = (loading) => ({
  type: MAIN_REPORT_BUG_MODAL_LOADING,
  loading,
});

export const setReportAvailable = (available) => ({
  type: MAIN_SET_REPORT_AVAILABLE,
  available,
});

export const setReportBugModalInNewTabData = (data) => ({
  type: MAIN_REPORT_BUG_MODAL_IN_NEW_TAB_DATA,
  data,
});

export const showReportBugModalInNewTabDataIfNeeded = () => dispatch => {
  try {
    const dataReportLaterInNewTabStr = localStorage.getItem('mamReportLaterInNewTab');
    localStorage.removeItem('mamReportLaterInNewTab');
    if (dataReportLaterInNewTabStr) {
      const dataReportLaterInNewTab = JSON.parse(dataReportLaterInNewTabStr);
      const {bugScreenshotUrl, description, blockingIssue, category, reportAvailable} = dataReportLaterInNewTab;
      if (typeof bugScreenshotUrl === 'string' && bugScreenshotUrl &&
          typeof description === 'string' &&
          typeof blockingIssue === 'boolean' &&
          typeof reportAvailable === 'boolean' &&
          (category === null || typeof category === 'string')
      ) {
        dispatch(toggleReportBugModal(true));
        dispatch(setBugScreenshotUrl(bugScreenshotUrl));
        dispatch(setReportAvailable(reportAvailable));
        dispatch(setReportBugModalInNewTabData({description, blockingIssue, category}));
      }
    }
  } catch {}
};

export const showSuccessPopup = (data) => ({
  type: MAIN_SUCCESS_POPUP_TOGGLE,
  isShow: true,
  ...data,
});

export const hideSuccessPopup = () => ({
  type: MAIN_SUCCESS_POPUP_TOGGLE,
  isShow: false,
});
