import React, {Component} from 'react';
import {Button, Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import {setNewFrameRate, showFrameRateModal, uncheckIngestLKFS} from "../../actions";
import RndModal from "../../../../components/modals/RndModal";

class FrameRateModal extends Component {

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(showFrameRateModal(false));
  };

  componentWillUnmount() {
    this.handleClose();
  }

  handleSetValue = (value) => {
    const {dispatch} = this.props;
    dispatch(setNewFrameRate(value));
    this.handleClose();
  }

  handleUncheckLKFS = () => {
    const {dispatch} = this.props;
    dispatch(uncheckIngestLKFS(true));
    this.handleClose();
  }

  render() {
    const {frameRate, dbFrameRate, errorType, fileFrameRates, isErrorNumberFrameRate, newFrameRate} = this.props;

    let frameRates = "";
    let uniqueFrameRates = [];
    if (errorType === 'more_diff' && fileFrameRates.length > 0) {
      uniqueFrameRates = [...new Set(fileFrameRates)];
      frameRates = uniqueFrameRates.join(", ");
    }
    const className = newFrameRate === null ? 'bg-danger' : '';
    const title = newFrameRate === null ? 'Frame Rate error' : 'Frame Rate modified by user';

    return (
      <>
        <RndModal
          show={true}
          onHide={this.handleClose}
          minHeight={300}
          backdrop={false}
          enforceFocus={false}
          bsSize="large"
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {errorType === 'success' ?
                ""
                :
                null
              }
              {!isErrorNumberFrameRate && (errorType === 'empty_fr' || errorType === 'empty_all') ?
                <p className={className} style={{padding: '15px'}}>
                  Video file/channel frame rate is UNKNOWN for this title's episode/season.
                  {newFrameRate !== null ? <>User entered {newFrameRate}.<br/></> : <></>}
                  Please, either uncheck LKFS OR enter the correct frame rate value that system should use for LKFS (and store for the future use).
                </p>
                :
                null
              }
              {!isErrorNumberFrameRate && errorType === 'one_diff' ?
                <p className={className} style={{padding: '15px'}}>
                  Video file/channel frame rate ({frameRate}) is different from the value that has been stored for this title's episode/season ({dbFrameRate}).<br/>
                  {newFrameRate !== null ? <>User entered {newFrameRate}.<br/></> : <></>}
                  Please, enter the correct frame rate value that system should use for LKFS (and store for the future use).
                </p>
                :
                null
              }
              {!isErrorNumberFrameRate && errorType === 'more_diff' ?
                <p className={className} style={{padding: '15px'}}>
                  Video file/channel frame rate ({frameRates}) are different from the value that has been stored for this title's episode/season ({dbFrameRate}).<br/>
                  {newFrameRate !== null ? <>User entered {newFrameRate}.<br/></> : <></>}
                  Please, enter the correct frame rate value that system should use for LKFS (and store for the future use).
                </p>
                :
                null
              }
              {isErrorNumberFrameRate ?
                <p className={className} style={{padding: '15px'}}>
                  Frame rate must be a number
                </p>
                :
                null
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
            {errorType === 'one_diff' ?
              <Button bsStyle="primary" onClick={() => this.handleSetValue(frameRate)}>Set {frameRate}</Button>
              : null
            }
            {
              errorType === 'more_diff' && uniqueFrameRates.length > 0 ?
                uniqueFrameRates.map(diffFrameRate => {
                  return (
                    <Button bsStyle="primary" onClick={() => this.handleSetValue(diffFrameRate)}>Set {diffFrameRate}</Button>
                  );
                })
                : null
            }
            {errorType === 'one_diff' || errorType === 'more_diff' ?
              <Button bsStyle="primary" onClick={() => this.handleSetValue(dbFrameRate)}>Set {dbFrameRate}</Button>
              : null
            }
            {
              !isErrorNumberFrameRate && errorType === 'more_diff' && uniqueFrameRates.length > 0 ?
                <Button bsStyle="primary" onClick={() => this.handleUncheckLKFS()}>Uncheck LKFS</Button>
                : null
            }
            {
              newFrameRate === null ? null :
                <Button bsStyle="primary" onClick={this.handleClose}>Leave {newFrameRate}</Button>
            }
            <Button bsStyle="default" onClick={this.handleClose}>Continue</Button>
          </Modal.Footer>
        </RndModal>
      </>
    );
  }
}

FrameRateModal.propTypes = {
  frameRate: PropTypes.string,
  dbFrameRate: PropTypes.string,
  newFrameRate: PropTypes.string,
  errorType: PropTypes.string.isRequired,
  fileFrameRates: PropTypes.object,
  isErrorNumberFrameRate: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  storageManager: PropTypes.object.isRequired
};

export default FrameRateModal;
