import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import qs from 'qs';
import PageComponent from "../../components/PageComponent";
import AppLayout from "../../layouts/AppLayout";
import {getBugReports, getAdditionalData, getActionRequestors} from "./actions";
import IssueBrowserFilters from "./components/Filters";
import GlobalStyled from "./components/Global.styled";
import IssueBrowserTable from "./components/table/Table";
import {showReportBugModalInNewTabDataIfNeeded} from '../../layouts/actions';
import MyActionsModal from "../StorageManager/components/modal/MyActionsModal";

class IssueBrowser extends PageComponent {
  componentDidMount() {
    localStorage.setItem('last_view', 'issues');

    const { dispatch, urlFilters } = this.props;
    dispatch(getAdditionalData())
      .always(() => {
        dispatch(getBugReports(true, urlFilters));
      });
    dispatch(showReportBugModalInNewTabDataIfNeeded());
    dispatch(getActionRequestors());
  }

  render() {
    const {dispatch, storageManager} = this.props;
    const {isMyActionsModalOpen} = storageManager;

    return (
      <AppLayout
        title="Issue Browser"
        className="issue-browser-page"
        filters={<IssueBrowserFilters />}
      >
        {isMyActionsModalOpen &&
          <MyActionsModal
          dispatch={dispatch}
          storageManager={storageManager}
          />
        }
        <IssueBrowserStyled>
          <IssueBrowserTable />
        </IssueBrowserStyled>
        <GlobalStyled />
      </AppLayout>
    );
  }
}

const IssueBrowserStyled = styled.div`
  padding: 6px 15px 0;
`;

function mapStateToProps(state, ownProps) {
  const { user, issueBrowser } = state;
  return {
    user,
    storageManager: state.storageManager,
    issueBrowser,
    urlFilters: qs.parse(ownProps.location.search.substr(1))
  };
}

export default connect(mapStateToProps)(withRouter(IssueBrowser));
