import React, { Component } from "react";

export default class CategoryCell extends Component {
  render() {
    const { row, className } = this.props;
    const value = row.CategoryAlt;
    const cellProps = {
      className,
    };
    return <td {...cellProps}>{value}</td>;
  }
}
