import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const FsRootCell = ({value, row, className}) => (
  <StyledCell className={className}>
    {value}
    {row.fsRootPath2 ? <div className="fs-root-2">{row.fsRootPath2}</div> : null}
  </StyledCell>
);

const StyledCell = styled.td`
  .fs-root-2 {
    opacity: 0.8;
    position: relative;
    padding-top: 14px;
    &:before {
      content: 'FS Root 2:';
      font-size: 10px;
      line-height: 11px;
      position: absolute;
      top: 6px;
      left: 0;
    }
  }
`;

FsRootCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object,
  className: PropTypes.string
};
