import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Loader from '../../../../components/Loader';
import TableRow from './TableRow';
import {HeaderRow} from '../../../../components/table/HeaderRow';
import InfoPanel from './infopanel/InfoPanel';
import {isEqualsItems} from '../../utils';
import ReportBugButton from "../../../../components/ReportBugButton";

class Table extends Component {

  headers = {
    selected: {
      className: 'without-sort',
    },
    collapsing: {
      className: 'without-sort',
    },
    WOMemfis: {
      title: 'WO#'
    },
    file_name: {
      title: 'File'
    },
    client_name: {
      title: 'Client'
    },
    file_size_bytes: {
      title: 'Size'
    },
    updated_on_sec: {
      title: 'Updated On'
    },
    status: {
      title: 'Status',
      className: 'cell--asset-status'
    },
    viewInfo: {
      className: 'without-sort'
    }
  };

  isCheckedItem = row => {
    const {selectedItems} = this.props;
    return Boolean(selectedItems.find(item => isEqualsItems(item, row)));
  };

  handleChangeCheck = (checked, row) => {
    const {onSelectRow} = this.props;
    onSelectRow(checked ? [row] : []);
  };

  render() {
    const {
      dispatch, data, fileMakerURL, minRate, maxRate, isLoading, sort, onHeaderCellClick, onClickLinkToMemfisWO,
      onClickSelectDownloadedFolder, selectedItems, notLockedRoots, actionsIsLoading, onRestartAction,
      onChangeTargetRate, onChangePassword, onChangePriority, onDeliveryMemfisWO, onSetOperationType, onRefreshDeliveryData,
      isHideCompletedLinks, getFsRoot, onMarkCompleted
    } = this.props;
    const advanced = document.location.href.indexOf('advanced=1') > 0;
    const headers = {...this.headers};
    if (!advanced) {
      delete headers.viewInfo;
    }

    const isInfoPanelCollapsed = selectedItems.length !== 1;
    return (
      <StyledTable className={isInfoPanelCollapsed ? 'info-panel-collapsed' : undefined}>
        {isLoading ?
            <div>
              <Loader/>
              <ReportBugButton isForModal className="loader-report-bug-btn"/>
            </div> : null}
        <div className="col-table">
          <table>
            <HeaderRow headers={headers} sort={sort} onHeaderCellClick={onHeaderCellClick}/>
            <tbody>
              {
                data.map((row, index) =>
                  <TableRow
                    key={index}
                    row={row}
                    onClickSelectDownloadedFolder={onClickSelectDownloadedFolder}
                    onClickLinkToMemfisWO={onClickLinkToMemfisWO}
                    fileMakerURL={fileMakerURL}
                    advanced={advanced}
                    isChecked={this.isCheckedItem}
                    onChange={this.handleChangeCheck}
                    isHideCompletedLinks={isHideCompletedLinks}
                    getFsRoot={getFsRoot}
                  />
                )
              }
            </tbody>
          </table>
        </div>
        <div className="col-info-panel">
          {
            isInfoPanelCollapsed ? null :
              <InfoPanel
                dispatch={dispatch}
                items={selectedItems}
                minRate={minRate}
                maxRate={maxRate}
                onChangeTargetRate={onChangeTargetRate}
                onChangePassword={onChangePassword}
                onChangePriority={onChangePriority}
                onMarkCompleted={onMarkCompleted}
                onDeliveryMemfisWO={onDeliveryMemfisWO}
                onSetOperationType={onSetOperationType}
                onRefreshDeliveryData={onRefreshDeliveryData}
                notLockedRoots={notLockedRoots}
                actionsIsLoading={actionsIsLoading}
                onRestartAction={onRestartAction}
              />
          }
        </div>
      </StyledTable>
    );
  }
}

const StyledTable = styled.div`
  display: flex;
  position: relative;

  .col-table {
    transition: width 0.3s ease-in-out 0s, padding 0s ease-in-out 0.3s;
    padding-right: 15px;
    width: 75%;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
  }

  .col-info-panel {
    transition: width 0.3s ease-in-out 0s, padding 0s ease-in-out 0.3s, border 0s ease-in-out 0.3s;
    padding-left: 15px;
    padding-right: 15px;
    width: 25%;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    border-left: 3px solid #ddd;
  }

  &.info-panel-collapsed {
    .col-table {
      padding: 0;
      width: 100%;
    }
    .col-info-panel {
      padding: 0;
      width: 0;
      border: 0;
    }
  }

  .react-spinner {
    top: 0;
  }

  table {
    width: 100%;
    th {
      position: sticky;
      top: 0px;
      background-color: #fff;
      border-bottom: 0.1rem solid #ddd;
      font-size: 1.4rem;
      font-weight: bold;
      text-align: left;
      margin: 0;
      padding: 0.5rem;
      box-shadow: 0 1.5px 0 #ccc;
      z-index: 1;
      &.cell--asset-status {
        padding-left: calc(0.5rem + 23px);
      }
      &.without-sort {
        pointer-events: none;
      }
      &:not(.without-sort) {
        cursor: pointer;
      }
      &.details {
        width: 80px;
      }
    }
    
    td {
      border-bottom: 0.1rem solid #ddd;
      font-size: 1.4rem;
      font-weight: normal;
      margin: 0;
      padding: 0.5rem;
      text-align: left;
      &.details {
        width: 80px;
      }
    }

    tr:last-of-type td {
      border-bottom: 0;
    }
  }
`;

Table.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  fileMakerURL: PropTypes.string,
  minRate: PropTypes.number.isRequired,
  maxRate: PropTypes.number.isRequired,
  onChangeTargetRate: PropTypes.func.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onChangePriority: PropTypes.func,
  onMarkCompleted: PropTypes.func,
  onDeliveryMemfisWO: PropTypes.func,
  onSetOperationType: PropTypes.func,
  onRefreshDeliveryData: PropTypes.func,
  isLoading: PropTypes.bool,
  onClickSelectDownloadedFolder: PropTypes.func,
  onClickLinkToMemfisWO: PropTypes.func,
  onHeaderCellClick: PropTypes.func,
  sort: PropTypes.object,
  onSelectRow: PropTypes.func.isRequired,
  selectedItems: PropTypes.array.isRequired,
  notLockedRoots: PropTypes.array,
  actionsIsLoading: PropTypes.bool.isRequired,
  onRestartAction: PropTypes.func.isRequired,
  getFsRoot: PropTypes.func.isRequired,
};

export default Table;