import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import styled from 'styled-components';
import {confirmAlert} from 'react-confirm-alert';
import RndModal from '../../../../components/modals/RndModal';

class FsRunnerModal extends Component {
  handleClose = () => {
    const {onClose} = this.props;
    onClose();
  };

  componentWillUnmount() {
    this.handleClose();
  }

  handleExecuteAction = () => {
    const {
      onExecuteAction, data: {FSRootID, runnerLabel, statusBit: {actionLabel, actionTypeForFS, actionTypeForMachine}}
    } = this.props;
    confirmAlert({
      title: 'Are You Sure?',
      message: `Are you sure you want to ${actionLabel.toLowerCase().replace('run', 'start')} ${runnerLabel}?`,
      buttons: [{
        label: 'No',
      }, {
        label: 'Yes',
        onClick: () => onExecuteAction({actionTypeForFS, actionTypeForMachine}, FSRootID, runnerLabel)
      }]
    });
  };

  render() {
    const {data: {runnerLabel, statusBit: {actionLabel, stateLabel, iconStateForFS, iconActionForFS}}} = this.props;
    return (
      <ModalStyled
        show={true}
        onHide={this.handleClose}
        backdrop="static"
        enforceFocus={false}
        bsSize="small"
      >
        <Modal.Header closeButton>
          <Modal.Title>{actionLabel.replace('Run', 'Start')} <strong>{runnerLabel}</strong></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContainerStyled className="container-fluid">
            <div className="runner-state">
              <div className="runner-icon">
                <i className={iconStateForFS}/>
              </div>
              {stateLabel.map(label =>
                <div key={label} className="runner-label">
                  {label}
                </div>
              )}
            </div>
            <div className="runner-action">
              <div
                className="runner-icon"
                onClick={this.handleExecuteAction}
                title={`Click to ${actionLabel}`}
              >
                <i className={iconActionForFS}/>
              </div>
              <div className="runner-label">
                {actionLabel}
              </div>
            </div>
          </ModalContainerStyled>
        </Modal.Body>
      </ModalStyled>
    );
  }
}

const ModalStyled = styled(RndModal)`
  @media (min-width: 768px) {
    .modal-sm {
      min-width: fit-content;
      .modal-content, .react-draggable {
        min-width: fit-content !important;
      }
    }
  }
`;

const ModalContainerStyled = styled.div`
  min-height: 150px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  i {
    position: relative;

    &.icon-run {
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-left: 17px solid #000;
      border-bottom: 10px solid transparent;

      &.being-stopped:after {
        content: '';
        position: absolute;
        top: -3px;
        left: -14px;
        width: 6px;
        height: 6px;
        border: 1px solid #000;
        background: #fff;
      }
    }

    &.icon-stop {
      width: 18px;
      height: 18px;
      background-color: #000;

      &.being-started {
        &:before {
          content: '';
          position: absolute;
          top: 4px;
          left: 5px;
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-left: 8px solid #000;
          border-bottom: 5px solid transparent;
          z-index: 2;
        }
        &:after {
          content: '';
          position: absolute;
          top: 6px;
          left: 6px;
          width: 0;
          height: 0;
          border-top: 3px solid transparent;
          border-left: 5px solid #fff;
          border-bottom: 3px solid transparent;
          z-index: 3;
        }
      }
    }
  }

  .runner-state, .runner-action {
    margin: 25px 0;
    padding: 0 10px;
    flex: 0 0 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .runner-icon {
      margin-bottom: 7px;
      padding: 5px;
      width: 30px;
      height: 30px;
      border: 1px solid transparent;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .runner-label {
      color: #282828;
      font-weight: bold;
      font-size: 13px;
      text-transform: capitalize;
    }
  }

  .runner-state {
    i {
      &.icon-run {
        border-left-color: #757575;
      }
      &.icon-stop {
        background-color: #757575;
      }
    }

    .runner-label {
      color: #757575;
    }
  }

  .runner-action {
    .runner-icon {
      cursor: pointer;
      :hover {
        border-color: #ccc;
        background-color: #ececec;
      }
    }
  }
`;

FsRunnerModal.propTypes = {
  onExecuteAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

export default FsRunnerModal;
