export const DELIVERY_PAGE__EDIT = 'edit';
export const DELIVERY_PAGE__APPROVE = 'approve';
export const DELIVERY_PAGE__VIEW = 'view';
export const DELIVERY_PAGE__RENAME_ONLY = 'r';

export const DELIVERY_STEP = {
  SELECTING_FILES: 1,
  RENAMING_FILES: 2
};

export const FILE_EXTENSION__ALL = '*.*';
export const FILE_EXTENSION__ALL_OPTION = {label: FILE_EXTENSION__ALL, value: FILE_EXTENSION__ALL};

export const TAB_WO__ALL = 'all';

export const INSTRUCTIONS__GENERAL = 'Common Instructions';

export const MACROS_FIELD_TYPE = {
  TEXT: 'text',
  DATE: 'date',
  RADIO: 'radio',
  SELECT: 'select',
  COMBOBOX: 'combobox',
};
