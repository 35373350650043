import React from 'react';
import PropTypes from 'prop-types';
import {formatBytes} from '../../../../../../utils';

export const SizeCell = ({value, row, className}) => (
  <td className={className}>
    <span title={row.IsDirectory === 'false' ? value : undefined}>
      {row.IsDirectory === 'false' && value ? formatBytes(value) : ''}
    </span>
  </td>
);

SizeCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object,
  className: PropTypes.string
};
