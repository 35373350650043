import styled from "styled-components";

const IssueBrowserTableStyled = styled.div`
  display: flex;
  position: relative;

  .col-table {
    transition: width 0.3s ease-in-out 0s, padding 0s ease-in-out 0.3s;
    padding-right: 15px;
    width: 75%;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    overflow-x: auto;
  }

  .col-info-panel {
    transition: width 0.3s ease-in-out 0s, margin 0s ease-in-out 0.3s, padding 0s ease-in-out 0.3s, border 0s ease-in-out 0.3s;
    padding-left: 15px;
    margin-right: -15px;
    width: calc(25% + 15px);
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    position: relative;
  }

  &.info-panel-collapsed {
    .col-table {
      padding: 0;
      width: 100%;
    }
    .col-info-panel {
      padding: 0;
      width: 0;
      border: 0;
    }
  }

  .react-spinner {
    top: 0;
  }

  .table {
    table-layout: fixed;
    border-collapse: separate;
    width: 100%;
    margin: 0;

    > tfoot > tr > td {
      position: sticky;
      bottom: 0;
      border-top: 0;
      background-color: #fff;
    }

    > thead > tr > th {
      position: sticky;
      top: 0px;
      background-color: #fff;
      border-bottom: 0.1rem solid #ddd;
      font-size: 1.2rem;
      font-weight: bold;
      text-align: left;
      margin: 0;
      padding: 0.5rem;
      width: 100px;
      box-shadow: 0 1.5px 0 #ccc;
      white-space: nowrap;
      z-index: 1;
      &.without-sort {
        pointer-events: none;
      }
      &:not(.without-sort) {
        cursor: pointer;
      }
      &.checkbox-cell {
        width: 30px;
      }
      &.bug-id {
        width: 50px;
      }
      &.description {
        width: 20%;
      }
      &.url {
        width: 15%;
      }
      &.filename {
        width: 25%;
      }
      &.comments {
        width: 120px;
      }
    }

    > tbody > tr {
      > td {
        vertical-align: middle !important;
        border-bottom: 0.1rem solid #ddd;
        font-size: 1.2rem;
        font-weight: normal;
        margin: 0;
        padding: 0.5rem;
        text-align: left;
        &.data-table-empty {
          padding: 10px;
          text-align: center;
          opacity: 0.65;
        }
      }
      :first-of-type {
        > td {
          border-top: 0;
        }
      }
    }

    tr:last-of-type td {
      border-bottom: 0;
    }
  }
`;

export default IssueBrowserTableStyled;
