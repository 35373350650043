import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Loader from '../../components/Loader';
import Filter from './Filter';
import styled from "styled-components";
import ReportBugButton from "../ReportBugButton";

class TableFilters extends Component {
  getFilterOptions(filter) {
    const remainedFilters = this.props.filters;
    remainedFilters.push(filter);
    return remainedFilters && remainedFilters.map(item => {
      return {
        label: item.title,
        value: item.name,
        isOrderedValues: item.isOrderedValues,
        type: item.type,
        multi: item.multi,
        disabled: item.name === filter.name
      };
    });
  }

  render() {
    const {loading, coloredFilterName, filters, onChangeFilters} = this.props;

    return (
      <StyledFilter>
        {loading ?
            <div>
              <Loader/>
              <ReportBugButton isForModal className="loader-report-bug-btn"/>
            </div>: null}
        {filters.length ? filters.map((filter, i) => {
          return (
            <Filter
              key={i}
              coloredFilterName={coloredFilterName}
              filter={filter}
              value={filter.value}
              onChangeFilters={onChangeFilters}
              isOpen={true}
            />
          );
        }) : null}
      </StyledFilter>
    );
  }
}

const StyledFilter = styled.div`
  

  .loader-container {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    z-index: 3;

    .react-spinner {
      top: 49%;
    }
  }

  @media (max-width: 991px) {
    .requests-content & {
      margin-top: 0;
    }
  }
`;

TableFilters.propTypes = {
  coloredFilterName: PropTypes.string,
  filters: PropTypes.array.isRequired,
  onChangeFilters: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default TableFilters;
