import React, {Component} from 'react';
import PropTypes from "prop-types";
import {showAlertModal} from "../../actions";
import {Button, Modal} from "react-bootstrap";
import RndModal from "../../../../components/modals/RndModal";

class AlertModal extends Component {
  handleClose = () => {
    const {dispatch, closeModal} = this.props;
    dispatch(showAlertModal(false));
    if (closeModal) {
      closeModal(false);
    }
  }

  componentWillUnmount() {
    this.handleClose();
  }

  render() {
    const { type, message } = this.props;

    let title = "";
    let color = "";
    if (type === "error") {
      title = "Error";
      color = "#ff0000";
    } else if (type === "warning") {
      title = "Warning";
      color = "#ffe000";
    }

    return (
      <RndModal
        show={true}
        onHide={this.handleClose}
        minHeight={150}
        backdrop={false}
        enforceFocus={false}
        //bsSize="large"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span style={{color: color}}>
            {message}
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="default" onClick={this.handleClose}>Close</Button>
        </Modal.Footer>
      </RndModal>
    );
  }
}

AlertModal.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  storageManager: PropTypes.object.isRequired,
  closeModal: PropTypes.func
};

export default AlertModal;
