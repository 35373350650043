import {
  EXPLORER__LOAD_SUBFOLDERS,
  EXPLORER__SET_EXPANDED,
  EXPLORER__SWITCH_CHECKED
} from "./actions";
import {produce} from "immer";
import {STORAGE_MNG_LKFS_GOTO_SCREEN, STORAGE_MNG_ADD_FILE_TO_WO_GOTO_SCREEN} from "../../pages/StorageManager/actions";

const initialState = {
  folders: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EXPLORER__LOAD_SUBFOLDERS: {
      const {
        folder,
        children,
      } = action;

      return produce(state, (draft) => {
        children.forEach((child) => {
          const isChildInTree = draft.folders.find((folder) => folder.key === child.key);
          if(!isChildInTree) {
            draft.folders.push(child);
          }
        });
        const folder3 = draft.folders.find((folder2) => {
          return folder2.id === folder.id && folder2.contentID === folder.contentID
        });
        if (folder3) {
          folder3.areChildrenInitialized = true;
          folder3.isExpanded = true;
        } else {
          console.warn('Expanded folder not found');
        }
      });
    }

    case EXPLORER__SET_EXPANDED: {
      const { folder, isExpanded } = action;
      return produce(state, (draft) => {
        const folder3 = draft.folders.find((folder2) => {
          return folder2.id === folder.id && folder2.contentID === folder.contentID
        });
        if (folder3) {
          folder3.isExpanded = isExpanded;
        } else {
          console.warn('Expanded folder not found');
        }
      });
    }

    case EXPLORER__SWITCH_CHECKED: {
      const { folder } = action;
      return produce(state, (draft) => {
        const folder3 = draft.folders.find((folder2) => {
          return folder2.id === folder.id && folder2.contentID === folder.contentID
        });
        if (folder3) {
          if (folder3.isChecked) {
            folder3.isChecked = false;
          } else {
            draft.folders.map((folder) => folder.isChecked = false);
            folder3.isChecked = true;
          }
        } else {
          console.warn('Expanded folder not found');
        }
      });
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_GOTO_SCREEN:
    case STORAGE_MNG_LKFS_GOTO_SCREEN: {
      const {newScreen, fsRoots} = action;
      return produce(state, (draft) => {
        if (newScreen === 'isSelectingDestination') {
          draft.folders = JSON.parse(JSON.stringify(fsRoots));
        }
      });
    }

    default: {
      return state;
    }
  }
}