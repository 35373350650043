import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, FormGroup, FormControl, ControlLabel} from 'react-bootstrap';
import styled from 'styled-components';
import {
  moveFsItem, checkNeedToMarkWoAsDownloadedAndMovedInMemfis, showMoveModal, setFoldersInModal, multipleMoveFsItems,
  migrateFsItems
} from '../../actions';
import {PathFieldStyledAdv, CheckboxStyled} from './ModalStyled';
import {isValidFileName} from '../../../../utils';
import TextEllipsisAdvanced from '../../../../components/TextEllipsis/TextEllipsisAdvanced';
import FolderExplorerNew, {isEqualsItemsByKey} from '../forms/FolderExplorerNew';
import Wizard from '../../../../components/Wizard/Wizard';
import SchedulerForm from '../../../../components/SchedulerForm/SchedulerForm';
import {
  FsRootFlags, FS_ACTION_TYPE__MIGRATE_PORTAL, FS_ACTION_TYPE__MULTIPLE_MOVE, FS_ACTION_TYPE__MOVE
} from '../../constants';
import {getValueForOS, preparePathForOsWithoutRoot} from '../../utils';
import {showError} from '../../../../layouts/actions';

const Steps = {
  FIRST: 'Start',
  BROWSE_FOR_DESTINATION: 'Browse for destination',
  RENAME: 'Rename', // for single move only
  CONFIRM: 'Confirm'
};

class MoveModal extends Component {
  state = {
    selectedFolder: null,
    isShowCheckbox: null,
    woMemfis: null,
    markWO: null,
    isScheduledFor: null,
    dateScheduledFor: null,
    isScheduledPauseTime: null,
    scheduledPauseTime: null,
    isDailyRepeat: false,
    inputValue: this.props.items[0].name,
    isScrollToElmClass: null,
    afterFirstStep: false,
  };

  componentDidMount() {
    const {dispatch, folders, items, allowedActions} = this.props;
    const {officeFlag, fsRootID} = items[0];
    const foldersForMigrate =
      !allowedActions.includes(FS_ACTION_TYPE__MIGRATE_PORTAL) ? [] :
        folders.filter(i => {
          const isReadonlyRoot = (i.fsRootFlags & FsRootFlags.READ_ONLY) === FsRootFlags.READ_ONLY;
          return i.fsRootID !== fsRootID
            && i.officeFlag === officeFlag
            && !isReadonlyRoot;
        });
    const foldersForMove =
      !allowedActions.includes(FS_ACTION_TYPE__MOVE) && !allowedActions.includes(FS_ACTION_TYPE__MULTIPLE_MOVE) ? [] :
        folders.filter(i => i.fsRootID === fsRootID);
    dispatch(setFoldersInModal([...foldersForMove, ...foldersForMigrate]));
  }

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(showMoveModal(false));
  };

  isFolderToMove = () => {
    const {items} = this.props;
    const {selectedFolder} = this.state;
    return !!selectedFolder && selectedFolder.fsRootID === items[0].fsRootID;
  };

  isFolderToMigrate = () => {
    const {items} = this.props;
    const {selectedFolder} = this.state;
    return !!selectedFolder && selectedFolder.fsRootID !== items[0].fsRootID;
  };

  isSingleMove = () => {
    const {allowedActions} = this.props;
    return allowedActions.includes(FS_ACTION_TYPE__MOVE) && (allowedActions.length === 1 || this.isFolderToMove());
  };

  isMultipleMove = () => {
    const {allowedActions} = this.props;
    return allowedActions.includes(FS_ACTION_TYPE__MULTIPLE_MOVE) && (allowedActions.length === 1 || this.isFolderToMove());
  };

  isMigrate = () => {
    const {allowedActions} = this.props;
    return allowedActions.includes(FS_ACTION_TYPE__MIGRATE_PORTAL) && (allowedActions.length === 1 || this.isFolderToMigrate());
  };

  isInvalidName = () => {
    const {inputValue} = this.state;
    return inputValue !== this.props.items[0].name && !isValidFileName(inputValue);
  };

  handleChangeName = event => {
    this.setState({inputValue: event.target.value});
  };

  handleSelectFolder = selectedFolder => {
    this.setState(
      {
        selectedFolder,
        isShowCheckbox: null,
        woMemfis: null,
        markWO: null
      }
    )
  };

  isMoveWithRename = () => {
    const {items} = this.props;
    const {inputValue} = this.state;
    return this.isSingleMove() && inputValue !== items[0].name;
  }

  buildDestPath = folder => {
    const {selectedFolder} = this.state;
    return (folder || selectedFolder || {}).key;
  };

  buildNewPathForSingleMove = folder => {
    const {inputValue} = this.state;
    return `${this.buildDestPath(folder)}\\${inputValue}`;
  };

  handleConfirmSingleMove = () => {
    const {dispatch} = this.props;
    const {markWO} = this.state;
    const path = this.buildNewPathForSingleMove();
    dispatch(moveFsItem(path, markWO)).then(() => this.handleClose());
  };

  handleConfirmMultipleMove = () => {
    const {dispatch} = this.props;
    const {markWO} = this.state;
    const path = this.buildDestPath();
    dispatch(multipleMoveFsItems(path, markWO)).then(() => this.handleClose());
  };

  handleConfirmMigrate = () => {
    const {dispatch} = this.props;
    const {selectedFolder: {key, fsRootID}, dateScheduledFor, scheduledPauseTime, isDailyRepeat} = this.state;
    let preparedScheduledDate = dateScheduledFor;
    if (preparedScheduledDate && preparedScheduledDate.getTime() < Date.now()) {
      preparedScheduledDate = new Date(Date.now() + 1000*1*60);
    }

    let preparedScheduledPauseTime = scheduledPauseTime;
    if (preparedScheduledPauseTime && preparedScheduledPauseTime.getTime() < Date.now()) {
      preparedScheduledPauseTime = new Date(Date.now() + 1000*1*60);
    }

    if (preparedScheduledDate && preparedScheduledPauseTime && preparedScheduledPauseTime.getTime() <= preparedScheduledDate.getTime()) {
      dispatch(showError(`Pause On value cannot be greater than Scheduled For`));
    } else {
      const schedulerData = {
        scheduledFor: preparedScheduledDate,
        scheduledPauseTime: preparedScheduledPauseTime,
        dailyRepeat: isDailyRepeat
      }
      dispatch(migrateFsItems(key, fsRootID, schedulerData)).then(() => this.handleClose());
    }
  };

  handleClickSelectDestination = elmClass => {
    this.setState({isScrollToElmClass: elmClass, afterFirstStep: true});
  };

  handleBackToSelectDestStep = () => {
    this.handleClickSelectDestination('selected');
  };

  handleGoToSelectDestStepFromFirstStep = () => {
    const {selectedFolder} = this.state;
    if (selectedFolder) {
      return this.handleBackToSelectDestStep();
    }
    this.handleClickSelectDestination(this.isMigrate() ? 'selection-would-be-invalid' : 'current');
  };

  renderSourceField(step) {
    const items = [...this.props.items].sort((a, b) => a.key.localeCompare(b.key));
    const limit = 3;
    return step === Steps.BROWSE_FOR_DESTINATION ? null : items.length === 1 ? (
      <PathFieldStyledAdv className="source" labelWidth={step === Steps.RENAME ? 155 : undefined}>
        <label>Source:</label>
        <TextEllipsisAdvanced className="value" data={getValueForOS(items[0], 'key')}/>
      </PathFieldStyledAdv>
    ) : (
      <CustomPathFieldStyled className="source">
        <label>Sources:</label>
        <div className="value">
          {items.map((item, index) =>
            <TextEllipsisAdvanced
              className={`source-item${index >= limit ? ' item-collapsed collapse' : ''}`}
              key={`source-item-${index}`}
              data={getValueForOS(item, 'key')}
            />
          )}
          {items.length > limit &&
            <div
              className="sources-more-less collapsed"
              data-toggle="collapse"
              data-target=".item-collapsed"
            >
              show
            </div>
          }
        </div>
      </CustomPathFieldStyled>
    );
  }

  renderDestinationField(step) {
    const {fsRoots} = this.props;
    let field = null, color, labelWidth, nameField;
    switch(step) {
      case Steps.FIRST:
        const destPath = this.buildDestPath();
        if (!destPath) {
          color = 'black';
        }
        field = (
          <div>
            <div className="left">
              <TextEllipsisAdvanced
                className="value"
                data={preparePathForOsWithoutRoot(destPath, fsRoots)}
                defaultText="[No Folder Selected]"
              />
            </div>
            <Button
              bsStyle="primary"
              onClick={() => {
                this.wizard.setActiveStepKey(Steps.BROWSE_FOR_DESTINATION);
                this.handleGoToSelectDestStepFromFirstStep();
              }}
              title="Select Destination..."
            >
              <i className="far fa-folder-tree"/>
            </Button>
          </div>
        );
        break;
      case Steps.RENAME:
        const {items} = this.props;
        const {inputValue} = this.state;
        labelWidth = 155;
        field = (
          <div className="dest-wr">
            <div className="left">
              <TextEllipsisAdvanced
                className="value"
                data={preparePathForOsWithoutRoot(this.buildDestPath(), fsRoots)}
              />
            </div>
            <Button
              bsStyle="primary"
              onClick={() => {
                this.wizard.setActiveStepKey(Steps.BROWSE_FOR_DESTINATION);
                this.handleBackToSelectDestStep();
              }}
              title="Change Destination..."
            >
              <i className="far fa-folder-tree"/>
            </Button>
          </div>
        );
        nameField =
          <FormGroupStyled validationState={this.isInvalidName() ? 'error' : null}>
            <ControlLabel>Moved {items[0].isDirectory ? 'Folder' : 'File'} Name:</ControlLabel>
            <FormControl
              className={this.isMoveWithRename() ? '' : 'same-name'}
              value={inputValue}
              onChange={this.handleChangeName}
              onBlur={this.handleChangeName}
            />
          </FormGroupStyled>;
        break;
      case Steps.CONFIRM:
        field =
          <TextEllipsisAdvanced
            className="value"
            data={preparePathForOsWithoutRoot(this.isSingleMove() ? this.buildNewPathForSingleMove() : this.buildDestPath(), fsRoots)}
          />;
        break;
      default:
        break;
    }

    return !!field && (
      <>
        <PathFieldStyledAdv className="destination move-destination" color={color} labelWidth={labelWidth}>
          <label>Destination{nameField ? ' Folder' : ''}:</label>
          {field}
        </PathFieldStyledAdv>
        {nameField}
      </>
    );
  }

  isParentOfSimilarToMoved = (folder) => {
    const {items} = this.props;
    return ((folder && folder.children) || [])
      .some(i => items.some(
          (item) => i.name.toLowerCase() === item.name.toLowerCase()
        )
      );
  };

  hasChildWithSameNameLikeMovedItem = () => {
    const {selectedFolder} = this.state;
    return this.isParentOfSimilarToMoved(selectedFolder);
  };

  handleGoToConfirmStep = () => {
    this.setState({isScrollToElmClass: null});
    if (this.isMigrate()) {
      return;
    }

    const {dispatch} = this.props;
    const {selectedFolder, isShowCheckbox, woMemfis, markWO} = this.state;
    if (selectedFolder.contentID < 0) {
      return;
    }
    dispatch(checkNeedToMarkWoAsDownloadedAndMovedInMemfis(selectedFolder)).then(
      res => {
        const {is_show_checkbox, wo_memfis} = res;
        if (is_show_checkbox) {
          this.setState({isShowCheckbox: true, woMemfis: wo_memfis, markWO: true});
        } else {
          this.setState({isShowCheckbox: null, woMemfis: null, markWO: null});
        }
      },
      () => {
        if (isShowCheckbox || woMemfis || markWO !== null) {
          this.setState({isShowCheckbox: null, woMemfis: null, markWO: null});
        }
      }
    );
  };

  handleGoToRenameStep = () => {
    this.setState({isScrollToElmClass: null});
  };

  handleBackToRenameFromConfirmStep = () => {
    this.setState(
      {
        isScrollToElmClass: null,
        isShowCheckbox: null,
        woMemfis: null,
        markWO: null
      }
    );
  };

  handleBackToSelectDestFromConfirmStep = () => {
    this.setState(
      {
        isScrollToElmClass: 'selected',
        isShowCheckbox: null,
        woMemfis: null,
        markWO: null
      }
    );
  };

  buildSteps = () => {
    const {items} = this.props;
    const {selectedFolder, inputValue, isScheduledFor, isScheduledPauseTime, dateScheduledFor, scheduledPauseTime, isDailyRepeat} = this.state;
    return {
      [Steps.FIRST]: {
        stepName: Steps.FIRST,
        onContinue: () => this.handleGoToSelectDestStepFromFirstStep()
      },
      [Steps.BROWSE_FOR_DESTINATION]: {
        stepName: Steps.BROWSE_FOR_DESTINATION,
        onBack: () => {
          this.setState({isScrollToElmClass: null});
        },
        allowContinue: () => {
          if (!selectedFolder) {
            return false;
          }
          if (this.isSingleMove()) {
            return !(
              (selectedFolder.name === items[0].name ||/* this.hasChildWithSameNameLikeMovedItem() ||*/
              (items[0].isDirectory && isEqualsItemsByKey(selectedFolder, items[0])) ||
              (selectedFolder.key === items[0].fullPath && inputValue !== items[0].name) ||
              (/*!items[0].isDirectory && */this.buildNewPathForSingleMove() === items[0].key))
            );
          }
          if (this.isMultipleMove()) {
            return !(
              this.hasChildWithSameNameLikeMovedItem() ||
              items.some(item => item.name.toLowerCase() === selectedFolder.name.toLowerCase() ||
                                 (item.isDirectory && isEqualsItemsByKey(selectedFolder, item)))
            );
          }
          return !this.isParentOfSimilarToMoved(selectedFolder);
        },
        onContinue: () => this.isSingleMove() ? this.handleGoToRenameStep() : this.handleGoToConfirmStep()
      },
      ...(this.isSingleMove() ? {
        [Steps.RENAME]: {
          stepName: Steps.RENAME,
          onBack: () => this.handleBackToSelectDestStep(),
          allowContinue: () => {
            return !(
              this.isInvalidName() || selectedFolder.name === items[0].name ||
              (this.hasChildWithSameNameLikeMovedItem() && inputValue.toLowerCase() === items[0].name.toLowerCase())
            );
          },
          onContinue: () => this.handleGoToConfirmStep()
        },
      } : {}),
      [Steps.CONFIRM]: {
        stepName: Steps.CONFIRM,
        onBack: () => this.isSingleMove() ? this.handleBackToRenameFromConfirmStep() : this.handleBackToSelectDestStep(),
        allowContinue: () => {
          const isDailyDisabled = isDailyRepeat && scheduledPauseTime && dateScheduledFor && (dateScheduledFor.getTime() - scheduledPauseTime.getTime() > 86400000 || dateScheduledFor.getTime() - scheduledPauseTime.getTime() < -86400000)
          return !isDailyDisabled && (!isScheduledFor  || (isScheduledFor && dateScheduledFor)) &&  (!isScheduledPauseTime || (isScheduledPauseTime && scheduledPauseTime));
        },
        onContinue: () => {
          if (this.isSingleMove()) {
            this.handleConfirmSingleMove();
          } else if (this.isMultipleMove()) {
            this.handleConfirmMultipleMove();
          } else {
            this.handleConfirmMigrate();
          }
        }
      }
    };
  };

  render() {
    const {folders, currentView, openedFolders, items, tempDirectories, dispatch, loaders, storageManager, allowedActions} = this.props;
    const {isShowCheckbox, woMemfis, markWO, selectedFolder, isScrollToElmClass, afterFirstStep, isScheduledFor, isScheduledPauseTime} = this.state;
    return (
      <Wizard
        onRef={ref => {
          this.wizard = ref;
        }}
        wizardName={this.isMigrate() ? 'Migrate' : 'Move'}
        minHeight={this.wizard && this.wizard.getActiveStepKey() === Steps.CONFIRM && (isScheduledFor || isScheduledPauseTime) ? 560 : undefined}
        bsSize="large"
        className="move-modal"
        onCancel={this.handleClose}
        steps={this.buildSteps()}
        getChildren={step => (
          <>
            {this.renderSourceField(step)}
            {this.renderDestinationField(step)}
            {
              afterFirstStep &&
                <FolderExplorerNew
                  isHidden={step !== Steps.BROWSE_FOR_DESTINATION}
                  dispatch={dispatch}
                  contentItems={items}
                  loaders={loaders}
                  folders={folders}
                  openedFolders={openedFolders}
                  currentView={currentView}
                  tempDirectories={tempDirectories}
                  selectedFolder={selectedFolder}
                  onSelectFolder={this.handleSelectFolder}
                  isGetChildrenOnSelect
                  checkChildWithSameName={this.isSingleMove() || this.isMultipleMove()}
                  isParentOfSimilarToMigrated={this.isMigrate() ? f => this.isParentOfSimilarToMoved(f) : undefined}
                  isScrollToElmClass={isScrollToElmClass}
                  onScrollToItem={() => {this.setState({isScrollToElmClass: null});}}
                  storageManager={storageManager}
                />
            }
            {
              step === Steps.CONFIRM && this.isMoveWithRename() ?
                <WarningRenamed>
                  <i className="fas fa-exclamation-circle"/>The content will be renamed
                </WarningRenamed> : null
            }
            {
              step === Steps.CONFIRM && isShowCheckbox ?
                <CheckboxStyled className="checkbox">
                  <FormControl
                    id="checkbox-mark-wo"
                    name="checkbox-mark-wo"
                    type="checkbox"
                    checked={markWO}
                    onChange={event => this.setState({markWO: event.target.checked})}
                  />
                  <ControlLabel htmlFor="checkbox-mark-wo">
                    The work order{woMemfis ? ` "${woMemfis}"` : ''} will be marked as Downloaded and Moved in Memfis
                  </ControlLabel>
                </CheckboxStyled> : null
            }
            {
              step === Steps.CONFIRM ?
                <SchedulerForm
                  item={items[0]}
                  dispatch={dispatch}
                  allowedActions={allowedActions}
                  selectedFolder={selectedFolder}
                  title={this.isMigrate() ? 'Migrate' : 'Move'}
                  onChangeScheduledFor={
                    (checkScheduledFor, date) => {
                      this.setState(
                        {
                          isScheduledFor: checkScheduledFor,
                          dateScheduledFor: date
                        }
                      );
                    }
                  }
                  onChangeScheduledPauseTime={
                    (checkScheduledPauseTime, date) => {
                      this.setState(
                        {
                          isScheduledPauseTime: checkScheduledPauseTime,
                          scheduledPauseTime: date
                        }
                      );
                    }
                  }
                  onChangeDailyRepeat={
                    (checkDailyRepeat) => {
                      this.setState({isDailyRepeat: checkDailyRepeat});
                    }
                  }
                /> : null
            }
          </>
        )}
      />
    );
  }
}

const CustomPathFieldStyled = styled(PathFieldStyledAdv)`
  &.source {
    .value {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      .source-item {
        max-width: 100%;
        + .source-item {
          margin-top: 8px;
        }
        &.item-collapsed {
          + .sources-more-less {
            margin-top: 7px;
            color: #3175b0;
            font-size: 12px;
            font-weight: bold;
            cursor: pointer;
            &:hover {
              color: #275c8b;
            }
            &:after {
              content: 'more';
              padding-left: 3px;
            }
          }
          &.in {
            + .sources-more-less {
              &:after {
                content: 'less';
              }
            }
          }
          &.collapsing {
            + .sources-more-less {
              visibility: hidden;
            }
          }
        }
      }
    }
  }
`;

const FormGroupStyled = styled(FormGroup)`
  display: flex;
  align-items: center;
  .control-label {
    margin-left: 0;
    padding: 0;
    flex: 0 0 155px;
    + div, + .form-control {
      flex-grow: 1;
      max-width: calc(100% - 155px);
    }
  }
  .form-control {
    font-family: monospace;
    &.same-name:not(:focus) {
      opacity: 0.65;
    }
  }
`;

const WarningRenamed = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;

  i {
    font-size: 22px;
    color: red;
    margin-right: 5px;
  }
`;

MoveModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  allowedActions: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  loaders: PropTypes.object.isRequired,
  fsRoots: PropTypes.array.isRequired,
  folders: PropTypes.array.isRequired,
  openedFolders: PropTypes.object.isRequired,
  currentView: PropTypes.any.isRequired,
  tempDirectories: PropTypes.any.isRequired,
  storageManager: PropTypes.object.isRequired
};

export default MoveModal;
