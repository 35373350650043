import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {Button} from "react-bootstrap";
import {
  selectCheckedItemsContentIDs,
  selectIsSaveEnabled,
  selectIsCurrentDestinationValid,
  selectSharedFSRoot, selectCheckedItemContentID
} from '../../selectors';
import {FS_ACTION_TYPE__LKFS_ANALYSIS_PORTAL} from '../../constants';
import {
  selectMemfisWoLKFS,
  sendLKFSRequest,
  STORAGE_MNG_LKFS_AUTO_CHOOSE_DESTINATION,
  STORAGE_MNG_LKFS_AUTO_UPDATE_AMB,
  STORAGE_MNG_LKFS_CHOOSE_DESTINATION,
  STORAGE_MNG_LKFS_GOTO_SCREEN,
  STORAGE_MNG_LKFS_RESET,
  STORAGE_MNG_LKFS_SWITCH_MODAL,
  STORAGE_MNG_LKFS_UPDATE_AMB,
  STORAGE_MNG_LKFS_UPDATE_WO_MEMFIS,
  onSubmitDestination,
  getFileFrameRate,
  showFrameRateModal,
  setNewFrameRate,
  setFrameRate,
  setErrorNumberFrameRateValue,
} from "../../actions";
import Loader from "../../../../components/Loader";
import FolderExplorerNew from '../forms/FolderExplorerNew';
import WOChooser from "../../../../components/WOChooser/WOChooser";
import TextEllipsisAdvanced from '../../../../components/TextEllipsis/TextEllipsisAdvanced';
import Wizard from '../../../../components/Wizard/Wizard';
import {getValueForOS} from '../../utils';
import ReportBugButton from "../../../../components/ReportBugButton";
import {showError} from "../../../../layouts/actions";

const onCloseModal = () => ({ type: STORAGE_MNG_LKFS_SWITCH_MODAL, isShow: false });
const onGotoScreen = (newScreen, fsRoots) => ({ type: STORAGE_MNG_LKFS_GOTO_SCREEN, newScreen, fsRoots });

const CustomPathFieldStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  > label, > span {
    margin: 0 16px 0 0;
    padding: 0;
    flex: 0 0 140px;
    color: #282828;
    font-size: 14px;
    font-weight: bold;
    text-align: left;

    + div:not(.checkbox) {
      max-width: calc(100% - 140px - 16px);
      flex-grow: 1;
      &.destination-wrapper {
        display: flex;
        align-items: center;
        .destination-value {
          flex-grow: 1;
          max-width: calc(100% - 42px/*button width*/ - 16px);
        }
        &.with-reset-btn {
          .destination-value {
            max-width: calc(100% - 42px/*button width*/ - 16px - 41px/*button width*/ - 16px);
          }
        }
      }
    }
  }

  .checkbox-label {
    input[type="checkbox"] {
      margin: 0 7px 0 0;
    }
  }
  
  .content {
    .error, .error:focus, .error:hover {
      border: 2px solid red;
    }
    
    p.bg-danger {
      max-width: 200px;
      padding: 4px;
      margin-top: 1px;
      position: absolute;
      margin-left: -4px;
    }
  }
  
  .fr-determ {
    width: 100%;
    padding: 5px;
  }
`;

/*#region InitialScreen */
const InitialLayoutStyled = styled.div`
  .amb-settings {
    label {
      margin: 5px 5px 5px 0;
      padding: 15px 15px 15px 0;
      > input[type=checkbox] {
        margin-right: 10px;
      }
    }
  }

  button {
    margin-left: 16px;
  }
  .select-destination, .destination-reset {
    max-height:34px;
  }
`;

function InitialScreen(props) {
  const {
    AMBCurrent,
    WOMemfis,
    destination,
    fsRoots,
    initialDestination,
    isAutoWO,
    onGotoScreen,
    openFrameRateModal,
    onUpdateAMB,
    onReset,
    frameRate,
    errorType,
    setNewFrameRate,
    newFrameRate,
    isErrorNumberFrameRate,
    errorQcProject,
    isEnable
  } = props;
  //let str = Number(AMBCurrent).toString(2);
  //if (str.length === 1) str = '0' + str;
  const checked1 = Boolean(Number(AMBCurrent) & 1);
  const checked3 = Boolean(Number(AMBCurrent) & 2);
  const checked4 = Boolean(Number(AMBCurrent) & 8192);
  const className = (errorType !== null && errorType !== "success") && newFrameRate === null || isErrorNumberFrameRate ? 'error' : '';
  const btnClassName = (errorType !== null && errorType !== "success") && newFrameRate === null || isErrorNumberFrameRate ? 'btn-danger' : 'bg-info';

  return <InitialLayoutStyled>
    <CustomPathFieldStyled>
      <span className='label'>WO#</span>
      <div className='content'>
        <input type='text' disabled={true} value={WOMemfis} />
        {
          isAutoWO ? null : <Button
            bsStyle='primary'
            onClick={() => onGotoScreen('isSelectingWOMemfis')}>
            Select WOMemfis
          </Button>
        }
      </div>
    </CustomPathFieldStyled>
    <CustomPathFieldStyled>
      <span className='label'>Destination</span>
      <div className='content destination-wrapper with-reset-btn'>
        <TextEllipsisAdvanced
          className="destination-value"
          data={getValueForOS(destination, 'key')}
          defaultText="[No Folder Selected]"
        />
        <Button
          bsStyle='primary'
          className='select-destination'
          title="Select Destination"
          onClick={() => onGotoScreen('isSelectingDestination', fsRoots)}><i className="far fa-folder-tree"/></Button>
          <Button
            className='destination-reset'
            disabled={initialDestination.key === destination.key}
            title="Reset"
            onClick={() => onReset('destination')}>
            <i className="fa-light fa-folder-xmark"/>
          </Button>
      </div>
    </CustomPathFieldStyled>
    <CustomPathFieldStyled>
      <span className='label'>AMB Settings</span>
      <div className='content amb-settings'>
        <label>
          <input type='checkbox'
                 id='amb_1'
                 name='amb_1'
                 checked={checked1}
                 onChange={() => onUpdateAMB(!checked1, checked3, checked4)}
          />
          1770_1
        </label>
        <label>
          <input type='checkbox'
                 id='amb_3'
                 name='amb_3'
                 checked={checked3}
                 onChange={() => onUpdateAMB(checked1, !checked3, checked4)}
          />
          1770_3
        </label>
        <label>
          <input type='checkbox'
                 id='amb_4'
                 name='amb_4'
                 checked={checked4}
                 onChange={() => onUpdateAMB(checked1, checked3, !checked4)}
          />
          1770_4
        </label>
      </div>
    </CustomPathFieldStyled>
    {isEnable ?
      <CustomPathFieldStyled>
        <span className='label'>Frame Rate</span>
        <div className='content'>
          <input
            type='text'
            className={className}
            value={newFrameRate !== null ? newFrameRate : frameRate}
            onChange={(e) => setNewFrameRate(e.target.value)}
          />
          &nbsp;
          {errorType !== null && errorType !== "success" ?
            <a
              className={'btn ' + btnClassName}
              onClick={() => openFrameRateModal()}
              title="See error details"
            >
              explain
            </a> : null
          }
          {isErrorNumberFrameRate && !errorQcProject ?
            <p className="bg-danger">Frame rate must be a number</p>
            : null
          }
        </div>
      </CustomPathFieldStyled> : null
    }
    { errorQcProject ?
      <CustomPathFieldStyled>
        <p className="bg-danger fr-determ">Frame rate can not be determined</p>
      </CustomPathFieldStyled> : null
    }
  </InitialLayoutStyled>;
}

const InitialScreenConnected = connect(
  function mapState(state) {
    const {fsRoots} = state.storageManager;
    const {
      AMBCurrent,
      AMBInitial,
      currentDestination,
      initialDestination,
      currentWOMemfis,
      isAutoWO,
      frameRate,
      dbFrameRate,
      errorType,
      newFrameRate,
      isErrorNumberFrameRate,
      errorQcProject,
      isEnable
    } = state.storageManager.LKFSModalState;
    return {
      AMBCurrent,
      AMBInitial,
      WOMemfis: currentWOMemfis,
      destination: currentDestination,
      fsRoots,
      isAutoWO,
      initialDestination,
      isCurrentDestinationValid: selectIsCurrentDestinationValid(state),
      frameRate,
      dbFrameRate,
      errorType,
      newFrameRate,
      isErrorNumberFrameRate,
      errorQcProject,
      isEnable
    };
  },

  function mapDispatch(dispatch) {
    return {
      onReset: (resetField) => dispatch({ type: STORAGE_MNG_LKFS_RESET, resetField }),
      onUpdateAMB: (new1, new3, new4) => dispatch({ type: STORAGE_MNG_LKFS_UPDATE_AMB, new1, new3, new4 }),
      onGotoScreen: (type, fsRoots) => {
        dispatch(onGotoScreen(type, fsRoots));
      },
      openFrameRateModal: () => {
        dispatch(showFrameRateModal(true));
      },
      setNewFrameRate: (value) => {
        if (!/^-?(0|[1-9]\d*)(\.[0-9]{1,4})?(\/-?(0|[1-9]\d*)(\.[0-9]{1,4})?)?$/.test(value) || value === "0") {
          dispatch(setErrorNumberFrameRateValue(true));
        } else {
          dispatch(setErrorNumberFrameRateValue(false));
        }
        dispatch(setNewFrameRate(value));
      },
      onDestinationUserInput: (event, fsRoots) => {
        const roots = fsRoots.map((root) => root.fsRootName.replace(':', ''));
        const {value} = event.target;
        const fsRootUpper = value.split(':')[0].toUpperCase();
        const rootsUpper = roots.map((str) => str.toUpperCase());
        if (rootsUpper.includes(fsRootUpper)) {
          const currentFsRoot = fsRoots.find((root) => {
            const letterUpper = root.fsRootName.replace(':', '').toUpperCase();
            return fsRootUpper === letterUpper;
          });
          if (currentFsRoot) {
            dispatch({
              type: STORAGE_MNG_LKFS_CHOOSE_DESTINATION,
              folder: {
                key: value,
                fsRootID: currentFsRoot.fsRootID,
              },
            });
            return;
          }
        }
        dispatch({
          type: STORAGE_MNG_LKFS_CHOOSE_DESTINATION,
          folder: {
            key: value,
          },
        })
      }
    };
  }
)(InitialScreen);
/*#endregion */

/*#region WOMemfis */

const WOMemfisLayout = styled.div`
min-height: 300px;
`;

function SelectWOMemfisScreen(props) {
  const {
    isWOMemfisLoading,
    onSubmitWO,
    rootID,
    contentID
  } = props;
  return <WOMemfisLayout>
    { isWOMemfisLoading &&
    <div>
      <Loader/>
      <ReportBugButton isForModal className="loader-report-bug-btn"/>
    </div> }
    <WOChooser
      onChoose={(value) => onSubmitWO(value, rootID, contentID)}
      fsRootID={rootID}
    />
  </WOMemfisLayout>;
}

const SelectWOMemfisScreenConnected = connect(
  function mapState(state) {
    return {
      rootID: selectSharedFSRoot(state),
      contentID: selectCheckedItemContentID(state),
    }
  },
  function mapDispatchToProps(dispatch) {
    return {
      onSubmitWO: (value, rootID, contentID) => {
        getFileFrameRate(rootID, contentID, value, dispatch);
        selectMemfisWoLKFS(value, rootID, (response) => {
          dispatch({
            type: STORAGE_MNG_LKFS_AUTO_CHOOSE_DESTINATION,
            folder: {
              key: response.data.content.key,
              fsRootID: response.data.content.fsRootID,
            }
          });
          dispatch({
            type: STORAGE_MNG_LKFS_AUTO_UPDATE_AMB,
            numericAMB: response,
          });
        });
        dispatch({
          type: STORAGE_MNG_LKFS_UPDATE_WO_MEMFIS,
          value,
        });
      }
    }
  }
)(SelectWOMemfisScreen);

function SelectDestinationLayout(props) {
  const {dispatch, storageManager, selectedFolder, setSelectedFolder} = props;
  const {currentView, openedFoldersInModal, foldersInModal, loaders} = storageManager;
  return (
    <FolderExplorerNew
      dispatch={dispatch}
      loaders={loaders}
      folders={foldersInModal}
      openedFolders={openedFoldersInModal}
      currentView={currentView}
      selectedFolder={selectedFolder}
      onSelectFolder={setSelectedFolder}
      storageManager={storageManager}
    />
  );
}

const SelectDestinationLayoutConnected = connect(
  function mapState(state) {
    return {
      storageManager: state.storageManager,
    }
  },
)(SelectDestinationLayout);

/*#endregion */

/*#region LKFSModal */
export const StyledLkfsModal = styled(Wizard)`
.error-message {
  color: #f00;
}
.container-fluid {
  min-height: unset !important
}
.add-to-wo--first-step {
  .container-fluid {
    min-height: 130px !important;
  }
  .react-draggable {
    min-height: 300px !important;
  }
}
.lkfs-modal-large {
  /*width:90%;*/
  .modal-content {
    min-height: 50vh;
    max-height: 80vh;
    .react-draggable {
      min-height: 50vh !important;
      max-height: 80vh !important;
    }
  }
  .modal-body {
    padding: 0;
    overflow: hidden;
    > div, > .container-fluid > div {
      height: 100%;
      > div {
        height: 100%;
        .container {
          height: calc(100% - 64px);
          .requests-content {
            height: 100%;
            > div {
              height: 100%;
            }
          }
        }
      }
    }
  }
  .custom-scrollbar {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      -webkit-box-shadow: none;
      background-color: #c4c4c4;
    }
    @media (max-width: 991px) {
      height: auto;
      border-top: 0.5px solid #efefef;
    }
  }
}

`;

class LKFSModal extends React.Component {
  state = {
    selectedFolder: null,
  };

  buildSteps = () => {
    const {
      isSelectingWOMemfis,
      isSelectingDestination,
      isSaveEnabled,
      onGotoScreen,
      onSubmit,
      frameRateValue,
      submitData,
      onConfirmDestination,
    } = this.props;
    const {selectedFolder} = this.state;
    const isInitial = !isSelectingWOMemfis && !isSelectingDestination;
    return {
      '-': {
        stepName: isSelectingWOMemfis ? 'Select WO' : isSelectingDestination ? 'Select Destination' : '',
        allowBack: () => !isInitial,
        onBack: () => {
          onGotoScreen();
          return '-';
        },
        allowContinue: () => isInitial ? isSaveEnabled : !!selectedFolder,
        getLabelContinue: () => isInitial ? 'Submit' : 'Continue',
        onContinue: () => {
          if (isInitial) {
            onSubmit(submitData, frameRateValue);
          } else {
            onConfirmDestination(selectedFolder);
          }
          return '-';
        }
      }
    };
  };

  render() {
    const {
      isSelectingWOMemfis,
      isSelectingDestination,
      isSaveEnabled,
      onCloseModal,
    } = this.props;
    const {selectedFolder} = this.state;
    const isInitial = !isSelectingWOMemfis && !isSelectingDestination;
    return <StyledLkfsModal
      onRef={ref => {
        this.wizard = ref;
      }}
      wizardName="LKFS"
      bsSize="large"
      width={isSelectingWOMemfis ? '90%' : undefined}
      dialogClassName={isSelectingWOMemfis ? 'lkfs-modal-large' : 'lkfs-modal'}
      onCancel={onCloseModal}
      forceConfirmOnCancel={!isInitial || isSaveEnabled}
      steps={this.buildSteps()}
      getChildren={step => (
        isSelectingDestination ?
          <SelectDestinationLayoutConnected
            selectedFolder={selectedFolder}
            setSelectedFolder={(folder) => this.setState({selectedFolder: folder})}
          /> :
            isSelectingWOMemfis ?
              <SelectWOMemfisScreenConnected /> :
                <InitialScreenConnected />
      )}
    />;
  }
}

export default connect(
  function mapState(state) {
    const {
      AMBCurrent,
      currentDestination,
      currentWOMemfis,
      initialWOMemfis,
      isSelectingWOMemfis,
      isSelectingDestination,
      frameRate,
      dbFrameRate,
      newFrameRate,
      errorType,
      isEnable
    } = state.storageManager.LKFSModalState;
    return {
      checkedItems: state.storageManager.checkedItems,
      isSelectingWOMemfis,
      isSelectingDestination,
      isSaveEnabled: selectIsSaveEnabled(state),
      initialWOMemfis,
      frameRateValue: {
        'frameRate': frameRate,
        'dbFrameRate': dbFrameRate,
        'newFrameRate': newFrameRate,
        'errorType': errorType,
        'woMemfis': currentWOMemfis,
        'isEnable': isEnable
      },
      submitData: {
        "actionType": FS_ACTION_TYPE__LKFS_ANALYSIS_PORTAL,
        "fsRootID": selectSharedFSRoot(state),
        "fsRootID2": currentDestination.fsRootID,
        "parameters": {
          "ContentIDs": selectCheckedItemsContentIDs(state),
          "WO_MEMFIS": currentWOMemfis,
          "DESTINATION": currentDestination.key,
          "AMB_SETTINGS": AMBCurrent,
        }
      },
    };
  },
  function mapDispatch(dispatch) {
    return {
      onCloseModal: () => dispatch(onCloseModal()),
      onGotoScreen: () => dispatch(onGotoScreen()),
      onConfirmDestination: (folder) => dispatch(onSubmitDestination(folder)),
      onSubmit: (data, frameRateValue) => {
        if (frameRateValue && frameRateValue.isEnable) {
          const newFrameRate = frameRateValue.newFrameRate;
          const frameRate = frameRateValue.frameRate;
          const woMemfis = frameRateValue.woMemfis;
          const errorType = frameRateValue.errorType;
          if (errorType !== null && errorType !== "success") {
            if (newFrameRate !== null) {
              dispatch(setFrameRate(newFrameRate, true, woMemfis));
            } else if (frameRate !== null) {
              dispatch(setFrameRate(frameRate, false, woMemfis));
            } else {
              dispatch(showError(`Frame Rate not set`));
              return;
            }
          } else if (errorType === "success") {
            if (newFrameRate !== null && frameRate !== null && newFrameRate !== frameRate) {
              dispatch(setFrameRate(newFrameRate, true, woMemfis));
            } else if (newFrameRate === null && frameRate !== null) {
              dispatch(setFrameRate(frameRate, false, woMemfis));
            } else {
              dispatch(showError(`Frame Rate not set`));
              return;
            }
          }
        }
        dispatch(onCloseModal());
        dispatch(sendLKFSRequest(data));
      },
    }
  }
)(LKFSModal);
/*#endregion */
