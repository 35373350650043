import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {login, hideLoginMessage} from './actions';
import LoginLayout from '../../layouts/LoginLayout';
import LoginForm from './components/LoginForm';

class Login extends Component {
  state = {
    email: '',
    password: ''
  };

  componentWillMount() {
    const {dispatch} = this.props;
    dispatch(hideLoginMessage());
  }

  handleHideMessage = () => {
    const {dispatch} = this.props;
    dispatch(hideLoginMessage());
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleLogin = event => {
    event.preventDefault();
    if (event && typeof event.preventDefault === 'function') {
      event.preventDefault();
    }
    const {dispatch, history, user} = this.props;
    dispatch(login(history, this.state.email, this.state.password, user.previousPage));
  };

  render() {
    return (
      <LoginLayout>
        <LoginForm
          email={this.state.email}
          onChange={this.handleChange}
          onLogin={this.handleLogin}
          onHideMessage={this.handleHideMessage}
          />
      </LoginLayout>
    );
  }
}

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(
  mapStateToProps
)(withRouter(Login));
