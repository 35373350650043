import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {HeaderRow} from '../../components/table/HeaderRow';
import {Row} from '../../components/table/Row';

class Table extends Component {
  render() {
    const {
      headers, rows, sort, onHeaderCellClick, onRowClick, getRowClassNames,
      keyName, stripped, emptyText, emptyTextInCaption, isSeparatedRows, className, emptyFoot
    } = this.props;
    return (
      <table className={`table${stripped ? ' table-striped' : ''} ${className || ''}`}>
        <HeaderRow headers={headers} sort={sort} onHeaderCellClick={onHeaderCellClick}/>
        <tbody>
          {rows.map((row, i) =>
            <Row
              headers={headers}
              row={row}
              key={keyName ? `${row[keyName]}_${i}` : i}
              rowKey={keyName}
              onRowClick={onRowClick}
              getRowClassNames={getRowClassNames}
              isSeparatedRows={isSeparatedRows}
            />
          )}
          {
            !rows.length && emptyText ? (
              <tr><td className="data-table-empty" colSpan={Object.keys(headers).length}>{emptyText}</td></tr>
            ) : null
          }
        </tbody>
        {!!emptyFoot && <tfoot><tr><td colSpan={Object.keys(headers).length}/></tr></tfoot>}
        {emptyTextInCaption && <caption>{!rows.length ? emptyTextInCaption : <span>&nbsp;</span>}</caption>}
      </table>
    );
  }
}

Table.propTypes = {
  headers: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  sort: PropTypes.object,
  keyName: PropTypes.string,
  stripped: PropTypes.bool,
  emptyFoot: PropTypes.bool,
  emptyText: PropTypes.string,
  isSeparatedRows: PropTypes.bool,
  getRowClassNames: PropTypes.func,
  onHeaderCellClick: PropTypes.func,
  onRowClick: PropTypes.func,
  className: PropTypes.string
};

Table.defaultProps = {
  stripped: true
};

export default Table;

