import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Checkbox} from 'react-bootstrap';
import styled from 'styled-components';
import {showCopyToQodModal, getAvailableWoMemfises, createPendingRequestProxy, doShareQodProxy, copyFsItem,
  saveMarkedWOAndAddFileToWO, closeModalAddFileToWO
} from '../../actions';
import TextEllipsisAdvanced from '../../../../components/TextEllipsis/TextEllipsisAdvanced';
import {showError} from '../../../../layouts/actions';
import ReportBugButton from '../../../../components/ReportBugButton';
import {getValueForOS, getCopyToQodInfoList} from '../../utils';
import RndModal from '../../../../components/modals/RndModal';
import {confirmAlert} from 'react-confirm-alert';

class CopyToQodModal extends React.PureComponent {
  state = {
    isDirectUploadProxy: false,
    isMpegDashProxy: false,
    isContinueCopy: false,
    isShareProxy: false,
    checkedWorkOrders: [],
    checkedArtworkRequests: []
  }

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(showCopyToQodModal(false));
  };

  componentWillUnmount() {
    this.handleClose();
  }

  isStepForAddFileToWO = () => {
    const {addFileToWoModalState: {proxyCopyAvailable, isCreateQODProxyChecked}} = this.props;
    return proxyCopyAvailable && isCreateQODProxyChecked;
  };

  getChoosedWoMemfis = () => {
    if (this.isStepForAddFileToWO()) {
      const {addFileToWoModalState: {currentWOMemfis}} = this.props;
      return currentWOMemfis;
    }
    return null;
  };

  getCopyToQodInfoWoMemfisList = () => {
    const {copyToQodModalState, item} = this.props;
    const copyToQodInfoList = getCopyToQodInfoList(copyToQodModalState, [item]);
    return copyToQodInfoList.map(item => item.woMemfis).filter(Boolean);
  };

  componentDidMount() {
    const {dispatch, copyToQodModalState: {ownProxyName}} = this.props;

    const choosedWoMemfis = this.getChoosedWoMemfis();

    dispatch(getAvailableWoMemfises(choosedWoMemfis)).then((res) => {
      // when we from "Add Link to WO" -> do not use "Share Proxy" feature
      if (this.isStepForAddFileToWO()) {
        this.setState({isContinueCopy: true});
      }

      // if work order (for selected file) has uploaded proxy
      if (ownProxyName) {
        return;
      }

      const currentWoMemfisArr = choosedWoMemfis ? [choosedWoMemfis] : this.getCopyToQodInfoWoMemfisList();

      // only for copy process
      const currentWorkOrders = res && res.length > 0 ? res.filter(item => item.WOType !== 'ARTWORK' && currentWoMemfisArr.includes(item.WOMemfis)) : null;
      if (currentWorkOrders) {
        this.setState({checkedWorkOrders: currentWorkOrders.map(item => item.WOID)});
      }
    }).catch(e => showError(e));
  }

  handleOnChange = (woid) => {
    const {checkedWorkOrders} = this.state;
    const index = checkedWorkOrders.indexOf(woid);

    const newValue = [...checkedWorkOrders];
    if (index !== -1) {
      newValue.splice(index, 1);
    } else {
      newValue.push(woid);
    }
    this.setState({checkedWorkOrders: newValue});
  };

  handleOnChangeArtwork = (artworkRequestID) => {
    const { checkedArtworkRequests } = this.state;
    const index = checkedArtworkRequests.indexOf(artworkRequestID);

    const newValue = [...checkedArtworkRequests];
    if (index !== -1) {
      newValue.splice(index, 1);
    } else {
      newValue.push(artworkRequestID);
    }
    this.setState({checkedArtworkRequests: newValue});
  }

  handleOnShareProxy = () => {
    const {dispatch} = this.props;
    const {checkedWorkOrders} = this.state;

    dispatch(doShareQodProxy(checkedWorkOrders));
  };

  handleOnCreateProxy = () => {
    const {dispatch, copyModalData, item} = this.props;
    const {checkedWorkOrders, checkedArtworkRequests, isDirectUploadProxy, isMpegDashProxy} = this.state;
    const {destFsRootID, destination, newFileName} = copyModalData;

    dispatch(createPendingRequestProxy(checkedWorkOrders, checkedArtworkRequests, isDirectUploadProxy, isMpegDashProxy))
      .then((res) => {
        if (res && res.request_proxy_id > 0) {
          // the copied file will be uploaded to AWS S3, with "pid" parameter it will have a unique name
          const filenameForProxy = 'pid' + res.request_proxy_id + '_' + (newFileName || item.name).replace(/[.]mov$/, res.proxy_mux_required && (newFileName || item.name).indexOf("_muxed")<0 ? "_muxed.mov" : ".mov");
          dispatch(copyFsItem(true, destFsRootID, destination, filenameForProxy, true));
        } else {
          dispatch(showError('Failed create QOD proxy, we cannot start copying file'));
        }
      })
      .catch((error) => {
        dispatch(showError('Failed create QOD proxy: ' + error));
      });
  };

  handleOnSubmit = () => {
    const {dispatch} = this.props;
    const {checkedWorkOrders, isDirectUploadProxy, isMpegDashProxy} = this.state;

    dispatch(saveMarkedWOAndAddFileToWO(checkedWorkOrders, isDirectUploadProxy, isMpegDashProxy));
  };

  handleCancelAddFileToWO = () => {
    const {dispatch} = this.props;
    confirmAlert({
      childrenElement: () =>
        <h5 className="custom-msg">
          Are you sure you want to cancel "Add to WO"?
        </h5>,
      buttons: [{
        label: 'No'
      }, {
        label: 'Yes',
        onClick: () => {
          this.handleClose();
          dispatch(closeModalAddFileToWO());
        }
      }]
    });
  };

  renderUploadProxyOptions = () => {
    return (
        <div>
          <Checkbox
              checked={this.state.isDirectUploadProxy}
              onChange={({target: {checked}}) => this.setState({isDirectUploadProxy: checked, isMpegDashProxy: false})}
          >
            Direct Proxy
          </Checkbox>
          <Checkbox
              checked={this.state.isMpegDashProxy}
              onChange={({target: {checked}}) => this.setState({isMpegDashProxy: checked, isDirectUploadProxy: false})}
          >
            MPEG-DASH Proxy
          </Checkbox>
        </div>
    )
  }

  render() {
    const {item, copyToQodModalState, loaders} = this.props;
    const {availableWOmemfises, ownProxyName} = copyToQodModalState;
    const { isContinueCopy, isShareProxy, checkedWorkOrders, checkedArtworkRequests } = this.state;

    let woMemfisArr = this.getCopyToQodInfoWoMemfisList();
    if (woMemfisArr.length > 1 && availableWOmemfises) {
      woMemfisArr = woMemfisArr.filter(woMemfis => availableWOmemfises.some(item => item.WOMemfis === woMemfis));
    }

    const choosedWoMemfis = this.getChoosedWoMemfis();
    const currentWoMemfisArr = choosedWoMemfis ? [choosedWoMemfis] : woMemfisArr;

    const isStepForAddFileToWO = this.isStepForAddFileToWO();

    const ActionButton = () => {
      // if work order has proxy -> user needs to make a choice - share or re-write proxy
      if (ownProxyName && !isContinueCopy && !isShareProxy) {
        return null;
      }

      if (isStepForAddFileToWO) {
        return (
          <Button
            bsStyle="primary"
            onClick={this.handleOnSubmit}
          >
            Submit
          </Button>
        );
      } else if (isShareProxy) {
        return (
          <Button
            bsStyle="primary"
            onClick={this.handleOnShareProxy}
          >
            Share Proxy
          </Button>
        );
      } else {
        return (
          <Button
            bsStyle="primary"
            onClick={this.handleOnCreateProxy}
          >
            Start Create Proxy
          </Button>
        );
      }
    };

    return (
      <RndModal
        bsSize="large"
        className="move-modal"
        show={true}
        onHide={this.handleClose}
        backdrop="static"
        enforceFocus={false}
      >
        <Modal.Header closeButton={!isStepForAddFileToWO}>
          <Modal.Title>
            {isStepForAddFileToWO ? 'Add to WO: ' : ''}Create QOD Proxy
            <ReportBugButton isForModal={true} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContainerStyled className="container-fluid">
            <div className="source-div">
              <span className="source-label">Source: </span>
              <TextEllipsisAdvanced className="source-value" data={getValueForOS(item, 'key')}/>
            </div>
            {!loaders.getAvailableWoMemfises && <div>
              {currentWoMemfisArr.length ? 'Found WO#: ' + currentWoMemfisArr.join(', ') : 'Work Order not found'}
            </div>}
            {loaders.getAvailableWoMemfises ?
              <span>Loading...</span> :
              availableWOmemfises.length > 0 ?
                ownProxyName && !isContinueCopy && !isShareProxy ?
                  <>
                    <div>This work order already has proxy: {ownProxyName}</div>
                    <div>What do you want to do?</div>
                    <Button
                      bsStyle="primary"
                      onClick={() => this.setState({isShareProxy: true})}
                    >
                      Share this proxy with other work orders
                    </Button>
                    <span> </span>
                    <Button
                      bsStyle="primary"
                      onClick={() => this.setState({isContinueCopy: true})}
                    >
                      Continue create proxy process
                    </Button>
                  </> :
                  isShareProxy ?
                    <>
                      <div>This is work orders without proxy, select for share proxy:</div>
                      <table className="memfises-table">
                        <tbody>
                          <tr>
                            <th className="wo-table-cell">WO#</th>
                            <th className="checkbox-head-cell"></th>
                          </tr>
                          {availableWOmemfises.filter(item => !woMemfisArr.includes(item.WOMemfis) && !item.ProxyName && !item.ProxyCreatedOn).map(item => (
                            <tr key={item.WOID}>
                              <td className="wo-table-cell">{item.WOMemfis}{item.Format ? ` - ${item.Format.replaceAll('/', ' ')}` : ''}</td>
                              <td className="checkbox-table-cell">
                                <input
                                  type="checkbox"
                                  checked={ checkedWorkOrders.includes(item.WOID) }
                                  value={ item.WOID }
                                  onChange={ () => this.handleOnChange(item.WOID) }
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </> :
                    <>
                      <div>Select work orders for which we will upload a new proxy:</div>
                      <table className="memfises-table">
                        <tbody>
                          <tr>
                            <th className="wo-table-cell">WO#</th>
                            <th className="wo-table-cell">Title</th>
                            <th className="has-proxy-cell">Proxy State</th>
                            <th className="proxy-date-cell">Date</th>
                            <th className="checkbox-head-cell"></th>
                          </tr>
                          {availableWOmemfises.map(item => (
                            <tr key={item.RequestID}>
                              <td className="wo-table-cell">{item.WOMemfis}{item.Format ? ` - ${item.Format.replaceAll('/', ' ')}` : ''}</td>
                              <td className="wo-table-cell">{item.Title}{item.WOType === 'ARTWORK' ? ` - ${item.AssetName}` : ''}</td>
                              <td className="has-proxy-cell" title={ item.ProxyName }>{ item.ProxyName ? 'Created' : item.ProxyCreatedOn ? 'Pending' : 'No' }{ item.ProxyName && checkedWorkOrders.includes(item.WOID) ? <span> (the existing proxy will be overwritten)</span> : ''}</td>
                              <td>{ item.ProxyCreatedOn || '-'}</td>
                              <td className="checkbox-table-cell">
                                {
                                  item.WOType === 'ARTWORK' ? <input
                                      type="checkbox"
                                      checked={ checkedArtworkRequests.includes(item.RequestID) }
                                      value={ item.RequestID }
                                      onChange={ () => this.handleOnChangeArtwork(item.RequestID) }
                                  /> : <input
                                      type="checkbox"
                                      checked={ checkedWorkOrders.includes(item.WOID) }
                                      value={ item.WOID }
                                      onChange={ () => this.handleOnChange(item.WOID) }
                                      disabled={ currentWoMemfisArr.includes(item.WOMemfis) }
                                  />
                                }
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {this.renderUploadProxyOptions()}
                    </> :
              <span>Sorry, there is no available WO#</span>
            }
          </ModalContainerStyled>
        </Modal.Body>
        <Modal.Footer>
          {isStepForAddFileToWO ?
            <Button bsStyle="default" onClick={this.handleClose}>
              Back
            </Button> : null
          }
          <ActionButton />
          <Button bsStyle="default" onClick={isStepForAddFileToWO ? this.handleCancelAddFileToWO : this.handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </RndModal>
    );
  }
}

const ModalContainerStyled = styled.div`
  min-height: 200px;

  .source-div {
    display: flex;
  }
  > div {
    margin-bottom: 5px;
  }
  .source-label {
    flex: 0 0 65px;
  }
  .source-value {
    color: blue;
    word-break: break-word;
    max-width: calc(100% - 65px);
  }
  .memfises-table {
    border-collapse: collapse;
    width: 100%;
    display: table;
    margin-top: 5px;
    
    tr {
      td, th {
        border: 2px solid grey;
        padding: 4px 0 4px 4px;
        
        &.wo-table-cell {
          padding: 4px 0 4px 10px;
        }

        &.checkbox-head-cell {
          width: 40px;
        }

        &.checkbox-table-cell {
          text-align: center;
        }

        &.has-proxy-cell span {
          color: #f57c00;
        }
      }
    }
  }
  .loader-container {
    z-index: 1051;
  }
  .warning-renamed {
    display: flex;
    align-items: center;
    padding-bottom: 20px;

    i {
      font-size: 22px;
      color: red;
      margin-right: 5px;
    }
  }
`;

CopyToQodModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  copyModalData: PropTypes.object.isRequired,
  copyToQodModalState: PropTypes.object,
  addFileToWoModalState: PropTypes.object,
  loaders: PropTypes.object,
};

export default CopyToQodModal;
