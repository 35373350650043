import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Modal} from 'react-bootstrap';
import styled from 'styled-components';
import RndModal from '../../components/modals/RndModal';
import {hideSuccessPopup} from '../actions';
import {switchMyActionsModal} from "../../pages/StorageManager/actions";

class SuccessPopup extends Component {
  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(hideSuccessPopup());
  };

  handleClickLink = () => {
    const {dispatch} = this.props;
    dispatch(switchMyActionsModal(true));
    this.handleClose();
  }

  handleClickCheckActionStatus = () => {
    const {actionId} = this.props;
    const url = document.location.href.replace(/[?&]action_id=[0-9]+/, '').replace(/[?&]show_popup=[0-9]+/, '');
    window.open(url + (url.indexOf('?') >= 0 ? '&' : '?') + 'action_id=' + actionId + '&show_popup=1', '_blank');
  };

  getDefaultMessage = () => {
    const {actionType} = this.props;
    return <>
      {actionType.toUpperCase()}  started successfully. Click <button type="button" className="btn btn-link" onClick={this.handleClickLink}>here</button> to view progress
    </>;
  }

  render() {
    setTimeout(() => {
      this.handleClose();
    }, 10000);
    const {isShow, actionId, message, isProgress} = this.props;

    return isShow && (
      <ModalStyled
        show
        onHide={this.handleClose}
        backdrop="static"
        enforceFocus={false}
        minHeight={100}
      >
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            { message ? message : this.getDefaultMessage()}

            {
              actionId && isProgress ?
                <>
                  <br/>
                  <button type="button" className="btn btn-link" onClick={this.handleClickCheckActionStatus}>Check action status</button>
                </> : null
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </ModalStyled>
    );
  }
}

const ModalStyled = styled(RndModal)`
  --success-popup-bg-color: #FFE279;
  --success-popup-inner-border-color: #efd885;
  @media (min-width: 768px) {
    .modal-sm {
      min-width: fit-content;
      .modal-content, .react-draggable {
        min-width: fit-content !important;
      }
    }
  }
  .react-draggable {
    background-color: var(--success-popup-bg-color) !important;
  }
  .modal-header {
    padding: 7px 15px;
    border-bottom-color: var(--success-popup-inner-border-color);
  }
  .modal-body {
    padding: 7px 15px;
  }
  .modal-footer {
    padding: 10px 15px;
    border-top-color: var(--success-popup-inner-border-color);
    .btn-check-action-status {
      float: left;
    }
    .btn-report-bug-in-success-popup {
      padding: 0;
    }
  }
  .modal-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
  }
  .modal-body {
    > div {
      font-size: 13px;
      line-height: 18px;
      + div {
        margin-top: 8px;
      }
    }
  }
  button {
    padding: 0px;
    margin-bottom: 3px;
  }
`;

SuccessPopup.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isShow: PropTypes.bool.isRequired,
  actionId: PropTypes.number,
  actionType: PropTypes.string,
  message: PropTypes.string,
  isProgress: PropTypes.bool
};

function mapStateToProps(state) {
  const {isShow, actionId, actionType, message, isProgress} = state.main.successPopupState;
  return {
    isShow,
    actionId,
    actionType,
    message,
    isProgress
  };
}

export default connect(mapStateToProps)(SuccessPopup);
