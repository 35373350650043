import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button} from 'react-bootstrap';
import styled from 'styled-components';
import ReactJson from 'react-json-view';
import RndModal from '../../../../components/modals/RndModal';

class AplusSettingsModal extends Component {
  state = {
    json: null
  };

  handleChange = data => {
    this.setState({json: data.updated_src});
  };

  handleSave = () => {
    const {onSave, data} = this.props;
    const {json} = this.state;
    onSave(data.fsRootID, json);
  };

  handleClose = () => {
    const {onClose} = this.props;
    onClose();
  };

  componentWillUnmount() {
    this.handleClose();
  }

  render() {
    const {data} = this.props;
    const {json} = this.state;
    return (
      <RndModal
        show={true}
        onHide={this.handleClose}
        backdrop="static"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Aplus Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContainerStyled className="container-fluid">
            <ReactJson
              src={data.settings}
              name={false}
              enableClipboard={false}
              onEdit={this.handleChange}
              onAdd={this.handleChange}
              onDelete={this.handleChange}
            />
          </ModalContainerStyled>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.handleSave} disabled={!json}>Save</Button>
          <Button bsStyle="default" onClick={this.handleClose}>Cancel</Button>
        </Modal.Footer>
      </RndModal>
    );
  }
}

const ModalContainerStyled = styled.div`
  min-height: 200px;
  > div {
    height: calc(100vh - 220px);
    overflow-y: auto;
  }
`;

AplusSettingsModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

export default AplusSettingsModal;
