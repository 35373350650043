import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Button, FormControl} from 'react-bootstrap';
import StyledBtn from './FilterBtnStyled';

const VISIBLE_OPTIONS_LIMIT = 10;
const WRAP_MIN_ITEMS_COUNT = 100;
const SEARCH_MIN_ITEMS_COUNT = 100;

class Filter extends Component {
  state = {
    wrap: true,
    searchValue: '',
  };

  handleClick = (filterName, filterValue) => {
    const {onChangeFilters} = this.props;
    onChangeFilters(filterName, filterValue);
  };

  isSelected = item => {
    const {filter} = this.props;
    return filter.value === item.name;
  };

  isVisible = item => {
    const {searchValue} = this.state;
    return this.isSelected(item)
      || item.name.toUpperCase().includes(searchValue.toUpperCase());
  };

  onUpdateFilter = (event) => {
    const {value} = event.target;
    this.setState({
      ...this.state,
      searchValue: value,
    });
  }

  renderFilter() {
    const {wrap, searchValue} = this.state;
    const {filter} = this.props;
    if (!filter || !filter.values || !filter.values.length) {
      return null;
    }

    const values = [...filter.values];
    let nameUppercased = filter.name.split('')
    nameUppercased[0] = nameUppercased[0].toUpperCase();
    nameUppercased =nameUppercased.join('');

    return (
      <FilterStyled id={`filter-${filter.name}`}>
        <div className='filter-header'>
          <h3 className='filter-name'>{nameUppercased}</h3>
          {
            values.length > SEARCH_MIN_ITEMS_COUNT ? <FormControl
              type='search'
              onChange={this.onUpdateFilter}
              autoComplete="off"
              placeholder='Search'
            /> : null
          }
        </div>
        {
          values.filter(this.isVisible)
            .slice(0, ( (!searchValue && wrap) ? VISIBLE_OPTIONS_LIMIT : values.length ))
            .map((item, i) => <div
              key={`item_${item.name}`}
              className='btn-zone'
              onClick={() => this.handleClick(filter.name, item.name)}
            >
              <Button
                className={filter.value === item.name ? 'selected' : ''}
                title={`${item.name}`}
              >{item.name}
              </Button>
            </div>
          )
        }
        {
          (!searchValue && values.length > WRAP_MIN_ITEMS_COUNT) ? <StyledBtn
            className="show-more-options"
            onClick={() => this.setState({
              ...this.state,
              wrap: !wrap,
            })}
          >
            {wrap ? 'Show more options' : 'Show less'}
          </StyledBtn> : null
        }
      </FilterStyled>
    );
  }

  render() {
    const {filter} = this.props;

    return (
      <StyledWrapFilter className={filter.type ? `type-${filter.type}` : undefined}>
        {
          this.renderFilter()
        }
      </StyledWrapFilter>
    );
  }
}

const StyledWrapFilter = styled.div`
  @media (max-width: 991px) {
    .requests-content & {
      margin-bottom: 15px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &.type-info {
    pointer-events: none;
    opacity: 0.8;
    .Select-arrow-zone {
      visibility: hidden;
    }
  }
`;

const FilterStyled = styled.div`
  border-bottom: 1px solid #ddd;
  color: #282828;
  margin: 5px 0 12px;
  padding: 7px;
  .filter-header {
    margin: 8px 0;
    .filter-name {
      font-size:16px;
      font-weight:bold;
    }
    input[type=search] {
      width: 100%;
      font-size: 14px;
      box-shadow: none;
      
      &:focus {
        border-color: #b1d3ee;
        box-shadow: 0 0 8px rgb(102 175 233 / 60%);
      }
      &::placeholder {
        font-size: 14px;
      }
    }
  }
  .btn-zone {
    &:hover {
      background: #f3f3f3;
    }
    
    button {
      border: 2px solid transparent;
      &:focus {
        border: 2px solid black;
      }
    }
  }
  .btn {
    border: none;
    background-color: transparent;
    background-image: none;
    text-shadow: none;
    box-shadow: none;
    font-size: 12px;
    margin-bottom: 1px;
    outline: none;
    opacity: 0.7;
    padding: 7px;
    &.selected {
      background-color: #585858;
      color: #fff;
      opacity: 1;
    }

    span {
      margin-left: 10px;
    }
  }

  @media (max-width: 991px) {
    .requests-content & {
      margin: 0;
      border-bottom-color: #e0e0e0;
      background-color: #F8F8F8;
      border-top: 0.5px solid rgba(0,0,0,0.2);
    }
  }

  @media (min-width: 992px) {

  .btn {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
    .requests-content & {
      display: block !important;
      height: auto !important;
    }
  }
`;

Filter.propTypes = {
  isOpen: PropTypes.bool,
  onToggleFilterOpen: PropTypes.func,
  coloredFilterName: PropTypes.string,
  filter: PropTypes.object.isRequired,
  onChangeFilters: PropTypes.func.isRequired,
  value: PropTypes.any
};

export default Filter;

