import React from "react";
import styled from "styled-components";

export default function CharCounter({
  minCharacterCount,
  currentCharacterCount,
  valid,
}) {
  return (
    <CharCounterStyled valid={valid}>
      {currentCharacterCount < minCharacterCount
        ? `Must type at least : ${
            minCharacterCount - currentCharacterCount
          } more characters`
        : `Must type at least 0 more characters`}
    </CharCounterStyled>
  );
}

const CharCounterStyled = styled.span`
  font-size: 16px;
  color: ${(props) => (props.valid ? "#3c763d" : "#a94442")};
`;
