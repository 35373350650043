import React from 'react';
import _ from 'lodash';
import {formatBytes, isFsRootItem} from '../../utils';
import {PATH_VIEW_DOS, PATH_VIEW_MAC_OS, FsRootFlags} from './constants';

export const getPathView = () => {
  const pathViewFromLocalStorage = localStorage.getItem('storage_path_view');
  return [PATH_VIEW_DOS, PATH_VIEW_MAC_OS].includes(pathViewFromLocalStorage) ? pathViewFromLocalStorage :
    (navigator.userAgent.indexOf('Mac') !== -1 ? PATH_VIEW_MAC_OS : PATH_VIEW_DOS);
};

export const setPathView = (view) => {
  localStorage.setItem('storage_path_view', view);
};

export const isMacOsPathView = () => {
  return getPathView() === PATH_VIEW_MAC_OS;
};

export const getValueForOS = (row, name) => {
  let valueForView;
  if (isMacOsPathView() && row.macOsFor) {
    valueForView = row.macOsFor[name];
  }
  return valueForView || row[name];
};

export const getNameForOS = row => {
  const name = getValueForOS(row, 'name');
  let withOffice;
  if (isRootItem(row)) {
    const {nameWithOfficeLabel, unixNameWithOfficeLabel} = row;
    if (isMacOsPathView() && row.macOsFor) {
      withOffice = nameWithOfficeLabel;
    } else {
      withOffice = unixNameWithOfficeLabel;
    }
  }
  return [name, withOffice];
};

export const preparePathForMacOS = (fullPath, fsRoot) => {
  let preparedPath;
  const {macRootPath, fsRootName} = (fsRoot || {});
  if (macRootPath && fsRootName && fullPath) {
    try {
      preparedPath = fullPath.replace(/[\\]/g, '/');
      if (preparedPath !== '/') {
          preparedPath = preparedPath.replace(new RegExp('^' + fsRootName), macRootPath);
      }
    } catch {}
  }
  return preparedPath;
};

export const convertPathToWindows = (fullPath, fsRoots) => {
  let preparedPath;
  if (! isMacOsPathView()) {
    return fullPath;
  }
  const fsRoot = fsRoots.find(({macRootPath}) => macRootPath && fullPath.startsWith(macRootPath));
  const {macRootPath, fsRootName} = (fsRoot || {});
  if (macRootPath && fsRootName && fullPath) {
    try {
      preparedPath = fullPath.replace(new RegExp('^' + macRootPath), fsRootName);
      preparedPath = preparedPath.replace(/[/]/g, '\\');
    } catch {}
  }
  return preparedPath;
};

export const preparePathForOS = (fullPath, fsRoot) => {
  if (isMacOsPathView()) {
    return preparePathForMacOS(fullPath, fsRoot);
  }
  return fullPath;
};

export const preparePathForOsWithoutRoot = (path, fsRoots, pathBody) => {
  const pathStart = pathBody || path;
  if (isMacOsPathView() && pathStart && fsRoots) {
    const fsRoot = fsRoots.find(({fsRootName}) => fsRootName && pathStart.startsWith(fsRootName));
    const pathForMacOS = preparePathForMacOS(path, fsRoot);
    if (pathForMacOS) {
      return pathForMacOS;
    }
  }
  return path;
};

export const compileSummaryOfDeletion = checkedItems => {
  const updateSummary = (item, data, isSpace) => {
    if (!isSpace) {
      data.total++;
    } else if (item.size > 0) {
      data.size += item.size;
    }
    const itemMaxLMTS = parseInt(item.maxLMTS, 10);
    if (!isNaN(itemMaxLMTS)) {
      data.maxLMTS = (data.maxLMTS || 0) + itemMaxLMTS;
    }
  };

  const barcodedDirectories = {total: 0};
  const barcodedFiles = {total: 0};
  const barcodedSpace = {size: 0};
  const nonbarcodedSpace = {size: 0};
  for (let item of checkedItems) {
    if (item.memfisWoID) {
      if (item.isDirectory) {
        updateSummary(item, barcodedDirectories);
      } else {
        updateSummary(item, barcodedFiles);
      }
      updateSummary(item, barcodedSpace, true);
    } else {
      updateSummary(item, nonbarcodedSpace, true);
    }
  }
  return {barcodedDirectories, barcodedFiles, barcodedSpace, nonbarcodedSpace};
};

const prepareSummaryText = (label, data, isSpace) => {
  return `${isSpace ? formatBytes(data.size) : data.total} ${label}, MAX LMTS is ${data.maxLMTS !== undefined ? data.maxLMTS : 'n/a'}`;
};

export const buildSummaryInfo = summary => {
  const {barcodedDirectories, barcodedFiles, barcodedSpace, nonbarcodedSpace} = summary;
  return (
    <>
      {prepareSummaryText('barcoded directories', barcodedDirectories)}<br/>
      {prepareSummaryText('barcoded files', barcodedFiles)}<br/>
      {prepareSummaryText('barcoded space', barcodedSpace, true)}<br/>
      {prepareSummaryText('non-barcoded space', nonbarcodedSpace, true)}
    </>
  );
};

export const buildSuccessMessageDeleteWithApproval = summary => {
  return (
    <>
      Confirmation email was sent, please check your email.<br/><br/>
      Summary:<br/>
      {buildSummaryInfo(summary)}
    </>
  );
};

export const buildSuccessMessageDeleteWithApprovalFromResponse = response => {
  const {
    barcodedDirsCount = 0, barcodedFilesCount = 0, nonBarcodedDirsCount,   nonBarcodedFilesCount,
    barcodedDirSize = 0,   barcodedFileSize = 0,   nonBarcodedDirSize = 0, nonBarcodedFileSize = 0
  } = response;
  return (
    <>
      Confirmation email was sent, please check your email.<br/><br/>
      Summary:<br/>
      {prepareSummaryText('barcoded directories', {total: barcodedDirsCount})}<br/>
      {prepareSummaryText('barcoded files', {total: barcodedFilesCount})}<br/>
      {prepareSummaryText('barcoded space', {size: barcodedFileSize + barcodedDirSize}, true)}<br/>
      {!!nonBarcodedDirsCount && <>{prepareSummaryText('non-barcoded directories', {total: nonBarcodedDirsCount})}<br/></>}
      {!!nonBarcodedFilesCount && <>{prepareSummaryText('non-barcoded files', {total: nonBarcodedFilesCount})}<br/></>}
      {prepareSummaryText('non-barcoded space', {size: nonBarcodedFileSize + nonBarcodedDirSize}, true)}
    </>
  );
};

export const buildWOInfo = workOrder => {
  const {woMemfisId, title, episode, standard, format} = workOrder;
  return `${woMemfisId}${title ? ` - ${title}` : ''}${episode ? ` EP${episode}` : ''}` +
         `${standard ? ` | ${standard}` : ''}${format ? ` | ${format}` : ''}`;
};

export const isDeletedStatus = data => {
  const {status} = data;
  return status === 'DELETED';
};

const SanContentFlag = {
  IS_WORK_ORDER_ROOT: 1,
  RECONCILE_DELETED: 8
};

const hasFlag = (data, flag) => {
  const {flags} = data;
  return (flags & flag) > 0;
};

export const isWorkOrderRoot = data => {
  return hasFlag(data, SanContentFlag.IS_WORK_ORDER_ROOT);
};

export const isReconcileDeleted = data => {
  return hasFlag(data, SanContentFlag.RECONCILE_DELETED);
};

export const parseName = filename => {
  return filename.split('\\').pop().split('/').pop();
};

export const parseFileExtension = filename => {
  const temp = filename.split(/[.]/);
  return temp.length > 1 ? temp.pop() : '';
};

export const MAX_FILENAME_LENGTH_TO_UPLOAD = 218;
export const MAX_PATH_LENGTH = 1020;

export const isTooLongName = (name, isDirectory) => {
  return (isDirectory ? name : name.replace(/[.][a-zA-z]+$/, '')).length > MAX_FILENAME_LENGTH_TO_UPLOAD;
};

export const prepareDataIfTooManyFiles = data => {
  const maxLimit = 500;
  const partSize = 10;
  if (data.length > maxLimit && data.length > (partSize * 2 + 1)) {
    return [
      ...data.slice(0, partSize),
      {isEllipsis: true, parents: data[partSize].parents},
      ...data.slice(-partSize)
    ];
  }
  return data;
};

export const isRootItem = item => isFsRootItem(item);

export const isReadOnlyFsRoot = (item, fsRootLockStatuses) => {
  if (/*isRootItem(item) && */fsRootLockStatuses) {
    const lockStatusOfRoot = fsRootLockStatuses.find(r => r.FSRootID === item.fsRootID);
    if (lockStatusOfRoot) {
      const {ReadOnly} = lockStatusOfRoot;
      return !!ReadOnly;
    }
  }
  return false;
};

export const isRegularDrive = ({fsRootID}, fsRoots) => {
  const {fsRootFlags} = (fsRoots.find(r => r.fsRootID === fsRootID) || {});
  return ((fsRootFlags || 0) & (FsRootFlags.IS_ACTIVE | FsRootFlags.READ_ONLY | FsRootFlags.IS_FAKE)) === FsRootFlags.IS_ACTIVE;
};

export const isNearlineDrive = ({fsRootID}, fsRoots) => {
  const {fsRootFlags} = (fsRoots.find(r => r.fsRootID === fsRootID) || {});
  return ((fsRootFlags || 0) & FsRootFlags.DOWNLOAD_ROOT) === FsRootFlags.DOWNLOAD_ROOT;
};

export const ENC_PASSWORD_MIN_LENGTH = 10;
export const isInvalidEncryptPassword = password => {
  return (
    !password ||
    password.length < ENC_PASSWORD_MIN_LENGTH ||
    !password.match(/^[A-Za-z0-9!$^&@#*?<>~=_{}-]+$/) ||
    !password.match(/^.*[A-Za-z]+.*$/) ||
    !password.match(/^.*[0-9]+.*$/)
  );
};

export function convertSymbolicToNumeric(string) {
  function getNum(triad) {
    let i0 = triad.charAt(0) === 'r' ? 4 : 0;
    let i1 = triad.charAt(1) === 'w' ? 2 : 0;
    let i2 = triad.charAt(2) === 'x' ? 1 : 0;
    return i0 + i1 + i2;
  }
  return `${
    getNum(string.slice(1, 4))}${
    getNum(string.slice(4, 7))}${
    getNum(string.slice(7, 10))}`;
}

const isApplicableToCAPTIONS = (item, extensionsWhitelist) => {
  return getFilesFromWhiteListExtensions(item, extensionsWhitelist);
}

const isApplicableToLKFS = (item, extensionsWhitelist) => {
  return getFilesFromWhiteListExtensions(item, extensionsWhitelist);
};

const getFilesFromWhiteListExtensions = (item, extensionsWhitelist) => {
  if (item.isDirectory) {
    return false;
  } else {
    const extension = (item.name || '')
    .split('.')
    .pop();
    return extensionsWhitelist.includes(extension)
      && !item.name.startsWith('.');
  }
}

export const getUniqueNumberValues = (files, property) => _.uniq(
  files.map(i => i[property])
    .filter(i => typeof i === 'number' && !Number.isNaN(i))
);

export const areAllCheckedItemsApplicableToCAPTIONS = (checkedItems, extensionsWhitelist) => {
  const someNotApplicable = checkedItems.some((item) => !isApplicableToCAPTIONS(item, extensionsWhitelist));
  if (someNotApplicable) return false;
  return checkedItems.length === 1;
}

export const areAllCheckedItemsApplicableToLKFS = (checkedItems, extensionsWhitelist) => {
  const someNotApplicable = checkedItems.some((item) => !isApplicableToLKFS(item, extensionsWhitelist));
  if (someNotApplicable) return false;
  const uniqueFsRootIDs = getUniqueNumberValues(checkedItems, 'fsRootID');
  const uniqueMemfisWoIDs = getUniqueNumberValues(checkedItems, 'memfisWoID');
  return checkedItems.length > 0
    && uniqueFsRootIDs.length === 1
    && uniqueMemfisWoIDs.length < 2;
};

export const checkIsAllowedAction = (actionPermissions, items) => {
  const getTypeOfOneChecked = (item) => {
    if (item.isDirectory) {
      return "DIRECTORY";
    } else if (item.isRoot) {
      return "ROOT";
    } else if (item.isSymlink) {
      return "SYMLINK";
    } else {
      return "FILE";
    }
  };
  const getTypeOfChecked = (items) => {
    if (items.length === 1) {
      return [getTypeOfOneChecked(items[0])];
    }
    if (items.length > 1){
      const result = [];
      items.forEach(item => {
        const currentType = getTypeOfOneChecked(item);
        !result.includes(currentType) && result.push(currentType);
      })
      return result;
    }
    return [];
  };
  const typeOfChecked = getTypeOfChecked(items);
  if (typeOfChecked.length === 0) return false;
  let isAllow = true;
  typeOfChecked.forEach(type => {
    if(!Object.values(actionPermissions.content.type).includes(type)) {
      isAllow = false;
    }
  });

  return isAllow;
};

export const getCopyToQodInfoList = (copyToQodModalState, checkedItems) => {
  const {copyToQodInfo, copyToQodInfoList} = copyToQodModalState;
  if (copyToQodInfo && copyToQodInfo.woMemfis) {
    return [copyToQodInfo];
  }
  if (copyToQodInfoList && copyToQodInfoList.length) {
    if ((checkedItems || []).length && (checkedItems[0].fileExtensionP || '').toLowerCase() === 'mov') {
      return copyToQodInfoList.filter(Boolean);
    }
    return copyToQodInfoList.slice(0, 1).filter(Boolean);
  }
  return [];
};
