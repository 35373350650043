import DateCell from "../../../components/table/cells/DateCell";
import BugIdCell from "../components/table/cells/BugIdCell";
import DescriptionCell from "../components/table/cells/DescriptionCell";
import FileNameCell from "../components/table/cells/FileNameCell";
import PriorityCell from "../components/table/cells/PriorityCell";
import CategoryCell from "../components/table/cells/CategoryCell";
import StatusCell from "../components/table/cells/StatusCell";
import UrlCell from "../components/table/cells/UrlCell";
import CommentsCell from "../components/table/cells/CommentsCell";
import JiraUrlCell from "../components/table/cells/JiraUrlCell";
import ISSUE_TYPES from "./types";

export const ISSUE_BROWSER_TABLE_HEADERS = {
  [ISSUE_TYPES.REPORTED_BUG_ID]: {
    title: "ID",
    className: "bug-id",
    component: BugIdCell,
  },
  [ISSUE_TYPES.MESSAGE]: {
    title: "Description",
    className: "description",
    component: DescriptionCell,
  },
  [ISSUE_TYPES.REPORTED_BY]: {
    title: "Reported By",
    className: "user-name",
  },
  [ISSUE_TYPES.ASSIGNED_TO]: {
    title: "Assigned To",
    className: "user-name",
  },
  [ISSUE_TYPES.JIRA_URL]: {
    title: "Jira URL",
    className: "jira-url",
    component: JiraUrlCell,
  },
  [ISSUE_TYPES.URL]: {
    title: "URL",
    className: "url",
    component: UrlCell,
  },
  [ISSUE_TYPES.FILENAME]: {
    title: "Filename",
    className: "filename",
    component: FileNameCell,
  },
  [ISSUE_TYPES.CREATED_ON]: {
    title: "Created On",
    className: "created-on",
    component: DateCell,
    componentArgs: {
      toFormat: "M/D/YYYY hh:mm:ss A",
    },
  },
  [ISSUE_TYPES.UPDATED_ON]: {
    title: "Updated On",
    className: "updated-on",
    component: DateCell,
    componentArgs: {
      toFormat: "M/D/YYYY hh:mm:ss A",
    },
  },
  [ISSUE_TYPES.PRIORITY]: {
    title: "Priority",
    className: "priority",
    component: PriorityCell,
  },
  [ISSUE_TYPES.CATEGORY]: {
    title: "Category",
    className: "category",
    component: CategoryCell,
  },
  [ISSUE_TYPES.STATUS]: {
    title: "Status",
    className: "status",
    component: StatusCell,
  },
  [ISSUE_TYPES.COMMENTS]: {
    title: "Comments",
    className: "comments without-sort",
    component: CommentsCell,
  },
};
