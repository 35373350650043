import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {confirmAlert} from 'react-confirm-alert';
import {Modal, Button, Checkbox} from 'react-bootstrap';
import DateField from '../../../../components/filters/DateField';
import RndModal from '../../../../components/modals/RndModal';
import {setSchedulerPauseTime} from '../../actions';
import {showError} from '../../../../layouts/actions';

class ScheduledPauseModal extends Component {
  addTime = 1000*1*60;

  state = {
    removePause: false,
    title: '',
    typeName: ['COPY', 'SIMPLE_COPY'].includes(this.props.item.actionType) ? "Copy" : "Migrate",
    isGreyedOut: false,
    isResume: false,
    isPause: false,
    isAdvancedBtn: false,
    isScheduledPauseTime: this.props.item.scheduledPauseTime != null,
    scheduledPauseTime: new Date(this.props.item.scheduledPauseTime),
    isScheduledFor: this.props.item.scheduledFor != null,
    scheduledFor: new Date(this.props.item.scheduledFor),
    isDailyRepeat: !!this.props.item.dailyRepeat
  };

  generatePauseState = () => {
    const {actionStatus, scheduledPauseTime, scheduledFor, dailyRepeat} = this.props.item;
    const preparedScheduledPauseTime = scheduledPauseTime === null ? null : new Date(scheduledPauseTime);
    const preparedScheduledFor = scheduledFor === null ? null : new Date(scheduledFor);

    let title = 'Scheduled Pause Time';
    let isGreyedOut = false;
    let isResume = false;
    let isPause = false;
    let removePause = false;
    if (preparedScheduledPauseTime) { // 1
      if (actionStatus === 'ACTIVE') { // 1.1
        if (preparedScheduledPauseTime.getTime() < Date.now()) { // 1.1.1
          title = 'State is ACTIVE. Waiting for Action Script to change state to PAUSED';
          isGreyedOut = true;
        } else { // 1.1.2
          isPause = dailyRepeat;
          title = 'State is ACTIVE. Waiting until reaching Pause Time to change state to PAUSED';
        }
      } else if (actionStatus === 'PAUSED') { // 1.2
        isResume = true;
        if (preparedScheduledPauseTime.getTime() < Date.now()) { // 1.2.1
          title = 'State is PAUSED. Will stay PAUSED until user clicks "Resume"';
        } else { // 1.2.2
          title = 'State is PAUSED. Will stay PAUSED until user clicks "Resume"';
        }
      } else if (preparedScheduledFor && preparedScheduledFor.getTime() >= Date.now()) { // 1.3
        removePause = true;
        if (preparedScheduledPauseTime.getTime() < Date.now()) { // 1.3.1
          title = 'Somehow action hasn\'t started yet. It will be PAUSED after it is started';
        } else { // 1.3.2
          title = 'Action hasn\'t started yet. After it is started and time reaches PauseTime, it will be PAUSED';
        }
      }
    } else { // 2
      if (actionStatus === 'ACTIVE') { // 2.1
        isPause = true;
        title = 'State is ACTIVE. To pause, user must click "Pause"';
      } else if (actionStatus === 'PAUSED') { // 2.2
        isGreyedOut = true;
        title = 'State is PAUSED. Waiting for Action Script to change state to ACTIVE';
      } else if (preparedScheduledFor && preparedScheduledFor.getTime() >= Date.now()) { // 2.3
        title = 'Action hasn\'t started yet. There is no pause scheduled, so action will not be paused after it is started. To schedule pause, user must click "Pause"';
      }
    }

    this.setState({title, isGreyedOut, isResume, isPause, removePause});
  }

  componentDidMount() {
    this.generatePauseState();
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  handleClickConfirm = () => {
    const {removePause} = this.state;

    let comment = "You changed the pause settings. Are you sure?";
    if (removePause) {
      comment = "Are you sure you want to remove the scheduled pause?";
    }

    confirmAlert({
      title: 'Are You Sure?',
      message: comment,
      buttons: [{
        label: 'No'
      }, {
        label: 'Yes',
        onClick: () => {
          const {dispatch, onClose, refreshFilters} = this.props;
          const {isScheduledFor, isScheduledPauseTime, scheduledPauseTime, scheduledFor, isDailyRepeat} = this.state;

          let preparedScheduledFor = scheduledFor;
          if (isScheduledFor) {
            if (preparedScheduledFor && preparedScheduledFor.getTime() < Date.now()) {
              preparedScheduledFor = new Date(Date.now() + this.addTime);
            }
          }

          let preparedScheduledPauseTime = scheduledPauseTime;
          if (isScheduledPauseTime) {
            if (preparedScheduledPauseTime && preparedScheduledPauseTime.getTime() < Date.now()) {
              preparedScheduledPauseTime = new Date(Date.now() + this.addTime);
            }
          }

          if (preparedScheduledFor && preparedScheduledPauseTime && preparedScheduledPauseTime.getTime() <= preparedScheduledFor.getTime()) {
            dispatch(showError(`Pause On value cannot be greater than Scheduled For`));
          } else {
            dispatch(setSchedulerPauseTime(
              this.props.item.actionID,
              preparedScheduledPauseTime,
              preparedScheduledFor,
              isDailyRepeat,
              this.props.item.actionStatus,
              this.props.item.scheduledPauseTime,
              this.props.item.scheduledFor,
              this.props.item.dailyRepeat,
              ''
            ))
              .then(() => onClose())
              .then(() => refreshFilters())
              .catch(() => {
                onClose();
                refreshFilters();
              });
          }
        }
      }]
    });
  };

  handleClickPause = () => {
    confirmAlert({
      title: 'Are You Sure?',
      message: `You will now pause the action. Are you sure?`,
      buttons: [{
        label: 'No'
      }, {
        label: 'Yes',
        onClick: () => {
          const {dispatch, onClose, refreshFilters} = this.props;
          const {isScheduledFor, isScheduledPauseTime, scheduledPauseTime, scheduledFor, isDailyRepeat} = this.state;

          const newScheduledPauseTime = new Date(Date.now() + this.addTime);
          dispatch(setSchedulerPauseTime(
            this.props.item.actionID,
            isScheduledPauseTime ? scheduledPauseTime : newScheduledPauseTime,
            isScheduledFor ? scheduledFor : null,
            isDailyRepeat,
            this.props.item.actionStatus,
            this.props.item.scheduledPauseTime,
            this.props.item.scheduledFor,
            this.props.item.dailyRepeat,
            'pause'
          ))
            .then(() => onClose())
            .then(() => refreshFilters())
            .catch(() => {
              onClose();
              refreshFilters();
            });
        }
      }]
    });
  }

  handleClickResume = () => {
    confirmAlert({
      title: 'Are You Sure?',
      message: `You will now unpause the action. Are you sure?`,
      buttons: [{
        label: 'No'
      }, {
        label: 'Yes',
        onClick: () => {
          const {dispatch, onClose, refreshFilters} = this.props;
          const {isScheduledFor, scheduledFor, isDailyRepeat} = this.state;

          dispatch(setSchedulerPauseTime(
            this.props.item.actionID,
            null,
            isScheduledFor ? scheduledFor : null,
            isDailyRepeat,
            this.props.item.actionStatus,
            this.props.item.scheduledPauseTime,
            this.props.item.scheduledFor,
            this.props.item.dailyRepeat,
            'resume'
          ))
            .then(() => onClose())
            .then(() => refreshFilters())
            .catch(() => {
              onClose();
              refreshFilters();
            });
        }
      }]
    });
  }

  handleClickAdvanced = () => {
    const {isAdvancedBtn} = this.state;
    this.setState({isAdvancedBtn: !isAdvancedBtn});
  }

  renderModalContent = () => {
    const {dispatch} = this.props;
    const {isResume, isPause, isDailyRepeat, isGreyedOut, isAdvancedBtn, isScheduledPauseTime, isScheduledFor, scheduledFor, scheduledPauseTime} = this.state;
    const isEnabled = !isGreyedOut && isScheduledPauseTime && isScheduledFor;

    const isDefaultDisplay = !isResume && !isPause && !isAdvancedBtn;
    const isScheduledForDisplay = (!isResume && !isPause && isAdvancedBtn) || isAdvancedBtn || isDefaultDisplay;
    const isScheduledPauseTimeDisplay = (isPause && !isAdvancedBtn) || (!isResume && !isPause && isAdvancedBtn) || isAdvancedBtn || isDefaultDisplay;
    const isDailyRepeatDisplay = (!isResume && !isPause && isAdvancedBtn) || isAdvancedBtn || isDefaultDisplay;

    return (
      <>
        {
          isResume &&
            <Button
              bsStyle='primary'
              onClick={() => this.handleClickResume()}>
              Resume
            </Button>
        }
        {
          isPause &&
            <Button
              bsStyle='primary'
              onClick={() => this.handleClickPause()}>
              Pause
            </Button>
        }
        {
          (isResume || isPause) && '\u00A0'
        }
        {
          (isResume || isPause) &&
            <Button
              bsStyle='primary'
              onClick={() => this.handleClickAdvanced()}>
              { isAdvancedBtn ? 'Hide' : 'Show' } advanced
            </Button>
        }
        {isScheduledForDisplay && this.renderScheduledFor()}
        {isScheduledPauseTimeDisplay && this.renderScheduledPauseTime()}
        {isDailyRepeatDisplay &&
          <Checkbox
            checked={!!isDailyRepeat}
            onChange={
              ({target: {checked}}) => {
                if (checked && scheduledFor && scheduledPauseTime && (scheduledPauseTime.getTime() - scheduledFor.getTime() > 86400000 || scheduledPauseTime.getTime() - scheduledFor.getTime() < -86400000)) {
                  dispatch(showError(`The difference cannot be more than a day`));
                }

                this.setState({isDailyRepeat: checked});
              }
            }
            disabled={!isEnabled}
          >
            Daily Repeat
          </Checkbox>
        }
      </>
    );
  }

  renderScheduledFor = () => {
    const {dispatch} = this.props;
    const {scheduledPauseTime, isScheduledFor, scheduledFor, isGreyedOut, typeName, isDailyRepeat} = this.state;

    const date = new Date(scheduledFor);
    const dateTime = date.getTime() ? date : null;

    return (
      <>
        <Checkbox
          checked={!!isScheduledFor}
          onChange={
            ({target: {checked}}) => {
              const newIsDailyRepeat = checked ? isDailyRepeat : false
              this.setState(
                {
                  isScheduledFor: checked,
                  scheduledFor: null,
                  isDailyRepeat: newIsDailyRepeat
                });
            }
          }
          disabled={isGreyedOut}
        >
          Add Scheduled Start Time for "{typeName}" action
        </Checkbox>
        {
          !isScheduledFor || isGreyedOut ? null :
            <DateField
              id="date-schedulable-for"
              styles={{width: '190px'}}
              placeholderText=""
              selected={dateTime}
              selectsStart
              minDate={new Date()}
              startDate={scheduledFor || new Date()}
              onChange={date => {
                if (date && date.getTime() < Date.now()) {
                  date = new Date(Date.now() + this.addTime);
                }

                if (date && scheduledPauseTime && scheduledPauseTime.getTime() <= date.getTime()) {
                  dispatch(showError(`Pause On value cannot be greater than Scheduled For`));
                }

                if (isDailyRepeat && date && scheduledPauseTime && (scheduledPauseTime.getTime() - date.getTime() > 86400000 || scheduledPauseTime.getTime() - date.getTime() < -86400000)) {
                  dispatch(showError(`The difference cannot be more than a day`));
                }

                this.setState({scheduledFor: date});
              }}
            />
        }
      </>
    );
  };

  renderScheduledPauseTime = () => {
    const {dispatch} = this.props;
    const {isScheduledPauseTime, scheduledPauseTime, scheduledFor, isGreyedOut, typeName, isDailyRepeat} = this.state;

    const date = new Date(scheduledPauseTime);
    const dateTime = date.getTime() ? date : null;

    return (
      <>
        <Checkbox
          checked={!!isScheduledPauseTime}
          onChange={
            ({target: {checked}}) => {
              const newIsDailyRepeat = checked ? isDailyRepeat : false
              this.setState(
                {
                  isScheduledPauseTime: checked,
                  scheduledPauseTime: null,
                  isDailyRepeat: newIsDailyRepeat
                }
              );
            }
          }
          disabled={isGreyedOut}
        >
          Add Scheduled Pause Time for "{typeName}" action
        </Checkbox>
        {
          !isScheduledPauseTime || isGreyedOut ? null :
            <DateField
              id="scheduled-pause-time"
              styles={{width: '190px'}}
              placeholderText=""
              selected={dateTime}
              selectsStart
              minDate={new Date()}
              startDate={scheduledPauseTime || new Date()}
              onChange={date => {
                if (date && date.getTime() < Date.now()) {
                  date = new Date(Date.now() + this.addTime);
                }

                if (scheduledFor && date && date.getTime() <= scheduledFor.getTime()) {
                  dispatch(showError(`Pause On value cannot be greater than Scheduled For`));
                }

                if (isDailyRepeat && scheduledFor && date && (date.getTime() - scheduledFor.getTime() > 86400000 || date.getTime() - scheduledFor.getTime() < -86400000)) {
                  dispatch(showError(`The difference cannot be more than a day`));
                }

                this.setState({scheduledPauseTime: date});
              }}
              disabled={isGreyedOut}
            />
        }
      </>
    );
  };

  render() {
    const {onClose} = this.props;
    const {isScheduledPauseTime, scheduledPauseTime, isScheduledFor, scheduledFor, title, isResume, isPause, isGreyedOut, isDailyRepeat} = this.state;
    const width = (isResume || isPause || isGreyedOut) ? 150 : 450;

    const isDailyDisabled = isDailyRepeat && scheduledPauseTime && scheduledFor && (scheduledFor.getTime() - scheduledPauseTime.getTime() > 86400000 || scheduledFor.getTime() - scheduledPauseTime.getTime() < -86400000)
    const disabledConfirm = (isDailyDisabled || !!isScheduledPauseTime) && (scheduledPauseTime === null || !!isScheduledFor) && scheduledFor === null;

    return (
      <RndModal
        show={true}
        onHide={onClose}
        minHeight={width}
        backdrop="static"
        enforceFocus={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderModalContent()}
        </Modal.Body>
        <Modal.Footer>
          {
            isGreyedOut ? null :
            <Button
              bsStyle="primary"
              onClick={this.handleClickConfirm}
              disabled={disabledConfirm}
            >
              Confirm
            </Button>
          }
          <Button bsStyle="default" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
      </RndModal>
    );
  }
}

ScheduledPauseModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshFilters: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
};

export default ScheduledPauseModal;
