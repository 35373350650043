import {Component} from 'react';
import PropTypes from 'prop-types';
import {initAuth} from '../../app/pages/Login/actions';

class PageComponent extends Component {
  constructor(props) {
    super();
    const {dispatch, history, user} = props;
    dispatch(initAuth(history, user.isLogin));
  }
}

PageComponent.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default PageComponent;
