import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
import styled from 'styled-components';
import Filters from '../../../../components/filters/Filters';
import {renameOfContentItem, getRegexPatternsToValidateAmazonFilename} from '../../actions';
import {getValueForOS} from '../../utils';
import Loader from '../../../../components/Loader';
import TextEllipsisAdvanced from "../../../../components/TextEllipsis/TextEllipsisAdvanced";
import RndModal from '../../../../components/modals/RndModal';
import ReportBugButton from "../../../../components/ReportBugButton";

class RenameAmazonFileModal extends Component {
  fields = [{
    name: 'showCode',
    title: 'Show Code',
    type: 'string'
  }, {
    name: 'episode',
    title: 'Episode',
    type: 'string'
  }, {
    name: 'assetType',
    title: 'Asset Type',
    type: 'string'
  }, {
    name: 'assetSpec',
    title: 'Asset Spec',
    type: 'string'
  }, {
    name: 'date',
    title: 'Date',
    type: 'date-single'
  }, {
    name: 'revision',
    title: 'Revision',
    type: 'string'
  }, {
    name: 'fileExtension',
    title: 'File Extension',
    type: 'string'
  }];

  defaultRegexPatterns = {
    showCode: '^(?=[\\S])[^\\\\/:*?"<>|_]+$',
    episode: '^(?=[\\S])[^\\\\/:*?"<>|_]+$',
    assetType: '^(?=[\\S])[^\\\\/:*?"<>|_]+$',
    assetSpec: '^(?=[\\S])[^\\\\/:*?"<>|_]+$',
    revision: '^R(?=[\\S])[^\\\\/:*?"<>|_-]+$'
  };

  state = {
    isLoading: false,
    isSaving: false,
    isInputFocused: false,
    isInputChanged: false,
    inputValue: '',
    isChanged: false,
    showCode: '',
    episode: '',
    assetType: '',
    assetSpec: '',
    date: '',
    revision: '',
    fileExtension: this.props.file.name.split('.').reverse()[0],
    regexPatterns: {...this.defaultRegexPatterns}
  };

  componentDidMount() {
    this.setState({isLoading: true});
    getRegexPatternsToValidateAmazonFilename()
      .then(
        res => {
          this.setState({isLoading: false, regexPatterns: {...this.defaultRegexPatterns, ...res}});
        },
        error => {
          this.setState({isLoading: false});
        }
      );
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  buildName = () => {
    const {showCode, episode, assetType, assetSpec, date, revision} = this.state;
    const year = date ? date.getFullYear() : '';
    const month = date ? date.getMonth() + 1 : '';
    const day = date ? date.getDate() : '';
    const dateStr = `${year}${month && month < 10 ? '0' : ''}${month}${day && day < 10 ? '0' : ''}${day}`;
    const name = `${showCode}_${episode}-${assetType}_${assetSpec}-${dateStr}`;
    if (!revision) {
      return name;
    }
    return `${name}-${revision}`;
  };

  buildFileName = () => {
    const {fileExtension} = this.state;
    return `${this.buildName()}.${fileExtension}`;
  };

  isValidByRegexPatterns = values => {
    const {regexPatterns} = this.state;
    if (!['showCode', 'episode', 'assetType', 'assetSpec'].every(k => {
      const v = values[k];
      const regex = regexPatterns[k];
      return v && (!regex || new RegExp(regex).test(v));
    })) {
      return false;
    }

    const {revision} = values;
    if (revision && regexPatterns.revision && !new RegExp(regexPatterns.revision).test(revision)) {
      return false;
    }

    return true;
  };

  isValidFileName = () => {
    const {date} = this.state;
    if (!this.isValidByRegexPatterns({...this.state})) {
      return false;
    }
    if (!date) {
      return false;
    }
    return /^(?=[\S])[^\\/:*?"<>|]+$/.test(this.buildName());
  };

  isValidInputValue = () => {
    const {inputValue} = this.state;
    /*const index = inputValue.lastIndexOf('.');
    if (index < 0) {
      return false;
    }
    const extension = inputValue.substr(index + 1);
    if (!extension || !extension.match(/^[a-z0-9]{2,}$/i)) {
      return false;
    }*/
    const name = inputValue/*.substr(0, index)*/;
    if (!/^(?=[\S])[^\\/:*?"<>|]+$/.test(name)) {
      return false;
    }
    const fields = /^(.+)_(.+)-(.+)_(.+)-([0-9]{8})-(R.+)$/.exec(name) || /^(.+)_(.+)-(.+)_(.+)-([0-9]{8})$/.exec(name);
    if (!fields) {
      return false;
    }
    const [, showCode, episode, assetType, assetSpec, , revision] = fields;
    if (!this.isValidByRegexPatterns({showCode, episode, assetType, assetSpec, revision})) {
      return false;
    }
    return true;
  };

  onRename = filename => {
    const {dispatch, onClose} = this.props;
    this.setState({isSaving: true});
    dispatch(renameOfContentItem(filename)).then(
      () => {
        onClose();
      },
      error => {
        this.setState({isSaving: false});
      }
    );
  };

  handleClickRename = () => {
    const {file, onClose} = this.props;
    const {isInputChanged, inputValue, fileExtension, isChanged} = this.state;
    if (isChanged) {
      this.onRename(this.buildFileName());
    } else if (isInputChanged) {
      const filename = `${inputValue}.${fileExtension}`;
      if (filename !== file.name) {
        this.onRename(filename);
      } else {
        onClose();
      }
    } else {
      onClose();
    }
  };

  handleChange = (name, value) => {
    if (name !== 'date' && value) {
      value = value.replace(/_/g, '');
      if (name === 'revision' && value.startsWith('r')) {
        value = value.replace(/^[r]{1}/, 'R');
      }
    }
    this.setState({
      isInputChanged: false,
      inputValue: '',
      isChanged: true,
      [name]: value
    });
  };

  prepareFields = () => {
    const {regexPatterns} = this.state;
    return this.fields.map(field => {
      const regex = regexPatterns[field.name];
      if (regex) {
        const value = this.state[field.name];
        return {...field, tooltip: regex, className: (value && !new RegExp(regex).test(value)) ? 'invalid' : undefined};
      }
      return field;
    });
  };

  handleInputKeyDown = event => {
    const keyCode = event.keyCode;
    if (keyCode === 13) {
      this.handleClickRename();
    } else if (keyCode === 27) {
      this.setState({
        inputValue: ''
      });
      this._input.blur();
    }
  };

  handleInputChange = event => {
    const {fileExtension} = this.state;
    const value = event.target.value;
    this.setState({
      inputValue: value || '',
      isInputChanged: `${value || ''}.${fileExtension}` !== this.props.file.name,
      isChanged: false,
      showCode: '',
      episode: '',
      assetType: '',
      assetSpec: '',
      date: '',
      revision: ''
    });
  };

  handleInputBlur = () => {
    this.setState({
      isInputFocused: false
    });
  };

  handleInputFocus = () => {
    this.setState({
      isInputFocused: true
    });
  };

  render() {
    const {onClose, file} = this.props;
    const {isLoading, isSaving, isChanged, isInputChanged, inputValue, fileExtension, isInputFocused} = this.state;
    const isValid = isChanged ? this.isValidFileName() : true;
    const isValidInput = isInputChanged ? this.isValidInputValue() : true;
    return (
      <RndModal
        className="rename-amazon-file-modal"
        show={true}
        onHide={onClose}
        backdrop="static"
        enforceFocus={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>
            Rename of the Amazon file
            <br/>
            <TextEllipsisAdvanced style={{color: 'blue', fontSize: '12px'}} data={getValueForOS(file, 'key')}/>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContainerStyled className="container-fluid">
            {isLoading ?
                <div>
                  <Loader/>
                  <ReportBugButton isForModal className="loader-report-bug-btn"/>
                </div> : null}
            <Filters
              loading={false}
              filters={this.prepareFields()}
              usedFilters={{...this.state}}
              onChange={this.handleChange}
              bodyMd={12}
              headerVisible={false}
            />
            <hr/>
            <FormGroup className={`filename${(isValid && isValidInput) ? '' : ' invalid'}`}>
              <ControlLabel>Filename</ControlLabel>
              <div className="input-wrapper">
                {(isInputFocused || isInputChanged || isChanged) ? <div>{fileExtension}</div> : null}
                <FormControl
                  value={isInputChanged ? inputValue : isChanged ? this.buildName() : isInputFocused ? file.name.substr(0, file.name.lastIndexOf('.')) : file.name}
                  onKeyDown={this.handleInputKeyDown}
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputBlur}
                  onFocus={this.handleInputFocus}
                  inputRef={ref => {
                    this._input = ref;
                  }}
                />
              </div>
              <div className="warning">{isValidInput ? '' : 'Does not match the pattern'}&nbsp;</div>
            </FormGroup>
          </ModalContainerStyled>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.handleClickRename} disabled={(!isValid && isChanged) || (!inputValue && isInputChanged)}>
            {isSaving ? <i className="fa fa-spinner fa-spin"/> : null}Rename
          </Button>
          <Button bsStyle="default" onClick={onClose} disabled={isSaving}>Cancel</Button>
        </Modal.Footer>
      </RndModal>
    );
  }
}

const ModalContainerStyled = styled.div`
  > div {
    margin: 0;
    box-shadow: none;
  }

  .filters-block {
    margin: 0;

    input#fileExtension {
      background-color: #eeeeee;
      pointer-events: none;
    }

    .form-control {
      &.invalid {
        border-color: #ff0000;
      }
    }
  }

  .filename {
    &.invalid {
      .form-control {
        border-color: #ff0000;
      }
    }
    .input-wrapper {
      position: relative;
      div {
        position: absolute;
        top: 1px;
        right: 1px;
        background-color: #eeeeee;
        line-height: 32px;
        padding: 0 15px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        & + .form-control {
          padding-right: 60px;
        }
      }
    }
    .warning {
      color: #ff0000;
      font-size: 10px;
    }
  }

  .loader-container {
    z-index: 1051;
  }
`;

RenameAmazonFileModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired
};

export default RenameAmazonFileModal;
