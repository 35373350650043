import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {getSortedRowsBy} from '../../../../../utils';
import Table from '../../../../../components/table/Table';
import {NameCell} from './cells/NameCell';
import {SizeCell} from './cells/SizeCell';
import {InstantScanCell} from './cells/InstantScanCell';

class TableWrap extends Component {
  state = {
    tableSort: {
      field: '',
      direction: ''
    }
  };

  handleClickInstantScan = (row) => {
    const {onInstantScan} = this.props;
    onInstantScan(row);
  };

  headers = {
    InstantScan: {
      title: '',
      className: 'instant-scan-cell',
      component: InstantScanCell,
      componentArgs: {
        onClick: this.handleClickInstantScan
      }
    },
    FileName: {
      title: 'File Name',
      className: 'word-break-all',
      component: NameCell
    },
    Size: {
      title: 'Size',
      component: SizeCell
    },
    ModifiedOn: {
      title: 'Modified On'
    },
    Owner: {
      title: 'Owner',
      className: 'word-break-all'
    },
    Group: {
      title: 'Group',
      className: 'word-break-all'
    },
    Mode: {
      title: 'Mode',
      className: 'word-break-all'
    }
  };

  handleHeaderCellClick = (field, direction) => {
    this.setState({tableSort: {field, direction}});
  };

  render() {
    const {data, loading} = this.props;
    const {tableSort} = this.state;
    return (
      <StyledTable className="table-wrapper">
        <div className="table-container">
          <div className="table-block">
            <Table
              headers={this.headers}
              rows={getSortedRowsBy(data, tableSort.field, tableSort.direction)}
              sort={tableSort}
              onHeaderCellClick={this.handleHeaderCellClick}
              emptyText={loading ? 'Loading...' : "No Data"}
              />
          </div>
        </div>
      </StyledTable>
    );
  }
}

const StyledTable = styled.div`
  .table-container {
    width: 100%;
  }

  .table-block {
    max-width: 100%;
    max-height: calc(100vh - 200px);
    min-height: 300px;
    overflow-y: auto;
    margin-bottom: 20px;

    .table {
      border-collapse: separate;
      color: #282828;
      margin-bottom: 0;

      th {
        position: sticky;
        top: 0;
        background-color: #fff;
        &.instant-scan-cell {
          pointer-events: none;
        }
      }

      td {
        &.word-break-all {
          word-break: break-all;
        }
        &.data-table-empty {
          text-align: center;
          width: 100% !important;
        }
      }
    }
  }
`;

TableWrap.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onInstantScan: PropTypes.func.isRequired,
}

export default TableWrap;
