import styled from "styled-components";

const StatusCellStyled = styled.td`
  .label {
    display: block;
    padding: 0.2em 0.6em 0.2em;
    width: fit-content;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: inherit;
  }
`;

export default StatusCellStyled;
