import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {InputStyled, LabelStyled} from './InputStyled';

class SearchInput extends Component {
  resetValuesOnBlur = false;

  state = {
    value: this.props.value
  };

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.value) !== JSON.stringify(prevProps.value)) {
      this.setState({
        value: this.props.value
      });
    }
  }

  handleKeyDown = event => {
    const keyCode = event.keyCode;
    if (keyCode === 13) {
      this.props.onEnter(this.state.value, () => {
        this.resetValuesOnBlur = false;
        this._input.blur();
      });
    } else if (keyCode === 27) {
      this._input.blur();
    }
  };

  handleChange = event => {
    this.resetValuesOnBlur = true;
    const value = event.target.value;
    this.setState({value});
    const {onChange} = this.props;
    onChange(value);
  };

  setAppliedValue = () => {
    const {appliedValue, onChange} = this.props;
    onChange(appliedValue);
    this.setState({value: appliedValue});
  };

  handleBlur = event => {
    if (this.resetValuesOnBlur && (!event.relatedTarget || event.relatedTarget.id !== 'search-btn-primary')) {
      this.setAppliedValue();
    } else {
      this.forceUpdate();
    }
  };

  render() {
    const {value} = this.state;
    const {title, placeholder} = this.props;
    return (
      <Fragment>
        <SearchInputLabelStyled className="form-control" htmlFor="search-input">
          Search
        </SearchInputLabelStyled>
        <InputStyled
          id="search-input"
          className="form-control"
          type="text"
          value={value}
          autoComplete="off"
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          ref={r => this._input = r}
          title={title}
          placeholder={placeholder ? `Search in ${placeholder}` : placeholder}
          />
      </Fragment>
    );
  }
}

const SearchInputLabelStyled = styled(LabelStyled)`
  margin: 0 10px 0 0;
`;

SearchInput.propTypes = {
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  appliedValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired
};

export default SearchInput;
