import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, FormGroup, FormControl, InputGroup, Glyphicon, Radio, ControlLabel
} from 'react-bootstrap';
import ReactJson from 'react-json-view'
import {
  clearMediaInfo,
  loadMediaInfo,
  getQodRequestViaFile,
  getQodRequestByID,
  sendMediaInfoToQOD
} from '../../actions';
import _ from 'lodash';
import styled from 'styled-components';
import RndModal from '../../../../components/modals/RndModal';

class MediaInfoModal extends Component {
  timer = null;

  state = {
    searchInput: null,
    searchString: null,
    expand: false,
    isShowQodRequest: false,
    qodRequestData: null,
    selectedQodRequestId: null,
  };

  clearTimeout = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  componentDidMount() {
    const {dispatch, item} = this.props;
    dispatch(clearMediaInfo());
    dispatch(loadMediaInfo(item, false));
  }

  componentWillUnmount() {
    this.clearTimeout();
    this.props.onClose();
  }

  getSortedMediaInfo = (mediaInfo) => {
    let sortedMediaInfo;
      
    if(mediaInfo && mediaInfo.media) {
      sortedMediaInfo = Object.assign({}, mediaInfo);
      sortedMediaInfo.media = Object.keys(mediaInfo.media)
      .sort((a,b) => {
        let aGroups = a.match(/^([A-Za-z0-9_$-]*)(\[(\d+)\]|)$/);
        let bGroups = b.match(/^([A-Za-z0-9_$-]*)(\[(\d+)\]|)$/);
        let comparableByNumber = aGroups && aGroups.length === 4 && bGroups && bGroups.length === 4 && aGroups[1] === bGroups[1];
        if (comparableByNumber) {
          let aNum = aGroups[3];
          if (aNum === undefined) aNum = 0;
          let bNum = bGroups[3];
          if (bNum === undefined) bNum = 0;
          return aNum - bNum;
        }
        return a > b ? 1 : a < b ? -1 : 0;
      })
      .reduce((r,k) => {
        if (k === 'track') {
          r['track[0]'] = mediaInfo.media[k];
          return r;
        }
        r[k] = mediaInfo.media[k];
        return r;
      }, {});
    }

    return sortedMediaInfo;
  }

  prepareSearchString = str => (str || '').trim().toLowerCase();

  prepareMediaInfo = () => {
    const mediaInfo = (this.props.mediaInfo || {}).MediaInfo;
    const filterString = this.prepareSearchString(this.state.searchString);
    if (mediaInfo && filterString) {
      const hasKey = o => _.isPlainObject(o) && Object.keys(o).some(k => k.toLowerCase().includes(filterString) || hasKey(o[k]));
      const filterNodes = o => {
        try {
          if (_.isPlainObject(o)) {
            const result = {};
            Object.keys(o).forEach(k => {
              if (k.toLowerCase().includes(filterString)) {
                result[k] = o[k];
              } else if (hasKey(o[k])) {
                result[k] = filterNodes(o[k]);
              }
            });
            return result;
          }
        } catch {}
        return o;
      };
      return this.getSortedMediaInfo(filterNodes(mediaInfo));
      
    }
    return this.getSortedMediaInfo(mediaInfo);
  };

  triggerChangeFilter = (value) => {
    this.setState({searchInput: value, searchString: value, expand: (this.state.expand || this.prepareSearchString(value))});
  };

  handleChangeFilter = ({target: {value}}) => {
    this.clearTimeout();
    if (value) {
      //this.setState({searchInput: value});
      this.timer = setTimeout(v => this.triggerChangeFilter(v), 500, value);
    } else {
      this.timer = setTimeout(v => this.triggerChangeFilter(v), 5, value);
    }
  };

  handleBlurFilter = ({target: {value}}) => {
    this.clearTimeout();
    this.triggerChangeFilter(value);
  };

  formatDate = (dateStr) => {
    const today = new Date(dateStr);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return today.toLocaleString('en-US', options) + " " + today.toLocaleTimeString('en-US');
  }

  handleSendToQOD = () => {
    const {dispatch, item} = this.props;
    const {selectedQodRequestId} = this.state;
    dispatch(sendMediaInfoToQOD(item, selectedQodRequestId));
  };

  handleViewQodRequest = () => {
    const {dispatch, item} = this.props;
    const {qodRequestData} = this.state;
    if (qodRequestData) {
      this.setState({isShowQodRequest: true});
    } else {
      dispatch(getQodRequestViaFile(item)).then(res => {
        const data = Array.isArray(res.data) && res.data.length ? res.data : {searchId: null};
        this.setState({
          isShowQodRequest: true,
          qodRequestData: data,
          selectedQodRequestId: Array.isArray(data) && data.length === 1 ? data[0]['QOD request ID'] : null
        });
      });
    }
  };

  handleSearchQodRequestByID = () => {
    const {dispatch} = this.props;
    const {qodRequestData: {searchId}} = this.state;
    dispatch(getQodRequestByID(+searchId)).then(res => {
      const {data} = res || {};
      this.setState({
        qodRequestData: {searchId, data: data || {}},
        selectedQodRequestId: data['QOD request ID']
      });
    });
  };

  buildTitle = () => {
    const {dispatch, mediaInfo, item} = this.props;
    const {isShowQodRequest} = this.state;

    if (isShowQodRequest) {
      return `MediaInfo - QOD request`;
    }

    const formattedDate = mediaInfo.UpdatedOn ? `Updated on ${this.formatDate(mediaInfo.UpdatedOn)}` : 'Updated time unknown';
    const refreshBtn = <Button bsSize="xsmall" bsStyle="default" onClick={() => dispatch(loadMediaInfo(item, true))}>Refresh</Button>;

    return <>MediaInfo {refreshBtn} <span style={{fontSize:12+'px'}}>{formattedDate}</span></>;
  };

  renderMediaInfo = () => {
    const {loading} = this.props;
    return (
      <>
        <FormGroup>
          <InputGroup>
            <InputGroup.Addon>
              <Glyphicon glyph="filter"/>
            </InputGroup.Addon>
            <FormControl
              type="text"
              disabled={loading}
              /*value={this.state.searchInput || ''}*/
              onChange={this.handleChangeFilter}
              onBlur={this.handleBlurFilter}
            />
          </InputGroup>
        </FormGroup>
        <ReactJson src={this.prepareMediaInfo()}
          name={false}
          quotesOnKeys={false}
          // sortKeys={true}
          collapseStringsAfterLength={40}
          iconStyle='square'
          collapsed={this.state.expand ? false : 1}
          enableClipboard={false}
          displayObjectSize={false}
          displayDataTypes={false}
        />
      </>
    );
  };

  renderQodRequest = () => {
    const {sending, item} = this.props;
    const {isShowQodRequest, qodRequestData, selectedQodRequestId} = this.state;
    if (!isShowQodRequest) {
      return null;
    }
    if (!qodRequestData) {
      return <div className="no-data">Loading...</div>;
    }
    const prepareRequestView = (r, fields) => [
      'QOD request ID', 'Project', 'Spec', 'Campaign', 'Revision', ...fields || []
    ].map(k => (
      <dl key={k}>
        <dt>
          {k}
        </dt>
        <dd className={r[k] ? undefined : 'is-empty'}>
          {r[k] || 'N/A'}
        </dd>
      </dl>
    ));
    if (!Array.isArray(qodRequestData)) {
      const {searchId, data} = qodRequestData;
      const isFoundRequest = !!Object.keys(data || {}).length;
      const showWarning = !isFoundRequest || data.IsArtworkRequest;
      const isMatchFileNames = !!data && data.IsArtworkRequest && ['AssetName', 'FileName'].some(k => data[k] === item.name);
      return (
        <>
          <div className="no-request">
            <i className="fas fa-info"/>
            No QOD request for selected file
          </div>
          <hr/>
          <div className="search-request">
            <FormGroup>
              <ControlLabel>
                QOD request ID
              </ControlLabel>
              <FormControl
                type="number"
                min={1}
                value={searchId || ''}
                onChange={({target: {value}}) => this.setState({qodRequestData: {...qodRequestData, searchId: value}})}
                onKeyDown={({keyCode}) => keyCode === 13 && this.handleSearchQodRequestByID()}
              />
            </FormGroup>
            <Button
              title="Search QOD request by ID"
              onClick={this.handleSearchQodRequestByID}
              disabled={+searchId <= 0}
            >
              Search request
            </Button>
          </div>
          {!!data && (
            <>
              {isFoundRequest && prepareRequestView(data, ['DAT_FsRootID', 'DAT_ContentID', 'AssetName', 'FileName'])}
              <div
                className="no-request exclamation"
                style={sending && !showWarning ? {visibility: 'hidden'} : undefined}
              >
                <i className="fas fa-triangle-exclamation"/>
                {!isFoundRequest ? <span>Not found QOD request by ID</span> : null}
                {data.IsArtworkRequest ? <span><br/>Artwork QOD request will be point to selected file</span> : null}
                {data.IsArtworkRequest && !isMatchFileNames ? <span><br/>The names of the selected file and found artwork asset do not match</span> : null}
              </div>
            </>
          )}
        </>
      );
    }
    if (qodRequestData.length === 1) {
      return <div>{prepareRequestView(qodRequestData[0])}</div>;
    }
    return (
      <FormGroup className="form-group--qod-request">
        <ControlLabel>
          Select request:
        </ControlLabel>
        {qodRequestData.map(r => {
          const requestId = r['QOD request ID'];
          return (
            <Radio
              key={requestId}
              name="qodRequest"
              onChange={() => this.setState({selectedQodRequestId: requestId})}
              checked={selectedQodRequestId === requestId}
              className={selectedQodRequestId === requestId ? 'checked' : undefined}
              title={selectedQodRequestId !== requestId ? 'Select' : undefined}
            >
              {prepareRequestView(r)}
            </Radio>
          );
        })}
      </FormGroup>
    );
  };

  renderQodButton = () => {
    const {mediaInfo} = this.props;
    const {isShowQodRequest, selectedQodRequestId} = this.state;
    if (isShowQodRequest) {
      return (
        <>
          <Button
            bsStyle="default"
            onClick={() => this.setState({isShowQodRequest: false})}
            className="btn-back"
          >
            Back
          </Button>
          <Button
            bsStyle="default"
            onClick={this.handleSendToQOD}
            disabled={!selectedQodRequestId}
          >
            Send to QOD
          </Button>
        </>
      );
    }
    return !!(mediaInfo || {}).MediaInfo && (
      <Button
        bsStyle="default"
        onClick={this.handleViewQodRequest}
      >
        View QOD request
      </Button>
    );
  };

  render() {
    const {onClose} = this.props;
    const {isShowQodRequest} = this.state;
    return (
      <RndModal
        className="media-info-modal"
        bsSize="large"
        show={true}
        onHide={onClose}
        backdrop="static"
        enforceFocus={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.buildTitle()}
          </Modal.Title>
        </Modal.Header>
        <ModalBodyStyled>
          <div className="container-fluid">
            <div className={isShowQodRequest ? 'hidden' : undefined}>
              {this.renderMediaInfo()}
            </div>
            {this.renderQodRequest()}
          </div>
        </ModalBodyStyled>
        <Modal.Footer>
          {this.renderQodButton()}
          <Button bsStyle="default" onClick={onClose}>Exit</Button>
        </Modal.Footer>
      </RndModal>
    );
  }
}

const ModalBodyStyled = styled(Modal.Body)`
  .react-json-view {
    max-height: calc(100vh - 280px);
    overflow-y: auto;
  }
  .hidden {
    display: none;
  }
  .no-data {
    margin: 15px 5px;
    text-align: center;
    opacity: 0.75;
  }
  .no-request {
    i {
      color: #d43f3a;
      margin: 0 8px 0 5px;
    }
    &.exclamation {
      margin: 15px 0;
      i {
        margin-left: 0;
        color: #eea236;
        font-size: 17px;
      }
    }
  }
  .is-empty {
    opacity: 0.75;
  }
  dl {
    margin: 0;
    + dl {
      margin-top: 5px;
    }
  }
  .search-request {
    margin: 15px 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    column-gap: 8px;
    .form-group {
      margin: 0;
      .form-control {
        min-width: 90px;
      }
    }
  }
  .form-group--qod-request {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
    .radio {
      label {
        margin: 0 7px;
        border-radius: 4px;
        box-shadow: 0 0 0 5px #fff, 0 0 0 6px #ddd;
        &:hover {
          background: #eee;
          box-shadow: 0 0 0 5px #eee, 0 0 0 6px #ddd;
        }
      }
      &.checked {
        label {
          background: #fff !important;
          box-shadow: 0 0 0 5px #fff, 0 0 0 6px #0075ff !important;
        }
      }
      + .radio {
        margin-top: 20px;
      }
    }
  }
  + .modal-footer {
    .btn-back {
      float: left;
    }
  }
`;

MediaInfoModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  sending: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  mediaInfo: PropTypes.object
};

export default MediaInfoModal;
