/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {RenamingTable} from './styledStep';
import FileNameCell from './FileNameCell';
import FileNameEditCell from './FileNameEditCell';
import DeliveryFilenameModal from './modals/DeliveryFilenameModal';
import {parseName} from '../../StorageManager/utils';
import {confirmAlert} from 'react-confirm-alert';
import {MACROS_FIELD_TYPE} from '../constants';

export const calcNewNamePartByKey = (k, macros, macrosFields) => {
  let val = macros[k];
  if (val) {
    const macrosType = (macrosFields[k] || {}).type;
    if (macrosType === MACROS_FIELD_TYPE.DATE) {
      val = val.replace(/-/g, '');
    } else if (macrosType === MACROS_FIELD_TYPE.SELECT || macrosType === MACROS_FIELD_TYPE.COMBOBOX) {
      const values = (macrosFields[k] || {}).values || {};
      const valKey = Object.keys(values).find(vk => values[vk] === val);
      if (valKey && valKey in macrosFields && macros[`${k}@@@${valKey}`]) {
        val = `${(macrosFields[valKey] || {}).valuePrefix || ''}${macros[`${k}@@@${valKey}`]}`;
      }
    }
  }
  return val;
};

export const buildFileNameFromTemplate = (template, macros, macrosFields) => {
  let newName = template;
  let newNameWithoutExtension = newName.replace(new RegExp("[.][A-Za-z0-9][A-Za-z0-9]*$"), "");
  Object.keys(macros).forEach(k => {
    const val = calcNewNamePartByKey(k, macros, macrosFields);
    if ((val === '' || val === null || val === undefined) && String((macrosFields[k] || {}).optional) === 'true' && newName !== `[${k}]`) {
      if (newName.startsWith(`[${k}]_`)) {
        newName = newName.replace(new RegExp(`\\[${k}\\][_]`, 'g'), val || '');
      } else if (newNameWithoutExtension.endsWith(`_[${k}]`) || newNameWithoutExtension.indexOf(`_[${k}]_`) >= 0) {
        newName = newName.replace(new RegExp(`[_]\\[${k}\\]`, 'g'), val || '');
      } else {
        newName = newName.replace(new RegExp(`\\[${k}\\]`, 'g'), val || '');
      }
    } else {
      newName = newName.replace(new RegExp(`\\[${k}\\]`, 'g'), val || '');
    }
  });
  return newName;
};

const TemplateFlag = {
  NO_TEMPLATE: 0,
  USE_TEMPLATE: 1,
  DONT_USE_TEMPLATE: -1
};

const Renaming = props => {
  const {data, onChange, isValidFileName, macrosFields, onExitDeliveryRename} = props;
  const {categoryData, commonInstructions} = data;

  const [dataForEditPopup, showEditPopup] = useState(null);

  const handleCloseDeliveryFilenameModal = isExitDeliveryRename => {
    showEditPopup(null);
    if (isExitDeliveryRename && onExitDeliveryRename) {
      onExitDeliveryRename();
    }
  };

  const handleManuallyChange = (file, useTemplateFlag, value, changedMacros) => {
    const byTemplate = !!changedMacros;
    let selectionStart, scrollLeft;
    try {
      const input = document.getElementById(`f${file.categoryID}-${file.woMemfis}-${file.contentID}`);
      selectionStart = input.selectionStart;
      scrollLeft = input.scrollLeft;
    } catch(ignored) {/**/}
    const applyChanges = () => {
      let setFocus = false;
      if (useTemplateFlag) {// TemplateFlag.USE_TEMPLATE or TemplateFlag.DONT_USE_TEMPLATE
        file.deliveryFileName = (byTemplate || useTemplateFlag === TemplateFlag.DONT_USE_TEMPLATE) ? value : file.deliveryFileName;
        if (byTemplate) {
          file.deliveryFileNameData.macros = {...changedMacros};
        }
        file.deliveryFileNameData.useTemplateFlag = byTemplate ? TemplateFlag.USE_TEMPLATE : TemplateFlag.DONT_USE_TEMPLATE;
        if (!(byTemplate || useTemplateFlag === TemplateFlag.DONT_USE_TEMPLATE)) {
          setFocus = true;
        }
      } else {// TemplateFlag.NO_TEMPLATE
        file.deliveryFileName = value;
      }
      file.isChecked = isValidFileName(value);
      file.isManuallyChanged = true;
      onChange(data);
      if (setFocus) {
        try {
          const input = document.getElementById(`f${file.categoryID}-${file.woMemfis}-${file.contentID}`);
          input.focus();
          input.selectionStart = selectionStart || 0;
          input.selectionEnd = selectionStart || 0;
          input.scrollLeft = scrollLeft || 0;
        } catch(ignored) {/**/}
      }
    };
    if (!useTemplateFlag || useTemplateFlag === (byTemplate ? TemplateFlag.USE_TEMPLATE : TemplateFlag.DONT_USE_TEMPLATE) || onExitDeliveryRename) {
      applyChanges();
    } else {
      confirmAlert({
        title: 'Are You Sure?',
        message: useTemplateFlag === TemplateFlag.USE_TEMPLATE ?
          'Are you sure you want to replace the file name created from a template, on the manually entered value?' :
            'Are you sure you want to replace the current value with the filename from the template?',
        buttons: [{
          label: 'No',
        }, {
          label: 'Yes',
          onClick: applyChanges
        }]
      });
    }
  };

  const handleRenameFromSpecPopup = (file, useTemplateFlag) => {
    const openPopup = () => showEditPopup({file, useTemplateFlag});
    if (useTemplateFlag === TemplateFlag.USE_TEMPLATE || onExitDeliveryRename) {
      openPopup();
    } else {
      confirmAlert({
        title: 'Are You Sure?',
        message: 'Are you sure you want to replace the current value with the filename from the template?',
        buttons: [{
          label: 'No',
        }, {
          label: 'Yes',
          onClick: openPopup
        }]
      });
    }
  };

  useEffect(() => {
    if (onExitDeliveryRename) {
      const {woDataList} = categoryData[0];
      const {contentDataList} = woDataList[0];
      const file = contentDataList[0];
      const {deliveryFileName, deliveryFileNameData} = file;
      const hasTemplate = !!deliveryFileNameData && !!deliveryFileNameData.template && !!deliveryFileNameData.macros;
      const useTemplateFlag = !hasTemplate ? TemplateFlag.NO_TEMPLATE : deliveryFileNameData.useTemplateFlag ||
        (deliveryFileName && deliveryFileName !== buildFileNameFromTemplate(deliveryFileNameData.template, deliveryFileNameData.macros, macrosFields) ? TemplateFlag.DONT_USE_TEMPLATE : TemplateFlag.USE_TEMPLATE);
      handleRenameFromSpecPopup(file, useTemplateFlag);
    }
  }, []);

  const colSpan = 5, isShowEditPopup = !!dataForEditPopup;

  return (
    <RenamingTable>
      {isShowEditPopup &&
        <DeliveryFilenameModal
          originalFileName={parseName(dataForEditPopup.file.fileName)}
          deliveryFileName={dataForEditPopup.file.deliveryFileName}
          deliveryFileNameDataList={dataForEditPopup.file.deliveryFileNameDataList}
          template={dataForEditPopup.file.deliveryFileNameData.template}
          macros={dataForEditPopup.file.deliveryFileNameData.macros}
          macrosFields={macrosFields}
          onClose={(b) => handleCloseDeliveryFilenameModal(typeof b === 'boolean' ? b : true)}
          onApply={(value, macros) => handleManuallyChange(dataForEditPopup.file, dataForEditPopup.useTemplateFlag, value, macros)}
          isValidFileName={isValidFileName}
        />
      }
      <table>
        <thead>
          <tr>
            <th colSpan={colSpan} className="col-title">
              Delivery WO: {data.woMemfis} - {data.woTitle}
            </th>
          </tr>
          <tr>
            <th colSpan={colSpan} className="col-instr">
              <div className="instructions">
                <div>General Instructions:</div>
                <div>{commonInstructions}</div>
              </div>
            </th>
          </tr>
        </thead>
        {
          categoryData.map(cat => {
            const {category, woDataList} = cat;
            const {categoryID, categoryName} = category;
            return (woDataList || []).map(wo => {
              const {woMemfis, contentDataList, description, format} = wo;
              const woKey = `${categoryID}---${woMemfis}`;
              return (
                <tbody key={woKey}>
                  <tr>
                    <td colSpan={colSpan} className="col-title">
                      {woMemfis} - {categoryName}{format ? ` - ${format}` : ''}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={colSpan} className="col-instr">
                      <div className="instructions">
                        <div>Instructions:</div>
                        <div className="wo-desc">{description}</div>
                      </div>
                    </td>
                  </tr>
                  {(contentDataList || []).map(file => {
                    const {contentID, fileName, deliveryFileName, deliveryFileNameData, isChecked, isSelected} = file;
                    if (!isSelected) {
                      return null;
                    }
                    const hasTemplate = !!deliveryFileNameData && !!deliveryFileNameData.template && !!deliveryFileNameData.macros;
                    const useTemplateFlag = !hasTemplate ? TemplateFlag.NO_TEMPLATE : deliveryFileNameData.useTemplateFlag ||
                      (deliveryFileName && deliveryFileName !== buildFileNameFromTemplate(deliveryFileNameData.template, deliveryFileNameData.macros, macrosFields) ? TemplateFlag.DONT_USE_TEMPLATE : TemplateFlag.USE_TEMPLATE);
                    const fileKey = `${woKey}---${contentID}`;
                    const isValidName = isValidFileName(deliveryFileName);
                    return (
                      <tr key={fileKey} className="row-file">
                        <FileNameCell className="col-filename" fileName={fileName}/>
                        <td className="col-action">
                          <i
                            className={`fas fa-file-import${isShowEditPopup ? ' disabled' : ''}`}
                            title="Copy file name"
                            onClick={() => {
                              const newValue = parseName(fileName);
                              file.deliveryFileName = newValue;
                              if (hasTemplate) {
                                file.deliveryFileNameData.useTemplateFlag = TemplateFlag.DONT_USE_TEMPLATE;
                              }
                              file.isChecked = true;
                              file.isManuallyChanged = false;
                              onChange(data);
                            }}
                          />
                        </td>
                        <FileNameEditCell
                          className="col-filename"
                          file={file}
                          deliveryFileName={deliveryFileName}
                          isValidName={isValidName}
                          onChange={({target: {value}}) => handleManuallyChange(file, useTemplateFlag, value)}
                          disabled={isShowEditPopup}
                        />
                        <td className="col-action">
                          <i
                            className={`fas fa-calculator${hasTemplate ? '' : ' v-hidden'}${isShowEditPopup ? ' disabled' : ''}`}
                            title="Rename from Spec"
                            onClick={hasTemplate ? () => handleRenameFromSpecPopup(file, useTemplateFlag) : undefined}
                          />
                        </td>
                        <td className="col-action">
                          <i
                            className={`fas fa-${isChecked ? '' : 'un'}lock${(isShowEditPopup || !(isValidName || isChecked)) ? ' disabled' : ''}`}
                            title={isChecked ? 'Disapprove' : 'Approve'}
                            onClick={() => {
                              file.isChecked = !file.isChecked;
                              onChange(data);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              );
            });
          })
        }
      </table>
    </RenamingTable>
  );
};

Renaming.propTypes = {
  isValidFileName: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onExitDeliveryRename: PropTypes.func,
};

export default Renaming;