import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LIMIT = 3;

export const ParametersCell = ({value, row, className, onClickListOfContentIDs}) => (
  <StyledCell className={className}>
    {value ? value.map((param, index) => {
      function toggleVisiblePassword(id) {
        if (document.getElementById(`password-${id}`).innerHTML === '********') {
          document.getElementById(`password-${id}`).innerHTML = param.value;
          document.getElementById(`eye-icon-${id}`).className = 'far fa-eye';
        } else {
          document.getElementById(`password-${id}`).innerHTML = '********';
          document.getElementById(`eye-icon-${id}`).className = 'far fa-eye-slash';
        }
      }
      return (
        <div
          className={`action-parameter${index >= LIMIT ? ` action-${row.actionID}-parameter collapse` : ''}`}
          key={`action-${row.actionID}-parameter-${index}`}
        >
          <label>{param.name}:</label>
          {param.name === 'PASSWORD' ? <div className='pass-field'>
            <div
              id={`password-${row.actionID}`}
              className={param.type}
            >
              ********
            </div>
            <i
              id={`eye-icon-${row.actionID}`}
              className='far fa-eye-slash'
              onClick={() => toggleVisiblePassword(row.actionID)}
            />
          </div> : 
          <div
            className={param.type}
            onClick={param.type === 'LIST_OF_CONTENT_IDS' ? () => onClickListOfContentIDs(row) : undefined}
            title={param.type === 'LIST_OF_CONTENT_IDS' ? 'View' : undefined}
          >
          {Array.isArray(param.value) ? param.value.join(', ') : (param.value === true || param.value === false) ? String(param.value) : param.value}
          </div>}
        </div>
      );
    }) : null}
    {value && value.length > LIMIT ?
      <div
        className="more-action-response-label collapsed"
        data-toggle="collapse"
        data-target={`.action-${row.actionID}-parameter`}
        >
         <span className="expand-label">show more...</span>
         <span className="collapse-label">collapse</span>
      </div> : null
    }
  </StyledCell>
);

const StyledCell = styled.td`
  div:not(.more-action-response-label) {
    margin-bottom: 10px;
    &.LIST_OF_CONTENT_IDS {
      color: #337ab7;
      &:hover {
        color: #23527c;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    &.STRING {
      word-break: break-all;
    }
    &.pass-field {
      display: flex;
    }
  }

  .collapse-label {
    display: none;
  }
  .expand-label, .collapse-label {
    font-weight: bold;
    font-size: 10px;
    line-height: 11px;
    color: #0DB4D9;
    padding: 0 0 0 1px;
    &:hover {
      color: #004A8D;
      cursor: pointer;
    }
  }
  .collapse.in {
    + .more-action-response-label {
      padding-top: 7px;
      .expand-label {
        display: none;
      }
      .collapse-label {
        display: unset;
      }
    }
  }
  .collapsing {
    + .more-action-response-label {
      span {
        visibility: hidden;
      }
    }
  }
`;

ParametersCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object,
  className: PropTypes.string,
  onClickListOfContentIDs: PropTypes.func
};
