/* eslint jsx-a11y/anchor-is-valid: "off" */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {isNetflixClient} from '../../../../../utils';
import {FLAG_FINAL_MOVE_WITHOUT_WO, FLAG_READY_FOR_MULTIPLE_WO} from '../../../constants';

class WoCell extends Component {
  renderCellContent() {
    const {link, fileMakerURL, onClickLinkToMemfisWO} = this.props;
    const {WOMemfis, client_name, flags, file_name} = link;

    if (WOMemfis) {
      if (fileMakerURL) {
        return (
          <a
            className="go-to-memfis-wo"
            title="Go to WO#"
            href={fileMakerURL.replace('@MEMFIS_WO@', WOMemfis)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {WOMemfis}
          </a>
        );
      }
      return WOMemfis;
    }

    if (isNetflixClient(client_name)) {
      return <span className="red">No WO#</span>;
    }

    if (flags && (flags & FLAG_FINAL_MOVE_WITHOUT_WO) > 0) {
      if ((flags & FLAG_READY_FOR_MULTIPLE_WO) === 0) {
        return <span className="final-move-in-progress">Final move in progress</span>;
      }
      return (
        <a
          className="go-to-memfis-wo"
          title="Link WO(s)"
          href={`/storage?nav=${encodeURIComponent(file_name)}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Link WO(s)
        </a>
      );
    }

    return (
      <span
        className="red link-to-memfis-wo"
        title={onClickLinkToMemfisWO ? 'Link to WO#' : 'Forbidden Action!'}
        onClick={onClickLinkToMemfisWO ? () => onClickLinkToMemfisWO(link) : undefined}
        disabled={!onClickLinkToMemfisWO}
      >
        Enter Memfis WO#
      </span>
    );
  }

  render() {
    return (
      <StyledCell className="memfiswo">
        {this.renderCellContent()}
      </StyledCell>
    );
  }
}

const StyledCell = styled.div`
  a:not(.go-to-memfis-wo) {
    text-decoration: none;
    color: #428bca;
    cursor: pointer;
    transition: all ease 0.6s;
    outline: 0;
  }
  a.red, span.red {
    color: #ff0000;
    &.link-to-memfis-wo {
      cursor: pointer;
      &:not([disabled]):hover {
        text-decoration: underline;
      }
      &[disabled] {
        cursor: not-allowed;
        filter: alpha(opacity=65);
        opacity: 0.65;
      }
    }
  }
  input {
    margin: 0;
    display: none;
  }
  input + div {
    display: none;
  }
`;

WoCell.propTypes = {
  link: PropTypes.object.isRequired,
  fileMakerURL: PropTypes.string,
  onClickLinkToMemfisWO: PropTypes.func
};

export default WoCell;