import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import styled, {createGlobalStyle} from 'styled-components';
import Loader from '../../../components/Loader';
import {DELIVERY_PAGE__EDIT, DELIVERY_STEP, DELIVERY_PAGE__RENAME_ONLY} from '../constants';
import {
  getFiles, changeFiles, saveFiles, startRenaming, startSelecting, applySavedData, approveFiles, openInstructions
} from '../actions';
import {H2} from './styledStep';
import Selecting from './Selecting';
import Renaming from './Renaming';
import {isValidFileName} from '../../../utils';
import Instructions from './Instructions';
import ReportBugButton from "../../../components/ReportBugButton";

class DeliveryCommon extends React.Component {

  componentDidMount() {
    const {dispatch, history, match, paramsToGetFiles} = this.props;
    const {operationType, actionId, step} = match.params;
    const currentStep = +step === DELIVERY_STEP.RENAMING_FILES ? DELIVERY_STEP.RENAMING_FILES : DELIVERY_STEP.SELECTING_FILES;
    dispatch(getFiles(history, operationType, actionId, currentStep, paramsToGetFiles));
  }

  isValidDeliveryFileName = (value) => {
    if (!isValidFileName(value || '')) {
      return false;
    }

    let filesWithSameName = 0;
    const {deliveryManager: {reviewDeliveryData: {categoryData}}} = this.props;
    categoryData.forEach(cat => {
      (cat.woDataList || []).forEach(wo => {
        (wo.contentDataList || []).forEach(file => {
          if (file.isSelected && (file.deliveryFileName || '').toLowerCase() === value.toLowerCase()) {
            filesWithSameName++;
          }
        });
      });
    });
    return filesWithSameName <= 1;
  };

  handleChangeFiles = (reviewDeliveryData, categoryDataForTreeView) => {
    const {dispatch, deliveryManager, onExitDeliveryRename} = this.props;
    dispatch(changeFiles(reviewDeliveryData, categoryDataForTreeView));
    if (deliveryManager.step !== DELIVERY_STEP.SELECTING_FILES && onExitDeliveryRename) {
      this.handleSaveFiles();
    }
  };

  handleSaveFiles = () => {
    const {dispatch, match, paramsToSaveFiles} = this.props;
    const {operationType, actionId} = match.params;
    dispatch(saveFiles(operationType, actionId, paramsToSaveFiles));
  };

  handleToggleOpenInstructions = (key, text) => {
    const {dispatch} = this.props;
    dispatch(openInstructions(key, text));
  };

  handleStartRenaming = () => {
    const {dispatch, history, match} = this.props;
    const {operationType, actionId} = match.params;
    history.replace(`/delivery/${operationType}/${actionId}/${DELIVERY_STEP.RENAMING_FILES}`);
    dispatch(startRenaming());
  };

  handleBackToSelecting = () => {
    const {dispatch, history, match} = this.props;
    const {operationType, actionId} = match.params;
    history.replace(`/delivery/${operationType}/${actionId}`);
    dispatch(startSelecting());
    dispatch(applySavedData());
  };

  handleApproveFiles = () => {
    const {dispatch, history, match} = this.props;
    const {operationType, actionId} = match.params;
    dispatch(approveFiles(history, operationType, actionId));
  };

  isNoFiles = () => {
    const {deliveryManager} = this.props;
    const {reviewDeliveryData} = deliveryManager;
    return !((reviewDeliveryData || {}).categoryData || []).some(c => (c.woDataList || []).some(wo => !!(wo.contentDataList || []).length));
  };

  renderButtonToolbar = () => {
    const {match, deliveryManager} = this.props;
    const {operationType} = match.params;
    const {reviewDeliveryData, step, savedDataJson} = deliveryManager;

    if (!step || !reviewDeliveryData || this.isNoFiles()) {
      return null;
    }

    const isRenameOnlyPage = operationType === DELIVERY_PAGE__RENAME_ONLY;

    const noUnsavedChanges = JSON.stringify(reviewDeliveryData) === savedDataJson;
    const primaryBtn = {
      label: 'Save',
      onClick: this.handleSaveFiles,
      disabled: noUnsavedChanges
    };

    if (step === DELIVERY_STEP.SELECTING_FILES && noUnsavedChanges &&
        reviewDeliveryData.categoryData.some(c => (c.woDataList || []).some(wo => (wo.contentDataList || []).some(f => !!f.isSelected)))) {

      primaryBtn.label = 'Start Renaming';
      primaryBtn.onClick = this.handleStartRenaming;
      primaryBtn.disabled = false;

    } else if (step === DELIVERY_STEP.RENAMING_FILES && noUnsavedChanges &&
        reviewDeliveryData.categoryData.every(c => !(c.woDataList || []).length || c.woDataList.every(wo =>
          !(wo.contentDataList || []).length || wo.contentDataList.every(f =>
            !f.isSelected || (this.isValidDeliveryFileName(f.deliveryFileName) && !!f.isChecked))))) {

      if (!isRenameOnlyPage) {
        const isEditPage = operationType === DELIVERY_PAGE__EDIT;
        primaryBtn.label = isEditPage ? 'Submit for Approval' : 'Approve';
        primaryBtn.onClick = this.handleApproveFiles;
      }
      primaryBtn.disabled = false;

    } else if (step === DELIVERY_STEP.RENAMING_FILES &&
        reviewDeliveryData.categoryData.some(c => (c.woDataList || []).some(wo => (wo.contentDataList || []).some(f =>
          f.isSelected && f.deliveryFileName && !this.isValidDeliveryFileName(f.deliveryFileName))))) {

      primaryBtn.disabled = true;
    }

    const showBackBtn = !isRenameOnlyPage && step === DELIVERY_STEP.RENAMING_FILES;

    return (
      <ButtonToolbar id="delivery-step-btns" className={showBackBtn ? 'space-between' : undefined}>
        {showBackBtn &&
          <Button
            bsStyle="default"
            onClick={this.handleBackToSelecting}
          >
            Change file set
          </Button>
        }
        <Button
          bsStyle="primary"
          onClick={primaryBtn.onClick}
          disabled={primaryBtn.disabled}
        >
          {primaryBtn.label}
        </Button>
      </ButtonToolbar>
    );
  };

  renderFiles = () => {
    const {deliveryManager, onExitDeliveryRename} = this.props;
    const {reviewDeliveryData, step, macrosFields} = deliveryManager;

    if (!reviewDeliveryData) {
      return null;
    }

    if (this.isNoFiles()) {
      return <NoFiles/>;
    }

    if (step === DELIVERY_STEP.SELECTING_FILES) {
      return (
        <Selecting
          onSelect={this.handleChangeFiles}
          onToggleOpenInstructions={this.handleToggleOpenInstructions}
        />
      );
    }

    return (
      <Renaming
        data={reviewDeliveryData}
        onChange={this.handleChangeFiles}
        isValidFileName={this.isValidDeliveryFileName}
        macrosFields={macrosFields}
        onExitDeliveryRename={onExitDeliveryRename}
      />
    );
  };

  render() {
    const {deliveryManager, operationName} = this.props;
    const {loading, step, reviewDeliveryData, openedInstructions} = deliveryManager;

    return (
      <>
        <Container>
          {loading &&
          <div>
            <Loader className="full-screen"/>
            <ReportBugButton isForModal className="loader-report-bug-btn"/>
          </div>}
          {(step === DELIVERY_STEP.SELECTING_FILES || (step === DELIVERY_STEP.RENAMING_FILES && this.isNoFiles())) &&
            <H2>
              {operationName} Delivery: {step === DELIVERY_STEP.SELECTING_FILES ? 'Selecting' : 'Renaming'} Files
              {reviewDeliveryData && reviewDeliveryData.woTitle ? ` for "${reviewDeliveryData.woTitle.replace(/["]/g,' ').trim()}"` : ''}
            </H2>
          }
          {this.renderFiles()}
          {this.renderButtonToolbar()}
          <Instructions
            data={openedInstructions}
            onClose={this.handleToggleOpenInstructions}
          />
        </Container>
        <GlobalStyled/>
      </>
    );
  }
}

const GlobalStyled = createGlobalStyle`
  #delivery-full-filename-tooltip-in-cell {
    .tooltip-inner {
      text-align: left;
      word-break: break-all;
    }
    body > & {
      margin-left: 5px;
      .tooltip-inner {
        max-width: 90vw;
      }
    }
  }
  #delivery-full-filename-tooltip {
    margin-top: 0;
    .tooltip-inner {
      word-break: break-all;
    }
  }
  #common-inst-popover {
    max-width: 90vw;
  }
  #common-inst-popover, #wo-desc-popover {
    .popover-content {
      white-space: pre-line;
      word-break: break-word;
    }
  }
  .delivery-manager-page {
    margin: 0;
    display: flex;
    /*height: 100vh;*/
    flex-direction: column;
    .main-layout {
      flex: 1;
      main {
        height: 100%;
      }
    }
  }
  .modal:not(.wizard--modal, .rename-modal) {
    z-index: 1053 !important;
  }
  .react-confirm-alert-overlay {
    z-index: 1054 !important;
  }
`;

const Container = styled.div`
  height: 100%;
  max-height: 100%;
  overflow: hidden;
`;

const ButtonToolbar = styled.div`
  padding: 15px 30px 0;
  background: #fff;
  border-top: 1px solid transparent;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1020;

  &.space-between {
    justify-content: space-between;
  }

  .btn {
    margin: 0 0 15px;
    & + .btn {
      margin-left: 15px;
    }
  }
`;

const NoFiles = styled.div`
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin: 25px;
  opacity: 0.75;
  &:after {
    content: 'No Files';
  }
`;

DeliveryCommon.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  deliveryManager: PropTypes.object.isRequired,
  operationName: PropTypes.string.isRequired,
  paramsToGetFiles: PropTypes.object,
  paramsToSaveFiles: PropTypes.object,
  onExitDeliveryRename: PropTypes.func,
};

export default DeliveryCommon;
