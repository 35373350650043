import $ from 'jquery';
import config from '../../../config';
import {showError} from '../../layouts/actions';
import {getErrorMessage} from '../../utils';

export const NOTIFICATIONS_IS_LOADING = 'NOTIFICATIONS_IS_LOADING';
export const NOTIFICATIONS_RECEIVE = 'NOTIFICATIONS_RECEIVE';

export const getNotifications = asperaLinkId => dispatch => {
  dispatch({type: NOTIFICATIONS_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/notifications/${asperaLinkId}`
  })
  .done(notifications => {
    dispatch({type: NOTIFICATIONS_RECEIVE, notifications});
  })
  .fail(error => {
    dispatch({type: NOTIFICATIONS_IS_LOADING, loading: false});
    dispatch(showError(`Could not get notifications.  ${getErrorMessage(error)}`));
  });
};

const getSignedTokenForDownloadFiles = path => {
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/util/download-file`,
    data: {
      path: `/services${path}`
    }
  })
  .done(res => {
    const token = encodeURIComponent(res.token);
    window.open(`${config.apiUrl}${path}?&t=${token}`);
  })
  .fail(() => {});
};

export const downloadLogFile = logId => dispatch => {
  const path = `/v1/file_system/notifications/download/${logId}`;
  getSignedTokenForDownloadFiles(path);
};
