import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import {formatDate} from '../../../../utils';

export const PauseCell = ({value, row, className, toFormat, fromFormat, defaultValue, openScheduledPauseModal}) => {
  const isEditActionStatus = ['NEW', 'PAUSED', 'ACTIVE', 'WAITING_EXECUTOR_ACTION', 'WAITING_SUB_ACTION'].includes(row.actionStatus);
  const isEditActionType = ['COPY', 'MIGRATE_CONTENT_PORTAL', 'SIMPLE_COPY', 'MIGRATE_CONTENT'].includes(row.actionType);

  return (
    <td className={className}>
      <div>
        {
          isEditActionStatus && isEditActionType ?
              <Button
                bsStyle='primary'
                onClick={() => openScheduledPauseModal(row)}>
                Edit
              </Button> : null
        }
        <div>
          {formatDate(value, fromFormat, toFormat, defaultValue)}
        </div>
      </div>
    </td>
  );
}

PauseCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object,
  className: PropTypes.string,
  runners: PropTypes.object,
};
