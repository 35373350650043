import styled from 'styled-components';

const StyledFramePlayer = styled.div`
  background: #ccc;
  position: relative;

  flex-grow: 1;
  display: flex;
  flex-direction: column;

  div {
    transform-origin: 0 0;
  }

  #frame-player-content-wrapper {
    flex-grow: 1;
  }

  canvas {
  	width: 100%;
    height: 100%;
  }

  .fp-ctrl {
    bottom: 0;
    left: 0;
    margin: 0;
    z-index: 1;
    opacity: .6;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    span {
      margin-left: 5px;
      margin-right: 5px;
    }

    button {
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center center;
      border: 0;
      padding: 0;
      outline: none;
      width: 16px;
      text-indent: -9999px;
      &.play,
      &.pause,
      &.next-frame,
      &.prev-frame,
      &.captions {
        margin-left: 7px;
        margin-right: 7px;
        text-indent: 0;
        font-size: 14px;
        color: #555;
      }
    }
    
    input {
      width: 55px;
    }
  }
`;

export default StyledFramePlayer;