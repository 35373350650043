import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Row, Col} from 'react-bootstrap';

class Toolbar extends Component {
  onChangeEntriesNumber = event => {
    const {onChangePageSize} = this.props;
    onChangePageSize(Number(event.target.value));
  };

  render() {
    const {pageSize} = this.props;
    return (
      <StyledToolbar>
        <Row>
          <Col md={6}>
            <div className="entries-number">
              <label>
                Show
                <select value={pageSize} onChange={this.onChangeEntriesNumber}>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="75">75</option>
                  <option value="100">100</option>
                </select>
                entries
              </label>
            </div>
          </Col>
        </Row>
      </StyledToolbar>
    );
  }
}

const StyledToolbar = styled.div`
  margin: 25px 0;
  
  .row {
    margin-bottom: 15px;
  }

  .entries-number {
    select {
      margin: 0 5px;
    }
  }
`;

Toolbar.propsTypes = {
  onChangePageSize: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired
};

export default Toolbar;