import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import styled from 'styled-components';
import RndModal from '../../../../components/modals/RndModal';
import Loader from '../../../../components/Loader';
import {closeActionProgress, getActionProgress} from '../../actions';
import {StatusColor} from '../fs-actions/StatusCell';
import {formatDate} from "../../../../utils";

class ActionProgressModal extends Component {
  timerId = null;

  clearTimer = () => {
    if (this.timerId) {
      clearInterval(this.timerId);
      this.timerId = null;
    }
  };

  updateProgress = () => {
    const {dispatch, data: {actionID}} = this.props;
    dispatch(getActionProgress(actionID))
      .then(res => {
        if (res === 100) {
          this.clearTimer();
        }
      });
  };

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(closeActionProgress());
  };

  componentDidMount() {
    this.updateProgress();
    this.timerId = setInterval(this.updateProgress, 3000);
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  render() {
    const {loading, data: {actionID, rootActionID, parentActionID, actionType, progress, createdOn, actionRequestorName, actionStatus, details}, storageManager: {actions}} = this.props;
    const value = `${progress || (details && details.progress) || 0}%`;
    const actionIDValue = actionID || (details && details.actionID);
    const parentActionIDValue = parentActionID || (details && details.parentActionID);
    const rootActionIDValue = rootActionID || (details && details.rootActionID);
    let createdOnValue = createdOn || (details && details.created_on);
    let actionRequestorNameValue = actionRequestorName || (details && details.action_requestor_name);
    let statusValue = actionStatus || (details && details.status);

    let action = details;
    if (rootActionIDValue && rootActionIDValue !== actionIDValue ) {
      action = actions.filter(item => item.actionID === rootActionIDValue)[0];
      if (!action) {
        action = actions.filter(item => item.actionID === parentActionIDValue)[0];
      }

      if (action) {
        createdOnValue = action.created_on || action.createdOn;
        actionRequestorNameValue = action.action_requestor_name || action.actionRequestorName;
      }
    }

    return (
      <ModalStyled
        show={true}
        onHide={this.handleClose}
        backdrop="static"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Action #{actionID}: {actionType}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContainerStyled className="container-fluid">
            <label>Started On: {formatDate(createdOnValue, '', 'M/D/YYYY hh:mm:ss A')}</label>
            <label>Started By: {actionRequestorNameValue}</label>
            {statusValue &&
            <label>
              Status: {statusValue}
            </label>
            }
            <label>
              Progress:
              {loading && <Loader/>}
            </label>
            <div
              className="progress"
            >
              <div
                className={`progress-bar${progress >= 10 ? '' : ' color-gray'}`}
                style={{background: StatusColor.DONE, width: value}}
              >
                {value}
              </div>
            </div>
          </ModalContainerStyled>
        </Modal.Body>
      </ModalStyled>
    );
  }
}

const ModalStyled = styled(RndModal)`
  @media (min-width: 768px) {
    .modal-sm {
      min-width: fit-content;
      .modal-content, .react-draggable {
        min-width: fit-content !important;
      }
    }
  }
`;

const ModalContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px;
  label {
    margin-top: 10px;
    width: 100%;
    display: block;
    position: relative;
    font-weight: normal;
    .loader-container {
      left: 85px;
      text-align: left;
      .react-spinner {
        top: 0;
        width: 20px;
        height: 20px;
      }
    }
  }
  .progress {
    min-width: 150px;
    width: 100%;
    height: 40px;
    margin: 0 0 20px;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
    .progress-bar {
      float: left;
      width: 0%;
      height: 100%;
      font-size: 12px;
      line-height: 40px;
      color: #fff;
      text-align: center;
      background-color: #337ab7;
      box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
      transition: width .6s ease;
      &.color-gray {
        color: gray;
      }
    }
  }
`;

ActionProgressModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  storageManager: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ActionProgressModal;
