import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, FormGroup, FormControl, ControlLabel, OverlayTrigger, Popover} from 'react-bootstrap';
import styled from 'styled-components';
import {
  closeEncryptModal, singleEncryptOrDecrypt, getEncryptOrDecryptPassword
} from '../../actions';
import {PathFieldStyledAdv} from './ModalStyled';
import FolderExplorerNew from '../forms/FolderExplorerNew';
import {isInvalidEncryptPassword, ENC_PASSWORD_MIN_LENGTH, getValueForOS} from '../../utils';
import TextEllipsisAdvanced from '../../../../components/TextEllipsis/TextEllipsisAdvanced';
import RndModal from '../../../../components/modals/RndModal';

class EncryptModal extends Component {
  state = {
    selectedFolder: null,
    autoGenPassword: null,
    newPassword: '',
    showInvalidPassword: false,
    isVisiblePassword: false
  };

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(closeEncryptModal());
  };

  componentWillUnmount() {
    this.handleClose();
  }

  usePasswordVerification = () => {
    const {item: {isEncrypted}} = this.props;
    return !isEncrypted;
  };

  isInvalidEncPassword = () => {
    const {autoGenPassword, newPassword} = this.state;
    return this.usePasswordVerification() && autoGenPassword !== newPassword && isInvalidEncryptPassword(newPassword);
  };

  isEmptyEncPassword = () => {
    const {newPassword} = this.state;
    return newPassword.length === 0;
  };

  componentDidMount() {
    const {dispatch, item} = this.props;
    dispatch(getEncryptOrDecryptPassword(item)).then(
      password => this.setState({autoGenPassword: password || null, newPassword: password || '', showInvalidPassword: true}),
      error => this.setState({showInvalidPassword: true})
    );
  }

  handleConfirm = () => {
    const {dispatch} = this.props;
    const {selectedFolder, newPassword} = this.state;
    dispatch(singleEncryptOrDecrypt(selectedFolder, newPassword));
  };

  render() {
    const {item, folders, openedFolders, dispatch, loaders, currentView, tempDirectories, storageManager} = this.props;
    const {selectedFolder, showInvalidPassword, isVisiblePassword, newPassword} = this.state;
    const isInvalidPassword = this.isInvalidEncPassword();
    const isEmptyPassword = this.isEmptyEncPassword();
    return (
      <RndModal
        bsSize="large"
        className="move-modal"
        show={true}
        onHide={this.handleClose}
        backdrop="static"
        enforceFocus={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>
            {item.isEncrypted ? 'Decrypt' : 'Encrypt'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContainerStyled className="container-fluid">
            <PathFieldStyledAdv className="source">
              <label>Source:</label>
              <TextEllipsisAdvanced className="value" data={getValueForOS(item, 'key')}/>
            </PathFieldStyledAdv>
            <FolderExplorerNew
              dispatch={dispatch}
              contentItems={[item]}
              loaders={loaders}
              folders={folders}
              openedFolders={openedFolders}
              currentView={currentView}
              tempDirectories={tempDirectories}
              selectedFolder={selectedFolder}
              onSelectFolder={selFolder => this.setState({selectedFolder: selFolder})}
              selectedFolderLabel="Destination:"
              selectedFolderLabelWidth={115}
              subHeight={415}
              withoutNewFolders
              storageManager={storageManager}
            />
            <PasswordField validationState={showInvalidPassword && isInvalidPassword ? 'error' : null}>
              <ControlLabel htmlFor="enc-password">
                Password
                {this.usePasswordVerification() &&
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Popover id="enc-password-popover" className="filter-popover">
                        The new password you select can contain digits (0-9) and letters (A-Z, a-z).
                        <br/>
                        The password must at least be {ENC_PASSWORD_MIN_LENGTH} characters long.
                      </Popover>
                    }
                  >
                    <i className="fas fa-question-circle"/>
                  </OverlayTrigger>
                }
              </ControlLabel>
              <FormControl
                id="enc-password"
                name="enc-password"
                type={isVisiblePassword ? 'text' : 'password'}
                value={newPassword}
                autoComplete="off"
                onChange={event => this.setState({newPassword: event.target.value || ''})}
              />
              <i
                className={`far fa-eye${isVisiblePassword ? '' : '-slash'}`}
                onClick={() => this.setState({isVisiblePassword: !isVisiblePassword})}
              />
            </PasswordField>
          </ModalContainerStyled>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="primary"
            onClick={this.handleConfirm}
            disabled={!selectedFolder || isInvalidPassword || isEmptyPassword}
          >
            Confirm
          </Button>
          <Button bsStyle="default" onClick={this.handleClose}>Cancel</Button>
        </Modal.Footer>
      </RndModal>
    );
  }
}

const PasswordField = styled(FormGroup)`
  margin: 20px 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  label {
    margin: 0 5px 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: default;
  }
  input {
    padding-left: 8px;
    padding-right: 30px;
    height: 30px;
    cursor: text;
  }
  i[class*="fa-eye"] {
    position: absolute;
    top: 0;
    right: 6px;
    line-height: 30px;
    width: 18px;
    text-align: center;
    cursor: pointer;
    opacity: 0.45;
    :hover {
      opacity: 0.6;
    }
  }
  i[class*="fa-question"] {
    margin-left: 2px;
    color: #333 !important;
    opacity: 0.8;
  }
`;

const ModalContainerStyled = styled.div`
  min-height: 200px;
  .loader-container {
    z-index: 1051;
  }
`;

EncryptModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  loaders: PropTypes.object.isRequired,
  folders: PropTypes.array.isRequired,
  openedFolders: PropTypes.object.isRequired,
  currentView: PropTypes.any.isRequired,
  tempDirectories: PropTypes.any.isRequired,
  storageManager: PropTypes.object.isRequired
};

export default EncryptModal;
