import {USER_SET_IS_LOGOUT} from '../../pages/Login/actions';
import {
  ASSET_DELETION_IS_LOADING, ASSET_DELETION_RECEIVE,
  BROWSER_IS_LOADING_FILES, PATH_BROWSER_FETCHED_FILES, PATH_BROWSER_INCREMENTAL_FETCHED_FILES,
} from './actions';
import {regexDelimeter} from '../../utils';

const fixFoldersPath = path => file => ({
  ...file,
  key: file.key + (file.isDirectory ? '/' : ''),
});

/**
 * return files without parent files for path
 * @param path
 * @param files
 */
const cleanUpFolder = (path, files) => {
  return files.filter(file => {
    const comparePath = path.replace(regexDelimeter, '');
    const compareKey = file.key.replace(regexDelimeter, '');
    return comparePath === compareKey || !compareKey.startsWith(comparePath);
  });
};

const initialState = {
  loading: false,
  assets: [],
  loadingFiles: false,
  files: {},
  contentChildren: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SET_IS_LOGOUT: {
      return {...initialState};
    }

    case ASSET_DELETION_IS_LOADING: {
      return {...state, loading: action.loading};
    }

    case ASSET_DELETION_RECEIVE: {
      return {
        ...state,
        loading: false,
        assets: action.assets
      };
    }

    case BROWSER_IS_LOADING_FILES: {
      return {
        ...state,
        loadingFiles: action.loading
      };
    }

    case PATH_BROWSER_FETCHED_FILES: {
      return {
        ...state,
        loadingFiles: false,
        files: {
          ...state.files,
          [action.root]: action.files.map(fixFoldersPath(action.path))
        },
        contentChildren: {
          ...state.contentChildren,
          [action.contentChildren.contentId]: action.contentChildren.children
        }
      };
    }

    case PATH_BROWSER_INCREMENTAL_FETCHED_FILES: {
      const filesWithoutPathSubfolders = cleanUpFolder(action.path, state.files[action.root] || []);
      return {
        ...state,
        loadingFiles: false,
        files: {
          ...state.files,
          [action.root]: [...filesWithoutPathSubfolders, ...action.files.map(fixFoldersPath(action.path))]
        },
        contentChildren: {
          ...state.contentChildren,
          [action.contentChildren.contentId]: action.contentChildren.children
        }
      };
    }

    default:
      return state;
  }
}
