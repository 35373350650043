import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import {Alert} from 'react-bootstrap';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {isNetflixClient} from '../../../../../utils';
import {getStatusAndColor} from '../../../utils';
import classNames from 'classnames';
import {preparePathForMacOS, isMacOsPathView} from '../../../../StorageManager/utils';

class FileNameCell extends Component {
  state = {
    showAlert: false
  };

  onCopyToClipboard = () => {
    $(this.refs.input).select();
    document.execCommand('copy');
    this.setState({showAlert: true});
    setTimeout(() => {
      this.setState({showAlert: false});
    }, 2000);
  };

  render() {
    const {showAlert} = this.state;
    const {link, inPackage, onClickSelectDownloadedFolder, links, getFsRoot} = this.props;
    const statusAndColor = getStatusAndColor(link);
    const isUnknownErrorStatus = (statusAndColor && statusAndColor[0] && statusAndColor[0].status === 'UNKNOWN ERROR');
    const isNotUnknownStatus = isUnknownErrorStatus || (statusAndColor && statusAndColor[0] && statusAndColor[0].status !== 'UNKNOWN');
    const failedZeroPercent = (statusAndColor && statusAndColor[0] && statusAndColor[0].status==="FAILED" && statusAndColor[0].extra===" (0%)");
    const {
      file_name, client_name, WOMemfis, msg_subject, is_deleted, isPartiallyDeleted, need_set_operation_type, is_cancelled, folderKey, CategoryName, upload_id,
      PrimaryTitle, isFileNameFromWO, rootName
    } = link;
    const isAssetNotFound = isFileNameFromWO === false && !!PrimaryTitle && !!file_name && !(file_name.length>2 && file_name.substring(1,3)===":\\");
    const fnClassNames = classNames({
      'file-name': true,
      'is--asset-not-found': isAssetNotFound,
      'is--not-unknown-status': isNotUnknownStatus
    });
    const warnIcon = isAssetNotFound && isNotUnknownStatus && <i className="fas fa-exclamation-circle"/>;
    const clickToCopyTitle = 'Click to copy' + ((isNetflixClient(client_name) || WOMemfis) ? '' : '. Email subject: ' +
      (msg_subject ? msg_subject.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;').replace(/'/g, '&apos;') : 'EMPTY'));
    let fileNameForOS;
    if (rootName && getFsRoot && isMacOsPathView()) {
      const fsRoot = getFsRoot(rootName);
      if (fsRoot) {
        fileNameForOS = preparePathForMacOS(file_name, fsRoot);
      }
    }
    if (!fileNameForOS) {
      fileNameForOS = file_name;
    }

    return (
      <StyledCell>
        <>
          {(isPartiallyDeleted || need_set_operation_type || is_cancelled)? <PartiallyDeletedStyled>{isPartiallyDeleted?<span>Partially Deleted</span>:null}{need_set_operation_type?<span>Operation types NOT set</span>:null}{is_cancelled?<span>CANCELLED BY NETFLIX</span>:null}</PartiallyDeletedStyled> : null}
          {file_name && file_name !== '-' && !failedZeroPercent ? (
            <Fragment>
              <div className={fnClassNames}>
                <Link
                  to={WOMemfis ? `/storage?wo_memfis=${WOMemfis}&with_file_symlinks=true` : `/storage?nav=${encodeURIComponent(file_name)}`}
                  target="_blank"
                  className={is_deleted ? 'deleted' : undefined}
                >
                  {fileNameForOS}
                </Link>
                {warnIcon}
                <i className="far fa-clipboard" title="Copy to clipboard" onClick={() => this.onCopyToClipboard()}/>
              </div>
              <input
                ref="input"
                type="text"
                value={fileNameForOS}
                readOnly
                className="copy-file"
                title={clickToCopyTitle}
                onClick={() => this.onCopyToClipboard()}
                />
              {showAlert ? <Alert bsStyle="success">Copied to clipboard: <b>{fileNameForOS}</b></Alert> : null}
            </Fragment>
          ) : (inPackage || failedZeroPercent || (isUnknownErrorStatus && link.isSingleRow && !!link.package_id)) && !upload_id ? (
            !failedZeroPercent && folderKey ? (
              <>
                <span><label>Folder:</label>&nbsp;{folderKey}</span><br/>
                <span><label>Category&nbsp;Name:</label>&nbsp;{CategoryName}</span>
              </>
            ) : (
              <>
                <span
                  className="btn-select-folder"
                  onClick={() => onClickSelectDownloadedFolder(link, links)}
                >
                  Select Downloaded Folder
                </span>
                {CategoryName ? ` (${CategoryName})` : null}
              </>
            )
          ) : <span className={fnClassNames}>{fileNameForOS || ''}{warnIcon}</span>}
        </>
      </StyledCell>
    );
  }
}

const PartiallyDeletedStyled = styled.div`
  span {
    margin: 0 3px 3px 0;
    padding: 0 4px;
    background-color: #fff;
    border: 1px dotted red;
    border-radius: 5px;
    color: red;
    display: inline-block;
    text-transform: uppercase;
    text-shadow: none !important;
  }
`;

const StyledCell = styled.td`
  > .file-name {
    float: left;
    word-break: break-all;
    i.fa-clipboard {
      margin-left: 6px;
      color: #337ab7;
      opacity: 0;
      transform: translate(-8px, 0px);
      transition: opacity 0.2s ease 0s, transform 0.2s ease 0s;
    }
    &:hover {
      i.fa-clipboard {
        opacity: 1;
        transform: translate(-0px, 0px);
        &:hover {
          color: #23527c;
          cursor: pointer;
        }
      }
    }
    a {
      &.deleted {
        color: red !important;
        text-decoration: line-through;
      }
    }

    &.is--asset-not-found {
      &, a {
        color: red;
      }
      &.is--not-unknown-status {
        i[class*="exclamation"] {
          margin-left: 6px;
          font-size: 1.4rem;
          &.fa-exclamation-circle {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  input.copy-file {
    background-color: transparent;
    color: transparent;
    border: 0;
    outline: 0;
    margin: 0;
    padding: 0;
    width: 1px;
    height: 0;
    float: left;
  }

  .alert {
    position: fixed;
    top: 60px;
    left: 5px;
    width: calc(100% - 10px);
    margin: 0;
    z-index: 10000;

    animation: alert-animation 2s 1s ease-in;
    @keyframes alert-animation {
      from {
       opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }

  .btn-select-folder {
    color: #009600;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

FileNameCell.propType = {
  link: PropTypes.object.isRequired,
  links: PropTypes.array,
  inPackage: PropTypes.bool,
  onClickSelectDownloadedFolder: PropTypes.func,
  getFsRoot: PropTypes.func,
};

export default FileNameCell;