import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import {Modal, Button} from 'react-bootstrap';
import {confirmAlert} from 'react-confirm-alert';
import Loader from '../Loader';
import ReportBugButton from '../ReportBugButton';
import RndModal from '../modals/RndModal';

class Wizard extends Component {
  state = {
    activeStepKey: Object.keys(this.props.steps)[0] || null,
  };

  getActiveStepKey = () => {
    const {activeStepKey} = this.state;
    return activeStepKey;
  };

  setActiveStepKey = (activeStepKey) => {
    this.setState({activeStepKey});
  };

  componentDidMount() {
    this.props.onRef(this);
  }

  getFirstStepKey = () => {
    const {steps} = this.props;
    return Object.keys(steps)[0] || null;
  };

  getLastStepKey = () => {
    const {steps} = this.props;
    return Object.keys(steps)[Object.keys(steps).length - 1] || null;
  };

  handleCancel = () => {
    const {activeStepKey} = this.state;
    const {wizardName, onCancel, forceConfirmOnCancel} = this.props;
    if (activeStepKey === this.getFirstStepKey() && !forceConfirmOnCancel) {
      onCancel();
    } else {
      confirmAlert({
        childrenElement: () =>
          <h5 className="custom-msg">
            Are you sure you want to cancel "{wizardName}"?
          </h5>,
        buttons: [{
          label: 'No'
        }, {
          label: 'Yes',
          onClick: () => {
            onCancel();
          }
        }]
      });
    }
  };

  handleNewArtworkWorkOrder = (activeStep) => {
    const {activeStepKey} = this.state;
    const {onNew} = this.props;

    onNew(activeStepKey);
  }

  handleBack = (activeStep) => {
    const {activeStepKey} = this.state;

    const {onBack} = activeStep;
    if (onBack) {
      const prevStepKey = onBack(activeStepKey);
      if (prevStepKey) {
        this.setActiveStepKey(prevStepKey);
        return;
      }
    }

    const stepKeys = Object.keys(this.props.steps);
    let prevStepKey = activeStepKey;
    for (let k of stepKeys) {
      if (activeStepKey === k) {
        break;
      }
      prevStepKey = k;
    }
    this.setActiveStepKey(prevStepKey);
  };

  handleContinue = (activeStep) => {
    const {activeStepKey} = this.state;

    const {onContinue} = activeStep;
    if (onContinue) {
      const nextStepKey = onContinue(activeStepKey);
      if (nextStepKey) {
        this.setActiveStepKey(nextStepKey);
        return;
      }
    }

    if (activeStepKey !== this.getLastStepKey()) {
      const stepKeys = Object.keys(this.props.steps);
      let nextStepKey = activeStepKey, applyNextStepKey = false;
      for (let k of stepKeys) {
        if (activeStepKey === k) {
          applyNextStepKey = true;
        } else if (applyNextStepKey) {
          nextStepKey = k;
          break;
        }
      }
      this.setActiveStepKey(nextStepKey);
    }
  };

  render() {
    const {activeStepKey} = this.state;
    if (!activeStepKey) {
      return null;
    }

    const {wizardName, steps, className, dialogClassName, bsSize, isLoading, getChildren, width, minHeight} = this.props;
    const activeStep = steps[activeStepKey] || {};
    const stepName = activeStep.stepName || activeStepKey;
    const header = stepName && stepName !== '-' ? `${wizardName}: ${stepName}` : wizardName;

    return (
      <RndModal
        className={classNames({'wizard--modal': true, ...(className ? {[className]: true} : {})})}
        dialogClassName={dialogClassName || 'wizard-modal'}
        bsSize={typeof bsSize === 'function' ? bsSize(activeStepKey) : bsSize}
        width={width}
        minHeight={minHeight}
        backdrop="static"
        enforceFocus={false}
        show={true}
        onHide={this.handleCancel}
      >
        <Modal.Header>
          <Modal.Title>
            {header}
          <ReportBugButton isForModal={true} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContainer className="container-fluid">
            {isLoading &&
            <div>
              <Loader/>
              <ReportBugButton isForModal className="loader-report-bug-btn"/>
            </div>}
            {getChildren(activeStepKey)}
          </ModalContainer>
        </Modal.Body>
        <Modal.Footer>
          {
            activeStep.allowNewBtn ?
              <CustomButtonContainerStyled>
                <Button
                  bsStyle="primary"
                  onClick={() => this.handleNewArtworkWorkOrder(activeStep)}
                >
                  {activeStep.newBtnTitle}
                </Button>
              </CustomButtonContainerStyled> : null
          }

          <Button
            bsStyle="default"
            disabled={activeStep.allowBack ? !activeStep.allowBack() : (activeStepKey === this.getFirstStepKey())}
            onClick={() => this.handleBack(activeStep)}
          >
            Back
          </Button>
          <Button
            bsStyle="primary"
            title={activeStep.getTitleContinue && activeStep.getTitleContinue()}
            disabled={activeStep.allowContinue && !activeStep.allowContinue()}
            onClick={() => this.handleContinue(activeStep)}
          >
            {(activeStep.getLabelContinue && activeStep.getLabelContinue()) || (activeStepKey === this.getLastStepKey() ? 'Submit' : 'Continue')}
            {activeStep.getInfoContinue && activeStep.getInfoContinue()}
          </Button>
          <Button
            bsStyle="default"
            onClick={this.handleCancel}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </RndModal>
    );
  }
}

const CustomButtonContainerStyled = styled.div`
  float: left;
`;

const ModalContainer = styled.div`
  min-height: 200px;
  .loader-container {
    z-index: 1051;
  }
`;

Wizard.propTypes = {
  onRef: PropTypes.func.isRequired,
  wizardName: PropTypes.string.isRequired,
  steps: PropTypes.object.isRequired,
  getChildren: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  onNew: PropTypes.func,
  className: PropTypes.string,
  dialogClassName: PropTypes.string,
  bsSize: PropTypes.oneOfType([PropTypes.oneOf(['large', 'small']), PropTypes.func]),
  isLoading: PropTypes.bool.isRequired,
  forceConfirmOnCancel: PropTypes.bool.isRequired,
};

Wizard.defaultProps = {
  isLoading: false,
  forceConfirmOnCancel: false,
  getChildren: () => {},
};

export default Wizard;

