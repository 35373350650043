import {USER_SET_IS_LOGOUT} from '../../pages/Login/actions';
import {
  ASPERA_CLIENTS_CHANGE_CHECKED_ITEMS,
  ASPERA_CLIENTS_CLEAR_ALL,
  ASPERA_CLIENTS_LOAD_PACKAGES,
  ASPERA_CLIENTS_SET_LOADING,
  ASPERA_CLIENTS_SHOW_DOWNLOAD_ASPERA_PACKAGE_MODAL
} from './actions';

const initialState = {
  checkedItems: [],
  downloadPackageHref: null,
  loading: false,
  packages: null,
  actionsRequestorsMap: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ASPERA_CLIENTS_CLEAR_ALL:
    case USER_SET_IS_LOGOUT: {
      return {...initialState};
    }

    case ASPERA_CLIENTS_SET_LOADING: {
      return {...state, loading: action.loading};
    }

    case ASPERA_CLIENTS_LOAD_PACKAGES: {
      return {...state, packages: action.res};
    }

    case ASPERA_CLIENTS_CHANGE_CHECKED_ITEMS: {
      return {...state, checkedItems: action.items};
    }

    case ASPERA_CLIENTS_SHOW_DOWNLOAD_ASPERA_PACKAGE_MODAL: {
      const {isShow, href} = action;
      return {
        ...state,
        isShowDownloadAsperaPackageModal: isShow,
        downloadPackageHref: href || initialState.downloadPackageHref
      };
    }

    default:
      return state;
  }
}
