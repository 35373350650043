import React from 'react';
import PropTypes from 'prop-types';
import InstructionsPopup from './InstructionsPopup';
import {INSTRUCTIONS__GENERAL} from '../constants';

function Instructions({data, onClose}) {
  return data.map((k, i) =>
    <InstructionsPopup
      key={k.key}
      index={i}
      header={k.key}
      text={k.text}
      onHide={() => onClose(k.key)}
      general={k.key === INSTRUCTIONS__GENERAL}
    />
  );
};

Instructions.propTypes = {
  data: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired
};

export default Instructions;
