import $ from "jquery";
import config from "../../../config";
import {prepareFile} from "../../pages/StorageManager/actions";

export const EXPLORER__LOAD_SUBFOLDERS = 'EXPLORER__LOAD_SUBFOLDERS';
export const EXPLORER__SET_EXPANDED = 'EXPLORER__SET_EXPANDED';
export const EXPLORER__SWITCH_CHECKED = 'EXPLORER__SWITCH_CHECKED';

export const folderManagerGetSubfolderAndExpand = (folder, newFolderName) => (dispatch) => {
  const {fsRootID, contentID, predictiveStateID} = folder;
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/root/${fsRootID}/content/${contentID > 0 ? contentID : 0}`,
    data: {
      with_children: true,
      use_predictive: true,
    }
  }).done((res) => {
    const {children} = (res.data || {});
    const parents = [...folder.parents || [], contentID];
    const parentsPredictiveStates = [...folder.parentsPredictiveStates || [], predictiveStateID || 0];
    const allParents = [...folder.allParents || [], {contentID, predictiveStateID}];
    const preparedChildren = (children || [])
      .map(prepareFile)
      .map(item => ({...item, parents, parentsPredictiveStates, allParents}));
    dispatch({
      type: EXPLORER__LOAD_SUBFOLDERS,
      folder,
      children: preparedChildren,
      allParents,
    });
    if(newFolderName) {
      const createdFolder = preparedChildren.find((child) => child.name === newFolderName)
      dispatch({type: EXPLORER__SWITCH_CHECKED, folder: createdFolder});
    }
  });
};
