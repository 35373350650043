import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, FormGroup, FormControl, ControlLabel} from 'react-bootstrap';
import Select from 'react-select';
import styled from 'styled-components';
import {getErrorMessage, isValidFileName} from '../../../../utils';
import { executeUploadToAsperaAction, getFaspexAccounts, STORAGE_MNG_LOAD_FASPEX_ACCOUNTS, STORAGE_MNG_SET_LOADING } from '../../actions';
import { showError } from '../../../../layouts/actions';
import RndModal from '../../../../components/modals/RndModal';

class UploadToAsperaModal extends Component {
  state = {
    packageTitle: '',
    selectedOption: null,
  };

  componentDidMount() {
    const name = 'executeGetFaspexAccounts';
    const {dispatch} = this.props;
    dispatch(getFaspexAccounts())
      .done(res => {
        dispatch({
          type: STORAGE_MNG_SET_LOADING,
          name,
          loading: false
        });
        dispatch({type: STORAGE_MNG_LOAD_FASPEX_ACCOUNTS, faspexAccounts: res});
        if(res && res.length === 1 && this.state.selectedOption === null) {
          this.SetSelectedOption({value: res[0].faspexAccountID, label: res[0].userName});
        }
      })
      .fail(error => {
        dispatch({
          type: STORAGE_MNG_SET_LOADING,
          name,
          loading: false
        });
        dispatch(showError(`Error when getting Faspex Accounts.  ${getErrorMessage(error)}`));
      });
  }


  SetSelectedOption = (newOption) => {
    this.setState({
      ...this.state,
      selectedOption: newOption
    });
  }

  isInvalidName = () => {
    const {packageTitle} = this.state;
    return !isValidFileName(packageTitle);
  };

  handleChangeTitle = event => {
    this.setState({
      ...this.state,
      packageTitle: event.target.value
    });
  };

  handleUpload = () => {
    const {packageTitle, selectedOption} = this.state;
    const {dispatch, items} = this.props;
    const contentIDs = items.map(item => item.contentID);
    dispatch(executeUploadToAsperaAction(items[0].fsRootID, contentIDs, packageTitle, selectedOption.label, selectedOption.value));
  };

  componentWillUnmount() {
    const {onClose} = this.props;
    onClose();
  }

  render() {
    const {faspexAccounts, onClose} = this.props;
    const {packageTitle, selectedOption} = this.state;
    let selectOptions;
    if(faspexAccounts && faspexAccounts.length > 1) {
      selectOptions = faspexAccounts.map(account => ({value: account.faspexAccountID, label: account.userName}));
    }
    return (
      <RndModal
        show={true}
        onHide={onClose}
        backdrop="static"
        enforceFocus={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>Upload to Aspera</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <FormGroupStyled>
              <ControlLabel>Package Title</ControlLabel>
              <FormControl
                value={packageTitle}
                onChange={this.handleChangeTitle}
                onBlur={this.handleChangeTitle}
                inputRef={ref => {
                  this._input = ref;
                }}
              />
            </FormGroupStyled>
            {faspexAccounts && faspexAccounts.length > 1 && <FormGroupStyled>
              <ControlLabel>Package Title</ControlLabel>
              <Select
                value={selectedOption}
                options={selectOptions}
                onChange={this.SetSelectedOption}
                searchable={false}
                clearable={false}
              />
            </FormGroupStyled>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.handleUpload} disabled={this.isInvalidName()}>Upload</Button>
          <Button bsStyle="default" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
      </RndModal>
    );
  }
}

const FormGroupStyled = styled(FormGroup)`
  margin-bottom: 6px;
  .control-label {
    font-size: 10px;
    margin-left: 0;
    padding: 0;
  }
`;

UploadToAsperaModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired
};

export default UploadToAsperaModal;
