import React, { Component } from "react";
import { Label } from "react-bootstrap";
import {getStatusLabelStyle} from "../../../utils";
import StatusCellStyled from "./styled/StatusCell.styled";

export default class StatusCell extends Component {
  render() {
    const { row, className } = this.props;
    const value = row.StatusAlt;
    return (
      <StatusCellStyled className={className}>
        <Label bsStyle={getStatusLabelStyle(value)} title={value}>{value}</Label>
      </StatusCellStyled>
    );
  }
}
