import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import Table from '../table/InstantScan/Table';
import {getValueForOS} from '../../utils';
import TextEllipsisAdvanced from '../../../../components/TextEllipsis/TextEllipsisAdvanced';
import RndModal from '../../../../components/modals/RndModal';

class InstantScanModal extends Component {
  componentWillUnmount() {
    this.props.onClose();
  }

  render() {
    const {onClose, item, data, onInstantScan, loading} = this.props;
    return (
      <RndModal
        bsSize="large"
        show={true}
        onHide={onClose}
        backdrop="static"
        enforceFocus={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>
            Instant scan result
            <br/>
            <TextEllipsisAdvanced style={{color: 'blue', fontSize: '12px'}} data={getValueForOS(item, 'key')}/>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <Table
              data={data}
              loading={loading}
              onInstantScan={onInstantScan}
            />
          </div>
        </Modal.Body>
      </RndModal>
    );
  }
}

InstantScanModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onInstantScan: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired
};

export default InstantScanModal;
