export const ACTION_URL_FILTER_OWN_ACTIONS = 'own_actions';
export const ACTION_URL_FILTER_POPUP_FLAG = 'show_popup';
export const ACTION_URL_FILTER_TYPE_ID = 'action_type_id';
export const ACTION_URL_FILTER_RUNNER_ID = 'action_runner_id';
export const ACTION_URL_FILTER_DAYS_BACK = 'action_days_back';

export const ACTION_STATUS_ACTIVE = 'ACTIVE';

export const ACTION_FILTER_STATUS = 'status';
export const ACTION_FILTER_TYPE_ID = 'type_id';
export const ACTION_FILTER_FS_ROOT_ID = 'fs_root_id';
export const ACTION_FILTER_CONTENT_ID = 'content_id';
export const ACTION_FILTER_ACTION_ID = 'action_id';
export const ACTION_FILTER_REQUESTOR_ID = 'requestor_id';
export const ACTION_FILTER_CREATED_ON = 'created_on';
export const ACTION_FILTER_RUNNER_ID = 'runner_id';

export const ACTIONS_FILTERS =
[{
  name: ACTION_FILTER_STATUS,
  title: 'Status',
  type: 'select',
  values: []
}, {
  name: ACTION_FILTER_TYPE_ID,
  title: 'Type',
  type: 'select',
  values: []
}, {
  name: ACTION_FILTER_FS_ROOT_ID,
  title: 'FS Root',
  type: 'select',
  values: []
}, {
  name: ACTION_FILTER_CONTENT_ID,
  title: 'Content ID(s)',
  type: 'string',
  placeholder: 'Enter separated by commas'
}, {
  name: ACTION_FILTER_REQUESTOR_ID,
  title: 'Requestor',
  type: 'select',
  values: []
}, {
  name: 'filename',
  title: 'Filename',
  type: 'string',
  placeholder: 'Searching by a pattern *text? to trace history of changes to certain filenames',
  tooltip: '? means any character, * means any sequence of characters',
  overlay: '? means any character, * means any sequence of characters'
}, {
  name: ACTION_FILTER_CREATED_ON,
  title: 'Created On',
  type: 'date'
}, {
  name: 'expiration_date',
  title: 'Expiration Date',
  type: 'date'
}, {
  name: 'performed_on',
  title: 'Performed On',
  type: 'date'
}, {
  name: 'last_active_on',
  title: 'Last Active On',
  type: 'date'
}, {
  name: ACTION_FILTER_ACTION_ID,
  title: 'Action ID',
  type: 'number',
  placeholder: 'Enter number'
}, {
  name: ACTION_FILTER_RUNNER_ID,
  title: 'Runner',
  type: 'select',
  values: []
}];

export const SELECTABLE_ACTION_FILTER_NAMES = ACTIONS_FILTERS.filter(({type}) => type === 'select').map(({name}) => name);

export const ACTION__SOURCE_OF_REQUEST__URL = 'url';
export const ACTION__SOURCE_OF_REQUEST__ACTIONSBAR = 'btn1';
export const ACTION__SOURCE_OF_REQUEST__ACTIONSVIEW = 'btn2';

export const ACTION_DEFAULT_PAGE_SIZE = 10;
export const ACTION_DEFAULT_SORT = {
  field: 'actionID',
  direction: 'desc'
};
