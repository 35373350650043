import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {isDeletedStatus, getNameForOS} from '../../../utils';
import TextEllipsisAdvanced from '../../../../../components/TextEllipsis/TextEllipsisAdvanced';
import {defaultSymlinkTraits, getSymlinkCellTraits} from "./symlinkCellTraits";
import {FileTypeIcon} from './FileTypeIcon';

export const prepareFileName = (row, symlinkRelativePath) => {
  const [name, rootNameWithOffice] = getNameForOS(row);
  if (!name) {
    return null;
  }
  if (rootNameWithOffice) {
    return <><span>{name}</span> <span className="root-name-with-office">({rootNameWithOffice})</span></>;
  }
  return (
    <TextEllipsisAdvanced
      data={name}
      tooltipText={symlinkRelativePath}
      noCopyCtrl
      nonMonospaceFont
    />
  );
};

export const prepareFileIcon = (row, isOpened, isDeleted) => {
  if (row.isDirectory) {
    const className = isOpened(row) ? 'far fa-folder-open' : 'far fa-folder';
    return <i className={'std--folder--icon ' + className + (isDeleted ? ' deleted' : '')} />;
  }

  const fileExtension = (row.fileExtensionP || '').toLowerCase();
  return <FileTypeIcon value={fileExtension} className={isDeleted ? 'deleted' : ''} />
};

export const NameCell = (props) => {
  const {
    dispatch,
    history,
    row,
    getTextClassName,
    isOpened,
    onClick,
    className,
    isNeedToConnectDrive,
    getConnectDriveComponent
  } = props;

  const cellRef = useRef(null);

  const depth = !row.parents ? 0 : row.parents.length;
  const paddingLeft = depth > 0 ? depth * 16 + 8 : undefined;

  if (row.isEllipsis) {
    return (
      <StyledCell
        ref={cellRef}
        className={`${className || ''} td-ellipsis`}
        paddingLeft={paddingLeft}
      >
        &hellip;
      </StyledCell>
    );
  }

  const isDirectory = row.isDirectory !== false;
  let symlinkTraits = defaultSymlinkTraits;
  if (row.isSymlink) {
    symlinkTraits = getSymlinkCellTraits(row, dispatch, history);
  }
  const contentId = row.contentID > 0 ? (!isDirectory ? row.parentID : row.contentID) : undefined;
  const textClassName = getTextClassName(row);
  const isDeleted = isDeletedStatus(row);
  const isNeedsDriveConnection = isNeedToConnectDrive(row);
  return (
    <StyledCell
      ref={cellRef}
      className={`${
        className || ''}${
        !isDirectory ? '' : ' is-directory'}${
        isDeleted ? ' deleted' : ''}${
        row.isSymlink ? ' is-symlink' : ''}`
      }
      paddingLeft={paddingLeft}
      >
      <div className={`cell-cont${isNeedsDriveConnection ? ' needs-drive-connection' : ''}`}>
        {isNeedsDriveConnection && getConnectDriveComponent(row)}
        <div className="cell-icons"
             role='button'
             onClick={symlinkTraits.onClickSymlink}>
          {symlinkTraits.iconClassName ? <i className={symlinkTraits.iconClassName}/> : prepareFileIcon(row, isOpened)}
          {row.isDirectory && row.isSymlink ? <i className='far fa-folder'/> : null}
        </div>
        <div className="cell-value">
          {
            isDeleted ? <span className={`deleted ${textClassName}`}>{prepareFileName(row)}</span> :
              <a
                className={textClassName}
                title={symlinkTraits.symlinkRelativePath}
                href={`/storage?root_id=${row.fsRootID}${contentId ? `&content_id=${contentId}` : ''}`}
                onClick={event => {
                  if (event.ctrlKey) {
                    event.stopPropagation();
                  } else {
                    event.preventDefault();
                    if (isDirectory) {
                      event.stopPropagation();
                      onClick(row);
                    }
                  }
                }}
                >
                {prepareFileName(row, symlinkTraits.symlinkRelativePath)}
              </a>
          }
        </div>
      </div>
    </StyledCell>
  );
};

const StyledCell = styled.td`
  ${props => props.paddingLeft && css`
    padding-left: ${props.paddingLeft}px !important;
  `}

  position: relative;
  padding:0!important;
  height: 100%;

  .strikethrough {
    text-decoration:line-through;
  }

  &.is-symlink a {
    color: #bb38bb;
  }

  .cell-cont {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .cell-icons {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;

      & + .cell-value {
        flex-grow: 1;
        white-space: nowrap;
        /*overflow: hidden;*/
        height: 100%;
        max-width: calc(100% - 26px);
        display: flex;
        align-items: center;
        justify-content: flex-start;

        a, span.deleted {
          max-width: 100%;
        }
      }
    }

    &.needs-drive-connection {
      &:not(:hover) {
        .connect-drive {
          display: none;
        }
      }
      .connect-drive {
        position: absolute;
        top: -4px;
        left: -4px;
        width: calc(100% + 8px);
        height: 100%;
        button {
          width: 100%;
          background-color: #efefefd6;
          border-color: #8a8a8a;
          border-radius: 4px;
          &:hover {
            background-color: #efefef;
            border-color: #ccc;
          }
        }
      }
      i, input, a {
        pointer-events: none;
      }
    }
  }

  &.deleted {
    i {
      color: red;
    }
  }

  i {
    margin-right: 0.5rem;
  }

  &.is-directory {
    span {
      &:hover {
        cursor: pointer;
      }
    }
  }

  a, span {
    &.deleted {
      /*pointer-events: none;*/
      color: red !important;
      text-decoration: line-through;
    }
    &.red {
      color: red !important;
    }
    &.strikethrough {
      text-decoration: line-through;
    }
  }

  .std--folder--icon, .std--symlink--icon, .file--type--icon {
    margin-right: 0.5rem;
    width: 20px;
    text-align: center;
  }
`;

NameCell.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  getTextClassName: PropTypes.func,
  history: PropTypes.object.isRequired,
  isOpened: PropTypes.func,
  onClick: PropTypes.func,
  row: PropTypes.object,
  value: PropTypes.any,
};
