import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "../../../../components/table/Table";
import IssueBrowserTableStyled from "./Table.styled";
import {isEqualsBugReports, saveSettingsToStorage, getSettingsFromStorage} from "../../utils";
import { changeSelectedItem } from "../../actions";
import InfoPanel from "../infopanel/InfoPanel";
import { getSortedRowsBy, formatXDate } from '../../../../utils';
import ISSUE_TYPES from "../../constants/types";
import {ISSUE_BROWSER_TABLE_HEADERS} from "../../constants/headers";
import {CheckboxCell} from "../../../../components/table/cells/CheckboxCell";

class IssueBrowserTable extends Component {
  constructor() {
    super();
    this.state = {
      tableSort: getSettingsFromStorage('sorting') || {
        field: '',
        direction: 'desc'
      }
    };
  }

  componentDidUpdate(preProps) {
    const {selectedItem} = this.props;
    if (Boolean(selectedItem) !== Boolean(preProps.selectedItem)) {
      for (let i = 1; i <= 2; i++) {
        setTimeout((ii) => {
          try {
            let event;
            if (typeof (Event) === 'function') {
              event = new Event('resize');
            } else { /*IE*/
              event = document.createEvent('Event');
              event.initEvent('resize', true, true);
            }
            window.dispatchEvent(event);
          } catch {}
          if (ii === 2 && selectedItem && selectedItem.needToScroll) {
            try {
              document.getElementsByClassName(`report-bug-row--${selectedItem.ReportedBugID}`)[0].scrollIntoView(false);
            } catch {}
          }
        }, 500, i);
      }
    }
  }

  handleIsChecked = (row) => {
    const { selectedItem } = this.props;
    if (selectedItem) {
      return isEqualsBugReports(selectedItem, row);
    }
    return false;
  };

  handleOnCheck = (checked, row) => {
    const { dispatch } = this.props;
    dispatch(changeSelectedItem(checked ? row : null));
  };

  headers = {
    selected: {
      title: '',
      className: 'checkbox-cell without-sort',
      component: CheckboxCell,
      componentArgs: {
        isChecked: this.handleIsChecked,
        onChange: this.handleOnCheck,
      },
    },
    ...ISSUE_BROWSER_TABLE_HEADERS,
  };

  sortAltFields = {
    [ISSUE_TYPES.STATUS]: 'StatusAlt',
    [ISSUE_TYPES.PRIORITY]: 'PriorityAlt',
    [ISSUE_TYPES.CATEGORY]: 'CategoryAlt',
  };

  sortAltFieldsGet = {
    [ISSUE_TYPES.CREATED_ON]: (v, r) => formatXDate(v, -1),
    [ISSUE_TYPES.UPDATED_ON]: (v, r) => formatXDate(v, -1),
  };

  handleHeaderCellClick = (field, direction) => {
    const tableSort = {field, direction};
    saveSettingsToStorage('sorting', tableSort);
    this.setState({tableSort});
  };

  render() {
    const { filteredData, selectedItem } = this.props;
    const { tableSort } = this.state;

    const sortedData = getSortedRowsBy(filteredData, tableSort.field, tableSort.direction, this.sortAltFields, this.sortAltFieldsGet);

    const isInfoPanelCollapsed = !selectedItem;
    return (
      <IssueBrowserTableStyled
        className={isInfoPanelCollapsed ? "info-panel-collapsed" : undefined}
      >
        <div className="col-table">
          <Table
            headers={this.headers}
            onHeaderCellClick={this.handleHeaderCellClick}
            sort={tableSort}
            rows={sortedData}
            getRowClassNames={row => `report-bug-row--${row.ReportedBugID}`}
            emptyFoot
            emptyText="No data"
          />
        </div>
        <div className="col-info-panel">
          {!isInfoPanelCollapsed ? <InfoPanel /> : null}
        </div>
      </IssueBrowserTableStyled>
    );
  }
}

function mapStateToProps(state) {
  return {
    filteredData: state.issueBrowser.filteredData,
    selectedItem: state.issueBrowser.selectedItem,
  };
}

export default connect(mapStateToProps)(IssueBrowserTable);
