import React, {Component} from "react";
import {Button, Modal} from "react-bootstrap";
import {closeMyActionsView, loadMyActions, showActionProgress, switchMyActionsModal, showActionSubscriberView} from "../../actions";
import RndModal from "../../../../components/modals/RndModal";
import {formatDate} from "../../../../utils";
import styled from "styled-components";
import {StatusColor} from "../fs-actions/StatusCell";

class MyActionsModal extends Component {
  componentWillMount() {
    const {dispatch} = this.props;
    dispatch(loadMyActions());
  }

  onClose = () => {
    const {dispatch} = this.props;
    dispatch(switchMyActionsModal(false));
    dispatch(closeMyActionsView());
  }

  handleShowProgressMyActions = action => {
    const {dispatch} = this.props;
    dispatch(showActionProgress(action));
  };

  handleActionSubscriptions = action => {
    const {dispatch} = this.props;
    dispatch(showActionSubscriberView(action));
  };

  handleClickRefresh = () => {
    const {dispatch} = this.props;
    dispatch(loadMyActions());
  }

  statusCell = row => {
    const {progress} = row;
    return (
      <>
        <div
          className={`progress`}
          onClick={() => this.handleShowProgressMyActions(row)}
          title={`Progress: ${progress || 0}%`}
        >
          <div
            className="progress-bar"
            style={{background: StatusColor.DONE, width: `${progress || 0}%`}}
          >
            {progress >= 25 ? `${progress}%` : ''}
          </div>
        </div>
      </>
    );
  };

  renderContent = (row) => {
    let content = '-';
    for (let parameter of row.parameters) {
      if (parameter.value && (parameter.name === 'Content' || parameter.name === 'DESTINATION' || parameter.name ===  'SOURCE')) {
        content = parameter.value;
      }
    }

    for (let parameter of row.parameters) {
      if (parameter.value && parameter.name === 'DESTINATION') {
        content = parameter.value;
      }
    }

    for (let parameter of row.parameters) {
      if (parameter.value && parameter.name ===  'SOURCE') {
        content = parameter.value;
      }
    }

    let subContent = content;
    if (content !== '-' && content.length > 25) {
      subContent = content.substring(0, 12);
      subContent += '...';
      subContent += content.substring(content.length - 13, content.length);
    }

    return (
      <div className="content-cell" title={content}>
        {subContent}
      </div>
    );
  }

  renderStatus = (row) => {
    let status = 'DONE';
    const failedArr = ['FAILED','EXPIRED','REJECTED','DISABLED_BY_FAILURE','MIA','INCOMPLETE'];
    if (failedArr.includes(row.actionStatus)) {
      status = "FAILED";
    }

    const inProgressArr = ['NEW','ACTIVE','WAITING_SUB_ACTION','WAITING_EXECUTOR_ACTION','POST_RESTARTING','WAITING_RESTART','PAUSED'];
    if (inProgressArr.includes(row.actionStatus)) {
      status = "In Progress";

      if (row.showProgress) {
        return (
          <>
            {this.statusCell(row)}
          </>
        );
      }

      if (row.actionStatus === 'WAITING_SUB_ACTION' && row.hasChildren) {
        for (let child of row.childrenList) {
          if (child.actionStatus === 'ACTIVE' || child.actionStatus === 'WAITING_EXECUTOR_ACTION') {
            status += ': ' + child.actionType;
          }
        }
      }
    }

    return (
      <>
        {status}
      </>
    );
  }

  render() {
    const {storageManager} = this.props;
    const {actionsRequestorsMap, myActions} = storageManager;
    const meRequestor = Object.entries(actionsRequestorsMap || {}).find(([k, v]) => v === 'me');

    return (
      <RndModal
        className="move-modal"
        show={true}
        onHide={this.onClose}
        backdrop="static"
        enforceFocus={false}
        bsSize="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            My Actions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-container">
            <div className="table-block">
              <StyleTable className={`table`}>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Started On</th>
                    <th>Action</th>
                    <th>Content</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {myActions && myActions.length > 0 && myActions.map((row, i) =>
                    <tr key={`${row.actionID}_${row.expirationDateLong}`}>
                      <td className="action-id-column">
                        <a target="_blank" href={`?action_id=${row.actionID}`} rel="noopener noreferrer">{row.actionID}</a>
                      </td>
                      <td className="date-column">
                        {formatDate(row.createdOn, '', 'M/D/YYYY hh:mm:ss A')}
                      </td>
                      <td className="action-type-column">
                        {row.actionType}
                      </td>
                      <td className="content-column">
                        {this.renderContent(row)}
                      </td>
                      <StyledCell className="status-column">
                        {this.renderStatus(row)}
                      </StyledCell>
                      <td className="subscriber-column" align="center">
                        <button className={row.finalActionStatus === true ? 'btn btn-default disabled' : 'btn btn-default'}
                          disabled={row.finalActionStatus === true}
                          title={row.finalActionStatus === true ? 'Watch (Completed/failed actions cannot be watched)' : 'Watch'}
                          onClick={() => this.handleActionSubscriptions(row)}>
                          <i className="fa-light fa-bell-on"/>
                        </button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </StyleTable>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {!!meRequestor &&
            <StyledFooter>
              <div className="own-my-actions-btn">
                <Button
                  bsStyle="default"
                  title="Refresh"
                  onClick={this.handleClickRefresh}
                >
                  <i className="fas fa-sync"/>
                </Button>
                <a
                  href={`/storage?own_actions=${meRequestor[0]}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={"btn btn-primary btn-outline-primary"}
                >
                  View all
                </a>
              </div>
            </StyledFooter>
          }
        </Modal.Footer>
      </RndModal>
    );
  }
}

const StyledFooter = styled.div`
  .own-my-actions-btn {
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyleTable =styled.table`
  .action-id-column {
    width: 8%;
    white-space:nowrap;
  }
  .date-column {
    width: 18%;
  }
  .action-type-column {
    width: 20%;
  }
  .content-column {
    width: 30%;
  }
  .status-column {
    width: 24%;
  }
  .subscriber-column {
    width: 14%;
  }
`;

const StyledCell = styled.td`
  .progress {
    margin: 0px;
    width: 80px;
    height: 20px;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
    cursor: pointer;
    .progress-bar {
      float: left;
      width: 0%;
      height: 100%;
      font-size: 12px;
      line-height: 20px;
      color: #fff;
      text-align: center;
      background-color: #337ab7;
      box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
      transition: width .6s ease;
    }
    &.disabled {
      cursor: default;
    }
  }
`;

export default React.memo(MyActionsModal);
