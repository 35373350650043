import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {getSortedRowsBy} from '../../../../utils';
import Table from '../../../../components/table/Table';
import {EmptyCell} from '../../../../components/table/cells/EmptyCell';
import DateCell from '../../../../components/table/cells/DateCell';
import DetailsCell from './cells/DetailsCell';
import {LogDataCell} from './cells/LogDataCell';
import {downloadLogFile} from '../../actions';

class TableWrap extends Component {
  handleDownloadLogFile = row => {
    const {dispatch} = this.props;
    dispatch(downloadLogFile(row.NotificationLogID));
  };

  state = {
    tableSort: {
      field: 'CreatedOn',
      direction: 'desc'
    }
  };

  headers = {
    CreatedOn: {
      title: 'Created On',
      className: 'created-on',
      component: DateCell,
      componentArgs: {
        toFormat: 'MMMM D, YYYY HH:mm A'
      }
    },
    CategoryName: {
      title: 'Category Name',
      className: 'category-name',
      component: EmptyCell
    },
    LogLevel: {
      title: 'Log Level',
      className: 'log-level'
    },
    Description: {
      title: 'Description',
      className: 'description'
    },
    LogData: {
      title: 'Log Data',
      className: 'log-data',
      component: LogDataCell,
      componentArgs: {
        onDownload: this.handleDownloadLogFile
      }
    },
    Details: {
      title: 'Details',
      component: DetailsCell,
      className: 'details'
    }
  };

  sortAltFields = {
    CreatedOn: 'NotificationLogID'
  };

  handleHeaderCellClick = (field, direction) => {
    this.setState({tableSort: {field, direction}});
  };

  render() {
    const {loading, data} = this.props;
    const {tableSort} = this.state;
    return (
      <StyledTable>
        <div className="table-container">
          <div className="table-block">
            <Table
              headers={this.headers}
              rows={getSortedRowsBy(data, tableSort.field, tableSort.direction, this.sortAltFields)}
              sort={tableSort}
              onHeaderCellClick={this.handleHeaderCellClick}
              stripped={false}
              emptyText={loading ? null : 'No Data'}
              />
          </div>
        </div>
      </StyledTable>
    );
  }
}

const StyledTable = styled.div`
  .table-block {
    max-width: 100%;
    overflow-x: auto;

    .table {
      border-collapse: separate;
      color: #282828;

      > thead {
        > tr {
          > th {
            &.details {
              pointer-events: none;
            }
            &.created-on, &.log-level, &.description {
              min-width: 110px;
            }
            &.log-data {
              min-width: 96px;
            }
            &.category-name {
              min-width: 135px;
            }
          }
        }
      }

      > tbody {
        > tr {
          > td {
            &.data-table-empty {
              text-align: center;
              width: 100% !important;
            }
          }
        }
      }
    }
  }
`;

TableWrap.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired
}

export default TableWrap;
