import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {
  clearAllAsperaClients, getActionRequestors,
  getAllPackages
} from './actions';
import Table from './components/table/Table';
import FaspexTabs from './components/FaspexTabs';
import PageComponent from '../../components/PageComponent';
import AppLayout from '../../layouts/AppLayout';
import Loader from '../../components/Loader';
import DownloadAsperaPackageModal from './components/modal/DownloadAsperaPackageModal';
import {
  FASPEX_FLAGS
} from './constants';
import {
  clearAllStorageMngForDownloadMng
} from '../StorageManager/actions';
import ReportBugButton from "../../components/ReportBugButton";
import Filters from "./components/filters/Filters";
import MyActionsModal from "../StorageManager/components/modal/MyActionsModal";

class AsperaClients extends PageComponent {
  state = {
    selectedTab: 'All',
    filters: null
  }

  handleClickTab = (newTab) => {
    this.setState({
      ...this.state,
      selectedTab: newTab
    })
  };

  componentDidMount() {
    localStorage.setItem('last_view', 'faspex');

    const {dispatch} = this.props;
    dispatch(getAllPackages());
    dispatch(getActionRequestors());
  }

  sortPackagesByFlag() {
    const {selectedTab, filters} = this.state;
    const {asperaClients: {packages}} = this.props;

    let packageFilter = packages;
    if (filters !== null) {
      if (filters.senderValue && filters.senderValue.trim()) {
        packageFilter = packageFilter.filter(item => item.authorName.indexOf(filters.senderValue.trim()) >= 0);
      }

      if (filters.titleValue && filters.titleValue.length && filters.titleValue.value !== 'All') {
        const all = filters.titleValue.filter(item => item.value === 'All');
        if (all.length !== 1) {
          const titleValues = filters.titleValue.map(a => a.value);
          packageFilter = packageFilter.filter(item => titleValues.includes(item.packageID));
        }
      }

      if (filters.completedValueTo || filters.completedValueFrom) {
        packageFilter = packageFilter.filter(item => {
          const completed = this.prepareDate(item.completed);
          if (!completed) {
            return false;
          }

          if (filters.completedValueFrom && filters.completedValueTo) {
            return filters.completedValueFrom.getTime() < completed.getTime() && filters.completedValueTo.getTime() >= completed.getTime();
          }

          if (filters.completedValueFrom) {
            return filters.completedValueFrom.getTime() < completed.getTime();
          }

          if (filters.completedValueTo) {
            return filters.completedValueTo.getTime() >= completed.getTime();
          }

          return true;
        });
      }

      if (filters.createdOnValueTo || filters.createdOnValueFrom) {
        packageFilter = packageFilter.filter(item => {
          const createdOn = this.prepareDate(item.createdOn);
          if (!createdOn) {
            return false;
          }

          if (filters.createdOnValueFrom && filters.createdOnValueTo) {
            return filters.createdOnValueFrom.getTime() < createdOn.getTime() && filters.createdOnValueTo.getTime() >= createdOn.getTime();
          } else if (filters.createdOnValueFrom) {
            return filters.createdOnValueFrom.getTime() < createdOn.getTime();
          } if (filters.createdOnValueTo) {
            return filters.createdOnValueTo.getTime() >= createdOn.getTime();
          }

          return true;
        });
      }
    }

    if(selectedTab === 'All') {
      return packageFilter;
    } else if(selectedTab === 'N/A') {
      return packageFilter.filter(item => item.packageFlags === 0);
    } else {
      return packageFilter.filter(item => (Number(item.packageFlags.toString(2)) & FASPEX_FLAGS[selectedTab]) > 0);
    }
  }

  prepareDate = value => {
    const date = new Date(value);
    const res = date.getTime() ? date : null;
    return res || null;
  };

  componentWillUnmount() {
    const {dispatch} = this.props;
    dispatch(clearAllAsperaClients());
    dispatch(clearAllStorageMngForDownloadMng());
  }

  renderModals() {
    const {dispatch, asperaClients, storageManager} = this.props;
    const {isMyActionsModalOpen} = storageManager;
    const {isShowDownloadAsperaPackageModal} = asperaClients;
    if (isShowDownloadAsperaPackageModal) {
      return (
        <DownloadAsperaPackageModal
          dispatch={dispatch}
          storageManager={storageManager}
        />
      );
    }
    if (isMyActionsModalOpen) {
      return (
        <MyActionsModal
          dispatch={dispatch}
          storageManager={storageManager}
        />
      );
    }
    return null;
  }

  onResetFilters = () => {
    this.setState({filters: null});
  }

  onApplyFilters = (filterData) => {
    this.setState({filters: filterData});
  }

  render() {
    const {dispatch, history, asperaClients} = this.props;
    const {loading} = asperaClients;
    const {selectedTab, filters} = this.state;
    const sortedPackages = this.sortPackagesByFlag();
    return (
      <AppLayout
        title="Aspera clients"
        >
        <div className="container">
          {
            loading ?
                <div>
                  <Loader className="full-screen"/>
                  <ReportBugButton isForModal className="loader-report-bug-btn"/>
                </div> : null
          }
          {this.renderModals()}
          {<FaspexTabs
            dispatch={dispatch}
            selectedTab={selectedTab}
            setSelectedTab={this.handleClickTab}
          />}
          {(sortedPackages && (sortedPackages.length > 0 || filters !== null)) ?
            <Filters
              sortedPackages={sortedPackages}
              onResetFilters={this.onResetFilters}
              onApplyFilters={this.onApplyFilters}
            /> : null
          }
          {(sortedPackages && sortedPackages.length > 0) ?
            <Table
              dispatch={dispatch}
              history={history}
              selectedTab={selectedTab}
              sortedPackages={sortedPackages}
              asperaClients={asperaClients}
            /> : <span>There is no packages in this tab</span>
          }
        </div>
      </AppLayout>
    );
  }
}


AsperaClients.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  asperaClients: PropTypes.object.isRequired,
  storageManager: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    asperaClients: state.asperaClients,
    storageManager: state.storageManager,
    user: state.user,
  };
};

export default connect(
  mapStateToProps
)(withRouter(AsperaClients));
