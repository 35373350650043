import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {StyledTable} from './TableStyled';
import {getSortedRowsBy} from '../../../../utils';
import Table from '../../../../components/table/Table';
import {CheckboxHeaderCell} from '../../../../components/table/cells/CheckboxHeaderCell';
import {CheckboxCell} from '../../../../components/table/cells/CheckboxCell';
import DateCell from '../../../../components/table/cells/DateCell';
import {NameCell} from './cells/NameCell';
import {SenderNameCell} from './cells/SenderNameCell';
import InfoPanel from './InfoPanel';
import classNames from 'classnames';
import _ from 'lodash';
import {prepareDataIfTooManyFiles} from '../../utils';
import { changeCheckedItems } from '../../actions';
import {WoMemfisCell} from "./cells/WoMemfisCell";

class TableWrap extends Component {

  isEqualsItems = (item1, item2) => {
    return item1.packageID === item2.packageID && item1.parentDeliveryID === item2.parentDeliveryID;
  };

  isCheckedItem = row => {
    const {checkedItems} = this.props.asperaClients;
    return Boolean(checkedItems.find(item => this.isEqualsItems(item, row)));
  };

  handleChangeCheck = (checked, row) => {
    const {dispatch, asperaClients} = this.props;
    const checkedItems = [...asperaClients.checkedItems];
    if (checked) {
      checkedItems.push(row);
    } else {
      _.remove(checkedItems, item => this.isEqualsItems(item, row));
    }
    dispatch(changeCheckedItems(checkedItems));
  };

  handleChangeHeaderCheck = checked => {
    const {dispatch, sortedPackages} = this.props;
    dispatch(changeCheckedItems(checked ? sortedPackages : []));
  };

  isHeaderChecked = () => {
    // const {data, storageManager} = this.props;
    // const {checkedItems} = storageManager;
    // return checkedItems.length && checkedItems.length === data.length;
  };

  handleNameCellClick = row => {
    // const {dispatch, history} = this.props;
    // dispatch(changeNavigationIntoCurrent(history, row));
  };

  state = {
    tableSort: {
      field: null,
      direction: 'asc'
    }
  };

  headers = {
    selected: {
      title: '',
      component: CheckboxCell,
      componentArgs: {
        isChecked: this.isCheckedItem,
        onChange: this.handleChangeCheck
      },
      headCell: {
        component: CheckboxHeaderCell,
        componentArgs: {
          isChecked: this.isHeaderChecked,
          onChange: this.handleChangeHeaderCheck
        }
      }
    },
    recipientName: {
      title: 'Sender',
      className: 'sender',
      component: SenderNameCell,
    },
    title: {
      title: 'Title',
      className: 'name',
      component: NameCell,
      componentArgs: {
        dispatch: this.props.dispatch,
        history: this.props.history,
        getTextClassName: () => '',
        isOpened: () => false,
        onClick: this.handleNameCellClick
      }
    },
    completed: {
      title: 'Completed',
      className: 'updated',
      component: DateCell,
      componentArgs: {
        toFormat: 'M/D/YYYY hh:mm:ss A'
      }
    },
    createdOn: {
      title: 'Created On',
      className: 'updated',
      component: DateCell,
      componentArgs: {
        toFormat: 'M/D/YYYY hh:mm:ss A'
      }
    },
    woMemfisList: {
      title: 'WO Memfis',
      className: 'updated',
      component: WoMemfisCell,
    }
  };

  sortAltFields = {
    modifiedOn: 'modified'
  };

  handleHeaderCellClick = (field, direction) => {
    this.setState({tableSort: {field, direction}});
  };

  handleRowClick = row => {
    this.handleChangeCheck(!this.isCheckedItem(row), row);
  };

  render() {
    const {dispatch, history, selectedTab, sortedPackages, asperaClients} = this.props;
    const {checkedItems} = asperaClients;
    const {tableSort} = this.state;
    const sortedData = getSortedRowsBy(sortedPackages, tableSort.field, tableSort.direction, this.sortAltFields);
    const displayedData = prepareDataIfTooManyFiles(sortedData);
    const isInfoPanelCollapsed = Boolean(!checkedItems.length);
    return (
      <StyledTable
        className={classNames({
          'faspex-content-table': true,
          'table-wrapper': true,
          'info-panel-collapsed': isInfoPanelCollapsed
        })}
        >
        <div className="table-container">
          <div className="table-block">
            <Table
              className={classNames({
                'with-full-path': true,
              })}
              headers={this.headers}
              rows={displayedData}
              sort={tableSort}
              onHeaderCellClick={this.handleHeaderCellClick}
              onRowClick={this.handleRowClick}
              getRowClassNames={r => r.isEllipsis ? 'tr-ellipsis' : undefined}
              stripped={false}
              emptyTextInCaption="No Files"
              />
          </div>
        </div>
        <InfoPanel
          selectedTab={selectedTab}
          dispatch={dispatch}
          history={history}
          items={checkedItems}
        />
      </StyledTable>
    );
  }
}

TableWrap.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  selectedTab: PropTypes.string.isRequired,
  sortedPackages: PropTypes.array.isRequired,
  asperaClients: PropTypes.object.isRequired,
}

export default TableWrap;
