import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';
import {FormGroup, FormControl, ControlLabel, Button, Collapse} from 'react-bootstrap';
import Wizard from '../../../../components/Wizard/Wizard';
import WOChooser from '../../../../components/WOChooser/WOChooser';
import {
  toggleAttachToWoModal,
  getAttachmentFilters,
  creteAttachToWoAction,
} from '../../actions';

const Steps = {
  INFO: 'Info',
  SELECT_WO: 'Select WO#'
};

export const CATEGORY_PSE_TEST = 6;

function AttachToWoModal(props) {
  const {
    dispatch,
    contentItem,
  } = props;

  const [wizardRef, setWizardRef] = useState(null);
  const [step, setStep] = useState(Steps.INFO);
  const [woMemfis, setWoMemfis] = useState(null);
  const [filters, setFilters] = useState(null);
  const [label, setLabel] = useState(null);
  const [category, setCategory] = useState(null);
  const [pseTestStatus, setPseTestStatus] = useState(null);

  const isSelectingWOMemfis = step === Steps.SELECT_WO;
  const isPSETestCategory = category === CATEGORY_PSE_TEST;

  const handleClose = () => {
    dispatch(toggleAttachToWoModal(false));
  };

  const buildSteps = () => {
    return {
      [Steps.INFO]: {
        stepName: Steps.INFO,
        onContinue: () => {
          dispatch(creteAttachToWoAction(contentItem, woMemfis, label, category, pseTestStatus));
          return Steps.INFO;
        },
        allowContinue: () => {
          return !!woMemfis && !!category && (!isPSETestCategory || !!pseTestStatus);
        },
        getLabelContinue: () => 'Submit'
      },
      [Steps.SELECT_WO]: {
        stepName: Steps.SELECT_WO,
        onBack: () => {
          setStep(Steps.INFO);
        },
        allowContinue: () => false,
        getLabelContinue: () => 'Submit'
      }
    };
  };

  const getChildren = curStep => {
    if (curStep === Steps.SELECT_WO) {
      return (
        <WOMemfisLayout>
          <WOChooser
            fsRootID={/*contentItem.fsRootID*/null}
            onChoose={(value) => {
              if (value !== woMemfis) {
                setWoMemfis(value);
                setLabel(null);
                setCategory(null);
                setPseTestStatus(null);
                setFilters(null);
                dispatch(getAttachmentFilters(value)).then(
                  res => {
                    setFilters(res.filters);
                    setCategory(res.filters.attachment_categories.default || null);
                  }
                );
              }
              setStep(Steps.INFO);
              !!wizardRef && wizardRef.setActiveStepKey(Steps.INFO);
            }}
          />
        </WOMemfisLayout>
      );
    }
    return (
      <InfoLayout>
        <FormGroup>
          <ControlLabel>
            WO #
          </ControlLabel>
          <FormControl.Static componentClass="div">
            {woMemfis}
            <Button
              bsSize="small"
              className={woMemfis ? 'ml15' : undefined}
              onClick={() => {
                setStep(Steps.SELECT_WO);
                !!wizardRef && wizardRef.setActiveStepKey(Steps.SELECT_WO);
              }}
            >
              {`${woMemfis ? 'Change' : 'Select'}...`}
            </Button>
          </FormControl.Static>
        </FormGroup>
        <FormGroup>
          <ControlLabel>
            Attachment Label
          </ControlLabel>
          <FormControl
            type="text"
            value={label || ''}
            onChange={({target: {value}}) => setLabel(value)}
            onBlur={({target: {value}}) => setLabel(value.trim())}
            maxLength={50}
          />
        </FormGroup>
        <FormGroup
          validationState={filters && !category ? 'error' : null}
        >
          <ControlLabel>
            Attachment Category
          </ControlLabel>
          <FormControl.Static componentClass="div">
            <Select
              value={category}
              options={!filters ? [] : filters.attachment_categories.options.map(o => ({...o, label: o.name}))}
              onChange={o => {
                if (o && o.value !== category) {
                  setCategory(o.value);
                  setPseTestStatus(null);
                }
              }}
              clearable={false}
              disabled={!filters}
            />
          </FormControl.Static>
        </FormGroup>
        <Collapse in={isPSETestCategory}>
          <div>
            <FormGroup
              validationState={isPSETestCategory && !pseTestStatus ? 'error' : null}
            >
              <ControlLabel>
                PSE Test Status
              </ControlLabel>
              <FormControl.Static componentClass="div">
                <Select
                  value={pseTestStatus}
                  options={!filters ? [] : filters.attachment_pse_test.map(o => ({value: o, label: o}))}
                  onChange={o => !!o && setPseTestStatus(o.value)}
                  clearable={false}
                  disabled={!filters}
                />
              </FormControl.Static>
            </FormGroup>
          </div>
        </Collapse>
      </InfoLayout>
    );
  };

  return (
    <StyledModal
      onRef={setWizardRef}
      wizardName="Attach File to WO"
      bsSize={() => isSelectingWOMemfis ? 'large' : undefined}
      width={isSelectingWOMemfis ? '90%' : undefined}
      dialogClassName={isSelectingWOMemfis ? 'attach-to-wo-modal-large' : 'attach-to-wo-modal'}
      onCancel={handleClose}
      steps={buildSteps()}
      getChildren={getChildren}
    />
  );
}

const StyledModal = styled(Wizard)`
  .attach-to-wo-modal-large {
    /*width:90%;*/
    .modal-content {
      min-height: 50vh;
      max-height: 80vh;
      .react-draggable {
        min-height: 50vh !important;
        max-height: 80vh !important;
      }
    }
    .modal-body {
      padding: 0;
      overflow: hidden;
      > div, > .container-fluid > div {
        height: 100%;
        > div {
          height: 100%;
          .container {
            height: calc(100% - 64px);
            .requests-content {
              height: 100%;
              > div {
                height: 100%;
              }
            }
          }
        }
      }
    }
    .custom-scrollbar {
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        background-color: transparent;
      }
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        -webkit-box-shadow: none;
        background-color: #c4c4c4;
      }
      @media (max-width: 991px) {
        height: auto;
        border-top: 0.5px solid #efefef;
      }
    }
  }

  .attach-to-wo-modal {
    .container-fluid {
      padding-bottom: 65px;
    }
  }
`;

const WOMemfisLayout = styled.div`
  min-height: 300px;
`;

const InfoLayout = styled.div`
  .form-group {
    display: grid;
    grid-template-columns: 155px auto;
    column-gap: 15px;
    align-items: center;
    justify-content: stretch;
    .control-label {
      margin: 0;
    }
    .form-control-static {
      padding: 0;
      min-height: auto;
      .btn.ml15 {
        margin-left: 15px;
      }
    }
  }
  .Select {
    .Select-menu-outer {
      max-height: 88px;
      .Select-menu {
        max-height: 86px;
      }
    }
  }
`;

AttachToWoModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  contentItem: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const {
    checkedItems
  } = state.storageManager;
  return {
    contentItem: checkedItems[0]
  };
}

export default connect(mapStateToProps)(AttachToWoModal);
