import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { changeCheckedItems, getAllPackages } from '../actions';
import {Row, Col, Button} from 'react-bootstrap';

class FaspexTabs extends Component {
  handleClickTab = (e) => {
    const {dispatch, setSelectedTab} = this.props;
    setSelectedTab(e.target.innerHTML)
    dispatch(changeCheckedItems([]));
  };

  handleClickRefresh = () => {
    const {dispatch} = this.props;
    dispatch(getAllPackages())
  };

  render() {
    const {selectedTab} = this.props;
    return (
      <StyledFaspexTabs>
        <div className="container">
          <Row>
            <Col md={8} className="col-navigation">
              <Button
                bsStyle={selectedTab === 'All' ? 'primary' : 'default'}
                title='All'
                onClick={this.handleClickTab}
                // disabled={!navigationHistory.length}
                >
                  All
              </Button>
              <Button
                bsStyle={selectedTab === 'Sent' ? 'primary' : 'default'}
                title='Sent'
                onClick={this.handleClickTab}
                // disabled={!navigationHistoryForward.length}
                >
                  Sent
              </Button>
              <Button
                bsStyle={selectedTab === 'Inbox' ? 'primary' : 'default'}
                title='Inbox'
                onClick={this.handleClickTab}
                // disabled={displayCategory !== DISPLAY_CATEGORY_FOLDER}
                >
                  Inbox
              </Button>
              <Button
                bsStyle={selectedTab === 'Archieve' ? 'primary' : 'default'}
                title='Archieve'
                onClick={this.handleClickTab}
                // disabled={displayCategory !== DISPLAY_CATEGORY_FOLDER}
                >
                  Archieve
              </Button>
              <Button
                bsStyle={selectedTab === 'N/A' ? 'primary' : 'default'}
                title='N/A'
                onClick={this.handleClickTab}
                // disabled={displayCategory !== DISPLAY_CATEGORY_FOLDER}
                >
                  N/A
              </Button>
            </Col>
            <Col md={4} className="col-refresh">
              <Button
                bsStyle="default"
                title="Refresh"
                onClick={this.handleClickRefresh}
                // disabled={![DISPLAY_CATEGORY_FOLDER, DISPLAY_CATEGORY_ROOTS].includes(displayCategory)}
                >
                <i className="fas fa-sync"/>
              </Button>
            </Col>
          </Row>
        </div>
      </StyledFaspexTabs>
    );
  }
}

const StyledFaspexTabs = styled.div`
  display: flex;
  height: 50px;
  background: #fff;
  z-index: 1020;

  @media (max-width: 767px) {
    height: 130px;
  }
  
  .col-navigation, .col-refresh {
    display: flex;
    
    button {
      
      & + button {
        margin-left: 10px;
      }
    }
  }

  .col-refresh {
    display: flex;
    flex-direction: row-reverse;
  }
  `;

FaspexTabs.propsTypes = {
  dispatch: PropTypes.func.isRequired,
  selectedTab: PropTypes.string.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
};

export default FaspexTabs;
